<app-header></app-header>
<nav class="navbar navbar-inverse">
    <div>
        <div style="position:relative;">
            <div>
                <div class="collapse navbar-collapse" id="myNavbar" style="float:left;padding:0px;clear:both;width:100%;background:#2d3e50;">
                    <div>
                        <ul class="nav navbar-nav ag-menu sm pl40" style="float:left;" data-smartmenus-id="14811307330159312">
                            <li class=""><a href="">HOME</a>
                            </li>
                            <li class="{{openlist}}"  (mouseover)='over()' (mouseout)='out()'><a href="" class="has-submenu">MYPRACTICE  </a>
                                <ul class="dropdown-menu sm-nowrap" >
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Dr Gokul reddy</a>
                                                    </div>
                                                    <div>
                                                        <a class="" routerLink="/practice-locations"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>Practice Locations</a>
                                                    </div>
                                                    <!-- <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Book  appointment</a>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                            </li>
                            <li class="{{openlist1}}" (mouseover)='over1()' (mouseout)='out1()'><a class="has-submenu" href="#" >PATIENT INFO </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/about-cholesterol"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>ABOUT CHOLESTEROL</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/angioplasty"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> ANGIOPLASTY</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/chestpain"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CHESTPAIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/common-misconceptions"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>COMMON MISCONCEPTIONS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/coronary-angiogram"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CORONARY ANGIOGRAM</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/exercsie-and-heart-health"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> EXERCSIE & HEART HEALTH</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/guide-to-taking-warfarin"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> GUIDE TO TAKING WARFARIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-attack"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTATTACK</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-fauilre"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTFAUILRE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/high-blood-pressure"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HIGH BLOOD PRESSURE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/htn-myths"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>HTN-MYTHS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/tips-to-lower"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>TIPS  TO LOWER CHOLESTEROL   LEVELS</a> </div>



                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li><a href="#">SERVICES</a></li>
                            <li><a href="#">APPOINTMENTS</a></li>
                            <li class=""><a  href="faq">FAQS </a></li>
                            <li class=""><a  href="blog">MY BLOG</a></li>

                            <li class=""> <a  href="reach-us">REACH US</a></li>

                            <div class="clearfix"></div>
                        </ul>
                        <div class="pull-right pn">
                            <a href="#"><img src="/../../assets/front/img/askdemo.png" style="height:52px;"/></a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</nav>

<section style="background:#fff;">

    <div class="container">

        <div  style="padding:30px 15px;">

            <div class="row">

                <h4 class="mb5" style="color: #0991cd;font-weight: bold;">COMMON MISCONCEPTIONS ABOUT CHOLESTEROL</h4>

                <!--<p class="pull-right"><img src="img/gokulreddy.png" style="height:350px;"/></p>-->





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:10px;">My choices about diet and physical activity are responsible for my cholesterol level !</h5>







                <p>

                    Diet and physical activity contribute to overall blood cholesterol levels as well as the cholesterol that is made naturally by the body. But other factors also contribute to your cholesterol. Being overweight or obese tends to increase bad and total cholesterol and lower good cholesterol. Getting older also causes LDL cholesterol to rise. For some, heredity may play a role because high cholesterol can run in families. However, a heart-healthy diet and regular physical activity are important to everyone for maintaining cardiovascular health

                </p>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Thin people don't have to worry about high cholesterol !</h5>





                <p>

                    A person with any body type can have high cholesterol. Overweight people are more likely to have high cholesterol, but thin people should also have their cholesterol checked regularly. People who do not gain weight easily are often less aware of how much saturated and trans fat they eat. Nobody can “eat anything they want” and stay heart-healthy. Have your cholesterol checked regularly regardless of your weight, physical activity and diet

                </p>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;"> My doctor hasn't said anything about my cholesterol, so I don't have to worry !</h5>





                <p>Your health is your responsibility. Ask your Doctor to assess your cholesterol and other risk factors starting at age 20. Your healthcare provider can use those numbers to estimate your risk for a heart attack or stroke. If you are between age 40 and 79, talk to your doctor about assessing your 10-year risk. Once you know your risk, you can do something about it. Your doctor may recommend diet and lifestyle changes and/or medication. Follow all of your doctor's instructions, and have your cholesterol and other risk factors reassessed every four to six years.</p>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;"> Since the nutrition label on my favorite food says there's no cholesterol, I can be sure that it's a “heart-healthy” choice !</h5>



                <p>Nutrition labels on food are very helpful when choosing heart-healthy foods, but you need to know what to look for. Many “low-cholesterol” foods contain high levels of saturated fat and/or trans fat — both of which contribute to high blood cholesterol. Even foods that claim to be “low-fat” may have a higher fat content than expected. Look for the amount of saturated fat, trans fat, and total calories in a serving of the product. Also check the size of one serving. Often it's smaller than you think. The first item listed in the ingredients list is the one used most in the product, so choose products where fats and oils appear near the end of the list.</p>







                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Since I started taking medication for my high cholesterol, I don't have to worry about what I eat !</h5>



                <p>Drug therapy is usually prescribed to those who have elevated risks for heart disease and stroke. But making diet and lifestyle changes — as well as taking the medication your doctor prescribes — is the best way to prevent heart disease and stroke. To lower cholesterol, you should eat a heart-healthy diet and get 40 minutes of aerobic exercise of moderate to vigorous intensity three to four times a week. It's also very important to take your medication exactly as your doctor has instructed so it can work most efficiently.</p>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">I'm a woman so I don't have to worry about high cholesterol. It's a man's problem!</h5>



                <p>Premenopausal women may have some protection from high LDL (bad) levels of cholesterol because the female hormone estrogen tends to raise HDL (good) cholesterol levels. But cholesterol levels tend to increase as you age, and postmenopausal women may find that even a heart-healthy diet and regular physical activity aren't enough to keep their cholesterol from rising. If you're approaching menopause, it's especially important to have your cholesterol and other risk factors checked and talk with your doctor about your options.</p>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">You don't need to have your cholesterol checked until you reach middle age !</h5>



                <p>Everyone should start getting tested for cholesterol and other risk factors at age 20. These measurements help to determine risk for a heart attack or stroke. In some cases, children can have high cholesterol levels. Evidence suggests these children are at greater risk for developing heart disease as adults. Lack of regular physical activity, poor dietary habits and genetics can all affect a child's cholesterol levels. Parents and caregivers can help kids develop a heart-healthy lifestyle by serving foods low in saturated fat and trans fats; encouraging at least 60 minutes of physical activity on most (and preferably all) days; and stressing the importance of avoiding tobacco products.</p>





            </div>



        </div>

    </div>

   

</section>
<app-footer></app-footer>
