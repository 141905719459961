<body data-gr-c-s-loaded="true">
    <div id="page" class="side-nav-wrapper">

        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pull-right" style="margin-right: 10px;">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                                style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                                <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                                <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                    userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                        class="">Settings</a></li>
                                <li
                                    *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                    <a [routerLink]="" (click)="users()" class="">Users</a></li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="container bg-gray">
            <div class="row">
                <div class="col-md-12">
                    <h5 class="dr-sub-head">Create Test</h5>
                </div>
            </div>
            <!-- <div class="btn-signin" style="width: 200px;">
                <a [routerLink]="" (click)="onClickCreate()">
                    Calculate Test Price</a>
            </div> -->
        </div>


        <div class="row orr-usercreation ptop30 pbtom30">

            <div class="col-md-12">
                <div class="panel">
                    <div class="tab-table-customer-entry"
                        style="margin:30px 0;background:#fff;padding:10px 0;border-top:1px solid #ddd;border-bottom:1px solid #ddd;">
                        <form id="contact-form" class="contact-form pad-40" autocomplete="off"
                            [formGroup]="paymentForm">
                            <div class="form-pad-label" style="padding-top:30px;">


                                <div class="col-md-6">
                                    <div class="input-text form-group has-feedback">
                                        <label style="width:180px;float:left;text-align:right;padding-right:15px;">Phone
                                            Number<span class="required"> * </span></label>
                                        <input
                                            [class.is-invalid]="ContactNumber.invalid && (ContactNumber.dirty || ContactNumber.touched)"
                                            class="form-control" id="ContactNumber" type="ContactNumber"
                                            name="ContactNumber" placeholder=" Phone Number "
                                            formControlName="ContactNumber" required #mynumber
                                            (change)="onPhoneEntered(mynumber.value)">

                                    </div>

                                    <div style="text-align: center;">
                                        <small *ngIf="error && ContactNumber.errors?.required" class="text-danger">Phone
                                            Number is
                                            required</small>
                                        <small *ngIf="ContactNumber.errors?.pattern" class="text-danger">Please
                                            enter valid Phone Number</small>
                                        <small *ngIf="ContactNumber.errors?.minlength" class="text-danger">Phone
                                            Number should be 10 digits</small>
                                        <small *ngIf="ContactNumber.errors?.maxlength" class="text-danger">Phone
                                            Number should be 10 digits</small>

                                    </div>
                                </div>

                                <div style="clear:both;"></div>
                                <div class="col-md-6" *ngIf="PatientDetiails && PatientDetiails.length>0">
                                    <div class="input-text form-group has-feedback">
                                        <label
                                            style="width:180px;float:left;text-align:right;padding-right:15px;">Select
                                            Patients Name<span class="required"> * </span></label>
                                        <select class="way-custom-dropdown price-text-field profile-mwidth form-control"
                                            style="background-color:#fff;margin-bottom:20px;" formControlName="MRNumber"
                                            id="MRNumber" name="MRNumber" required="" #PatientSelect
                                            (change)='onPatientSelectSelected(PatientSelect.value)'>
                                            <option value="" disabled>Select Patient</option>

                                            <option *ngFor="let pt of PatientDetiails; let i=index"
                                                value="{{pt.MRNumber}}">{{pt.Name}}</option>
                                        </select>
                                    </div>
                                    <div style="text-align: center;">
                                        <small *ngIf="error && MRNumber.errors?.required" class="text-danger">Patient
                                            Select Patient
                                        </small>
                                    </div>
                                </div>


                                <div style="clear:both;"></div>
                                <div class="col-md-6">
                                    <div class="input-text form-group has-feedback">
                                        <label
                                            style="width:180px;float:left;text-align:right;padding-right:15px;">Select
                                            Test List<span class="required"> *</span></label>

                                        <ng-multiselect-dropdown [placeholder]="' Select Test list .... '"
                                            [data]="dropdownList" [(ngModel)]="selectedItems"
                                            [settings]="dropdownSettings" (onSelect)="selectTest($event)"
                                            (onDeSelect)="onDeTestSelect($event)" (onSelectAll)="onSelectAll($event)"
                                            [ngModelOptions]="{standalone: true}" required style="width: 480px">

                                        </ng-multiselect-dropdown>

                                    </div>
                                </div>
                                <div style="clear:both;"></div>
                                <div class="col-md-6">
                                    <div class="input-text form-group has-feedback">
                                        <label style="width:180px;float:left;text-align:right;padding-right:15px;"><span
                                                class="required"> </span></label>
                                    </div>


                                    
                                </div>
                                    
                                <div
                                    style="margin:auto;text-align:center;margin-bottom:10px;margin-top:30px;margin-bottom:30px;">
                                    <a [routerLink]="" (click)="checkcharges(paymentForm)"> <input type="submit"
                                        class="btn-signin" style="background: #367fa9;" value="Check Charges"></a>
                                </div>
                               
                                
                                <div class="row orr-usercreation ptop30 pbtom30"
                                    *ngIf="CalculatedCharges && CalculatedCharges.AllTestPriceDetails.length>0">
                                    <div class="col-md-12">
                                        <div class="panel">
                                            <table cellspacing="0" cellpadding="0" width="100%"
                                                class="mirchi-birthday-grid" id="example">
                                                <thead>
                                                    <tr>
                                                        <th style="text-align:center;"> S.No</th>
                                                        <th style="text-align:center;"> Test Name</th>
                                                        <th style="text-align:center;"> Amount</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr
                                                        *ngFor="let list of CalculatedCharges.AllTestPriceDetails; let i=index ">
                                                        <td style="text-align:center;">{{i + 1}}</td>

                                                        <td style="text-align:center;">{{list.BillingName}}</td>
                                                        <td style="text-align:center;">{{list.TotalPrice}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="text-align:center;"></td>
                                                        <td style="text-align:center;"><b>Final Amount</b></td>
                                                        <td style="text-align:center;">
                                                            <b>{{CalculatedCharges.TotalAmount}}</b></td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style="margin:auto;text-align:center;margin-bottom:10px;margin-top:30px;margin-bottom:30px;" *ngIf="CalculatedCharges && CalculatedCharges.AllTestPriceDetails.length>0">
                                    <a [routerLink]="" (click)="onSubmit(paymentForm)" > <input type="submit"
                                            class="btn-pink" value="Create Payment"></a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</body>



<ngx-spinner></ngx-spinner>