import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-create-test-payments',
  templateUrl: './create-test-payments.component.html',
  styleUrls: ['./create-test-payments.component.css']
})
export class CreateTestPaymentsComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private apicalls: ApicallsService, private router: ActivatedRoute,
    private route: Router, private httpClient: HttpClient, private formBuilder: FormBuilder) { }
  viewallpatients;
  viwAllBillingsActiveType;
  dropdownSettings = {};
  dropdownList = [];
  userDetails;
  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data
    this.apicalls.viewAllPatients()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
          this.viewallpatients = data;
        } else {
          this.spinner.hide();
          if (data.code == 'PD01') {
            alert(data.data)
            this.route.navigate(["/dashboardmain/dashboard"]);
          }
          else {
            alert(data.data)
          }
        }
      }, error => {
        alert(" Internal Server Error ");
      });
      
    this.apicalls.viewAllBillingsActiveTestTypes()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {
            this.viwAllBillingsActiveType = data;
            let tmp = [];
            for (let j = 0; j < this.viwAllBillingsActiveType.data.length; j++) {
              tmp.push({
                BillingID: data.data[j].BillingID,
                BillingName: data.data[j].BillingName,
              })
            }
            this.dropdownList = tmp;
            this.spinner.hide();
          }
          if (data.code == 'V002') {
            alert(data.data);
            this.spinner.hide();
          }
        } else {
          alert(data.data)
          //  this.alertService.info(data.message)
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ");
      });


    this.dropdownSettings = {
      singleSelection: false,
      idField: 'BillingID',
      textField: 'BillingName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    }
  }



  PatientDetiails;
  onPhoneEntered(value: string) {

    this.apicalls.viewPatientsByContactNumber(value).subscribe(

      data => {
        if (data.type == true) {
          if (data.code == 'S001') {
            this.PatientDetiails = data.data;
            if (this.PatientDetiails.length == 1) {

            }
          }
          if (data.code == 'S002') {
            this.PatientDetiails = []
          }

          console.log(this.PatientDetiails)
          setTimeout(() => {
            this.spinner.hide();
          }, 500);

        } else {
          alert(data.message);
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ")
      });

  }


  onPatientSelectSelected(value: string) {
    console.log(value)
  }

  obj = []
  obj1 = [];
  selectedItems = [];
  selectTest(value) {
    this.obj = this.selectedItems
  
  }
  onSelectAll(dropdownList) {
    this.obj = this.dropdownList
  }

  onDeTestSelect(value) {
    this.obj = this.selectedItems
    console.log(this.obj)
  }


  paymentForm = new FormGroup({
    //PatientName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.pattern('[a-zA-Z ]*')]),
    MRNumber: new FormControl('', [Validators.required]),
    Discount: new FormControl('', [Validators.pattern('^[0-9]*')]),
    ContactNumber: new FormControl('', [Validators.required, Validators.pattern('[6789][0-9]{9}'), Validators.minLength(10), Validators.maxLength(10)]),
    TestArray: new FormArray([])
  });
  get MRNumber() {
    return this.paymentForm.get('MRNumber');
  }
  get Discount() {
    return this.paymentForm.get('Discount');
  }
  get TestArray() {
    return this.paymentForm.get('TestArray') as FormArray;
  }
  get ContactNumber() {
    return this.paymentForm.get('ContactNumber');
  }
  get PatientName() {
    return this.paymentForm.get('ContactNumber');
  }

  onClickCreate() {
    this.route.navigate(["/dashboardmain/calculateTestPrices"]);
  }


  TempTestArray = [];
  PaymentStatus = "Paid";
  error;

  CalculatedCharges;
  checkcharges(paymentForm){
    this.TempTestArray=[]

    if(this.obj.length ==0){
      alert("Select Tests")
      return;
    }
    for (let i = 0; i < this.obj.length; i++) {
      this.TempTestArray.push(this.obj[i].BillingID)
    }

    var noDupe = Array.from(new Set(this.TempTestArray))
    this.paymentForm.value.TestArray = noDupe;

    this.apicalls.calculateTestPrice(this.paymentForm.value)
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          this.spinner.hide();

          if (data.code == 'S001') {
            this.CalculatedCharges=data.data;
            console.log(this.CalculatedCharges)
          }else {
            alert(data.data)
          }
          if (data.code == 'V002') {
            alert(data.data);
            this.spinner.hide();
          }
        } else {
          alert(data.data)
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ");
      });
  }

  onSubmit(paymentForm) {

    if (this.paymentForm.invalid) {
      this.error = true;
      return;
    }
    this.TempTestArray=[]
    this.paymentForm.value.TestArray=[]
    for (let i = 0; i < this.selectedItems.length; i++) {
      this.TempTestArray.push(this.selectedItems[i].BillingID)
    }
    this.paymentForm.value.TestArray = this.TempTestArray;
    this.paymentForm.value.PaymentStatus = this.PaymentStatus;

    console.log(this.paymentForm.value)
    this.apicalls.createTestPayment(this.paymentForm.value)
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {
            this.spinner.hide();
            alert(data.data)
            console.log(" billing ", data);
            this.route.navigate(["/dashboardmain/ViewAllPayments"]);
          }
          if (data.code == 'V002') {
            alert(data.data);
            this.spinner.hide();
          }
        } else {
          alert(data.data)
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ");
      });
  }
  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }
  Settings(){
    this.route.navigate(["/dashboardmain/configurations"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
}