<!DOCTYPE html>
<!-- saved from url=(0031)http://drgokulreddy.in/patients -->
<html lang="en">

<body>
    <div id="page" class="side-nav-wrapper">
        <!-- Simple Navbar -->
        <!-- Simple Navbar -->
        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pull-right" style="margin-right: 10px;">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                                style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                                <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                                <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                    userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                        class="">Settings</a></li>
                                <li
                                    *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                    <a [routerLink]="" (click)="users()" class="">Users</a></li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>


        <div class="container bg-gray">
            <div class="row" style="padding-top: 10px; padding-left: 15px;">
                <div class="col-md-4">
                    <!-- <button type="button" style="background:#367fa9; width: 200px ;height: 40px; border-radius: 5px;
                        font-weight: bold; color: white; font-family: 'Open Sans', sans-serif;">
                        <a [routerLink]="" (click)="onClickCreate()" style="color: white;">
                            Create New Appointments </a>
                    </button> -->
                </div>
                <div class="col-md-4" style="padding-left: 480px;">

                    <!-- <button type="button" style="background:#367fa9; width: 200px ;height: 40px; border-radius: 5px;
                    font-weight: bold; color: white; font-family: 'Open Sans', sans-serif;"> -->
                    <a [routerLink]="" (click)="Appointments()" style="color: white;">
                        <input type="submit" class="btn-pink" value="All Appointments ">

                    </a>
                    <!-- </button> -->
                </div>
            </div>
            <div class="row orr-usercreation ptop30 pbtom30">

                <div class="col-md-12">


                    <div class="panel">
                        <div style="text-align: center; color:#367fa9;">
                            <h4 style=" font-weight: bold;">
                                Todays Appointments
                            </h4>
                            <!-- <div class="col-md-4" style="float: right" >
                                    <div class="form-group" >
                                            
                                        <input type="text" class="form-control" placeholder="Enter text here to search " [(ngModel)]="term">
                                    </div>
                                    </div> -->
                        </div>
                        <div class="col-md-12">
                                <label >Search with Patient's Name or AppointmentID</label>
                                </div>
                                <div class="col-md-4">
                                        <div class="form-group" >
                                                
                                                <i class="fa fa-search" style="font-size:24px;padding-top: 9px;padding-right: 9px;"></i><input type="text" class="form-control" placeholder=" Enter text here to search " [(ngModel)]="term">
                                        </div>
                                        </div>

                        <table cellspacing="0" cellpadding="0" width="100%" class="mirchi-birthday-grid" id="example">
                            <thead>
                                <tr>
                                    <th style="text-align:center;"> S.No</th>
                                    <th style="text-align:center;"> Appointment ID </th>
                                    <th style="text-align:center;"> Patient Name</th>
                                    <th style="text-align: center;">Appointment Date</th>
                                    <th style="text-align:center;"> Payment Status</th>
                                    <th style="text-align:center;"> Status</th>
                                    <!-- <th style="text-align:center;"> Health Reports</th> -->

                                    <th style="text-align:center;"> Actions</th>


                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let list of todayAppointments?.data | filter:term; let i=index ">
                                    <td style="text-align:center;">{{i + 1}}</td>
                                    <td style="text-align:center;">{{list.AppointmentID}}</td>
                                    <!-- <td style="text-align:center;">{{list.DoctorName}}</td> -->
                                    <td style="text-align:center;">{{list.PatientName}}</td>
                                    <td style="text-align:center;">{{list.Date*1000|date:"dd-MM-yyyy"}}</td>
                                    <td style="text-align:center;">{{list.PaymentStatus}}</td>
                                    <td style="text-align:center;">{{list.Status}}</td>
                                    <!-- <td>
                                        <a [routerLink]=""
                                            (click)="viewAllHealthReports(list.AppointmentID,list.MRNumber)">
                                            <img src="http://drgokulreddy.in/assets/img/prescription-icon.png">
                                        </a>

                                    </td> -->
                                    <td>
                                        <a [routerLink]="" (click)="editprescription(list.AppointmentID)">
                                            <img src="http://drgokulreddy.in/assets/img/prescription-icon.png">
                                        </a>
                                        <a [routerLink]="" *ngIf="list.Type"
                                        (click)="PrintPrescription(list.AppointmentID)"><img
                                            src="../assets/images/print2.png" width="25px" height="25px"
                                            alt="prescription Print"></a>
                                    <a *ngIf="!list.Type" [disabled]="!list.Type"><img
                                            src="../assets/images/print.png" width="25px" height="25px"
                                            alt="No prescription"></a>

                                    </td>
                                    <!-- <td style="text-align:center;"> -->

                                    <!-- <a href="http://drgokulreddy.in/edit-patient/1730"><img src="./GOKUL CLINIC - Patients List page_files/edit.png"></a>
                                                <a href="http://drgokulreddy.in/Home/delete_patient/1730"><img src="./GOKUL CLINIC - Patients List page_files/delete.png"></a>                         <a href="http://drgokulreddy.in/Home/add_app/1730"><img src="./GOKUL CLINIC - Patients List page_files/appointment.png"></a> -->
                                    <!-- </td> -->

                                    <!-- <td style="text-align:center;"> </td> -->


                                </tr>

                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
            <!--<div class="card-body">
                <div *ngFor="let item of pageOfItems">{{item.name}}</div>
            </div>
             <div class="card-footer pb-0 pt-3">
                
                <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
                    <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item first-item">
                        <a routerLink [queryParams]="{ page: 1 }" class="page-link">First</a>
                    </li>
                    <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item previous-item">
                        <a routerLink [queryParams]="{ page: pager.currentPage - 1 }" class="page-link">Previous</a>
                    </li>
                    <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}"
                        class="page-item number-item">
                        <a routerLink [queryParams]="{ page: page }" class="page-link">{{page}}</a>
                    </li>
                    <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item next-item">
                        <a routerLink [queryParams]="{ page: pager.currentPage + 1 }" class="page-link">Next</a>
                    </li>
                    <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item last-item">
                        <a routerLink [queryParams]="{ page: pager.totalPages }" class="page-link">Last</a>
                    </li>
                </ul>
            </div> -->
            <!-- <div class="card-footer pb-0 pt-3">
                <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
                    <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item first-item">
                        <a routerLink [queryParams]="{ page: 1 }" class="page-link">First</a>
                    </li>
                    <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item previous-item">
                        <a routerLink [queryParams]="{ page: pager.currentPage - 1 }" class="page-link">Previous</a>
                    </li>
                    <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}"
                        class="page-item number-item">
                        <a routerLink [queryParams]="{ page: page }" class="page-link">{{page}}</a>
                    </li>
                    <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item next-item">
                        <a routerLink [queryParams]="{ page: pager.currentPage + 1 }" class="page-link">Next</a>
                    </li>
                    <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item last-item">
                        <a routerLink [queryParams]="{ page: pager.totalPages }" class="page-link">Last</a>
                    </li>
                </ul>
            </div> -->
        </div>
    </div>
</body>

</html>
<ngx-spinner></ngx-spinner>