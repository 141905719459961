import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';


@Component({
  selector: 'app-createpatients',
  templateUrl: './createpatients.component.html',
  styleUrls: ['./createpatients.component.css']
})
export class CreatepatientsComponent implements OnInit {
  datePickerConfig: Partial<BsDatepickerConfig>;
  data: '';
  pathurl: string = 'GokulClinic';
  isSubmitted: boolean = true;
  AddressObj = {};
  error = false;
  maxDate;
  constructor(private spinner: NgxSpinnerService, private httpclient: HttpClient, private formBuilder: FormBuilder, private apicalls: ApicallsService, private route: Router, private router: ActivatedRoute) {
    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumber: false,
        dateInputFormat: 'DD-MM-YYYY'

      }
    );
  }

  userDetails
  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data

    let todaysdate = moment();
    let dateIn1day = todaysdate.toDate();
    let NextYear = dateIn1day.getFullYear();
    let NextDate = dateIn1day.getDate();
    let NextMonth = dateIn1day.getUTCMonth();

    this.maxDate = new Date(NextYear, NextMonth, NextDate);

  }




  onSubmit(patientForm) {
    if (this.patientForm.invalid) {
      this.error = true;
      return;
    }
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 5000);
    if (this.patientForm.value.StreetAddress != '' && this.patientForm.value.StreetAddress != null
      && this.patientForm.value.StreetAddress != 'undefined') {
      this.AddressObj = {
        StreetAddress: this.patientForm.value.StreetAddress,
      };
    } else {
      this.AddressObj = {
        StreetAddress: '',
      }
    }
    this.patientForm.value.Address = this.AddressObj;
    let x = moment(this.patientForm.value.DOB).format("DD-MM-YYYY");
    this.patientForm.value.DOB = x;
    let result = this.apicalls.createPatients(this.patientForm.value)
      .subscribe(data => {
        this.spinner.show();
        if (data.type === true) {
          if (data.code == 'S001') {
            this.spinner.hide();
            alert(data.data)
            this.route.navigate(["/dashboardmain/patients"]);
          }
          if (data.code == 'S002') {
            this.spinner.hide();
            alert(data.data);
            this.isSubmitted = false;
            return false;
          }
        }
        else {
          this.spinner.hide();
          this.isSubmitted = false;
          alert(data.data)
          return false;
        }
      }, error => { alert(" Internal Server Error "); });

  }

  get Name() {
    return this.patientForm.get('Name');
  }
  get EmailID() {
    return this.patientForm.get('EmailID');
  }
  get DOB() {
    return this.patientForm.get('DOB');
  }

  get Age() {
    return this.patientForm.get('Age');
  }
  get Gender() {
    return this.patientForm.get('Gender');
  }
  get RefferedBy() {
    return this.patientForm.get('RefferedBy');
  }
  get District() {
    return this.patientForm.get('District');
  }
  get Hospital() {
    return this.patientForm.get('Hospital');
  }
  get StreetAddress() {
    return this.patientForm.get('StreetAddress');
  }
  // get Colony() {
  //   return this.patientForm.get('Colony');
  // }
  // get Locality() {
  //   return this.patientForm.get('Locality');
  // }
  // get PinCode() {
  //   return this.patientForm.get('PinCode');
  // }
  // get City() {
  //   return this.patientForm.get('City');
  // }
  // get State() {
  //   return this.patientForm.get('State');
  // }
  // get Country() {
  //   return this.patientForm.get('Country');
  // }
  get HospitalName() {
    return this.patientForm.get('HospitalName');
  }
  get HospitalBranch() {
    return this.patientForm.get('HospitalBranch');
  }

  get ContactNumber() {
    return this.patientForm.get('ContactNumber');
  }
  get Address() {
    return this.patientForm.get('Address') as FormArray;
  }


  patientForm = new FormGroup({
    Name: new FormControl('', [Validators.required, Validators.minLength(3), Validators.pattern('[a-zA-Z ]*')]),
    DOB: new FormControl(''),
    Age: new FormControl('', [Validators.required, Validators.pattern('[0-9][0-9]{1}')]),
    Gender: new FormControl('', [Validators.required]),
    ContactNumber: new FormControl('', [Validators.required, Validators.pattern('[6789][0-9]{9}'), Validators.minLength(10), Validators.maxLength(10)]),
    EmailID: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z2-9.-]+\.[a-z]{2,4}$')]),
    RefferedBy: new FormControl('', [Validators.pattern('[a-zA-Z ]*')]),
    District: new FormControl('', [Validators.pattern('[a-zA-Z ]*')]),
    Hospital: new FormControl('', [Validators.pattern('[a-zA-Z ]*')]),

    Address: new FormGroup({
      StreetAddress: new FormControl(''),
      // Colony: new FormControl('', [Validators.required]),
      // Locality: new FormControl('', [Validators.required]),
      // PinCode: new FormControl('',[Validators.required,Validators.pattern('[0-9]{6}')]),
      // City: new FormControl('', [Validators.required]),
      // State: new FormControl('', [Validators.required]),
      // Country: new FormControl('', [Validators.required]),

      // Latitude: new FormControl(''),
      // Longitude: new FormControl(''),
      // LocationLink: new FormControl('')  
    }),
    StreetAddress: new FormControl(''),
    // Colony: new FormControl('', [Validators.required]),
    // Locality: new FormControl('', [Validators.required]),
    // PinCode: new FormControl('',[Validators.required,Validators.pattern('[0-9]{6}')]),
    // City: new FormControl('', [Validators.required]),
    // State: new FormControl('', [Validators.required]),
    // Country: new FormControl('', [Validators.required]),

    // Latitude: new FormControl(''),
    // Longitude: new FormControl(''),
    // LocationLink: new FormControl('')  

  });



  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }
  Settings(){
    this.route.navigate(["/dashboardmain/configurations"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
}


