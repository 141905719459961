<app-header></app-header>
    
<nav class="navbar navbar-inverse">
    <div>
        <div style="position:relative;">
            <div>
                <div class="collapse navbar-collapse" id="myNavbar" style="float:left;padding:0px;clear:both;width:100%;background:#2d3e50;">
                    <div>
                        <ul class="nav navbar-nav ag-menu sm pl40" style="float:left;" data-smartmenus-id="14811307330159312">
                            <li class=""><a href="">HOME</a>
                            </li>
                            <li class="{{openlist}}"  (mouseover)='over()' (mouseout)='out()'><a href="" class="has-submenu">MYPRACTICE  </a>
                                <ul class="dropdown-menu sm-nowrap" >
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Dr Gokul reddy</a>
                                                    </div>
                                                    <div>
                                                        <a class="" routerLink="/practice-locations"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>Practice Locations</a>
                                                    </div>
                                                    <!-- <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Book  appointment</a>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                            </li>
                            <li class="{{openlist1}}" (mouseover)='over1()' (mouseout)='out1()'><a class="has-submenu" href="#" >PATIENT INFO </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/about-cholesterol"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>ABOUT CHOLESTEROL</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/angioplasty"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> ANGIOPLASTY</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/chestpain"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CHESTPAIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/common-misconceptions"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>COMMON MISCONCEPTIONS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/coronary-angiogram"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CORONARY ANGIOGRAM</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/exercsie-and-heart-health"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> EXERCSIE & HEART HEALTH</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/guide-to-taking-warfarin"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> GUIDE TO TAKING WARFARIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-attack"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTATTACK</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-fauilre"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTFAUILRE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/high-blood-pressure"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HIGH BLOOD PRESSURE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/htn-myths"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>HTN-MYTHS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/tips-to-lower"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>TIPS  TO LOWER CHOLESTEROL   LEVELS</a> </div>



                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li><a href="#">SERVICES</a></li>
                            <li><a href="#">APPOINTMENTS</a></li>
                            <li class=""><a  href="faq">FAQS </a></li>
                            <li class=""><a  href="blog">MY BLOG</a></li>

                            <li class=""> <a  href="reach-us">REACH US</a></li>

                            <div class="clearfix"></div>
                        </ul>
                        <div class="pull-right pn">
                            <a href="#"><img src="/../../assets/front/img/askdemo.png" style="height:52px;"/></a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</nav>
<section style="background:#fff;">

    <div class="container">

        <div  style="padding:30px 15px;">

            <div class="row">

                <h4 class="mb5" style="color: #0991cd;font-weight: bold;">ABOUT DOCTOR</h4>

                <p class="pull-right"><img src="/../../assets/front/img/gokulreddy.png" style="height:350px;"/></p>

                <p><b> Dr Gokul Reddy Mandala</b> is an interventional Cardiologist with an interest in the diagnosis and management of patients presenting with chest pain. He has an experience of over 15 years in the field of interventional cardiology and has performed several thousand cardiac interventions like Coronary angioplasty with stenting, peripheral angioplasty, and Renal angioplasty, Structural heart disease interventional therapies like, PBMV, ASD, PDA and VSD device closures.

                </p>



                <p>

                    He has a special interest in managing patients with Heart failure, Aortic diseases and Pulmonary Hypertension.

                </p>

                <p>

                    He is the Director of Cardiac Catheterization Lab in <b>CITIZENS Hospitals</b>, Gachibowli-Nallagandla in Hyderabad and Chief cardiologist at <b>ADITYA Hospital</b> in Hyderabad. His previous appointment was with CARE Hospitals group , Hyderabad for 10 years till March-2013 as a senior Consultant Cardiologist and also has management experience as a medical Director of a 100 bedded unit of CARE Hospital in Musheerabad ,Hyderabad from 2009 to 2013 .

                </p>

                <p>

                    He is Founder -Director of one of the largest cardiology & Cardiac Surgery Group practice, FACTS Cardiology Professionals, in Hyderabad, which now has on board 30 cardiologists and cardiac surgeons. The group renders its

                    services in 10 hospitals of which 5 are in city of Hyderabad and 5 in district headquarters - Mahboobnagar, Karimnagar, Warangal, Bidar, Siddipet and Peddapally.

                </p>

                <p>To serve home town, Emergency cardiac ICU was started in Peddapally town in association with VIJAYA MANDALA Hospitals in March 2016. Emergencies like chest pain, Heart Attack, Acute Heart Failure are being treated. The primary aim of the facility is to minimize the delay in diagnosing heart attack and administer timely care like thrombolytic therapy wherever indicated. The patients are stabilized in ICU and once better are shifted to a higher secondary or tertiary care centers for further management as per the patients need.</p>



                <p>He has authored journal articles and has been an invited faculty in major cardiology conferences like CSI, TCT-USA, ACVS and TCT-INDIA NEXT. He has been the program Director for a clinical and preventive cardiology training program (PGDCC) by IGNOU in CARE Hospitals, Hyderabad till 2013, and has been as a faculty and trained Doctors in the DNB Cardiology Program.</p>



                <h5 class="mb5" style="color: #0991cd;font-weight: bold;margin-top:30px;">Qualifications and Medical School :</h5>

                <ul class="list_1">

                    <li>

                        <b>MBBS - </b>Osmania Medical College, Hyderabad

                    </li>

                    <li>

                        <b>MD-</b>Internal Medicine from Osmania Medical College, Hyderabad

                    </li>

                    <li>

                        <b>DM-</b>Cardiology from Osmania Medical College, Hyderabad

                    </li>



                </ul>

                <!--	<h5 class="mb5" style="color: #0991cd;font-weight: bold;margin-top:30px;"> OUR VISION</h5>

                    <p>There are many risk factors that contribute to the development of  cardiovascular disease.  Some people are born with conditions that predispose them to heart disease and stroke, but most people who  develop cardiovascular disease do so because of a combination of factors such as poor diet, lack of physical activity and smoking, to name just three.  The more risk factors you expose yourself to, the higher the  chance of developing cardiovascular disease..

                    </p>-->



                <h5 class="mb5" style="color: #73bf45;font-weight: bold;margin-top:30px;"> Medical Registration:</h5>

                <p>Telangana State Medical Council, Regd Number- 38759</p>



            </div>

            <div class="row">

                <div class="col-md-4">

                    <h5 class="mb5" style="color: #0991cd;font-weight: bold;margin-top:30px;"> Professional Membership :</h5>

                    <p>Indian Medical Association</p>

                    <p>Indian Society of Echocardiography</p>

                    <p>Fellow of American College of Cardiology</p>

                </div>

                <div class="col-md-4">

                    <h5 class="mb5" style="color: #0991cd;font-weight: bold;margin-top:30px;"> Current Appointments:</h5>

                    <p><b>Senior Consultant Cardiologist and Director of cath lab and interventional Cardiology</b>

                        CITIZENS Hospitals, Gachibowli- Nallagandla, Hyderabad</p>

                    <p><b>Chief Cardiologist</b>

                        ADITYA Hospitals, Uppal, Hyderabad</p>

                    <p><b>Senior Consultant Cardiologist</b>

                        PRATIMA Hospital, Kachiguda</p>



                </div>

                <div class="col-md-4">

                    <h5 class="mb5" style="color: #0991cd;font-weight: bold;margin-top:30px;"> Special Interests:</h5>
                    <ul class="list_1">

                        <li>Chest Pain Diagnosis and Management</li>

                        <li>Coronary Angiography and Angioplasty</li>

                        <li>Structural Heart Disease interventional therapy</li>

                        <li>Pulmonary Hypertension</li>

                        <li>Management of Heart Failure</li>

                        <li>Preventive Cardiology</li>

                    </ul>

                </div>

            </div>

        </div>

    </div>


</section>
<app-footer></app-footer>
