<body data-gr-c-s-loaded="true">
    <div id="page" class="side-nav-wrapper">

        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="dropdown">
                    <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                        style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                        <span class="caret"></span></button>
                    <ul class="dropdown-menu">
                        <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                        <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                        <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                        userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                class="">Settings</a></li>
                        <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                            <a [routerLink]="" (click)="users()" class="">Users</a></li>

                    </ul>
                </div>

            </div>
        </div>

        <div class="container bg-gray">
            <div class="row">
                <div class="col-md-12">
                    <h5 class="dr-sub-head">Create Appointments</h5>
                </div>
            </div>
            <div class="row orr-usercreation ptop30 pbtom30">

                <div class="col-md-12">
                    <!-- <h5 >Create Hospital</h5> -->
                    <div class="panel">
                        <h5>Create Appointments for existing Patients</h5>
                        <!-- <h5 style="margin-left:60px;text-align:left;padding-left:15px;margin:0px 0;padding:0px 0; ">Create Hospital</h5> -->


                        <div class="tab-table-customer-entry"
                            style="margin:30px 0;background:#fff;padding:10px 0;border-top:1px solid #ddd;border-bottom:1px solid #ddd;">
                            <!-- <div class="text-center fs20" *ngIf="channelError" [hidden]='isSubmitted'  style="background-color:white;color:red">{{channelError}}</div> -->
                            <!-- <div class="row"> -->
                            <!-- <p>click here to create appointment for existing patient</p>
                        <div class="col-md-4">
                <div class="btn-signin">
                 
                        <a [routerLink]="" (click)="onClickCreate()">
                                  Create </a>
                      </div>
                      </div> -->
                            <!-- </div> -->
                            <!-- <form action="http://drgokulreddy.in/Home/add_user" onsubmit="return emp_entry()" method="post" enctype="multipart/form-data"> -->
                            <form id="contact-form" class="contact-form pad-40" autocomplete="off"
                                [formGroup]="appointmentForm">
                                <div class="form-pad-label" style="padding-top:30px;">

                                    <!-- <div class="col-md-6">
                            <div class="input-text form-group has-feedback">
                                <label style="width:100px;float:left;text-align:right;padding-right:15px;">USER NAME<span class="required"> * </span></label>
                                <input type="text" name="email" id="email" class="input-name form-control" placeholder=" User Name " style="float:left;width:70%;">
                            </div>
                            <span class="error" id="email_error"></span>
                        </div> -->


                                    <!-- <div style="clear:both;"></div> -->

                                    <!-- MedicID: true,
// * DoctorName: true,
// * PatientName: true,
// * HospitalID: true,
// * HospitalName: true,
// * Date: true,
// * Time: true,
// * Fee: true,
// * PaymentStatus: true,
// * Age: true,
// * Gender: true,
// * ContactNumber: true,
// * Address: {
//  * StreetAddress: true,
//  * Colony: true,
//  * Locality: true,
//  * PinCode: true,
//  * City: true,
//  * State: true -->


                                    <!-- MedicID: true,
* DoctorName: true,
* MRNumber: true,
* PatientName: true,
* HospitalID: true,
* HospitalName: true,
* Date: true,
* Time: true,
* Fee: true,
* PaymentStatus: t -->
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">MRNumber<span
                                                    class="required"> * </span></label>
                                            <input
                                                [class.is-invalid]="MRNumber.invalid && (MRNumber.dirty || MRNumber.touched)"
                                                class="form-control" id="MRNumber" type="text" name="MRNumber"
                                                placeholder=" MRNumber " formControlName="MRNumber">
                                            <div *ngIf="MRNumber.invalid  && (MRNumber.dirty || MRNumber.touched)">
                                                <small *ngIf="MRNumber.errors?.required" class="text-danger"> MRNumber
                                                    is
                                                    required</small>
                                                <!-- <small *ngIf="MedicID.errors?.minlength" class="text-danger"> Name should be minimum 3 characters
                            </small>
                            <small *ngIf="MedicID.errors?.pattern" class="text-danger"> Name should be alphabets
                                </small> -->

                                            </div>
                                            <!-- <input type="text" name="Resource" id="Resource" class="input-name form-control" placeholder=" Resource Name " style="float:left;width:70%;"> -->
                                        </div>
                                        <!-- <span class="error" id="email_error"></span> -->
                                    </div>
                                    <div style="clear:both;"></div>
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Medic-ID<span
                                                    class="required"> * </span></label>
                                            <input
                                                [class.is-invalid]="MedicID.invalid && (MedicID.dirty || MedicID.touched)"
                                                class="form-control" id="MedicID" type="MedicID" name="MedicID"
                                                placeholder=" MedicID " formControlName="MedicID" readonly>
                                            <div *ngIf="MedicID.invalid  && (MedicID.dirty || MedicID.touched)">
                                                <small *ngIf="MedicID.errors?.required" class="text-danger"> MedicID is
                                                    required</small>
                                                <!-- <small *ngIf="MedicID.errors?.minlength" class="text-danger"> Name should be minimum 3 characters
                                                    </small>
                                                    <small *ngIf="MedicID.errors?.pattern" class="text-danger"> Name should be alphabets
                                                        </small> -->

                                            </div>
                                            <!-- <input type="text" name="Resource" id="Resource" class="input-name form-control" placeholder=" Resource Name " style="float:left;width:70%;"> -->
                                        </div>
                                        <!-- <span class="error" id="email_error"></span> -->
                                    </div>
                                    <div style="clear:both;"></div>
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Doctor
                                                Name<span class="required"> * </span></label>
                                            <select
                                                class="way-custom-dropdown price-text-field profile-mwidth form-control"
                                                style="background-color:#fff;margin-bottom:20px;"
                                                formControlName="DoctorID" id="DoctorID" name="DoctorID" required=""
                                                #mySelect (change)='onOptionsSelected(mySelect.value)'>
                                                <option value="">Select Doctor's Name</option>

                                                <option *ngFor="let list of viewalldoctors?.data; let i=index"
                                                    value="{{list.MedicID}}">{{list.FirstName}}</option>


                                            </select>
                                            <div *ngIf="DoctorID.invalid  && (DoctorID.dirty || DoctorID.touched)">
                                                <small *ngIf="DoctorID.errors?.required" class="text-danger">Doctor's
                                                    Name is
                                                    required</small>

                                            </div>
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Hospital<span
                                                    class="required"> * </span></label>
                                            <select
                                                class="way-custom-dropdown price-text-field profile-mwidth form-control"
                                                style="background-color:#fff;margin-bottom:20px;"
                                                formControlName="HospitalID" id="HospitalID" name="HospitalID"
                                                required="" #mySelecthospital
                                                (change)='onOptionsSelectedhospital(mySelecthospital.value)'>
                                                <option value="">Select Hospital</option>

                                                <option *ngFor="let list of viewallhospitals?.data; let i=index"
                                                    value="{{list.HospitalID}}">{{list.HospitalName}}</option>


                                            </select>
                                            <div
                                                *ngIf="HospitalID.invalid  && (HospitalID.dirty || HospitalID.touched)">
                                                <small *ngIf="HospitalID.errors?.required" class="text-danger">Hospital
                                                    is
                                                    required</small>

                                            </div>
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                    <!-- <div class="col-md-8">
                                    <div class="input-text form-group has-feedback">
                                        <label style="width:180px;float:left;text-align:right;padding-right:15px;">Patient Name<span class="required"> * </span></label>
                                        <input
                                        [class.is-invalid]="PatientName.invalid && (PatientName.dirty || PatientName.touched)"
                                        class="form-control" id="PatientName" type="PatientName" name="PatientName"
                                        placeholder=" PatientName " formControlName="PatientName">
                                        <div *ngIf="PatientName.invalid  && (PatientName.dirty || PatientName.touched)">
                                                <small *ngIf="PatientName.errors?.required" class="text-danger">PatientName is
                                                    required</small>
                        
                                            </div>
                                    </div>
                                </div>
                                <div style="clear:both;"></div>
                                <div class="col-md-8">
                                    <div class="input-text form-group has-feedback">
                                        <label style="width:180px;float:left;text-align:right;padding-right:15px;">Age<span class="required"> * </span></label>
                                        <input
                                        [class.is-invalid]="Age.invalid && (Age.dirty || Age.touched)"
                                        class="form-control" id="Age" type="Age" name="Age"
                                        placeholder=" Age " formControlName="Age">
                                        <div *ngIf="Age.invalid  && (Age.dirty || Age.touched)">
                                                <small *ngIf="Age.errors?.required" class="text-danger">Age is
                                                    required</small>
                                                    <small *ngIf="Age.errors?.pattern" class="text-danger">Age must be two digits  </small>
                        
                                            </div>
                                    </div>
                                </div>
                                <div style="clear:both;"></div> -->
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Appointment
                                                Date<span class="required"> * </span></label>
                                            <input [class.is-invalid]="Date.invalid && (Date.dirty || Date.touched)"
                                                class="form-control" id="Date" type="text" name="Date"
                                                placeholder=" Date (dd-mm-yyyy)" formControlName="Date">
                                            <div *ngIf="Date.invalid  && (Date.dirty || Date.touched)">
                                                <small *ngIf="Date.errors?.required" class="text-danger">Date is
                                                    required</small>
                                                <!-- <small *ngIf="Age.errors?.pattern" class="text-danger">Age must be two digits  </small> -->

                                            </div>
                                            <!-- <input type="text" name="Resource" id="Resource" class="input-name form-control" placeholder=" Resource Name " style="float:left;width:70%;"> -->
                                        </div>
                                        <!-- <span class="error" id="email_error"></span> -->
                                    </div>
                                    <div style="clear:both;"></div>
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Time<span
                                                    class="required"> * </span></label>
                                            <input [class.is-invalid]="Time.invalid && (Time.dirty || Time.touched)"
                                                class="form-control" id="Time" type="time" name="Time"
                                                placeholder=" Time " formControlName="Time">
                                            <div *ngIf="Time.invalid  && (Time.dirty || Time.touched)">
                                                <small *ngIf="Time.errors?.required" class="text-danger">Time is
                                                    required</small>
                                                <!-- <small *ngIf="Age.errors?.pattern" class="text-danger">Age must be two digits  </small> -->

                                            </div>
                                            <!-- <input type="text" name="Resource" id="Resource" class="input-name form-control" placeholder=" Resource Name " style="float:left;width:70%;"> -->
                                        </div>
                                        <!-- <span class="error" id="email_error"></span> -->
                                    </div>
                                    <div style="clear:both;"></div>
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Fee<span
                                                    class="required"> * </span></label>
                                            <input [class.is-invalid]="Fee.invalid && (Fee.dirty || Fee.touched)"
                                                class="form-control" id="Fee" type="Fee" name="Fee" placeholder=" Fee "
                                                formControlName="Fee" readonly>
                                            <div *ngIf="Fee.invalid  && (Fee.dirty || Fee.touched)">
                                                <small *ngIf="Fee.errors?.required" class="text-danger">Fee is
                                                    required</small>
                                                <small *ngIf="Fee.errors?.pattern" class="text-danger">Fee must be
                                                    number </small>

                                            </div>
                                            <!-- <input type="text" name="Resource" id="Resource" class="input-name form-control" placeholder=" Resource Name " style="float:left;width:70%;"> -->
                                        </div>
                                        <!-- <span class="error" id="email_error"></span> -->
                                    </div>
                                    <div style="clear:both;"></div>
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Payment
                                                Status<span class="required"> * </span></label>
                                            <select
                                                class="way-custom-dropdown price-text-field profile-mwidth form-control"
                                                style="background-color:#fff;margin-bottom:20px;"
                                                formControlName="PaymentStatus" id="PaymentStatus" name="PaymentStatus"
                                                required="">
                                                <option value="">Select PaymentStatus</option>

                                                <option value="Paid">Paid</option>
                                                <option value="NotPaid">Not Paid</option>


                                            </select>
                                            <div
                                                *ngIf="PaymentStatus.invalid  && (PaymentStatus.dirty || PaymentStatus.touched)">
                                                <small *ngIf="PaymentStatus.errors?.required"
                                                    class="text-danger">PaymentStatus is
                                                    required</small>

                                            </div>
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>

                                    <!-- <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label style="width:180px;float:left;text-align:right;padding-right:15px;">Phone Number<span class="required"> * </span></label>
                                            <input
                                            [class.is-invalid]="ContactNumber.invalid && (ContactNumber.dirty || ContactNumber.touched)"
                                            class="form-control" id="ContactNumber" type="ContactNumber" name="ContactNumber"
                                            placeholder=" Phone Number " formControlName="ContactNumber">
                                           
                                            <div *ngIf="ContactNumber.invalid  && (ContactNumber.dirty || ContactNumber.touched)">
                                                    <small *ngIf="ContactNumber.errors?.required" class="text-danger">Phone Number is
                                                        required</small>
                                                        <small *ngIf="ContactNumber.errors?.pattern" class="text-danger">Please enter valid Phone Number</small>
                                                            <small *ngIf="ContactNumber.errors?.minlength" class="text-danger">Phone Number should be 10 digits</small>
                                                                <small *ngIf="ContactNumber.errors?.maxlength" class="text-danger">Phone Number should be 10 digits</small>
                            
                                                </div>
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
    
                                    <div class="col-md-8">
                                            <div class="input-text form-group has-feedback">
                                                <label style="width:180px;float:left;text-align:right;padding-right:15px;">Gender<span class="required"> * </span></label>
                                                <select
                                                        class="way-custom-dropdown price-text-field profile-mwidth form-control"
                                                        style="background-color:#fff;margin-bottom:20px;" formControlName="Gender"
                                                        id="Gender" name="Gender" required="">
                                                        <option value="">Select Gender</option>
        
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                        
        
                                                    </select>
                                                <div *ngIf="Gender.invalid  && (Gender.dirty || Gender.touched)">
                                                        <small *ngIf="Gender.errors?.required" class="text-danger">Gender is
                                                            required</small>
                                                                                      
                                                    </div>
                                            </div>
                                        </div>
                                        <div style="clear:both;"></div>
    
                                        <div class="col-md-8">
                                                <div class="input-text form-group has-feedback">
    
                                                    <label style="width:180px;float:left;text-align:right;padding-right:15px;">Street Address<span class="required"> * </span></label>
                                                    <input
                                                    [class.is-invalid]="StreetAddress.invalid && (StreetAddress.dirty || StreetAddress.touched)"
                                                    class="form-control" id="StreetAddress" type="StreetAddress" name="StreetAddress"
                                                    placeholder=" Street Address " formControlName="StreetAddress">
                                                    <div *ngIf="StreetAddress.invalid  && (StreetAddress.dirty || StreetAddress.touched)">
                                                            <small *ngIf="StreetAddress.errors?.required" class="text-danger">Street Address is
                                                                required</small>
                                    
                                                        </div>
                                                </div>
                                            </div>
                                            <div style="clear:both;"></div>
                                            <div class="col-md-8">
                                                    <div class="input-text form-group has-feedback">
                                                        <label style="width:180px;float:left;text-align:right;padding-right:15px;">Colony <span class="required"> * </span></label>
                                                        <input
                                                        [class.is-invalid]="Colony.invalid && (Colony.dirty || Colony.touched)"
                                                        class="form-control" id="Colony" type="Colony" name="Colony"
                                                        placeholder=" Colony " formControlName="Colony">
                                                        <div *ngIf="Colony.invalid  && (Colony.dirty || Colony.touched)">
                                                                <small *ngIf="Colony.errors?.required" class="text-danger">Colony is
                                                                    required</small>
                                        
                                                            </div>
                                                    </div>
                                                </div>
                                                <div style="clear:both;"></div>
                                                <div class="col-md-8">
                                                        <div class="input-text form-group has-feedback">
                                                            <label style="width:180px;float:left;text-align:right;padding-right:15px;">Locality <span class="required"> * </span></label>
                                                            <input
                                                            [class.is-invalid]="Locality.invalid && (Locality.dirty || Locality.touched)"
                                                            class="form-control" id="Locality" type="Locality" name="Locality"
                                                            placeholder=" Locality " formControlName="Locality">
                                                            <div *ngIf="Locality.invalid  && (Locality.dirty || Locality.touched)">
                                                                    <small *ngIf="Locality.errors?.required" class="text-danger">Locality is
                                                                        required</small>
                                            
                                                                </div>
                                                        </div>
                                                    </div>
                                                    <div style="clear:both;"></div>
    
                                                    <div class="col-md-8">
                                                            <div class="input-text form-group has-feedback">
                                                                <label style="width:180px;float:left;text-align:right;padding-right:15px;">City <span class="required"> * </span></label>
                                                                <input
                                                                [class.is-invalid]="City.invalid && (City.dirty || City.touched)"
                                                                class="form-control" id="City" type="City" name="City"
                                                                placeholder=" City " formControlName="City">
                                                                <div *ngIf="City.invalid  && (City.dirty || City.touched)">
                                                                        <small *ngIf="City.errors?.required" class="text-danger">City is
                                                                            required</small>
                                                
                                                                    </div>
                                                            </div>
                                                        </div>
                                                        <div style="clear:both;"></div>
                                                        <div class="col-md-8">
                                                                <div class="input-text form-group has-feedback">
                                                                    <label style="width:180px;float:left;text-align:right;padding-right:15px;">PinCode <span class="required"> * </span></label>
                                                                    <input
                                                                    [class.is-invalid]="PinCode.invalid && (PinCode.dirty || PinCode.touched)"
                                                                    class="form-control" id="PinCode" type="PinCode" name="PinCode"
                                                                    placeholder=" PinCode " formControlName="PinCode">
                                                                    <div *ngIf="PinCode.invalid  && (PinCode.dirty || PinCode.touched)">
                                                                            <small *ngIf="PinCode.errors?.required" class="text-danger">PinCode is
                                                                                required</small>
                                                                                <small *ngIf="PinCode.errors?.pattern" class="text-danger">please enter valid PinCode</small>
                                                            
                                                    
                                                                        </div>
                                                                </div>
                                                            </div>
                                                            <div style="clear:both;"></div>
                                                        <div class="col-md-8">
                                                                <div class="input-text form-group has-feedback">
                                                                    <label style="width:180px;float:left;text-align:right;padding-right:15px;">State <span class="required"> * </span></label>
                                                                    <input [class.is-invalid]="State.invalid && (State.dirty || State.touched)"
                                                                    class="form-control" id="State" type="State" name="State"
                                                                    placeholder=" State " formControlName="State">
                                                                    <div *ngIf="State.invalid  && (State.dirty || State.touched)">
                                                                            <small *ngIf="State.errors?.required" class="text-danger">State is
                                                                                required</small>
                                                    
                                                                        </div>
                                                                </div>
                                                            </div>
                                                            <div style="clear:both;"></div>
                                                            <div class="col-md-8">
                                                                    <div class="input-text form-group has-feedback">
                                                                        <label style="width:180px;float:left;text-align:right;padding-right:15px;">Country <span class="required"> * </span></label>
                                                                        <input
                                                                        [class.is-invalid]="Country.invalid && (Country.dirty || Country.touched)"
                                                                        class="form-control" id="Country" type="Country" name="Country"
                                                                        placeholder=" Country " formControlName="Country">
                                                                        <div *ngIf="Country.invalid  && (Country.dirty || Country.touched)">
                                                                                <small *ngIf="Country.errors?.required" class="text-danger">Country is
                                                                                    required</small>
                                                        
                                                                            </div>
                                                                    </div>
                                                                </div>
                                                                <div style="clear:both;"></div> -->

                                    <!-- <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label style="width:100px;float:left;text-align:right;padding-right:15px;">AliasName<span class="required"> * </span></label>
                                            <textarea
                                            [class.is-invalid]="AliasName.invalid && (AliasName.dirty || AliasName.touched)"
                                            class="form-control" id="AliasName" type="AliasName" name="AliasName"
                                            placeholder=" AliasName " formControlName="AliasName"></textarea>
                                            <div *ngIf="AliasName.invalid  && (AliasName.dirty || AliasName.touched)">
                                                    <small *ngIf="AliasName.errors?.required" class="text-danger">AliasName is
                                                        required</small>
                                                        <small *ngIf="AliasName.errors?.pattern" class="text-danger">AliasName should me minimum 10 characters
                                                            </small>
                            
                                                </div>
                                        </div>
                                    </div> -->
                                    <!-- <div style="clear:both;"></div> -->

                                    <div
                                        style="margin:auto;text-align:center;margin-bottom:10px;margin-top:30px;margin-bottom:30px;">
                                        <a [routerLink]="" (click)="onSubmit(appointmentForm)"> <input type="submit"
                                                class="btn-pink" value="CREATE"></a>

                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>

            </div>
        </div>

        <script>

            var base_url = 'http://drgokulreddy.in/';
            function emp_entry() {

                var flag = 0;
                var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
                var alpha = /^[a-zA-Z\s]+$/;
                var email = $("#email").val();
                var regex = /^[0-9]*(?:\.\d{1,2})?$/;

                if ($("#name").val() == '') {
                    $("#name_error").html('Enter Name');
                    flag = 1;
                }
                else {
                    if (!alpha.test($("#name").val())) {
                        $("#name_error").html('Enter only Alphabets');
                        flag = 1;
                    }
                    else {
                        $("#name_error").html('');
                    }

                }
                if ($("#email").val() == '') {
                    $("#email_error").html('Enter email');
                    flag = 1;
                }
                else if (!pattern.test($("#email").val())) {
                    $("#email_error").html('Enter valid  Email');
                    flag = 1;
                }
                else {
                    $("#email_error").html('');
                }





                if ($("#pass").val() == '') {
                    $("#pass_error").html('Enter Password');
                    flag = 1;
                }
                else {
                    $("#pass_error").html('');

                }


                if ($("#utype").val() == '') {
                    $("#utype_error").html('Select User Type');
                    flag = 1;
                }
                else {
                    $("#utype_error").html('');

                }
                if (flag) {
                    $(".error").each(function () {
                        if ($(this).html().length > 0) {

                            var str = $(this).attr('id');
                            var res = str.split("_");
                            $("#" + res[0]).focus();
                            return false;
                        }

                    })
                    return false;
                }

            }


        </script>
        <script src="./GOKUL CLINIC - Add User page_files/jquery.dataTables.min.js.download"></script>
        <script src="./GOKUL CLINIC - Add User page_files/dataTables.bootstrap.min.js.download"></script>
        <style type="text/css">
            @media print {


                #get-quote,
                .upper {
                    display: none;
                }
            }
        </style>
        <script type="text/javascript">
            $('.print').click(function () {
                window.print();
                //printDiv();

            });
            function printDiv() {

                var divToPrint = document.getElementById('divtoprint');

                var newWin = window.open('', 'Print-Window');

                newWin.document.open();

                newWin.document.write('<html><body onload="window.print()">' + divToPrint.innerHTML + '</body></html>');

                newWin.document.close();

                setTimeout(function () { newWin.close(); }, 10);

            }
            $(document).ready(function () {
                $('#example').DataTable();
            });
            $(document).ready(function () {
                $(".alert").fadeTo(2000, 500).slideUp(500, function () {
                    $(".alert").slideUp(500);
                });
            });
        </script>
    </div>
    <script src="./GOKUL CLINIC - Add User page_files/tabs.js.download"></script>





</body>
<ngx-spinner></ngx-spinner>