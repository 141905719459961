<app-header></app-header>

<nav class="navbar navbar-inverse">
    <div>
        <div style="position:relative;">
            <div>
                <div class="collapse navbar-collapse" id="myNavbar" style="float:left;padding:0px;clear:both;width:100%;background:#2d3e50;">
                    <div>
                        <ul class="nav navbar-nav ag-menu sm pl40" style="float:left;" data-smartmenus-id="14811307330159312">
                            <li class=""><a href="">HOME</a>
                            </li>
                            <li class="{{openlist}}"  (mouseover)='over()' (mouseout)='out()'><a href="" class="has-submenu">MYPRACTICE  </a>
                                <ul class="dropdown-menu sm-nowrap" >
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Dr Gokul reddy</a>
                                                    </div>
                                                    <div>
                                                        <a class="" routerLink="/practice-locations"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>Practice Locations</a>
                                                    </div>
                                                    <!-- <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Book  appointment</a>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                            </li>
                            <li class="{{openlist1}}" (mouseover)='over1()' (mouseout)='out1()'><a class="has-submenu" href="#" >PATIENT INFO </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/about-cholesterol"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>ABOUT CHOLESTEROL</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/angioplasty"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> ANGIOPLASTY</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/chestpain"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CHESTPAIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/common-misconceptions"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>COMMON MISCONCEPTIONS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/coronary-angiogram"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CORONARY ANGIOGRAM</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/exercsie-and-heart-health"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> EXERCSIE & HEART HEALTH</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/guide-to-taking-warfarin"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> GUIDE TO TAKING WARFARIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-attack"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTATTACK</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-fauilre"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTFAUILRE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/high-blood-pressure"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HIGH BLOOD PRESSURE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/htn-myths"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>HTN-MYTHS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/tips-to-lower"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>TIPS  TO LOWER CHOLESTEROL   LEVELS</a> </div>



                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li><a href="#">SERVICES</a></li>
                            <li><a href="#">APPOINTMENTS</a></li>
                            <li class=""><a  href="faq">FAQS </a></li>
                            <li class=""><a  href="blog">MY BLOG</a></li>

                            <li class=""> <a  href="reach-us">REACH US</a></li>

                            <div class="clearfix"></div>
                        </ul>
                        <div class="pull-right pn">
                            <a href="#"><img src="/../../assets/front/img/askdemo.png" style="height:52px;"/></a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</nav>

<section style="background:#fff;">

    <div class="container">

        <div  style="padding:30px 15px;">

            <div class="row">

                <h4 class="mb5" style="color: #0991cd;font-weight: bold;"> High Blood Pressure </h4>

                <p class="pull-right"><img src="/../../assets/front/img/High-Blood-Pressure.png" style="height:100%;"/></p>



                <p><i><b>High blood pressure (HBP or hypertension) is when your blood pressure, the force of your blood pushing against the walls of your blood vessels, is consistently too high.</b></i></p>

                <p>

                    In order to survive and function properly, your tissues and organs need the oxygenated blood that your circulatory system carries throughout the body. When the heart beats, it creates pressure that pushes blood through a network of tube-shaped blood vessels, which include arteries, veins and capillaries.

                </p>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">This pressure — blood pressure — is the result of two forces:</h5>





                <ul class="list_1">

                    <li>The first force (systolic pressure) occurs as blood pumps out of the heart and into the arteries that are part of the circulatory system.</li>

                    <li>The second force (diastolic pressure) is created as the heart rests between heart beats.</li>

                </ul>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">How is blood pressure checked ?</h5>



                <ul class="list_1">

                    <li>A blood pressure reading is taken with a pressure cuff</li>

                    <li>During the test, the cuff is placed around the upper arm before being manually or electronically inflated. Once inflated, the cuff compresses the brachial artery( Blood vessel in the arm) , momentarily stopping blood flow. Next, air in the cuff is slowly

                        released while the person performing the measurement listens with a stethoscope or monitors an electronic readout.</li>



                </ul>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Your blood pressure reading is recorded as two numbers:</h5>



                <ul class="list_1">

                    <li>Systolic blood pressure (the top number) — indicates how much pressure your blood is exerting against your artery walls during heart beat</li>



                    <li>Diastolic blood pressure (the bottom number) — indicates how much pressure your blood is exerting against your artery walls while the heart is resting between beats.</li>



                </ul>







                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">If your blood pressure is normal (less than 120/80 mm Hg),</h5>



                <ul class="list_1">

                    <li>Your blood pressure should be screened during regular healthcare visits at least once every two years for anyone 20 years of age or older.</li>



                </ul>







                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">If your blood pressure reading is higher than normal</h5>



                <ul class="list_1">

                    <li>Your need several readings over time and/or have you monitor your blood pressure at home before diagnosing you with high blood pressure.</li>



                    <li>A single high reading does not mean that you have high blood pressure. But, if your readings continue to stay high, you most likely would need to begin a treatment program.</li>



                </ul>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Know your numbers</h5>



                <p>The best way to protect yourself is to learn where you stand by measuring your blood pressure.</p>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;"> High blood pressure is a largely symptomless “silent killer.”</h5>



                <p>High blood pressure does not cause headaches or nosebleeds, except in the case of hypertensive crisis, a medical emergency when blood pressure is 180/110 mm Hg or higher

                    A variety of symptoms may be indirectly related to, but are not always caused by, high blood pressure, such as:</p>



                <ul class="list_1">

                    <li><b>CBlood spots in the eyes</b> </li>



                    <li><b>Facial flushing</b> </li>



                    <li><b>Dizziness</b> </li>

                </ul>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">TRAGET ORGAN DAMAGE / COMPLICATIONS OF HTN</h5>





                <p class="pull-center"><img src="/../../assets/front/img/htn.png" style="height:90%;"/></p>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">SYMPTOMS OF LOW BLOOD PRESSURE ( < 90-100/ 60-70 mmHG)</h5>





                <p>Noticeable signs and symptoms are</p>



                <ul class="list_1">

                    <li>Dizziness or lightheadedness</li>



                    <li>Nausea</li>



                    <li>Fainting</li>



                    <li>Dehydration and unusual thirst</li>



                    <li>Lack of concentration</li>



                    <li>Blurred vision</li>

                    <li>Fatigue</li>

                </ul>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Low blood pressure can occur with:</h5>





                <ul class="list_1">

                    <li>Prolonged bed rest</li>



                    <li>Pregnancy - During the first 24 weeks of pregnancy, it’s common for blood pressure to drop.</li>



                    <li>Decreases in blood volume ,Certain medications , Heart problems, Endocrine problems, Severe infection (septic shock), Allergic reaction (anaphylaxis) , Neurally mediated hypotension</li>



                    <li>NutritionaldeficienciesI</li>



                    <li>f you notice a sudden decline in blood pressure- single lower-than-normal reading is not cause for alarm, unless you are experiencing any other symptoms or problems. If you experience any dizziness, lightheadedness, nausea or other symptoms, it’s a good idea to consult doctor .</li>





                </ul>







            </div>



        </div>

    </div>

    

</section>
<app-footer></app-footer>