import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exercsie-and-heart-health',
  templateUrl: './exercsie-and-heart-health.component.html',
  styleUrls: ['./exercsie-and-heart-health.component.css']
})
export class ExercsieAndHeartHealthComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  openlist;
  openlist1;
  over(){
    this.openlist="open"
  }

  out(){
    this.openlist="";
  }
  over1(){
    this.openlist1="open"
  }

  out1(){
    this.openlist1="";
  }

}
