import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../../services/apicalls.service';
@Component({
  selector: 'app-allechoreports',
  templateUrl: './allechoreports.component.html',
  styleUrls: ['./allechoreports.component.css']
})
export class AllechoreportsComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private apicalls: ApicallsService, private router: ActivatedRoute, private route: Router, private httpClient: HttpClient) { }

  reports;
  userDetails;

  term: string = '';

  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data
    this.apicalls.AllLabReports()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {
            this.reports = data.data;
            console.log(" resports ", this.reports);
          }
          else if (data.code == "S002") {
            alert("No data found")
          } else if (data.code == "UA01") {
            alert("Your UnAuthorized to Access this Service")
          }
          setTimeout(() => {
            this.spinner.hide();
          }, 500);

        } else {

          //  this.alertService.info(data.message)
          this.spinner.hide();
          // this.spinner.hide();

          if (data.code == 'PD01') {
            alert(data.data)
            this.route.navigate(["/dashboardmain/dashboard"]);

          }
          else {
            alert(data.data)
          }

        }
      }, error => {
        error => { alert(" Internal Server Error "); }
        // this.alertService.warning(" Internal Server Error ");
      });
  }

  Configurations() {
    this.route.navigate(["/dashboardmain/configuration"]);
  }



  Followupappointments() {
    this.route.navigate(["/dashboardmain/followupappointments"]);
  }



  doctors() {
    this.route.navigate(["/dashboardmain/doctors"]);
  }
  patients() {
    this.route.navigate(["/dashboardmain/patients"]);
  }
  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }

  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }

  Createechoreport() {
    this.route.navigate(["/dashboardmain/createechoreport"]);
  }
  ondata(list) {

    if (list.BillName == "2D_ECHO" || list.BillName =="2D ECHOCARDIOGRAM"	) {
      this.route.navigate(["/dashboardmain/createechoreport/", list.MRNumber, list.BillID, list.PaymentID, list.Type]);
    } else {
      this.route.navigate(["/dashboardmain/ViewReportsbylabtech", list.MRNumber, list.BillID, list.PaymentID]);
    }
  }



  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }



}
