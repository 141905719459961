import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'app-view-health-report',
  templateUrl: './view-health-report.component.html',
  styleUrls: ['./view-health-report.component.css']
})
export class ViewHealthReportComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private httpclient: HttpClient, private formBuilder: FormBuilder,
    private apicalls: ApicallsService, private route: Router, private router: ActivatedRoute) { }
  userDetails;
  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data

    this.router.paramMap.subscribe(params => {
      const ReportID = params.get('ReportID');
      if (ReportID) {
        this.getHelathReport(ReportID);
      }
    });
  }
  report;
  ReportDate;
  getHelathReport(ReportID: string) {
    this.apicalls.viewReportById(ReportID).subscribe(
      data => {
        this.spinner.show();

        if (data.type == true) {
          this.report = data
          console.log(" report ", this.report)
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
          }, 500);
          this.ReportDate = moment.unix(this.report.data.ReportGeneratedDate).format("DD-MM-YYYY");

        } else {
          alert(data.message);
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ")
      });
  }

  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }
  Settings(){
    this.route.navigate(["/dashboardmain/configurations"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
}
