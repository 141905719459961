import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './component/login/login.component';
import { ForgotpasswordComponent } from './component/forgotpassword/forgotpassword.component';
import { ChangepasswordComponent } from './component/changepassword/changepassword.component';
import { ViewallresourcesComponent } from './component/resources/viewallresources/viewallresources.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { ResourcepageComponent } from './component/resources/resourcepage/resourcepage.component';
//import { NewcompComponent } from './component/newcomp/newcomp.component';
import { ViewallrolesComponent } from './component/roles/viewallroles/viewallroles.component';
import { CreateroleComponent } from './component/roles/createrole/createrole.component';
import { UpdateroleComponent } from './component/roles/updaterole/updaterole.component';
import { ViewallprivilegesComponent } from './privileges/viewallprivileges/viewallprivileges.component';
import { DashboardmainComponent } from './dashboardmain/dashboardmain.component';
import { CreateprivilegesComponent } from './privileges/createprivileges/createprivileges.component';
import { ViewallpermissionsComponent } from './permissions/viewallpermissions/viewallpermissions.component';
import { ViewallchannelpartnersComponent } from './channelpartners/viewallchannelpartners/viewallchannelpartners.component';
import { UpdatechannelpartnerComponent } from './channelpartners/updatechannelpartner/updatechannelpartner.component';
import { CreatechannelpartnerComponent } from './channelpartners/createchannelpartner/createchannelpartner.component';
import { LogoutComponent } from './logout/logout.component';
import { CreatepermissionsComponent } from './permissions/createpermissions/createpermissions.component';
import { ViewallhospitalsComponent } from './hospitals/viewallhospitals/viewallhospitals.component';
import { CreatehospitalsComponent } from './hospitals/createhospitals/createhospitals.component';
import { UpdatehospitalsComponent } from './hospitals/updatehospitals/updatehospitals.component';
import { UpdatepermissionsComponent } from './permissions/updatepermissions/updatepermissions.component';
import { ViewalldoctorsComponent } from './doctors/viewalldoctors/viewalldoctors.component';
import { CreatedoctorsComponent } from './doctors/createdoctors/createdoctors.component';
import { UpdatedoctorsComponent } from './doctors/updatedoctors/updatedoctors.component';
import { ViewallpatientsComponent } from './patients/viewallpatients/viewallpatients.component';
import { CreatepatientsComponent } from './patients/createpatients/createpatients.component';
import { UpdatepatientsComponent } from './patients/updatepatients/updatepatients.component';
import { ViewallmyappointmentsComponent } from './patients/viewallmyappointments/viewallmyappointments.component';
import { CreateappointmentsComponent } from './appointments/createappointments/createappointments.component';
import { ViewallappointmentsComponent } from './appointments/viewallappointments/viewallappointments.component';
import { UpdateappointmentsComponent } from './appointments/updateappointments/updateappointments.component';
import { CreateappointmentforexistpatientsComponent } from './appointments/createappointmentforexistpatients/createappointmentforexistpatients.component';
import { UpdateappointmentsprescriptionComponent } from './appointments/updateappointmentsprescription/updateappointmentsprescription.component';
import { TodaysAppointmentsComponent } from './appointments/todays-appointments/todays-appointments.component';
import { ViewAllbillingsComponent } from './billings/view-allbillings/view-allbillings.component';
import { CreatebillingsComponent } from './billings/createbillings/createbillings.component';
import { UpdatebillingComponent } from './billings/updatebilling/updatebilling.component';

import { ViewAllPaymentsComponent } from './payments/view-all-payments/view-all-payments.component';
import { CreateTestPaymentsComponent } from './payments/create-test-payments/create-test-payments.component';
import { UpdatePaymentsComponent } from './payments/update-payments/update-payments.component';
import { CalculateTestPricesComponent } from './payments/calculate-test-prices/calculate-test-prices.component';
import { RevenueComponent } from './revenue/revenue.component';
import { ConfigurationsComponent } from './configurations/configurations.component';
import { FollowupappointmentsComponent } from './followupappointments/followupappointments.component';

import { AllechoreportsComponent } from './2dechoreport/allechoreports/allechoreports/allechoreports.component';
import { CreatereportComponent } from './2dechoreport/createechoreport/createreport/createreport.component';
import { ViewEchoReportsComponent } from './2dechoreport/view-echo-reports/view-echo-reports.component';
import { Print2DEchoReportComponent } from './2dechoreport/print-2d-echo-report/print-2d-echo-report.component';

import { CreateUserComponent } from './Users/create-user/create-user.component';
import { ViewAllUsersComponent } from './Users/view-all-users/view-all-users.component';
import { UpdateUserComponent } from './Users/update-user/update-user.component';

import { UploadHelathReportsComponent } from './Reports/upload-helath-reports/upload-helath-reports.component';
import { ViewAllHelathReportsComponent } from './Reports/view-all-helath-reports/view-all-helath-reports.component';
import { ViewHealthReportComponent } from './Reports/view-health-report/view-health-report.component';
import { ViewReportsbylabtechComponent } from './Reports/view-reportsbylabtech/view-reportsbylabtech.component'

import { CreatePatientBillingsComponent } from './PatientBillings/create-patient-billings/create-patient-billings.component';
import { combineLatest, from } from 'rxjs';
import { MasterComponent } from './master/master.component';

import {PatientsbycategoryComponent} from './patientsbycategory/patientsbycategory.component'

import {HeaderComponent} from './header/header.component';

import {HomeComponent} from './home/home.component';

import {GokulComponent} from './gokul/gokul.component';
import {PractiselocationComponent} from './practiselocation/practiselocation.component';
import {AboutcholesterolComponent} from './aboutcholesterol/aboutcholesterol.component';
import { AngioplastyComponent } from './angioplasty/angioplasty.component';
import { ChestpainComponent } from './chestpain/chestpain.component';
import { CommonMisconceptionsComponent } from './common-misconceptions/common-misconceptions.component';

import { CoronaryAngiogramComponent } from './coronary-angiogram/coronary-angiogram.component';
import { ExercsieAndHeartHealthComponent } from './exercsie-and-heart-health/exercsie-and-heart-health.component';
import { GuideToTakingWarfarinComponent } from './guide-to-taking-warfarin/guide-to-taking-warfarin.component';
import { HeartAttackComponent } from './heart-attack/heart-attack.component';
import { HeartFauilreComponent } from './heart-fauilre/heart-fauilre.component';
import { HighBloodPressureComponent } from './high-blood-pressure/high-blood-pressure.component';
import { HtnMythsComponent } from './htn-myths/htn-myths.component';
import { TipsToLowerComponent } from './tips-to-lower/tips-to-lower.component';
import { FaqComponent } from './faq/faq.component';
import { ReachUsComponent } from './reach-us/reach-us.component';




const pathurl = 'GokulClinic';
const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forgotpassword', component: ForgotpasswordComponent },
  { path: 'header', component: HeaderComponent },
  { path: 'home', component: HomeComponent },
  { path: 'gokul-reddy-mandala', component: GokulComponent },
  { path: 'practice-locations', component: PractiselocationComponent },
  { path: 'about-cholesterol', component: AboutcholesterolComponent },
  { path: 'angioplasty', component: AngioplastyComponent },
  { path: 'chestpain', component: ChestpainComponent },
  { path: 'common-misconceptions', component: CommonMisconceptionsComponent },
  { path: 'coronary-angiogram', component: CoronaryAngiogramComponent },
  { path: 'exercsie-and-heart-health', component: ExercsieAndHeartHealthComponent },
  { path: 'guide-to-taking-warfarin', component: GuideToTakingWarfarinComponent },
  { path: 'heart-attack', component: HeartAttackComponent },
  { path: 'heart-fauilre', component: HeartFauilreComponent },
  { path: 'high-blood-pressure', component: HighBloodPressureComponent },
  { path: 'htn-myths', component: HtnMythsComponent },
  { path: 'tips-to-lower', component: TipsToLowerComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'reach-us', component: ReachUsComponent },
  //  { path: 'changepassword', component: ChangepasswordComponent },
  // { path: 'dashboard', component: DashboardComponent },
  // { path: 'resources', component: ResourcepageComponent },
  //{ path: 'newcomp', component: NewcompComponent },
  // { path: 'viewallresources', component: ViewallresourcesComponent },
  // { path: 'viewallroles', component: ViewallrolesComponent },
  // { path: 'createrole', component: CreateroleComponent },
  //  { path: 'channelpartners', component: ViewallchannelpartnersComponent },
  //  { path: 'updaterole/:id', component: UpdateroleComponent },
  {
    path: 'dashboardmain', component: DashboardmainComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent },
      {
        path: 'changepassword',
        component: ChangepasswordComponent
      },
      {
        path: 'createrole',
        component: CreateroleComponent
      },
      {
        path: 'viewallroles',
        component: ViewallrolesComponent
      },
      {
        path: 'updaterole/:id',
        component: UpdateroleComponent
      },
      {
        path: 'viewallresources',
        component: ViewallresourcesComponent
      },
      {
        path: 'resources',
        component: ResourcepageComponent //create resource
      },
      {
        path: 'privileges',
        component: ViewallprivilegesComponent
      },
      {
        path: 'createprivilege',
        component: CreateprivilegesComponent
      },
      {
        path: 'permissions',
        component: ViewallpermissionsComponent
      },
      {
        path: 'createpermissions',
        component: CreatepermissionsComponent
      },
      {
        path: 'updatepermissions/:id',
        component: UpdatepermissionsComponent

      },
      {
        path: 'channelpartners',
        component: ViewallchannelpartnersComponent
      },
      {
        path: 'createchannelpartners',
        component: CreatechannelpartnerComponent
      },
      {
        path: 'updatechannelpartners/:id',
        component: UpdatechannelpartnerComponent
      },
      {
        path: 'hospitals',
        component: ViewallhospitalsComponent
      },
      {
        path: 'createhospitals',
        component: CreatehospitalsComponent
      },
      {
        path: 'updatehospitals/:id',
        component: UpdatehospitalsComponent
      },
      {
        path: 'doctors',
        component: ViewalldoctorsComponent
      },
      {
        path: 'createdoctors',
        component: CreatedoctorsComponent
      },
      {
        path: 'updatedoctors/:id',
        component: UpdatedoctorsComponent
      },
      {
        path: 'patients',
        component: ViewallpatientsComponent
      },
      {
        path: 'patients/:mobile/:mrnumber',
        component: ViewallpatientsComponent
      },
      {
        path: 'createpatients',
        component: CreatepatientsComponent
      },
      {
        path: 'updatepatients/:id',
        component: UpdatepatientsComponent
      },
      {
        path: 'viewallpatientsappointments',
        component: ViewallmyappointmentsComponent
      },
      {
        path: 'appointments',
        component: ViewallappointmentsComponent
      },
      {
        path: 'createappointments',
        component: CreateappointmentsComponent
      },
      {
        path: 'createappointmentforexistpatient',
        component: CreateappointmentforexistpatientsComponent
      },
      {
        path: 'updateappointments/:id',
        component: UpdateappointmentsComponent
      },
      {
        path: 'updateappointmentsprescriptions/:id',
        component: UpdateappointmentsprescriptionComponent
      },
      ///////////////////////////////////////
      {
        path: 'TodaysAppointments',
        component: TodaysAppointmentsComponent
      },
      {
        path: 'ViewAllbillings',
        component: ViewAllbillingsComponent
      }, {
        path: 'Createbilling',
        component: CreatebillingsComponent
      },
      {
        path: 'Updatebilling/:BillingID',
        component: UpdatebillingComponent
      },
      {
        path: 'calculateTestPrices',
        component: CalculateTestPricesComponent
      },
      /*Payments  */
      {
        path: 'ViewAllPayments',
        component: ViewAllPaymentsComponent
      }, {
        path: 'CreateTestPayments',
        component: CreateTestPaymentsComponent
      },
      {
        path: 'UpdatePayment/:PaymentID',
        component: UpdatePaymentsComponent
      },
      {
        path: 'revenue',
        component: RevenueComponent
      },
      {
        path: 'configurations',
        component: ConfigurationsComponent
      },
      {
        path: 'followupappointments',
        component: FollowupappointmentsComponent
      },
      {
        path: 'allechoreports',
        component: AllechoreportsComponent
      },
      {
        path: 'createechoreport',
        component: CreatereportComponent
      },
      {
        path: 'viewechoreports/:ReportID',
        component: ViewEchoReportsComponent
      },
      {
        path: 'createechoreport/:MRNumber/:BillID/:PaymentID/:Type',
        component: CreatereportComponent
      },
      /*users Api  */
      {
        path: 'CreateUser',
        component: CreateUserComponent
      },
      {
        path: 'ViewAllUsers',
        component: ViewAllUsersComponent
      },

      {
        path: 'UpdateUser/:MedicID',
        component: UpdateUserComponent
      },
      /*Health Reports */
      {
        path: 'UploadHelathReports/:AppointmentID',
        component: UploadHelathReportsComponent
      },
      {
        path: 'UploadHelathReports/:MRNumber',
        component: UploadHelathReportsComponent
      },
      {
        path: 'ViewAllHelathReports/:AppointmentID/:MRNumber',
        component: ViewAllHelathReportsComponent
      },
      {
        path: 'ViewReportsbylabtech/:MRNumber/:BillID/:PaymentID',
        component: ViewReportsbylabtechComponent
      },
      {
        path: 'ViewHealthReport/:ReportID',
        component: ViewHealthReportComponent
      },
      /*Patient Billings  */
      {
        path: 'CreatePatientBillings',
        component: CreatePatientBillingsComponent
      },
      {
        path: 'master',
        component: MasterComponent
      },
      {
        path: 'Print2DEchoReport/:ReportID/:MRNumber',
        component: Print2DEchoReportComponent
      },
      {
        path: 'PatientsbyCondition',
        component: PatientsbycategoryComponent
      },
    ]
  },
  { path: pathurl + '/viewallresources', component: ViewallresourcesComponent },
  { path: 'logout', component: LogoutComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
