import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../../services/apicalls.service';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-createrole',
  templateUrl: './createrole.component.html',
  styleUrls: ['./createrole.component.css']
})
export class CreateroleComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private httpclient: HttpClient, private apicalls: ApicallsService, private route: Router, private router: ActivatedRoute) { }
  viewjobtype;
  submitted;
  userDetails
  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data
    this.apicalls.viewJobTypes()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {


          setTimeout(() => {

            this.spinner.hide();
          }, 500);
          this.viewjobtype = data;

        } else {

          this.spinner.hide();

        }
      }, error => {
        error => { alert(" Internal Server Error "); }
        // this.alertService.warning(" Internal Server Error ");
      });
  }

  data: '';
  pathurl: string = 'GokulClinic';
  loginError: string;
  isSubmitted = false;
  error = false;
  onSubmit(roleForm) {
    if (this.roleForm.invalid) {
      this.error = true;
      return;
    }
    this.isSubmitted = true;
    if (this.roleForm.invalid) {
      alert("Please enter all fields")
      return;
    }

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 5000);

    let result = this.apicalls.createRoles(this.roleForm.value)
      .subscribe(data => {
        this.spinner.show();
        this.data = data;
        if (data.type == true) {
          if (data.code == 'S001') {
            this.spinner.hide();
            alert(data.data);
            this.route.navigate(["/dashboardmain/viewallroles"]);
          }
          if (data.code == 'AE01') {
            this.spinner.hide();
            alert(data.data);
          }
          if (data.code == 'S002') {
            this.spinner.hide();
            alert(data.data);
          }
        }
        else {
          this.spinner.hide();
          alert(data.data)
          if (data.code == 'PD01') {
            alert(data.data)
            this.route.navigate(["/dashboardmain/dashboard"]);
          }
        }
      },
        error => { alert(" Internal Server Error "); }
      );

  }
  get f() { return this.roleForm.controls; }

  get Role() {
    return this.roleForm.get('Role');
  }

  get JobType() {
    return this.roleForm.get('JobType');
  }



  roleForm = new FormGroup({
    Role: new FormControl('', [Validators.required, Validators.minLength(2), Validators.pattern('[a-zA-Z ]*')]),
    JobType: new FormControl('', [Validators.required])
  });



  // Resources(){
  //   this.route.navigate(["/GokulClinic/viewallresources"]);
  // }
  // dashboard(){
  //   this.route.navigate(["/dashboard"]);
  // }

  // Resources(){
  //   this.route.navigate(["/viewallresources"]);
  // }

  // Roles(){
  //   this.route.navigate(["/viewallroles"]);
  // }

  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }

}
