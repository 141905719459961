import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private httpclient: HttpClient, private apicalls: ApicallsService, private route: Router, private router: ActivatedRoute) { }

  ngOnInit(): void {
    let data = this.apicalls.getToken();
    if(data ==null ){
     // this.route.navigate(["/logout"]);
    }else{
      this.route.navigate(["/dashboardmain/dashboard"]);
    }
  }
  data: '';
  pathurl: string = 'GokulClinic';
  isSubmitted: boolean = true;
  loginError: string;

  IsLoggined: boolean = true;
  error = false;
  onSubmit(loginForm) {
    if (this.loginForm.invalid) {
      this.error = true;
      return;
    }
    this.isSubmitted = true;
    this.loginForm.value.Type = "employee";

    setTimeout(() => {
      this.spinner.hide();
    }, 5000);

    let result = this.apicalls.userLogin(this.loginForm.value)
      .subscribe(data => {

        this.spinner.show();
        this.data = data;
        //  if (data.type == 'true') {
        if (data.code == 'S001') {
          this.spinner.hide();
          localStorage.setItem('token', JSON.stringify(data));

          let token = data['data'];

          this.route.navigate(["/dashboardmain/dashboard"]);


        } else {

          this.IsLoggined = false;
          this.loginError = data.data;
          this.spinner.hide();
          return false;

        }


      },
        error => { alert(" Internal Server Error "); }
      );

  }



  get UserID() {
    return this.loginForm.get('UserID');
  }

  get Password() {
    return this.loginForm.get('Password');
  }

  loginForm = new FormGroup({
    UserID: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z2-9.-]+\.[a-z]{2,4}$')]),
    Password: new FormControl('', [Validators.required]),
  });

  onSubmitForgotPassword() {
    this.route.navigate(["/forgotpassword"]);
  }

}
