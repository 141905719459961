<body data-gr-c-s-loaded="true">
    <div id="page" class="side-nav-wrapper">

        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pull-right" style="margin-right: 10px;">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                                style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                                <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                                <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                    userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                        class="">Settings</a></li>
                                <li
                                    *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                    <a [routerLink]="" (click)="users()" class="">Users</a></li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>


    <div class="row bg-gray">
        <div id="printdiv">
            <div class="row orr-usercreation ptop30 pbtom30">
                <div class="col-md-3">

                </div>
                <div class="col-md-9">


                    <div class="panel" style="padding:15px; margin-top: 40px; border: 0px;">
                        <div class="row" style="margin-top: 25px">
                            <div class="col-md-5" style="float: left;">
                                <h5 class="text-left"> NAME :<b> {{Appointmentdetails.PatientDetails.Name}} </b></h5>

                            </div>

                            <div class="col-md-3" style="float: left;">
                                <h6 class="text-left">GENDER : {{Appointmentdetails.PatientDetails.Gender}} </h6>
                            </div>

                            <div class="col-md-4" style="float: left;">
                                <h5> DATE : {{Appointmentdetails.Date*1000 |date:"dd-MMM-yyyy"}}</h5>
                            </div>
                        </div>
                        <div class="row">

                            <div class="col-md-2" style="float: left;">
                                <h6 class="text-left"> AGE : {{Appointmentdetails.PatientDetails.Age}}</h6>
                            </div>
                            <div class="col-md-6" style="float: left;">
                                <h6>MR NUMBER : {{Appointmentdetails.MRNumber}}</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="tab_content" style="padding: 0px; display: block;">
                                    <div class="col-md-12">

                                        <div class="ptop0">

                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.RiskFactors && prescirption.RiskFactors.length>0">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h5 class="blue-color">RiskFactors: </h5>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.RiskFactors}} </div>
                                                </div>
                                            </div>

                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.CHEST_PAIN && prescirption.CHEST_PAIN.length > 0">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14"><b>CHEST PAIN:</b> </h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.CHEST_PAIN}} </div>
                                                </div>
                                            </div>
                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.ECG && prescirption.ECG.length>0">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14"><b>ECG:</b> </h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.ECG}} </div>
                                                </div>
                                            </div>

                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.STRESS_TEST && prescirption.STRESS_TEST.length>0">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14"><b>STRESS TEST:</b> </h4>
                                                </label>
                                                <div class="col-lg-10 col-md-8">
                                                    <div class="bs-component">
                                                        {{prescirption.STRESS_TEST}} </div>
                                                </div>
                                            </div>

                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.MITRAL_REGURGITATION">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>VALVE INVOLVEMENT :</b></h4>
                                                </label>


                                            </div>
                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.MITRAL_REGURGITATION">


                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>MITRAL :</b></h4>
                                                </label>

                                            </div>
                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.MITRAL_STENOSIS">

                                                <label for="inputStandard"
                                                    class="control-label top-pad-0 pr10 col-lg-8 col-md-6">
                                                    <h4 class="fs14 "><b>MITRAL STENOSIS :</b>
                                                        {{prescirption.MITRAL_STENOSIS}}</h4>
                                                </label>


                                            </div>
                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.MITRAL_REGURGITATION">

                                                <label for="inputStandard"
                                                    class="control-label top-pad-0 pr10 col-lg-8 col-md-6">
                                                    <h4 class="fs14 "><b>MITRAL REGURGITATION :</b>
                                                        {{prescirption.MITRAL_REGURGITATION}}</h4>
                                                </label>


                                            </div>

                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.AORTIC_REGURGITATION">


                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>AORTIC :</b></h4>
                                                </label>

                                            </div>

                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.AORTIC_REGURGITATION">

                                                <label for="inputStandard"
                                                    class="control-label top-pad-0 pr10 col-lg-8 col-md-6">
                                                    <h4 class="fs14 "><b>AORTIC REGURGITATION :</b>
                                                        {{prescirption.AORTIC_REGURGITATION}}</h4>
                                                </label>


                                            </div>
                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.AORTIC_STENOSIS">

                                                <label for="inputStandard"
                                                    class="control-label top-pad-0 pr10 col-lg-8 col-md-6">
                                                    <h4 class="fs14 "><b>AORTIC STENOSIS :</b>
                                                        {{prescirption.AORTIC_STENOSIS}}</h4>
                                                </label>


                                            </div>

                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.TRICUSPID_REGURGITATION">


                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>TRICUSPID :</b></h4>
                                                </label>

                                            </div>

                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.TRICUSPID_REGURGITATION">

                                                <label for="inputStandard"
                                                    class="control-label top-pad-0 pr10 col-lg-8 col-md-6">
                                                    <h4 class="fs14 "><b>TRICUSPID REGURGITATION :</b>
                                                        {{prescirption.TRICUSPID_REGURGITATION}}</h4>
                                                </label>


                                            </div>
                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.TRICUSPID_STENOSIS">

                                                <label for="inputStandard"
                                                    class="control-label top-pad-0 pr10 col-lg-8 col-md-6">
                                                    <h4 class="fs14 "><b>TRICUSPID STENOSIS :</b>
                                                        {{prescirption.TRICUSPID_STENOSIS}}</h4>
                                                </label>


                                            </div>
                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.TRICUSPID_REGURGITATION">


                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>PULMONARY :</b></h4>
                                                </label>

                                            </div>
                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.PULMONARY_REGURGITATION">

                                                <label for="inputStandard"
                                                    class="control-label top-pad-0 pr10 col-lg-8 col-md-6">
                                                    <h4 class="fs14 "><b>PULMONARY REGURGITATION :</b>
                                                        {{prescirption.PULMONARY_REGURGITATION}}</h4>
                                                </label>


                                            </div>
                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.PULMONARY_STENOSIS">

                                                <label for="inputStandard"
                                                    class="control-label top-pad-0 pr10 col-lg-8 col-md-6">
                                                    <h4 class="fs14 "><b>PULMONARY STENOSIS :</b>
                                                        {{prescirption.PULMONARY_STENOSIS}}</h4>
                                                </label>


                                            </div>


                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.CRHD_SINCE">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>CRHD SINCE: </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.CRHD_SINCE}} </div>
                                                </div>
                                            </div>


                                            <div class="form-group" style="margin: 0px;" *ngIf="prescirption.Class">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14"><b>PRESENTING REASON :</b>
                                                    </h4>
                                                </label>

                                            </div>
                                            <div class="form-group" style="margin: 0px;" *ngIf="prescirption.Class">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h3 class="fs14"><b>Class:</b>
                                                    </h3>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.Class}} </div>
                                                </div>
                                            </div>


                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.PRESENTING_REASON && prescirption.PRESENTING_REASON.length>0">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14"><b>PRESENTING REASON :</b>
                                                    </h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.PRESENTING_REASON}} </div>
                                                </div>

                                            </div>


                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.DIAGNOSED_IN">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14">DIAGNOSED IN: </h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.DIAGNOSED_IN}} </div>
                                                </div>
                                            </div>

                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.CREATININE_GFR">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14"><b>Family History:</b> </h4>
                                                </label>

                                            </div>

                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.CREATININE_GFR">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14">CREATININE, GFR</h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.CREATININE_GFR}} </div>
                                                </div>
                                            </div>

                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.SYMTOM_STATUS">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14"><b>SYMTOM STATUS</b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.SYMTOM_STATUS}} </div>
                                                </div>
                                            </div>



                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.APCLASS && prescirption.APCLASS.length>0">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h5 class="blue-color">&nbsp; </h5>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        1{{prescirption.APCLASS}} </div>
                                                </div>
                                            </div>
                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.AdvicePostCAG && prescirption.AdvicePostCAG.length>0">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h5 class="fs14"><b>ADVICE POST CAG :</b>
                                                    </h5>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.AdvicePostCAG}} </div>
                                                </div>
                                            </div>
                                            <div class="form-group" style="margin: 0px;" *ngIf="prescirption.PostCABG">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>PostCABG: </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.PostCABG}} {{prescirption.PostCABGText}}</div>
                                                </div>
                                            </div>
                                            <div class="form-group" style="margin: 0px;" *ngIf="prescirption.PostPTCA">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>POST PTCA : </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.PostPTCA}} {{prescirption.PostPTCAText}}</div>
                                                </div>
                                            </div>
                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.HeartFailure && prescirption.HeartFailure.length>0">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h5 class="blue-color">&nbsp; </h5>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.HeartFailure}} </div>
                                                </div>
                                            </div>
                                            <div class="form-group" style="margin: 0px;" *ngIf="prescirption.NYHA && prescirption.NYHA.length>0">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h5 class="blue-color">&nbsp; </h5>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.NYHA}} </div>
                                                </div>
                                            </div>
                                            <div class="form-group" style="margin: 0px;" *ngIf="prescirption.Stage && prescirption.Stage.length>0">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h5 class="blue-color">&nbsp; </h5>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.Stage}} </div>
                                                </div>
                                            </div>
                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.DIAGNOSIS && prescirption.DIAGNOSIS.length>0">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14"><b>DIAGNOSIS</b> </h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.DIAGNOSIS}} </div>
                                                </div>
                                            </div>
                                            <div class="form-group" style="margin: 0px;" *ngIf="prescirption.EF">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14"><b>ECHO</b> </h4>
                                                </label>

                                            </div>
                                            <div class="form-group" style="margin: 0px;" *ngIf="prescirption.EF">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14"><b>EF</b> </h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.EF}} </div>
                                                </div>

                                            </div>
                                            <div class="form-group" style="margin: 0px;" *ngIf="prescirption.PASP">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14"><b>PASP</b> </h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.PASP}} </div>
                                                </div>

                                            </div>
                                            <div class="form-group" style="margin: 0px;" *ngIf="prescirption.PAH">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14"><b>PAH</b> </h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.PAH}} </div>
                                                </div>

                                            </div>
                                            <div class="form-group" style="margin: 0px;" *ngIf="prescirption.LVEF">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14"><b>LVEF</b> </h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.LVEF}} </div>
                                                </div>

                                            </div>
                                            <div class="form-group" style="margin: 0px;" *ngIf="prescirption.LVEDD">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14"><b>LVEDD</b> </h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.LVEDD}} </div>
                                                </div>

                                            </div>
                                            <div class="form-group" style="margin: 0px;" *ngIf="prescirption.LVESD">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14"><b>LVESD</b> </h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.LVESD}} </div>
                                                </div>

                                            </div>

                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.RV_FUNCTION">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14"><b>RV FUNCTION</b> </h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.RV_FUNCTION}} </div>
                                                </div>

                                            </div>

                                            <div class="form-group" style="margin: 0px;" *ngIf="prescirption.NYHS">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14"><b>NYHS</b> </h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.NYHS}} </div>
                                                </div>

                                            </div>



                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.CAG && prescirption.CAG.length>0">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>CAG: </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.CAG}} </div>
                                                </div>
                                            </div>

                                            <div class="form-group" style="margin: 0px;" *ngIf="prescirption.DM_Since">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>DM Since: </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.DM_Since}} </div>
                                                </div>
                                            </div>
                                            <div class="form-group" style="margin: 0px;" *ngIf="prescirption.HTN_Since">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>HTN Since: </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.HTN_Since}} </div>
                                                </div>
                                            </div>

                                            <div class="form-group" style="margin: 0px;" *ngIf="prescirption.HLP_SINCE">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>HLP Since: </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.HLP_SINCE}} </div>
                                                </div>
                                            </div>
                                            <div class="form-group" style="margin: 0px;" *ngIf="prescirption.TOTAL_CH">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>Total CH: </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.TOTAL_CH}} </div>
                                                </div>
                                            </div>
                                            <div class="form-group" style="margin: 0px;" *ngIf="prescirption.LDL_C">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>LDL C: </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.LDL_C}} </div>
                                                </div>
                                            </div>
                                            <div class="form-group" style="margin: 0px;" *ngIf="prescirption.TG">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>TG: </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.TG}} </div>
                                                </div>
                                            </div>
                                            <div class="form-group" style="margin: 0px;" *ngIf="prescirption.HDL_C">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>HDL C: </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.HDL_C}} </div>
                                                </div>
                                            </div>
                                            <div class="form-group" style="margin: 0px;" *ngIf="prescirption.LPA">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>LP(A): </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.LPA}} </div>
                                                </div>
                                            </div>
                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.SUBFRACTION_OF_LDL">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>SUBFRACTION OF LDL: </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.SUBFRACTION_OF_LDL}} </div>
                                                </div>
                                            </div>
                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.ANY_EXT_SIGNS">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>ANY EXT SIGNS: </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.ANY_EXT_SIGNS}} </div>
                                                </div>
                                            </div>
                                            <div class="form-group" style="margin: 0px;" *ngIf="prescirption.Kidney">


                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>TARGET ORGAN INVOLVEMENT :
                                                        </b></h4>
                                                </label>

                                            </div>
                                            <div class="form-group" style="margin: 0px;" *ngIf="prescirption.BGH">


                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>BGH :
                                                        </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.BGH}} </div>
                                                </div>

                                            </div>

                                            <div class="form-group" style="margin: 0px;" *ngIf="prescirption.BMI">


                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>BMI :
                                                        </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.BMI}} </div>
                                                </div>

                                            </div>
                                            <div class="form-group" style="margin: 0px;" *ngIf="prescirption.Kidney">


                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h5 class="fs14 "><b>KIDNEY :
                                                        </b></h5>
                                                </label>

                                            </div>
                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.Kidney && prescirption.Kidney.PR_CR">


                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>PROTEINURIA, 24HOURS/SPOT PR:CR:
                                                        </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.Kidney.PR_CR}} </div>
                                                </div>
                                            </div>
                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.Kidney && prescirption.Kidney.CREATININE_GFR">


                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>CREATININE GFR
                                                        </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.Kidney.CREATININE_GFR}} </div>
                                                </div>
                                            </div>
                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.Kidney && prescirption.Kidney.CKD">


                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>CKD
                                                        </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.Kidney.CKD}} </div>
                                                </div>
                                            </div>

                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.CVS && prescirption.CVS.LVH_ECG">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>CVS:
                                                        </b></h4>
                                                </label>

                                            </div>
                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.CVS && prescirption.CVS.LVH_ECG">


                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>LVH-ECG:
                                                        </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.CVS.LVH_ECG}} </div>
                                                </div>
                                            </div>

                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.CVS && prescirption.CVS.LV_MASS">


                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>LV MASS:
                                                        </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.CVS.LV_MASS}} </div>
                                                </div>
                                            </div>
                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.CVS && prescirption.CVS.LVEF">


                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>LVEF:
                                                        </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        {{prescirption.CVS.LVEF}} </div>
                                                </div>
                                            </div>
                                            <div class="form-group" style="margin: 0px;" *ngIf="prescirption.ECHO">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>ECHO: </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component multi_lines_text">
                                                        {{prescirption.ECHO}} </div>
                                                </div>
                                            </div>
                                            <div class="form-group" style="margin: 0px;"
                                                *ngIf="prescirption.OTHER_DATA">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>OTHER DATA: </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component multi_lines_text">
                                                        {{prescirption.OTHER_DATA}} </div>
                                                </div>
                                            </div>

                                            <div class="form-group" style="margin: 0px;" *ngIf="prescirption.BP">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>BP: </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component multi_lines_text">
                                                        {{prescirption.BP}} </div>
                                                </div>
                                            </div>
                                            <div class="form-group" style="margin-top: 0px;" *ngIf="prescirption.PR">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 "><b>PR : </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component multi_lines_text">
                                                        {{prescirption.PR}} </div>
                                                </div>
                                            </div>


                                            <div class="form-group" style="margin-top: 0px;" *ngIf="prescirption.BGH">

                                                <label for="inputStandard" class="control-label top-pad-0">
                                                    <h4 class="fs14 "><b>BGH : </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component multi_lines_text">
                                                        {{prescirption.BGH}} </div>
                                                </div>
                                            </div>
                                            <div class="form-group" style="margin-top: 0px;" *ngIf="prescirption.Notes">

                                                <label for="inputStandard" class="control-label top-pad-0">
                                                    <h4 class="fs14 "><b>NOTES:</b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component multi_lines_text">
                                                        {{prescirption.Notes}}
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- *ngIf="Appointmentdetails.Type != ('HTN_NO_CAD_CHF' && 'DM_NO_CAD_CHF' && 'CRHD')" -->
                                            <!-- <div *ngIf="Next =='true'" class="page-break-before" style="page-break-before: always;">
                                                

                                            </div> -->
                                            <div class="form-group" style="margin-top: 5px;"
                                                *ngIf="prescirption.Investigations">

                                                <label for="inputStandard" class="control-label top-pad-0">
                                                    <h4 class="fs14 "><b>INVESTIGATIONS : </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component multi_lines_text">
                                                        {{prescirption.Investigations}} </div>
                                                </div>
                                            </div>

                                            <div class="form-group" style="margin-top: 5px;"
                                                *ngIf="prescirption.Diagnosis">

                                                <label for="inputStandard" class="control-label top-pad-0">
                                                    <h4 class="fs14 "><b>Diagnosis: </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component multi_lines_text">
                                                        {{prescirption.Diagnosis}} </div>
                                                </div>
                                            </div>
                                            <div class="form-group" style="margin-top: 5px;"
                                                *ngIf="prescirption.Prescription">

                                                <label for="inputStandard" class="control-label top-pad-0">
                                                    <h4 class="fs14 "><b>PRESCRIPTION: </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        <b class="multi_lines_text">{{prescirption.Prescription}}</b>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group" style="margin-top: 5px;" *ngIf="prescirption.Diet">

                                                <label for="inputStandard" class="control-label top-pad-0">
                                                    <h4 class="fs14 "><b>Diet : </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component multi_lines_text">
                                                        {{prescirption.Diet}} </div>
                                                </div>
                                            </div>
                                            <div class="form-group" style="margin-top: 5px;"
                                                *ngIf="prescirption.FollowUp">

                                                <label for="inputStandard" class="control-label top-pad-0">
                                                    <h4 class="fs14 "><b>FollowUp : </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component multi_lines_text">
                                                        {{prescirption.FollowUp}} </div>
                                                </div>
                                            </div>
                                            <div class="form-group" style="margin-top: 5px;"
                                                *ngIf="Appointmentdetails.FollowUpDate">

                                                <label for="inputStandard" class="control-label top-pad-0">
                                                    <h4 class="fs14 "><b>FollowUpDate : </b></h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component multi_lines_text">
                                                        {{Appointmentdetails.FollowUpDate *1000 |date:"dd-MMM-yyyy"}}
                                                    </div>
                                                </div>
                                            </div> 

                                            <div class="form-group pull-right" style="margin-top: 5px;">


                                                <div class="col-lg-12 col-md-12">
                                                    <div class="bs-component">
                                                        <b>{{Appointmentdetails.DoctorDetails.FirstName}}
                                                            {{Appointmentdetails.DoctorDetails.LastName}}</b> </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <!-- #tab10 -->

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="row bg-gray" style="text-align: center;">

            <button (click)="printPage('printdiv')" class="btn btn-default print upper submitBtn"
                style="background: #367fa9;">Print </button>

        </div>
    </div>

</body>

<style type="text/css">
    @media print {


        #get-quote,
        .upper {
            display: none;
        }
    }
</style>