import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
// import * as moment  from 'moment';


@Component({
  selector: 'app-viewallhospitals',
  templateUrl: './viewallhospitals.component.html',
  styleUrls: ['./viewallhospitals.component.css']
})
export class ViewallhospitalsComponent implements OnInit {
  // today=new Date();
  constructor(private spinner: NgxSpinnerService, private apicalls: ApicallsService, private router: ActivatedRoute, private route: Router, private httpClient: HttpClient) { }

  viewallhospitals;

  userDetails;
  ngOnInit() {
    // console.log("todayyyyyy",this.today)
    // let x=moment(this.today).format("DD-MM-YYYY");
    // console.log("DATEEEEEEEEEEEEE",x);

    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data

    this.apicalls.viewAllHospitals()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {


          setTimeout(() => {

            this.spinner.hide();
          }, 500);
          this.viewallhospitals = data;
          console.log("DATAAAAAAAAAAAAAAA", this.viewallhospitals);
          //  this.dtTrigger.next();

        } else {

          //  this.alertService.info(data.message)
          //   this.spinner.hide();
          this.spinner.hide();

          if (data.code == 'PD01') {
            alert(data.data)
            this.route.navigate(["/dashboardmain/dashboard"]);

          }
          else {
            alert(data.data)
          }

        }
      }, error => {
        error => { alert(" Internal Server Error "); }
        // this.alertService.warning(" Internal Server Error ");
      });

  }
  // onSubmitCreateBatch(){
  //   this.route.navigate(["/dashboard/batch_create"]);
  // }


  // onSubmitUpdate(list){
  // //  alert(" Edit batch option will be included soon..........")
  //  //  console.log("((((((((((",list);
  //    this.route.navigate(["/dashboard/view_batchbyid",list.batch_id,list.programme_id]);

  //  }


  onClickCreate() {
    this.route.navigate(["/dashboardmain/createhospitals"]);
  }

  // dashboard(){
  //   this.route.navigate(["/dashboard"]);
  // }

  // Resources(){
  //   this.route.navigate(["/viewallresources"]);
  // }

  // Roles(){
  //   this.route.navigate(["/viewallroles"]);
  // }

  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }

  editdata(HospitalID) {

    this.route.navigate(["/dashboardmain/updatehospitals", HospitalID]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }
}


