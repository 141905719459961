import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reach-us',
  templateUrl: './reach-us.component.html',
  styleUrls: ['./reach-us.component.css']
})
export class ReachUsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  openlist;
  openlist1;
  over(){
    this.openlist="open"
  }

  out(){
    this.openlist="";
  }
  over1(){
    this.openlist1="open"
  }

  out1(){
    this.openlist1="";
  }

}
