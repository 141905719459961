import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService,private httpclient: HttpClient, private apicalls: ApicallsService, private route: Router, private router: ActivatedRoute) { }

  data: '';
  isSubmitted: boolean = true;
  forgotpasswordError: string;
  loginError: string;
  
  IsLoggined:boolean = true;
  error = false;


  ngOnInit(): void {
    let data = this.apicalls.getToken();
    if(data ==null ){
     // this.route.navigate(["/logout"]);
    }else{
      this.route.navigate(["/dashboardmain/dashboard"]);
    }
  }

  get UserID() {
    return this.forgotpasswordForm.get('UserID');
  }


  forgotpasswordForm = new FormGroup({

    UserID: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z2-9.-]+\.[a-z]{2,4}$')])

  });

  onSubmit(forgotpasswordForm) {
    if (this.forgotpasswordForm.invalid) {
      this.error = true;
      return;
    }
    this.isSubmitted = true;
    this.spinner.show();
    let result = this.apicalls.forgetPassword(this.forgotpasswordForm.value)
      .subscribe(data => {
        this.data = data;
        if (data.type == true) {
          if (data.code == 'L005') {
            this.spinner.hide();
            this.data = data;
            alert(data.data)
            this.route.navigate(["/login"]);
          }else if(data.code == 'S002'){
            this.IsLoggined=false;
            this.loginError = data.data;
            this.spinner.hide();
            return false;
          }
        }
        else {
          alert(data.data);
          this.IsLoggined=false;
          this.loginError = data.data;
          this.spinner.hide();
          return false;
          alert(data.data);
        }
      },
        error => { alert(" Internal Server Error "); }
      );

  }


}
