<body data-gr-c-s-loaded="true">
    <div id="page" class="side-nav-wrapper">
        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pull-right" style="margin-right: 10px;">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                                style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                                <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                                <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                                userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]=""
                                        (click)="Settings()" class="">Settings</a></li>
                                <li
                                    *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                    <a [routerLink]="" (click)="users()" class="">Users</a></li>

                            </ul>
                        </div>

                    </div>

                </div>
            </div>


            <div class="container bg-gray">
                <div class="row">
                    <div class="col-md-12">
                        <h5 class="dr-sub-head">Create Channel Partner</h5>
                    </div>
                </div>
                <div class="row orr-usercreation ptop30 pbtom30">

                    <div class="col-md-12">
                        <div class="panel">


                            <div class="tab-table-customer-entry"
                                style="margin:30px 0;background:#fff;padding:10px 0;border-top:1px solid #ddd;border-bottom:1px solid #ddd;">
                                <div class="text-center fs20" *ngIf="channelError" [hidden]='isSubmitted'
                                    style="background-color:white;color:red">{{channelError}}</div>

                                <!-- <form action="http://drgokulreddy.in/Home/add_user" onsubmit="return emp_entry()" method="post" enctype="multipart/form-data"> -->
                                <form id="contact-form" class="contact-form pad-40" autocomplete="off"
                                    [formGroup]="channelpartnerForm">
                                    <div class="form-pad-label" style="padding-top:30px;">

                                        <!-- <div class="col-md-6">
                            <div class="input-text form-group has-feedback">
                                <label style="width:100px;float:left;text-align:right;padding-right:15px;">USER NAME<span class="required"> * </span></label>
                                <input type="text" name="email" id="email" class="input-name form-control" placeholder=" User Name " style="float:left;width:70%;">
                            </div>
                            <span class="error" id="email_error"></span>
                        </div> -->


                                        <!-- <div style="clear:both;"></div> -->

                                        <div class="col-md-8">
                                            <div class="input-text form-group has-feedback">
                                                <label
                                                    style="width:180px;float:left;text-align:right;padding-right:15px;">Name<span
                                                        class="required"> * </span></label>
                                                <input [class.is-invalid]="Name.invalid && (Name.dirty || Name.touched)"
                                                    class="form-control" id="Name" type="Name" name="Name"
                                                    placeholder=" ChannelPartner Name " formControlName="Name">
                                                <div *ngIf="Name.invalid  && (Name.dirty || Name.touched)">
                                                    <small *ngIf="Name.errors?.required" class="text-danger">Channel
                                                        Partner Name is
                                                        required</small>
                                                    <small *ngIf="Name.errors?.minlength" class="text-danger">Channel
                                                        Partner Name should be minimum 3 characters
                                                    </small>
                                                    <small *ngIf="Name.errors?.pattern" class="text-danger">Channel
                                                        Partner Name should be alphabets
                                                    </small>

                                                </div>
                                                <!-- <input type="text" name="Resource" id="Resource" class="input-name form-control" placeholder=" Resource Name " style="float:left;width:70%;"> -->
                                            </div>
                                            <!-- <span class="error" id="email_error"></span> -->
                                        </div>
                                        <div style="clear:both;"></div>
                                        <div class="col-md-8">
                                            <div class="input-text form-group has-feedback">
                                                <label
                                                    style="width:180px;float:left;text-align:right;padding-right:15px;">Email-ID<span
                                                        class="required"> * </span></label>
                                                <input
                                                    [class.is-invalid]="EmailID.invalid && (EmailID.dirty || EmailID.touched)"
                                                    class="form-control" id="EmailID" type="EmailID" name="EmailID"
                                                    placeholder=" Email-ID " formControlName="EmailID">
                                                <div *ngIf="EmailID.invalid  && (EmailID.dirty || EmailID.touched)">
                                                    <small *ngIf="EmailID.errors?.required" class="text-danger">EmailID
                                                        is
                                                        required</small>
                                                    <small *ngIf="EmailID.errors?.pattern" class="text-danger">Please
                                                        enter valid EmailID </small>

                                                </div>
                                                <!-- <input type="text" name="Resource" id="Resource" class="input-name form-control" placeholder=" Resource Name " style="float:left;width:70%;"> -->
                                            </div>
                                            <!-- <span class="error" id="email_error"></span> -->
                                        </div>
                                        <div style="clear:both;"></div>
                                        <div class="col-md-8">
                                            <div class="input-text form-group has-feedback">
                                                <label
                                                    style="width:180px;float:left;text-align:right;padding-right:15px;">Phone
                                                    Number<span class="required"> * </span></label>
                                                <input
                                                    [class.is-invalid]="PhoneNumber.invalid && (PhoneNumber.dirty || PhoneNumber.touched)"
                                                    class="form-control" id="PhoneNumber" type="PhoneNumber"
                                                    name="PhoneNumber" placeholder=" Phone Number "
                                                    formControlName="PhoneNumber">
                                                <!-- <div *ngIf="submitted && f.mobile_number.errors" class="text-danger">
                                                                <small *ngIf="f.mobile_number.errors.required">Mobile Number is required</small>
                                                                <small *ngIf="f.mobile_number.errors?.minlength" >Enter valid number(min 10)</small>
                                                                <small *ngIf="f.mobile_number.errors?.maxlength" >Enter valid number(min 10)</small>


                                                                <small *ngIf="f.mobile_number.errors?.pattern" >Enter valid mobile number</small>
                                                            </div> -->
                                                <div
                                                    *ngIf="PhoneNumber.invalid  && (PhoneNumber.dirty || PhoneNumber.touched)">
                                                    <small *ngIf="PhoneNumber.errors?.required"
                                                        class="text-danger">Phone Number is
                                                        required</small>
                                                    <small *ngIf="PhoneNumber.errors?.pattern"
                                                        class="text-danger">Please enter valid Phone Number</small>
                                                    <small *ngIf="PhoneNumber.errors?.minlength"
                                                        class="text-danger">Phone Number should be 10 digits</small>
                                                    <small *ngIf="PhoneNumber.errors?.maxlength"
                                                        class="text-danger">Phone Number should be 10 digits</small>

                                                </div>
                                                <!-- <input type="text" name="Resource" id="Resource" class="input-name form-control" placeholder=" Resource Name " style="float:left;width:70%;"> -->
                                            </div>
                                            <!-- <span class="error" id="email_error"></span> -->
                                        </div>
                                        <div style="clear:both;"></div>
                                        <!-- <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label style="width:100px;float:left;text-align:right;padding-right:15px;">AliasName<span class="required"> * </span></label>
                                            <textarea
                                            [class.is-invalid]="AliasName.invalid && (AliasName.dirty || AliasName.touched)"
                                            class="form-control" id="AliasName" type="AliasName" name="AliasName"
                                            placeholder=" AliasName " formControlName="AliasName"></textarea>
                                            <div *ngIf="AliasName.invalid  && (AliasName.dirty || AliasName.touched)">
                                                    <small *ngIf="AliasName.errors?.required" class="text-danger">AliasName is
                                                        required</small>
                                                        <small *ngIf="AliasName.errors?.pattern" class="text-danger">AliasName should me minimum 10 characters
                                                            </small>
                            
                                                </div>
                                        </div>
                                    </div> -->
                                        <!-- <div style="clear:both;"></div> -->

                                        <div
                                            style="margin:auto;text-align:center;margin-bottom:10px;margin-top:30px;margin-bottom:30px;">
                                            <a [routerLink]="" (click)="onSubmit(channelpartnerForm)"> <input
                                                    type="submit" class="btn-pink" value="CREATE"></a>

                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>

                </div>
            </div>

            <script>

                var base_url = 'http://drgokulreddy.in/';
                function emp_entry() {

                    var flag = 0;
                    var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
                    var alpha = /^[a-zA-Z\s]+$/;
                    var email = $("#email").val();
                    var regex = /^[0-9]*(?:\.\d{1,2})?$/;

                    if ($("#name").val() == '') {
                        $("#name_error").html('Enter Name');
                        flag = 1;
                    }
                    else {
                        if (!alpha.test($("#name").val())) {
                            $("#name_error").html('Enter only Alphabets');
                            flag = 1;
                        }
                        else {
                            $("#name_error").html('');
                        }

                    }
                    if ($("#email").val() == '') {
                        $("#email_error").html('Enter email');
                        flag = 1;
                    }
                    else if (!pattern.test($("#email").val())) {
                        $("#email_error").html('Enter valid  Email');
                        flag = 1;
                    }
                    else {
                        $("#email_error").html('');
                    }





                    if ($("#pass").val() == '') {
                        $("#pass_error").html('Enter Password');
                        flag = 1;
                    }
                    else {
                        $("#pass_error").html('');

                    }


                    if ($("#utype").val() == '') {
                        $("#utype_error").html('Select User Type');
                        flag = 1;
                    }
                    else {
                        $("#utype_error").html('');

                    }
                    if (flag) {
                        $(".error").each(function () {
                            if ($(this).html().length > 0) {

                                var str = $(this).attr('id');
                                var res = str.split("_");
                                $("#" + res[0]).focus();
                                return false;
                            }

                        })
                        return false;
                    }

                }


            </script>
            <script src="./GOKUL CLINIC - Add User page_files/jquery.dataTables.min.js.download"></script>
            <script src="./GOKUL CLINIC - Add User page_files/dataTables.bootstrap.min.js.download"></script>
            <style type="text/css">
                @media print {


                    #get-quote,
                    .upper {
                        display: none;
                    }
                }
            </style>
            <script type="text/javascript">
                $('.print').click(function () {
                    window.print();
                    //printDiv();

                });
                function printDiv() {

                    var divToPrint = document.getElementById('divtoprint');

                    var newWin = window.open('', 'Print-Window');

                    newWin.document.open();

                    newWin.document.write('<html><body onload="window.print()">' + divToPrint.innerHTML + '</body></html>');

                    newWin.document.close();

                    setTimeout(function () { newWin.close(); }, 10);

                }
                $(document).ready(function () {
                    $('#example').DataTable();
                });
                $(document).ready(function () {
                    $(".alert").fadeTo(2000, 500).slideUp(500, function () {
                        $(".alert").slideUp(500);
                    });
                });
            </script>
        </div>
        <script src="./GOKUL CLINIC - Add User page_files/tabs.js.download"></script>

    </div>



</body>
<ngx-spinner></ngx-spinner>