<app-header></app-header>
<nav class="navbar navbar-inverse">
    <div>
        <div style="position:relative;">
            <div>
                <div class="collapse navbar-collapse" id="myNavbar" style="float:left;padding:0px;clear:both;width:100%;background:#2d3e50;">
                    <div>
                        <ul class="nav navbar-nav ag-menu sm pl40" style="float:left;" data-smartmenus-id="14811307330159312">
                            <li class=""><a href="">HOME</a>
                            </li>
                            <li class="{{openlist}}"  (mouseover)='over()' (mouseout)='out()'><a href="" class="has-submenu">MYPRACTICE  </a>
                                <ul class="dropdown-menu sm-nowrap" >
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Dr Gokul reddy</a>
                                                    </div>
                                                    <div>
                                                        <a class="" routerLink="/practice-locations"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>Practice Locations</a>
                                                    </div>
                                                    <!-- <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Book  appointment</a>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                            </li>
                            <li class="{{openlist1}}" (mouseover)='over1()' (mouseout)='out1()'><a class="has-submenu" href="#" >PATIENT INFO </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/about-cholesterol"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>ABOUT CHOLESTEROL</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/angioplasty"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> ANGIOPLASTY</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/chestpain"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CHESTPAIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/common-misconceptions"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>COMMON MISCONCEPTIONS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/coronary-angiogram"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CORONARY ANGIOGRAM</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/exercsie-and-heart-health"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> EXERCSIE & HEART HEALTH</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/guide-to-taking-warfarin"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> GUIDE TO TAKING WARFARIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-attack"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTATTACK</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-fauilre"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTFAUILRE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/high-blood-pressure"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HIGH BLOOD PRESSURE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/htn-myths"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>HTN-MYTHS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/tips-to-lower"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>TIPS  TO LOWER CHOLESTEROL   LEVELS</a> </div>



                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li><a href="#">SERVICES</a></li>
                            <li><a href="#">APPOINTMENTS</a></li>
                            <li class=""><a  href="faq">FAQS </a></li>
                            <li class=""><a  href="blog">MY BLOG</a></li>

                            <li class=""> <a  href="reach-us">REACH US</a></li>

                            <div class="clearfix"></div>
                        </ul>
                        <div class="pull-right pn">
                            <a href="#"><img src="/../../assets/front/img/askdemo.png" style="height:52px;"/></a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</nav>
<section style="background:#fff;">
    <div class="container">
        <div  style="padding:30px 15px;">

            <div class="row">

                <h4 class="mb5" style="color: #0991cd;font-weight: bold;">ABOUT CHOLESTEROL</h4>

                <!--<p class="pull-right"><img src="img/gokulreddy.png" style="height:350px;"/></p>-->



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">What is cholesterol and where does it come from?</h5>

                <p>Cholesterol comes from two sources: your body and food. Your body, and especially your liver, makes all the cholesterol you need and circulates it through the blood. But cholesterol is also found in foods from animal sources, such as meat, poultry and full-fat dairy products. Your liver produces more cholesterol when you eat a diet high in saturated and trans fats.

                </p>



                <p>

                    Excess cholesterol can form plaque between layers of artery walls, making it harder for your heart to circulate blood. Plaque can break open and cause blood clots. If it blocks an artery that feeds the heart, it causes a heart attack.

                </p>

                <p>

                    There are two types of cholesterol: "good" and "bad." Too much of one type — or not enough of another — can put you at risk for coronary heart disease, heart attack or stroke. It's important to know the levels of cholesterol in your blood so that you and your doctor can determine the best strategy to lower your risk.

                </p>

                <p>

                    Making healthy eating choices and increasing exercise are important first steps in improving your cholesterol. For some people, cholesterol-lowering medication may also be needed to reduce the risk for heart attack and stroke

                </p>

                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">What Can Cholesterol Do?</h5>



                <p>High cholesterol is one of the major controllable risk factors for coronary heart disease, heart attack and stroke</p>



                <p>As your blood cholesterol rises, so does your risk of coronary heart disease. If you have other risk factors such as smoking, high blood pressure , diabetes or a strong Family history , this risk increases even further. The greater the level of each risk factor, the more that factor affects your overall risk.</p>



                <p>When too much LDL (bad) cholesterol circulates in the blood, it can slowly build up in the inner walls of the arteries that feed the heart and brain. Together with other substances, cholesterol can form a thick, hard deposit called plaque that can narrow the arteries and make them less flexible. This condition is known as atherosclerosis.</p>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">How are women different in cholesterol levels?</h5>



                <p>The female sex hormone ( estrogen) tends to raise HDL cholesterol, and as a rule, women have higher HDL (good) cholesterol levels than men. Estrogen production is highest during the childbearing years. This may help explain why premenopausal women are usually protected from developing heart disease. ,Older women tend to have higher triglyceride levels. As people get older, gain weight or both, their triglyceride and cholesterol levels tend to rise.</p>







                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Any Symptoms?</h5>



                <p>Many people do not know their cholesterol is too high because there are usually no symptoms. That's why it is important to have your cholesterol levels checked by your doctor.</p>







                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">How often should I have my cholesterol checked?</h5>



                <p>If you are age 20 or older and have not been diagnosed with cardiovascular disease, the American Heart Association recommends having your cholesterol levels checked every four to six years as part of a cardiovascular risk assessment.</p>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Eat a heart-healthy diet</h5>



                <p>To lower cholesterol, eat a dietary pattern that emphasizes fruits, vegetables, whole grains, low-fat dairy products, poultry, fish and nuts. You should also limit red meat and sugary foods and beverages.</p>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Get moving</h5>



                <p>Being physically active is also important to prevent heart disease and stroke. Just 40 minutes of aerobic exercise of moderate to vigorous intensity done three to four times a week is enough to lower both cholesterol and high blood pressure. Brisk walking, swimming, bicycling or a dance class are examples.</p>











            </div>



        </div>
  </div>
</section>
<app-footer></app-footer>
