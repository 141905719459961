<!DOCTYPE html>
<!-- saved from url=(0031)http://drgokulreddy.in/patients -->
<html lang="en">

<body>
    <div id="page" class="side-nav-wrapper">
        <!-- Simple Navbar -->
        <!-- Simple Navbar -->
        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pull-right" style="margin-right: 10px;">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                                style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                                <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                                <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                    userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                        class="">Settings</a></li>
                                <li
                                    *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                    <a [routerLink]="" (click)="users()" class="">Users</a></li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>


        <div class="container bg-gray">
            <div class="row">
                <div class="col-md-4">
                    <h5 class="dr-sub-head">Hospitals</h5>
                </div>
            </div>
            <!-- <div class="col-md-4"> -->
            <div class="btn-signin">
                <a [routerLink]="" (click)="onClickCreate()">
                    Create </a>
            </div>
            <!-- </div> -->

            <!-- <form method="post" action="http://drgokulreddy.in/Home/get_databyhospital">
        <div class="col-md-4">
            <select class="way-custom-dropdown price-text-field profile-mwidth" name="hos" id="hos">
                <option value="">Select Hospital</option>
                <option value="1"> EVENING CLINIC (Medquest Diagnostics )</option>
                <option value="2">ADITYA Hospitals, Uppal </option>
                <option value="3"> PRATIMA Hospitals</option>
            </select>
            <span class="error" id="hos_error"></span>
        </div>
        <div class="col-md-4">
            <input type="submit" class="btn btn-success" value="Submit" onsubmit="return get_hospital()">
        </div></form> -->
            <!-- </div> -->
            <div class="row orr-usercreation ptop30 pbtom30">

                <div class="col-md-12">


                    <div class="panel">


                        <table cellspacing="0" cellpadding="0" width="100%" class="mirchi-birthday-grid" id="example">
                            <thead>
                                <tr>

                                    <th style="text-align:center;"> S.No</th>
                                    <!-- <th style="text-align:center;"> Hospital ID </th> -->
                                    <th style="text-align:center;"> Name</th>

                                    <th style="text-align:center;"> Phone Number</th>
                                    <th style="text-align:center;"> Alternate PhoneNumber</th>
                                    <th style="text-align:center;"> Branch </th>

                                    <th style="text-align:center;"> Status</th>
                                    <th style="text-align:center;"> Edit</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <tr *ngFor="let list of viewallhospitals?.data; let i=index ">
                                    <td style="text-align:center;">{{i + 1}}</td>

                                    <!-- <td style="text-align:center;">{{list.HospitalID}}</td> -->
                                    <td style="text-align:center;">{{list.HospitalName}}</td>


                                    <td style="text-align:center;">{{list.ContactNumber}}</td>
                                    <td style="text-align:center;">{{list.SecondryContactNumber}}</td>


                                    <!-- <td style="text-align:center;">{{list.Address[0].StreetAddress}}</td> -->
                                    <td style="text-align:center;">{{list.HospitalBranch}}</td>


                                    <td style="text-align:center;">{{list.Status}}</td>
                                    <td>

                                        <a [routerLink]="" (click)="editdata(list.HospitalID)"><img
                                                src="../assets/images/edit.png"></a>
                                    </td>
                                    <!-- <td style="text-align:center;"> -->

                                    <!-- <a href="http://drgokulreddy.in/edit-patient/1730"><img src="./GOKUL CLINIC - Patients List page_files/edit.png"></a>
                                                <a href="http://drgokulreddy.in/Home/delete_patient/1730"><img src="./GOKUL CLINIC - Patients List page_files/delete.png"></a>                         <a href="http://drgokulreddy.in/Home/add_app/1730"><img src="./GOKUL CLINIC - Patients List page_files/appointment.png"></a> -->
                                    <!-- </td> -->

                                    <!-- <td style="text-align:center;"> </td> -->


                                </tr>
                                <tr>


                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>

</html>
<ngx-spinner></ngx-spinner>