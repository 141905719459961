import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

import { Login } from '../models/login';
import { Changepassword } from '../models/changepassword';
import { Resources } from '../models/resources';
import { Roles } from '../models/roles';
import { Privileges } from '../models/privileges';
import { Channelpartners } from '../models/channelpartners';
import { Permissions } from '../models/permissions';
import { Hospitals } from '../models/hospitals';
import { Doctors } from '../models/doctors';
import { Patients } from '../models/patients';
import { Appointments } from '../models/appointments';
import { BillingItem } from '../models/BillingsItem';
import { Payments } from '../models/Payments';
import { RevenueData } from '../models/RevenueData';
import { Twodeechoreport } from '../models/Twodeechoreport'
import { Users } from '../models/Users'
import { Reports } from '../models/Reports'


@Injectable(
  {
    providedIn: 'root'
  }
)
export class ApicallsService {


  
  //apiURL: string = "http://192.168.1.85:3000";

  apiURL: string = "http://15.207.250.248:3000";

  constructor(private httpclient: HttpClient, private route: Router) { }


  // login api of user

  userLogin(login: Login): Observable<any> {

    let result = this.httpclient.post(`${this.apiURL}/login`, login);
    return result;
  }


  getToken() {

    let s = localStorage.getItem('token');
    let tokenObj = JSON.parse(s);
    //console.log(" @@@@@ ", tokenObj.data.UserData.Data)

    return tokenObj;
  }

  // getting Autherization token which is stored in localstorage

  getAuthToken() {

    let val = JSON.parse(localStorage.getItem('token'));
    let token1 = val['data'];
    let token2 = token1['dms-token'];
    let headers = new HttpHeaders();
    headers = headers.set('dms-token', token2);
    //console.log(" ###### ", token1)
    return headers;

  }


  forgetPassword(login: Login): Observable<any> {

    let result = this.httpclient.post<any>(`${this.apiURL}/forgotPassword`, login);

    return result;
  }

  // Change Password

  changePassword(changepassword: Changepassword): Observable<any> {

    let headers = this.getAuthToken();
    let result = this.httpclient.post<any>(`${this.apiURL}/changePassword `, changepassword, { headers: headers });

    return result;

  }


  createResources(resources: Resources): Observable<any> {

    let headers = this.getAuthToken();

    let result = this.httpclient.post<any>(`${this.apiURL}/api/resource/createResource`, resources, { headers: headers });
    return result;
  }

  /* Resources Api's */
  viewAllResources(): Observable<any> {
    let headers = this.getAuthToken();
    let result = this.httpclient.get<any>(`${this.apiURL}/api/resource/viewAllResources`, { headers: headers });
    return result;
  }
  getAllResources(): Observable<any> {
    let headers = this.getAuthToken();
    let result = this.httpclient.get<any>(`${this.apiURL}/api/resource/getAllResources`, { headers: headers });
    return result;
  }
  viewResourcesById(ResourceID: string): Observable<any> {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
      params: { 'ResourceID': ResourceID }
    };
    let result = this.httpclient.get<any>(`${this.apiURL}/api/resource/viewResourceByID`, httpOptions);
    return result;
  }

  /* Role Api's */
  createRoles(roles: Roles): Observable<any> {
    let headers = this.getAuthToken();
    let result = this.httpclient.post<any>(`${this.apiURL}/api/roles/createRole`, roles, { headers: headers });
    return result;
  }
  viewAllRoles(): Observable<any> {
    let headers = this.getAuthToken();
    let result = this.httpclient.get<any>(`${this.apiURL}/api/roles/viewAllRoles`, { headers: headers });
    return result;
  }
  viewRolesByChannelPratherID(ChannelID: string) {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
      params: { "ChannelID": ChannelID }
    };
    let result = this.httpclient.post<any>(`${this.apiURL}/api/roles/viewAllRoles`, { headers: headers });
    return result;
  }
  viewJobTypes(): Observable<any> {
    let headers = this.getAuthToken();
    let result = this.httpclient.get<any>(`${this.apiURL}/api/roles/getJobType`, { headers: headers });
    return result;
  }
  updateRole(role: Roles): Observable<any> {
    let headers = this.getAuthToken();
    let result = this.httpclient.post<any>(`${this.apiURL}/api/roles/updateRole`, role, { headers: headers });
    return result;
  }
  viewRoleById(RoleID: string): Observable<any> {
    let headers = this.getAuthToken();
    let Roledata = { "RoleID": RoleID };
    let result = this.httpclient.post<any>(`${this.apiURL}/api/roles/viewRoleByID`, Roledata, { headers: headers });
    return result;
  }


  viewPatientsByContactNumber(ContactNumber: string): Observable<any> {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
      params: { 'ContactNumber': ContactNumber }
    };
    let result = this.httpclient.get<any>(`${this.apiURL}/api/patient/viewPatientsByContactNumber`, httpOptions);
    return result;
  }




  createPrivileges(privileges: Privileges): Observable<any> {

    let headers = this.getAuthToken();
    let result = this.httpclient.post<any>(`${this.apiURL}/api/privileges/createPrivilege`, privileges, { headers: headers });
    return result;

  }
  viewAllPrivileges(): Observable<any> {

    let headers = this.getAuthToken();
    let result = this.httpclient.get<any>(`${this.apiURL}/api/privileges/viewAllPrivileges`, { headers: headers });
    return result;

  }


  /* Permission API's */
  createPermissions(permissions: Permissions): Observable<any> {
    let headers = this.getAuthToken();
    let result = this.httpclient.post<any>(`${this.apiURL}/api/permissions/createPermission`, permissions, { headers: headers });
    return result;
  }


  viewAllPermissions(): Observable<any> {
    let headers = this.getAuthToken();
    let result = this.httpclient.get<any>(`${this.apiURL}/api/permissions/viewAllPermissions`, { headers: headers });
    return result;
  }

  viewPermissionsById(PermissionID: string): Observable<any> {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
      params: { "PermissionID": PermissionID }
    };
    let result = this.httpclient.get<any>(`${this.apiURL}/api/permissions/viewPermissionByID`, httpOptions);
    return result;
  }
  updatePermissions(permissions: Permissions): Observable<any> {
    let headers = this.getAuthToken();
    let result = this.httpclient.post<any>(`${this.apiURL}/api/permissions/updatePermission`, permissions, { headers: headers });
    return result;
  }



  createChannelPartners(channelpartners: Channelpartners): Observable<any> {
    let headers = this.getAuthToken();
    let result = this.httpclient.post<any>(`${this.apiURL}/api/channelpartner/createChannelPartner`, channelpartners, { headers: headers });
    return result;
  }

  updateChannelPartner(ChannelID: string, Status: string): Observable<any> {
    let headers = this.getAuthToken();
    let data = { 'ChannelID': ChannelID, 'Status': Status };
    let result = this.httpclient.put<any>(`${this.apiURL}/api/channelpartner/updateChannelPartner`, data, { headers: headers });
    return result;
  }


  viewChannelPartnerById(ChannelID: string): Observable<any> {

    let headers = this.getAuthToken();

    let params;

    const httpOptions = {
      headers: headers,
      params: { 'ChannelID': ChannelID }
    };

    let result = this.httpclient.get<any>(`${this.apiURL}/api/channelpartner/viewChannelPartner`, httpOptions);
    return result;
  }



  viewAllChannelPartners(): Observable<any> {

    let headers = this.getAuthToken();
    let result = this.httpclient.get<any>(`${this.apiURL}/api/channelpartner/viewAllChannelPartners`, { headers: headers });
    return result;
  }


  viewAllHospitals(): Observable<any> {

    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
    };


    let result = this.httpclient.get<any>(`${this.apiURL}/api/hospital/viewAllHospitals`, { headers: headers });
    return result;
  }

  createHospitals(hospitals: Hospitals): Observable<any> {

    let headers = this.getAuthToken();
    let result = this.httpclient.post<any>(`${this.apiURL}/api/hospital/createHospital`, hospitals, { headers: headers });
    return result;

  }

  updateHospitals(hospitals: Hospitals): Observable<any> {
    let headers = this.getAuthToken();
    let result = this.httpclient.post<any>(`${this.apiURL}/api/hospital/updateHospital`, hospitals, { headers: headers });
    return result;

  }



  viewHospitalsById(HospitalID: string): Observable<any> {

    let headers = this.getAuthToken();

    let params;

    const httpOptions = {
      headers: headers,
      params: { 'HospitalID': HospitalID }
    };

    let result = this.httpclient.get<any>(`${this.apiURL}/api/hospital/viewHospitalByID`, httpOptions);
    return result;
  }


  viewAllDoctors(): Observable<any> {
    let headers = this.getAuthToken();
    let result = this.httpclient.get<any>(`${this.apiURL}/api/doctor/viewAllDoctors`, { headers: headers });
    return result;
  }


  createDoctors(doctors: Doctors): Observable<any> {

    let headers = this.getAuthToken();
    let result = this.httpclient.post<any>(`${this.apiURL}/api/doctor/createDoctor`, doctors, { headers: headers });
    return result;

  }


  viewDoctorsById(MedicID: string): Observable<any> {
    let headers = this.getAuthToken();
    let params;
    const httpOptions = {
      headers: headers,
      params: { 'MedicID': MedicID }
    };
    let result = this.httpclient.get<any>(`${this.apiURL}/api/doctor/viewDoctorByID`, httpOptions);
    return result;
  }


  updateDoctors(doctors: Doctors): Observable<any> {
    let headers = this.getAuthToken();
    let result = this.httpclient.put<any>(`${this.apiURL}/api/doctor/updateDoctor`, doctors, { headers: headers });
    return result;
  }


  viewAllPatients(): Observable<any> {
    let headers = this.getAuthToken();
    let result = this.httpclient.get<any>(`${this.apiURL}/api/patient/viewAllPatients`, { headers: headers });
    return result;
  }

  createPatients(patients: Patients): Observable<any> {

    let headers = this.getAuthToken();
    let result = this.httpclient.post<any>(`${this.apiURL}/api/patient/createPatient`, patients, { headers: headers });
    return result;

  }

  viewPatientsById(MRNumber: string): Observable<any> {
    let headers = this.getAuthToken();
    let params;
    const httpOptions = {
      headers: headers,
      params: { 'MRNumber': MRNumber }
    };

    let result = this.httpclient.get<any>(`${this.apiURL}/api/patient/viewPatientByID`, httpOptions);
    return result;
  }



  viewPatientsPrescription(MRNumber: any): Observable<any> {

    let headers = this.getAuthToken();

    let params;


    const httpOptions = {
      headers: headers,
      // params: { 'MRNumber': MRNumber }
    };

    let result = this.httpclient.post<any>(`${this.apiURL}/api/appointments/getAllPatientPrescriptions`, MRNumber, httpOptions);
    return result;
  }

  viewAllPatientsAppointments(MRNumber: string): Observable<any> {

    let headers = this.getAuthToken();

    let params;


    const httpOptions = {
      headers: headers,
      params: { 'MRNumber': MRNumber }
    };

    let result = this.httpclient.get<any>(`${this.apiURL}/api/patient/viewAllMyAppointments`, httpOptions);
    return result;
  }

  viewPatientsByCondition(Type: string): Observable<any> {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
      params: { 'Type': Type }
    };

    let result = this.httpclient.get<any>(`${this.apiURL}/api/patient/viewPatientsByCondition`, httpOptions);
    return result;
  }
  viewPatientsByConditionPagination(Type: string, pageNumber: string, limit: string): Observable<any> {

    let headers = this.getAuthToken();
    let params;
    const httpOptions = {
      headers: headers,
      params: { 'Type': Type, 'pageNumber': pageNumber, 'limit': limit }
    };

    let result = this.httpclient.get<any>(`${this.apiURL}/api/patient/viewPatientsByCondition`, httpOptions);
    return result;
  }


  updatePatients(patients: Patients): Observable<any> {
    let headers = this.getAuthToken();

    let result = this.httpclient.put<any>(`${this.apiURL}/api/patient/updatePatient`, patients, { headers: headers });

    return result;

  }

  /*Patients*/
  viewPatientsAllAppointemetReports(optionaldata: any): Observable<any> {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
      // params: { 'MRNumber': MRNumber }
    };
    console.log(optionaldata)
    let result = this.httpclient.post<any>(`${this.apiURL}/api/patient/viewPatientsAllAppointemetReports`, optionaldata, httpOptions);
    return result;
  }


  createAppointments(appointments: Appointments): Observable<any> {

    let headers = this.getAuthToken();

    let result = this.httpclient.post<any>(`${this.apiURL}/api/appointments/createAppointment`, appointments, { headers: headers });
    return result;

  }


  viewAllAppointmentsPagination(pageNumber: string, limit: string): Observable<any> {

    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
      params: { 'pageNumber': pageNumber, 'limit': limit }//pageNumber=1&limit=10

    };

    let result = this.httpclient.get<any>(`${this.apiURL}/api/appointments/viewAllAppointments`, httpOptions);
    return result;
  }

  viewAllAppointments(): Observable<any> {

    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
    };

    let result = this.httpclient.get<any>(`${this.apiURL}/api/appointments/viewAllAppointments`, httpOptions);
    return result;
  }


  viewAllfollowupAppointments(): Observable<any> {

    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
    };

    let result = this.httpclient.get<any>(`${this.apiURL}/api/followup/viewFollowUpAppointments`, httpOptions);
    return result;
  }

  AllLabReports(): Observable<any> {

    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
    };
    let result = this.httpclient.get<any>(`${this.apiURL}/api/lab/getPendingTests`, httpOptions);
    return result;
  }


  createtwodeechoreport(Twodeechoreport: Twodeechoreport): Observable<any> {

    let headers = this.getAuthToken();

    let result = this.httpclient.post<any>(`${this.apiURL}/api/twodecho/createTwoDEchoReport`, Twodeechoreport, { headers: headers });
    return result;

  }

  Updatetwodeechoreport(Twodeechoreport: Twodeechoreport): Observable<any> {

    let headers = this.getAuthToken();

    let result = this.httpclient.put<any>(`${this.apiURL}/api/twodecho/updateTwoDEchoReport`, Twodeechoreport, { headers: headers });
    return result;

  }

  viewPatientTwoDEchoReports(ReportID: string): Observable<any> {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
      params: { 'ReportID': ReportID }

    };
    let result = this.httpclient.get<any>(`${this.apiURL}/api/twodecho/viewTwoDEchoReportByID`, httpOptions);
    return result;

  }


  viewAppointmentById(AppointmentID: string): Observable<any> {

    let headers = this.getAuthToken();

    let params;

    const httpOptions = {
      headers: headers,
      params: { 'AppointmentID': AppointmentID }
    };

    let result = this.httpclient.get<any>(`${this.apiURL}/api/appointments/viewAppointmentById`, httpOptions);
    return result;
  }

  updateAppointments(appointments: Appointments): Observable<any> {
    let headers = this.getAuthToken();

    let result = this.httpclient.post<any>(`${this.apiURL}/api/appointments/updateAppointment`, appointments, { headers: headers });

    return result;

  }

  todayAppointments(): Observable<any> {

    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
    };
    let result = this.httpclient.get<any>(`${this.apiURL}/api/appointments/viewTodayAppointments`, httpOptions);
    return result;
  }



  updateAppointmentsPrescription(appointments: Appointments): Observable<any> {
    let headers = this.getAuthToken();
    let result = this.httpclient.post<any>(`${this.apiURL}/api/appointments/updateAppointmentPrescription`, appointments, { headers: headers });
    return result;
  }

  /* Billings Apis */
  viewAllBillings(Status: string): Observable<any> {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
      params: { 'Status': Status }
      // params: { 'pageNumber': pageNumber, 'limit': limit }//pageNumber=1&limit=10
    };
    let result = this.httpclient.get<any>(`${this.apiURL}/api/billingitem/viewAllBillingItems`, httpOptions);
    return result;
  }
  viewAlluniqueBillings(Status: string): Observable<any> {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
      // params: { 'Status': Status }
      // params: { 'pageNumber': pageNumber, 'limit': limit }//pageNumber=1&limit=10
    };
    let result = this.httpclient.get<any>(`${this.apiURL}/api/billingitem/getAllUniqueBills`, httpOptions);
    return result;
  }
  viewAllBillingsPrices(obj: any): Observable<any> {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
    };
    let result = this.httpclient.post<any>(`${this.apiURL}/api/billingitem/getBillingPrices`, obj, httpOptions);
    return result;
  }
  createBilling(Billing: BillingItem): Observable<any> {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
    };
    let result = this.httpclient.post<any>(`${this.apiURL}/api/billingitem/createBillingItem`, Billing, httpOptions);
    return result;
  }
  viewBilling(BillingID: string): Observable<any> {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
      params: { 'BillingID': BillingID }
    };
    let result = this.httpclient.get<any>(`${this.apiURL}/api/billingitem/viewBillingItem`, httpOptions);
    return result;
  }
  updateBilling(Billing: BillingItem): Observable<any> {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers
    };
    let result = this.httpclient.put<any>(`${this.apiURL}/api/billingitem/updateBillingItem`, Billing, httpOptions);
    return result;
  }

  viewAllBillingsActiveTestTypes() {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
    };
    let result = this.httpclient.get<any>(`${this.apiURL}/api/billingitem/viewAllActiveTestTypes`, httpOptions);
    return result;
  }
  calculateTestPrice(Billing: BillingItem) {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers
    };
    let result = this.httpclient.post<any>(`${this.apiURL}/api/billingitem/calculateTestPrices`, Billing, httpOptions);
    return result;
  }
  getDoctorBillings(MedicID: string) {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
      params: { 'MedicID': MedicID }
    };
    let result = this.httpclient.get<any>(`${this.apiURL}/api/billingitem/getDoctorBillings`, httpOptions);
    return result;
  }
  //-------------------------------------------------//
  getTypeMastersData() {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
    };
    let result = this.httpclient.get<any>(`${this.apiURL}/api/masters/getTypeMastersData`, httpOptions);
    return result;
  }
  getTestMastersData() {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
    };
    let result = this.httpclient.get<any>(`${this.apiURL}/api/masters/getTestMastersData`, httpOptions);
    return result;
  }



  /*Api for create all type of payments*/
  createPayment(Payment: Payments): Observable<any> {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
    };
    let result = this.httpclient.post<any>(`${this.apiURL}/api/payments/createPayment`, Payment, httpOptions);
    return result;
  }


  /* paymenst Apis */
  viewAllPayments(): Observable<any> {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
    };
    let result = this.httpclient.post<any>(`${this.apiURL}/api/payments/viewAllPayments`, {}, httpOptions);
    return result;
  }
  viewAllPaymentsPagination(Object: string): Observable<any> {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,

    };
    let result = this.httpclient.post<any>(`${this.apiURL}/api/payments/viewAllPayments`, Object, httpOptions);
    return result;
  }

  viewRevenue(Revenue: RevenueData): Observable<any> {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
    };
    let result = this.httpclient.post<any>(`${this.apiURL}/api/revenue/getRevenue`, Revenue, httpOptions);
    return result;
  }
  viewAllPaymentsByDate(Payments: Payments): Observable<any> {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
    };
    let result = this.httpclient.post<any>(`${this.apiURL}/api/payments/viewAllPayments`, Payments, httpOptions);
    return result;
  }
  createTestPayment(Payments: Payments): Observable<any> {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
    };
    let result = this.httpclient.post<any>(`${this.apiURL}/api/payments/createTestsPayment`, Payments, httpOptions);
    return result;
  }
  viewPayment(PaymentID: string): Observable<any> {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
      params: { 'PaymentID': PaymentID }
    };
    let result = this.httpclient.get<any>(`${this.apiURL}/api/payments/viewPaymentByID`, httpOptions);
    return result;
  }
  updatePayment(Payments: Payments): Observable<any> {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers
    };
    let result = this.httpclient.put<any>(`${this.apiURL}/api/payments/updatePayment`, Payments, httpOptions);
    return result;
  }

  getTests(): Observable<any> {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers
    };
    let result = this.httpclient.get<any>(`${this.apiURL}/api/revenue/getTests`, httpOptions);
    return result;
  }

  getPatients(): Observable<any> {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers
    };
    let result = this.httpclient.get<any>(`${this.apiURL}/api/revenue/getPatients`, httpOptions);
    return result;
  }

  /* User Apis */

  createUser(User: Users): Observable<any> {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
    };
    let result = this.httpclient.post<any>(`${this.apiURL}/api/users/createUser`, User, httpOptions);
    return result;
  }
  viewAllUsers(): Observable<any> {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
      // params: { 'pageNumber': pageNumber, 'limit': limit }//pageNumber=1&limit=10
    };
    //let result = this.httpclient.get<any>(`${this.apiURL}/api/users/viewAllUsers?pageNumber=${pageNumber}&limit=${limit}`, httpOptions);
    let result = this.httpclient.get<any>(`${this.apiURL}/api/users/viewAllUsers`, httpOptions);
    return result;
  }


  viewUserByID(MedicID: string): Observable<any> {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
      params: { 'MedicID': MedicID }
    };

    let result = this.httpclient.get<any>(`${this.apiURL}/api/users/viewUserByID`, httpOptions);
    return result;
  }


  updateUser(User: Users): Observable<any> {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers
    };
    let result = this.httpclient.put<any>(`${this.apiURL}/api/users/updateUser`, User, httpOptions);
    return result;
  }

  /*Health Reports */
  uploadReports(Report: Reports) {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers
    };
    let result = this.httpclient.post<any>(`${this.apiURL}/api/healthreports/uploadReports`, Report, httpOptions);
    return result;

  }
  viewAllHealthReports(AppointmentID: string, MRNumber: string) {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
      params: { 'AppointmentID': AppointmentID, 'MRNumber': MRNumber }
    };
    let result = this.httpclient.get<any>(`${this.apiURL}/api/healthreports/viewReports`, httpOptions);
    return result;
  }

  viewReportsbylabtech(MRNumber: string, PaymentID: string, BillID: string) {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
      params: { 'MRNumber': MRNumber, 'PaymentID': PaymentID, 'BillID': BillID }
    };
    let result = this.httpclient.get<any>(`${this.apiURL}/api/healthreports/viewReportsbylabtech`, httpOptions);
    return result;

  }
  viewReportById(ReportID: string) {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
      params: { 'ReportID': ReportID }
    };
    let result = this.httpclient.get<any>(`${this.apiURL}/api/healthreports/viewReportById`, httpOptions);
    return result;

  }

  //  API's FOR BILLING TYPE :

  createBillingTypeMaster(billingtype: BillingItem): Observable<any> {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers
    };
    let result = this.httpclient.post<any>(`${this.apiURL}/api/masters/createBillingTypeMasterItem`, billingtype, httpOptions);
    return result;
  }

  viewAllBillingTypeMasterData() {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
    };
    let result = this.httpclient.get<any>(`${this.apiURL}/api/masters/getTypeMastersData`, httpOptions);
    return result;
  }

  updateBillingTypeMaster(BillingID: string, Status: string): Observable<any> {
    let headers = this.getAuthToken();
    let data = { 'BillingID': BillingID, 'Status': Status };
    let result = this.httpclient.put<any>(`${this.apiURL}/api/masters/updateBillingTypeMasterItem`, data, { headers: headers });
    return result;
  }

  // API's FOR BILLING TEST:

  createBillingTestMaster(billingtype: BillingItem): Observable<any> {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers
    };
    let result = this.httpclient.post<any>(`${this.apiURL}/api/masters/createBillingTestMasterItem`, billingtype, httpOptions);
    return result;
  }

  viewAllBillingTestMasterData() {
    let headers = this.getAuthToken();
    const httpOptions = {
      headers: headers,
    };
    let result = this.httpclient.get<any>(`${this.apiURL}/api/masters/getTestMastersData`, httpOptions);
    return result;
  }

  updateBillingTestMaster(BillingID: string, Status: string): Observable<any> {
    let headers = this.getAuthToken();
    let data = { 'BillingID': BillingID, 'Status': Status };
    let result = this.httpclient.put<any>(`${this.apiURL}/api/masters/updateBillingTestMasterItem`, data, { headers: headers });
    return result;
  }



}


