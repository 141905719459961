import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';

@Component({
  selector: 'app-view-allbillings',
  templateUrl: './view-allbillings.component.html',
  styleUrls: ['./view-allbillings.component.css']
})
export class ViewAllbillingsComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private apicalls: ApicallsService, private router: ActivatedRoute, private route: Router, private httpClient: HttpClient) { }
  Billings = [];
  userDetails
  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data
    //Status = "Active"
    this.apicalls.viewAllBillings("Active")
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          setTimeout(() => {

            this.spinner.hide();
          }, 500);
          for (var i = 0; i < data.data.length; i++) {
            if (data.data[i].Status == "Active") {
              this.Billings.push(data.data[i]);
            }
          }
          //console.log("Billings...", this.Billings);
          //  this.dtTrigger.next();

        } else {

          //  this.alertService.info(data.message)
          this.spinner.hide();
          // this.spinner.hide();

          if (data.code == 'PD01') {
            alert(data.data)
            this.route.navigate(["/dashboardmain/dashboard"]);

          }
          else {
            alert(data.data)
          }

        }
      }, error => {
        error => { alert(" Internal Server Error "); }
        // this.alertService.warning(" Internal Server Error ");
      });

  }


  onClickCreate() {
    this.route.navigate(["/dashboardmain/Createbilling"]);
  }

  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }

  editdata(BillingID) {
    this.route.navigate(["/dashboardmain/Updatebilling", BillingID]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }

}
