<!DOCTYPE html>
<!-- saved from url=(0031)http://drgokulreddy.in/add-user -->
<html lang="en">

<body data-gr-c-s-loaded="true">
    <div id="page" class="side-nav-wrapper">
        <!-- Simple Navbar -->

        <!-- Simple Navbar -->
        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pull-right" style="margin-right: 10px;">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                                style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                                <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                                <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                    userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                        class="">Settings</a></li>
                                <li
                                    *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                    <a [routerLink]="" (click)="users()" class="">Users</a></li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="container bg-gray">
            <div class="row">
                <div class="col-md-12">
                    <h5 class="dr-sub-head">Create Role </h5>
                </div>
            </div>
            <div class="row orr-usercreation ptop30 pbtom30">

                <div class="col-md-12">
                    <div class="panel">


                        <div class="tab-table-customer-entry"
                            style="margin:30px 0;background:#fff;padding:10px 0;border-top:1px solid #ddd;border-bottom:1px solid #ddd;">

                            <form id="contact-form" class="contact-form pad-40" autocomplete="off"
                                [formGroup]="roleForm">
                                <div class="form-pad-label" style="padding-top:30px;">
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label 
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Role
                                                Name<span class="required"> * </span></label>
                                            <input [class.is-invalid]="Role.invalid && (Role.dirty || Role.touched)"
                                                class="form-control" id="Role" type="Role" name="Role"
                                                placeholder=" Role Name " formControlName="Role">
                                        </div>
                                        <div *ngIf="error && Role.errors?.required" style="text-align: center;" class="text-danger">Role is
                                            required</div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Job
                                                Type<span class="required">* </span></label>

                                            <select
                                                [class.is-invalid]="JobType.invalid && (JobType.dirty || JobType.touched)"
                                                class="form-control" id="JobType" type="JobType" name="JobType"
                                                formControlName="JobType" style="float:left;width:70%;">

                                                <option value="" disabled>Select Job Type</option>
                                                <option *ngFor="let list of viewjobtype?.data; let i=index"
                                                    value="{{list}}">{{list}}</option>

                                            </select>
                                         
                                        </div>
                                        <div *ngIf="error && JobType.errors?.required" style="text-align: center;" class="text-danger">JobType is
                                            required</div>
                                    </div>
                                    <div style="clear:both;"></div>

                                    <div
                                        style="margin:auto;text-align:center;margin-bottom:10px;margin-top:30px;margin-bottom:30px;">
                                        <a [routerLink]="" (click)="onSubmit(roleForm)"> <input type="submit"
                                                class="btn-pink" value="CREATE"></a>

                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>

            </div>
        </div>

        <script>

            var base_url = 'http://drgokulreddy.in/';
            function emp_entry() {

                var flag = 0;
                var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
                var alpha = /^[a-zA-Z\s]+$/;
                var email = $("#email").val();
                var regex = /^[0-9]*(?:\.\d{1,2})?$/;

                if ($("#name").val() == '') {
                    $("#name_error").html('Enter Name');
                    flag = 1;
                }
                else {
                    if (!alpha.test($("#name").val())) {
                        $("#name_error").html('Enter only Alphabets');
                        flag = 1;
                    }
                    else {
                        $("#name_error").html('');
                    }

                }
                if ($("#email").val() == '') {
                    $("#email_error").html('Enter email');
                    flag = 1;
                }
                else if (!pattern.test($("#email").val())) {
                    $("#email_error").html('Enter valid  Email');
                    flag = 1;
                }
                else {
                    $("#email_error").html('');
                }





                if ($("#pass").val() == '') {
                    $("#pass_error").html('Enter Password');
                    flag = 1;
                }
                else {
                    $("#pass_error").html('');

                }


                if ($("#utype").val() == '') {
                    $("#utype_error").html('Select User Type');
                    flag = 1;
                }
                else {
                    $("#utype_error").html('');

                }
                if (flag) {
                    $(".error").each(function () {
                        if ($(this).html().length > 0) {

                            var str = $(this).attr('id');
                            var res = str.split("_");
                            $("#" + res[0]).focus();
                            return false;
                        }

                    })
                    return false;
                }

            }


        </script>
        <script src="./GOKUL CLINIC - Add User page_files/jquery.dataTables.min.js.download"></script>
        <script src="./GOKUL CLINIC - Add User page_files/dataTables.bootstrap.min.js.download"></script>
        <style type="text/css">
            @media print {


                #get-quote,
                .upper {
                    display: none;
                }
            }
        </style>
        <script type="text/javascript">
            $('.print').click(function () {
                window.print();
                //printDiv();

            });
            function printDiv() {

                var divToPrint = document.getElementById('divtoprint');

                var newWin = window.open('', 'Print-Window');

                newWin.document.open();

                newWin.document.write('<html><body onload="window.print()">' + divToPrint.innerHTML + '</body></html>');

                newWin.document.close();

                setTimeout(function () { newWin.close(); }, 10);

            }
            $(document).ready(function () {
                $('#example').DataTable();
            });
            $(document).ready(function () {
                $(".alert").fadeTo(2000, 500).slideUp(500, function () {
                    $(".alert").slideUp(500);
                });
            });
        </script>
    </div>
    <script src="./GOKUL CLINIC - Add User page_files/tabs.js.download"></script>





</body>

</html>
<ngx-spinner></ngx-spinner>