<!DOCTYPE html>
<!-- saved from url=(0031)http://drgokulreddy.in/patients -->
<html lang="en">

<body>
    <div id="page" class="side-nav-wrapper">

        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pull-right" style="margin-right: 10px;">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                                style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                                <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                                <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                    userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                        class="">Settings</a></li>
                                <li
                                    *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                    <a [routerLink]="" (click)="users()" class="">Users</a></li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>


        <div class="container bg-gray">
            <div class="row">
                <div class="col-md-4">
                    <h5 class="dr-sub-head">All Reports</h5>
                </div>
            </div>



            <div class="row orr-usercreation ptop30 pbtom30">

                <div class="col-md-12">


                    <div class="panel">

                        <div class="col-md-12">
                            <label>Search with MRNumber, Patient's Name or Report Name</label>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">

                                <i class="fa fa-search"
                                    style="font-size:24px;padding-top: 9px;padding-right: 9px;"></i><input type="text"
                                    class="form-control" placeholder=" Enter text here to search " [(ngModel)]="term">
                            </div>
                        </div>

                        <table cellspacing="0" cellpadding="0" width="100%" class="mirchi-birthday-grid" id="example">
                            <thead>
                                <tr>
                                    <th style="text-align:center;"> S.No</th>
                                    <th style="text-align:center;"> MR Number</th>
                                    <th style="text-align:center;"> Patient Name</th>
                                    <th style="text-align: center;">Report Name</th>
                                    <th style="text-align: center;">View</th>

                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                <tr *ngFor="let list of reports | filter:term; let i=index ">
                                    <td style="text-align:center;">{{i + 1}}</td>
                                    <td style="text-align:center;">{{list.PatientDetails.MRNumber}}</td>
                                    <td style="text-align:center;">{{list.PatientDetails.Name}}</td>
                                    <td style="text-align:center;">{{list.AliasName}}</td>
                                    <td style="text-align:center;">
                                        
                                        <a [routerLink]="" (click)="ondata(list)">
                                            <span class="glyphicon glyphicon-edit"></span></a>
                                    </td>


                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>

</html>
<ngx-spinner></ngx-spinner>