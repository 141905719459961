import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApicallsService } from '../../../services/apicalls.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-viewallresources',
  templateUrl: './viewallresources.component.html',
  styleUrls: ['./viewallresources.component.css']
})
export class ViewallresourcesComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService,private apicalls: ApicallsService, private router: ActivatedRoute, private route: Router, private httpClient: HttpClient) { }
 
  viewallresources;
  
  // dtOptions: DataTables.Settings = {};
  // dtTrigger: Subject<any> = new Subject();
  ngOnInit() {

    // let ResutedLocalStorage = this.apicalls.getToken();
  
    // let LocalStoragetoken = ResutedLocalStorage['data'];
  
    // let LocalStorageAuthObject = LocalStoragetoken.role_type;

   
    // if(LocalStorageAuthObject =="Student"){
    //   alert("Resticred aacess navigating to home page");
    //   this.route.navigate(["/dashboard/student_welcomepage"]);
    //   return;
    // }


   // console.log("Users details are");
    // this.dtOptions = {
    //   pageLength: 6,
    //   stateSave: true,
    //   lengthMenu: [[5, 10, -1], [5, 10, "All"]],
    //   processing: true
    // };
    this.apicalls.viewAllResources()
      .subscribe(data => {
        this.spinner.show();
        if(data.type==true){

          if(data.code=="S001"){
        
        
          setTimeout(() => {
          
            this.spinner.hide();
          }, 500);
          this.viewallresources = data;
       console.log("DATAAAAAAAAAAAAAAA",this.viewallresources);
      //  this.dtTrigger.next();
        }else{
          alert(data.data)
        }
      
      }else{
        
      //  this.alertService.info(data.message)
        // this.spinner.hide();
        this.spinner.hide();
        
        if(data.code=='PD01'){
          alert(data.data)
          this.route.navigate(["/dashboardmain/dashboard"]);

        }
        else{
        alert(data.data)
        }
        alert(data.data)
      }
      },error => {
        error => { alert(" Internal Server Error "); }
       // this.alertService.warning(" Internal Server Error ");
      });

    }
    // onSubmitCreateBatch(){
    //   this.route.navigate(["/dashboard/batch_create"]);
    // }

    
    // onSubmitUpdate(list){
    // //  alert(" Edit batch option will be included soon..........")
    //  //  console.log("((((((((((",list);
    //    this.route.navigate(["/dashboard/view_batchbyid",list.batch_id,list.programme_id]);
       
    //  }
   
  
    onClickCreate(){
      this.route.navigate(["/dashboardmain/resources"]);
    }

    // dashboard(){
    //   this.route.navigate(["/dashboard"]);
    // }
  
    // Resources(){
    //   this.route.navigate(["/viewallresources"]);
    // }
  
    // Roles(){
    //   this.route.navigate(["/viewallroles"]);
    // }
    changePassword(){
      this.route.navigate(["/dashboardmain/changepassword"]);
    }
    logout(){
      this.route.navigate(["/logout"]);
    }
    
}
