import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../services/apicalls.service';

@Component({
  selector: 'app-followupappointments',
  templateUrl: './followupappointments.component.html',
  styleUrls: ['./followupappointments.component.css']
})
export class FollowupappointmentsComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private apicalls: ApicallsService, private router: ActivatedRoute, private route: Router, private httpClient: HttpClient) { }

  followupappointmnets
  userDetails;
  term: string='';
  
  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data

    this.apicalls.viewAllfollowupAppointments()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {
            this.followupappointmnets = data.data;
            console.log(" ", this.followupappointmnets)
          } else {
            this.followupappointmnets = []
          }

          setTimeout(() => {

            this.spinner.hide();
          }, 500);

        } else {

          //  this.alertService.info(data.message)
          this.spinner.hide();
          // this.spinner.hide();

          if (data.code == 'PD01') {
            alert(data.data)
            this.route.navigate(["/dashboardmain/dashboard"]);

          }
          else {
            alert(data.data)
          }

        }
      }, error => {
        error => { alert(" Internal Server Error "); }
        // this.alertService.warning(" Internal Server Error ");
      });

  }

  Configurations() {
    this.route.navigate(["/dashboardmain/configuration"]);
  }

  Followupappointments() {
    this.route.navigate(["/dashboardmain/followupappointments"]);
  }



  doctors() {
    this.route.navigate(["/dashboardmain/doctors"]);
  }
  patients() {
    this.route.navigate(["/dashboardmain/patients"]);
  }
  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }

  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }

  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
  
}

