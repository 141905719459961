import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';


@Component({
  selector: 'app-updatepatients',
  templateUrl: './updatepatients.component.html',
  styleUrls: ['./updatepatients.component.css']
})
export class UpdatepatientsComponent implements OnInit {
  datePickerConfig: Partial<BsDatepickerConfig>;
  constructor(private spinner: NgxSpinnerService, private httpclient: HttpClient, private formBuilder: FormBuilder, private apicalls: ApicallsService, private route: Router, private router: ActivatedRoute) {
    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumber: false,
        dateInputFormat: 'DD-MM-YYYY'

      }
    );
  }

  details;
  maxDate;
  temppreviousdob;
  userDetails;
  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data
    this.router.paramMap.subscribe(params => {
      const MRNumber = params.get('id');
      if (MRNumber) {

        this.getPatientsId(MRNumber);
      }
    });

    let todaysdate = moment();
    let dateIn1day = todaysdate.toDate();
    let NextYear = dateIn1day.getFullYear();
    let NextDate = dateIn1day.getDate();
    let NextMonth = dateIn1day.getUTCMonth();

    this.maxDate = new Date(NextYear, NextMonth, NextDate);


  }

  getPatientsId(MRNumber: string) {
    console.log("MRNumber..........", MRNumber)
    this.apicalls.viewPatientsById(MRNumber).subscribe(

      data => {
        this.spinner.show();


        if (data.type == true) {
          this.details = data
          console.log("DDDDData@@@@@@@@@@@@@@@@@", this.details)
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
          }, 500);
          this.temppreviousdob = moment(this.details.data.DOB, "DD-MM-YYYY").unix();

          this.patientForm.patchValue({

            MRNumber: this.details.data.MRNumber,
            Name: this.details.data.Name,
            DOB: this.details.data.DOB,
            Age: this.details.data.Age,
            Gender: this.details.data.Gender,
            ContactNumber: this.details.data.ContactNumber,
            EmailID: this.details.data.EmailID,
            RefferedBy: this.details.data.RefferedBy,
            District: this.details.data.District,
            Hospital: this.details.data.Hospital,

            // HospitalID:this.details.data.HospitalID,
            // HospitalName : this.details.data.HospitalName,
            // HospitalBranch : this.details.data.HospitalBranch,
            // ContactNumber : this.details.data.ContactNumber,
            // SecondryContactNumber :this.details.data.SecondryContactNumber,

            StreetAddress: this.details.data.Address.StreetAddress,
            // Colony: this.details.data.Address.Colony,
            // Locality: this.details.data.Address.Locality,
            // PinCode: this.details.data.Address.PinCode,
            // City: this.details.data.Address.City,
            // State: this.details.data.Address.State,
            // Country: this.details.data.Address.Country,

            // Latitude: this.details.data.Address.Latitude,
            // Longitude: this.details.data.Address.Longitude,
            // LocationLink: this.details.data.Address.LocationLink,


            Status: this.details.data.Status


          });
        } else {
          alert(data.message);
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ")
      });


  }

  data: '';
  pathurl: string = 'GokulClinic';
  isSubmitted: boolean = true;
  //loggedIn:boolean=true;
  channelError: string;
  AddressObj = {};
  error = false;


  onSubmit(patientForm) {

    if (this.patientForm.invalid) {
      this.error = true;
      return;
    }
    console.log("@@@@@@@hospital", this.patientForm.value);

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 5000);



    this.AddressObj = {
      StreetAddress: this.patientForm.value.StreetAddress,
      // Colony: this.patientForm.value.Colony,
      // Locality: this.patientForm.value.Locality,
      // PinCode: this.patientForm.value.PinCode,
      // City: this.patientForm.value.City,
      // State: this.patientForm.value.State,
      // Country: this.patientForm.value.Country,
      // Latitude: this.patientForm.value.Latitude,
      // Longitude: this.patientForm.value.Longitude,
      // LocationLink: this.patientForm.value.LocationLink
    };
    this.patientForm.value.Address = this.AddressObj;

    console.log("AAAAAA", this.temppreviousdob);

    console.log("AAAAAAbbbbbbbbb", moment(this.patientForm.value.DOB, "DD-MM-YYYY").unix());
    if (this.temppreviousdob == moment(this.patientForm.value.DOB, "DD-MM-YYYY").unix()) {
      this.patientForm.value.DOB = moment.unix(this.temppreviousdob).format("DD-MM-YYYY")

    }
    else {
      let x = moment(this.patientForm.value.DOB).format("DD-MM-YYYY");
      this.patientForm.value.DOB = x;
    }


    // console.log("DOBBBBBBBBBBBB", this.patientForm.value.DOB);
    // let x = moment(this.patientForm.value.DOB).format("DD-MM-YYYY");
    // this.patientForm.value.DOB = x;
    console.log("DOBBBBBBBBBBBBwwwwwwwwww", this.patientForm.value.DOB)
    // console.log("DATEEEEEEEEEEEEE",x);


    console.log("DATATATATATATATATATA", this.patientForm.value)
    let result = this.apicalls.updatePatients(this.patientForm.value)
      .subscribe(data => {
        this.spinner.show();
        //  this.data = data;
        if (data.type == true) {

          if (data.code == 'S001') {
            this.spinner.hide();
            alert(data.data)
            console.log("!!!!!@@@@@@@@@@@!!!1", data);
            this.route.navigate(["/dashboardmain/patients"]);
          }

          if (data.code == 'S002') {
            this.spinner.hide();
            alert(data.data);
            this.isSubmitted = false;
            return false;

          }

        }
        else {
          console.log("!!!!!!!!!!!!!1", data.data);
          this.spinner.hide();
          this.isSubmitted = false;
          alert(data.data)


          return false;


        }
      },
        error => { alert(" Internal Server Error "); }
      );

  }

  get MRNumber() {
    return this.patientForm.get('MRNumber');
  }
  get Name() {
    return this.patientForm.get('Name');
  }
  get DOB() {
    return this.patientForm.get('DOB');
  }

  get Age() {
    return this.patientForm.get('Age');
  }
  get Gender() {
    return this.patientForm.get('Gender');
  }
  get RefferedBy() {
    return this.patientForm.get('RefferedBy');
  }
  get District() {
    return this.patientForm.get('District');
  }
  get Hospital() {
    return this.patientForm.get('Hospital');
  }
  get StreetAddress() {
    return this.patientForm.get('StreetAddress');
  }
  // get Colony() {
  //   return this.patientForm.get('Colony');
  // }
  // get Locality() {
  //   return this.patientForm.get('Locality');
  // }
  // get PinCode() {
  //   return this.patientForm.get('PinCode');
  // }
  // get City() {
  //   return this.patientForm.get('City');
  // }
  // get State() {
  //   return this.patientForm.get('State');
  // }
  // get Country() {
  //   return this.patientForm.get('Country');
  // }
  get HospitalName() {
    return this.patientForm.get('HospitalName');
  }
  get HospitalBranch() {
    return this.patientForm.get('HospitalBranch');
  }

  get ContactNumber() {
    return this.patientForm.get('ContactNumber');
  }
  get EmailID() {
    return this.patientForm.get('EmailID');
  }
  get Address() {
    return this.patientForm.get('Address') as FormArray;
  }


  patientForm = new FormGroup({
    MRNumber: new FormControl(''),
    Name: new FormControl('', [Validators.required, Validators.minLength(3), Validators.pattern('[a-zA-Z ]*')]),
    DOB: new FormControl(''),
    Age: new FormControl('', [Validators.required, Validators.pattern('[0-9][0-9]{1}')]),
    Gender: new FormControl('', [Validators.required]),
    ContactNumber: new FormControl('', [Validators.required, Validators.pattern('[6789][0-9]{9}'), Validators.minLength(10), Validators.maxLength(10)]),
    EmailID: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z2-9.-]+\.[a-z]{2,4}$')]),
    RefferedBy: new FormControl('', [Validators.pattern('[a-zA-Z ]*')]),
    District: new FormControl('', [Validators.pattern('[a-zA-Z ]*')]),
    Hospital: new FormControl('', [Validators.pattern('[a-zA-Z ]*')]),

    Address: new FormGroup({
      StreetAddress: new FormControl(''),
      // Colony: new FormControl('', [Validators.required]),
      // Locality: new FormControl('', [Validators.required]),
      // PinCode: new FormControl('', [Validators.required, Validators.pattern('[0-9]{6}')]),
      // City: new FormControl('', [Validators.required]),
      // State: new FormControl('', [Validators.required]),
      // Country: new FormControl('', [Validators.required]),

      // Latitude: new FormControl(''),
      // Longitude: new FormControl(''),
      // LocationLink: new FormControl('')
    }),
    StreetAddress: new FormControl(''),
    // Colony: new FormControl('', [Validators.required]),
    // Locality: new FormControl('', [Validators.required]),
    // PinCode: new FormControl('', [Validators.required, Validators.pattern('[0-9]{6}')]),
    // City: new FormControl('', [Validators.required]),
    // State: new FormControl('', [Validators.required]),
    // Country: new FormControl('', [Validators.required]),

    // Latitude: new FormControl(''),
    // Longitude: new FormControl(''),
    // LocationLink: new FormControl('')

  });



  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }
  Settings(){
    this.route.navigate(["/dashboardmain/configurations"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
}



