import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';

@Component({
  selector: 'app-view-all-payments',
  templateUrl: './view-all-payments.component.html',
  styleUrls: ['./view-all-payments.component.css']
})
export class ViewAllPaymentsComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private apicalls: ApicallsService, private router: ActivatedRoute, private route: Router, private httpClient: HttpClient) { }
  Payments;
  TempPaymentArray = [];
  PatientDetails;
  userDetails;
  term: string = '';
  Paymentobj:any;


  config: any;
  collection = [];
  pageLimit = 10;
  onLimit(value: number) {
    //console.log("limit ", value);
    this.pageLimit = value;
    this.onPage();
  }
  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data

    this.config = {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0
    };
    this.onPage();


  }

  onPage() {
    this.router.queryParamMap.subscribe(params => {
      this.config.currentPage = params.get('page')//params['page'] ? params['page'] : 1
      this.config.itemsPerPage = params['limit'] ? params['limit'] : this.pageLimit
      var Object = <any>{};
      Object.pageNumber = this.config.currentPage;
      Object.limit = this.pageLimit;
      this.apicalls.viewAllPaymentsPagination(Object).subscribe(data => {
        if (data.type == true) {
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
          if (data.code == "S001") {
            this.collection = [];
            this.config.totalItems = data.data.TotalRecords;
            for (var i = 0; i < data.data.payments.length; i++) {
              //console.log(" @@ ", data.data.payments[i])
              this.collection.push(data.data.payments[i]);
            }
          } else if (data.code == "UA01") {
            alert("Your UnAuthorized to Access this Service");
            return;
          }
        } else {
          alert(data.data)
        }
      }, error => { alert(" Internal Server Error "); })


    })

  }

  pageChange(newPage: number) {
    this.route.navigate(['/dashboardmain/ViewAllPayments'], { queryParams: { page: newPage } });
  }




  onClickCreate() {
    this.route.navigate(["/dashboardmain/CreateTestPayments"]);
  }

  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }

  editdata(PaymentID) {
    this.route.navigate(["/dashboardmain/UpdatePayment", PaymentID]);
  }
  delete(PaymentID) {
    let text;
    if (confirm("Do You Want to cancel the Payment?") == true) {
      text = true;
    } else {
      text = false;
    }
    if(true){
      console.log(PaymentID)
      this.Paymentobj={}
      this.Paymentobj.PaymentID=PaymentID;
      this.Paymentobj.PaymentStatus="Cancelled";

      this.apicalls.updatePayment(this.Paymentobj).subscribe(data => {
        if (data.type == true) {
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
          if (data.code == "S001") {
            this.onPage();
          } else if (data.code == "UA01") {
            alert("Your UnAuthorized to Access this Service");
            return;
          }
        } else {
          alert(data.data)
        }
      }, error => { alert(" Internal Server Error "); })

    }



    //this.route.navigate(["/dashboardmain/UpdatePayment", PaymentID]);
  }


  printrecipt(PaymentID) {
    this.route.navigate(["/dashboardmain/UpdatePayment", PaymentID]);
  }
  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
}
