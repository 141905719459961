<body data-gr-c-s-loaded="true">
    <div id="page" class="side-nav-wrapper">

        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pull-right" style="margin-right: 10px;">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                                style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                                <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                                <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                    userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                        class="">Settings</a></li>
                                <li
                                    *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                    <a [routerLink]="" (click)="users()" class="">Users</a></li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="container bg-gray">
            <div class="row">
                <div class="col-md-12">
                    <h5 class="dr-sub-head">Calculate Test Price</h5>
                </div>
            </div>

        </div>


        <div class="row orr-usercreation ptop30 pbtom30">

            <div class="col-md-12">
                <!-- <h5 >Create Hospital</h5> -->
                <div class="panel">
                    <h5>Calculate Test Price</h5>
                    <!-- <h5 style="margin-left:60px;text-align:left;padding-left:15px;margin:0px 0;padding:0px 0; ">Create Hospital</h5> -->


                    <div class="tab-table-customer-entry"
                        style="margin:30px 0;background:#fff;padding:10px 0;border-top:1px solid #ddd;border-bottom:1px solid #ddd;">

                        <form id="contact-form" class="contact-form pad-40" autocomplete="off"
                            [formGroup]="CalTestPriceForm">
                            <div class="form-pad-label" style="padding-top:30px;">




                                <!-- <div style="clear:both;"></div> -->
                                <div class="col-md-6">
                                    <div class="input-text form-group has-feedback">
                                        <label
                                            style="width:180px;float:left;text-align:right;padding-right:15px;">Select
                                            Test List<span class="required"> *</span></label>

                                        <ng-multiselect-dropdown [placeholder]="' Select Test list ... '"
                                            [data]="dropdownList" [(ngModel)]="selectedItems"
                                            [settings]="dropdownSettings" (onSelect)="selectTest($event)"
                                            (onSelectAll)="onSelectAll($event)" [ngModelOptions]="{standalone: true}"
                                            required style="width: 480px">

                                        </ng-multiselect-dropdown>


                                    </div>

                                </div>



                                <div style="clear:both;"></div>
                                <div class="col-md-6">
                                    <div class="input-text form-group has-feedback">
                                        <label
                                            style="width:180px;float:left;text-align:right;padding-right:15px;">Discount
                                            <span class="required"> * </span>
                                        </label>
                                        <input
                                            [class.is-invalid]="Discount.invalid && (Discount.dirty || Discount.touched)"
                                            class="form-control" id="Discount" type="Discount" name="Discount"
                                            placeholder="Discount" formControlName="Discount">
                                        <!-- <div *ngIf="UserID.invalid  && (UserID.dirty || UserID.touched)"> -->
                                        <!-- <small *ngIf="error &&  BillingName.errors?.required"
                                            class="text-danger">Billing Name is required</small> -->
                                        <small *ngIf="Discount.errors?.pattern" class="text-danger">Please enter
                                            valid Discount
                                        </small>

                                    </div>
                                </div>
                                <div style="clear:both;"></div>
                                <div
                                    style="margin:auto;text-align:center;margin-bottom:10px;margin-top:30px;margin-bottom:30px;">
                                    <a [routerLink]="" (click)="onSubmit(CalTestPriceForm)"> <input type="submit"
                                            class="btn-pink" value="CREATE"></a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- <div class="clearfix"></div> -->
            </div>

        </div>
    </div>
</body>
<!-- <ngx-spinner></ngx-spinner> -->