import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.css']
})
export class UpdateUserComponent implements OnInit {
  constructor(private spinner: NgxSpinnerService, private apicalls: ApicallsService, private router: ActivatedRoute, private route: Router, private httpClient: HttpClient) { }
  viewallroles =[];
  AddressObj = {};
  userDetails;
  user
  ngOnInit(): void {

    let data = this.apicalls.getToken();
    this.user = data.data.UserData.Data
    this.apicalls.viewAllRoles()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          this.spinner.hide();
          if(data.code =="S001"){
            for(var i=0;i<data.data.length;i++ ){
              if(data.data[i].Role !="DOCTOR" && data.data[i].Status=="Active"){
                this.viewallroles.push(data.data[i])
              }
            }
         
          }else if(data.code =="S002"){
            alert("No data found")
          }else if(data.code =="UA01"){
            alert("Your UnAuthorized to Access this Service")
          }else if(data.code =="PD01"){
            alert("Permissions Denied")
          }else{
            alert(data.data)
          }
          
        } else {
          this.spinner.hide();
        }
      }, error => {
        error => { alert(" Internal Server Error "); }
      });


    this.router.paramMap.subscribe(params => {
      const MedicID = params.get('MedicID');
      if (MedicID) {
        this.getUserByID(MedicID);
      }
    });

  }

  getUserByID(MedicID) {
    this.apicalls.viewUserByID(MedicID).subscribe(
      data => {
        this.spinner.show();

        if (data.type == true) {
          this.userDetails = data
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
        
          this.updateUserForm.patchValue({
            MedicID: this.userDetails.data.MedicID,
            FirstName: this.userDetails.data.FirstName,
            LastName: this.userDetails.data.LastName,
            Age: this.userDetails.data.Age,
            ContactNumber: this.userDetails.data.ContactNumber,
            RoleID: this.userDetails.data.Roles[0].RoleID,
            EmailID: this.userDetails.data.EmailID,
            Status: this.userDetails.data.Status,
            StreetAddress: this.userDetails.data.Address.StreetAddress,
         
          });
        } else {
          alert(data.message);
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ")
      });
  }
  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }

  updateUserForm = new FormGroup({
    MedicID: new FormControl('', [Validators.required]),
    FirstName: new FormControl('', [Validators.required, Validators.minLength(3), Validators.pattern('[a-zA-Z ]*')]),
    LastName: new FormControl('', [Validators.required, Validators.minLength(3), Validators.pattern('[a-zA-Z ]*')]),
    Age: new FormControl('', [Validators.required, Validators.pattern('[0-9][0-9]{1}')]),
    ContactNumber: new FormControl('', [Validators.required, Validators.pattern('[6789][0-9]{9}'), Validators.minLength(10), Validators.maxLength(10)]),
    RoleID: new FormControl('', [Validators.required]),
    EmailID: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z2-9.-]+\.[a-z]{2,4}$')]),
    StreetAddress: new FormControl('', [Validators.required]),
   
    Roles: new FormArray([]),
    Status: new FormControl('', [Validators.required])
  });

  get MedicID() {
    return this.updateUserForm.get('MedicID');
  }
  get FirstName() {
    return this.updateUserForm.get('FirstName');
  }
  get LastName() {
    return this.updateUserForm.get('LastName');
  }
  get Age() {
    return this.updateUserForm.get('Age');
  }
  get ContactNumber() {
    return this.updateUserForm.get('ContactNumber');
  }
  get EmailID() {
    return this.updateUserForm.get('EmailID');
  }
  get RoleID() {
    return this.updateUserForm.get('RoleID');
  }
  get StreetAddress() {
    return this.updateUserForm.get('StreetAddress');
  }
  // get Colony() {
  //   return this.updateUserForm.get('Colony');
  // }
  get Status() {
    return this.updateUserForm.get('Status');
  }
  // get Locality() {
  //   return this.updateUserForm.get('Locality');
  // }
  // get PinCode() {
  //   return this.updateUserForm.get('PinCode');
  // }
  // get City() {
  //   return this.updateUserForm.get('City');
  // }
  // get State() {
  //   return this.updateUserForm.get('State');
  // }
  get Roles() {
    return this.updateUserForm.get('Roles') as FormArray;
  }
  error;
  TempRoles = [];
  onSubmit(updateUserForm) {
    if (this.updateUserForm.invalid) {
      this.error = true;
      return;
    }
    this.spinner.show();

    this.AddressObj = {
      StreetAddress: this.updateUserForm.value.StreetAddress,
     
    };
    for (let k = 0; k < this.viewallroles.length; k++) {
      if (this.updateUserForm.value.RoleID == this.viewallroles[k].RoleID) {
        if (this.viewallroles[k].Status == "Active") {
          this.TempRoles.push(this.viewallroles[k]);
        }

      }
    }
    this.updateUserForm.value.Address = this.AddressObj;
    this.updateUserForm.value.Roles = this.TempRoles;
    this.apicalls.updateUser(this.updateUserForm.value)
      .subscribe(data => {
        this.spinner.hide();
        if (data.type == true) {
          if (data.code == 'U001') {
           
            alert(data.data)
            this.route.navigate(["/dashboardmain/ViewAllUsers"]);
          }
          if (data.code == 'S002') {
            alert(data.data);
            this.spinner.hide();
          }
        }  else if(data.code =="UA01"){
          alert("Your UnAuthorized to Access this Service")
        }else if(data.code =="PD01"){
          alert("Permissions Denied")
        }else{
          alert(data.data)
        }
      }, error => {
        alert(" Internal Server Error ");
      });
  }

  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
}
