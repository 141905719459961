import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl, FormArray, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-createpermissions',
  templateUrl: './createpermissions.component.html',
  styleUrls: ['./createpermissions.component.css']
})
export class CreatepermissionsComponent implements OnInit {

  viewallresources;
  viewallroles;
  viewRolesByChannelPratherID;
  viewallchannelpartners;
  viewallprivileges;
  dropdownSettings = {};
  dropdownList = [];
  constructor(private spinner: NgxSpinnerService, private httpclient: HttpClient, private formBuilder: FormBuilder, private apicalls: ApicallsService, private route: Router, private router: ActivatedRoute) { }
  detailsResource;
  ResourceName;
  userDetails

  ngOnInit(): void {
    let tokenData = this.apicalls.getToken();
    this.userDetails = tokenData.data.UserData.Data

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'PrivilegeID',
      textField: 'PrivilegeName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    }

    this.apicalls.viewAllResources()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
          this.viewallresources = data;
        } else {
          this.spinner.hide();

        }
      }, error => { alert(" Internal Server Error "); });


    this.apicalls.viewAllRoles()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
          this.viewallroles = data;

        } else {
          this.spinner.hide();
        }
      }, error => {
        error => { alert(" Internal Server Error "); }
      });

    // this.viewRolesByChannelPratherID(){

    // }

    this.router.paramMap.subscribe(params => {
      const ChannelID = params.get('ChannelID');
      if (ChannelID) {
        // this.getPaymentByID(PaymentID);      
        this.apicalls.viewRolesByChannelPratherID(ChannelID).subscribe(
          data => {
            this.spinner.show();
            if (data.type == true) {
              setTimeout(() => {
                this.spinner.hide();
              }, 500);
              if (data.code == "S001") {
                this.viewRolesByChannelPratherID = data.data
              }

            } else {
              alert(data.data);
              this.spinner.hide();
            }
          }, error => {
            alert(" Internal Server Error ")
          });
      }

    });


    this.apicalls.viewAllChannelPartners()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
          this.viewallchannelpartners = data;
        } else {
          this.spinner.hide();
        }
      }, error => { alert(" Internal Server Error "); });


    this.apicalls.viewAllPrivileges()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {
            this.viewallprivileges = data.data;
            let tmp = [];
            for (let j = 0; j < this.viewallprivileges.length; j++) {

              tmp.push({ PrivilegeID: this.viewallprivileges[j].PrivilegeID, PrivilegeName: this.viewallprivileges[j].PrivilegeAliasName, });//PrivilegeName: data.data[j].PrivilegeAliasName

            }
            this.dropdownList = tmp;
            this.spinner.hide();
          }

          if (data.code == 'V002') {
            alert(data.data);
            this.spinner.hide();
          }

        } else {
          alert(data.data)
          this.spinner.hide();
        }
      }, error => { alert(" Internal Server Error "); });


  }

  data: '';
  pathurl: string = 'GokulClinic';
  isSubmitted: boolean = true;
  channelError: string;
  details;
  RoleName;
  channeldetails;
  channelname;
  channelaliasname;
  channelid;
  PermissionArray = [];
  PermissionArray1 = [];
  channelobj = {
    ChannelPartnerID: "",
    ChannelPartnerName: "",
    Privileges: []
  }

  error = false;
  onSubmit(permissionsForm) {
    if (this.permissionsForm.invalid) {
      this.error = true;
      return;
    }
    this.obj=this.selectedItems; 
   
    for (let i = 0; i < this.obj.length; i++) {
      this.obj[i].PrivilegeID = this.obj[i].PrivilegeID;
      this.PermissionArray.push(this.obj[i]);
      this.PermissionArray1.push({ PrivilegeID: this.PermissionArray[i].PrivilegeID, PrivilegeName: this.PermissionArray[i].PrivilegeName });//Privilege: this.PermissionArray[i].Privilege,
    }
    for (let j = 0; j < this.viewallresources.data.length; j++) {
      if (this.permissionsForm.value.ResourceID == this.viewallresources.data[j].ResourceID) {
        this.permissionsForm.value.Resource = this.viewallresources.data[j].ResourceAliasName;
      }
    }
    for (let k = 0; k < this.viewallroles.data.length; k++) {
      if (this.permissionsForm.value.RoleID == this.viewallroles.data[k].RoleID) {
        this.permissionsForm.value.Role = this.viewallroles.data[k].RoleAliasName;
      }
    }
    // for (let l = 0; l < this.viewallchannelpartners.data.length; l++) {
    //   if (this.permissionsForm.value.ChannelID == this.viewallchannelpartners.data[l].ChannelID) {
    //     this.permissionsForm.value.channelname = this.viewallchannelpartners.data[l].Name;
    //   }
    // }
    // this.channelobj.ChannelPartnerID = this.permissionsForm.value.ChannelID;
    // this.channelobj.ChannelPartnerName =  this.permissionsForm.value.channelname;
    this.channelobj.Privileges = this.PermissionArray1
    this.permissionsForm.value.ChannelPartners = []
    this.permissionsForm.value.ChannelPartners.push(this.channelobj);
    this.spinner.show();
    let result = this.apicalls.createPermissions(this.permissionsForm.value)
      .subscribe(data => {
        this.spinner.show();
        //  this.data = data;
        if (data.type == true) {

          if (data.code == 'S001') {
            this.spinner.hide();
            alert(data.data)
            this.route.navigate(["/dashboardmain/permissions"]);
          }
          if (data.code == 'L005') {
            this.spinner.hide();
            alert(data.data)
            this.route.navigate(["/dashboardmain/permissions"]);
          }
          if (data.code == 'S002') {
            this.spinner.hide();
            alert(data.data);
            this.isSubmitted = false;
            return false;
          }
          if (data.code == 'AE01') {
            this.spinner.hide();
            alert(data.data);
            this.isSubmitted = false;
            return false;
          }
        }
        else {
          this.spinner.hide();
          this.isSubmitted = false;
          alert(data.data)
          this.channelError = data.data;
          return false;
        }
      }, error => { alert(" Internal Server Error "); });
  }




  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }


  get ResourceID() {
    return this.permissionsForm.get('ResourceID');
  }
  get Resource() {
    return this.permissionsForm.get('Resource');
  }
  get RoleID() {
    return this.permissionsForm.get('RoleID');
  }

  get ChannelPartnerID() {
    return this.permissionsForm.get('ChannelPartnerID');
  }
  get PrivilegeID() {
    return this.permissionsForm.get('PrivilegeID');
  }

  get ChannelID() {
    return this.permissionsForm.get('ChannelID');
  }

  permissionsForm = new FormGroup({
    // Resource: new FormControl(''),
    ResourceID: new FormControl('', [Validators.required]),
    //  Role: new FormControl(''),
    ChannelID: new FormControl(''),
    // PrivilegeID: new FormControl(''),
    RoleID: new FormControl('', [Validators.required]),
    // ChannelPartners: new FormArray([
    //   this.addChannelPartnerFormGroup()
    // ])



  });

  addChannelPartnerFormGroup(): FormGroup {
    return this.formBuilder.group({

      ChannelPartnerID: new FormControl('', [Validators.required]),
      ChannelPartnerName: new FormControl(''),
      Privileges: new FormArray([
        this.addPrivilegesFormGroup()
      ])


    });
  }
  addPrivilegesFormGroup(): FormGroup {
    return this.formBuilder.group({

      PrivilegeID: new FormControl('', [Validators.required]),
      PrivilegeAliasName: new FormControl('', [Validators.required]),
      PrivilegeName: new FormControl('', [Validators.required]),


    });
  }


  obj = [];
  obj1 = [];
  selectedItems = [];
  selectPrivilege(value) {
    this.obj = this.selectedItems
  }

  onItemSelect(value: any) {
  }
  onSelectAll(dropdownList) {
    this.obj = this.dropdownList

  }
  selectPrivilegeType(value) {

  }
  onItemDeSelect(value) {
    this.obj = this.selectedItems

  }
  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }

}
