<body data-gr-c-s-loaded="true">
    <div id="page" class="side-nav-wrapper">

        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pull-right" style="margin-right: 10px;">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                                style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                                <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                                <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                    userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                        class="">Settings</a></li>
                                <li
                                    *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                    <a [routerLink]="" (click)="users()" class="">Users</a></li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="container bg-gray">
            <div class="row">
                <div class="col-md-12">
                    <h5 class="dr-sub-head">Create Doctor</h5>
                </div>
            </div>
            <div class="row orr-usercreation ptop30 pbtom30">

                <div class="col-md-12">
                    <!-- <h5 >Create Hospital</h5> -->
                    <div class="panel">
                        <h5>Update Doctor</h5>
                        <!-- <h5 style="margin-left:60px;text-align:left;padding-left:15px;margin:0px 0;padding:0px 0; ">Create Hospital</h5> -->

                        <div class="tab-table-customer-entry"
                            style="margin:30px 0;background:#fff;padding:10px 0;border-top:1px solid #ddd;border-bottom:1px solid #ddd;">

                            <form id="contact-form" class="contact-form pad-40" autocomplete="off"
                                [formGroup]="doctorForm">
                                <div class="form-pad-label" style="padding-top:30px;">


                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">First
                                                Name<span class="required"> * </span></label>
                                            <input
                                                [class.is-invalid]="FirstName.invalid && (FirstName.dirty || FirstName.touched)"
                                                class="form-control" id="FirstName" type="FirstName" name="FirstName"
                                                placeholder=" First Name " formControlName="FirstName"
                                                style="float:left;width:70%;">
                                            <!-- <div *ngIf="FirstName.invalid  && (FirstName.dirty || FirstName.touched)"> -->
                                            <small *ngIf="error && FirstName.errors?.required"
                                                class="text-danger">FirstName is
                                                required</small>
                                            <small *ngIf="error && FirstName.errors?.minlength"
                                                class="text-danger">FirstName should be minimum 3 characters
                                            </small>
                                            <small *ngIf="error && FirstName.errors?.pattern"
                                                class="text-danger">FirstName should be alphabets
                                            </small>

                                            <!-- </div> -->
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Last
                                                Name<span class="required"> * </span></label>
                                            <input
                                                [class.is-invalid]="LastName.invalid && (LastName.dirty || LastName.touched)"
                                                class="form-control" id="LastName" type="LastName" name="LastName"
                                                placeholder=" Last Name " formControlName="LastName"
                                                style="float:left;width:70%;">
                                            <!-- <div *ngIf="LastName.invalid  && (LastName.dirty || LastName.touched)"> -->
                                            <small *ngIf="error && LastName.errors?.required"
                                                class="text-danger">LastName is
                                                required</small>
                                            <small *ngIf="error && LastName.errors?.pattern"
                                                class="text-danger">LastName should be alphabets</small>

                                            <!-- </div> -->
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                   


                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Age<span
                                                    class="required"> * </span></label>
                                            <input [class.is-invalid]="Age.invalid && (Age.dirty || Age.touched)"
                                                class="form-control" id="Age" type="Age" name="Age" placeholder="Age "
                                                formControlName="Age">

                                            <small *ngIf="error && Age.errors?.required" class="text-danger">Age is
                                                required</small>
                                            <small *ngIf="error && Age.errors?.pattern" class="text-danger">Please enter
                                                valid Age</small>


                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>

                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">EmailID<span
                                                    class="required"> * </span></label>
                                            <input
                                                [class.is-invalid]="EmailID.invalid && (EmailID.dirty || EmailID.touched)"
                                                class="form-control" id="EmailID" type="EmailID" name="EmailID"
                                                placeholder=" Email-ID " formControlName="EmailID">

                                            <!-- <div *ngIf="EmailID.invalid  && (EmailID.dirty || EmailID.touched)"> -->
                                            <small *ngIf="error && EmailID.errors?.required" class="text-danger">EmailID
                                                is
                                                required</small>
                                            <small *ngIf="error && EmailID.errors?.pattern" class="text-danger">Please
                                                enter valid EmailID</small>

                                            <!-- </div> -->
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Phone
                                                Number<span class="required"> * </span></label>
                                            <input
                                                [class.is-invalid]="ContactNumber.invalid && (ContactNumber.dirty || ContactNumber.touched)"
                                                class="form-control" id="ContactNumber" type="ContactNumber"
                                                name="ContactNumber" placeholder=" Phone Number "
                                                formControlName="ContactNumber">

                                            <small *ngIf="error && ContactNumber.errors?.required"
                                                class="text-danger">Phone Number is
                                                required</small>
                                            <small *ngIf="error && ContactNumber.errors?.pattern"
                                                class="text-danger">Please enter valid Phone Number</small>
                                            <small *ngIf="error && ContactNumber.errors?.minlength"
                                                class="text-danger">Phone Number should be 10 digits</small>
                                            <small *ngIf="error && ContactNumber.errors?.maxlength"
                                                class="text-danger">Phone Number should be 10 digits</small>

                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                 

                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Specialization<span
                                                    class="required"> * </span></label>
                                            <input
                                                [class.is-invalid]="Specialization.invalid && (Specialization.dirty || Specialization.touched)"
                                                class="form-control" id="Specialization" type="Specialization"
                                                name="Specialization" placeholder=" Specialization "
                                                formControlName="Specialization">

                                            <small *ngIf="error && Specialization.errors?.required"
                                                class="text-danger">Specialization is
                                                required</small>

                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>

                           
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Registration
                                                Number<span class="required"> * </span></label>
                                            <input
                                                [class.is-invalid]="RegNumber.invalid && (RegNumber.dirty || RegNumber.touched)"
                                                class="form-control" id="RegNumber" type="RegNumber" name="RegNumber"
                                                placeholder=" RegNumber " formControlName="RegNumber">

                                            <small *ngIf="error && RegNumber.errors?.required"
                                                class="text-danger">Registration Number is
                                                required</small>
                                            <small *ngIf="error && RegNumber.errors?.pattern"
                                                class="text-danger">Registration Number should be alphanumeric
                                            </small>

                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">FollowUp
                                                Days <span class="required"> * </span></label>
                                            <input
                                                [class.is-invalid]="FollowUpDays.invalid && (FollowUpDays.dirty || FollowUpDays.touched)"
                                                class="form-control" id="FollowUpDays" type="FollowUpDays"
                                                name="FollowUpDays" placeholder=" FollowUpDays "
                                                formControlName="FollowUpDays">

                                            <small *ngIf="error && FollowUpDays.errors?.required"
                                                class="text-danger">FollowUpDays is
                                                required</small>
                                            <small *ngIf="error && FollowUpDays.errors?.pattern"
                                                class="text-danger">FollowUpDays should be number
                                            </small>

                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>

                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">

                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Address<span
                                                    class="required"> </span></label>
                                            <input
                                                [class.is-invalid]="StreetAddress.invalid && (StreetAddress.dirty || StreetAddress.touched)"
                                                class="form-control" id="StreetAddress" type="StreetAddress"
                                                name="StreetAddress" placeholder=" Street Address "
                                                formControlName="StreetAddress">
                                           
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                  

                                    <div
                                        style="margin:auto;text-align:center;margin-bottom:10px;margin-top:30px;margin-bottom:30px;">
                                        <a [routerLink]="" (click)="onSubmit(doctorForm)"> <input type="submit"
                                                class="btn-pink" value="SAVE"></a>

                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>

            </div>
        </div>

        <script>

            var base_url = 'http://drgokulreddy.in/';
            function emp_entry() {

                var flag = 0;
                var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
                var alpha = /^[a-zA-Z\s]+$/;
                var email = $("#email").val();
                var regex = /^[0-9]*(?:\.\d{1,2})?$/;

                if ($("#name").val() == '') {
                    $("#name_error").html('Enter Name');
                    flag = 1;
                }
                else {
                    if (!alpha.test($("#name").val())) {
                        $("#name_error").html('Enter only Alphabets');
                        flag = 1;
                    }
                    else {
                        $("#name_error").html('');
                    }

                }
                if ($("#email").val() == '') {
                    $("#email_error").html('Enter email');
                    flag = 1;
                }
                else if (!pattern.test($("#email").val())) {
                    $("#email_error").html('Enter valid  Email');
                    flag = 1;
                }
                else {
                    $("#email_error").html('');
                }





                if ($("#pass").val() == '') {
                    $("#pass_error").html('Enter Password');
                    flag = 1;
                }
                else {
                    $("#pass_error").html('');

                }


                if ($("#utype").val() == '') {
                    $("#utype_error").html('Select User Type');
                    flag = 1;
                }
                else {
                    $("#utype_error").html('');

                }
                if (flag) {
                    $(".error").each(function () {
                        if ($(this).html().length > 0) {

                            var str = $(this).attr('id');
                            var res = str.split("_");
                            $("#" + res[0]).focus();
                            return false;
                        }

                    })
                    return false;
                }

            }


        </script>
        <script src="./GOKUL CLINIC - Add User page_files/jquery.dataTables.min.js.download"></script>
        <script src="./GOKUL CLINIC - Add User page_files/dataTables.bootstrap.min.js.download"></script>
        <style type="text/css">
            @media print {


                #get-quote,
                .upper {
                    display: none;
                }
            }
        </style>
        <script type="text/javascript">
            $('.print').click(function () {
                window.print();
                //printDiv();

            });
            function printDiv() {

                var divToPrint = document.getElementById('divtoprint');

                var newWin = window.open('', 'Print-Window');

                newWin.document.open();

                newWin.document.write('<html><body onload="window.print()">' + divToPrint.innerHTML + '</body></html>');

                newWin.document.close();

                setTimeout(function () { newWin.close(); }, 10);

            }
            $(document).ready(function () {
                $('#example').DataTable();
            });
            $(document).ready(function () {
                $(".alert").fadeTo(2000, 500).slideUp(500, function () {
                    $(".alert").slideUp(500);
                });
            });
        </script>
    </div>
    <script src="./GOKUL CLINIC - Add User page_files/tabs.js.download"></script>





</body>
<ngx-spinner></ngx-spinner>