<app-header></app-header>
<nav class="navbar navbar-inverse">
    <div>
        <div style="position:relative;">
            <div>
                <div class="collapse navbar-collapse" id="myNavbar" style="float:left;padding:0px;clear:both;width:100%;background:#2d3e50;">
                    <div>
                        <ul class="nav navbar-nav ag-menu sm pl40" style="float:left;" data-smartmenus-id="14811307330159312">
                            <li class=""><a href="">HOME</a>
                            </li>
                            <li class="{{openlist}}"  (mouseover)='over()' (mouseout)='out()'><a href="" class="has-submenu">MYPRACTICE  </a>
                                <ul class="dropdown-menu sm-nowrap" >
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Dr Gokul reddy</a>
                                                    </div>
                                                    <div>
                                                        <a class="" routerLink="/practice-locations"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>Practice Locations</a>
                                                    </div>
                                                    <!-- <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Book  appointment</a>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                            </li>
                            <li class="{{openlist1}}" (mouseover)='over1()' (mouseout)='out1()'><a class="has-submenu" href="#" >PATIENT INFO </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/about-cholesterol"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>ABOUT CHOLESTEROL</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/angioplasty"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> ANGIOPLASTY</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/chestpain"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CHESTPAIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/common-misconceptions"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>COMMON MISCONCEPTIONS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/coronary-angiogram"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CORONARY ANGIOGRAM</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/exercsie-and-heart-health"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> EXERCSIE & HEART HEALTH</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/guide-to-taking-warfarin"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> GUIDE TO TAKING WARFARIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-attack"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTATTACK</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-fauilre"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTFAUILRE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/high-blood-pressure"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HIGH BLOOD PRESSURE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/htn-myths"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>HTN-MYTHS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/tips-to-lower"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>TIPS  TO LOWER CHOLESTEROL   LEVELS</a> </div>



                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li><a href="#">SERVICES</a></li>
                            <li><a href="#">APPOINTMENTS</a></li>
                            <li class=""><a  href="faq">FAQS </a></li>
                            <li class=""><a  href="blog">MY BLOG</a></li>

                            <li class=""> <a  href="reach-us">REACH US</a></li>

                            <div class="clearfix"></div>
                        </ul>
                        <div class="pull-right pn">
                            <a href="#"><img src="/../../assets/front/img/askdemo.png" style="height:52px;"/></a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</nav>

<section style="background:#fff;">

    <div class="container">

        <div  style="padding:30px 15px;">

            <div class="row">

                <h4 class="mb5" style="color: #0991cd;font-weight: bold;">HEART ATTACK</h4>

                <!--<p class="pull-right"><img src="img/gokulreddy.png" style="height:350px;"/></p>-->

                <p>

                    A heart attack occurs when the flow of blood to the heart is blocked, most often by a Clot on top of a plaque which is a build-up of fat, cholesterol and other substances, in the arteries that feed the heart (coronary arteries). The interrupted blood flow can damage or destroy part of the heart muscle.

                </p>



                <p>A heart attack, also called a myocardial infarction, can be fatal, but treatment has improved dramatically over the years.</p>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Symptoms</h5>



                <p>

                    Common heart attack signs and symptoms include:

                </p>





                <ul class="list_1">

                    <li>Pressure, tightness, pain, or a squeezing or aching sensation in your chest or arms that may spread to your neck, jaw or back</li>

                    <li>Nausea, indigestion, heartburn or abdominal pain</li>

                    <li>Shortness of breath</li>

                    <li>Cold sweat</li>

                    <li>Fatigue</li>

                    <li>Lightheadedness or sudden dizziness</li>



                </ul>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">  Heart attack symptoms vary</h5>





                <p>Not all people who have heart attacks have the same symptoms or have the same severity of symptoms. Some people have mild pain; others have more severe pain. Some people have no symptoms, while for others, the first sign may be sudden cardiac arrest</p>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;"> Causes</h5>



                <p>A heart attack occurs when one or more of your coronary arteries become blocked. Over time, a coronary artery can narrow from the buildup of various substances, including cholesterol (atherosclerosis). This condition, known as coronary artery disease, causes most heart attacks.</p>



                <p>During a heart attack, one of these plaques can rupture and spill cholesterol and other substances into the bloodstream. A blood clot forms at the site of the rupture. If large enough, the clot can completely block the flow of blood through the coronary artery.</p>



                <p>Another cause of a heart attack is a spasm of a coronary artery that shuts down blood flow to part of the heart muscle. Use of tobacco and of illicit drugs, such as cocaine, can cause a life-threatening spasm. A heart attack can also occur due to a tear in the heart artery (spontaneous coronary artery dissection)</p>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;"> Risk factors</h5>



                <p>    Heart attack risk factors include</p>



                <ul class="list_1">

                    <li><b>Age.</b> Men age 45 or older and women age 55 or older are more likely to have a heart attack than are younger men and women.</li>

                    <li><b>Tobacco.</b> Smoking and long-term exposure to secondhand smoke increase the risk of a heart attack.</li>

                    <li><b>High blood pressure.</b> Over time, high blood pressure can damage arteries that feed your heart by accelerating atherosclerosis.</li>

                    <li><b>High blood cholesterol or triglyceride levels.</b> A high level of low-density lipoprotein (LDL) cholesterol (the "bad" cholesterol) is most likely to narrow arteries. A high level of triglycerides, a type of blood fat related to your diet, also ups your risk of heart attack. However, a high level of high-density lipoprotein (HDL) cholesterol (the "good" cholesterol) lowers your risk of heart attack.</li>

                    <li><b>Diabetes.</b> Insulin, a hormone secreted by your pancreas, allows your body to use glucose, a form of sugar. Having diabetes — not producing enough insulin or not responding to insulin properly — causes your body's blood sugar levels to rise. Diabetes, especially uncontrolled, increases your risk of a heart attack.</li>



                    <li><b>Family history of heart attack.</b> If your siblings, parents or grandparents have had early heart attacks (by age 55 for male relatives and by age 65 for female relatives), you may be at increased risk.

                    </li>

                    <li><b>Lack of physical activity , Obesity & Stress</b> are also very important contributors for the build-up of plaque and its rupture</li>

                </ul>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Complications</h5>



                <p>Complications are often related to the damage done to your heart during an attack. Damage can lead to:</p>



                <ul class="list_1">

                    <li><b>Abnormal heart rhythms (arrhythmias).</b> Electrical "short circuits" can develop, resulting in abnormal heart rhythms, some of which can be serious, even fatal.</li>

                    <li><b>Heart failure.</b> An attack may damage so much heart tissue that the remaining heart muscle can't adequately pump blood out of your heart. Heart failure may be temporary, or it can be a chronic condition resulting from extensive and permanent damage to your heart.</li>

                    <li><b>Heart rupture.</b> Areas of heart muscle weakened by a heart attack can rupture, leaving a hole in part of the heart. This rupture is often fatal.</li>

                    <li><b>Valve problems.</b> Heart valves damaged during a heart attack may develop severe leakage problems</li>



                </ul>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;"> Tests and diagnosis</h5>



                <p>Tests will help check if your signs and symptoms, such as chest pain, indicate a heart attack or another condition. These tests include:</p>





                <ul class="list_1">

                    <li><b>Electrocardiogram (ECG).</b> This first test done to diagnose a heart attack records the electrical activity of your heart via electrodes attached to your skin. Impulses are recorded as waves displayed on a monitor or printed on paper. Because injured heart muscle doesn't conduct electrical impulses normally, the ECG may show that a heart attack has occurred or is in progress.</li>



                    <li><b>Blood tests.</b> Certain heart enzymes slowly leak out into your blood if your heart has been damaged by a heart attack. Emergency room doctors will take samples of your blood to test for the presence of these enzymes.</li>



                </ul>

                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Treatments and drugs</h5>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Heart attack treatment at a hospital</h5>



                <p>With each passing minute after a heart attack, more heart tissue loses oxygen and deteriorates or dies. The main way to prevent heart damage is to restore blood flow quickly.</p>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Medications</h5>



                <p>Medications given to treat a heart attack include:</p>





                <ul class="list_1">

                    <li><b>Aspirin </b>and Other Anti-Platelet Drugs. Aspirin and other anti-platelet drugs like Clopidigrel reduces blood clotting, thus helping maintain blood flow through a narrowed artery.</li>



                    <li> <b>Thrombolytics.</b> These drugs, also called clot-busters, help dissolve a blood clot that's blocking blood flow to your heart. The earlier you receive a thrombolytic drug after a heart attack, the greater the chance you'll survive and with less heart damage.</li>



                    <li><b>Other blood-thinning medications.</b> You'll likely be given other medications, such as heparin, to make your blood less "sticky" and less likely to form clots. Heparin is given intravenously or by an injection under your skin.</li>



                    <li><b>Pain relievers.</b> You may receive a pain reliever, such as morphine, to ease your discomfort.</li>



                    <li><b>Nitroglycerin.</b> This medication, used to treat chest pain (angina), can help improve blood flow to the heart by widening (dilating) the blood vessels.</li>



                    <li><b>Beta blockers.</b> These medications help relax your heart muscle, slow your heartbeat and decrease blood pressure, making your heart's job easier</li>



                    <li><b>ACE inhibitors.</b> These drugs lower blood pressure and reduce stress on the heart.</li>



                </ul>







                <p><i>“Currently the best Available treatment for a heart attack is Primary Angioplasty if can be done with in the first 24 hours to Open the Blocked artery and restore the Blood flow to minimize the damage done to the heart and improve heart function and the probability to survive the Heart Attack “ </i></p>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Procedures</h5>



                <p>In addition to medications, you may undergo one of the following procedures to treat your heart attack once stabilized:</p>





                <ul class="list_1">

                    <li><b>Coronary angioplasty and stenting. </b>Doctors insert a long, thin tube (catheter) that's passed through an artery, usually in your leg or groin, to a blocked artery in your heart. If you've had a heart attack, this procedure is often done immediately after a cardiac catheterization, a procedure used to locate blockages.

                        This catheter is equipped with a special balloon that, once in position, is briefly inflated to open a blocked coronary artery. A metal mesh stent may be inserted into the artery to keep it open long term, restoring blood flow to the heart. Depending on your condition, your doctor may opt to place a stent coated with a slow-releasing medication to help keep your artery open.</li>



                    <li> <b>Coronary artery bypass surgery.</b> In some cases, doctors may perform emergency bypass surgery at the time of a heart attack. If possible, your doctor may suggest that you have bypass surgery after your heart has had time — about three to seven days — to recover from your heart attack.</li>



                </ul>



                <p>Once blood flow to your heart is restored and your condition is stable, you're likely to remain in the hospital for several days</p>





            </div>



        </div>

    </div>

    

</section>
<app-footer></app-footer>