import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl, FormArray, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-createappointmentforexistpatients',
  templateUrl: './createappointmentforexistpatients.component.html',
  styleUrls: ['./createappointmentforexistpatients.component.css']
})
export class CreateappointmentforexistpatientsComponent implements OnInit {

  viewalldoctors;
  viewallhospitals;
  constructor(private spinner: NgxSpinnerService, private httpclient: HttpClient, private formBuilder: FormBuilder, private apicalls: ApicallsService, private route: Router, private router: ActivatedRoute) { }
  userDetails
  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data
    this.apicalls.viewAllHospitals()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {
            setTimeout(() => {
              this.spinner.hide();
            }, 500);
            this.viewallhospitals = data;
            console.log("DATAAAAAAAAAAAAAAA", this.viewallhospitals);
            //  this.dtTrigger.next();
          } else if (data.code == "PD01") {
            alert("Permissions Denied");
            return;
          }
          else if (data.code == "UA01") {
            alert("Your UnAuthorized to Access this Service");
            return;
          } else if (data.code == "S001") {
            alert("No Data Found");
            return;
          }
        } else {

          //  this.alertService.info(data.message)
          alert(data.data);
          this.spinner.hide();

        }
      }, error => {
        error => { alert(" Internal Server Error "); }
        // this.alertService.warning(" Internal Server Error ");
      });

    this.apicalls.viewAllDoctors()
      .subscribe(data => {
        this.spinner.show();
        console.log("RRRRRRRRRRRRRRRRr", data);
        if (data.type == true) {
          if (data.code == 'S001') {
            this.viewalldoctors = data;
            console.log("viewalldoctors...........", this.viewalldoctors);
            this.spinner.hide();
          } else if (data.code == "PD01") {
            alert("Permissions Denied");
            return;
          }
          else if (data.code == "UA01") {
            alert("Your UnAuthorized to Access this Service");
            return;
          }

          if (data.code == 'V002') {

            alert(data.data);
            this.spinner.hide();
            //  this.dtTrigger.next();
          }

        } else {
          alert(data.data)
          //  this.alertService.info(data.message)
          this.spinner.hide();

        }
      },
        error => {
          alert(" Internal Server Error ");
          // this.alertService.warning(" Internal Server Error ");
        });

  }

  data: '';
  pathurl: string = 'GokulClinic';
  isSubmitted: boolean = true;
  //loggedIn:boolean=true;
  channelError: string;
  AddressObj = {};

  onSubmit(appointmentForm) {
    console.log("@@@@@@@hospital", this.appointmentForm.value);

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 5000);


    // this.AddressObj={
    //   StreetAddress:  this.appointmentForm.value.StreetAddress,
    //   Colony: this.appointmentForm.value.Colony,
    //   Locality: this.appointmentForm.value.Locality,
    //   PinCode:  this.appointmentForm.value.PinCode,
    //   City:this.appointmentForm.value.City,
    //   State:this.appointmentForm.value.State,
    //   Country:this.appointmentForm.value.Country,
    //   Latitude:this.appointmentForm.value.Latitude,
    //   Longitude:this.appointmentForm.value.Longitude,
    //   LocationLink:this.appointmentForm.value.LocationLink
    // };
    //   this.appointmentForm.value.Address=this.AddressObj;

    console.log("DATATATATATATATATATA", this.appointmentForm.value)
    let result = this.apicalls.createAppointments(this.appointmentForm.value)
      .subscribe(data => {
        this.spinner.show();
        //  this.data = data;
        if (data.type == true) {

          if (data.code == 'S001') {
            this.spinner.hide();
            alert(data.data)
            console.log("!!!!!@@@@@@@@@@@!!!1", data);
            this.route.navigate(["/dashboardmain/appointments"]);
          }
          //    if (data.code == 'L005') {
          //     this.spinner.hide();
          //     alert(data.data)
          // // console.log("!!!!!@@@@@@@@@@@!!!1",data);        
          //    this.route.navigate(["/dashboardmain/hospitals"]);
          //   }
          if (data.code == 'S002') {
            this.spinner.hide();
            alert(data.data);
            this.isSubmitted = false;
            return false;
            //   console.log("!!!!!@@@@@@@@@@@!!!1",data);        
            // this.route.navigate(["/dashboardmain/channelpartners"]);
          } else if (data.code == "PD01") {
            alert("Permissions Denied");
            return;
          }
          else if (data.code == "UA01") {
            alert("Your UnAuthorized to Access this Service");
            return;
          }

        }
        else {
          // "code":"L005"
          // console.log("!!!!!!!!!!!!!1", data.data);
          this.spinner.hide();
          this.isSubmitted = false;
          alert(data.data)
          this.channelError = data.data;

          return false;
          //  alert( data.data)

        }

        // }
      },
        error => { alert(" Internal Server Error "); }
      );

  }

  get MedicID() {
    return this.appointmentForm.get('MedicID');
  }
  get DoctorName() {
    return this.appointmentForm.get('DoctorName');
  }

  // get Age() {
  //   return this.appointmentForm.get('Age');
  // }
  // get Gender() {
  //   return this.appointmentForm.get('Gender');
  // }
  // get PatientName() {
  //   return this.appointmentForm.get('PatientName');
  // }
  // get District() {
  //   return this.appointmentForm.get('District');
  // }
  get HospitalID() {
    return this.appointmentForm.get('HospitalID');
  }
  get HospitalName() {
    return this.appointmentForm.get('HospitalName');
  }
  // get StreetAddress() {
  //   return this.appointmentForm.get('StreetAddress');
  // }
  // get Colony() {
  //   return this.appointmentForm.get('Colony');
  // }
  // get Locality() {
  //   return this.appointmentForm.get('Locality');
  // }
  // get PinCode() {
  //   return this.appointmentForm.get('PinCode');
  // }
  // get City() {
  //   return this.appointmentForm.get('City');
  // }
  // get State() {
  //   return this.appointmentForm.get('State');
  // }
  // get Country() {
  //   return this.appointmentForm.get('Country');
  // }

  get Date() {
    return this.appointmentForm.get('Date');
  }
  get Time() {
    return this.appointmentForm.get('Time');
  }
  get Fee() {
    return this.appointmentForm.get('Fee');
  }
  get PaymentStatus() {
    return this.appointmentForm.get('PaymentStatus');
  }
  get MRNumber() {
    return this.appointmentForm.get('MRNumber');
  }


  get DoctorID() {
    return this.appointmentForm.get('DoctorID');
  }

  appointmentForm = new FormGroup({
    MRNumber: new FormControl('', [Validators.required]),
    MedicID: new FormControl('', [Validators.required]),
    DoctorID: new FormControl('', [Validators.required]),
    DoctorName: new FormControl(''),
    PatientName: new FormControl('', [Validators.required]),
    HospitalID: new FormControl('', [Validators.required]),
    HospitalName: new FormControl('', [Validators.required]),
    // HospitalName: new FormControl('', [Validators.required]),
    Date: new FormControl('', [Validators.required]),
    Time: new FormControl('', [Validators.required]),
    Fee: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
    PaymentStatus: new FormControl('', [Validators.required]),
    // Name : new FormControl('', [Validators.required, Validators.minLength(3),Validators.pattern('[a-zA-Z ]*')]),
    // DOB : new FormControl('', [Validators.required]),
    // Age : new FormControl('', [Validators.required, Validators.pattern('[0-9][0-9]{1}')]),
    // Gender : new FormControl('', [Validators.required]),
    // ContactNumber : new FormControl('',[Validators.required, Validators.pattern('[6789][0-9]{9}'),Validators.minLength(10), Validators.maxLength(10)]),
    // EmailID : new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z2-9.-]+\.[a-z]{2,4}$')]),
    // RefferedBy : new FormControl('', [Validators.required]),
    // District : new FormControl('', [Validators.required]),
    // Hospital : new FormControl('', [Validators.required]),
    // AliasName : new FormControl('', [Validators.required,Validators.minLength(10)]),
    // SecondryContactNumber : new FormControl('',[Validators.required, Validators.pattern('[6789][0-9]{9}'),Validators.minLength(10), Validators.maxLength(10)]),
    // Address:new FormArray([
    //   this.addAddressFormGroup()
    // ]),
    // Address: new FormGroup({
    //   StreetAddress:new FormControl('', [Validators.required]),
    // Colony: new FormControl('', [Validators.required]),
    // Locality: new FormControl('', [Validators.required]),
    // PinCode: new FormControl('',[Validators.required,Validators.pattern('[0-9]{6}')]),
    // City: new FormControl('', [Validators.required]),
    // State: new FormControl('', [Validators.required]),
    // Country: new FormControl('', [Validators.required]),

    // Latitude: new FormControl(''),
    // Longitude: new FormControl(''),
    // LocationLink: new FormControl('')  
    // }),
    // StreetAddress:new FormControl('', [Validators.required]),
    // Colony: new FormControl('', [Validators.required]),
    // Locality: new FormControl('', [Validators.required]),
    // PinCode: new FormControl('',[Validators.required,Validators.pattern('[0-9]{6}')]),
    // City: new FormControl('', [Validators.required]),
    // State: new FormControl('', [Validators.required]),
    // Country: new FormControl('', [Validators.required]),

    // Latitude: new FormControl(''),
    // Longitude: new FormControl(''),
    // LocationLink: new FormControl('')  

  });


  // addAddressFormGroup(): FormGroup {
  //   return this.formBuilder.group({
  //   StreetAddress:new FormControl('', [Validators.required]),
  //   Colony: new FormControl('', [Validators.required]),
  //   Locality: new FormControl('', [Validators.required]),
  //   PinCode: new FormControl('', [Validators.required,Validators.pattern('[0-9]{6}')]),
  //   City: new FormControl('', [Validators.required]),
  //   State: new FormControl('', [Validators.required]),
  //   Country: new FormControl('', [Validators.required]),

  //   Latitude: new FormControl(''),
  //   Longitude: new FormControl(''),
  //   LocationLink: new FormControl('')         

  //   });
  // }
  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }



  detailshospital;
  onOptionsSelectedhospital(value: string) {
    //console.log("the selected hospital is " + value);
    this.spinner.show();
    this.apicalls.viewHospitalsById(value).subscribe(
      data => {
        this.spinner.show();
        // if (data.success) {
        //   setTimeout(() => {
        //     /** spinner ends after 5 seconds */
        //     this.spinner.hide();
        //   }, 2000);

        if (data.type == true) {

          if (data.code == "S001") {


            this.detailshospital = data
            //console.log("HOOOOOOSpital@@@@@@@@@@@@@", this.detailshospital)
            setTimeout(() => {
              /** spinner ends after 5 seconds */
              this.spinner.hide();
            }, 500);
            this.appointmentForm.patchValue({

              HospitalID: this.detailshospital.data.HospitalID,
              HospitalName: this.detailshospital.data.HospitalName,



            });
          } else if (data.code == "PD01") {
            alert("Permissions Denied");
            return;
          }
          else if (data.code == "UA01") {
            alert("Your UnAuthorized to Access this Service");
            return;
          } else if (data.code == "S002") {
            alert("No Data Found");
            this.spinner.hide();
          }
        } else {
          alert(data.message);
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ")
      });


  }
  doctorsdetails;
  onOptionsSelected(value: string) {
    //console.log("the selected value is " + value);
    // this.appointmentForm.value.MedicID=value;
    // this.appointmentForm.patchValue({
    //   MedicID:value
    // });

    this.apicalls.viewDoctorsById(value).subscribe(
      data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == "S001") {

            this.doctorsdetails = data
            //console.log("DDDDData", this.doctorsdetails)
            setTimeout(() => {
              /** spinner ends after 5 seconds */
              this.spinner.hide();
            }, 500);
            this.appointmentForm.patchValue({

              MedicID: this.doctorsdetails.data.MedicID,
              DoctorName: this.doctorsdetails.data.FirstName,

              Fee: this.doctorsdetails.data.Fee,
              //  Specialization:this.doctorsdetails.data.Specialization,

              //   StreetAddress:this.doctorsdetails.data.Address.StreetAddress,

              //   Status: this.doctorsdetails.data.Status



            });
          } else if (data.code == "PD01") {
            alert("Permissions Denied");
            return;
          }
          else if (data.code == "UA01") {
            alert("Your UnAuthorized to Access this Service");
            return;
          } else if (data.code == "S001") {
            alert("No Data Found");
            return;
          }
        } else {
          alert(data.message);
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ")
      });

  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }
}



