<!DOCTYPE html>
<!-- saved from url=(0031)http://drgokulreddy.in/patients -->
<html lang="en">

<body>
    <div id="page" class="side-nav-wrapper">
        <!-- Simple Navbar -->
        <!-- Simple Navbar -->
        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pull-right" style="margin-right: 10px;">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                                style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                                <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                                <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                    userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                        class="">Settings</a></li>
                                <li
                                    *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                    <a [routerLink]="" (click)="users()" class="">Users</a></li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="container bg-gray">
            <div class="row">
                <div class="col-md-4">
                    <h5 class="dr-sub-head">Upload Helath Reports</h5>
                </div>
            </div>
            <div class="row orr-usercreation ptop30 pbtom30">
                <div class="col-md-12">
                    <div class="panel">
                        <div class="tab-table-customer-entry"
                            style="margin:30px 0;background:#fff;padding:10px 0;border-top:1px  #ddd;border-bottom:1px  #ddd;">

                            <form id="contact-form" class="contact-form pad-40" autocomplete="off"
                                [formGroup]="healthReportForm">
                                <div class="col-md-6">
                                    <div class="input-text form-group has-feedback">

                                        <label
                                            style="width:180px;float:left;text-align:right;padding-right:15px;">Patient
                                            Name<span class="required"> * </span></label>
                                        <input
                                            [class.is-invalid]="PatientName.invalid && (PatientName.dirty || PatientName.touched)"
                                            class="form-control" id="PatientName" type="PatientName" name="PatientName"
                                            placeholder=" PatientName " formControlName="PatientName" readonly disabled>
                                        <!-- <div *ngIf="PatientName.invalid  && (PatientName.dirty || PatientName.touched)"> -->
                                        <small *ngIf=" error && PatientName.errors?.required"
                                            class="text-danger">PatientName
                                            is required</small>
                                        <!-- <small *ngIf="DOB.errors?.minlength" class="text-danger">Hospital Branch should be minimum 3 characters   </small> -->

                                        <!-- </div> -->
                                        <!-- <input type="text" name="Resource" id="Resource" class="input-name form-control" placeholder=" Resource Name " style="float:left;width:70%;"> -->
                                    </div>
                                    <!-- <span class="error" id="email_error"></span> -->
                                </div>
                                <div class="col-md-6">
                                    <div class="input-text form-group has-feedback">
                                        <label style="width:180px;float:left;text-align:right;padding-right:15px;">
                                            MRNumber<span class="required"> * </span></label>
                                        <input
                                            [class.is-invalid]="MRNumber.invalid && (MRNumber.dirty || MRNumber.touched)"
                                            class="form-control" id="MRNumber" type="PatientName" name="MRNumber"
                                            placeholder=" MRNumber " formControlName="MRNumber" readonly disabled>
                                        <!-- <div *ngIf="MRNumber.invalid  && (MRNumber.dirty || MRNumber.touched)"> -->
                                        <small *ngIf="error && MRNumber.errors?.required" class="text-danger">MRNumber
                                            is required</small>
                                    </div>
                                </div>


                                <div style="clear:both;"></div>
                                <div class="col-md-6">
                                    <div class="input-text form-group has-feedback">
                                        <label style="width:200px;float:left;text-align:right;padding-right:5px;">Select
                                            Report Date <span class="required"> * </span></label>

                                        <div class="input-group date">
                                            <input type='text' class="form-control" placeholder="dd-mm-yyyy"
                                                formControlName="ReportGeneratedDate" id="ReportGeneratedDate"
                                                name="ReportGeneratedDate" #dp="bsDatepicker" bsDatepicker
                                                [bsConfig]="datePickerConfig" placement="right" style=" width: 280px;">
                                            <span class="input-group-addon">
                                                <i class="glyphicon glyphicon-calendar" (click)="dp.toggle()"></i>
                                            </span>

                                        </div>
                                        <div style="text-align: center;">
                                            <small *ngIf="error && ReportGeneratedDate.errors?.required"
                                                class="text-danger">
                                                Report Date is required</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="input-text form-group has-feedback">
                                        <label style="width:200px;float:left;text-align:right;padding-right:5px;">
                                            Select Report Files<span class="required"> * </span></label>
                                        <input formControlName="file" id="file" type="file" multiple
                                            class="form-control" (change)="onFileChange($event)" required>
                                        <!-- <div *ngIf="f.file.touched && f.file.invalid" class="alert alert-danger">
                                            <div *ngIf="f.file.errors.required">File is required.</div>
                                        </div> -->
                                        <small *ngIf="error && file.errors?.required" class="text-danger">Report file
                                            is required</small>
                                    </div>
                                </div>

                                <div style="clear:both;"></div>
                                <div class="col-md-6">
                                    <div class="input-text form-group has-feedback">
                                        <label style="width:180px;float:left;text-align:right;padding-right:15px;">
                                            Report Name<span class="required"> </span></label>
                                        <input
                                            [class.is-invalid]="ReportName.invalid && (ReportName.dirty || ReportName.touched)"
                                            class="form-control" id="ReportName" type="text" name="ReportName"
                                            placeholder="report name" formControlName="ReportName">
                                        <!-- <div *ngIf="MRNumber.invalid  && (MRNumber.dirty || MRNumber.touched)"> -->
                                        <small *ngIf="ReportName.errors?.pattern" class="text-danger">Report Name
                                            must be alphabets </small>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="input-text form-group has-feedback">
                                        <label style="width:180px;float:left;text-align:right;padding-right:15px;">
                                            Description<span class="required"> </span></label>
                                        <textarea
                                            [class.is-invalid]="Description.invalid && (Description.dirty || Description.touched)"
                                            class="form-control" id="Description" type="text" name="Description"
                                            placeholder=" Description " formControlName="Description"></textarea>
                                        <!-- <div *ngIf="MRNumber.invalid  && (MRNumber.dirty || MRNumber.touched)"> -->
                                        <small *ngIf="Description.errors?.pattern" class="text-danger">Description
                                            must be alphabets </small>
                                    </div>
                                </div>


                                <div style="clear:both;"></div>
                                <div
                                    style="margin:auto;text-align:center;margin-bottom:10px;margin-top:30px;margin-bottom:30px;">
                                    <a [routerLink]="" (click)="onSubmit(healthReportForm)"> <input type="submit"
                                            class="btn-pink" value="SAVE"></a>

                                </div>

                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</body>

</html>
<ngx-spinner></ngx-spinner>