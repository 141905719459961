import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
@Component({
  selector: 'app-createappointments',
  templateUrl: './createappointments.component.html',
  styleUrls: ['./createappointments.component.css']
})
export class CreateappointmentsComponent implements OnInit {
  datePickerConfig: Partial<BsDatepickerConfig>;
  viewalldoctors;
  viewallhospitals;
  constructor(private spinner: NgxSpinnerService, private httpclient: HttpClient, private formBuilder: FormBuilder, private apicalls: ApicallsService, private route: Router, private router: ActivatedRoute) {

    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        dateInputFormat: 'DD-MM-YYYY',


      }
    );
  }
  minDate;
  todaysdate = moment();
  userDetails
  ngOnInit(): void {

    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data
    let currentYear = moment().toDate().getFullYear();
    let currentDate = moment().toDate().getDate();
    let currentMonth = moment().toDate().getUTCMonth();
    this.minDate = new Date(currentYear, currentMonth, currentDate);

    this.apicalls.viewAllHospitals()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
          if (data.code == "S001") {
            this.viewallhospitals = data;
          }
          else if (data.code == "PD01") {
            alert("Permissions Denied");
            return;
          }
          else if (data.code == "UA01") {
            alert("Your UnAuthorized to Access this Service");
            return;
          } else if (data.code == "S002") {
            alert("No Data Found");
            return;
          }

        } else {
          this.spinner.hide();
        }
      }, error => { alert(" Internal Server Error "); });

    this.apicalls.viewAllDoctors()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {
            this.viewalldoctors = data;
            this.spinner.hide();
          }
          else if (data.code == "UA01") {
            alert(data.data);
            return;
          }
          else if (data.code == 'V002') {
            alert(data.data);
            this.spinner.hide();
          } else if (data.code == "PD01") {
            alert("Permissions Denied");
            return;
          }


        } else {
          alert(data.data)
          this.spinner.hide();
        }
      }, error => { alert(" Internal Server Error "); });

  }

  data: '';
  pathurl: string = 'GokulClinic';
  isSubmitted: boolean = true;
  channelError: string;
  AddressObj = {};
  error = false;
  mrnum = '';


  onSubmit(appointmentForm) {
    //console.log(this.appointmentForm.value)

    if (this.appointmentForm.invalid) {
      this.error = true;
      return;
    }
    if (this.viewalldoctors.data.length == 1) {
      this.appointmentForm.value.MedicID = this.viewalldoctors.data[0].MedicID;
      this.appointmentForm.value.FirstName = this.viewalldoctors.data[0].FirstName;
      this.appointmentForm.value.LastName = this.viewalldoctors.data[0].LastName;
    }
    if (this.viewallhospitals.data.length == 1) {
      this.appointmentForm.value.HospitalID = this.viewallhospitals.data[0].HospitalID;
      this.appointmentForm.value.HospitalName = this.viewallhospitals.data[0].HospitalName;
    }
    if (this.appointmentForm.value.CMRNumber != "NewCustomer") {
      this.appointmentForm.value.MRNumber = this.appointmentForm.value.CMRNumber;
    }
    this.appointmentForm.value.Fee = this.doctorsdetails.data.Fee
    this.appointmentForm.value.PaymentStatus = "Paid";
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);
    // this.AddressObj = {
    //   StreetAddress: this.appointmentForm.value.StreetAddress,
    //   Colony: this.appointmentForm.value.Colony,
    //   Locality: this.appointmentForm.value.Locality,
    //   PinCode: this.appointmentForm.value.PinCode,
    //   City: this.appointmentForm.value.City,
    //   State: this.appointmentForm.value.State,
    //   Country: this.appointmentForm.value.Country,
    //   Latitude: this.appointmentForm.value.Latitude,
    //   Longitude: this.appointmentForm.value.Longitude,
    //   LocationLink: this.appointmentForm.value.LocationLink
    // };
    //this.appointmentForm.value.Address = this.AddressObj;
    let x = moment(this.appointmentForm.value.Date).format("DD-MM-YYYY");
    this.appointmentForm.value.Date = x;
    //console.log(" createAppointments ", this.appointmentForm.value);
    //return;
    let result = this.apicalls.createAppointments(this.appointmentForm.value)
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {
            this.spinner.hide();
            console.log(" data ", data.data);
            alert(data.data)
            //this.route.navigate(["/dashboardmain/appointments"]);UpdatePayment/DMSPD00000097
            this.route.navigate(["/dashboardmain/UpdatePayment", data.AppointmentDetails.PaymentID]);
          }
          else if (data.code == "PD01") {
            alert("Permissions Denied");
            return;
          }
          else if (data.code == "UA01") {
            alert("Your UnAuthorized to Access this Service");
            return;
          }
          else if (data.code == 'S002') {
            this.spinner.hide();
            alert(data.data);
            this.isSubmitted = false;
            return false;
          }

        }
        else {
          this.spinner.hide();
          this.isSubmitted = false;
          alert(data.data)
          this.channelError = data.data;
          return false;
        }
      },
        error => { alert(" Internal Server Error "); }
      );

  }

  get MedicID() {
    return this.appointmentForm.get('MedicID');
  }
  get DoctorName() {
    return this.appointmentForm.get('DoctorName');
  }

  get Age() {
    return this.appointmentForm.get('Age');
  }
  get Gender() {
    return this.appointmentForm.get('Gender');
  }
  get District() {
    return this.appointmentForm.get('District');
  }


  get PatientName() {
    return this.appointmentForm.get('PatientName');
  }
  get HospitalID() {
    return this.appointmentForm.get('HospitalID');
  }
  get HospitalName() {
    return this.appointmentForm.get('HospitalName');
  }
  get StreetAddress() {
    return this.appointmentForm.get('StreetAddress');
  }
  get Colony() {
    return this.appointmentForm.get('Colony');
  }
  get Locality() {
    return this.appointmentForm.get('Locality');
  }
  get PinCode() {
    return this.appointmentForm.get('PinCode');
  }
  get City() {
    return this.appointmentForm.get('City');
  }
  get State() {
    return this.appointmentForm.get('State');
  }
  get Country() {
    return this.appointmentForm.get('Country');
  }

  get Date() {
    return this.appointmentForm.get('Date');
  }
  get Time() {
    return this.appointmentForm.get('Time');
  }
  get Fee() {
    return this.appointmentForm.get('Fee');
  }
  get PaymentStatus() {
    return this.appointmentForm.get('PaymentStatus');
  }
  get ContactNumber() {
    return this.appointmentForm.get('ContactNumber');
  }
  // get Address() {
  //   return this.appointmentForm.get('Address') as FormArray;
  // }

  get DoctorID() {
    return this.appointmentForm.get('DoctorID');
  }
  get Discount() {
    return this.appointmentForm.get('Discount');
  }
  get CMRNumber() {
    return this.appointmentForm.get('CMRNumber');
  }
  get AppointmentType() {
    return this.appointmentForm.get('AppointmentType');
  }

  appointmentForm = new FormGroup({
    MedicID: new FormControl(''),
    DoctorID: new FormControl('',),
    CMRNumber: new FormControl(''),
    AppointmentType: new FormControl(''),
    DoctorName: new FormControl(''),
    PatientName: new FormControl('',),
    HospitalID: new FormControl('',),
    HospitalName: new FormControl('',),
    Date: new FormControl('', [Validators.required]),
    //Time: new FormControl('', [Validators.required]),
    District: new FormControl('',),
    //Fee: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
    //PaymentStatus: new FormControl('', [Validators.required]),
    Age: new FormControl('',),
    Gender: new FormControl('',),
    //Discount: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
    ContactNumber: new FormControl(''),
    //Address: new FormGroup({}),
    StreetAddress: new FormControl(''),


  });



  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }

  detailshospital;
  onOptionsSelectedhospital(value: string) {
    this.apicalls.viewHospitalsById(value).subscribe(
      data => {
        this.spinner.show();
        if (data.type == true) {
          this.detailshospital = data
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
          }, 500);
          if (data.code == "S001") {
            this.appointmentForm.patchValue({
              HospitalID: this.detailshospital.data.HospitalID,
              HospitalName: this.detailshospital.data.HospitalName,
            });
          }
          else if (data.code == "UA01") {
            alert(data.data);
            return;
          }
          else if (data.code == "PD01") {
            alert("Permissions Denied");
            return;
          }
          else if (data.code == "S002") {
            alert("No Data Found");
            return;
          }
        } else {
          alert(data.message);
          this.spinner.hide();
        }
      }, error => { alert(" Internal Server Error ") });


  }

  doctorsdetails;
  onOptionsSelected(value: string) {
    this.apicalls.viewDoctorsById(value).subscribe(
      data => {
        this.spinner.show();
        if (data.type == true) {
          this.doctorsdetails = data
          //console.log("DDDDData", this.doctorsdetails)
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
          }, 500);
          if (data.code = "S001") {
            this.appointmentForm.patchValue({
              MedicID: this.doctorsdetails.data.MedicID,
              DoctorName: this.doctorsdetails.data.FirstName,
              Fee: this.doctorsdetails.data.Fee,
            });
          }
          if (data.code = "UA001") {
            alert(data.data);
            return;
          }
          else if (data.code == "PD01") {
            alert("Permissions Denied");
            return;
          }
          else if (data.code == "S002") {
            alert("Data Not Found");
            return;
          }

        } else {
          alert(data.message);
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ")
      });

  }

  PatientDetiails;
  onPhoneEntered(value: string) {
    this.apicalls.viewPatientsByContactNumber(value).subscribe(
      data => {
        //this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {
            this.PatientDetiails = data.data;
          }
          else if (data.code == 'S002') {
            this.PatientDetiails = []
          } else if (data.code == "PD01") {
            alert("Permissions Denied");
            return;
          }
          else if (data.code == "UA01") {
            alert("Your UnAuthorized to Access this Service");
            return;
          } else if (data.code == "S002") {
            alert("No Data Found");
            return;
          }
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
          }, 500);

        } else {
          alert(data.message);
          this.spinner.hide();
        }
      }, error => { alert(" Internal Server Error ") })
  }

  isReadonly = false;
  isDisabled = false;
  Patientinfo;

  onPatientSelectSelected(value: string) {
    if (value == "NewCustomer") {
      this.isReadonly = false;
      this.isDisabled = false;
      this.Patientinfo = {}
      this.Patientinfo.Address = {}
      this.appointmentForm.patchValue({
        PatientName: this.Patientinfo.Name,
        Gender: "",
        Age: this.Patientinfo.Age,
        StreetAddress: this.Patientinfo.Address.StreetAddress,
        District: this.Patientinfo.District
      });

    } else {
      this.isReadonly = !this.isReadonly;
      this.isDisabled = !this.isDisabled;
      this.apicalls.viewPatientsById(value).subscribe(
        data => {
          if (data.type == true) {
            if (data.code == 'S001') {
              this.Patientinfo = data.data;
              this.appointmentForm.patchValue({
                PatientName: this.Patientinfo.Name,
                Gender: this.Patientinfo.Gender,
                ContactNumber: this.Patientinfo.ContactNumber,
                Age: this.Patientinfo.Age,
                StreetAddress: this.Patientinfo.Address.StreetAddress,
                District: this.Patientinfo.District
              });

            }
            else if (data.code == 'S002') {
              alert(data.data);
              return;
              this.Patientinfo = {}
            } else if (data.code == "PD01") {
              alert("Permissions Denied");
              return;
            }
            else if (data.code == "UA01") {
              alert("Your UnAuthorized to Access this Service");
              return;
            }
            setTimeout(() => {
              this.spinner.hide();
            }, 500);
          } else {
            alert(data.message);
            this.spinner.hide();
          }
        }, error => {
          alert(" Internal Server Error ")
        });

    }
  }

  onClickCreate() {
    this.route.navigate(["/dashboardmain/createappointmentforexistpatient"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }

}



