<app-header></app-header>
<nav class="navbar navbar-inverse">
    <div>
        <div style="position:relative;">
            <div>
                <div class="collapse navbar-collapse" id="myNavbar" style="float:left;padding:0px;clear:both;width:100%;background:#2d3e50;">
                    <div>
                        <ul class="nav navbar-nav ag-menu sm pl40" style="float:left;" data-smartmenus-id="14811307330159312">
                            <li class=""><a href="">HOME</a>
                            </li>
                            <li class="{{openlist}}"  (mouseover)='over()' (mouseout)='out()'><a href="" class="has-submenu">MYPRACTICE  </a>
                                <ul class="dropdown-menu sm-nowrap" >
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Dr Gokul reddy</a>
                                                    </div>
                                                    <div>
                                                        <a class="" routerLink="/practice-locations"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>Practice Locations</a>
                                                    </div>
                                                    <!-- <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Book  appointment</a>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                            </li>
                            <li class="{{openlist1}}" (mouseover)='over1()' (mouseout)='out1()'><a class="has-submenu" href="#" >PATIENT INFO </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/about-cholesterol"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>ABOUT CHOLESTEROL</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/angioplasty"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> ANGIOPLASTY</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/chestpain"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CHESTPAIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/common-misconceptions"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>COMMON MISCONCEPTIONS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/coronary-angiogram"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CORONARY ANGIOGRAM</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/exercsie-and-heart-health"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> EXERCSIE & HEART HEALTH</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/guide-to-taking-warfarin"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> GUIDE TO TAKING WARFARIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-attack"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTATTACK</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-fauilre"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTFAUILRE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/high-blood-pressure"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HIGH BLOOD PRESSURE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/htn-myths"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>HTN-MYTHS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/tips-to-lower"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>TIPS  TO LOWER CHOLESTEROL   LEVELS</a> </div>



                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li><a href="#">SERVICES</a></li>
                            <li><a href="#">APPOINTMENTS</a></li>
                            <li class=""><a  href="faq">FAQS </a></li>
                            <li class=""><a  href="blog">MY BLOG</a></li>

                            <li class=""> <a  href="reach-us">REACH US</a></li>

                            <div class="clearfix"></div>
                        </ul>
                        <div class="pull-right pn">
                            <a href="#"><img src="/../../assets/front/img/askdemo.png" style="height:52px;"/></a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</nav>

<section style="background:#fff;">

    <div class="container">

        <div  style="padding:30px 15px;">

            <div class="row">

                <h4 class="mb5" style="color: #0991cd;font-weight: bold;">ChestPain</h4>

                <!--<p class="pull-right"><img src="img/gokulreddy.png" style="height:350px;"/></p>-->





                <p><strong style="color:#FF6600;"> <i>A wide range of health problems can cause chest pain. In many cases, the underlying cause has nothing to do with your heart — though there's no easy way to tell without seeing a doctor.</i></strong>

                </p>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:10px;">Heart-related chest pain</h5>







                <p>

                    Although chest pain is commonly attributed to heart disease, many people with heart disease say they experience a vague discomfort for which "pain" doesn't seem to be an adequate description. In general, chest discomfort related to a heart attack or another heart problem may be described by or associated with one or more of the following:

                </p>



                <ul class="list_1">

                    <li> Pressure, fullness or tightness in your chest</li>

                    <li> Crushing or searing pain that radiates to back, neck, jaw, shoulders and arms — particularly left arm</li>

                    <li> Pain that lasts more than a few minutes, getting worse with activity, goes away on rest</li>

                    <li>Shortness of breath </li>

                    <li> Cold sweats</li>

                    <li>Dizziness , weakness , Nausea or vomiting</li>

                </ul>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Other types of chest pain</h5>





                <p>

                    It can be difficult to distinguish chest pain due to a heart problem from other types of chest pain. However, chest pain that is less likely due to a heart problem is more often associated with:

                </p>



                <ul class="list_1">

                    <li>Pain that gets better or worse when you change your body position</li>

                    <li>Pain increasing on deep breath or cough</li>

                    <li>Tenderness when you touch /press on the pain area on your chest</li>

                    <li>Pricking / tingling kind of pain</li>

                </ul>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;"> When to see a doctor</h5>





                <p>If you have new or unexplained chest pain or suspect you're having a heart attack, call for emergency medical help immediately.</p>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;"> Causes of chest pain</h5>



                <p>Chest pain has many possible causes, all of which deserve medical attention.</p>







                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Heart-related causes</h5>



                <p>Examples of heart-related causes of chest pain include:</p>



                <ul class="list_1">

                    <li> <b>Heart attack:</b>  A heart attack is a result of a blood clot that's blocking blood flow to your heart muscle.</li>





                    <li> <b>Angina:</b>Thick plaques can gradually build up on the inner walls of the arteries that carry blood to your heart. These plaques narrow the arteries and restrict the heart's blood supply, particularly during exertion.</li>





                    <li><b>Aortic dissection:</b> This life-threatening condition involves the main artery leading from your heart — your aorta. If the inner layers of this blood vessel separate, blood will be forced between the layers and can cause the aorta to rupture.</li>



                    <li><b>Pericarditis:</b> This condition, an inflammation of the sac surrounding your heart, usually causes sharp pain that gets worse when you breathe in or when you lay down.</li>



                </ul>







                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Digestive causes</h5>



                <p>Chest pain can be caused by disorders of the digestive system, including:</p>



                <ul class="list_1">

                    <li>

                        <b>Heartburn:</b>This painful, burning sensation behind your breastbone occurs when stomach acid washes up from your stomach into the esophagus — the tube that connects your throat to your stomach.

                    </li>

                    <li><b>Swallowing disorders:</b> Disorders of the esophagus can make swallowing difficult and even painful.</li>



                    <li><b>Gallbladder or pancreas problems:</b> Gallstones or inflammation of your gallbladder or pancreas can cause abdominal pain that radiates to your chest.</li>



                </ul>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Muscle and bone causes</h5>



                <p>Some types of chest pain are associated with injuries and other problems affecting the structures that make up the chest wall. Examples include:</p>



                <ul class="list_1">

                    <li><strong>Costochondritis:</strong> In this condition, the cartilage of your rib cage, particularly the cartilage that joins your ribs to your breastbone, becomes inflamed and painful.</li>

                    <li> <strong>Sore muscles:</strong> Chronic pain syndromes, such as fibromyalgia, can produce persistent muscle-related chest pain.</li>

                    <li> <strong>Injured ribs :</strong> A bruised or broken rib can cause chest pain.</li>



                    <li> <strong>Lung-related causes :</strong>Many lung disorders can cause chest pain, including:</li>



                </ul>



                <ul class="list-style" style="margin-top:20px;">

                    <li>Pulmonary embolism.</li>

                    <li>Pleurisy.</li>

                    <li>Pulmonary hypertension.</li>

                </ul>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Other causes :Chest pain can also be caused by:</h5>





                <ul class="list_1">

                    <li><strong>Panic attack:</strong> If you have periods of intense fear accompanied by chest pain, rapid heartbeat, rapid breathing, profuse sweating, shortness of breath, nausea, dizziness and a fear of dying, you may be experiencing a panic attack.</li>

                    <li> <strong>Shingles:</strong> Caused by a reactivation of the chickenpox virus, shingles can produce pain and a band of blisters from your back around to your chest wall.</li>



                </ul>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">TESTING / EVALUATING CHEST PAIN</h5>



                <b>      Immediate tests</b>



                <p>Some of the first tests you may undergo include:</p>





                <ul class="list_1">

                    <li><strong>Electrocardiogram (ECG):</strong> This test records the electrical activity of your heart through electrodes attached to your skin. Because injured heart muscle doesn't conduct electrical impulses normally, the ECG may show that a heart attack has occurred or is in progress.</li>

                    <li> <strong>Blood tests:</strong> Your doctor may order blood tests to check for increased levels of certain enzymes normally found in heart muscle. Damage to heart cells from a heart attack may allow these enzymes to leak, over a period of hours, into your blood.</li>

                    <li> <strong>Chest X-ray:</strong> An X-ray of your chest allows doctors to check the condition of your lungs and the size and shape of your heart and major blood vessels. A chest X-ray can also reveal lung problems such as pneumonia or a collapsed lung.</li>



                </ul>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Follow-up testing</h5>



                <p>

                    Depending upon the results from these initial tests, you may need follow-up testing, which may include:</p>





                <ul class="list_1">

                    <li><strong>Echocardiogram:</strong> An echocardiogram uses ultrasonic waves to produce a video image of your heart in motion.</li>

                    <li><strong>Computerized tomography (CT scan):</strong> Different types of CT scans can be used to check your heart arteries for signs of calcium, which indicate areas where plaque blockages are accumulating. CT scans can also be done with dye to check your heart and lung arteries for blockages and other problems.</li>



                    <li> <strong>Stress tests:</strong> These measure how your heart and blood vessels respond to exertion, which may indicate if your pain is related to your heart. You may be asked to walk on a treadmill while hooked up to an ECG or you may be given a drug intravenously to stimulate your heart in a way similar to exercise.</li>



                    <li><strong> Coronary catheterization (angiogram)</strong> This test helps to identify individual arteries to the heart that may be narrowed or blocked. A dye is injected into the arteries of your heart through a catheter, either through wrist or groin, to arteries in your heart. As the dye fills your arteries, they become visible on X-ray and video.</li>



                </ul>





            </div>



        </div>

    </div>

    
</section>
<app-footer></app-footer>
