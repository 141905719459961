import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
@Component({
  selector: 'app-view-reportsbylabtech',
  templateUrl: './view-reportsbylabtech.component.html',
  styleUrls: ['./view-reportsbylabtech.component.css']
})
export class ViewReportsbylabtechComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private httpclient: HttpClient, private formBuilder: FormBuilder,
    private apicalls: ApicallsService, private route: Router, private router: ActivatedRoute) { }

  PatientDetails;
  reports = [];
  myFiles: string[] = [];
  datePickerConfig: Partial<BsDatepickerConfig>;

  MRNumber;
  PaymentID;
  BillID;
  userDetails
  BillingDetails;
  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data

    this.router.paramMap.subscribe(params => {
      this.MRNumber = params.get('MRNumber');
      this.PaymentID = params.get('PaymentID');
      this.BillID = params.get('BillID');
      this.apicalls.viewReportsbylabtech(this.MRNumber, this.PaymentID, this.BillID).subscribe(
        data => {
          this.spinner.show();
          if (data.type == true) {
            this.spinner.show();
            if (data.code == "S001") {
              setTimeout(() => {
                this.spinner.hide();
              }, 500);
              console.log(" @@##$$%% ", data.data);
              for (var i = 0; i < data.data.recordsdata.length; i++) {
                for (var j = 0; j < data.data.recordsdata[i].ReportsImageURLS.length; j++) {
                  this.reports.push(data.data.recordsdata[i].ReportsImageURLS[j])
                }
              }
            } setTimeout(() => {
              this.spinner.hide();
            });
          }
          else {
            alert(data.message);
            this.spinner.hide();
          }
        }, error => { alert(" Internal Server Error ") });


      this.apicalls.viewPatientsById(this.MRNumber).subscribe(
        data => {
          this.spinner.show();
          if (data.type == true) {
            this.PatientDetails = data.data
            console.log(" @@@## ", this.PatientDetails)
            setTimeout(() => {
              /** spinner ends after 5 seconds */
              this.spinner.hide();
            }, 500);

          } else {
            alert(data.message);
            this.spinner.hide();
          }
        }, error => {
          alert(" Internal Server Error ")
        });


      this.apicalls.viewBilling(this.BillID).subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == "S001") {
            this.BillingDetails = data.data
            console.log(" BillingDetails ", this.BillingDetails)
            setTimeout(() => {
              /** spinner ends after 5 seconds */
              this.spinner.hide();
            }, 500);

          }
          if (data.code == "S002") {
            this.BillingDetails = [];
          }

        } else {
          alert(data.message);
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ")
      })


    })

    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumber: false,
        dateInputFormat: 'DD-MM-YYYY'

      }
    );

  }

  onFileChange(event) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.myFiles.push(event.target.files[i]);
    }
  }


  showform = false;
  UploadHelathReports(MRNumber) {
    this.showform = true;
  }

  healthReportForm = new FormGroup({

    ReportGeneratedDate: new FormControl('', Validators.required),
    file: new FormControl('', Validators.required),
    ReportName: new FormControl('', Validators.pattern('[a-zA-Z ]*')),
    Description: new FormControl('', Validators.pattern('[a-zA-Z ]*')),
  })


  get ReportGeneratedDate() {
    return this.healthReportForm.get('ReportGeneratedDate');
  }
  get file() {
    return this.healthReportForm.get('file');
  }
  get ReportName() {
    return this.healthReportForm.get('ReportName');
  }
  get Description() {
    return this.healthReportForm.get('Description');
  }
  error;


  onSubmit(healthReportForm) {
    if (this.healthReportForm.invalid) {
      this.error = true;
      return;
    }
    this.spinner.show();
    const formData = new FormData();
    formData.append("MRNumber", this.PatientDetails.MRNumber);
    formData.append("PaymentID", this.PaymentID);
    formData.append("BillID", this.BillID);
    //formData.append("AppointmentID", this.AppointmentID);
    let ReportGeneratedDate = moment(this.healthReportForm.value.ReportGeneratedDate).format("DD-MM-YYYY");
    formData.append("ReportGeneratedDate", ReportGeneratedDate);
    formData.append("ReportName", this.healthReportForm.value.ReportName = this.BillingDetails.BillingName);
    formData.append("BillTypeID", this.healthReportForm.value.BillTypeID = this.BillingDetails.BillingType);
    formData.append("Description", this.healthReportForm.value.Description);

    //console.log(" @@@#### ", formData);
    //return;
    for (var i = 0; i < this.myFiles.length; i++) {
      formData.append("Reports", this.myFiles[i]);
    }
    //return;
    this.apicalls.uploadReports(formData)
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {
            this.spinner.hide();
            alert(data.data)
            this.route.navigate(["/dashboardmain/ViewReportsbylabtech", this.PatientDetails.MRNumber, this.PaymentID, this.BillID]);
            window.location.reload();
          }
          if (data.code == 'S002') {
            alert(data.data);
            this.spinner.hide();
          }
        } else {
          alert(data.data)
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ");
      });

  }



  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }
  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
}
