import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../../services/apicalls.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-updaterole',
  templateUrl: './updaterole.component.html',
  styleUrls: ['./updaterole.component.css']
})
export class UpdateroleComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private httpclient: HttpClient, private apicalls: ApicallsService, private route: Router, private router: ActivatedRoute) { }
  viewjobtype;
  userDetails
  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data
    this.router.paramMap.subscribe(params => {
      const RoleID = params.get('id');
      if (RoleID) {

        this.getRole(RoleID);
      }
    });

    this.apicalls.viewJobTypes()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {


          setTimeout(() => {

            this.spinner.hide();
          }, 500);
          this.viewjobtype = data;

        } else {

          this.spinner.hide();

        }
      }, error => {
        error => { alert(" Internal Server Error "); }
        // this.alertService.warning(" Internal Server Error ");
      });




  }
  details;
  data: '';
  pathurl: string = 'GokulClinic';
  isSubmitted: boolean = true;
  //loggedIn:boolean=true;
  loginError: string;

  onSubmit(roleForm) {
    if (this.roleForm.invalid) {
      alert("Please enter all fields")
      return;
    }

    setTimeout(() => {
      this.spinner.hide();
    }, 5000);

    let result = this.apicalls.updateRole(this.roleForm.value)
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {
            this.spinner.hide();
            alert(data.data)
            this.route.navigate(["/dashboardmain/viewallroles"]);


          }
          if (data.code == 'S002') {
            this.spinner.hide();
            alert(data.data)
          }

        }
        else {
          this.spinner.hide();
          alert(data.data)

        }
      },
        error => { alert(" Internal Server Error "); }
      );

  }

  get Role() {
    return this.roleForm.get('Role');
  }

  get JobType() {
    return this.roleForm.get('JobType');
  }
  get RoleID() {
    return this.roleForm.get('RoleID');
  }
  get Status() {
    return this.roleForm.get('Status');
  }

  get RoleAliasName() {
    return this.roleForm.get('RoleAliasName');
  }



  roleForm = new FormGroup({
    Role: new FormControl('', [Validators.required]),
    JobType: new FormControl('', [Validators.required]),
    RoleAliasName: new FormControl('', [Validators.required]),
    RoleID: new FormControl('', [Validators.required]),
    Status: new FormControl('', [Validators.required])
  });

  

  getRole(RoleID: string) {
    console.log("ROLEID", RoleID)
    this.apicalls.viewRoleById(RoleID).subscribe(

      data => {
        this.spinner.show();
      
        if (data.type == true) {
          this.details = data
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
          this.roleForm.patchValue({

            RoleID: this.details.data.RoleID,
            Role: this.details.data.Role,
            RoleAliasName: this.details.data.RoleAliasName,
            JobType: this.details.data.JobType,
            Status: this.details.data.Status


          });
        } else {
          alert(data.message);
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ")
      });


  }
  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }
}
