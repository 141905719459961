import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApicallsService } from '../../services/apicalls.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-viewallpermissions',
  templateUrl: './viewallpermissions.component.html',
  styleUrls: ['./viewallpermissions.component.css']
})
export class ViewallpermissionsComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private apicalls: ApicallsService, private router: ActivatedRoute, private route: Router, private httpClient: HttpClient) { }

  viewallpermissions;

  tempprivileges = [];
  userDetails;
  ngOnInit() {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data

    this.apicalls.viewAllPermissions()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {


            this.viewallpermissions = data;
            // for (let i = 0; i < this.viewallpermissions.data[0].ChannelPartners[0].Privileges.length; i++) {

            //   this.tempprivileges.push({ Privilege: this.viewallpermissions.data[0].ChannelPartners[0].Privileges[i].PrivilegeName })


            // }

            this.spinner.hide();
          }

          if (data.code == 'V002') {

            alert(data.data);
            this.spinner.hide();
          }

        } else {

          this.spinner.hide();

          if (data.code == 'PD01') {
            alert(data.data)
            this.route.navigate(["/dashboardmain/dashboard"]);

          }
          else {
            alert(data.data)
          }

        }
      },
        error => {
          alert(" Internal Server Error ");
          // this.alertService.warning(" Internal Server Error ");
        });

  }

  editpermission(PermissionID) {

    this.route.navigate(["/dashboardmain/updatepermissions", PermissionID]);
  }

  onClickCreate() {
    this.route.navigate(["/dashboardmain/createpermissions"]);
  }
  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }
  Settings(){
    this.route.navigate(["/dashboardmain/configurations"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
}
