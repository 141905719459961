<app-header></app-header>
<nav class="navbar navbar-inverse">
    <div>
        <div style="position:relative;">
            <div>
                <div class="collapse navbar-collapse" id="myNavbar" style="float:left;padding:0px;clear:both;width:100%;background:#2d3e50;">
                    <div>
                        <ul class="nav navbar-nav ag-menu sm pl40" style="float:left;" data-smartmenus-id="14811307330159312">
                            <li class=""><a href="">HOME</a>
                            </li>
                            <li class="{{openlist}}"  (mouseover)='over()' (mouseout)='out()'><a href="" class="has-submenu">MYPRACTICE  </a>
                                <ul class="dropdown-menu sm-nowrap" >
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Dr Gokul reddy</a>
                                                    </div>
                                                    <div>
                                                        <a class="" routerLink="/practice-locations"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>Practice Locations</a>
                                                    </div>
                                                    <!-- <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Book  appointment</a>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                            </li>
                            <li class="{{openlist1}}" (mouseover)='over1()' (mouseout)='out1()'><a class="has-submenu" href="#" >PATIENT INFO </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/about-cholesterol"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>ABOUT CHOLESTEROL</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/angioplasty"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> ANGIOPLASTY</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/chestpain"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CHESTPAIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/common-misconceptions"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>COMMON MISCONCEPTIONS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/coronary-angiogram"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CORONARY ANGIOGRAM</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/exercsie-and-heart-health"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> EXERCSIE & HEART HEALTH</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/guide-to-taking-warfarin"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> GUIDE TO TAKING WARFARIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-attack"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTATTACK</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-fauilre"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTFAUILRE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/high-blood-pressure"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HIGH BLOOD PRESSURE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/htn-myths"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>HTN-MYTHS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/tips-to-lower"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>TIPS  TO LOWER CHOLESTEROL   LEVELS</a> </div>



                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li><a href="#">SERVICES</a></li>
                            <li><a href="#">APPOINTMENTS</a></li>
                            <li class=""><a  href="faq">FAQS </a></li>
                            <li class=""><a  href="blog">MY BLOG</a></li>

                            <li class=""> <a  href="reach-us">REACH US</a></li>

                            <div class="clearfix"></div>
                        </ul>
                        <div class="pull-right pn">
                            <a href="#"><img src="/../../assets/front/img/askdemo.png" style="height:52px;"/></a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</nav>

<section style="background:#fff;">
    <div class="container">
        <div  style="padding:30px 15px;">
            <div class="row">
                <h4 class="mb5" style="color: #0991cd;font-weight: bold;">Practice Locations</h4>
                <p class="pull-right"><img src="/../../assets/front/img/apollo-hospitals.png" style="height:100px;"/></p>

                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">APOLLO HOSPITALS  </h5>

                <p>OP Time :9 am to 4 pm </p>

                <p> Days : Monday to Thursday and Saturday  </p>


                <p class="pull-right"><img src="/../../assets/front/img/elbit-medical.png" style="height:75px;"/></p>

                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">EVENING CLINIC - Dr. Gokul's Cardiac Clinic   </h5>

                <p>

                    OP Timing : 7pm to 9pm
                </p>
                <p>Days: Monday  to Thursday  and Saturday  </p>
                <p>Friday Morning - 9am to 10am</p>
                <p><b>Address</b> : Street Number - 8 , Opp. ICICI Direct , Habsiguda, Hyderabad</p>



                <p class="pull-right"><img src="/../../assets/front/img/adithya-drgokul-reddy.png" style="height:75px;"/></p>

                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">ADITYA Hospitals - Uppal  </h5>

                <p>

                    Tuesday  : 2pm to 6pm
                </p>
                <p>Fridays: 9am to 1 pm </p>


                <p class="pull-right"><img src="/../../assets/front/img/prathima-gokulreddy-beeravolu.png" style="height:75px;"/></p>

                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">PRATIMA Hospitals  </h5>

                <p>

                    Saturday 5 to 6pm ( By Appointment only)
                </p>
                <p> </p>






            </div>
        </div>
    </div>
    
</section>

<app-footer></app-footer>
