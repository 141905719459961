<app-header></app-header>
    
<nav class="navbar navbar-inverse">
    <div>
        <div style="position:relative;">
            <div>
                <div class="collapse navbar-collapse" id="myNavbar" style="float:left;padding:0px;clear:both;width:100%;background:#2d3e50;">
                    <div>
                        <ul class="nav navbar-nav ag-menu sm pl40" style="float:left;" data-smartmenus-id="14811307330159312">
                            <li class=""><a href="">HOME</a>
                            </li>
                            <li class="{{openlist}}" (mouseover)='over()' (mouseout)='out()'><a href="" class="has-submenu">MYPRACTICE  </a>
                                <ul class="dropdown-menu sm-nowrap" >
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Dr Gokul reddy</a>
                                                    </div>
                                                    <div>
                                                        <a class="" routerLink="/practice-locations"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>Practice Locations</a>
                                                    </div>
                                                    <!-- <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Book  appointment</a>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                            </li>
                            <li class="{{openlist1}}" (mouseover)='over1()' (mouseout)='out1()'><a class="has-submenu" href="#" >PATIENT INFO </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/about-cholesterol"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>ABOUT CHOLESTEROL</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/angioplasty"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> ANGIOPLASTY</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/chestpain"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CHESTPAIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/common-misconceptions"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>COMMON MISCONCEPTIONS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/coronary-angiogram"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CORONARY ANGIOGRAM</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/exercsie-and-heart-health"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> EXERCSIE & HEART HEALTH</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/guide-to-taking-warfarin"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> GUIDE TO TAKING WARFARIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-attack"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTATTACK</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-fauilre"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTFAUILRE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/high-blood-pressure"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HIGH BLOOD PRESSURE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/htn-myths"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>HTN-MYTHS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/tips-to-lower"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>TIPS  TO LOWER CHOLESTEROL   LEVELS</a> </div>



                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li><a href="#">SERVICES</a></li>
                            <li><a href="#">APPOINTMENTS</a></li>
                            <li class=""><a  href="faq">FAQS </a></li>
                            <li class=""><a  href="blog">MY BLOG</a></li>

                            <li class=""> <a  href="reach-us">REACH US</a></li>

                            <div class="clearfix"></div>
                        </ul>
                        <div class="pull-right pn">
                            <a href="book-appointment"><img src="/../../assets/front/img/askdemo.png" style="height:52px;"/></a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</nav>

<div id="myCarousel" class="carousel slide pos" data-interval="4000" data-pause="false" data-wrap="true" data-ride="carousel">
    <div class="carousel-inner">
        <div class="item active">
            <img src="/../../assets/front/img/screen1.png" alt="BBB" width="100%"/>
            <div class="carousel-caption text-left" style="text-shadow:none;top:40%;left:50%;right:5%;">
                <h2 class="mb5" style="font-size:32px;text-align:left;"> DR GOKUL REDDY MANDALA </h2>
                <p class="mbn" style="font-size:18px;text-align:left;line-height:22px;">MD,DM,FACC <span class="row">SENIOR CONSULTANT CARDIOLOGIST. </span></p>
            </div>
        </div>
        <div class="item">
            <img src="/../../assets/front/img/chestpain-slide.png" alt="BBB" width="100%"/>
            <div class="carousel-caption text-left" style="text-shadow:none;top:40%;left:50%;right:5%;">
                <h2 class="mb5" style="font-size:32px;text-align:left; color:#fff;"> CHESTPAIN </h2>
                <p class="mbn" style="font-size:18px;text-align:left;line-height:22px; color:#fff;">A wide range of health problems can cause chest pain. <span class="row">In many cases, the underlying cause has nothing to do with your heart though there's no easy way to tell without seeing a doctor. </span></p>
            </div>
        </div>
    </div>
    <!-- Left and right controls -->
    <style>
        .owl-prev{border:1px solid #333;margin-right:20px !important;}
        .owl-next{border:1px solid #333;}
    </style>
    <a class="left carousel-control" href="#myCarousel" role="button" data-slide="prev">
        <!-- <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
        <span class="sr-only">Previous</span> -->
        <img src="/../../assets/front/img/left-arw.png"/>
    </a>

    <a class="right carousel-control" href="#myCarousel" role="button" data-slide="next">
        <!-- <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
        <span class="sr-only">Next</span> -->
        <img src="/../../assets/front/img/left-arw.png" style="transform:rotate(180deg);"/>
    </a>
</div>

<section style="background:#fafafa;">
    <div class="container">
        <div class="row" style="padding:60px 0;">
            <div class="col-md-8">
                <h4 style="text-transform:uppercase;" class="bold"> <span class="blue">Our</span> Story</h4>
                <p>
                    Dr Gokul Reddy Mandala is an interventional Cardiologist with

                    an interest in the diagnosis and management of patients

                    presenting with chest pain. He has an experience of over 15

                    years in the field of interventional cardiology and has

                    performed several thousand cardiac interventions like

                    Coronary angioplasty with stenting, peripheral angioplasty,

                    and Renal angioplasty, Structural heart disease interventional

                    therapies like, PBMV, ASD, PDA and VSD device closures.
                </p>
                <p>He has a special interest in managing patients with Heart

                    failure, Aortic diseases and Pulmonary Hypertension.
                </p>
                <p>He is the Director of Cardiac Catheterization Lab in CITIZENS
                    Hospitals, Gachibowli-Nallagandla in Hyderabad and Chief
                    cardiologist at ADITYA Hospital in Hyderabad. His previous
                    appointment was with CARE Hospitals group , Hyderabad for
                    10 years till March-2013 as a senior Consultant Cardiologist
                    and also has management experience as a medical Director
                    of a 100 bedded unit of CARE Hospital in Musheerabad
                    ,Hyderabad from 2009 to 2013 .
                </p>

                <p>He is Founder -Director of one of the largest cardiology &
                    Cardiac Surgery Group practice, FACTS Cardiology
                    Professionals, in Hyderabad, which now has on board 30
                    cardiologists and cardiac surgeons. The group renders its
                    services in 10 hospitals of which 5 are in city of Hyderabad and
                    5 in district headquarters - Mahboobnagar, Karimnagar,
                    Warangal, Bidar, Siddipet and Peddapally. </p>
                <p class="text-right pt20"><img src="/../../assets/front/img/logo.png" width="200"/></p>
            </div>
            <div class="col-md-4 pn" style="background:url(/../../assets/front/img/our-story.png)no-repeat;">
                <h6 class="mbn mt30" style="background:#2d3e50;padding:10px 0;color:#fff;text-align:center;"> <img src="/../../assets/front/img/timing.png"/> TIMINGS </h6>
                <div class="hospital-info">
                    <p class="name"> APOLLO HOSPITAL   </p>
                    <p class="time"> OP Time :9 am to 4 pm</p>
                    <p class="weekdays"> Days : MON to Thu & Sat  </p>
                </div>
                <div class="hospital-info">
                    <p class="name"> EVENING CLINIC  (Dr. Gokul's Cardiac Clinic)</p>
                    <p class="time"> OP Time :7 pm to 9 pm</p>
                    <p class="weekdays"> Days: Monday  to Thursday  & Saturday <br> Friday Morning - 9am to 10am   </p>
                </div>
                <div class="hospital-info">
                    <p class="name"> ADITYA Hospitals, Uppal   </p>
                    <p class="time"> Tuesday  : 2pm to 6pm | Fridays: 9am to 1 pm</p>
                    <p class="weekdays"> Call - 040 33855555 </p>
                </div>
                <div class="hospital-info">
                    <p class="name"> PRATIMA Hospitals  </p>
                    <p class="time"> OP Time :5pm to 6pm on Saturdays (by appointment only)</p>
                    <p class="weekdays"> Call   -040 43454345 </p>
                </div>
            </div>
        </div>
    </div>
</section>


<section style="background:#2d3e50;">
    <div class="container">
        <div class="row" style="padding:60px 0;">
            <div class="col-md-3">
                <div class="col-md-9 overRight">
                    <p class="fs20 lh20">Years of Experience </p>
                    <h6 class="mbn fs35 bold pl20">13</h6>
                </div>
                <div class="col-md-2 overLeft text-center">
                    <img src="<?php echo base_url(); ?>assets/front/img/experience.png" width="35"/>
                </div>
            </div>
            <div class="col-md-3">
                <div class="col-md-9 overRight">
                    <p class="fs15 lh20">Number of Coronary Angiogram  </p>
                    <h6 class="mbn fs35 bold pl20">6000+</h6>
                </div>
                <div class="col-md-2 overLeft text-center">
                    <img src="<?php echo base_url(); ?>assets/front/img/surgerys.png" width="35"/>
                </div>
            </div>
            <div class="col-md-3">
                <div class="col-md-9 overRight">
                    <p class="fs15 lh20">number of Coronary Interventions, PTCA  <span class="down">Awards</span> </p>
                    <h6 class="mbn fs35 bold pl20">2500+</h6>
                </div>
                <div class="col-md-2 overLeft text-center">
                    <img src="/../../assets/front/img/cup.png" width="35"/>
                </div>
            </div>
            <div class="col-md-3">
                <div class="col-md-9 overRight">
                    <p class="fs15 lh20">Happy Patients <span class="down">Patients </span> </p>
                    <h6 class="mbn fs35 bold pl20">15000+</h6>
                </div>
                <div class="col-md-2 overLeft text-center">
                    <img src="/../../assets/front/img/Patients.png" width="35"/>
                </div>
            </div>
        </div>
    </div>
</section>

<section style="background-size: 100%;color:#333;">
    <div class="container">
        <div class="row"  style="padding-top:20px;padding-bottom:50px;">
            <img class="hidden" src="workFlow.png"/>
            <h2 class="ttitle"><span><i></i></span> Our Medical Services </h2>
            <div class="col-md-4">
                <ul class="services">
                    <li> ECG </li>
                    <li> 2D Echocardiogram  </li>
                </ul>
            </div>
            <div class="col-md-4">
                <ul class="services">
                    <li> Tread Mill Test  </li>
                    <li> Dobutamine Stress Echo  </li>
                </ul>
            </div>
            <div class="col-md-4">
                <ul class="services">
                    <li> Ambulatory BP Monitoring  </li>
                    <li> Holter Study </li>
                </ul>
            </div>
            <div class="col-md-12">
                <ul class="services">
                    <li> Cath Lab Services like Coronary angiogram, Angioplasty , PBMV , Pacemaker  </li>
                </ul>
            </div>
        </div>
    </div>
</section>
<section>
    <div>
        <div class="row text-center">
            <div class="col-md-4 pn" style="background:#2b96cc;">
                <div style="background:#2b96cc;padding:30px 60px;;min-height:320px;color:#fff;">
                    <p> <img src="/../../assets/front/img/open.png"> </p>
                    <h4 style="margin-bottom:0px;font-size:16px;font-weight:bold;"> SPECIAL INTERESTS </h4>
                    <p style="padding-top:5px;line-height:23px;" align="left"> Chest Pain Diagnosis and Management<br>
                        Coronary Angiography and Angioplasty<br>
                        Structural Heart Disease interventional therapy<br>
                        Pulmonary Hypertension<br>
                        Management of Heart Failure<br>
                        Preventive Cardiology </p>
                </div>
            </div>
            <div class="col-md-4 pn" style="background:#f7f7f7;">
                <div style="background:#f7f7f7;padding:30px;min-height:320px;">
                    <p> <img src="/../../assets/front/img/membership.png"> </p>
                    <h4 style="margin-bottom:0px;font-size:16px;font-weight:bold;"> PROFESSIONAL MEMBERSHIP </h4>
                    <p style="padding-top:5px;color:#919191;line-height:23px;">Indian Medical Association<br>
                        Indian Society of Echocardiography<br>
                        Fellow of American College of Cardiology </p>
                </div>
            </div>
            <div class="col-md-4 security-dv pn" style="background:#2b96cc;min-height:320px;">
                <div class="owl-carousel pagination-1 dark-switch" data-pagination="true" data-autoplay="true" data-navigation="false" data-singleitem="true" data-animation="fadeInUp">
                    <div class="item text-left">
                        <div style="background:#2b96cc;padding:30px 60px;;min-height:250px;color:#fff;">
                            <p align="center"> <img src="/../../assets/front/img/open.png"> </p>
                            <h4 style="margin-bottom:0px;font-size:16px;font-weight:bold; text-align:center;"> What Our patients Say About Us </h4>
                            <p style="padding-top:5px;line-height:18px;"> ame-day turn-around is available on double-sided boards and 24 hour turn on multilayer boards. So you can rest assured, we do our very best to deliver your PCBs when you need them without compromising our AVANTI industry-leading quality. </p>
                        </div>
                    </div>
                    <div class="item text-left">
                        <div style="background:#2b96cc;padding:30px 60px;;min-height:250px;color:#fff;">

                            <p style="padding-top:5px;line-height:18px;"> ame-day turn-around is available on double-sided boards and 24 hour turn on multilayer boards. So you can rest assured, we do our very best to deliver your PCBs when you need them without compromising our AVANTI industry-leading quality. </p>
                        </div>
                    </div>
                    <div class="item text-left">
                        <div style="background:#2b96cc;padding:30px 60px;;min-height:250px;color:#fff;">

                            <p style="padding-top:5px;line-height:18px;"> ame-day turn-around is available on double-sided boards and 24 hour turn on multilayer boards. So you can rest assured, we do our very best to deliver your PCBs when you need them without compromising our AVANTI industry-leading quality. </p>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</section>
<app-footer></app-footer>
