import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
import { AutoCompleteComponent } from '@syncfusion/ej2-angular-dropdowns';
import { CheckBoxComponent } from '@syncfusion/ej2-angular-buttons';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-viewallpatients',
  templateUrl: './viewallpatients.component.html',
  styleUrls: ['./viewallpatients.component.css']
})
export class ViewallpatientsComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private apicalls: ApicallsService, private router: ActivatedRoute, private route: Router, private httpClient: HttpClient, private _route: ActivatedRoute,
    private _router: Router) { }
  mobilenum;
  patients;
  userDetails
  type="All";
  Bill="All";
  optionaldata=<any>{}

  @ViewChild('local')
  public localObj: AutoCompleteComponent;
  @ViewChild('remote')
  public remoteObj: AutoCompleteComponent;
  @ViewChild('checkbox')
  public checkboxObj: CheckBoxComponent;



  public localFields: Object = { value: 'ContactNumber' };
  public localWaterMark: string = 'e.g. 9490219062';
  Billings = [];
  BillingsTypes = [];
  ngOnInit() {

    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data
    this.router.queryParams.subscribe(params => {
      if(params.mobile && params.mrnumber){
        this.optionaldata={}
        this.optionaldata.MRNumber=params.mrnumber
        this.getdetailsofpatient(this.optionaldata)
        this.getpatientdatabymob(params.mobile)
        this.viewReportsForm.patchValue({
          MRNumber: params.mrnumber,
        });
      }
    });

    this.apicalls.getTypeMastersData()
    .subscribe(data => {
      this.spinner.show();
      if (data.type == true) {
        if (data.code == 'S001') {
          this.spinner.hide();
        }
        this.BillingsTypes = data.data;
        if (data.code == 'S002') {
          alert(data.data);
          this.spinner.hide();
        }
      } else {
        alert(data.data)
        this.spinner.hide();
      }
    }, error => { alert(" Internal Server Error "); });


    this.apicalls.viewAlluniqueBillings("Active")
    .subscribe(data => {
      this.spinner.show();
      if (data.type == true) {
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
          this.Billings=data.data;
      } else {
        this.spinner.hide();
        if (data.code == 'PD01') {
          alert(data.data)
          this.route.navigate(["/dashboardmain/dashboard"]);
        }
        else {
          alert(data.data)
        }
      }
    }, error => { alert(" Internal Server Error "); });
    this.apicalls.viewAllPatients()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
          if (data.code == 'S001') {
            this.patients = data.data;
          } if (data.code == 'S002') {
            this.patients = []
          }
        } else {
          this.spinner.hide();
          if (data.code == 'PD01') {
            alert(data.data)
            this.route.navigate(["/dashboardmain/dashboard"]);
          }
          else {
            alert(data.data)
          }
        }
      }, error => { alert(" Internal Server Error "); });


  }

  PatientDetiails;
  onPhoneEntered(value: string) {
    this.mobilenum=value;
    this.getpatientdatabymob(value)
    

  }

  getpatientdatabymob(mob){
    this.apicalls.viewPatientsByContactNumber(mob).subscribe(
      data => {
        if (data.type == true) {
          if (data.code == 'S001') {
            this.PatientDetiails = data.data;
          }
          if (data.code == 'S002') {
            this.PatientDetiails = []
          }
            setTimeout(() => {
            this.spinner.hide();
          }, 500);
        } else {
          alert(data.message);
          this.spinner.hide();
        }
      }, error => { alert(" Internal Server Error ") })

  }
  onTypeSelected(value: string) {



    this.type = value
    this.optionaldata.Type=value
    this.optionaldata.BillID=""
    if(this.type =="All"){
     this.optionaldata.Type=""
    }
    this.getdetailsofpatient(this.optionaldata)


}

onBillSelected(value:string){
  this.Bill=value
  this.optionaldata.BillID=value
  if(this.Bill =="All"){
    this.optionaldata.BillID=""
   }
  this.getdetailsofpatient(this.optionaldata)

}



  viewReportsForm = new FormGroup({
    ContactNumber: new FormControl('',),
    MRNumber: new FormControl(''),
    Type:new FormControl(''),
    BillID:new FormControl(''),
  })
  get ContactNumber() {
    return this.viewReportsForm.get('ContactNumber');
  }

  get MRNumber() {
    return this.viewReportsForm.get('MRNumber');
  }

  get BillID() {
    return this.viewReportsForm.get('BillID');
  }
  

  Reports;
  onPatientSelect(value: string) {
    this.optionaldata.MRNumber=value;
    this.getdetailsofpatient(this.optionaldata)
    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        mobile: this.mobilenum,
        mrnumber:value
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: false
      // do not trigger navigation
    });
    
  

  }


  getdetailsofpatient(optionaldata){
    this.apicalls.viewPatientsAllAppointemetReports(optionaldata)
    .subscribe(data => {
      this.spinner.show();
      if (data.type == true) {
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
        this.Reports = data.data;
      } else {
        this.spinner.hide();
        if (data.code == 'PD01') {
          alert(data.data)
          this.route.navigate(["/dashboardmain/dashboard"]);
        }
        else {
          alert(data.data)
        }
      }
    }, error => { alert(" Internal Server Error "); });

  

  }

  editechoreport(list){

    

    this.route.navigate(["/dashboardmain/viewechoreports", list.ReportID]);

  }


  navigate(list) {
    if (list.Type == '2D_ECHO'|| list.Type =="2D ECHOCARDIOGRAM" ) {
      this.route.navigate(["/dashboardmain/Print2DEchoReport", list.ReportID, list.MRNumber]);
    } else if (list.Type == 'Reports') {
      this.route.navigate(["/dashboardmain/ViewHealthReport", list.ReportID]);
    }
    else {
      this.route.navigate(["/dashboardmain/updateappointments", list.AppointmentID]);
    }
  }















  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }

  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
}

