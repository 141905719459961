<app-header></app-header>

<nav class="navbar navbar-inverse">
    <div>
        <div style="position:relative;">
            <div>
                <div class="collapse navbar-collapse" id="myNavbar" style="float:left;padding:0px;clear:both;width:100%;background:#2d3e50;">
                    <div>
                        <ul class="nav navbar-nav ag-menu sm pl40" style="float:left;" data-smartmenus-id="14811307330159312">
                            <li class=""><a href="">HOME</a>
                            </li>
                            <li class="{{openlist}}"  (mouseover)='over()' (mouseout)='out()'><a href="" class="has-submenu">MYPRACTICE  </a>
                                <ul class="dropdown-menu sm-nowrap" >
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Dr Gokul reddy</a>
                                                    </div>
                                                    <div>
                                                        <a class="" routerLink="/practice-locations"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>Practice Locations</a>
                                                    </div>
                                                    <!-- <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Book  appointment</a>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                            </li>
                            <li class="{{openlist1}}" (mouseover)='over1()' (mouseout)='out1()'><a class="has-submenu" href="#" >PATIENT INFO </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/about-cholesterol"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>ABOUT CHOLESTEROL</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/angioplasty"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> ANGIOPLASTY</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/chestpain"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CHESTPAIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/common-misconceptions"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>COMMON MISCONCEPTIONS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/coronary-angiogram"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CORONARY ANGIOGRAM</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/exercsie-and-heart-health"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> EXERCSIE & HEART HEALTH</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/guide-to-taking-warfarin"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> GUIDE TO TAKING WARFARIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-attack"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTATTACK</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-fauilre"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTFAUILRE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/high-blood-pressure"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HIGH BLOOD PRESSURE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/htn-myths"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>HTN-MYTHS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/tips-to-lower"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>TIPS  TO LOWER CHOLESTEROL   LEVELS</a> </div>



                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li><a href="#">SERVICES</a></li>
                            <li><a href="#">APPOINTMENTS</a></li>
                            <li class=""><a  href="faq">FAQS </a></li>
                            <li class=""><a  href="blog">MY BLOG</a></li>

                            <li class=""> <a  href="reach-us">REACH US</a></li>

                            <div class="clearfix"></div>
                        </ul>
                        <div class="pull-right pn">
                            <a href="#"><img src="/../../assets/front/img/askdemo.png" style="height:52px;"/></a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</nav>

<section style="background:#fff;">

    <div class="container">

        <div  style="padding:30px 15px;">

            <div class="row">

                <h4 class="mb5" style="color: #0991cd;font-weight: bold;"> TIPS TO LOWER CHOLESTEROL LEVELS </h4>

                <!--<p class="pull-right"><img src="img/gokulreddy.png" style="height:350px;"/></p>-->





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:20px;">Eat a heart-healthy diet</h5>





                <p>To lower cholesterol, eat a dietary pattern that emphasizes fruits, vegetables, whole grains, low-fat dairy products, poultry, fish and nuts. You should also limit red meat and sugary foods and beverages.</p>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;"> Get moving</h5>



                <p>Being physically active is also important to prevent heart disease and stroke. Just 40 minutes of aerobic exercise of moderate to vigorous intensity done three to four times a week is enough to lower both cholesterol and high blood pressure. Brisk walking, swimming, bicycling or a dance class are examples.</p>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Avoid tobacco smoke</h5>





                <p>If you smoke, your cholesterol level is one more good reason to quit. And everyone should avoid exposure to secondhand smoke</p>



                <h5 class="mb5" style="color: #0991cd;font-weight: bold;"><u>Know Your Fats</u></h5>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:10px;">Saturated fat</h5>



                <p>The majority of saturated fat comes from animal products such as beef, lamb, pork poultry with skin, butter, cream, cheese and other dairy products made from whole or 2 percent milk,. All of these foods also contain dietary cholesterol. Foods from plants that contain

                    saturated fat include coconut, coconut oil, palm oil and palm kernel oil (often called tropical oils) and cocoa butter. For people who need to lower their cholesterol, the American Heart Association recommends reducing saturated fat to no more than 7 percent of total daily calories.</p>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Unsaturated fat</h5>



                <p>Polyunsaturated and monounsaturated fats are the two unsaturated fats. They're mainly found in fish such as salmon, trout and herring, avocados, olives, walnuts and liquid vegetable oils such as soybean, corn, safflower, canola, olive and sunflower.</p>

                <p>

                    Both polyunsaturated and monounsaturated fats may help improve your blood cholesterol when you use them in place of saturated and trans fats.</p>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Trans Fat</h5>



                <p>Trans fats (or trans fatty acids) are created in an industrial process that adds hydrogen to liquid vegetable oils to make them more solid. Another name for trans fats is “partially hydrogenated oils." Trans fats are found in many fried foods and baked goods.</p>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">How are trans fats harmful?</h5>



                <p>rans fats raise your bad (LDL) cholesterol levels and lower your good (HDL) cholesterol levels. These changes are associated with increased risk of heart disease.</p>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Regulating your intake of saturated and trans fats</h5>



                <p> Adults who would benefit from lowering LDL cholesterol reduce their intake of trans fat and limit their consumption of saturated fat to <7 % of total calories. Here are some ways to achieve that: </p>



                <ul class="list_1">

                    <li>Eat a dietary pattern that emphasizes fruits, vegetables, whole grains, low-fat dairy products, poultry, fish and nuts. Also limit red meat and sugary foods and beverages.</li>



                    <li>Use naturally occurring, unhydrogenated vegetable oils such as canola, safflower, sunflower or olive oil most often.</li>



                    <li>Doughnuts, cookies, crackers, muffins, pies and cakes are examples of foods that are high in trans fat. Don't eat them often.</li>



                    <li>Limit commercially fried foods and baked goods made with shortening or partially hydrogenated vegetable oils.</li>



                    <li>Limit fried fast food. </li>

                </ul>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Cholesterol-Lowering Drugs</h5>



                <p> Various medications can lower blood cholesterol levels.Statins are recommended for most patients because they are the only cholesterol-lowering drug class that has been directly associated with reduced risk for heart attack and stroke. The Following would benefit from statin, </p>



                <ul class="list_1">

                    <li>Adults with LDL (bad) cholesterol of 70-189 mg/dL and a 7.5 percent or higher risk for having a heart attack or stroke within 10 years.</li>



                    <li>People with a history of a cardiovascular event (heart attack, stroke, stable or unstable angina, peripheral artery disease, transient ischemic attack, or coronary or other arterial revascularization).</li>



                    <li>People 21 and older who have a very high level of LDL (bad) cholesterol (190 mg/dL or higher).</li>



                    <li>People with diabetes and a LDL (bad) cholesterol level of 70-189 mg/dL who are 40 to 75 years old.</li>



                </ul>









            </div>



        </div>

    </div>

   

</section>
<app-footer></app-footer>
