<app-header></app-header>
<nav class="navbar navbar-inverse">
    <div>
        <div style="position:relative;">
            <div>
                <div class="collapse navbar-collapse" id="myNavbar" style="float:left;padding:0px;clear:both;width:100%;background:#2d3e50;">
                    <div>
                        <ul class="nav navbar-nav ag-menu sm pl40" style="float:left;" data-smartmenus-id="14811307330159312">
                            <li class=""><a href="">HOME</a>
                            </li>
                            <li class="{{openlist}}"  (mouseover)='over()' (mouseout)='out()'><a href="" class="has-submenu">MYPRACTICE  </a>
                                <ul class="dropdown-menu sm-nowrap" >
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Dr Gokul reddy</a>
                                                    </div>
                                                    <div>
                                                        <a class="" routerLink="/practice-locations"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>Practice Locations</a>
                                                    </div>
                                                    <!-- <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Book  appointment</a>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                            </li>
                            <li class="{{openlist1}}" (mouseover)='over1()' (mouseout)='out1()'><a class="has-submenu" href="#" >PATIENT INFO </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/about-cholesterol"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>ABOUT CHOLESTEROL</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/angioplasty"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> ANGIOPLASTY</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/chestpain"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CHESTPAIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/common-misconceptions"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>COMMON MISCONCEPTIONS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/coronary-angiogram"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CORONARY ANGIOGRAM</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/exercsie-and-heart-health"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> EXERCSIE & HEART HEALTH</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/guide-to-taking-warfarin"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> GUIDE TO TAKING WARFARIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-attack"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTATTACK</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-fauilre"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTFAUILRE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/high-blood-pressure"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HIGH BLOOD PRESSURE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/htn-myths"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>HTN-MYTHS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/tips-to-lower"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>TIPS  TO LOWER CHOLESTEROL   LEVELS</a> </div>



                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li><a href="#">SERVICES</a></li>
                            <li><a href="#">APPOINTMENTS</a></li>
                            <li class=""><a  href="faq">FAQS </a></li>
                            <li class=""><a  href="blog">MY BLOG</a></li>

                            <li class=""> <a  href="reach-us">REACH US</a></li>

                            <div class="clearfix"></div>
                        </ul>
                        <div class="pull-right pn">
                            <a href="#"><img src="/../../assets/front/img/askdemo.png" style="height:52px;"/></a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</nav>

<section style="background:#fff;">

    <div class="container">

        <div  style="padding:30px 15px;">

            <div class="row">

                <h4 class="mb5" style="color: #0991cd;font-weight: bold;">Coronary Angiogram</h4>

                <!--<p class="pull-right"><img src="img/gokulreddy.png" style="height:350px;"/></p>-->

                <p>

                    Coronary angiogram is a procedure that uses X-ray imaging to see the heart's blood vessels. The test is generally done to see if there's a restriction or blockages in blood flow going to the heart.

                    During a coronary angiogram, a dye that's visible by an X-ray machine is injected into the blood vessels of heart. The X-ray machine rapidly takes a series of images (angiograms), offering a look at the blood vessels.

                </p>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Coronary angiogram might be recommended if you have:</h5>





                <ul class="list_1">

                    <li>Symptoms of coronary artery disease, such as chest pain (angina)</li>

                    <li>Pain in your chest, jaw, neck or arm that can't be explained by other tests</li>

                    <li> New or increasing chest pain (unstable angina)</li>

                    <li> Abnormal results on a noninvasive heart stress test</li>

                </ul>





                <p>

                    Because there's a small risk of complications, angiograms aren't usually done until after noninvasive heart tests have been performed,As with most procedures done on your heart and blood vessels, a coronary angiogram has some risks, such as radiation exposure from the X-rays used. Major complications are rare, though. Potential risks and complications include:

                </p>



                <ul class="list_1">

                    <li>Heart attack</li>

                    <li>Stroke</li>

                    <li>Injury to the catheterized artery</li>

                    <li>Irregular heart rhythms (arrhythmias)</li>

                    <li>Allergic reactions to the dye or medications used during the procedure</li>

                    <li>Kidney damage</li>

                    <li>Excessive bleeding</li>

                    <li>Infection



                </ul>







                <p>Angiograms are performed in the catheterization (cath) lab of a hospital. General guidelines include:</p>





                <ul class="list_1">

                    <li>Should be fasting for 3-4 hours prior to the procedure</li>

                    <li>Ask doctor about whether or not to take your usual morning medications as some medicines like Metformin and ACE inhibitors should be avoided on the day of angiogram</li>





                </ul>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;"> Cardiac catheterization procedure approaches</h5>



                <p>For the procedure, you lie on your back on an X-ray table. Because the table may be tilted during the procedure, safety straps may be fastened across your chest and legs. X-ray cameras may move over and around your head and chest to take pictures from many angles.

                </p>

                <p>

                    An IV line is inserted into a vein in your arm. You may be given a sedative through the IV to help you relax, as well as other medications and fluids. You might be little sleepy and may drift off to

                    sleep during the procedure, but you'll still be able to be easily awakened to follow any instructions.</p>



                <p>Electrodes on your chest monitor your heart throughout the procedure. A blood pressure cuff tracks your blood pressure and another device, a pulse oximeter, measures the amount of oxygen in your blood.</p>



                <p>A small amount of hair may be shaved from your groin or arm where a flexible tube (catheter) will be inserted. The area is washed and disinfected and then numbed with an injection of local anesthetic.</p>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Coronary angiogram</h5>



                <p>A small incision is made at the entry site, and a short plastic tube (sheath) is inserted into your artery. The catheter is inserted through the sheath into your blood vessel and carefully threaded to your heart or coronary arteries.</p>



                <p>Threading the catheter might cause mild pain in some, and you shouldn't feel it moving through your body. Dye (contrast material) is injected through the catheter. When this happens, you may have a brief sensation of flushing or warmth .</p>



                <p>The dye is easy to see on X-ray images. As it moves through your blood vessels, your doctor can observe its flow and identify any blockages or constricted areas.</p>



                <p>Having an angiogram takes about 15 minutes to 30 minutes , although it may be longer, especially if combined with other cardiac catheterization procedures.</p>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">After the procedure</h5>



                <p>When the angiogram is over, the catheter is removed from your arm or groin and the incision is closed with manual pressure .</p>



                <p>You'll be taken to a recovery area for observation and monitoring. When your condition is stable, you return to your own room/Ward, where you're monitored regularly.</p>



                <p> You'll need to lie flat for several hours to avoid bleeding if the catheter was inserted in the groin. During this time, pressure may be applied to the incision to prevent bleeding and promote healing.</p>



                <p>You may be able to go home the same day if the procedure is done through arm , or you may have to remain in the hospital overnight if the procedure is done through groin .If there are high risk feature , you might be instructed to stay overnight in hospital . Drink plenty of fluids to help flush the dye from your body.</p>



                <p>Your puncture site is likely to remain tender for a while. It may be slightly bruised and have a small bump.</p>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Call your doctor’s if:</h5>



                <ul class="list_1">

                    <li>You notice bleeding, new bruising or swelling at the catheter site</li>

                    <li>You develop increasing pain or discomfort at the catheter site</li>

                    <li>There's a change in temperature or color of the leg or arm that was used for the procedure</li>

                    <li>Weakness or numbness in the leg or arm where the catheter was inserted</li>

                    <li>You develop chest pain or shortness of breath</li>

                </ul>





                <p>An angiogram can show doctors what's wrong with your blood vessels. It can:</p>





                <ul class="list_1">

                    <li>Show how many of your coronary arteries are blocked or narrowed by fatty plaques (atherosclerosis)</li>

                    <li>Pinpoint where blockages are located in your blood vessels</li>

                    <li>Show how much blood flow is blocked through your blood vessels</li>

                    <li>Check the results of previous coronary bypass surgery</li>

                </ul>



                <p>Knowing this information can help your doctor determine what treatment is best for you and how much danger your heart condition poses to your health. Based on the results, your doctor would decide, you would benefit from having coronary angioplasty/stenting or bypass Surgery or to Optimise your medicines to clear blocked arteries.</p>



            </div>



        </div>

    </div>
</section>
<app-footer></app-footer>