import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-calculate-test-prices',
  templateUrl: './calculate-test-prices.component.html',
  styleUrls: ['./calculate-test-prices.component.css']
})
export class CalculateTestPricesComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private apicalls: ApicallsService, private router: ActivatedRoute,
    private route: Router, private httpClient: HttpClient, private formBuilder: FormBuilder) { }

  viwAllBillingsActiveType;
  dropdownSettings = {};
  dropdownList = [];
  userDetails
  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data
    this.apicalls.viewAllBillingsActiveTestTypes()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {
            this.viwAllBillingsActiveType = data;
            let tmp = [];
            for (let j = 0; j < this.viwAllBillingsActiveType.data.length; j++) {
              tmp.push({
                //this.viwAllBillingsActiveType.data.BillingID 
                BillingID: data.data[j].BillingID,
                BillingName: data.data[j].BillingName,
                //    BillingType: data.data[j].BillingType,
              })
            }
            this.dropdownList = tmp;
            this.spinner.hide();
          }
          if (data.code == 'V002') {
            alert(data.data);
            this.spinner.hide();
          }
        } else {
          alert(data.data)
          //  this.alertService.info(data.message)
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ");
      });


    this.dropdownSettings = {
      singleSelection: false,
      idField: 'BillingID',
      textField: 'BillingName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    }
  }

  obj = []
  obj1 = [];
  selectedItems = [];
  selectTest(value) {
    this.obj = this.selectedItems
  }
  onSelectAll(dropdownList) {
    this.obj = this.dropdownList
  }


  CalTestPriceForm = new FormGroup({
    Discount: new FormControl('', [Validators.pattern('^[0-9]*')]),
    TestArray: new FormArray([])
  });

  get Discount() {
    return this.CalTestPriceForm.get('Discount');
  }
  get TestArray() {
    return this.CalTestPriceForm.get('TestArray') as FormArray;
  }





  TempTestArray = [];
  PaymentStatus = "Paid";
  error;
  onSubmit(CalTestPriceForm) {
    if (this.CalTestPriceForm.invalid) {
      this.error = true;
      return;
    }
    for (let i = 0; i < this.selectedItems.length; i++) {
      //console.log(this.selectedItems[i]);
      this.TempTestArray.push(this.selectedItems[i].BillingID)
    }
    this.CalTestPriceForm.value.TestArray = this.TempTestArray;
    //this.paymentForm.value.PaymentStatus = this.PaymentStatus;
    console.log(" ###### ", this.CalTestPriceForm.value);
    //return;
    this.apicalls.calculateTestPrice(this.CalTestPriceForm.value)
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {
            this.spinner.hide();
            alert(data.data)
            console.log(" billing ", data);
            //  this.route.navigate(["/dashboardmain/ViewAllPayments"]);
          }
          if (data.code == 'V002') {
            alert(data.data);
            this.spinner.hide();
          }
        } else {
          alert(data.data)
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ");
      });
  }
  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }
  Settings(){
    this.route.navigate(["/dashboardmain/configurations"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
}