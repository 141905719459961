import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../services/apicalls.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-configurations',
  templateUrl: './configurations.component.html',
  styleUrls: ['./configurations.component.css']
})
export class ConfigurationsComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private httpclient: HttpClient, private apicalls: ApicallsService, private route: Router, private router: ActivatedRoute) { }
  userDetails;

  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data
  }

  /*Header Routers */

  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }

  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }

  /*Config Routers */

  Resources() {
    this.route.navigate(["/dashboardmain/viewallresources"]);
  }

  Roles() {
    this.route.navigate(["/dashboardmain/viewallroles"]);
  }
  Privileges() {
    this.route.navigate(["/dashboardmain/privileges"]);
  }
  Permissions() {
    this.route.navigate(["/dashboardmain/permissions"]);
  }
  ChannelPartners() {
    this.route.navigate(["/dashboardmain/channelpartners"]);
  }

  Hospitals() {
    this.route.navigate(["/dashboardmain/hospitals"]);
  }

  doctors() {
    this.route.navigate(["/dashboardmain/doctors"]);
  }

  Billings() {
    this.route.navigate(["/dashboardmain/ViewAllbillings"]);
  }

  master(){
    this.route.navigate(["/dashboardmain/master"]);
  }

}
