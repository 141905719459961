import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../../services/apicalls.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-resourcepage',
  templateUrl: './resourcepage.component.html',
  styleUrls: ['./resourcepage.component.css']
})
export class ResourcepageComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private httpclient: HttpClient, private apicalls: ApicallsService, private route: Router, private router: ActivatedRoute) { }
  data: '';
  Resources = [];

  ngOnInit(): void {
    this.apicalls.getAllResources()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == "S001") {
            setTimeout(() => {
              this.spinner.hide();
            }, 500);
            for (const [key, value] of Object.entries(data.data)) {
              this.Resources.push(`${value}`);
            }
          } else {
            alert(data.data)
          }
        } else {
          this.spinner.hide();
          if (data.code == 'PD01') {
            alert(data.data)
            this.route.navigate(["/dashboardmain/dashboard"]);
          }
          else {
            alert(data.data)
          }
          alert(data.data)
        }
      }, error => { alert(" Internal Server Error "); });

  }

  error = false;
  onSubmit(resourceForm) {
    if (this.resourceForm.invalid) {
      this.error = true;
      return;
    }
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);

    //console.log("@@@@@@@", this.resourceForm.value);
    let result = this.apicalls.createResources(this.resourceForm.value)
      .subscribe(data => {
        this.spinner.show();
        this.data = data;
        if (data.type == true) {
          this.spinner.hide();
          if (data.code == 'S001') {
            this.spinner.hide();
            alert(data.data)
            this.route.navigate(["/dashboardmain/viewallresources"]);
          }
          if (data.code == 'AE01') {
            this.spinner.hide();
            alert(data.data)
            //console.log("!!!!!@@@@@@@@@@@!!!1", data.data);
            this.route.navigate(["/dashboardmain/resources"]);
          }
          if (data.code == 'S002') {
            this.spinner.hide();
            alert(data.data)
            // console.log("!!!!!@@@@@@@@@@@!!!1", data.data);
            this.route.navigate(["/dashboardmain/viewallresources"]);
          }
        }
        else {
          if (data.code == 'PD01') {
            alert(data.data)
            this.route.navigate(["/dashboardmain/dashboard"]);
          }
          this.spinner.hide();
          alert(data.data)
        }
      },
        error => { alert(" Internal Server Error "); }
      );

  }

  get Resource() {
    return this.resourceForm.get('Resource');
  }
  get ResourceID() {
    return this.resourceForm.get('ResourceID');
  }



  resourceForm = new FormGroup({
    //ResourceID: new FormControl('', [Validators.required]),
    Resource: new FormControl('', [Validators.required])//, Validators.minLength(2), Validators.pattern('[a-zA-Z ]*')

  });



  // Resources(){
  //   this.route.navigate(["/GokulClinic/viewallresources"]);
  // }

  // dashboard(){
  //   this.route.navigate(["/dashboard"]);
  // }

  // Resources(){
  //   this.route.navigate(["/viewallresources"]);
  // }

  // Roles(){
  //   this.route.navigate(["/viewallroles"]);
  // }

  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }
}
