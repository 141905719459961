<!DOCTYPE html>
<!-- saved from url=(0031)http://drgokulreddy.in/patients -->
<html lang="en">

<body>
    <div id="page" class="side-nav-wrapper">
        <!-- Simple Navbar -->
        <!-- Simple Navbar -->
        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pull-right" style="margin-right: 10px;">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                                style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                                <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                                <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                    userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                        class="">Settings</a></li>
                                <li
                                    *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                    <a [routerLink]="" (click)="users()" class="">Users</a></li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>


        <div class="container bg-gray">
            <div class="row">
                <div class="col-md-4">
                    <h5 class="dr-sub-head">Users</h5>
                </div>
            </div>
            <!-- <div class="col-md-4"> -->
            <div class="btn-signin">
                <a [routerLink]="" (click)="onClickCreate()">
                    Create User </a>
            </div>

            <div class="row orr-usercreation ptop30 pbtom30">

                <div class="col-md-12">


                    <div class="panel">


                        <table cellspacing="0" cellpadding="0" width="100%" class="mirchi-birthday-grid" id="example">
                            <thead>
                                <tr>
                                    <th style="text-align:center;"> S.No</th>
                                    <!-- <th style="text-align:center;"> Billing ID </th> -->
                                    <th style="text-align:center;">Name</th>
                                    <!-- <th style="text-align:center;"> Alias Name</th>
                                                <th style="text-align:center;"> Address </th> -->
                                    <th style="text-align:center;">ContactNumber</th>
                                    <th style="text-align:center;"> EmailID</th>
                                    <th style="text-align:center;"> Age </th>
                                    <th style="text-align:center;">Role </th>

                                    <th style="text-align:center;"> Status</th>

                                    <th style="text-align:center;"> Edit</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let list of userslist?.data; let i=index ">
                                    <td style="text-align:center;">{{i + 1}}</td>

                                    <!-- <td style="text-align:center;">{{list.BillingID}}</td> -->
                                    <td style="text-align:center;">{{list.FirstName}} {{list.LastName}}</td>
                                    <td style="text-align:center;">{{list.ContactNumber}}</td>
                                    <td style="text-align:center;">{{list.EmailID}}</td>
                                    <td style="text-align:center;">{{list.Age}}</td>
                                    <td style="text-align:center;">{{list.Roles[0].Role}}</td>

                                    <td style="text-align:center;">{{list.Status}}</td>
                                    <td>

                                        <a [routerLink]="" (click)="editdata(list.MedicID)"><img
                                                src="../assets/images/edit.png"></a>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>

</html>
<ngx-spinner></ngx-spinner>