import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './component/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from "ngx-spinner";
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';



import { ApicallsService } from './services/apicalls.service';

import { TokenInterceptorService } from './services/token-interceptor.service';
import { ForgotpasswordComponent } from './component/forgotpassword/forgotpassword.component';
import { ChangepasswordComponent } from './component/changepassword/changepassword.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { ResourcepageComponent } from './component/resources/resourcepage/resourcepage.component';
import { ViewallresourcesComponent } from './component/resources/viewallresources/viewallresources.component';
import { ViewallrolesComponent } from './component/roles/viewallroles/viewallroles.component';
import { CreateroleComponent } from './component/roles/createrole/createrole.component';
import { UpdateroleComponent } from './component/roles/updaterole/updaterole.component';
import { ViewallprivilegesComponent } from './privileges/viewallprivileges/viewallprivileges.component';
import { CreateprivilegesComponent } from './privileges/createprivileges/createprivileges.component';
import { DashboardmainComponent } from './dashboardmain/dashboardmain.component';
import { ViewallpermissionsComponent } from './permissions/viewallpermissions/viewallpermissions.component';
import { ViewallchannelpartnersComponent } from './channelpartners/viewallchannelpartners/viewallchannelpartners.component';
import { CreatechannelpartnerComponent } from './channelpartners/createchannelpartner/createchannelpartner.component';
import { UpdatechannelpartnerComponent } from './channelpartners/updatechannelpartner/updatechannelpartner.component';
import { LogoutComponent } from './logout/logout.component';
import { CreatepermissionsComponent } from './permissions/createpermissions/createpermissions.component';
import { UpdatepermissionsComponent } from './permissions/updatepermissions/updatepermissions.component';
import { ViewallhospitalsComponent } from './hospitals/viewallhospitals/viewallhospitals.component';
import { CreatehospitalsComponent } from './hospitals/createhospitals/createhospitals.component';
import { UpdatehospitalsComponent } from './hospitals/updatehospitals/updatehospitals.component';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ViewalldoctorsComponent } from './doctors/viewalldoctors/viewalldoctors.component';
import { CreatedoctorsComponent } from './doctors/createdoctors/createdoctors.component';
import { UpdatedoctorsComponent } from './doctors/updatedoctors/updatedoctors.component';
import { ViewallpatientsComponent } from './patients/viewallpatients/viewallpatients.component';
import { CreatepatientsComponent } from './patients/createpatients/createpatients.component';
import { UpdatepatientsComponent } from './patients/updatepatients/updatepatients.component';
import { ViewallmyappointmentsComponent } from './patients/viewallmyappointments/viewallmyappointments.component';
import { CreateappointmentsComponent } from './appointments/createappointments/createappointments.component';
import { ViewallappointmentsComponent } from './appointments/viewallappointments/viewallappointments.component';
import { UpdateappointmentsComponent } from './appointments/updateappointments/updateappointments.component';
import { UpdateappointmentsprescriptionComponent } from './appointments/updateappointmentsprescription/updateappointmentsprescription.component';
import { CreateappointmentforexistpatientsComponent } from './appointments/createappointmentforexistpatients/createappointmentforexistpatients.component';
import { CreatebillingsComponent } from './billings/createbillings/createbillings.component';
import { ViewAllbillingsComponent } from './billings/view-allbillings/view-allbillings.component';
import { TodaysAppointmentsComponent } from './appointments/todays-appointments/todays-appointments.component';
import { UpdatebillingComponent } from './billings/updatebilling/updatebilling.component';
import { CreateTestPaymentsComponent } from './payments/create-test-payments/create-test-payments.component';
import { ViewAllPaymentsComponent } from './payments/view-all-payments/view-all-payments.component';
import { UpdatePaymentsComponent } from './payments/update-payments/update-payments.component';
import { CalculateTestPricesComponent } from './payments/calculate-test-prices/calculate-test-prices.component';
import { RevenueComponent } from './revenue/revenue.component';
import { ConfigurationsComponent } from './configurations/configurations.component';
import { FollowupappointmentsComponent } from './followupappointments/followupappointments.component';
import { AllechoreportsComponent } from './2dechoreport/allechoreports/allechoreports/allechoreports.component';
import { CreatereportComponent } from './2dechoreport/createechoreport/createreport/createreport.component';
import { CreateUserComponent } from './Users/create-user/create-user.component';
import { ViewAllUsersComponent } from './Users/view-all-users/view-all-users.component';
import { UpdateUserComponent } from './Users/update-user/update-user.component';
import { UploadHelathReportsComponent } from './Reports/upload-helath-reports/upload-helath-reports.component';
import { ViewAllHelathReportsComponent } from './Reports/view-all-helath-reports/view-all-helath-reports.component';
import { ViewEchoReportsComponent } from './2dechoreport/view-echo-reports/view-echo-reports.component';
import { ViewHealthReportComponent } from './Reports/view-health-report/view-health-report.component';
import { CreatePatientBillingsComponent } from './PatientBillings/create-patient-billings/create-patient-billings.component';
import { Print2DEchoReportComponent } from './2dechoreport/print-2d-echo-report/print-2d-echo-report.component';


import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';

import { AutoCompleteModule } from '@syncfusion/ej2-angular-dropdowns';

import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { ViewReportsbylabtechComponent } from './Reports/view-reportsbylabtech/view-reportsbylabtech.component';
import { MasterComponent } from './master/master.component';

import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { PatientsbycategoryComponent } from './patientsbycategory/patientsbycategory.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { GokulComponent } from './gokul/gokul.component';
import { PractiselocationComponent } from './practiselocation/practiselocation.component';
import { AboutcholesterolComponent } from './aboutcholesterol/aboutcholesterol.component';
import { AngioplastyComponent } from './angioplasty/angioplasty.component';
import { ChestpainComponent } from './chestpain/chestpain.component';
import { CommonMisconceptionsComponent } from './common-misconceptions/common-misconceptions.component';
import { CoronaryAngiogramComponent } from './coronary-angiogram/coronary-angiogram.component';
import { ExercsieAndHeartHealthComponent } from './exercsie-and-heart-health/exercsie-and-heart-health.component';
import { GuideToTakingWarfarinComponent } from './guide-to-taking-warfarin/guide-to-taking-warfarin.component';
import { HeartAttackComponent } from './heart-attack/heart-attack.component';
import { HeartFauilreComponent } from './heart-fauilre/heart-fauilre.component';
import { HighBloodPressureComponent } from './high-blood-pressure/high-blood-pressure.component';
import { HtnMythsComponent } from './htn-myths/htn-myths.component';
import { TipsToLowerComponent } from './tips-to-lower/tips-to-lower.component';
import { FaqComponent } from './faq/faq.component';
import { ReachUsComponent } from './reach-us/reach-us.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotpasswordComponent,
    ChangepasswordComponent,
    DashboardComponent,
    ResourcepageComponent,
    ViewallresourcesComponent,
    ViewallrolesComponent,
    CreateroleComponent,
    UpdateroleComponent,
    ViewallprivilegesComponent,
    CreateprivilegesComponent,
    DashboardmainComponent,
    ViewallpermissionsComponent,
    ViewallchannelpartnersComponent,
    CreatechannelpartnerComponent,
    UpdatechannelpartnerComponent,
    LogoutComponent,
    CreatepermissionsComponent,
    UpdatepermissionsComponent,
    ViewallhospitalsComponent,
    CreatehospitalsComponent,
    UpdatehospitalsComponent,
    ViewalldoctorsComponent,
    CreatedoctorsComponent,
    UpdatedoctorsComponent,
    ViewallpatientsComponent,
    CreatepatientsComponent,
    UpdatepatientsComponent,
    ViewallmyappointmentsComponent,
    CreateappointmentsComponent,
    ViewallappointmentsComponent,
    UpdateappointmentsComponent,
    UpdateappointmentsprescriptionComponent,
    CreateappointmentforexistpatientsComponent,
    CreatebillingsComponent,
    ViewAllbillingsComponent,
    TodaysAppointmentsComponent,
    UpdatebillingComponent,
    CreateTestPaymentsComponent,
    ViewAllPaymentsComponent,
    UpdatePaymentsComponent,
    CalculateTestPricesComponent,
    RevenueComponent,
    ConfigurationsComponent,
    FollowupappointmentsComponent,
    AllechoreportsComponent,
    CreatereportComponent,
    CreateUserComponent,
    ViewAllUsersComponent,
    UpdateUserComponent,
    UploadHelathReportsComponent,
    ViewAllHelathReportsComponent,
    ViewEchoReportsComponent,
    ViewHealthReportComponent,
    CreatePatientBillingsComponent,
    ViewReportsbylabtechComponent,
    MasterComponent,
    Print2DEchoReportComponent,
    PatientsbycategoryComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    GokulComponent,
    PractiselocationComponent,
    AboutcholesterolComponent,
    AngioplastyComponent,
    ChestpainComponent,
    CommonMisconceptionsComponent,
    CoronaryAngiogramComponent,
    ExercsieAndHeartHealthComponent,
    GuideToTakingWarfarinComponent,
    HeartAttackComponent,
    HeartFauilreComponent,
    HighBloodPressureComponent,
    HtnMythsComponent,
    TipsToLowerComponent,
    FaqComponent,
    ReachUsComponent

  ],
  imports: [
    AutoCompleteModule,
    CheckBoxModule,
    DropDownListModule,

    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserModule,
    RouterModule,
    BrowserAnimationsModule,
    NgxSpinnerModule, NgMultiSelectDropDownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    Ng2SearchPipeModule,
    NgxPaginationModule

  ],
  providers: [ApicallsService, {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptorService,

    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
