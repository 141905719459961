<app-header></app-header>

<nav class="navbar navbar-inverse">
    <div>
        <div style="position:relative;">
            <div>
                <div class="collapse navbar-collapse" id="myNavbar" style="float:left;padding:0px;clear:both;width:100%;background:#2d3e50;">
                    <div>
                        <ul class="nav navbar-nav ag-menu sm pl40" style="float:left;" data-smartmenus-id="14811307330159312">
                            <li class=""><a href="">HOME</a>
                            </li>
                            <li class="{{openlist}}" (mouseover)='over()' (mouseout)='out()'><a href="" class="has-submenu">MYPRACTICE  </a>
                                <ul class="dropdown-menu sm-nowrap" >
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Dr Gokul reddy</a>
                                                    </div>
                                                    <div>
                                                        <a class="" routerLink="/practice-locations"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>Practice Locations</a>
                                                    </div>
                                                    <!-- <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Book  appointment</a>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                            </li>
                            <li class="{{openlist1}}" (mouseover)='over1()' (mouseout)='out1()'><a class="has-submenu" href="#" >PATIENT INFO </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/about-cholesterol"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>ABOUT CHOLESTEROL</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/angioplasty"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> ANGIOPLASTY</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/chestpain"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CHESTPAIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/common-misconceptions"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>COMMON MISCONCEPTIONS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/coronary-angiogram"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CORONARY ANGIOGRAM</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/exercsie-and-heart-health"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> EXERCSIE & HEART HEALTH</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/guide-to-taking-warfarin"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> GUIDE TO TAKING WARFARIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-attack"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTATTACK</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-fauilre"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTFAUILRE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/high-blood-pressure"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HIGH BLOOD PRESSURE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/htn-myths"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>HTN-MYTHS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/tips-to-lower"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>TIPS  TO LOWER CHOLESTEROL   LEVELS</a> </div>



                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li><a href="#">SERVICES</a></li>
                            <li><a href="#">APPOINTMENTS</a></li>
                            <li class=""><a  href="faq">FAQS </a></li>
                            <li class=""><a  href="blog">MY BLOG</a></li>

                            <li class=""> <a  href="reach-us">REACH US</a></li>

                            <div class="clearfix"></div>
                        </ul>
                        <div class="pull-right pn">
                            <a href="#"><img src="/../../assets/front/img/askdemo.png" style="height:52px;"/></a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</nav>
<section style="background:#fff;">
    <div class="container">
        <div  style="padding:30px 15px;">
            <div class="row">
                <h4 class="mb5" style="color: #0991cd;font-weight: bold;text-transform: uppercase;">KEEP IN  <span style="color:#2d3e50;">TOUCH WITH US</span></h4>
                <div class="row">
                    <div class="col-md-4 mln">
                        <div class="col-md-10 cont-overRight mt20" style="color:#333;min-height:120px;">
                            <p style="color:#576573;" class="pr5 pl50 mbn">  <a href="mailto:gokulreddy@yahoo.com" style="color:#576573;">gokulreddy@yahoo.com </a></p>
                            <p style="color:#576573;" class="pr5 pl50 mbn"> <a href="mailto:info@drgokulreddy.in " style="color:#576573;">info@drgokulreddy.in </a></p>
                        </div>
                        <div class="col-md-2 contact-mail text-center">
                            <img src="/../../assets/front/img/email.png" contact-mailwidth="35">
                        </div>
                        <div class="clearfix"></div>
                        <h6 style="color:#000;font-weight:bold;" class="pt10 mbn">         Dr. Gokul's Cardiac Clinic </h6>
                        <p> Street Number - 8 , Opp. ICICI Direct , <br>Habsiguda, Hyderabad</p>
                        <p><iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15226.999644433496!2d78.5350669!3d17.4237855!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd835d5514ccafadc!2sElbit+Diagnostics!5e0!3m2!1sen!2sin!4v1496645598250" width="400" height="260" frameborder="0" style="border:0" allowfullscreen></iframe></p>
                    </div>

                    <div class="col-md-4">
                        <div class="col-md-10 cont-overRight mt20" style="color:#333;min-height:120px;">
                            <p style="color:#576573;" class="pr5 pl50 mbn"> CLINIC : (91) 93816 13672 <br> 040 - 35100354
</p>
                            <p style="color:#576573;" class="pr5 pl50 mbn"> HOSPITAL- 040 - 2360 7777</p>
                        </div>
                        <div class="col-md-2 contact-mail text-center">
                            <img src="/../../assets/front/img/call-contact.png" contact-mailwidth="35">
                        </div>
                        <div class="clearfix"></div>
                        <h6 style="color:#000;font-weight:bold;" class="pt10 mbn"> APOLLO Hospitals   </h6>
                        <p> Jubilee Hills<br>
                            Hyderabad -500096 , T.S (INDIA)</p>
                        <p>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d243643.88901967186!2d78.41234!3d17.41487!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfb2d302be3c6df60!2sApollo+Hospitals!5e0!3m2!1sen!2sin!4v1533221062603" width="400" height="260" frameborder="0" style="border:0" allowfullscreen></iframe>
                           </p>
                    </div>

                    <div class="col-md-4">
                        <div class="col-md-10 cont-overRight mt20" style="color:#333;min-height:120px;">
                            <p style="color:#576573;" class="pr5 pl50 mbn">  <a href="mailto:info@gokul.com" style="color:#576573;">Street Number - 8 , <br>Opp. ICICI Direct , Habsiguda, </a></p>
                            <p style="color:#576573;" class="pr5 pl50 mbn"> <a href="mailto:info@gokul.com" style="color:#576573;"> Hyderabad 500007</a></p>
                        </div>
                        <div class="col-md-2 contact-mail text-center">
                            <img src="/../../assets/front/img/location.png" contact-mailwidth="35">
                        </div>
                        <div class="clearfix"></div>
                        <h6 style="color:#000;font-weight:bold;" class="pt10 mbn"> ADITYA Hospitalsl </h6>
                        <p> Address:  Opp. Bharath Petrol Pump, Warangal Highway Mainroad, Shanthi Nagar, Uppal, Hyderabad - 500039</p>
                        <p><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.210157338699!2d78.57336651443904!3d17.40169928807!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9ecf02047eff%3A0xf2ee02b47539263!2sAditya+Hospital!5e0!3m2!1sen!2sin!4v1496645753794" width="400" height="260" frameborder="0" style="border:0" allowfullscreen></iframe></p>
                    </div>

                </div>
              
            </div>

        </div>
    </div>
   
</section>

<app-footer></app-footer>
