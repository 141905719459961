<!DOCTYPE html>
<!-- saved from url=(0031)http://drgokulreddy.in/patients -->
<html lang="en">

<body>
    <div id="page" class="side-nav-wrapper">
        <!-- Simple Navbar -->
        <!-- Simple Navbar -->
        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pull-right" style="margin-right: 10px;">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                                style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                                <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                                <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                    userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                        class="">Settings</a></li>
                                <li
                                    *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                    <a [routerLink]="" (click)="users()" class="">Users</a></li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>


        <div class="container bg-gray">
            <div class="row">
                <div class="col-md-12">
                    <h5 class="dr-sub-head">Master</h5>
                </div>
            </div>

            <!-- <div class="row">
                <div class="col-md-12">
                    <h5 class="dr-sub-head"></h5>
                </div>
            </div> -->

            <div class="row">


                <a [routerLink]="" (click)="Billings()" class="">
                    <div class="col-md-4" [ngStyle]="typeValue && {'background-color': '#cecaca'}">
                        <div class="card">
                            <img src="../assets/images/billing2.png" alt="Avatar" style="align-items: center;width:20%">
                            <div class="container">
                                <h4><b>Billing Type</b></h4>
                            </div>
                        </div>
                    </div>
                </a>


                <a [routerLink]="" (click)="BillingsTest()" class="">
                    <div class="col-md-4" [ngStyle]="typetestValue && {'background-color': '#cecaca'}">
                        <div class="card">
                            <img src="../assets/images/billing2.png" alt="Avatar" style="align-items: center;width:20%">
                            <div class="container">
                                <h4><b>Billing Test</b></h4>
                            </div>
                        </div>
                    </div>
                </a>



            </div>

            <div class="row orr-usercreation ptop30 pbtom30">

                <div class="col-md-12">

                    <form *ngIf="typeValue" autocomplete="off" [formGroup]="mastertypeForm">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="input-text form-group has-feedback">
                                    <label style="width:200px;float:left;text-align:right;padding-right:15px;">Billing
                                        Type
                                        Name<span class="required"> * </span></label>
                                    <input
                                        [class.is-invalid]="BillingName.invalid && (BillingName.dirty || BillingName.touched)"
                                        class="form-control" id="BillingName" type="text" name="BillingName"
                                        placeholder=" Billing Type Name" formControlName="BillingName">


                                    <small *ngIf="error && BillingName.errors?.required" class="text-danger">Billing
                                        Type
                                        Name is required</small>

                                </div>

                            </div>
                        </div>
                        <div class="row">

                            <div class="col-md-6">
                                <div class="input-text form-group has-feedback">
                                    <label style="width:200px;float:left;text-align:right;padding-right:15px;">Select
                                        Doctor Required<span class="required"> * </span></label>



                                    <div style="padding-left : 20px;">
                                        <input id="IsDoctorRequired" type="radio" value="Yes" name="IsDoctorRequired"
                                            formControlName="IsDoctorRequired">
                                        <label for="Yes">YES</label>
                                    </div>

                                    <div style="padding-left : 40px;">
                                        <input id="IsDoctorRequired" type="radio" value="No" name="IsDoctorRequired"
                                            formControlName="IsDoctorRequired">
                                        <label for="No">NO</label>
                                    </div>

                                    <small *ngIf="error && IsDoctorRequired.error?.required" class="text-danger">Select
                                        an Option
                                    </small>
                                </div>

                            </div>
                        </div>

                        <div
                            style="margin:auto;text-align:center;margin-bottom:10px;margin-top:30px;margin-bottom:30px;">
                            <a [routerLink]="" (click)="onSubmit(mastertypeForm)"> <input type="submit" class="btn-pink"
                                    value="ADD"></a>

                        </div>

                    </form>





                    <form *ngIf="typetestValue" autocomplete="off" [formGroup]="mastertestForm">
                        <div class="col-md-8">
                            <div class="input-text form-group has-feedback">
                                <label style="width:200px;float:left;text-align:right;padding-right:15px;">Billing Type
                                    <span class="required"> * </span></label>
                                <select class="way-custom-dropdown price-text-field profile-mwidth form-control"
                                    style="background-color:#fff;margin-bottom:20px;" formControlName="BillingType"
                                    id="BillingType" name="BillingType" required="">
                                    <option value="" disabled>Select Billing Type</option>

                                    <option *ngFor="let list1 of viewAllbillingtypes.data; let i=index"
                                        value="{{list1.BillingID}}">{{list1.BillingName}}</option>

                                </select>


                                <div style="text-align: center;">
                                    <small *ngIf="error && BillingType.errors?.required" class="text-danger">Billing
                                        Type is required
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div style="clear:both;"></div>
                        <div class="col-md-8">
                            <div class="input-text form-group has-feedback">
                                <label style="width:200px;float:left;text-align:right;padding-right:15px;">Billing
                                    Name<span class="required"> * </span></label>
                                <input
                                    [class.is-invalid]="BillingName1.invalid && (BillingName1.dirty || BillingName1.touched)"
                                    class="form-control" id="BillingName1" type="text" name="BillingName1"
                                    placeholder=" Billing Name" formControlName="BillingName1" required="">


                                <small *ngIf="error && BillingName1.errors?.required" class="text-danger">Billing Name
                                    is required</small>

                            </div>
                        </div>
                        <div style="clear:both;"></div>

                        <div
                            style="margin:auto;text-align:center;margin-bottom:10px;margin-top:30px;margin-bottom:30px;">
                            <a [routerLink]="" (click)="onSubmittest(mastertestForm)"> <input type="submit"
                                    class="btn-pink" value="ADD"></a>

                        </div>

                    </form>



                    <div class="panel">


                        <table *ngIf="typeValue" cellspacing="0" cellpadding="0" width="100%"
                            class="mirchi-birthday-grid" id="example">
                            <thead>
                                <tr>
                                    <th style="text-align:center;"> S.No</th>
                                    <th style="text-align:center;"> Billing Name </th>


                                    <th style="text-align:center;"> Remove</th>




                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                <tr *ngFor="let list of viewAllbillingtypes?.data; let i=index ">
                                    <td style="text-align:center;">{{i + 1}}</td>

                                    <td style="text-align:center;">{{list.BillingName}}</td>

                                    <td>

                                        <a [routerLink]="" (click)="editdata(list)"><img
                                                src="http://drgokulreddy.in/assets/img/delete.png"></a>
                                    </td>




                                </tr>
                                <tr>


                                </tr>
                            </tbody>
                        </table>


                        <table *ngIf="typetestValue" cellspacing="0" cellpadding="0" width="100%"
                            class="mirchi-birthday-grid" id="example">
                            <thead>
                                <tr>
                                    <th style="text-align:center;"> S.No</th>
                                    <th style="text-align:center;"> Billing Name </th>
                                    <th style="text-align:center;"> Billing Type </th>
                                    <th style="text-align:center;"> Remove</th>

                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                <tr *ngFor="let list of viewAllbillingtests?.data; let i=index ">
                                    <td style="text-align:center;">{{i + 1}}</td>
                                    <td style="text-align:center;">{{list.BillingName}}</td>
                                    <td style="text-align:center;">{{list.MasterTypeData[0].BillingName}}</td>

                                    <td>

                                        <a [routerLink]="" (click)="editdatatest(list)"><img
                                                src="http://drgokulreddy.in/assets/img/delete.png"></a>
                                    </td>



                                </tr>
                                <tr>


                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>

</html>
<ngx-spinner></ngx-spinner>