<!DOCTYPE html>
<!-- saved from url=(0031)http://drgokulreddy.in/patients -->
<html lang="en">

<body>
    <div id="page" class="side-nav-wrapper">
        <!-- Simple Navbar -->
        <!-- Simple Navbar -->
        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pull-right" style="margin-right: 10px;">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                                style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                                <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                                <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                    userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                        class="">Settings</a></li>
                                <li
                                    *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                    <a [routerLink]="" (click)="users()" class="">Users</a></li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>


        <div class="container bg-gray">
            <div class="row" style="padding-top: 10px; padding-left: 15px;">
                <div class="col-md-4">
                    <!-- <button type="button" style="background:#367fa9; width: 200px ;height: 40px; border-radius: 5px;
                        font-weight: bold; color: white; font-family: 'Open Sans', sans-serif;">
                        <a [routerLink]="" (click)="onClickCreate()" style="color: white;">
                            <input type="submit" class="btn-pink" value="Create New Appointments">
                            Create New Appointments
                        </a>
                    </button> -->
                </div>
                <div class="col-md-4" style="padding-left: 480px;">

                    <!-- <button type="button" style="background:#367fa9; width: 200px ;height: 40px; border-radius: 5px;
                    font-weight: bold; color: white; font-family: 'Open Sans', sans-serif;"> -->
                    <a [routerLink]="" (click)="todaysAppointments()" style="color: white;">
                        <input type="submit" class="btn-pink" value="Todays Appointments">

                    </a>
                    <!-- <div class="row">
                                <div class="col-md-6 pt10">
                                    <div class="row">
                                        <span class="col-md-6 text-right pt10">Search</span>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control ng-pristine ng-valid ng-empty ng-touched" ng-model="localfilter.$" placeholder="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                    <!-- </button> -->
                </div>

                <!-- <div class="col-md-4" >
                <div class="form-group">
                        
                    <input type="text" style="color: black;"class="form-control" placeholder="Enter text here to search " [(ngModel)]="term">
                </div>
                </div> -->
            </div>
            <div class="row orr-usercreation ptop30 pbtom30">

                <div class="col-md-12">


                    <div class="panel">
                        <div style="text-align: center; color:#367fa9;">
                            <h4 style=" font-weight: bold;">
                                All Appointments
                            </h4>


                            <!-- <div class="col-md-4" style="float: right" >
                                    <div class="form-group" >
                                            
                                        <input type="text" class="form-control" placeholder="Enter text here to search " [(ngModel)]="term">
                                    </div>
                                    </div> -->
                        </div>
                        <div class="row" style="padding-left: 20px;">
                            <div class="col-md-6" style="padding-right: 150px;">
                                <label>Search with Patient's Name or AppointmentID</label>
                            </div>
                            <div class="col-md-6" style="padding-left: 200px; padding-right: 10px;">
                                <label>No of records per page</label>
                            </div>
                        </div>
                        <div class="row" style="padding-left: 20px;">
                            <div class="col-md-3">
                                <div class="input-text form-group has-feedback">
                                    <i class="fa fa-search"
                                        style="font-size:24px;padding-top: 10px;padding-right: 10px;"></i><input
                                        type="text" class="form-control" placeholder=" Enter text here to search "
                                        [(ngModel)]="term">
                                </div>
                            </div>

                            <div class="col-md-3" style="margin-left: 500px;margin-right: 0px;">
                                <div class="form-group">
                                    <select class="way-custom-dropdown price-text-field profile-mwidth form-control"
                                        id="limit" name="limit" style="width: 50px;" #Selectedlimit
                                        (change)='onLimit(Selectedlimit.value)'>
                                        <option selected value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                        </div>


                        <!-- <table cellspacing="0" cellpadding="0" width="100%" class="mirchi-birthday-grid" id="example">
                            <thead>
                                <tr>
                                    <th style="text-align:center;"> S.No</th>
                                    <th style="text-align:center;"> Appointment ID </th>
                                    <th style="text-align:center;"> Patient Name</th>
                                    <th style="text-align: center;">Appointment Date</th>
                                    <th style="text-align:center;"> Status</th>
                                    <th style="text-align:center;"> Actions</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <tr *ngFor="let list of viewallappointments | filter:term; let i=index ">
                                    <td style="text-align:center;">{{i + 1}}</td>
                                    <td style="text-align:center;">{{list.AppointmentID}}</td>
                                    <td style="text-align:center;">{{list.PatientName}}</td>
                                    <td style="text-align:center;">{{list.Date *1000 | date:"dd-MM-yyy"}} </td>
                                    <td style="text-align:center;">{{list.Status}}</td>
                                    <td>
                                        <a [routerLink]="" (click)="editprescription(list.AppointmentID)">
                                            <img src="http://drgokulreddy.in/assets/img/prescription-icon.png">
                                        </a>
                                        <a [routerLink]="" *ngIf="list.Type"
                                            (click)="PrintPrescription(list.AppointmentID)"><img
                                                src="../assets/images/print2.png" width="25px" height="25px"
                                                alt="prescription Print"></a>
                                        <a *ngIf="!list.Type" [disabled]="!list.Type"><img
                                                src="../assets/images/print.png" width="25px" height="25px"
                                                alt="No prescription"></a>
                                    </td>
                                </tr>
                                <tr>
                                </tr>
                            </tbody>
                        </table> -->







                        <table cellspacing="0" cellpadding="0" width="100%" class="mirchi-birthday-grid" id="example">
                            <thead>
                                <tr>
                                    <th style="text-align:center;"> S.No</th>
                                    <th style="text-align:center;"> Appointment ID </th>
                                    <th style="text-align:center;"> Patient Name</th>
                                    <th style="text-align: center;">Appointment Date</th>
                                    <!-- <th style="text-align:center;"> Status</th> -->
                                    <th style="text-align:center;"> Actions</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <tr *ngFor="let item of collection | paginate: config|filter:term; let i=index ;">
                                    <td style="text-align:center;">{{i + 1}}</td>
                                    <td style="text-align:center;">{{item.AppointmentID}}</td>
                                    <td style="text-align:center;">{{item.PatientName}}</td>
                                    <td style="text-align:center;">{{item.Date *1000 | date:"dd-MM-yyy"}} </td>
                                    <!-- <td style="text-align:center;">{{item.Status}}</td> -->
                                    <td>
                                        <a [routerLink]="" (click)="editprescription(item.AppointmentID)">
                                            <img src="http://drgokulreddy.in/assets/img/prescription-icon.png">
                                        </a>
                                        <a [routerLink]="" *ngIf="item.Type"
                                            (click)="PrintPrescription(item.AppointmentID)"><img
                                                src="../assets/images/print2.png" width="25px" height="25px"
                                                alt="prescription Print"></a>
                                        <a *ngIf="!item.Type" [disabled]="!item.Type"><img
                                                src="../assets/images/print.png" width="25px" height="25px"
                                                alt="No prescription"></a>
                                    </td>
                                </tr>
                                <tr>
                                </tr>
                            </tbody>
                        </table>




                        <!-- <ul>
                            <li *ngFor="let item of collection | paginate: config">{{ item.AppointmentID }}</li>
                        </ul> -->
                        <pagination-controls (pageChange)="pageChange($event)" class="my-pagination">
                        </pagination-controls>


                    </div>
                </div>
            </div>
        </div>
    </div>
</body>

</html>
<ngx-spinner></ngx-spinner>