<footer id="footer" >
    <div class="footer-widget" >
        <div class="container pv30">
            <div class="row">

                <div class="col-xs-12 col-sm-6 col-md-3 widget">
                    <div class="widget-title">
                        <!-- Title -->
                        <img src="/../../assets/front/img/logo.png"/>
                    </div>
                    <div>
                        
                        <div class="col-md-3">
                            <img src="/../../assets/front/img/footer1.png" class="pr5"/>
                        </div>
                        <div class="col-md-9 pln pb15">
                            <p class="mb5">
                            Habsiguda, Street Number - 8 ,<br>
                            Opp. ICICI Direct ,  Hyderabad, Telangana 500007
                        </p>
                        </div>
                        
                        
                        <div class="col-md-3">
                            <img src="/../../assets/front/img/footer2.png" class="pr5"/>
                        </div>
                        <div class="col-md-9 pln pb15">
                            <p class="mb5">
                            CLINIC : (91) 9381613672<br>040 35100354<br> HOSPITAL- 04067191919
                        </p>
                        </div>
                       
                      
                        <div class="col-md-3">
                            <img src="/../../assets/front/img/footer3.png" class="pr5"/>
                        </div>
                        <div class="col-md-9 pln pb15">
                            <p class="mb5">
                            gokulreddy@yahoo.com <br>
                            info@drgokulreddy.in
                        </p>
                        </div>
                      
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 widget">
                    <div class="widget-title">
                        <!-- Title -->
                        <h3 class="title" style="font-weight:600;"> ABOUT US </h3>
                        <p style="height: 2px;background: #2b96cc;width: 60px;"></p>
                    </div>
                    <div>
                        <p> <b> Dr Gokul Reddy Mandala</b> is an interventional Cardiologist with an interest in the diagnosis and management of patients presenting with chest pain. He has an experience of over 15 years in the field of interventional cardiology and has performed several thousand cardiac interventions like Coronary angioplasty with stenting, peripheral angioplasty, and Renal angioplasty, Structural heart disease interventional therapies like, PBMV, ASD, PDA and VSD device closures.</p>
                        <p>He has a special interest in managing patients with Heart failure, Aortic diseases and Pulmonary Hypertension.</p>
                        <p class="socialLinks mt20">

                            <a href="#" class="pr10" style="padding-left:12px;"><i class="fa fa-facebook" aria-hidden="true"></i> </a>

                            <a href="#" class="pl10 pr10"><span class="fa fa-linkedin"></span>  </a>

                            <a href="#"><span class="fa fa-google-plus"></span>  </a>

                            <a href="#" class="pl10 pr10"><span class="fa fa-twitter"></span>  </a>



                        </p>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-3 widget">
                    <div class="widget-title">
                        <!-- Title -->
                        <h3 class="title" style="font-weight:600;"> USEFUL LINKS </h3>
                        <p style="height: 2px;background: #2b96cc;width: 60px;"></p>
                    </div>

                    <div class="icons-circle i-3x">
                        <p class="mbn">
                            <a href="">
                                <i class="fa fa-heart-o" aria-hidden="true"></i> HOME
                            </a>
                        </p>
                        <p class="mbn">
                            <a >
                                <i class="fa fa-heart-o" aria-hidden="true"></i>PATIENT INFO
                            </a> </p>
                        <p class="mbn">
                            <a href="#">
                                <i class="fa fa-heart-o" aria-hidden="true"></i> SERVICES
                            </a> </p>
                        <!-- <p class="mbn">
                            <a href="book-appointment">
                                <i class="fa fa-heart-o" aria-hidden="true"></i> BOOK APPOINTMENTS
                            </a> </p> -->
                        <!-- <p class="mbn">
                            <a href="blog">
                                <i class="fa fa-heart-o" aria-hidden="true"></i> MY BLOG
                            </a> </p> -->
                        <p class="mbn">
                            <a href="reach-us">
                                <i class="fa fa-heart-o" aria-hidden="true"></i> REACH US
                            </a> </p>
                    </div>

                </div>
                <!--<div class="col-xs-12 col-sm-6 col-md-3 widget">
                  <div class="widget-title">
                   <h3 class="title" style="font-weight:600;"> RECNT POSTS </h3>
                   <p style="height: 2px;background: #2b96cc;width: 60px;"></p>
                    </div>
                    <div class="pt10">
                    <div class="col-md-4">
                    <imgsrc="assets/front/img/gal.png" class="pr5"/>
                    </div>
                    <div class="col-md-8 pln pb15 lh16">
                    Oct 23 - 2016 <br/> The movie star the <br/> professor and here on
                    </div>
                    <div class="col-md-4">
                    <imgsrc="assets/front/img/gal.png" class="pr5"/>
                    </div>
                        <div class="col-md-8 pln pb15 lh16">
                    Nov 23 - 2016 <br/> The movie star the <br/> professor and here on
                    </div>
                    <div class="col-md-4">
                    <imgsrc="assets/front/img/gal.png" class="pr5"/>
                    </div>
                        <div class="col-md-8 pln pb15 lh16">
                    Dec 23-2016 <br/> The movie star the <br/> professor and here on
                    </div>
                    </div>
                </div>-->

            </div>
        </div>
        <!-- footer-top -->
        <div class="copyright">
            <div class="container">
                <div class="row">
                    <!-- Copyrights -->
                    <div class="col-xs-10 col-sm-6 col-md-6"style="padding-top:5px;"> &copy; Copyright 2016. GOKUL REDDY All Rights Reserved.
                    </div>
                    <div class="col-md-6 col-xs-2 col-sm-6 text-right page-scroll gray-bg icons-circle i-3x">
                        <ul class="footerMenu">
                            <li>Powered by :</li>
                            <li><a href="http://beeravolutech.com/" target="_blank">Beeravolu Technologies</a></li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- footer-bottom -->

        <!-- footer -->
    </div>
</footer>
<!-- page -->
<!-- Scripts -->

<!-- Menu jQuery plugin -->

<script type="text/javascript"src="/../../assetsassets/front/js/hover-dropdown-menu.js"></script>
<!-- Menu jQuery Bootstrap Addon -->
<script type="text/javascript"src="/../../assetsassets/front/js/jquery.hover-dropdown-menu-addon.js"></script>
<!-- Scroll Top Menu -->

<script type="text/javascript"src="/../../assetsassets/front/js/jquery.easing.1.3.js"></script>
<!-- Sticky Menu -->
<script type="text/javascript"src="/../../assetsassets/front/js/jquery.sticky.js"></script>
<!-- Bootstrap Validation -->

<script type="text/javascript"src="/../../assetsassets/front/js/bootstrapValidator.min.js"></script>
<!-- Revolution Slider -->

<script type="text/javascript" src="/../../assetsassets/front/rs-plugin/js/jquery.themepunch.tools.min.js"></script>
<script type="text/javascript" src="/../../assetsassets/front/rs-plugin/js/jquery.themepunch.revolution.min.js"></script>
<script type="text/javascript"src="/../../assetsassets/front/js/revolution-custom.js"></script>
<!-- Portfolio Filter -->

<script type="text/javascript"src="/../../assetsassets/front/js/jquery.mixitup.min.js"></script>
<!-- Animations -->

<script type="text/javascript"src="/../../assetsassets/front/js/jquery.appear.js"></script>
<script type="text/javascript"src="/../../assetsassets/front/js/effect.js"></script>
<!-- Owl Carousel Slider -->

<script type="text/javascript"src="/../../assetsassets/front/js/owl.carousel.min.js"></script>
<!-- Pretty Photo Popup -->

<script type="text/javascript"src="/../../assetsassets/front/js/jquery.prettyPhoto.js"></script>
<!-- Parallax BG -->

<script type="text/javascript"src="/../../assetsassets/front/js/jquery.parallax-1.1.3.js"></script>
<!-- Fun Factor / Counter -->

<script type="text/javascript"src="/../../assetsassets/front/js/jquery.countTo.js"></script>


<script type="text/javascript" src="/../../assetsassets/front/js/jquery.mb.YTPlayer.js"></script>
<!-- Custom Js Code -->

