<app-header></app-header>

<nav class="navbar navbar-inverse">
    <div>
        <div style="position:relative;">
            <div>
                <div class="collapse navbar-collapse" id="myNavbar" style="float:left;padding:0px;clear:both;width:100%;background:#2d3e50;">
                    <div>
                        <ul class="nav navbar-nav ag-menu sm pl40" style="float:left;" data-smartmenus-id="14811307330159312">
                            <li class=""><a href="">HOME</a>
                            </li>
                            <li class="{{openlist}}"  (mouseover)='over()' (mouseout)='out()'><a href="" class="has-submenu">MYPRACTICE  </a>
                                <ul class="dropdown-menu sm-nowrap" >
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Dr Gokul reddy</a>
                                                    </div>
                                                    <div>
                                                        <a class="" routerLink="/practice-locations"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>Practice Locations</a>
                                                    </div>
                                                    <!-- <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Book  appointment</a>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                            </li>
                            <li class="{{openlist1}}" (mouseover)='over1()' (mouseout)='out1()'><a class="has-submenu" href="#" >PATIENT INFO </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/about-cholesterol"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>ABOUT CHOLESTEROL</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/angioplasty"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> ANGIOPLASTY</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/chestpain"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CHESTPAIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/common-misconceptions"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>COMMON MISCONCEPTIONS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/coronary-angiogram"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CORONARY ANGIOGRAM</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/exercsie-and-heart-health"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> EXERCSIE & HEART HEALTH</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/guide-to-taking-warfarin"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> GUIDE TO TAKING WARFARIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-attack"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTATTACK</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-fauilre"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTFAUILRE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/high-blood-pressure"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HIGH BLOOD PRESSURE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/htn-myths"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>HTN-MYTHS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/tips-to-lower"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>TIPS  TO LOWER CHOLESTEROL   LEVELS</a> </div>



                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li><a href="#">SERVICES</a></li>
                            <li><a href="#">APPOINTMENTS</a></li>
                            <li class=""><a  href="faq">FAQS </a></li>
                            <li class=""><a  href="blog">MY BLOG</a></li>

                            <li class=""> <a  href="reach-us">REACH US</a></li>

                            <div class="clearfix"></div>
                        </ul>
                        <div class="pull-right pn">
                            <a href="#"><img src="/../../assets/front/img/askdemo.png" style="height:52px;"/></a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</nav>


<section style="background:#fff;">

    <div class="container">

        <div  style="padding:30px 15px;">

            <div class="row">

                <h4 class="mb5" style="color: #0991cd;font-weight: bold;">FAQ</h4>



                <div class="panel-group list-style" id="accordion">

                    <div class="panel panel-default mix all">

                        <div class="panel-heading">

                            <div class="panel-title">

                                <a data-toggle="collapse" data-parent="#accordion" href="#faq1" style="color: #0991cd;">What causes cardiovascular disease?</a>

                            </div>

                        </div>

                        <div id="faq1" class="panel-collapse collapse in">

                            <div class="panel-body">

                                <p>There are many risk factors that contribute to the development of cardiovascular disease.  Some people are born with conditions that predispose them to heart disease and stroke, but most people who develop cardiovascular disease do so because of a combination of factors such as poor diet, lack of physical activity and smoking, to name just three.  The more risk factors you expose yourself to, the higher the chance of developing cardiovascular disease.</p>



                                <p>Many of the risk factors for cardiovascular disease cause problems because they lead to atherosclerosis.

                                </p>

                                <p>

                                    Atherosclerosis is the narrowing and thickening of arteries. Atherosclerosis develops for years without causing symptoms.  It can happen in any part of the body.  Around the heart, it is known as coronary artery disease, in the legs it is known as peripheral arterial disease.</p>

                                <p>

                                    The narrowing and thickening of the arteries is due to the deposition of fatty material, cholesterol and other substances in the walls of blood vessels. The deposits are known as plaques. The rupture of a plaque can lead to stroke or a heart attack.

                                </p>



                            </div>

                        </div>

                    </div>

                    <div class="panel panel-default mix panel-bg graphic-design all">

                        <div class="panel-heading">

                            <div class="panel-title">

                                <a data-toggle="collapse" data-parent="#accordion" href="#faq2" style="color: #0991cd;">What is cholesterol?</a>

                            </div>

                        </div>

                        <div id="faq2" class="panel-collapse collapse">

                            <div class="panel-body">

                                <p>Cholesterol is a waxy, fat-like substance used by the body to build cell walls and for making several essential hormones. Your liver produces cholesterol and you absorb it from the animal fats you eat.</p>



                                <p>Cholesterol is carried through the blood by particles called lipoproteins. There are two types: low-density lipoproteins (LDL) and high-density lipoproteins (HDL). The former carries the cholesterol around the body in the blood and the latter transports cholesterol out of the blood into the liver.

                                </p>

                                <p>

                                    When cholesterol is too high, or the levels of the two types are out of balance (dyslipidaemia), the cholesterol can clog the arteries affecting the flow of the blood.

                                </p>



                            </div>

                        </div>

                    </div>

                    <div class="panel panel-default mix panel-bg web-design all">

                        <div class="panel-heading">

                            <div class="panel-title">

                                <a data-toggle="collapse" data-parent="#accordion" href="#faq3" style="color: #0991cd;">What are triglycerides?</a>

                            </div>

                        </div>

                        <div id="faq3" class="panel-collapse collapse">

                            <div class="panel-body">

                                <p>Triglycerides are fats found in the blood that are important for muscle energy.  They travel through the blood in lipoproteins. As triglyceride levels rise, HDL cholesterol levels fall. High levels of of triglyceride increase the risk for heart disease. In rare cases, very high levels can lead to pancreatitis. Conditions that may cause high triglycerides include obesity, poorly controlled diabetes, drinking too much alcohol, hypothryroidism, and kidney disease.</p>

                            </div>

                        </div>

                    </div>

                    <div class="panel panel-default mix panel-bg ecommerce all">

                        <div class="panel-heading">

                            <div class="panel-title">

                                <a data-toggle="collapse" data-parent="#accordion" href="#faq4" style="color: #0991cd;">What is the connection between high blood pressure (hypertension) and heart disease?</a>

                            </div>

                        </div>

                        <div id="faq4" class="panel-collapse collapse">

                            <div class="panel-body">

                                <p>Blood moving through your arteries pushes against the arterial walls; this force is measured as blood pressure.</p>



                                <p>High blood pressure (hypertension) occurs when very small arteries (arterioles) tighten.  Your heart has to work harder to pump blood through the smaller space and the pressure inside the vessels grows.  The constant excess pressure on the artery walls weakens them making them more susceptible to atherosclerosis.</p>

                            </div>

                        </div>

                    </div>







                    <div class="panel panel-default mix panel-bg ecommerce all">

                        <div class="panel-heading">

                            <div class="panel-title">

                                <a data-toggle="collapse" data-parent="#accordion" href="#faq5" style="color: #0991cd;">How is coronary heart disease diagnosed?</a>

                            </div>

                        </div>

                        <div id="faq5" class="panel-collapse collapse">

                            <div class="panel-body">

                                <p>There are a number of ways to diagnose coronary heart disease. Your physician will probably use a number to make a definitive diagnosis. .</p>



                                <p>A coronary angiogram uses a dye inserted into your arteries and an x-ray to see how the blood flows through your heart. The picture taken, the angiogram, will show any atherosclerosis.</p>



                                <p>Another test is an electrocardiogram.  This test records the electrical activity of your heart.  An electrocardiogram measures the rate and regularity of heartbeats, the size and position of the heart chambers, the presence of any damage to the heart, and the effects of drugs or devices used to regulate the heart. It is a non-invasive procedure.</p>





                            </div>

                        </div>





                    </div>







                    <div class="panel panel-default mix panel-bg ecommerce all">

                        <div class="panel-heading">

                            <div class="panel-title">

                                <a data-toggle="collapse" data-parent="#accordion" href="#faq6" style="color: #0991cd;">How are smoking and heart disease linked?</a>

                            </div>

                        </div>

                        <div id="faq6" class="panel-collapse collapse">

                            <div class="panel-body">

                                <p>Smoking damages the lining of blood vessels, increases fatty deposits in the arteries, increases blood clotting, adversely affects blood lipid levels, and promotes coronary artery spasm. Nicotine accelerates the heart rate and raises blood pressure. </p>





                            </div>

                        </div>





                    </div>







                    <div class="panel panel-default mix panel-bg ecommerce all">

                        <div class="panel-heading">

                            <div class="panel-title">

                                <a data-toggle="collapse" data-parent="#accordion" href="#faq7" style="color: #0991cd;">Does diet play a part in the development of heart disease?</a>

                            </div>

                        </div>

                        <div id="faq7" class="panel-collapse collapse">

                            <div class="panel-body">

                                <p>Diet plays a significant role in protecting or predisposing people to heart disease. Diets high in animal fat, low in fresh vegetables and fruit, and high in alcohol have been shown to increase the risk of heart disease. </p>



                                <p>

                                    Adopting a diet low in fat and salt has a protective effect over the long term. This means whole grains, fruits, and vegetables.

                                </p>





                            </div>

                        </div>





                    </div>





                    <div class="panel panel-default mix panel-bg ecommerce all">

                        <div class="panel-heading">

                            <div class="panel-title">

                                <a data-toggle="collapse" data-parent="#accordion" href="#faq8" style="color: #0991cd;">Aren’t women protected from heart disease because of estrogen?</a>

                            </div>

                        </div>

                        <div id="faq8" class="panel-collapse collapse">

                            <div class="panel-body">

                                <p>Estrogen does help raise good HDL cholesterol so protecting women, but once through the menopause as many women as men are affected by heart disease: but if a woman suffers from diabetes or has raised levels of triglycerides that cancels out the positive effect of estrogen.

                                </p>





                            </div>

                        </div>





                    </div>







                    <div class="panel panel-default mix panel-bg ecommerce all">

                        <div class="panel-heading">

                            <div class="panel-title">

                                <a data-toggle="collapse" data-parent="#accordion" href="#faq9" style="color: #0991cd;">How do the symptoms of heart attack differ between men and women?</a>

                            </div>

                        </div>

                        <div id="faq9" class="panel-collapse collapse">

                            <div class="panel-body">

                                <p>The symptoms of heart attack in a man are intense chest pain, pain in the left arm or jaw and difficulty breathing.

                                </p>

                                <p>



                                    A woman may have some of the same symptoms, but her pain may be more diffuse, spreading to the shoulders, neck, arms, abdomen and even her back. A woman may experience pain more like indigestion. The pain may not be consistent. There may not be pain but unexplained anxiety, nausea, dizziness, palpitations and cold sweat. A woman’s heart attack may have been preceded by unexplained fatigue.

                                </p>

                                <p>

                                    Women also tend to have more severe first heart attacks that more frequently lead to death, compared to men.



                                </p>





                            </div>

                        </div>





                    </div>





                    <div class="panel panel-default mix panel-bg ecommerce all">

                        <div class="panel-heading">

                            <div class="panel-title">

                                <a data-toggle="collapse" data-parent="#accordion" href="#faq10" style="color: #0991cd;">Is heart disease hereditary?</a> </div>  </div>

                        <div id="faq10" class="panel-collapse collapse">

                            <div class="panel-body">

                                <p>Heart disease can run in some families. But even if you inherit the risks factors that predispose you to heart disease, such as high blood cholesterol, high blood pressure, diabetes, or being overweight, there are measures you can take that will help you avoid developing cardiovascular disease.

                                </p>

                            </div> </div>  </div>









                    <div class="panel panel-default mix panel-bg ecommerce all">

                        <div class="panel-heading">

                            <div class="panel-title">

                                <a data-toggle="collapse" data-parent="#accordion" href="#faq11" style="color: #0991cd;">Many people take  Dicofenac or  ibuprofen or similar pain Killers , daily for joint issues, such as arthritis.   How serious is this risk?</a> </div>  </div>

                        <div id="faq11" class="panel-collapse collapse">

                            <div class="panel-body">

                                <p>A: Ibuprofen, Diclofenac or similar NSAID(Pain Killers)  can cause marked worsening of existing hypertension (high blood pressure) or development of new high blood pressure. It can also cause damage to the kidneys (nephrotoxicity), worsening of heart failure, and even heart attack or stroke. Outside of the cardiovascular system, its major risk is to the stomach, where it can cause ulceration and gastrointestinal bleeding.</p>

                                <p>The bottom line is that for short-term occasional use, these drugs are probably safe for most of us, but personally I rarely use them. If I have patients who really need them, I make sure that they check their blood pressure regularly, take their daily baby aspirin and try to get off them as soon as possible.</p>

                                <p>Heart-friendly medicines for joint issues or arthritis that I do use personally and recommend : </p>



                                <ul class="list-style"><li>	Paracetamol  (DOLO or CROCIN or any other brand) — One tablet two to three times daily </li>

                                </ul>



                            </div> </div>  </div>







                    <div class="panel panel-default mix panel-bg ecommerce all">

                        <div class="panel-heading">

                            <div class="panel-title">

                                <a data-toggle="collapse" data-parent="#accordion" href="#faq12" style="color: #0991cd;">Risk factors of heart disease :</a> </div>  </div>

                        <div id="faq12" class="panel-collapse collapse">

                            <div class="panel-body">





                                <ul class="list_1"><li>	<b>Age.</b> Simply getting older increases your risk of damaged and narrowed arteries and weakened or thickened heart muscle, which contribute to heart disease. </li>



                                    <li><b>Gender.</b> Men are generally at greater risk of heart disease. However, the risk for a woman increases after menopause.</li>



                                    <li><b>Family history. </b>A family history of heart disease increases your risk of coronary artery disease, especially if a parent developed it at an early age (before age 55 for a male relative, such as your brother or father, and 65 for a female relative, such as your mother or sister).</li>



                                    <li><b>Smoking. </b>Nicotine constricts your blood vessels, and carbon monoxide can damage their inner lining, making them more susceptible to atherosclerosis. Heart attacks are more common in smokers than in nonsmokers.</li>



                                    <li><b>Poor diet: </b>A diet that’s high in fat, salt and cholesterol can contribute to the development of heart disease.</li>



                                    <li><b>High blood pressure:</b> Uncontrolled high blood pressure can result in hardening and thickening of your arteries, narrowing the vessels through which blood flows.</li>



                                    <li><b>High blood cholesterol levels:</b> High levels of cholesterol in your blood can increase the risk of formation of plaques and atherosclerosis. Plaques can be caused by a high level of low-density lipoprotein (LDL) cholesterol, known as “bad” cholesterol, or a low level of high-density lipoprotein (HDL) cholesterol, known as “good” cholesterol.</li>



                                    <li><b>Diabetes:</b> Diabetes increases your risk of heart disease. Both conditions share similar risk factors, such as obesity and high blood pressure.</li>



                                    <li><b>Obesity:</b> Excess weight typically worsens other risk factors.</li>



                                    <li><b>Physical inactivity:</b> Lack of exercise also is associated with many forms of heart disease and some of its other risk factors, as well.</li>

                                    <li><b>High stress:</b> Unrelieved stress in your life may damage your arteries as well as worsen other risk factors for heart disease.</li>

                                </ul>



                            </div> </div>  </div>













                </div>

            </div>

        </div>

    </div>

   
</section>
<app-footer></app-footer>
