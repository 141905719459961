<!DOCTYPE html>
<html lang="en">

<head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <meta name="generator" content="">

    <title> GOKUL CLINIC - Dashboard page </title>
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="keywords" content="">
    <meta name="description" content="">
    <meta name="author" content="">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="shortcut icon" href="http://drgokulreddy.in/img/favicon.ico">
    <link rel="shortcut icon" href="http://drgokulreddy.in/assets/img/favicon.ico" />
    <!-- Font -->
    <link rel='stylesheet' href='http://fonts.googleapis.com/css?family=Arimo:300,400,700,400italic,700italic' />
    <link href='http://fonts.googleapis.com/css?family=Oswald:400,300,700' rel='stylesheet' type='text/css' />
    <!-- Font Awesome Icons -->
    <link href='http://drgokulreddy.in/assets/css/font-awesome.min.css' rel='stylesheet' type='text/css' />
    <!-- Bootstrap core CSS -->
    <link href="http://drgokulreddy.in/assets/css/bootstrap.min.css" rel="stylesheet" />
    <!-- Custom Style -->
    <link href="http://drgokulreddy.in/assets/css/style.css" rel="stylesheet" />
    <link href="http://drgokulreddy.in/assets/css/responsive.css" rel="stylesheet" />
    <!-- Color Scheme -->
    <link href="http://drgokulreddy.in/assets/css/color.css" rel="stylesheet" />
    <link href="http://drgokulreddy.in/assets/css/custom.css" rel="stylesheet" />
    <link href="http://drgokulreddy.in/assets/css/checkboxcss.css" rel="stylesheet" type="text/css">

    <link href="http://drgokulreddy.in/assets/css/hover-dropdown-menu.css" rel="stylesheet" />
    <link href="http://drgokulreddy.in/assets/css/icons.css" rel="stylesheet" />
    <!-- Revolution Slider -->
    <link href="http://drgokulreddy.in/assets/css/revolution-slider.css" rel="stylesheet" />
    <link href="http://drgokulreddy.in/assets/rs-plugin/css/settings.css" rel="stylesheet" />
    <!-- Animations -->
    <link href="http://drgokulreddy.in/assets/css/animate.min.css" rel="stylesheet" />
    <link href="http://drgokulreddy.in/assets/css/hover-dropdown-menu.css" rel="stylesheet" />
    <link href="http://drgokulreddy.in/assets/css/icons.css" rel="stylesheet" />
    <!-- Revolution Slider -->
    <link href="http://drgokulreddy.in/assets/css/revolution-slider.css" rel="stylesheet" />
    <link href="http://drgokulreddy.in/assets/rs-plugin/css/settings.css" rel="stylesheet" />
    <!-- Animations -->
    <link href="http://drgokulreddy.in/assets/css/animate.min.css" rel="stylesheet" />
    <link rel="stylesheet" href="http://drgokulreddy.in/assets/css/tabs.css">
    <link rel="stylesheet" href="https://cdn.datatables.net/1.10.12/css/dataTables.bootstrap.min.css">
    <script type="text/javascript" src="http://drgokulreddy.in/assets/js/jquery.min.js"></script>
    <!-- Owl Carousel Slider -->
    <link href="http://drgokulreddy.in/assets/css/owl//owl.carousel.css" rel="stylesheet">
    <link href="http://drgokulreddy.in/assets/css/owl//owl.theme.css" rel="stylesheet">
    <link href="http://drgokulreddy.in/assets/css/owl//owl.transitions.css" rel="stylesheet">

    <script type="text/javascript" src="http://drgokulreddy.in/assets/js/jquery.min.js"></script>
    <script type="text/javascript" src="http://drgokulreddy.in/assets/js/bootstrap.min.js"></script>


    <style>
        .error {
            color: red;
        }
    </style>
</head>

<body data-gr-c-s-loaded="true">
    <div id="page" class="side-nav-wrapper">
        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pull-right" style="margin-right: 10px;">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                                style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                                <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                                <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                    userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                        class="">Settings</a></li>
                                <li
                                    *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                    <a [routerLink]="" (click)="users()" class="">Users</a></li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>


        <div class="container bg-gray" style="    min-height: 600px;">
            <div class="row">
                <div class="col-md-12">
                    <h5 class="dr-sub-head"><b> DASHBOARD </b></h5>
                </div>
            </div>
            <div class="row orr-usercreation ptop30 pbtom30">

            </div>

        </div>
    </div>
    <header class="simple-header bg-dark">
        <!-- navbar -->
        <div class="navbar side-navbar navbar-default navbar-bg-light" role="navigation">
            <div class="navbar-header ">
                <!-- Button For Responsive toggle -->
                <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span></button>
                <!-- Logo -->
                <div class="vtop-logo">
                    <a class="" href="http://drgokulreddy.in/index.html">
                        <img class="site_logo" alt="Site Logo" src="../assets/images/logo.png">
                    </a>
                </div>
            </div>
            <div class="profile-main">
                <p class="pull-left"> <img src="../assets/images/profile.png"> </p>
                <div class="dr-profile">
                    <h5> Dr. Gokul Reddy </h5>
                    <h6> Online </h6>
                </div>
            </div>
            <!-- Navbar Collapse -->
            <div class="navbar-collapse collapse">
                <!-- nav -->
                <ul class="nav navbar-nav dr-icons">
                    <li>
                        <a [routerLink]="" (click)="newRegistartion()" class="">
                            <img src="../assets/images/icon-2.png" class="pr5"> Walkin's and billing</a>
                    </li>
                    <li *ngIf="userDetails.Roles[0].Role != 'RECEPTIONIST'">
                        <a [routerLink]="" (click)="todaysAppointments()" class="">
                            <img src="../assets/images/icon-2.png" class="pr5">Appointments</a>
                    </li>
                    <li>
                        <a [routerLink]="" (click)="patients()" class="">
                            <img src="../assets/images/icon-2.png" class="pr5">Patients</a>
                    </li>
                    <li>
                        <a [routerLink]="" (click)="patients()" class="">
                            <img src="../assets/images/icon-2.png" class="pr5">Patients By Conditions</a>
                    </li>
                    <li>
                        <a [routerLink]="" (click)="Followupappointments()" class="">
                            <img src="../assets/images/icon-2.png" class="pr5"> follow up Appointments</a>
                    </li>

                    <li>
                        <a [routerLink]="" (click)="Payments()" class="">
                            <img src="../assets/images/icon-9.png" class="pr5"> Receipts</a>
                    </li>
                    <li>
                        <a [routerLink]="" (click)="Revenue()" class="">
                            <img src="../assets/images/icon-3.png" class="pr5"> Revenue</a>
                    </li>
                    <li>
                        <a [routerLink]="" (click)="Allechoreports()" class="">
                            <img src="../assets/images/icon-9.png" class="pr5"> Diagnostic Report</a>
                    </li>
                    <!-- <li>
                        <a [routerLink]="" (click)="dashboard()" class="active">
                            <img src="../assets/images/icon-1.png" class="pr5"> Dashboard</a>

                    </li> -->
                    <!-- <li>
                        <a [routerLink]="" (click)="patients()" class="">
                            <img src="../assets/images/icon-7.png" class="pr5"> Patients</a>

                    </li> -->




                </ul>


                <!-- Right nav -->
            </div>
            <!-- /.navbar-collapse -->
        </div>
        <!-- navbar -->

    </header>
</body>

</html>
<ngx-spinner></ngx-spinner>