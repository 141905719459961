import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-print2-decho-report',
  templateUrl: './print-2d-echo-report.component.html',
  styleUrls: ['./print-2d-echo-report.component.css']
})
export class Print2DEchoReportComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private apicalls: ApicallsService, private router: ActivatedRoute,
    private route: Router, private httpClient: HttpClient, private formBuilder: FormBuilder) { }

  userDetails;
  twoDechoReportDetails;
  PatientDetiails;
  MRNumber
  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data
    this.router.paramMap.subscribe(params => {
      const ReportID = params.get('ReportID');
      const MRNumber = params.get('MRNumber');

      this.apicalls.viewPatientTwoDEchoReports(ReportID).subscribe(
        data => {
          this.spinner.show();
          if (data.type == true) {
            this.twoDechoReportDetails = data
            // this.MRNumber = this.twoDechoReportDetails.MRNumber;
            // console.log(" MRNumber ", this.MRNumber, " ", this.twoDechoReportDetails.MRNumber)
            setTimeout(() => {
              /** spinner ends after 5 seconds */
              this.spinner.hide();
            }, 500);

          } else if (data.code == 'S002') {
            alert(data.data);           
            return;
          } else if (data.code == "PD01") {
            alert(data.data);
            return;
          }else if(data.code == "UA01"){
            alert(data.data);
            return;
          }
          else {
            alert(data.message);
            this.spinner.hide();
          }
        }, error => {
          alert(" Internal Server Error ")
        });

      this.apicalls.viewPatientsById(MRNumber).subscribe(
        data => {
          if (data.type == true) {
            if(data.code == "S001"){
              this.PatientDetiails = data;
              console.log(" PatientDetiails ", this.PatientDetiails)

              setTimeout(() => {
                this.spinner.hide();
              });
            }else if(data.code =="S002"){
              alert(data.data);
              return;
            }else if(data.code== "UA01"){
              alert(data.data);
              return;
            }
            
          } else {
            alert(data.message);
            this.spinner.hide();
          }
        }, error => {
          alert(" Internal Server Error ")
        });


    });


  }


  printPage(comp) {
    // let printContents = document.getElementById(comp).innerHTML;
    // let originalContents = document.body.innerHTML;
    // document.body.innerHTML = printContents;
    var originalTitle = document.title;
    var url = document.URL;
  //  console.log(url)
    var curURL = window.location.href;
history.replaceState(history.state, '', '/');
document.title = "Print";
window.print();
history.replaceState(history.state, '', curURL);
//console
   // document.URL =="Print"
//window.print();

   // window.print();
    // document.body.innerHTML = originalContents;
  }


  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }
}
