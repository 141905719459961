import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-createprivileges',
  templateUrl: './createprivileges.component.html',
  styleUrls: ['./createprivileges.component.css']
})
export class CreateprivilegesComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private httpclient: HttpClient, private apicalls: ApicallsService, private route: Router, private router: ActivatedRoute) { }

  AllResources;
  Privileges = [];
  userDetails
  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data

    this.apicalls.getAllResources()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {
            this.AllResources = data.data;
            console.log("privileges...........", this.AllResources);
            this.spinner.hide();
            for (const [key, value] of Object.entries(this.AllResources)) {
              this.Privileges.push(value);
            }
            console.log(" $$%$%$$% ", this.Privileges)

          }
          if (data.code == 'V002') {
            alert(data.data);
            this.spinner.hide();
          }

        } else {
          this.spinner.hide();
          if (data.code == 'PD01') {
            alert(data.data)
            this.route.navigate(["/dashboardmain/dashboard"]);
          }
          else {
            alert(data.data)
          }
        }
      }, error => { alert(" Internal Server Error "); });
  }
  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }

  data: '';
  pathurl: string = 'GokulClinic';
  isSubmitted: boolean = true;
  //loggedIn:boolean=true;
  loginError: string;
  error = false;

  onSubmit(privilegeForm) {
    if (this.privilegeForm.invalid) {
      this.error = true;
      return;
    }
    let result = this.apicalls.createPrivileges(this.privilegeForm.value)
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          this.spinner.hide();
          alert(data.data)
          this.route.navigate(["/dashboardmain/privileges"]);
        }
        else {
          this.spinner.hide();
          alert(data.data)
        }
      }, error => { alert(" Internal Server Error "); });

  }


  get Privilege() {
    return this.privilegeForm.get('Privilege');
  }



  privilegeForm = new FormGroup({
    Privilege: new FormControl('', [Validators.required])

  });


  Settings(){
    this.route.navigate(["/dashboardmain/configurations"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }


}
