import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApicallsService } from '../../../services/apicalls.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-viewallroles',
  templateUrl: './viewallroles.component.html',
  styleUrls: ['./viewallroles.component.css']
})
export class ViewallrolesComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private apicalls: ApicallsService, private router: ActivatedRoute, private route: Router, private httpClient: HttpClient) { }

  viewallroles;
  Roles = [];
  userDetails
  ngOnInit() {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data

    this.apicalls.viewAllRoles()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {


          setTimeout(() => {

            this.spinner.hide();
          }, 500);
          this.viewallroles = data;
          //console.log("Rolesssssssssss", data.data);
          //  this.dtTrigger.next();
          // for (var i = 0; i < data.data.length; i++) {
          //   if (data.data[i].Status == "Active") {
          //     this.Roles.push(data.data[i]);
          //   }
          // }
        } else {

          //  this.alertService.info(data.message)
          // this.spinner.hide();
          this.spinner.hide();

          if (data.code == 'PD01') {
            alert(data.data)
            this.route.navigate(["/dashboardmain/dashboard"]);

          }
          else {
            alert(data.data)
          }

        }
      }, error => {
        error => { alert(" Internal Server Error "); }
        // this.alertService.warning(" Internal Server Error ");
      });

  }

  editrole(RoleID) {
    this.route.navigate(["dashboardmain/updaterole", RoleID]);
  }


  onClickCreate() {
    this.route.navigate(["/dashboardmain/createrole"]);
  }

  // dashboard(){
  //   this.route.navigate(["/dashboard"]);
  // }

  // Resources(){
  //   this.route.navigate(["/viewallresources"]);
  // }

  // Roles(){
  //   this.route.navigate(["/viewallroles"]);
  // }

  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }
}
