<!DOCTYPE html>
<html lang="en">

<body>
    <div id="page" class="side-nav-wrapper">
        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pull-right" style="margin-right: 10px;">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                                style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                                <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                                <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                    userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                        class="">Settings</a></li>
                                <li
                                    *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                    <a [routerLink]="" (click)="users()" class="">Users</a></li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>


        <div class="container bg-gray">
            <div class="row">
                <div class="col-md-4">
                    <h5 class="dr-sub-head">Payments</h5>
                </div>
            </div>
            <!-- <div class="col-md-4"> -->
            <!-- <div class="btn-signin" style="width: 200px;">
                <a [routerLink]="" (click)="onClickCreate()">
                    Create Payment</a>
            </div> -->
            <div class="row orr-usercreation ptop30 pbtom30">

                <div class="col-md-12">



                    <div class="panel">
                        <!-- <div style="text-align: center; color:#367fa9;">
                                    <h4 style=" font-weight: bold;">
                                        Payment Receipts
                                    </h4>
                                    
        
                                    <div class="col-md-4" style="float: right" >
                                            <div class="form-group" >
                                                    
                                                <input type="text" class="form-control" placeholder="Enter text here to search " [(ngModel)]="term">
                                            </div>
                                            </div>
                                </div> -->
                        <div class="row" style="padding-left: 20px;">
                            <div class="col-md-6" style="padding-right: 150px;">
                                <label>Search with Patient's Name, MRNumber or PaymentID</label>
                            </div>
                            <div class="col-md-6" style="padding-left: 200px; padding-right: 10px;">
                                <label>No of records per page</label>
                            </div>
                        </div>
                        <div class="row" style="padding-left: 20px;">
                            <div class="col-md-3">
                                <div class="input-text form-group has-feedback">
                                    <i class="fa fa-search"
                                        style="font-size:24px;padding-top: 9px;padding-right: 9px;"></i><input
                                        type="text" class="form-control" placeholder=" Enter text here to search "
                                        [(ngModel)]="term">
                                </div>
                            </div>

                            <div class="col-md-3" style="margin-left: 500px;margin-right: 0px;">
                                <div class="form-group">
                                    <select class="way-custom-dropdown price-text-field profile-mwidth form-control"
                                        id="limit" name="limit" style="width: 50px;" #Selectedlimit
                                        (change)='onLimit(Selectedlimit.value)'>
                                        <option selected value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                        </div>


                        <table cellspacing="0" cellpadding="0" width="100%" class="mirchi-birthday-grid" id="example">
                            <thead>
                                <tr>
                                    <th style="text-align:center;"> S.No</th>
                                    <th style="text-align:center;"> Payment ID</th>
                                    <th style="text-align:center;"> Patient MRNumber</th>
                                    <th style="text-align:center;"> Patient Name</th>

                                    <th style="text-align:center;"> Payment Status</th>
                                    
                                    <th style="text-align:center;"> Payment Date</th>

                                    <!-- <th style="text-align:center;"> Type</th> -->

                                    <th style="text-align:center;"> Total Amount</th>


                                    <th style="text-align:center;"> Print</th>
                                    <th style="text-align:center;"> Delete</th>

                                </tr>
                            </thead>

                            <tbody>
                                <!-- <tr *ngFor="let list of Payments | filter:term; let i=index "> -->
                                <tr *ngFor="let list of collection | paginate: config|filter:term; let i=index ;">

                                    <td style="text-align:center;">{{i + 1}}</td>

                                    <td style="text-align:center;">{{list.PaymentID}}</td>
                                    <td style="text-align:center;">{{list.MRNumber}}</td>
                                    <td style="text-align:center;">{{list.PatientDetails.Name}}</td>
                                    <td style="text-align:center;">{{list.PaymentStatus}}</td>
                                    <!-- <td style="text-align:center;"> {{list.Type}}</td> -->
                                    <td style="text-align:center;"> {{list.CreatedDate *1000 | date: "dd-MM-yyyy"}}</td>

                                    <td style="text-align:center;">{{list.TotalAmount}}</td>
                                    <td>

                                        <a [routerLink]="" (click)="editdata(list.PaymentID)"><img
                                                src="../assets/images/print-icon.png"></a>
                                    </td>

                                    <td >

                                        <a [routerLink]="" (click)="delete(list.PaymentID)" *ngIf="list.PaymentStatus =='Paid'" ><img
                                                src="../assets/images/delete.png" style="width: 25px;height:25px"></a>
                                    </td>

                                </tr>
                            </tbody>
                        </table>

                        <pagination-controls (pageChange)="pageChange($event)" class="my-pagination">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>

</html>
<ngx-spinner></ngx-spinner>