<body data-gr-c-s-loaded="true">
    <div id="page" class="side-nav-wrapper">

        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pull-right" style="margin-right: 10px;">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                                style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                                <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                                <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                    userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                        class="">Settings</a></li>
                                <li
                                    *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                    <a [routerLink]="" (click)="users()" class="">Users</a></li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="container bg-gray">
            <div class="row">
                <div class="col-md-12">
                    <h5 class="dr-sub-head">Create User</h5>
                </div>
            </div>

        </div>


        <div class="row orr-usercreation ptop30 pbtom30">

            <div class="col-md-12">
                <!-- <h5 >Create Hospital</h5> -->
                <div class="panel">
                    <h5>Create User</h5>
                    <!-- <h5 style="margin-left:60px;text-align:left;padding-left:15px;margin:0px 0;padding:0px 0; ">Create Hospital</h5> -->


                    <div class="tab-table-customer-entry"
                        style="margin:30px 0;background:#fff;padding:10px 0;border-top:1px solid #ddd;border-bottom:1px solid #ddd;">

                        <form id="contact-form" class="contact-form pad-40" autocomplete="off" [formGroup]="userForm">
                            <div class="form-pad-label" style="padding-top:30px;">

                                <div class="col-md-6">
                                    <div class="input-text form-group has-feedback">
                                        <label style="width:180px;float:left;text-align:right;padding-right:15px;">First
                                            Name<span class="required"> * </span>
                                        </label>
                                        <input
                                            [class.is-invalid]="FirstName.invalid && (FirstName.dirty || FirstName.touched)"
                                            class="form-control" id="FirstName" type="FirstName" name="FirstName"
                                            placeholder="Enter FirstName..." formControlName="FirstName" required>
                                        <!-- <div *ngIf="UserID.invalid  && (UserID.dirty || UserID.touched)"> -->
                                        <small *ngIf="error &&  FirstName.errors?.required" class="text-danger">First
                                            Name is required</small>
                                        <small *ngIf="FirstName.errors?.minlength" class="text-danger">First Name
                                            should be minimum 3 characters
                                        </small>
                                        <small *ngIf="FirstName.errors?.pattern" class="text-danger">First Name
                                            should be alphabets
                                        </small>

                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="input-text form-group has-feedback">
                                        <label style="width:180px;float:left;text-align:right;padding-right:15px;">Last
                                            Name<span class="required"> * </span>
                                        </label>
                                        <input
                                            [class.is-invalid]="LastName.invalid && (LastName.dirty || LastName.touched)"
                                            class="form-control" id="LastName" type="LastName" name="LastName"
                                            placeholder="Enter Last Name..." formControlName="LastName" required>
                                        <!-- <div *ngIf="UserID.invalid  && (UserID.dirty || UserID.touched)"> -->
                                        <small *ngIf="error &&  LastName.errors?.required" class="text-danger">Last
                                            Name is required</small>
                                        <small *ngIf="LastName.errors?.minlength" class="text-danger">Last Name should
                                            be
                                            minimum 3 characters
                                        </small>
                                        <small *ngIf="LastName.errors?.pattern" class="text-danger">Last Name should be
                                            alphabets
                                        </small>

                                    </div>
                                </div>
                                <div style="clear:both;"></div>
                                <div class="col-md-6">
                                    <div class="input-text form-group has-feedback">
                                        <label style="width:180px;float:left;text-align:right;padding-right:15px;">Age
                                            <span class="required"> * </span>
                                        </label>
                                        <input [class.is-invalid]="Age.invalid && (LastName.dirty || Age.touched)"
                                            class="form-control" id="LastName" type="Age" name="Age"
                                            placeholder="Enter Age..." formControlName="Age" required>
                                        <!-- <div *ngIf="UserID.invalid  && (UserID.dirty || UserID.touched)"> -->
                                        <small *ngIf="error && Age.errors?.required" class="text-danger">Age is
                                            required</small>
                                        <small *ngIf="Age.errors?.pattern" class="text-danger">Age must be two
                                            digits </small>

                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="input-text form-group has-feedback">
                                        <label
                                            style="width:180px;float:left;text-align:right;padding-right:15px;">Contact
                                            Number
                                            <span class="required"> * </span>
                                        </label>
                                        <input
                                            [class.is-invalid]="ContactNumber.invalid && (ContactNumber.dirty || ContactNumber.touched)"
                                            class="form-control" id="ContactNumber" type="ContactNumber"
                                            name="ContactNumber" placeholder="Enter Contact Number..."
                                            formControlName="ContactNumber" required>
                                        <!-- <div *ngIf="UserID.invalid  && (UserID.dirty || UserID.touched)"> -->
                                        <small *ngIf="error &&  ContactNumber.errors?.required"
                                            class="text-danger">Contact Number is required</small>
                                        <small *ngIf="ContactNumber.errors?.pattern" class="text-danger">Enter valid
                                            Contact Number</small>
                                        <small *ngIf="ContactNumber.errors?.minlength" class="text-danger">Contact
                                            Number should be 10 digits</small>
                                        <small *ngIf="ContactNumber.errors?.maxlength" class="text-danger">Contact
                                            Number should be 10 digits</small>
                                    </div>
                                </div>
                                <div style="clear:both;"></div>
                                <div class="col-md-6">
                                    <div class="input-text form-group has-feedback">
                                        <label style="width:180px;float:left;text-align:right;padding-right:15px;">Enter
                                            EmailID<span class="required"> * </span>
                                        </label>
                                        <input
                                            [class.is-invalid]="EmailID.invalid && (EmailID.dirty || EmailID.touched)"
                                            class="form-control" id="EmailID" type="EmailID" name="EmailID"
                                            placeholder="Enter EmailID..." formControlName="EmailID" required>
                                        <!-- <div *ngIf="UserID.invalid  && (UserID.dirty || UserID.touched)"> -->
                                        <small *ngIf="error &&  EmailID.errors?.required" class="text-danger">EmailID is
                                            required</small>
                                        <small *ngIf="EmailID.errors?.pattern" class="text-danger">Enter valid
                                            EmailID</small>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="input-text form-group has-feedback">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Select
                                                Role<span class="required">* </span></label>

                                            <select
                                                [class.is-invalid]="RoleID.invalid && (RoleID.dirty || RoleID.touched)"
                                                class="form-control" id="RoleID" type="RoleID" name="RoleID"
                                                formControlName="RoleID" style="width:95%;" required>

                                                <option value="" disabled>Select Role</option>
                                                <option *ngFor="let list of viewallroles; let i=index"
                                                    value="{{list.RoleID}}">{{list.Role}}</option>
                                            </select>
                                        </div>
                                        <div *ngIf="error && RoleID.errors?.required" class="text-danger"
                                            style="text-align: center;">
                                            <small>Role is
                                                required</small>
                                        </div>
                                    </div>
                                </div>

                                <div style="clear:both;"></div>

                                <div class="col-md-6">
                                    <div class="input-text form-group has-feedback">
                                        <label style="width:180px;float:left;text-align:right;padding-right:15px;">
                                            Address<span class="required"> * </span>
                                        </label>
                                        <input
                                            [class.is-invalid]="StreetAddress.invalid && (StreetAddress.dirty || StreetAddress.touched)"
                                            class="form-control" id="StreetAddress" type="StreetAddress"
                                            name="StreetAddress" placeholder="Enter  Address..."
                                            formControlName="StreetAddress" required>
                                        <!-- <div *ngIf="UserID.invalid  && (UserID.dirty || UserID.touched)"> -->
                                        <small *ngIf="error &&  StreetAddress.errors?.required" class="text-danger">
                                            Address is required</small>

                                    </div>
                                </div>
                                <div style="clear:both;"></div>
                                <div
                                    style="margin:auto;text-align:center;margin-bottom:10px;margin-top:30px;margin-bottom:30px;">
                                    <a [routerLink]="" (click)="onSubmit(userForm)"> <input type="submit"
                                            class="btn-pink" value="CREATE"></a>

                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</body>
<ngx-spinner></ngx-spinner>