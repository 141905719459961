import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApicallsService } from '../../services/apicalls.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-viewallprivileges',
  templateUrl: './viewallprivileges.component.html',
  styleUrls: ['./viewallprivileges.component.css']
})
export class ViewallprivilegesComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private apicalls: ApicallsService, private router: ActivatedRoute, private route: Router, private httpClient: HttpClient) { }

  viewallprivileges;

  userDetails
  ngOnInit() {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data
    
    this.apicalls.viewAllPrivileges()
      .subscribe(data => {
        this.spinner.show();
        console.log("RRRRRRRRRRRRRRRRr", data);
        if (data.type == true) {
          if (data.code == 'S001') {

            // setTimeout(() => {

            // this.spinner.hide();
            // }, 500);
            this.viewallprivileges = data;
            console.log("privileges...........", this.viewallprivileges);
            //  alert(data.data);
            this.spinner.hide();
            //  this.dtTrigger.next();
          }

          if (data.code == 'V002') {

            alert(data.data);
            this.spinner.hide();
            //  this.dtTrigger.next();
          }

        } else {
          // alert(data.data)
          //  this.alertService.info(data.message)
          // this.spinner.hide();
          this.spinner.hide();

          if (data.code == 'PD01') {
            alert(data.data)
            this.route.navigate(["/dashboardmain/dashboard"]);

          }
          else {
            alert(data.data)
          }

        }
      },
        error => {
          alert(" Internal Server Error ");
          // this.alertService.warning(" Internal Server Error ");
        });

  }

  // editrole(RoleID){
  //   this.route.navigate(["/updaterole",RoleID]);
  // }


  onClickCreate() {
    this.route.navigate(["/dashboardmain/createprivilege"]);
  }

  // dashboard() {
  //   this.route.navigate(["/dashboard"]);
  // }

  // Resources() {
  //   this.route.navigate(["/viewallresources"]);
  // }

  // Roles() {
  //   this.route.navigate(["/viewallroles"]);
  // }

  // changePassword() {
  //   this.route.navigate(["/changepassword"]);
  // }
  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }
  Settings(){
    this.route.navigate(["/dashboardmain/configurations"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
}
