<app-header></app-header>
<nav class="navbar navbar-inverse">
    <div>
        <div style="position:relative;">
            <div>
                <div class="collapse navbar-collapse" id="myNavbar" style="float:left;padding:0px;clear:both;width:100%;background:#2d3e50;">
                    <div>
                        <ul class="nav navbar-nav ag-menu sm pl40" style="float:left;" data-smartmenus-id="14811307330159312">
                            <li class=""><a href="">HOME</a>
                            </li>
                            <li class="{{openlist}}"  (mouseover)='over()' (mouseout)='out()'><a href="" class="has-submenu">MYPRACTICE  </a>
                                <ul class="dropdown-menu sm-nowrap" >
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Dr Gokul reddy</a>
                                                    </div>
                                                    <div>
                                                        <a class="" routerLink="/practice-locations"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>Practice Locations</a>
                                                    </div>
                                                    <!-- <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Book  appointment</a>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                            </li>
                            <li class="{{openlist1}}" (mouseover)='over1()' (mouseout)='out1()'><a class="has-submenu" href="#" >PATIENT INFO </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/about-cholesterol"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>ABOUT CHOLESTEROL</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/angioplasty"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> ANGIOPLASTY</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/chestpain"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CHESTPAIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/common-misconceptions"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>COMMON MISCONCEPTIONS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/coronary-angiogram"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CORONARY ANGIOGRAM</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/exercsie-and-heart-health"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> EXERCSIE & HEART HEALTH</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/guide-to-taking-warfarin"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> GUIDE TO TAKING WARFARIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-attack"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTATTACK</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-fauilre"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTFAUILRE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/high-blood-pressure"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HIGH BLOOD PRESSURE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/htn-myths"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>HTN-MYTHS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/tips-to-lower"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>TIPS  TO LOWER CHOLESTEROL   LEVELS</a> </div>



                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li><a href="#">SERVICES</a></li>
                            <li><a href="#">APPOINTMENTS</a></li>
                            <li class=""><a  href="faq">FAQS </a></li>
                            <li class=""><a  href="blog">MY BLOG</a></li>

                            <li class=""> <a  href="reach-us">REACH US</a></li>

                            <div class="clearfix"></div>
                        </ul>
                        <div class="pull-right pn">
                            <a href="#"><img src="/../../assets/front/img/askdemo.png" style="height:52px;"/></a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</nav>
<section style="background:#fff;">

    <div class="container">

        <div  style="padding:30px 15px;">

            <div class="row">

                <h4 class="mb5" style="color: #0991cd;font-weight: bold;">Angioplasty</h4>

                <!--<p class="pull-right"><img src="img/gokulreddy.png" style="height:350px;"/></p>-->





                <p>Coronary angioplasty also called percutaneous coronary intervention, is a procedure used to open blocked / clogged heart arteries. Angioplasty involves temporarily inserting and inflating a tiny balloon where your artery is clogged to help widen the artery.

                </p>



                <p>

                    Angioplasty is often combined with the permanent placement of a small wire mesh tube called a stent to help prop the artery open and decrease its chance of narrowing again. Some stents are coated with medication to help keep your artery open (drug-eluting stents), while others are not (bare-metal stents).

                </p>

                <p>

                    Angioplasty can improve symptoms of blocked arteries, such as chest pain and shortness of breath. Angioplasty can also be used during a heart attack to quickly open a blocked artery and reduce the amount of damage to your heart.

                </p>

                <p>

                    Angioplasty isn't for everyone. If the main artery that brings blood to the left side of your heart is narrow, if your heart muscle is weak or if you have multiple diseased blood vessels, then coronary artery bypass surgery may be a better option than angioplasty. In coronary artery bypass surgery, the blocked part of your artery is bypassed using a blood vessel from another part of your body.

                </p>





                <p>If you have diabetes and multiple blockages, your doctor may suggest coronary artery bypass surgery. The decision of angioplasty versus bypass surgery will depend on the extent of your heart disease and overall medical condition.</p>



                <p>Although angioplasty is a less invasive way to open clogged arteries than bypass surgery is, the procedure still carries some risks.</p>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">The most common angioplasty risks include:</h5>



                <ul class="list_1">

                    <li> <b>Re-narrowing of your artery (restenosis):</b> With angioplasty alone — without stent placement , restenosis happens in about 30 percent of cases. Stents were developed to reduce restenosis. Bare-metal stents reduce the chance of restenosis to about 15 -20 percent, and the use of drug-eluting stents reduces the risk to less than 3 to 10 percent.</li>





                    <li> <b>Blood clots:</b> Blood clots can form within stents even after the procedure. These clots can close the artery, causing a heart attack. It's important to take aspirin, clopidogrel or prasugrel that helps reduce the risk of blood clots exactly as prescribed to decrease the chance of clots forming in your stent.

                        Never discontinue these medications without discussing it with your doctor.</li>





                    <li><b>Bleeding:</b> You may have bleeding in your leg or arm where a catheter was inserted. Usually this simply results in a bruise, but sometimes serious bleeding occurs and may require a blood transfusion or surgical procedures.</li>



                </ul>







                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Other rare risks of angioplasty include:</h5>

                <ul class="list_1">

                    <li>

                        <b>Heart attack:</b> Though rare, you may have a heart attack during the procedure.

                    </li>

                    <li><b>Coronary artery damage:</b> Your coronary artery may be torn or ruptured (dissected) during the procedure. These complications may require emergency bypass surgery.</li>



                    <li><b>Kidney problems:</b> The dye used during angioplasty and stent placement can cause kidney damage, especially in people who already have kidney problems. If you're at increased risk, your doctor may take steps to try to protect your kidneys, such as limiting the amount of contrast dye and making sure that you're well-hydrated during the procedure.</li>



                    <li><b>Stroke:</b> During angioplasty, a stroke can occur if plaques break loose when the catheters are being threaded through the aorta. Blood clots also can form in catheters and travel to the brain if they break loose. A stroke is an extremely rare complication of coronary angioplasty, and blood thinners are used during the procedure to reduce the risk.</li>



                    <li><b>Abnormal heart rhythms:</b> During the procedure, the heart may beat too quickly or too slowly. These heart rhythm problems are usually short-lived, but sometimes medications or a temporary pacemaker is needed.</li>

                </ul>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">During the procedure</h5>



                <p>Your body isn't cut open except for a very small incision in the skin over a blood vessel in the leg, arm or wrist through which a small, thin tube (catheter) is threaded and the procedure performed. Angioplasty can take up to several hours, depending on the difficulty and number of blockages and whether any complications arise.</p>





                <p>Angioplasty is performed by a heart specialist (cardiologist) and a team of specialized cardiovascular nurses and technicians in a special operating room called a cardiac catheterization laboratory. This room is often called the cath lab.</p>







                <p>Angioplasty is commonly performed through an artery in your arm or wrist area (Radial artery). Less commonly, it may be done using an artery in your groin area ( Femoral Artery) . Before the procedure, the area is prepared with an antiseptic solution and a sterile sheet is placed over your body.</p>



                <p>A local anesthetic is injected to numb the area where the catheter will be inserted. Small electrode pads are placed on your chest to monitor your heart during the procedure.</p>

                <p>

                    General anesthesia isn't needed. You'll be sedated but awake during the procedure. You'll receive fluids, medications to relax you and blood-thinning medications (anticoagulants) through an IV catheter. Then, the procedure begins:</p>



                <ul class="list_1">

                    <li>After numbing the incision area, a small needle is used to access an artery in your leg or arm. A small cut is made in the skin.</li>

                    <li> Your doctor then threads a thin guidewire followed by a catheter into the artery until it reaches the blockage in your heart.</li>

                    <li> You might feel pressure in the area where the catheter is inserted, but you shouldn't feel sharp pain. Tell your doctor if you do. You also won't feel the catheter in your body.</li>

                    <li> A small amount of dye is injected through the catheter. This helps your doctor look at the blockage on X-ray images called angiograms.</li>

                    <li> A small balloon at the end of the catheter is inflated, widening the blocked artery. After the artery is stretched, the balloon is deflated and removed. Your doctor might inflate and deflate the balloon several times before it's removed, stretching the artery a bit more each time.</li>

                </ul>



                <p>If you have several blockages, the procedure may be repeated at each blockage. Because the balloon temporarily blocks blood flow to part of your heart, it's not uncommon to experience chest pain while it's inflated.</p>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;"> Stent placement</h5>



                <p>Most people who have angioplasty also have a stent placed in their blocked artery during the same procedure. The stent is usually inserted in the artery after it's widened by the inflated balloon.

                    The stent supports the walls of your artery to help prevent it from re-narrowing after the angioplasty. The stent looks like a tiny coil of wire mesh.</p>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Coronary artery stent</h5>



                <ul class="list_1">

                    <li>The stent, which is collapsed around a balloon at the tip of the catheter, is guided through the artery to the blockage.</li>

                    <li> At the blockage, the balloon is inflated and the spring-like stent expands and locks into place inside the artery</li>

                    <li> The stent stays in the artery permanently to hold it open and improve blood flow to your heart. In some cases, more than one stent may be needed to open a blockage.</li>

                    <li> Once the stent is in place, the balloon catheter is removed and more images (angiograms) are taken to see how well blood flows through your newly widened artery.</li>

                    <li> Finally, the guide catheter is removed, and the procedure is completed.</li>

                </ul>



                <p>A new drug-eluting, fully absorbable stent has recently been approved to treat coronary artery disease. The stent is designed to dissolve and be absorbed by the body over time. Long-term effects aren't yet known.</p>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Call your doctor or Hospital Emergency room immediately if:</h5>



                <ul class="list_1">

                    <li> The site where your catheter was inserted starts bleeding or swelling</li>

                    <li> You develop pain or discomfort at the site where your catheter was inserted</li>

                    <li> You have signs of infection, such as redness, swelling, drainage or fever</li>

                    <li> There's a change in temperature or color of the leg or arm that was used for the procedure</li>

                    <li> You feel faint or weak</li>

                    <li> You develop chest pain or shortness of breath</li>

                </ul>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;"> Blood thinners</h5>



                <p>It's important that you closely follow your doctor's recommendations about your treatment with blood-thinning medications — aspirin and clopidogrel or similar medications.</p>



                <p>Most people who have undergone angioplasty with or without stent placement will need to take aspirin indefinitely. Those who have had

                    stent placement will need a blood-thinning medication such as clopidogrel for a year or longer in some cases. If you have any questions or if you need noncardiac surgery, talk to your cardiologist before stopping any of these medications.</p>



                <p>Coronary angioplasty greatly increases blood flow through the previously narrowed or blocked coronary artery. Your chest pain generally should decrease, and you may be better able to exercise.

                    Having angioplasty and stenting doesn't mean your heart disease goes away. You'll need to continue healthy lifestyle habits and take medications as prescribed by your doctor.</p>



                <p>If your symptoms return, such as chest pain or shortness of breath, or if other symptoms similar to those you had before your procedure recur, contact your doctor</p>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">To keep your heart healthy after angioplasty, you should:</h5>



                <ul class="list_1">

                    <li> Quit smoking</li>

                    <li> Lower your cholesterol levels</li>

                    <li> Maintain a healthy weight</li>

                    <li> Control other conditions, such as diabetes and high blood pressure</li>

                    <li> Get regular exercise</li>

                </ul>



            </div>



        </div>

    </div>

    

</section>

<app-footer></app-footer>
