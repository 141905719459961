import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-createchannelpartner',
  templateUrl: './createchannelpartner.component.html',
  styleUrls: ['./createchannelpartner.component.css']
})
export class CreatechannelpartnerComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private httpclient: HttpClient, private apicalls: ApicallsService, private route: Router, private router: ActivatedRoute) { }
  userDetails
  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data

  }

  data: '';
  pathurl: string = 'GokulClinic';
  isSubmitted: boolean = true;
  //loggedIn:boolean=true;
  channelError: string;

  onSubmit(channelpartnerForm) {
    console.log("@@@@@@@privilegeForm", this.channelpartnerForm.value);

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 5000);

    let result = this.apicalls.createChannelPartners(this.channelpartnerForm.value)
      .subscribe(data => {
        this.spinner.show();
        //  this.data = data;
        if (data.type == true) {

          if (data.code == 'S001') {
            this.spinner.hide();
            alert(data.data)
            console.log("!!!!!@@@@@@@@@@@!!!1", data);
            this.route.navigate(["/dashboardmain/channelpartners"]);
          }
          if (data.code == 'L005') {
            this.spinner.hide();
            alert(data.data)
            // console.log("!!!!!@@@@@@@@@@@!!!1",data);        
            this.route.navigate(["/dashboardmain/channelpartners"]);
          }
          if (data.code == 'S002') {
            this.spinner.hide();
            alert(data.data);
            this.isSubmitted = false;
            return false;
            //   console.log("!!!!!@@@@@@@@@@@!!!1",data);        
            // this.route.navigate(["/dashboardmain/channelpartners"]);
          }

        }
        else {
          // "code":"L005"
          console.log("!!!!!!!!!!!!!1", data.data);
          this.spinner.hide();
          this.isSubmitted = false;
          alert(data.data)
          this.channelError = data.data;

          return false;
          //  alert( data.data)

        }

        // }
      },
        error => { alert(" Internal Server Error "); }
      );

  }


  get Name() {
    return this.channelpartnerForm.get('Name');
  }
  get EmailID() {
    return this.channelpartnerForm.get('EmailID');
  }
  // get AliasName() {
  //   return this.channelpartnerForm.get('AliasName');
  // }
  get PhoneNumber() {
    return this.channelpartnerForm.get('PhoneNumber');
  }


  channelpartnerForm = new FormGroup({
    Name: new FormControl('', [Validators.required, Validators.minLength(3), Validators.pattern('[a-zA-Z ]*')]),
    EmailID: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z2-9.-]+\.[a-z]{2,4}$')]),
    // AliasName : new FormControl('', [Validators.required,Validators.minLength(10)]),
    PhoneNumber: new FormControl('', [Validators.required, Validators.pattern('[6789][0-9]{9}'), Validators.minLength(10), Validators.maxLength(10)])

  });

  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }

}
