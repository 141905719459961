<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="utf-8" />
    <title> - Dr. Gokul Reddy Mandala Cardiologist </title>
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="keywords" content="Dr Gokul Reddy Cardiologist , cardiologist in hyderabad, cardiologist in Tarnaka, cardiologist in Uppal, cardiologist in Nalgonda, cardiologist in Warangal,cardiologist near Habsiguda, cardiologist Near Tarnaka , Best cardiologist in hyderabad, Best cardiologist doctor in hyderabad, any good cardiologist in hyderabad, any good cardiologist in Tarnaka , best cardiac surgeon in hyderabad , best cardiac surgeon , top 10 cardiothoracic surgeon in hyderabad, famous cardiologists hyderabad , cardiologists in hyderabad india, cardiologists near me, cardiologists hyderabad, cardiology doctor, best cardiologist near me" />
    <meta name="Drgokulreddy" content="Dr Gokul Reddy Cardiologist" />
    <meta name="author" content="" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <!-- Favicon -->
    <link rel="shortcut icon" href="/../../assets/front/img/favicon.ico" />
    <!-- Font -->
    <link rel='stylesheet' href='http://fonts.googleapis.com/css?family=Arimo:300,400,700,400italic,700italic' />
    <link href='http://fonts.googleapis.com/css?family=Oswald:400,300,700' rel='stylesheet' type='text/css' />
    <!-- Font Awesome Icons -->
    <link href='/../../assets/front/css/font-awesome.min.css' rel='stylesheet' type='text/css' />

    <link href='/../../assets/front/css/font-awesome.min.css' rel='stylesheet' type='text/css' />
    <!-- Bootstrap core CSS -->
    <link href="/../../assets/front/css/bootstrap.min.css" rel="stylesheet" />
    <link href="/../../assets/front/css/hover-dropdown-menu.css" rel="stylesheet" />
    <!-- Icomoon Icons -->
    <link href="/../../assets/front/css/icons.css" rel="stylesheet" />
    <link href="http://ajax.googleapis.com/ajax/libs/jqueryui/1.7.2/themes/base/jquery-ui.css" rel="stylesheet" />
    <!-- Revolution Slider -->
    <link href="/../../assets/front/css/revolution-slider.css" rel="stylesheet" />
    <link href="/../../assets/front/rs-plugin/css/settings.css" rel="stylesheet" />
    <!-- Animations -->
    <link href="/../../assets/front/css/animate.min.css" rel="stylesheet" />
    <!-- Owl Carousel Slider -->
    <link href="/../../assets/front/css/owl/owl.carousel.css" rel="stylesheet" />
    <link href="/../../assets/front/css/owl/owl.theme.css" rel="stylesheet" />
    <link href="/../../assets/front/css/owl/owl.transitions.css" rel="stylesheet" />
    <!-- PrettyPhoto Popup -->
    <link href="/../../assets/front/css/prettyPhoto.css" rel="stylesheet" />
    <!-- Custom Style -->
    <link href="/../../assets/front/css/style.css" rel="stylesheet" />
    <link href="/../../assets/front/css/helpers.css" rel="stylesheet" />
    <link href="/../../assets/front/css/responsive.css" rel="stylesheet" />
    <!-- Color Scheme -->
    <link href="/../../assets/front/css/color.css" rel="stylesheet" />
    <link href="/../../assets/front/css/custom.css" rel="stylesheet" />
    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
    <script type="text/javascript" src="/../../assets/front/js/jquery.min.js"></script>
    <script type="text/javascript" src="/../../assets/front/js/bootstrap.min.js"></script>
    <script src="https://ajax.googleapis.com/ajax/libs/jqueryui/1.8.23/jquery-ui.min.js"></script>
    <style>
        .carousel-control .glyphicon-chevron-left, .carousel-control .glyphicon-chevron-right, .carousel-control .icon-next, .carousel-control .icon-prev {
            position: absolute;
            top: 90%;
            z-index: 5;
            display: inline-block;
            margin-top: -10px;
        }
        .carousel-control.left {
            background-image:none;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
            background-repeat: repeat-x;
        }
        .carousel-control.right { background-image:none;left:66%;}
        .left.carousel-control {
            top:inherit;
            width: 10%;
            left:62%;
        }
        .error{
            color:red;
        }
    </style>



</head>
<body>

    
<nav class="navbar navbar-inverse">
    <div>
        <div>
            <div class="pull-right" style="padding:2px 0px;border-bottom:1px solid #ddd;    margin-right: 50px;">
                <div class="pull-left">
                    <span style="color:#576573;" class="pr5">
                         <img src="/../../assets/front/img/mail.png"/> Email: <a href="mailto:gokulreddy@yahoo.com" style="color:#576573;">gokulreddy@yahoo.com</a>
                         |  <a href="mailto:info@drgokulreddy.in" style="color:#576573;" class="pl5 pr5">info@drgokulreddy.in</a> 
                        |  <a href="#" style="color:#576573;" class="pl5 pr5"><img src="/../../assets/front/img/help.png" style="vertical-align:text-top"/>Help Desk</a> | </span>
                </div>
                <span class="pull-left front-socials">
	<a href="#"><i class="fa fa-2x fa-facebook" aria-hidden="true"></i></a>
	<a href="#"><i class="fa fa-2x fa-twitter" aria-hidden="true"></i></a>
	<a href="#"><i class="fa fa-2x fa-google-plus" aria-hidden="true"></i></a>
	<a href="#"><i class="fa fa-2x fa-linkedin" aria-hidden="true"></i></a>
	
	&nbsp;
	</span>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="navbar-header" style="width:100%;background:#fff;padding:0 25px;">
            <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <div class="ph15">
                <img src="/../../assets/front/img/logo.png" class="lm-logo pull-left pn">

                <div class="pull-right pn pt20">
                    <p class="pull-left"><img src="/../../assets/front/img/calltime.png"/></p>
                    <div class="pull-left pl10">
                        <h4 class="mn pn lh15 pt5 fs16" style="font-weight:500;"> 040 - 2360 7777 </h4>
                        <p class="mn pn fs13"> Emergency Cases Alollo Hospitals</p>
                    </div>
                </div>
                <div class="pull-right pn pt20 pr20">
                    <p class="pull-left"><img src="/../../assets/front/img/time.png"/></p>
                    <div class="pull-left pl10">
                        <h4 class="mn pn lh15 pt5 fs16" style="font-weight:500;"> Medical Registration </h4>
                        <p class="mn pn fs13"> TS Medical Council, Regd Number- 38759</p>
                    </div>
                </div>

            </div>
            <div class="clearfix"></div>
        </div>
        
      
    </div>
</nav>



             



         
