<app-header></app-header>
<nav class="navbar navbar-inverse">
    <div>
        <div style="position:relative;">
            <div>
                <div class="collapse navbar-collapse" id="myNavbar" style="float:left;padding:0px;clear:both;width:100%;background:#2d3e50;">
                    <div>
                        <ul class="nav navbar-nav ag-menu sm pl40" style="float:left;" data-smartmenus-id="14811307330159312">
                            <li class=""><a href="">HOME</a>
                            </li>
                            <li class="{{openlist}}"  (mouseover)='over()' (mouseout)='out()'><a href="" class="has-submenu">MYPRACTICE  </a>
                                <ul class="dropdown-menu sm-nowrap" >
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Dr Gokul reddy</a>
                                                    </div>
                                                    <div>
                                                        <a class="" routerLink="/practice-locations"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>Practice Locations</a>
                                                    </div>
                                                    <!-- <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Book  appointment</a>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                            </li>
                            <li class="{{openlist1}}" (mouseover)='over1()' (mouseout)='out1()'><a class="has-submenu" href="#" >PATIENT INFO </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/about-cholesterol"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>ABOUT CHOLESTEROL</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/angioplasty"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> ANGIOPLASTY</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/chestpain"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CHESTPAIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/common-misconceptions"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>COMMON MISCONCEPTIONS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/coronary-angiogram"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CORONARY ANGIOGRAM</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/exercsie-and-heart-health"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> EXERCSIE & HEART HEALTH</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/guide-to-taking-warfarin"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> GUIDE TO TAKING WARFARIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-attack"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTATTACK</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-fauilre"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTFAUILRE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/high-blood-pressure"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HIGH BLOOD PRESSURE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/htn-myths"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>HTN-MYTHS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/tips-to-lower"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>TIPS  TO LOWER CHOLESTEROL   LEVELS</a> </div>



                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li><a href="#">SERVICES</a></li>
                            <li><a href="#">APPOINTMENTS</a></li>
                            <li class=""><a  href="faq">FAQS </a></li>
                            <li class=""><a  href="blog">MY BLOG</a></li>

                            <li class=""> <a  href="reach-us">REACH US</a></li>

                            <div class="clearfix"></div>
                        </ul>
                        <div class="pull-right pn">
                            <a href="#"><img src="/../../assets/front/img/askdemo.png" style="height:52px;"/></a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</nav>
<section style="background:#fff;">

    <div class="container">

        <div  style="padding:30px 15px;">

            <div class="row">

                <h4 class="mb5" style="color: #0991cd;font-weight: bold;"> HTN Myths </h4>

                <!--<p class="pull-right"><img src="img/gokulreddy.png" style="height:350px;"/></p>-->





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Myth: High blood pressure runs in my family. There is nothing I can do to prevent it.</h5>





                <p>High blood pressure can run in families. If your parents or close blood relatives have had high blood pressure, you are more likely to develop it, too. However, lifestyle choices have allowed many people with a family history of high blood pressure to avoid it themselves.</p>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;"> Myth: I don’t use table salt, so I’m in control of my sodium intake and my blood pressure.</h5>



                <p>In some people, sodium can increase blood pressure. But controlling sodium means more than just putting down the salt shaker. It also means checking labels, because up to 75 percent of the sodium we consume is hidden in processed foods like tomato sauce, soups, condiments, canned foods and prepared mixes. When buying prepared and prepackaged foods, read the labels. Watch for the words “soda” and “sodium” and the symbol “Na” on labels. These words show that sodium compounds are present.</p>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Myth: I use kosher or sea salt when I cook instead of regular table salt. They are low-sodium alternatives.</h5>





                <p>Chemically, sea salt is the same as table salt — 40 percent sodium— and count the same toward total sodium consumption. Table salt is a combination of the two minerals sodium (Na) and chloride (Cl).</p>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Myth: I feel fine. I don’t have to worry about high blood pressure.</h5>



                <p>About 80 million U.S. adults have high blood pressure — and many of them don’t know it or don’t experience typical symptoms. High blood pressure is also a major risk factor for stroke. If uncontrolled, high blood pressure can lead to serious and severe health problems.</p>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Myth: People with high blood pressure have nervousness, sweating, difficulty sleeping and their face becomes flushed. I don’t have those symptoms so I’m good.</h5>



                <p>Many people have high blood pressure for years without knowing it. It’s often called “the silent killer” because it usually has no symptoms. You may not be aware that it’s damaging your arteries, heart and other organs. Know your numbers and don’t make the mistake of assuming any specific symptoms will let you know there’s a problem.</p>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Myth: I read that wine is good for the heart, which means I can drink as much as I want.</h5>



                <p>If you drink alcohol, including red wine, do so in moderation. Heavy and regular use of alcohol can increase blood pressure dramatically. It can also cause heart failure, lead to stroke and produce irregular heartbeats. Too much alcohol can contribute to high triglycerides, cancer, obesity, alcoholism, suicide and accidents. If you drink, limit consumption to no more than two drinks per day for men and one drink per day for women. Generally, one drink equals a 360ml of beer, a 120ml of wine, 45ml of whisky or any hard liquor</p>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Myth: I have high blood pressure and my doctor checks it for me. This means I don’t need to check it at home.</h5>



                <p>Because blood pressure can fluctuate, home monitoring and recording of blood pressure readings can provide your healthcare provider with valuable information to determine whether you really have high blood pressure and, if you do, whether your treatment plan is working. It’s important to take the readings at the same time each day, such as morning and evening, or as your healthcare professional recommends.</p>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Myth: I was diagnosed with high blood pressure, but I have been maintaining lower readings, so I can stop taking my medication.</h5>



                <p>High blood pressure can be a lifelong disease. Follow your healthcare professional’s recommendations carefully, even if it means taking medication every day for the rest of your life. By partnering with your healthcare team, you can successfully reach your treatment goals and enjoy the benefits of better health</p>









            </div>



        </div>

    </div>

</section>
<app-footer></app-footer>