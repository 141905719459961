import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
@Component({
  selector: 'app-updatedoctors',
  templateUrl: './updatedoctors.component.html',
  styleUrls: ['./updatedoctors.component.css']
})
export class UpdatedoctorsComponent implements OnInit {
  doctorsdetails;
  datePickerConfig: Partial<BsDatepickerConfig>;
  temppreviousdob;
  error = false;

  constructor(private spinner: NgxSpinnerService, private httpclient: HttpClient, private formBuilder: FormBuilder, private apicalls: ApicallsService, private route: Router, private router: ActivatedRoute) {


    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumber: false,
        dateInputFormat: 'DD-MM-YYYY'

      }
    );

  }
  userDetails;
  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data
    this.router.paramMap.subscribe(params => {
      const MedicID = params.get('id');
      if (MedicID) {

        this.getMedicID(MedicID);
      }
    });


  }
  getMedicID(MedicID: string) {
    this.spinner.show();
    this.apicalls.viewDoctorsById(MedicID).subscribe(

      data => {
        
        this.spinner.hide();
        if (data.type == true) {
          if(data.code =="S001"){
            this.doctorsdetails = data
          this.temppreviousdob = moment(this.doctorsdetails.data.DOB, "DD-MM-YYYY").unix();
          this.doctorForm.patchValue({
            MedicID: this.doctorsdetails.data.MedicID,
            FirstName: this.doctorsdetails.data.FirstName,
            LastName: this.doctorsdetails.data.LastName,
            DOB: this.doctorsdetails.data.DOB,
            Age: this.doctorsdetails.data.Age,
            EmailID: this.doctorsdetails.data.EmailID,
            ContactNumber: this.doctorsdetails.data.ContactNumber,
            Fee: this.doctorsdetails.data.Fee,
            Specialization: this.doctorsdetails.data.Specialization,
            RegNumber: this.doctorsdetails.data.RegNumber,
            FollowUpDays: this.doctorsdetails.data.FollowUpDays,
            StreetAddress: this.doctorsdetails.data.Address.StreetAddress,
            Status: this.doctorsdetails.data.Status
          });
          }else{
            alert(data.data)
          }
          
        } else {
          alert(data.message);
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ")
      });


  }

  data: '';
  pathurl: string = 'GokulClinic';
  isSubmitted: boolean = true;

  AddressObj = {};


  onSubmit(doctorForm) {
    if (this.doctorForm.invalid) {
      this.error = true;
      return;
    }
    this.spinner.show();




    this.AddressObj = {
      StreetAddress: this.doctorForm.value.StreetAddress,
     
    };
    this.doctorForm.value.Address = this.AddressObj;

    let result = this.apicalls.updateDoctors(this.doctorForm.value)
      .subscribe(data => {
        if (data.type == true) {

          if (data.code == 'U001') {
            this.spinner.hide();
            alert(data.data)
            this.route.navigate(["/dashboardmain/doctors"]);
          }
          else if (data.code == 'L005') {
            this.spinner.hide();
            alert(data.data)
            this.route.navigate(["/dashboardmain/doctors"]);
          }
       
          else if (data.code == 'S002') {
            this.spinner.hide();
            alert(data.data);
            this.isSubmitted = false;
            return false;
          }
          else if(data.code =="UA01"){
            alert("Your UnAuthorized to Access this Service")
          }else if(data.code =="PD01"){
            alert("Permissions Denied")
          }else{
            alert(data.data)
          }
          this.spinner.hide();

        }
        else {
          this.spinner.hide();
          this.isSubmitted = false;
          alert(data.data)
          return false;
        }
      },
        error => { alert(" Internal Server Error "); }
      );

  }
  get RegNumber() {
    return this.doctorForm.get('RegNumber');
  }
  get FollowUpDays() {
    return this.doctorForm.get('FollowUpDays');
  }
  get Age() {
    return this.doctorForm.get('Age');
  }
  get StreetAddress() {
    return this.doctorForm.get('StreetAddress');
  }
  get Colony() {
    return this.doctorForm.get('Colony');
  }
  get Locality() {
    return this.doctorForm.get('Locality');
  }
  get PinCode() {
    return this.doctorForm.get('PinCode');
  }
  get City() {
    return this.doctorForm.get('City');
  }
  get State() {
    return this.doctorForm.get('State');
  }
  get Country() {
    return this.doctorForm.get('Country');
  }
  get FirstName() {
    return this.doctorForm.get('FirstName');
  }
  get LastName() {
    return this.doctorForm.get('LastName');
  }
  
  get ContactNumber() {
    return this.doctorForm.get('ContactNumber');
  }
  get Address() {
    return this.doctorForm.get('Address') as FormArray;
  }
  get DOB() {
    return this.doctorForm.get('DOB');
  }
  get EmailID() {
    return this.doctorForm.get('EmailID');
  }
  get Fee() {
    return this.doctorForm.get('Fee');
  }
  get Specialization() {
    return this.doctorForm.get('Specialization');
  }

  get MedicID() {
    return this.doctorForm.get('MedicID');
  }

  doctorForm = new FormGroup({
    MedicID: new FormControl(''),
    FirstName: new FormControl('', [Validators.required, Validators.minLength(3), Validators.pattern('[a-zA-Z ]*')]),
    LastName: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
    Age: new FormControl('', [Validators.required, Validators.pattern('[1-9][0-9]')]),
    EmailID: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z2-9.-]+\.[a-z]{2,4}$')]),
    ContactNumber: new FormControl('', [Validators.required, Validators.pattern('[6789][0-9]{9}'), Validators.minLength(10), Validators.maxLength(10)]),
    Specialization: new FormControl('', [Validators.required, Validators.minLength(2)]),
    RegNumber: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9]+')]),
    FollowUpDays: new FormControl('', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
    Address: new FormGroup({
      StreetAddress: new FormControl(''),
    }),
    StreetAddress: new FormControl(''),
   });


  
  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }
}


