import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApicallsService } from '../../services/apicalls.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-todays-appointments',
  templateUrl: './todays-appointments.component.html',
  styleUrls: ['./todays-appointments.component.css']
})
export class TodaysAppointmentsComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private apicalls: ApicallsService, private router: ActivatedRoute,
    private route: Router, private httpClient: HttpClient) { }
  pager = {};
  pageOfItems = [];
  todayAppointments;
  userDetails;
  term: string = '';

  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data
    //this.router.queryParams.subscribe(x => this.viewTodayAppointments(x.page, x.limit || 1));
    this.apicalls.todayAppointments()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
          if (data.code == "S001") {
            this.todayAppointments = data;
          }
          if (data.code == "S002") {
            alert(data.data);
            return;
          }
          else if (data.code == "PD01") {
            alert("Permissions Denied");
            return;
          }
          else if (data.code == "UA01") {
            alert("Your UnAuthorized to Access this Service");
            return;
          }


          //console.log("todayAppointments..", this.todayAppointments);
        } else {
          //  this.alertService.info(data.message)
          this.spinner.hide();
          // this.spinner.hide();
          if (data.code == 'PD01') {
            alert(data.data)
            this.route.navigate(["/dashboardmain/dashboard"]);
          }
          else {
            alert(data.data)
          }
        }
      }, error => { alert(" Internal Server Error "); });


  }

  // private viewTodayAppointments(pageNumber, limit) {
  //   this.apicalls.todayAppointments(pageNumber, limit)
  //     .subscribe(data => {
  //       this.spinner.show();
  //       if (data.type == true) {
  //         setTimeout(() => {
  //           this.spinner.hide();
  //         }, 500);
  //         this.todayAppointments = data;
  //         this.pager = data.pager;
  //         this.pageOfItems = data.pageOfItems;
  //         console.log(" ### ", this.pager);
  //         console.log("............", this.todayAppointments);
  //         //  this.dtTrigger.next();

  //       } else {

  //         //  this.alertService.info(data.message)
  //         this.spinner.hide();
  //         // this.spinner.hide();

  //         if (data.code == 'PD01') {
  //           alert(data.data)
  //           this.route.navigate(["/dashboardmain/dashboard"]);

  //         }
  //         else {
  //           alert(data.data)
  //         }

  //       }
  //     }, error => {
  //       error => { alert(" Internal Server Error "); }
  //       // this.alertService.warning(" Internal Server Error ");
  //     });
  // }
  
  PrintPrescription(AppointmentID) {

    this.route.navigate(["/dashboardmain/updateappointments", AppointmentID]);
  }
  editprescription(AppointmentID) {
    this.route.navigate(["/dashboardmain/updateappointmentsprescriptions", AppointmentID]);
  }
  UploadHelathReports(AppointmentID) {
    this.route.navigate(["/dashboardmain/UploadHelathReports", AppointmentID]);
  }


  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }

  Appointments() {
    this.route.navigate(["/dashboardmain/appointments"]);
  }
  onClickCreate() {
    this.route.navigate(["/dashboardmain/createappointments"]);
  }
  viewAllHealthReports(AppointmentID, MRNumber) {
    this.route.navigate(["/dashboardmain/ViewAllHelathReports", AppointmentID, MRNumber]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }
}
