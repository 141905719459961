import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-update-payments',
  templateUrl: './update-payments.component.html',
  styleUrls: ['./update-payments.component.css']
})
export class UpdatePaymentsComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private apicalls: ApicallsService, private router: ActivatedRoute,
    private route: Router, private httpClient: HttpClient, private formBuilder: FormBuilder) { }
  viewallpatients;
  viwAllBillingsActiveType;
  dropdownSettings = {};
  dropdownList = [];

  paymentdetails;
  userDetails;
  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data
    this.router.paramMap.subscribe(params => {
      const PaymentID = params.get('PaymentID');
      if (PaymentID) {
       // this.getPaymentByID(PaymentID);      
          this.apicalls.viewPayment(PaymentID).subscribe(
            data => {
              this.spinner.show();
              if (data.type == true) {
                setTimeout(() => {
                  this.spinner.hide();
                }, 500);
                if(data.code =="S001"){
                  this.paymentdetails = data.data
                  console.log(this.paymentdetails.Type)
                }
              
              } else {
                alert(data.data);
                this.spinner.hide();
              }
            }, error => {
              alert(" Internal Server Error ")
            });
        }
      
    });
    
  }

  printPage(comp) {
    let printContents = document.getElementById(comp).innerHTML;
     let originalContents = document.body.innerHTML;

      
     document.body.innerHTML = printContents;

     window.print();

     document.body.innerHTML = originalContents;
  //  window.print();

    // var divToPrint = document.getElementById('divtoprint');

    // var newWin = window.open('', 'Print-Window');

    // newWin.document.open();

    // newWin.document.write('<html><body onload="window.print()">' + divToPrint.innerHTML + '</body></html>');

    // newWin.document.close();

    // setTimeout(function () { newWin.close(); }, 10);
  }

 


 


  paymentForm = new FormGroup({
    PatientName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.pattern('[a-zA-Z ]*')]),
    PaymentID: new FormControl('', [Validators.required]),
    MRNumber: new FormControl('', [Validators.required]),

    Discount: new FormControl('', [Validators.pattern('^[0-9]*')]),
    ContactNumber: new FormControl('', [Validators.required, Validators.pattern('[6789][0-9]{9}'), Validators.minLength(10), Validators.maxLength(10)]),
    TestArray: new FormArray([])
  });
  get PaymentID() {
    return this.paymentForm.get('PaymentID');
  }
  get MRNumber() {
    return this.paymentForm.get('MRNumber');
  }
  get Discount() {
    return this.paymentForm.get('Discount');
  }
  get TestArray() {
    return this.paymentForm.get('TestArray') as FormArray;
  }
  get ContactNumber() {
    return this.paymentForm.get('ContactNumber');
  }
  get PatientName() {
    return this.paymentForm.get('ContactNumber');
  }

  onClickCreate() {
    this.route.navigate(["/dashboardmain/calculateTestPrices"]);
  }


  TempTestArray = [];
  PaymentStatus = "Paid";
  error;
  
  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }
  Settings(){
    this.route.navigate(["/dashboardmain/configurations"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
}