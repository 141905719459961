<body data-gr-c-s-loaded="true">
    <div id="page" class="side-nav-wrapper">
        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pull-right" style="margin-right: 10px;">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                                style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                                <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                                <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                    userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                        class="">Settings</a></li>
                                <li
                                    *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                    <a [routerLink]="" (click)="users()" class="">Users</a></li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="container bg-gray">
            <div class="row">
                <div class="col-md-12">
                    <h5 class="dr-sub-head">Update Permissions</h5>
                </div>
            </div>
            <div class="row orr-usercreation ptop30 pbtom30">

                <div class="col-md-12">
                    <div class="panel">


                        <div class="tab-table-customer-entry"
                            style="margin:30px 0;background:#fff;padding:10px 0;border-top:1px solid #ddd;border-bottom:1px solid #ddd;">
                            <div class="text-center fs20" *ngIf="channelError" [hidden]='isSubmitted'
                                style="background-color:white;color:red">{{channelError}}</div>

                            <form id="contact-form" class="contact-form pad-40" autocomplete="off"
                                [formGroup]="permissionsForm">
                                <div class="form-pad-label" style="padding-top:30px;">


                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Select
                                                Role<span class="required">* </span></label>

                                            <select
                                                [class.is-invalid]="RoleID.invalid && (RoleID.dirty || RoleID.touched)"
                                                class="form-control" id="RoleID" type="RoleID" name="RoleID"
                                                formControlName="RoleID" style="float:left;width:70%;">

                                                <option value="" disabled>Select Role</option>
                                                <option *ngFor="let list of viewallroles?.data; let i=index"
                                                    value="{{list.RoleID}}">{{list.Role}}</option>

                                            </select>
                                            <div *ngIf="RoleID.invalid  && (RoleID.dirty || RoleID.touched)">
                                                <small *ngIf="RoleID.errors?.required" class="text-danger">Role is
                                                    required</small>

                                            </div>
                                        </div>

                                    </div>
                                    <div style="clear:both;"></div>

                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Select
                                                Resource<span class="required"> * </span></label>

                                            <select
                                                [class.is-invalid]="ResourceID.invalid && (ResourceID.dirty || ResourceID.touched)"
                                                class="form-control" id="ResourceID" type="ResourceID" name="ResourceID"
                                                formControlName="ResourceID" style="float:left;width:70%;">

                                                <option value="" disabled>Select Resource</option>
                                                <option *ngFor="let list of viewallresources?.data; let i=index"
                                                    value="{{list.ResourceID}}">{{list.Resource}}</option>

                                            </select>
                                            <div
                                                *ngIf="ResourceID.invalid  && (ResourceID.dirty || ResourceID.touched)">
                                                <small *ngIf="ResourceID.errors?.required" class="text-danger">resource
                                                    is
                                                    required</small>

                                            </div>
                                        </div>

                                    </div>
                                    <div style="clear:both;"></div>

                                    <!-- <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Select
                                                Channel Partner<span class="required"> *</span></label>

                                            <select
                                                [class.is-invalid]="ChannelID.invalid && (ChannelID.dirty || ChannelID.touched)"
                                                class="form-control" id="ChannelID" type="ChannelID" name="ChannelID"
                                                formControlName="ChannelID" style="float:left;width:70%;">

                                                <option value="" disabled>Select Channel</option>
                                                <option *ngFor="let list of viewallchannelpartners?.data; let i=index"
                                                    value="{{list.ChannelID}}">{{list.Name}}</option>

                                            </select>
                                            <div *ngIf="ChannelID.invalid  && (ChannelID.dirty || ChannelID.touched)">
                                                <small *ngIf="ChannelID.errors?.required" class="text-danger">Channel
                                                    partner is
                                                    required</small>

                                            </div>
                                        </div>

                                    </div>
                                    <div style="clear:both;"></div> -->

                                    <div class="col-md-10">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Select
                                                Privileges<span class="required"> *</span></label>

                                            <ng-multiselect-dropdown [placeholder]="' Select Privilege '"
                                                [data]="dropdownList" [(ngModel)]="selectedItems"
                                                [settings]="dropdownSettings" (onSelect)="selectPrivilege($event)"
                                                (onSelectAll)="onSelectAll($event)"
                                                (onDeSelect)="onItemDeSelect($event)"
                                                [ngModelOptions]="{standalone: true}"
                                                (ngModelChange)="selectPrivilegeType(PrivilegeID)" required>

                                            </ng-multiselect-dropdown>

                                        </div>

                                    </div>



                                    <div style="clear:both;"></div>

                                    <div
                                        style="margin:auto;text-align:center;margin-bottom:10px;margin-top:30px;margin-bottom:30px;">
                                        <a [routerLink]="" (click)="onSubmit(permissionsForm)"> <input type="submit"
                                                class="btn-pink" value="SAVE"></a>

                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>

            </div>
        </div>
    </div>
</body>
<ngx-spinner></ngx-spinner>