import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'app-updateappointmentsprescription',
  templateUrl: './updateappointmentsprescription.component.html',
  styleUrls: ['./updateappointmentsprescription.component.css']
})

export class UpdateappointmentsprescriptionComponent implements OnInit {
  datePickerConfig: Partial<BsDatepickerConfig>;

  submitError: string;

  IsSubmitted: boolean = true;
  error = false;

  // this.isSubmitted = true;

  data: '';
  reportObj = {};
  HCMreportObj = {};
  PAHreportObj = {};
  HTN_NO_CAD_CHFreportObj = {};
  pathurl: string = 'GokulClinic';
  isSubmitted: boolean = true;
  //loggedIn:boolean=true;
  channelError: string;
  temptype = '';

  selectedValue;
  public show: boolean = false;
  public buttonName: any = 'Show';
  public htnvalue: boolean = false;
  public chfvalue: boolean = true;
  public cadvalue: boolean = false;
  public dmvalue: boolean = false;
  public hcmvalue: boolean = false;
  public pahvalue: boolean = false;
  public crhdvalue: boolean = false;
  public hlpvalue: boolean = false;
  public cpvalue: boolean = false;
  public othervalue: boolean = false;

  RiskFactorDMriskFormArray = [];

  cdkDMFormValue;
  OLD_CVA_OR_TIAvalue;


  constructor(private spinner: NgxSpinnerService, private httpclient: HttpClient, private formBuilder: FormBuilder, private apicalls: ApicallsService, private route: Router, private router: ActivatedRoute) {
    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumber: false,
        dateInputFormat: 'DD-MM-YYYY'

      }
    );
  }

  htn() {
    this.temptype = 'HTN_NO_CAD_CHF';
    this.htnvalue = !this.htnvalue;
    this.chfvalue = false;
    this.cadvalue = false;
    this.dmvalue = false;
    this.hcmvalue = false;
    this.pahvalue = false;
    this.crhdvalue = false;
    this.hlpvalue = false;
    this.cpvalue = false;
    this.othervalue = false;



  }
  chf() {
    this.temptype = 'CHF';

    this.chfvalue = !this.chfvalue;
    this.htnvalue = false;
    this.cadvalue = false;
    this.dmvalue = false;
    this.hcmvalue = false;
    this.pahvalue = false;
    this.crhdvalue = false;
    this.hlpvalue = false;
    this.cpvalue = false;
    this.othervalue = false;

  }
  cad() {
    this.temptype = 'CAD';
    this.cadvalue = !this.cadvalue;
    this.htnvalue = false;
    this.chfvalue = false;
    this.dmvalue = false;
    this.hcmvalue = false;
    this.pahvalue = false;
    this.crhdvalue = false;
    this.hlpvalue = false;
    this.cpvalue = false;
    this.othervalue = false;
  }
  dm() {
    this.temptype = 'DM_NO_CAD_CHF';
    this.dmvalue = !this.dmvalue;
    this.htnvalue = false;
    this.chfvalue = false;
    this.cadvalue = false;
    this.hcmvalue = false;
    this.pahvalue = false;
    this.crhdvalue = false;
    this.hlpvalue = false;
    this.cpvalue = false;
    this.othervalue = false;
  }
  hcm() {
    this.temptype = 'HCM';
    this.hcmvalue = !this.hcmvalue;
    this.htnvalue = false;
    this.chfvalue = false;
    this.cadvalue = false;
    this.dmvalue = false;
    this.pahvalue = false;
    this.crhdvalue = false;
    this.hlpvalue = false;
    this.cpvalue = false;
    this.othervalue = false;
  }
  pah() {
    this.temptype = 'PAH';
    this.pahvalue = !this.pahvalue;
    this.htnvalue = false;
    this.chfvalue = false;
    this.cadvalue = false;
    this.dmvalue = false;
    this.hcmvalue = false;
    this.crhdvalue = false;
    this.hlpvalue = false;
    this.cpvalue = false;
    this.othervalue = false;
  }
  crhd() {
    this.temptype = 'CRHD';
    // this.CRHDappointmentForm.value.MITRAL_STENOSIS="NORMAL";
    this.crhdvalue = !this.crhdvalue;
    this.htnvalue = false;
    this.chfvalue = false;
    this.cadvalue = false;
    this.dmvalue = false;
    this.hcmvalue = false;
    this.pahvalue = false;
    this.hlpvalue = false;
    this.cpvalue = false;
    this.othervalue = false;
  }
  hlp() {

    this.temptype = 'HLP_WITH_NO_CAD';
    this.hlpvalue = !this.hlpvalue;
    this.htnvalue = false;
    this.chfvalue = false;
    this.cadvalue = false;
    this.dmvalue = false;
    this.hcmvalue = false;
    this.pahvalue = false;
    this.crhdvalue = false;
    this.cpvalue = false;
    this.othervalue = false;
  }
  cp() {
    this.temptype = 'Chest_Pain';
    this.cpvalue = !this.cpvalue;
    this.htnvalue = false;
    this.chfvalue = false;
    this.cadvalue = false;
    this.dmvalue = false;
    this.hcmvalue = false;
    this.pahvalue = false;
    this.crhdvalue = false;
    this.hlpvalue = false;
    this.othervalue = false;
  }
  others() {
    this.temptype = 'Other';
    this.othervalue = !this.othervalue;
    this.htnvalue = false;
    this.chfvalue = false;
    this.cadvalue = false;
    this.dmvalue = false;
    this.hcmvalue = false;
    this.pahvalue = false;
    this.crhdvalue = false;
    this.hlpvalue = false;
    this.cpvalue = false;
  }

  //  RiskFactorArray=['HTN','DM','HLP','SMOKER','FHCAD'];

  //  HeartFailureArray=['HFPEF','HFREF','COMPANSATED CHF','DECOMPANSATED CHF'];

  //  NYHAArray=['NYHA-I','NYHA-II','NYHA-III','NYHA-IV'];

  //  StageArray=['STAGE-A','STAGE-B','STAGE-C','STAGE-D']

  //  DIAGNOSISArray=['ICMP','DCM','CRHD','RCMP','P-PAH','SEC','OTHERS'];
  appid
  userDetails
  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data

    this.router.paramMap.subscribe(params => {
      const AppointmentID = params.get('id');
      this.appid = AppointmentID
      if (AppointmentID) {

        this.getAppointmentID(AppointmentID);
      }
    });


  }

  fileToUpload: File = null;
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);

  }
  // RiskFactory
  RiskFactorFormArray = [];
  HeartFailureFormArray = [];
  NYHAFormArray = [];
  tempRisk;
  StageFormArray = [];
  DIAGNOSISFormArray = [];
  i;
  RiskFactorFormArrayCad = [];
  RiskFactorFormArrayAP = [];
  RiskFactorFormArrayAdviceCag = [];
  CADreportObj = {};
  RiskFactorHTNriskFormArray = [];
  ckdFormArray = [];
  ckdvalue;
  HTN_RETINOPATHY_OPTHAL_OPINIONValue;
  OLD_CVA_OR_TIAValue;
  kidneyObj = {};
  CVSObject = {};

  CAD1Array = [];
  CAD2Array = [];
  CAD3Array = [];
  CAD4Array = [];
  onChangecad(list, isChecked: boolean) {
    if (isChecked == true) {
      this.CAD1Array.push(list);
    } else {
      const index = this.CAD1Array.indexOf(list);
      if (index > -1) {
        this.CAD1Array.splice(index, 1);
      }
    }
    this.RiskFactorFormArrayCad = Array.from(new Set(this.CAD1Array))
  }
  onChangeapclass(list, isChecked: boolean) {

    if (isChecked == true) {
      this.CAD2Array.push(list);
    } else {
      const index = this.CAD2Array.indexOf(list);
      if (index > -1) {
        this.CAD2Array.splice(index, 1);
      }
    }
    this.RiskFactorFormArrayAP = Array.from(new Set(this.CAD2Array))

  }

  onChangeadvicecag(list, isChecked: boolean) {

    if (isChecked == true) {
      this.CAD3Array.push(list);
    } else {
      const index = this.CAD3Array.indexOf(list);
      if (index > -1) {
        this.CAD3Array.splice(index, 1);
      }
    }
    this.RiskFactorFormArrayAdviceCag = Array.from(new Set(this.CAD3Array))

  }
  HTNArray = [];
  onChangeHTNrisk(list, isChecked: boolean) {

    if (isChecked == true) {
      this.HTNArray.push(list);
    } else {
      const index = this.HTNArray.indexOf(list);
      if (index > -1) {
        this.HTNArray.splice(index, 1);
      }
    }
    this.RiskFactorHTNriskFormArray = Array.from(new Set(this.HTNArray))

  }
  onChangeckd(list, isChecked: boolean) {
    if (isChecked == true) {
      this.ckdvalue = list;

    } else {
      this.ckdvalue = ""
    }

  }
  onChangeHTN_RETINOPATHY_OPTHAL_OPINION(list, isChecked: boolean) {

    if (isChecked == true) {
      this.HTN_RETINOPATHY_OPTHAL_OPINIONValue = list

    } else {
      this.HTN_RETINOPATHY_OPTHAL_OPINIONValue = ""
    }

  }
  onChangeOLD_CVA_OR_TIA(list, isChecked: boolean) {

    if (isChecked == true) {
      this.OLD_CVA_OR_TIAValue = list;
      this.ckdFormArray.push(list);
    } else {
      const index = this.ckdFormArray.indexOf(list);
      if (index > -1) {
        this.ckdFormArray.splice(index, 1);
      }
    }

  }
  RISKArray1 = [];
  RISKArray2 = [];
  RISKArray3 = [];
  RISKArray4 = [];
  RISKArray5 = [];
  onChange(list, isChecked: boolean) {



    if (list == 'HTN' || list == 'DM' || list == 'HLP' || list == 'SMOKER' || list == 'FHCAD') {

      if (isChecked == true) {
        this.RISKArray1.push(list);
      } else {
        const index = this.RISKArray1.indexOf(list);
        if (index > -1) {
          this.RISKArray1.splice(index, 1);
        }

      }

      this.RiskFactorFormArray = Array.from(new Set(this.RISKArray1))

    }


    if (list == 'HFPEF' || list == 'HFREF' || list == 'COMPANSATED CHF' || list == 'DECOMPANSATED CHF') {
      if (isChecked == true) {
        this.RISKArray2.push(list);
      } else {
        const index = this.RISKArray2.indexOf(list);
        if (index > -1) {
          this.RISKArray2.splice(index, 1);
        }
      }
      this.HeartFailureFormArray = Array.from(new Set(this.RISKArray2))
    }

    if (list == 'NYHA-I' || list == 'NYHA-II' || list == 'NYHA-III' || list == 'NYHA-IV') {
      if (isChecked == true) {
        this.RISKArray3.push(list);
      } else {
        const index = this.RISKArray3.indexOf(list);
        if (index > -1) {
          this.RISKArray3.splice(index, 1);
        }

      }
      this.NYHAFormArray = Array.from(new Set(this.RISKArray3))

    }

    if (list == 'STAGE-A' || list == 'STAGE-B' || list == 'STAGE-C' || list == 'STAGE-D') {
      if (isChecked == true) {
        this.RISKArray4.push(list);
      } else {
        const index = this.RISKArray4.indexOf(list);
        if (index > -1) {
          this.RISKArray4.splice(index, 1);
        }

      }

      this.StageFormArray = Array.from(new Set(this.RISKArray4))

    }

    if (list == 'ICMP' || list == 'DCM' || list == 'CRHD' || list == 'RCMP' || list == 'P-PAH' || list == 'SEC' || list == 'OTHERS') {
      if (isChecked == true) {
        this.RISKArray5.push(list);
      } else {
        const index = this.RISKArray5.indexOf(list);
        if (index > -1) {
          this.RISKArray5.splice(index, 1);
        }

      }
      this.DIAGNOSISFormArray = Array.from(new Set(this.RISKArray5))


    }


  }



  onSubmit(appointmentForm) {
    // if (this.appointmentForm.invalid) {
    //   this.error = true;
    //   return;
    // }
    console.log(this.appointmentForm.value.FollowUpDate)
    let x;
    if (this.appointmentForm.value.FollowUpDate != "Invalid date" && this.appointmentForm.value.FollowUpDate != null && this.appointmentForm.value.FollowUpDate != "") {
      x = moment(this.appointmentForm.value.FollowUpDate).format("DD-MM-YYYY");
      this.appointmentForm.value.FollowUpDate = x;
    } else {
      x = "";
      this.appointmentForm.value.FollowUpDate = x;
    }
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 5000);
    this.appointmentForm.value.Type = "CHF";

    // this.appointmentForm.value.RiskFactors= []
    // this.appointmentForm.value.HeartFailure = [];
    // this.appointmentForm.value.NYHA = [];
    // this.appointmentForm.value.Stage = [];
    // this.appointmentForm.value.DIAGNOSIS = [];

    this.appointmentForm.value.RiskFactors = this.RiskFactorFormArray;
    this.appointmentForm.value.HeartFailure = this.HeartFailureFormArray;
    this.appointmentForm.value.NYHA = this.NYHAFormArray;
    this.appointmentForm.value.Stage = this.StageFormArray;
    this.appointmentForm.value.DIAGNOSIS = this.DIAGNOSISFormArray;

    this.reportObj = {
      RiskFactors: this.appointmentForm.value.RiskFactors,
      HeartFailure: this.appointmentForm.value.HeartFailure,
      NYHA: this.appointmentForm.value.NYHA,
      Stage: this.appointmentForm.value.Stage,
      DIAGNOSIS: this.appointmentForm.value.DIAGNOSIS,
      BP: this.appointmentForm.value.BP,
      PR: this.appointmentForm.value.PR,
      BGH: this.appointmentForm.value.BGH,
      Notes: this.appointmentForm.value.Notes,
      Investigations: this.appointmentForm.value.Investigations,
      Diagnosis: this.appointmentForm.value.Diagnosis,
      FollowUp: this.appointmentForm.value.FollowUp,
      FollowUpDate: this.appointmentForm.value.FollowUpDate,
      Prescription: this.appointmentForm.value.Prescription,
      Diet: this.appointmentForm.value.Diet,
      CAG: this.appointmentForm.value.CAG
    };
    this.appointmentForm.value.CHF = {}
    this.appointmentForm.value.CHF = this.reportObj;
    this.appointmentForm.value.FollowUp = this.appointmentForm.value.FollowUp;
    this.appointmentForm.value.AppointmentID = this.appid;

    let result = this.apicalls.updateAppointmentsPrescription(this.appointmentForm.value)
      .subscribe(data => {
        this.spinner.show();
        //  this.data = data;
        if (data.type == true) {
          if (data.code == 'S001') {
            this.spinner.hide();
            alert(data.data)
            this.route.navigate(["/dashboardmain/TodaysAppointments"]);
          }
          if (data.code == 'S002') {
            this.spinner.hide();
            alert(data.data);
            this.isSubmitted = false;
            this.submitError = data.data
            return false;
          }
          else if (data.code == "PD01") {
            alert("Permissions Denied");
            return;
          }
          else if (data.code == "UA01") {
            alert("Your UnAuthorized to Access this Service");
            return;
          }
        }
        else {
          this.spinner.hide();
          this.isSubmitted = false;
          alert(data.data)
          this.channelError = data.data;
          return false;
        }
      }, error => { alert(" Internal Server Error "); });
  }


  onSubmitCAD(CADappointmentForm) {
    // if (this.appointmentForm.invalid) {
    //   this.error = true;
    //   return;
    // }

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 5000);

    let x;
    if (this.CADappointmentForm.value.FollowUpDate1 != "Invalid date" && this.CADappointmentForm.value.FollowUpDate1 != null && this.CADappointmentForm.value.FollowUpDate1 != "") {
      x = moment(this.CADappointmentForm.value.FollowUpDate1).format("DD-MM-YYYY");
      this.CADappointmentForm.value.FollowUpDate1 = x;
    } else {
      x = "";
      this.CADappointmentForm.value.FollowUpDate1 = x;
    }
    this.CADappointmentForm.value.Type = this.temptype;
    this.CADappointmentForm.value.RiskFactors1 = this.RiskFactorFormArrayCad;
    this.CADappointmentForm.value.APCLASS = this.RiskFactorFormArrayAP;
    this.CADappointmentForm.value.AdvicePostCAG = this.RiskFactorFormArrayAdviceCag;
    this.CADreportObj = {
      RiskFactors: this.CADappointmentForm.value.RiskFactors1,
      APCLASS: this.CADappointmentForm.value.APCLASS,
      AdvicePostCAG: this.CADappointmentForm.value.AdvicePostCAG,
      PostCABG: this.CADappointmentForm.value.PostCABG,
      PostCABGText: this.CADappointmentForm.value.PostCABGText,
      PostPTCA: this.CADappointmentForm.value.PostPTCA,
      PostPTCAText: this.CADappointmentForm.value.PostPTCAText,

      BP: this.CADappointmentForm.value.BP1,
      PR: this.CADappointmentForm.value.PR1,
      BGH: this.CADappointmentForm.value.BGH1,
      Notes: this.CADappointmentForm.value.Notes1,
      Investigations: this.CADappointmentForm.value.Investigations1,
      Diagnosis: this.CADappointmentForm.value.Diagnosis1,
      FollowUp: this.CADappointmentForm.value.FollowUp1,
      FollowUpDate: this.CADappointmentForm.value.FollowUpDate1,
      Prescription: this.CADappointmentForm.value.Prescription1,
      Diet: this.CADappointmentForm.value.Diet1,
      CAG: this.CADappointmentForm.value.CAG1
    };
    this.CADappointmentForm.value.CAD = this.CADreportObj;
    this.CADappointmentForm.value.FollowUpDate = this.CADappointmentForm.value.FollowUpDate1;

    this.CADappointmentForm.value.AppointmentID = this.appid;
    let result = this.apicalls.updateAppointmentsPrescription(this.CADappointmentForm.value)
      .subscribe(data => {
        this.spinner.show();
        //  this.data = data;
        if (data.type == true) {
          if (data.code == 'S001') {
            this.spinner.hide();
            alert(data.data)
            this.route.navigate(["/dashboardmain/TodaysAppointments"]);
          }
          if (data.code == 'S002') {
            this.spinner.hide();
            alert(data.data);
            this.isSubmitted = false;
            return false;
          } else if (data.code == "PD01") {
            alert("Permissions Denied");
            return;
          }
          else if (data.code == "UA01") {
            alert("Your UnAuthorized to Access this Service");
            return;
          }
        }
        else {
          this.spinner.hide();
          this.isSubmitted = false;
          alert(data.data)
          this.channelError = data.data;
          return false;
        }
      }, error => { alert(" Internal Server Error "); });
  }

  followupdatevalue;

  onSubmitHTN_NO_CAD_CHF(HTN_NO_CAD_CHFappointmentForm) {


    // let x = moment(this.HTN_NO_CAD_CHFappointmentForm.value.FollowUpDate2).format("DD-MM-YYYY");
    //   this.HTN_NO_CAD_CHFappointmentForm.value.FollowUpDate2 = x;

    // let x;
    // if (this.HTN_NO_CAD_CHFappointmentForm.value.FollowUpDate2 != "Invalid date" && this.HTN_NO_CAD_CHFappointmentForm.value.FollowUpDate2 != null && this.HTN_NO_CAD_CHFappointmentForm.value.FollowUpDate2 != "") {
    //   x = moment(this.HTN_NO_CAD_CHFappointmentForm.value.FollowUpDate2).format("DD-MM-YYYY");
    //   this.HTN_NO_CAD_CHFappointmentForm.value.FollowUpDate2 = x;
    // } else {
    //   x = "";
    //   this.HTN_NO_CAD_CHFappointmentForm.value.FollowUpDate2 = x;
    // }



    setTimeout(() => {
      this.spinner.hide();
    }, 5000);

    this.HTN_NO_CAD_CHFappointmentForm.value.Type = this.temptype;
    this.HTN_NO_CAD_CHFappointmentForm.value.RiskFactors2 = this.RiskFactorHTNriskFormArray;
    this.HTN_NO_CAD_CHFappointmentForm.value.CKD = this.ckdvalue;
    // this.HTN_NO_CAD_CHFappointmentForm.value.AdvicePostCAG=this.RiskFactorFormArrayAdviceCag;
    this.kidneyObj = {
      CREATININE_GFR: this.HTN_NO_CAD_CHFappointmentForm.value.CREATININE_GFR,
      PR_CR: this.HTN_NO_CAD_CHFappointmentForm.value.PR_CR,
      CKD: this.HTN_NO_CAD_CHFappointmentForm.value.CKD,
    };
    this.CVSObject = {
      LVH_ECG: this.HTN_NO_CAD_CHFappointmentForm.value.LVH_ECG,
      LV_MASS: this.HTN_NO_CAD_CHFappointmentForm.value.LV_MASS,
      LVEF: this.HTN_NO_CAD_CHFappointmentForm.value.LVEF,
    };

    this.HTN_NO_CAD_CHFreportObj = {
      RiskFactors: this.HTN_NO_CAD_CHFappointmentForm.value.RiskFactors2,
      Kidney: this.kidneyObj,
      CVS: this.CVSObject,
      HTN_Since: this.HTN_NO_CAD_CHFappointmentForm.value.HTN_Since,
      HTN_RETINOPATHY_OPTHAL_OPINION: this.HTN_RETINOPATHY_OPTHAL_OPINIONValue,
      OLD_CVA_OR_TIA: this.OLD_CVA_OR_TIAValue,
      ECHO: this.HTN_NO_CAD_CHFappointmentForm.value.ECHO,
      BP: this.HTN_NO_CAD_CHFappointmentForm.value.BP2,
      PR: this.HTN_NO_CAD_CHFappointmentForm.value.PR2,
      BGH: this.HTN_NO_CAD_CHFappointmentForm.value.BGH2,
      Notes: this.HTN_NO_CAD_CHFappointmentForm.value.Notes2,
      Investigations: this.HTN_NO_CAD_CHFappointmentForm.value.Investigations2,
      Diagnosis: this.HTN_NO_CAD_CHFappointmentForm.value.Diagnosis2,
      FollowUp: this.HTN_NO_CAD_CHFappointmentForm.value.FollowUp2,
      FollowUpDate: this.HTN_NO_CAD_CHFappointmentForm.value.FollowUpDate2,
      Prescription: this.HTN_NO_CAD_CHFappointmentForm.value.Prescription2,
      Diet: this.HTN_NO_CAD_CHFappointmentForm.value.Diet2,

    };
    this.HTN_NO_CAD_CHFappointmentForm.value.HTN_NO_CAD_CHF = this.HTN_NO_CAD_CHFreportObj;

    this.HTN_NO_CAD_CHFappointmentForm.value.FollowUpDate = this.HTN_NO_CAD_CHFappointmentForm.value.FollowUpDate2;
    this.HTN_NO_CAD_CHFappointmentForm.value.Kidney = this.kidneyObj;
    this.HTN_NO_CAD_CHFappointmentForm.value.CVS = this.CVSObject;
    // this.HTN_NO_CAD_CHFappointmentForm.value.AppointmentID=this.CVSObject;
    this.HTN_NO_CAD_CHFappointmentForm.value.AppointmentID = this.appid;


    let result = this.apicalls.updateAppointmentsPrescription(this.HTN_NO_CAD_CHFappointmentForm.value)
      .subscribe(data => {
        this.spinner.show();
        //  this.data = data;
        if (data.type == true) {
          if (data.code == 'S001') {
            this.spinner.hide();
            alert(data.data)
            this.route.navigate(["/dashboardmain/TodaysAppointments"]);
          }
          if (data.code == 'S002') {
            this.spinner.hide();
            alert(data.data);
            this.isSubmitted = false;
            return false;
          } else if (data.code == "PD01") {
            alert("Permissions Denied");
            return;
          }
          else if (data.code == "UA01") {
            alert("Your UnAuthorized to Access this Service");
            return;
          }
        }
        else {
          this.spinner.hide();
          this.isSubmitted = false;
          alert(data.data)
          return false;
        }
      }, error => { alert(" Internal Server Error "); });

  }

  get AppointmentID() {
    return this.appointmentForm.get('AppointmentID');
  }
  get CHF() {
    return this.appointmentForm.get('CHF') as FormArray;
  }
  get Type() {
    return this.appointmentForm.get('Type');
  }
  get BP() {
    return this.appointmentForm.get('BP');
  }
  get PR() {
    return this.appointmentForm.get('PR');
  }
  get BGH() {
    return this.appointmentForm.get('BGH');
  }
  get Notes() {
    return this.appointmentForm.get('Notes');
  }
  get Investigations() {
    return this.appointmentForm.get('Investigations');
  }
  get Diagnosis() {
    return this.appointmentForm.get('Diagnosis');
  }
  get FollowUp() {
    return this.appointmentForm.get('FollowUp');
  }
  get FollowUpDate() {
    return this.appointmentForm.get('FollowUpDate');
  }
  get Prescription() {
    return this.appointmentForm.get('Prescription');
  }
  get Diet() {
    return this.appointmentForm.get('Diet');
  }
  get CAG() {
    return this.appointmentForm.get('CAG');
  }
  get RiskFactors() {
    return this.appointmentForm.get('RiskFactors') as FormArray;
  }
  get HeartFailure() {
    return this.appointmentForm.get('HeartFailure') as FormArray;
  }
  get NYHA() {
    return this.appointmentForm.get('NYHA') as FormArray;
  }
  get Stage() {
    return this.appointmentForm.get('Stage') as FormArray;
  }
  get DIAGNOSIS() {
    return this.appointmentForm.get('DIAGNOSIS') as FormArray;
  }
  appointmentForm = new FormGroup({
    AppointmentID: new FormControl(''),
    Type: new FormControl('', [Validators.required]),
    CHF: new FormGroup({
      RiskFactors: new FormArray([
        // this.addRiskFactorsFormGroup()
      ]),
      HeartFailure: new FormArray([]),
      NYHA: new FormArray([]),
      Stage: new FormArray([]),
      DIAGNOSIS: new FormArray([]),
      BP: new FormControl(''),
      PR: new FormControl(''),
      BGH: new FormControl(''),
      Notes: new FormControl(''),
      Investigations: new FormControl(''),
      Diagnosis: new FormControl(''),
      FollowUp: new FormControl(''),
      FollowUpDate: new FormControl('', [Validators.required]),
      Prescription: new FormControl(''),
      Diet: new FormControl(''),
      CAG: new FormControl(''),

    }),

    RiskFactors: new FormArray([
      // this.addRiskFactorsFormGroup()
    ]),
    HeartFailure: new FormArray([]),
    NYHA: new FormArray([]),
    Stage: new FormArray([]),
    DIAGNOSIS: new FormArray([]),
    BP: new FormControl(''),
    PR: new FormControl(''),
    BGH: new FormControl(''),
    Notes: new FormControl(''),
    Investigations: new FormControl(''),
    Diagnosis: new FormControl(''),
    FollowUp: new FormControl(''),
    FollowUpDate: new FormControl(''),
    Prescription: new FormControl(''),
    Diet: new FormControl(''),
    CAG: new FormControl(''),


  });

  CADappointmentForm = new FormGroup({
    AppointmentID: new FormControl(''),
    Type: new FormControl('', [Validators.required]),
    CAD: new FormGroup({

      RiskFactors: new FormArray([]),
      APCLASS: new FormArray([]),
      CAG: new FormControl(''),
      AdvicePostCAG: new FormArray([]),
      PostCABG: new FormControl(''),
      PostCABGText: new FormControl(''),
      PostPTCA: new FormControl(''),
      PostPTCAText: new FormControl(''),
      BP: new FormControl(''),
      PR: new FormControl(''),
      BGH: new FormControl(''),
      Notes: new FormControl(''),
      Investigations: new FormControl(''),
      Diagnosis: new FormControl(''),
      FollowUp: new FormControl(''),
      FollowUpDate: new FormControl(''),
      Prescription: new FormControl(''),
      Diet: new FormControl(''),
    }),
    RiskFactors1: new FormArray([]),
    APCLASS: new FormArray([]),
    CAG1: new FormControl(''),
    AdvicePostCAG: new FormArray([]),
    PostCABG: new FormControl(''),
    PostCABGText: new FormControl(''),
    PostPTCA: new FormControl(''),
    PostPTCAText: new FormControl(''),
    BP1: new FormControl(''),
    PR1: new FormControl(''),
    BGH1: new FormControl(''),
    Notes1: new FormControl(''),
    Investigations1: new FormControl(''),
    Diagnosis1: new FormControl(''),
    FollowUp1: new FormControl(''),
    FollowUpDate1: new FormControl(''),
    Prescription1: new FormControl(''),
    Diet1: new FormControl(''),

  });

  get AppointmentID1() {
    return this.CADappointmentForm.get('AppointmentID1');
  }
  get CAD() {
    return this.CADappointmentForm.get('CAD') as FormArray;
  }
  get Type1() {
    return this.CADappointmentForm.get('Type1');
  }
  get PostCABG() {
    return this.CADappointmentForm.get('PostCABG');
  }
  get PostCABGText() {
    return this.CADappointmentForm.get('PostCABGText');
  }
  get PostPTCA() {
    return this.CADappointmentForm.get('PostPTCA');
  }
  get PostPTCAText() {
    return this.CADappointmentForm.get('PostCABG');
  }
  get BP1() {
    return this.CADappointmentForm.get('BP1');
  }
  get PR1() {
    return this.CADappointmentForm.get('PR1');
  }
  get BGH1() {
    return this.CADappointmentForm.get('BGH1');
  }
  get Notes1() {
    return this.CADappointmentForm.get('Notes1');
  }
  get Investigations1() {
    return this.CADappointmentForm.get('Investigations1');
  }
  get Diagnosis1() {
    return this.CADappointmentForm.get('Diagnosis1');
  }
  get FollowUp1() {
    return this.CADappointmentForm.get('FollowUp1');
  }
  get FollowUpDate1() {
    return this.CADappointmentForm.get('FollowUpDate1');
  }
  get Prescription1() {
    return this.CADappointmentForm.get('Prescription1');
  }
  get Diet1() {
    return this.CADappointmentForm.get('Diet1');
  }
  get CAG1() {
    return this.CADappointmentForm.get('CAG1');
  }
  get RiskFactors1() {
    return this.CADappointmentForm.get('RiskFactors1') as FormArray;
  }
  get APCLASS() {
    return this.CADappointmentForm.get('APCLASS') as FormArray;
  }
  get AdvicePostCAG() {
    return this.CADappointmentForm.get('AdvicePostCAG') as FormArray;
  }

  HTN_NO_CAD_CHFappointmentForm = new FormGroup({
    AppointmentID: new FormControl(''),
    Type: new FormControl('', [Validators.required]),
    FollowUpDate: new FormControl(''),
    HTN_NO_CAD_CHF: new FormGroup({
      CREATININE_GFR: new FormControl(''),
      PR_CR: new FormControl(''),
      CKD: new FormControl(''),
      LVH_ECG: new FormControl(''),
      LV_MASS: new FormControl(''),
      LVEF: new FormControl(''),
      RiskFactors: new FormArray([]),
      Kidney: new FormGroup({
        CREATININE_GFR: new FormControl(''),
        PR_CR: new FormControl(''),
        CKD: new FormControl(''),
      }),
      CVS: new FormGroup({
        LVH_ECG: new FormControl(''),
        LV_MASS: new FormControl(''),
        LVEF: new FormControl(''),
      }),
      HTN_Since: new FormControl(''),
      HTN_RETINOPATHY_OPTHAL_OPINION: new FormControl(''),
      OLD_CVA_OR_TIA: new FormControl(''),
      ECHO: new FormControl(''),
      BP: new FormControl(''),
      PR: new FormControl(''),
      BGH: new FormControl(''),
      Notes: new FormControl(''),
      Investigations: new FormControl(''),
      Diagnosis: new FormControl(''),
      FollowUp: new FormControl(''),
      FollowUpDate: new FormControl(''),
      Prescription: new FormControl(''),
      Diet: new FormControl(''),
    }),
    CREATININE_GFR: new FormControl(''),
    PR_CR: new FormControl(''),
    CKD: new FormControl(''),
    LVH_ECG: new FormControl(''),
    LV_MASS: new FormControl(''),
    LVEF: new FormControl(''),
    RiskFactors2: new FormArray([]),
    Kidney: new FormGroup({
      CREATININE_GFR: new FormControl(''),
      PR_CR: new FormControl(''),
      CKD: new FormControl(''),
    }),
    CVS: new FormGroup({
      LVH_ECG: new FormControl(''),
      LV_MASS: new FormControl(''),
      LVEF: new FormControl(''),
    }),
    HTN_Since: new FormControl(''),
    HTN_RETINOPATHY_OPTHAL_OPINION: new FormControl(''),
    OLD_CVA_OR_TIA: new FormControl(''),
    ECHO: new FormControl(''),
    BP2: new FormControl(''),
    PR2: new FormControl(''),
    BGH2: new FormControl(''),
    Notes2: new FormControl(''),
    Investigations2: new FormControl(''),
    Diagnosis2: new FormControl(''),
    FollowUp2: new FormControl(''),
    FollowUpDate2: new FormControl(''),
    Prescription2: new FormControl(''),
    Diet2: new FormControl('')
  });
  get AppointmentID2() {
    return this.HTN_NO_CAD_CHFappointmentForm.get('AppointmentID2');
  }
  get CREATININE_GFR() {
    return this.HTN_NO_CAD_CHFappointmentForm.get('CREATININE_GFR');
  }
  get PR_CR() {
    return this.HTN_NO_CAD_CHFappointmentForm.get('PR_CR');
  }
  get CKD() {
    return this.HTN_NO_CAD_CHFappointmentForm.get('CKD');
  }
  get LVH_ECG() {
    return this.HTN_NO_CAD_CHFappointmentForm.get('LVH_ECG');
  }
  get LV_MASS() {
    return this.HTN_NO_CAD_CHFappointmentForm.get('LV_MASS');
  }
  get LVEF() {
    return this.HTN_NO_CAD_CHFappointmentForm.get('LVEF');
  }
  get Type2() {
    return this.HTN_NO_CAD_CHFappointmentForm.get('Type2');
  }
  get HTN_Since() {
    return this.HTN_NO_CAD_CHFappointmentForm.get('HTN_Since');
  }
  get HTN_RETINOPATHY_OPTHAL_OPINION() {
    return this.HTN_NO_CAD_CHFappointmentForm.get('HTN_RETINOPATHY_OPTHAL_OPINION');
  }
  get OLD_CVA_OR_TIA() {
    return this.HTN_NO_CAD_CHFappointmentForm.get('OLD_CVA_OR_TIA');
  }
  get ECHO() {
    return this.HTN_NO_CAD_CHFappointmentForm.get('ECHO');
  }
  get Kidney() {
    return this.HTN_NO_CAD_CHFappointmentForm.get('Kidney') as FormArray;
  }
  get CVS() {
    return this.HTN_NO_CAD_CHFappointmentForm.get('CVS') as FormArray;
  }
  get RiskFactors2() {
    return this.HTN_NO_CAD_CHFappointmentForm.get('RiskFactors2') as FormArray;
  }
  get BP2() {
    return this.HTN_NO_CAD_CHFappointmentForm.get('BP2');
  }
  get PR2() {
    return this.HTN_NO_CAD_CHFappointmentForm.get('PR2');
  }
  get BGH2() {
    return this.HTN_NO_CAD_CHFappointmentForm.get('BGH2');
  }
  get Notes2() {
    return this.HTN_NO_CAD_CHFappointmentForm.get('Notes2');
  }
  get Investigations2() {
    return this.HTN_NO_CAD_CHFappointmentForm.get('Investigations2');
  }
  get Diagnosis2() {
    return this.HTN_NO_CAD_CHFappointmentForm.get('Diagnosis2');
  }
  get FollowUp2() {
    return this.HTN_NO_CAD_CHFappointmentForm.get('FollowUp2');
  }
  get FollowUpDate2() {
    return this.HTN_NO_CAD_CHFappointmentForm.get('FollowUpDate2');
  }
  get Prescription2() {
    return this.HTN_NO_CAD_CHFappointmentForm.get('Prescription2');
  }
  get Diet2() {
    return this.HTN_NO_CAD_CHFappointmentForm.get('Diet2');
  }
  DMArray1 = [];
  onChangeDMrisk(list, isChecked: boolean) {

    if (isChecked == true) {
      this.DMArray1.push(list);
    } else {
      const index = this.DMArray1.indexOf(list);
      if (index > -1) {
        this.DMArray1.splice(index, 1);
      }
    }
    this.RiskFactorDMriskFormArray = Array.from(new Set(this.DMArray1))

  }
  onChangeckdDM(list, isChecked: boolean) {

    if (isChecked == true) {

      this.cdkDMFormValue = list;
    } else {
      this.cdkDMFormValue = ""
    }

  }
  dmHTN_RETINOPATHYValue;
  OLD_CVA_OR_TIAval;
  DM_NO_CAD_CHFreportObj = {};
  onChangeHTN_RETINOPATHY_OPTHAL_OPINION1(list, isChecked: boolean) {

    if (isChecked == true) {

      this.dmHTN_RETINOPATHYValue = list;
    } else {
      this.dmHTN_RETINOPATHYValue = ""
    }

  }

  onChangeOLD_CVA_OR_TIA1(list, isChecked: boolean) {

    if (isChecked == true) {
      this.OLD_CVA_OR_TIAvalue = list;
    } else {
      this.OLD_CVA_OR_TIAvalue = "";
    }

  }
  onSubmitDM_NO_CAD_CHF(DM_NO_CAD_CHFappointmentForm) {


    //  let x;
    // if (this.DM_NO_CAD_CHFappointmentForm.value.FollowUpDate3 != "Invalid date" && this.DM_NO_CAD_CHFappointmentForm.value.FollowUpDate3 != null && this.DM_NO_CAD_CHFappointmentForm.value.FollowUpDate3 != "") {
    //   x = moment(this.DM_NO_CAD_CHFappointmentForm.value.FollowUpDate3).format("DD-MM-YYYY");
    //   this.DM_NO_CAD_CHFappointmentForm.value.FollowUpDate3 = x;
    // } else {
    //   x = "";
    //   this.DM_NO_CAD_CHFappointmentForm.value.FollowUpDate3 = x;
    // }

    setTimeout(() => {
      this.spinner.hide();
    }, 5000);

    this.DM_NO_CAD_CHFappointmentForm.value.Type = this.temptype;
    this.DM_NO_CAD_CHFappointmentForm.value.RiskFactors3 = this.RiskFactorDMriskFormArray;
    this.DM_NO_CAD_CHFappointmentForm.value.CKD1 = this.cdkDMFormValue;
    this.kidneyObj = {
      CREATININE_GFR: this.DM_NO_CAD_CHFappointmentForm.value.CREATININE_GFR1,
      PR_CR: this.DM_NO_CAD_CHFappointmentForm.value.PR_CR1,
      CKD: this.DM_NO_CAD_CHFappointmentForm.value.CKD1,
    };
    this.CVSObject = {
      LVH_ECG: this.DM_NO_CAD_CHFappointmentForm.value.LVH_ECG1,
      LV_MASS: this.DM_NO_CAD_CHFappointmentForm.value.LV_MASS1,
      LVEF: this.DM_NO_CAD_CHFappointmentForm.value.LVEF1,


    };
    this.DM_NO_CAD_CHFreportObj = {
      RiskFactors: this.DM_NO_CAD_CHFappointmentForm.value.RiskFactors3,
      Kidney: this.kidneyObj,
      CVS: this.CVSObject,
      DM_Since: this.DM_NO_CAD_CHFappointmentForm.value.DM_Since,
      HTN_RETINOPATHY_OPTHAL_OPINION: this.dmHTN_RETINOPATHYValue,
      OLD_CVA_OR_TIA: this.OLD_CVA_OR_TIAvalue,
      ECHO: this.DM_NO_CAD_CHFappointmentForm.value.ECHO1,
      BMI: this.DM_NO_CAD_CHFappointmentForm.value.BMI,
      HBA1C: this.DM_NO_CAD_CHFappointmentForm.value.HBA1C,
      BP: this.DM_NO_CAD_CHFappointmentForm.value.BP3,
      PR: this.DM_NO_CAD_CHFappointmentForm.value.PR3,
      BGH: this.DM_NO_CAD_CHFappointmentForm.value.BGH3,
      Notes: this.DM_NO_CAD_CHFappointmentForm.value.Notes3,
      Investigations: this.DM_NO_CAD_CHFappointmentForm.value.Investigations3,
      Diagnosis: this.DM_NO_CAD_CHFappointmentForm.value.Diagnosis3,
      FollowUp: this.DM_NO_CAD_CHFappointmentForm.value.FollowUp3,
      FollowUpDate: this.DM_NO_CAD_CHFappointmentForm.value.FollowUpDate3,
      Prescription: this.DM_NO_CAD_CHFappointmentForm.value.Prescription3,
      Diet: this.DM_NO_CAD_CHFappointmentForm.value.Diet3,

    };
    this.DM_NO_CAD_CHFappointmentForm.value.DM_NO_CAD_CHF = this.DM_NO_CAD_CHFreportObj;

    this.DM_NO_CAD_CHFappointmentForm.value.FollowUpDate = this.DM_NO_CAD_CHFappointmentForm.value.FollowUpDate3;
    this.DM_NO_CAD_CHFappointmentForm.value.Kidney = this.kidneyObj;
    this.DM_NO_CAD_CHFappointmentForm.value.CVS = this.CVSObject;
    this.DM_NO_CAD_CHFappointmentForm.value.AppointmentID = this.appid;
    let result = this.apicalls.updateAppointmentsPrescription(this.DM_NO_CAD_CHFappointmentForm.value)
      .subscribe(data => {
        this.spinner.show();
        //  this.data = data;
        if (data.type == true) {


          if (data.code == 'S001') {
            this.spinner.hide();
            alert(data.data)
            this.route.navigate(["/dashboardmain/TodaysAppointments"]);
          }

          if (data.code == 'S002') {
            this.spinner.hide();
            alert(data.data);
            this.isSubmitted = false;
            return false;

          }
          else if (data.code == "PD01") {
            alert("Permissions Denied");
            return;
          }
          else if (data.code == "UA01") {
            alert("Your UnAuthorized to Access this Service");
            return;
          }

        }
        else {
          this.spinner.hide();
          this.isSubmitted = false;
          alert(data.data)
          return false;
          //  alert( data.data)

        }

        // }
      },
        error => { alert(" Internal Server Error "); }
      );

  }

  DM_NO_CAD_CHFappointmentForm = new FormGroup({
    AppointmentID: new FormControl(''),
    Type: new FormControl('', [Validators.required]),
    FollowUpDate: new FormControl(''),
    DM_NO_CAD_CHF: new FormGroup({
      CREATININE_GFR: new FormControl(''),
      PR_CR: new FormControl(''),
      CKD: new FormControl(''),
      LVH_ECG: new FormControl(''),
      LV_MASS: new FormControl(''),
      LVEF: new FormControl(''),
      RiskFactors: new FormArray([]),
      Kidney: new FormGroup({
        CREATININE_GFR: new FormControl(''),
        PR_CR: new FormControl(''),
        CKD: new FormControl(''),
      }),
      CVS: new FormGroup({
        LVH_ECG: new FormControl(''),
        LV_MASS: new FormControl(''),
        LVEF: new FormControl(''),
      }),
      BMI: new FormControl(''),
      HBA1C: new FormControl(''),
      DM_Since: new FormControl(''),
      HTN_RETINOPATHY_OPTHAL_OPINION: new FormControl(''),
      OLD_CVA_OR_TIA: new FormControl(''),
      ECHO: new FormControl(''),
      BP: new FormControl(''),
      PR: new FormControl(''),
      BGH: new FormControl(''),
      Notes: new FormControl(''),
      Investigations: new FormControl(''),
      Diagnosis: new FormControl(''),
      FollowUp: new FormControl(''),
      FollowUpDate: new FormControl(''),
      Prescription: new FormControl(''),
      Diet: new FormControl(''),
    }),
    CREATININE_GFR1: new FormControl(''),
    PR_CR1: new FormControl(''),
    CKD1: new FormControl(''),
    LVH_ECG1: new FormControl(''),
    LV_MASS1: new FormControl(''),
    LVEF1: new FormControl(''),
    RiskFactors3: new FormArray([]),
    Kidney: new FormGroup({
      CREATININE_GFR: new FormControl(''),
      PR_CR: new FormControl(''),
      CKD: new FormControl(''),
    }),
    CVS: new FormGroup({
      LVH_ECG: new FormControl(''),
      LV_MASS: new FormControl(''),
      LVEF: new FormControl(''),
    }),
    DM_Since: new FormControl(''),
    HTN_RETINOPATHY_OPTHAL_OPINION1: new FormControl(''),
    OLD_CVA_OR_TIA1: new FormControl(''),
    ECHO1: new FormControl(''),
    BP3: new FormControl(''),
    PR3: new FormControl(''),
    BGH3: new FormControl(''),
    Notes3: new FormControl(''),
    Investigations3: new FormControl(''),
    Diagnosis3: new FormControl(''),
    FollowUp3: new FormControl(''),
    FollowUpDate3: new FormControl(''),
    Prescription3: new FormControl(''),
    Diet3: new FormControl(''),
    BMI: new FormControl(''),
    HBA1C: new FormControl(''),

  });
  // get AppointmentID() {
  //   return this.DM_NO_CAD_CHFappointmentForm.get('AppointmentID');
  // }
  get AppointmentID3() {
    return this.DM_NO_CAD_CHFappointmentForm.get('AppointmentID3');
  }
  get BMI() {
    return this.DM_NO_CAD_CHFappointmentForm.get('BMI');
  }
  get HBA1C() {
    return this.DM_NO_CAD_CHFappointmentForm.get('HBA1C');
  }
  get CREATININE_GFR1() {
    return this.DM_NO_CAD_CHFappointmentForm.get('CREATININE_GFR1');
  }
  get PR_CR1() {
    return this.DM_NO_CAD_CHFappointmentForm.get('PR_CR1');
  }
  get CKD1() {
    return this.DM_NO_CAD_CHFappointmentForm.get('CKD1');
  }
  get LVH_ECG1() {
    return this.DM_NO_CAD_CHFappointmentForm.get('LVH_ECG1');
  }
  get LV_MASS1() {
    return this.DM_NO_CAD_CHFappointmentForm.get('LV_MASS1');
  }
  get LVEF1() {
    return this.DM_NO_CAD_CHFappointmentForm.get('LVEF1');
  }
  get DM_Since() {
    return this.DM_NO_CAD_CHFappointmentForm.get('DM_Since');
  }
  get HTN_RETINOPATHY_OPTHAL_OPINION1() {
    return this.DM_NO_CAD_CHFappointmentForm.get('HTN_RETINOPATHY_OPTHAL_OPINION1');
  }
  get OLD_CVA_OR_TIA1() {
    return this.DM_NO_CAD_CHFappointmentForm.get('OLD_CVA_OR_TIA1');
  }
  get ECHO1() {
    return this.DM_NO_CAD_CHFappointmentForm.get('ECHO1');
  }
  get Kidney1() {
    return this.DM_NO_CAD_CHFappointmentForm.get('Kidney1') as FormArray;
  }
  get CVS1() {
    return this.DM_NO_CAD_CHFappointmentForm.get('CVS1') as FormArray;
  }
  get RiskFactors3() {
    return this.DM_NO_CAD_CHFappointmentForm.get('RiskFactors3') as FormArray;
  }
  get BP3() {
    return this.DM_NO_CAD_CHFappointmentForm.get('BP3');
  }
  get PR3() {
    return this.DM_NO_CAD_CHFappointmentForm.get('PR3');
  }
  get BGH3() {
    return this.DM_NO_CAD_CHFappointmentForm.get('BGH3');
  }
  get Notes3() {
    return this.DM_NO_CAD_CHFappointmentForm.get('Notes3');
  }
  get Investigations3() {
    return this.DM_NO_CAD_CHFappointmentForm.get('Investigations3');
  }
  get Diagnosis3() {
    return this.DM_NO_CAD_CHFappointmentForm.get('Diagnosis3');
  }
  get FollowUp3() {
    return this.DM_NO_CAD_CHFappointmentForm.get('FollowUp3');
  }
  get FollowUpDate3() {
    return this.DM_NO_CAD_CHFappointmentForm.get('FollowUpDate3');
  }
  get Prescription3() {
    return this.DM_NO_CAD_CHFappointmentForm.get('Prescription3');
  }
  get Diet3() {
    return this.DM_NO_CAD_CHFappointmentForm.get('Diet3');
  }

  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }
  doctorsdetails;
  onOptionsSelected(value: string) {


  }

  HCMappointmentForm = new FormGroup({
    AppointmentID: new FormControl(''),
    Type: new FormControl('', [Validators.required]),
    FollowUpDate: new FormControl(''),
    CREATININE_GFR2: new FormControl(''),
    DIAGNOSED_IN: new FormControl(''),
    GENE_STUDY: new FormControl(''),
    SYMTOM_STATUS: new FormControl(''),
    HCM: new FormGroup({
      CREATININE_GFR: new FormControl(''),
      GENE_STUDY: new FormControl(''),
      SYMTOM_STATUS: new FormControl(''),
      LVH_ECG: new FormControl(''),
      LV_MASS: new FormControl(''),
      LVEF: new FormControl(''),
      RiskFactors: new FormArray([]),

      CVS: new FormGroup({
        LVH_ECG: new FormControl(''),
        LV_MASS: new FormControl(''),
        LVEF: new FormControl(''),
      }),
      DIAGNOSED_IN: new FormControl(''),
      ECHO: new FormControl(''),
      BP: new FormControl(''),
      PR: new FormControl(''),
      BGH: new FormControl(''),
      Notes: new FormControl(''),
      Investigations: new FormControl(''),
      Diagnosis: new FormControl(''),
      FollowUp: new FormControl(''),
      FollowUpDate: new FormControl(''),
      Prescription: new FormControl(''),
      Diet: new FormControl(''),
    }),
    LVH_ECG2: new FormControl(''),
    LV_MASS2: new FormControl(''),
    LVEF2: new FormControl(''),
    RiskFactors4: new FormArray([]),

    CVS: new FormGroup({
      LVH_ECG: new FormControl(''),
      LV_MASS: new FormControl(''),
      LVEF: new FormControl(''),
    }),

    ECHO2: new FormControl(''),
    BP4: new FormControl(''),
    PR4: new FormControl(''),
    BGH4: new FormControl(''),
    Notes4: new FormControl(''),
    Investigations4: new FormControl(''),
    Diagnosis4: new FormControl(''),
    FollowUp4: new FormControl(''),
    FollowUpDate4: new FormControl(''),
    Prescription4: new FormControl(''),
    Diet4: new FormControl(''),

  });

  get GENE_STUDY() {
    return this.HCMappointmentForm.get('GENE_STUDY');
  }

  get SYMTOM_STATUS() {
    return this.HCMappointmentForm.get('SYMTOM_STATUS');
  }
  get CREATININE_GFR2() {
    return this.HCMappointmentForm.get('CREATININE_GFR2');
  }

  get LVH_ECG2() {
    return this.HCMappointmentForm.get('LVH_ECG2');
  }
  get LV_MASS2() {
    return this.HCMappointmentForm.get('LV_MASS2');
  }
  get LVEF2() {
    return this.HCMappointmentForm.get('LVEF2');
  }


  get ECHO2() {
    return this.HCMappointmentForm.get('ECHO2');
  }

  get CVS2() {
    return this.HCMappointmentForm.get('CVS2') as FormArray;
  }
  get RiskFactors4() {
    return this.HCMappointmentForm.get('RiskFactors4') as FormArray;
  }
  get BP4() {
    return this.HCMappointmentForm.get('BP4');
  }
  get PR4() {
    return this.HCMappointmentForm.get('PR4');
  }
  get BGH4() {
    return this.HCMappointmentForm.get('BGH4');
  }
  get Notes4() {
    return this.HCMappointmentForm.get('Notes4');
  }
  get Investigations4() {
    return this.HCMappointmentForm.get('Investigations4');
  }
  get Diagnosis4() {
    return this.HCMappointmentForm.get('Diagnosis4');
  }
  get FollowUp4() {
    return this.HCMappointmentForm.get('FollowUp4');
  }
  get FollowUpDate4() {
    return this.HCMappointmentForm.get('FollowUpDate4');
  }
  get Prescription4() {
    return this.HCMappointmentForm.get('Prescription4');
  }
  get Diet4() {
    return this.HCMappointmentForm.get('Diet4');
  }
  get DIAGNOSED_IN() {
    return this.HCMappointmentForm.get('DIAGNOSED_IN');
  }
  HCMRiskFactoryArray = [];
  HCMGenestudyArrayValue;
  HCMArray1 = [];
  onChangeHCMRisk(list, isChecked: boolean) {

    if (isChecked == true) {
      this.HCMArray1.push(list);
    } else {
      const index = this.HCMArray1.indexOf(list);
      if (index > -1) {
        this.HCMArray1.splice(index, 1);
      }
    }

    this.HCMRiskFactoryArray = Array.from(new Set(this.HCMArray1))

  }
  onChangeGENESTUDY(list, isChecked: boolean) {

    if (isChecked == true) {

      this.HCMGenestudyArrayValue = list
    } else {
      this.HCMGenestudyArrayValue = ""
    }

  }
  onSubmitHCM(HCMappointmentForm) {

    // if(this.HCMappointmentForm.value.FollowUpDate4){
    //   let x = moment(this.HCMappointmentForm.value.FollowUpDate4).format("DD-MM-YYYY");
    //   this.HCMappointmentForm.value.FollowUpDate4=x;

    // }

    // let x;
    // if (this.HCMappointmentForm.value.FollowUpDate4 != "Invalid date" && this.HCMappointmentForm.value.FollowUpDate4 != null && this.HCMappointmentForm.value.FollowUpDate4 != "") {
    //   x = moment(this.HCMappointmentForm.value.FollowUpDate4).format("DD-MM-YYYY");
    //   this.HCMappointmentForm.value.FollowUpDate4 = x;
    // } else {
    //   x = "";
    //   this.HCMappointmentForm.value.FollowUpDate4 = x;
    // }
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);

    this.HCMappointmentForm.value.Type = this.temptype;
    this.HCMappointmentForm.value.RiskFactors4 = this.HCMRiskFactoryArray;

    this.CVSObject = {
      LVH_ECG: this.HCMappointmentForm.value.LVH_ECG2,
      LV_MASS: this.HCMappointmentForm.value.LV_MASS2,
      LVEF: this.HCMappointmentForm.value.LVEF2,


    };

    this.HCMreportObj = {
      RiskFactors: this.HCMappointmentForm.value.RiskFactors4,
      CVS: this.CVSObject,
      CREATININE_GFR: this.HCMappointmentForm.value.CREATININE_GFR2,
      DIAGNOSED_IN: this.HCMappointmentForm.value.DIAGNOSED_IN,
      GENE_STUDY: this.HCMGenestudyArrayValue,
      SYMTOM_STATUS: this.HCMappointmentForm.value.SYMTOM_STATUS,
      ECHO: this.HCMappointmentForm.value.ECHO2,

      BP: this.HCMappointmentForm.value.BP4,
      PR: this.HCMappointmentForm.value.PR4,
      BGH: this.HCMappointmentForm.value.BGH4,
      Notes: this.HCMappointmentForm.value.Notes4,
      Investigations: this.HCMappointmentForm.value.Investigations4,
      Diagnosis: this.HCMappointmentForm.value.Diagnosis4,
      FollowUp: this.HCMappointmentForm.value.FollowUp4,
      FollowUpDate: this.HCMappointmentForm.value.FollowUpDate4,
      Prescription: this.HCMappointmentForm.value.Prescription4,
      Diet: this.HCMappointmentForm.value.Diet4,

    };
    this.HCMappointmentForm.value.HCM = this.HCMreportObj;

    this.HCMappointmentForm.value.FollowUpDate = this.HCMappointmentForm.value.FollowUpDate4;
    this.HCMappointmentForm.value.CVS = this.CVSObject;
    this.HCMappointmentForm.value.AppointmentID = this.appid;
    let result = this.apicalls.updateAppointmentsPrescription(this.HCMappointmentForm.value)
      .subscribe(data => {
        this.spinner.show();
        //  this.data = data;
        if (data.type == true) {


          if (data.code == 'S001') {
            this.spinner.hide();
            alert(data.data)
            this.route.navigate(["/dashboardmain/TodaysAppointments"]);
          }

          if (data.code == 'S002') {
            this.spinner.hide();
            alert(data.data);
            this.isSubmitted = false;
            return false;

          }
          else if (data.code == "PD01") {
            alert("Permissions Denied");
            return;
          }
          else if (data.code == "UA01") {
            alert("Your UnAuthorized to Access this Service");
            return;
          }
        }
        else {

          this.spinner.hide();
          this.isSubmitted = false;
          alert(data.data)
          return false;

        }
      },
        error => { alert(" Internal Server Error "); }
      );

  }
  PAHRiskArray = [];
  PAHClassArray = [];
  PAHCOMPANSATEDValue;
  PAHDECOMPANSATEDValue;
  PAHDiagnosisArray = [];
  PAHArray1 = [];
  PAHArray2 = [];
  PAHArray3 = [];


  onChangePAHRisk(list, isChecked: boolean) {

    if (isChecked == true) {
      this.PAHArray1.push(list);
    } else {
      const index = this.PAHArray1.indexOf(list);
      if (index > -1) {
        this.PAHArray1.splice(index, 1);
      }
    }

    this.PAHRiskArray = Array.from(new Set(this.PAHArray1))

  }
  onChangePAHClass(list, isChecked: boolean) {

    if (isChecked == true) {
      this.PAHArray2.push(list);
    } else {
      const index = this.PAHArray2.indexOf(list);
      if (index > -1) {
        this.PAHArray2.splice(index, 1);
      }
    }
    this.PAHClassArray = Array.from(new Set(this.PAHArray2))

  }
  onChangePAHCOMPANSATED(list, isChecked: boolean) {

    if (isChecked == true) {

      this.PAHCOMPANSATEDValue = list;
    }
    else {
      this.PAHCOMPANSATEDValue = ""
    }

  }
  onChangePAHDECOMPANSATED(list, isChecked: boolean) {

    if (isChecked == true) {

      this.PAHDECOMPANSATEDValue = list;
    } else {
      this.PAHDECOMPANSATEDValue = ""
    }

  }
  onChangePAHDIAGNOSIS(list, isChecked: boolean) {

    if (isChecked == true) {
      this.PAHArray3.push(list);
    } else {
      const index = this.PAHArray3.indexOf(list);
      if (index > -1) {
        this.PAHArray3.splice(index, 1);
      }
    }
    this.PAHDiagnosisArray = Array.from(new Set(this.PAHArray3))

  }
  get RiskFactors5() {
    return this.PAHappointmentForm.get('RiskFactors5') as FormArray;
  }
  get BP5() {
    return this.PAHappointmentForm.get('BP5');
  }
  get PR5() {
    return this.PAHappointmentForm.get('PR5');
  }
  get BGH5() {
    return this.PAHappointmentForm.get('BGH5');
  }
  get Notes5() {
    return this.PAHappointmentForm.get('Notes5');
  }
  get Investigations5() {
    return this.PAHappointmentForm.get('Investigations5');
  }
  get Diagnosis5() {
    return this.PAHappointmentForm.get('Diagnosis5');
  }
  get FollowUp5() {
    return this.PAHappointmentForm.get('FollowUp5');
  }
  get FollowUpDate5() {
    return this.PAHappointmentForm.get('FollowUpDate5');
  }
  get Prescription5() {
    return this.PAHappointmentForm.get('Prescription5');
  }
  get Diet5() {
    return this.PAHappointmentForm.get('Diet5');
  }
  get DIAGNOSIS1() {
    return this.PAHappointmentForm.get('DIAGNOSIS1') as FormArray;
  }
  // get DIAGNOSIS1() {
  //   return this.HCMappointmentForm.get('DIAGNOSIS1')as FormArray ;
  //   }

  get EF() {
    return this.PAHappointmentForm.get('EF');
  }
  get COMPANSATED() {
    return this.PAHappointmentForm.get('COMPANSATED');
  }
  get DECOMPANSATED() {
    return this.PAHappointmentForm.get('DECOMPANSATED');
  }
  get PASP() {
    return this.PAHappointmentForm.get('PASP');
  }
  get PADP() {
    return this.PAHappointmentForm.get('PADP');
  }
  get RV_FUNCTION() {
    return this.PAHappointmentForm.get('RV_FUNCTION');
  }

  onSubmitPAH(PAHappointmentForm) {

    //this.followupdatevalue = moment(this.PAHappointmentForm.value.FollowUpDate5).format("DD-MM-YYYY");

    let x;
    if (this.PAHappointmentForm.value.FollowUpDate5 != "Invalid date" && this.PAHappointmentForm.value.FollowUpDate5 != null && this.PAHappointmentForm.value.FollowUpDate5 != "") {
      x = moment(this.PAHappointmentForm.value.FollowUpDate5).format("DD-MM-YYYY");
      this.PAHappointmentForm.value.FollowUpDate5 = x;
    } else {
      x = "";
      this.PAHappointmentForm.value.FollowUpDate5 = x;
    }
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);

    this.PAHappointmentForm.value.Type = this.temptype;
    this.PAHappointmentForm.value.RiskFactors5 = this.PAHRiskArray;


    this.PAHreportObj = {
      RiskFactors: this.PAHappointmentForm.value.RiskFactors5,
      Class: this.PAHClassArray,
      COMPANSATED: this.PAHCOMPANSATEDValue,
      DECOMPANSATED: this.PAHDECOMPANSATEDValue,

      EF: this.PAHappointmentForm.value.EF,
      PASP: this.PAHappointmentForm.value.PASP,
      PADP: this.PAHappointmentForm.value.PADP,
      RV_FUNCTION: this.PAHappointmentForm.value.RV_FUNCTION,
      DIAGNOSIS: this.PAHDiagnosisArray,

      BP: this.PAHappointmentForm.value.BP5,
      PR: this.PAHappointmentForm.value.PR5,
      BGH: this.PAHappointmentForm.value.BGH5,
      Notes: this.PAHappointmentForm.value.Notes5,
      Investigations: this.PAHappointmentForm.value.Investigations5,
      Diagnosis: this.PAHappointmentForm.value.Diagnosis5,
      FollowUp: this.PAHappointmentForm.value.FollowUp5,
      FollowUpDate: this.PAHappointmentForm.value.FollowUpDate5,
      Prescription: this.PAHappointmentForm.value.Prescription5,
      Diet: this.PAHappointmentForm.value.Diet5,

    };
    this.PAHappointmentForm.value.PAH = this.PAHreportObj;

    this.PAHappointmentForm.value.FollowUpDate = this.PAHappointmentForm.value.FollowUpDate5;
    this.PAHappointmentForm.value.AppointmentID = this.appid;
    let result = this.apicalls.updateAppointmentsPrescription(this.PAHappointmentForm.value)
      .subscribe(data => {
        this.spinner.show();
        //  this.data = data;
        if (data.type == true) {


          if (data.code == 'S001') {
            this.spinner.hide();
            alert(data.data)
            this.route.navigate(["/dashboardmain/TodaysAppointments"]);
          }

          if (data.code == 'S002') {
            this.spinner.hide();
            alert(data.data);
            this.isSubmitted = false;
            return false;

          }
          else if (data.code == "PD01") {
            alert("Permissions Denied");
            return;
          }
          else if (data.code == "UA01") {
            alert("Your UnAuthorized to Access this Service");
            return;
          }
        }
        else {
          this.spinner.hide();
          this.isSubmitted = false;
          alert(data.data)
          return false;
        }
      },
        error => { alert(" Internal Server Error "); }
      );

  }
  PAHappointmentForm = new FormGroup({

    AppointmentID: new FormControl(''),
    Type: new FormControl('', [Validators.required]),
    FollowUpDate: new FormControl(''),
    PAH: new FormGroup({
      RiskFactors: new FormArray([]),
      Class: new FormArray([]),
      COMPANSATED: new FormControl(''),
      DECOMPANSATED: new FormControl(''),
      EF: new FormControl(''),
      PASP: new FormControl(''),
      PADP: new FormControl(''),
      RV_FUNCTION: new FormControl(''),
      DIAGNOSIS: new FormArray([]),
      BP: new FormControl(''),
      PR: new FormControl(''),
      BGH: new FormControl(''),
      Notes: new FormControl(''),
      Investigations: new FormControl(''),
      Diagnosis: new FormControl(''),
      FollowUp: new FormControl(''),
      FollowUpDate: new FormControl(''),
      Prescription: new FormControl(''),
      Diet: new FormControl(''),
    }),
    RiskFactors5: new FormArray([]),
    Class: new FormArray([]),
    COMPANSATED: new FormControl(''),
    DECOMPANSATED: new FormControl(''),
    EF: new FormControl(''),
    PASP: new FormControl(''),
    PADP: new FormControl(''),
    RV_FUNCTION: new FormControl(''),
    DIAGNOSIS1: new FormArray([]),
    BP5: new FormControl(''),
    PR5: new FormControl(''),
    BGH5: new FormControl(''),
    Notes5: new FormControl(''),
    Investigations5: new FormControl(''),
    Diagnosis5: new FormControl(''),
    FollowUp5: new FormControl(''),
    Prescription5: new FormControl(''),
    Diet5: new FormControl(''),
    FollowUpDate5: new FormControl('')

  });


  CRHDreportObj = {};
  onSubmitCRHD(CRHDappointmentForm) {
    // this.followupdatevalue = moment(this.CRHDappointmentForm.value.FollowUpDate6).format("DD-MM-YYYY");

    // let x;
    // if (this.CRHDappointmentForm.value.FollowUpDate6 != "Invalid date" && this.CRHDappointmentForm.value.FollowUpDate6 != null && this.CRHDappointmentForm.value.FollowUpDate6 != "") {
    //   x = moment(this.CRHDappointmentForm.value.FollowUpDate6).format("DD-MM-YYYY");
    //   this.CRHDappointmentForm.value.FollowUpDate6 = x;
    // } else {
    //   x = "";
    //   this.CRHDappointmentForm.value.FollowUpDate6 = x;
    // }

    setTimeout(() => {
      this.spinner.hide();
    }, 5000);

    this.CRHDappointmentForm.value.Type = this.temptype;
    this.CRHDappointmentForm.value.RiskFactors6 = this.CRHDArray;
    this.CRHDreportObj = {
      RiskFactors: this.CRHDappointmentForm.value.RiskFactors6,
      CRHD_SINCE: this.CRHDappointmentForm.value.CRHD_SINCE,
      MITRAL_STENOSIS: this.CRHDappointmentForm.value.MITRAL_STENOSIS,

      MITRAL_REGURGITATION: this.CRHDappointmentForm.value.MITRAL_REGURGITATION,
      AORTIC_STENOSIS: this.CRHDappointmentForm.value.AORTIC_STENOSIS,
      AORTIC_REGURGITATION: this.CRHDappointmentForm.value.AORTIC_REGURGITATION,
      TRICUSPID_STENOSIS: this.CRHDappointmentForm.value.TRICUSPID_STENOSIS,


      TRICUSPID_REGURGITATION: this.CRHDappointmentForm.value.TRICUSPID_REGURGITATION,
      PULMONARY_STENOSIS: this.CRHDappointmentForm.value.PULMONARY_STENOSIS,
      PULMONARY_REGURGITATION: this.CRHDappointmentForm.value.PULMONARY_REGURGITATION,
      PAH: this.CRHDappointmentForm.value.PAH,
      LVEF: this.CRHDappointmentForm.value.LVEF6,
      LVEDD: this.CRHDappointmentForm.value.LVEDD,
      LVESD: this.CRHDappointmentForm.value.LVESD,
      RV_FUNCTION: this.CRHDappointmentForm.value.RV_FUNCTION6,
      OLD_CVA_OR_TIA_CAROTID: this.CRHDCarotidValue,
      NYHS: this.CRHDnyhaArray,
      SINUS_RYTHM: this.CRHDSinusVAlue,
      AF: this.CRHDafValue,
      HO_IE: this.CRHDHOValue,
      BP: this.CRHDappointmentForm.value.BP6,
      PR: this.CRHDappointmentForm.value.PR6,
      BGH: this.CRHDappointmentForm.value.BGH6,
      ECHO: this.CRHDappointmentForm.value.ECHO6,
      Notes: this.CRHDappointmentForm.value.Notes6,
      Investigations: this.CRHDappointmentForm.value.Investigations6,
      Diagnosis: this.CRHDappointmentForm.value.Diagnosis6,
      FollowUp: this.CRHDappointmentForm.value.FollowUp6,
      FollowUpDate: CRHDappointmentForm.value.FollowUpDate6,
      Prescription: this.CRHDappointmentForm.value.Prescription6,
      Diet: this.CRHDappointmentForm.value.Diet6,

    };
    this.CRHDappointmentForm.value.CRHD = this.CRHDreportObj;

    this.CRHDappointmentForm.value.FollowUpDate = CRHDappointmentForm.value.FollowUpDate6;
    this.CRHDappointmentForm.value.AppointmentID = this.appid;

    let result = this.apicalls.updateAppointmentsPrescription(this.CRHDappointmentForm.value)
      .subscribe(data => {
        this.spinner.show();
        //  this.data = data;
        if (data.type == true) {


          if (data.code == 'S001') {
            this.spinner.hide();
            alert(data.data)
            this.route.navigate(["/dashboardmain/TodaysAppointments"]);
          }

          if (data.code == 'S002') {
            this.spinner.hide();
            alert(data.data);
            this.isSubmitted = false;
            return false;

          }
          else if (data.code == "PD01") {
            alert("Permissions Denied");
            return;
          }
          else if (data.code == "UA01") {
            alert("Your UnAuthorized to Access this Service");
            return;
          }
        }
        else {
          this.spinner.hide();
          this.isSubmitted = false;
          alert(data.data)
          return false;
        }
      },
        error => { alert(" Internal Server Error "); }
      );

  }
  CRHDArray = [];
  CRHDCarotidValue;
  CRHDSinusVAlue;
  CRHDafValue;
  CRHDHOValue;
  CRHDnyhaArray = [];
  CRHDArray1 = [];
  CRHDArray2 = [];
  CHRDArray3 = [];

  onChangeCRHDRisk(list, isChecked: boolean) {

    if (isChecked == true) {
      this.CRHDArray1.push(list);
    } else {
      const index = this.CRHDArray1.indexOf(list);
      if (index > -1) {
        this.CRHDArray1.splice(index, 1);
      }
    }

    this.CRHDArray = Array.from(new Set(this.CRHDArray1))

  }
  onChangeCRHDCarotid(list, isChecked: boolean) {

    if (isChecked == true) {
      this.CRHDCarotidValue = list;
    } else {
      this.CRHDCarotidValue = ""
    }

  }
  onChangeCRHDNYHA(list, isChecked: boolean) {

    if (isChecked == true) {
      this.CRHDArray2.push(list);
    } else {
      const index = this.CRHDArray2.indexOf(list);
      if (index > -1) {
        this.CRHDArray2.splice(index, 1);
      }
    }

    this.CRHDnyhaArray = Array.from(new Set(this.CRHDArray2))

  }
  onChangeCRHDSinus(list, isChecked: boolean) {

    if (isChecked == true) {
      this.CRHDSinusVAlue = list;
    } else {
      this.CRHDSinusVAlue = "";
    }

  }
  onChangeCRHDaf(list, isChecked: boolean) {

    if (isChecked == true) {
      this.CRHDafValue = list;
    } else {
      this.CRHDafValue = ""
    }

  }
  onChangeCRHDho_ie(list, isChecked: boolean) {

    if (isChecked == true) {
      this.CRHDHOValue = list;
    } else {
      this.CRHDHOValue = ""
    }

  }
  selectedvalue = 'NORMAL';
  CRHDappointmentForm = new FormGroup({

    AppointmentID: new FormControl(''),
    Type: new FormControl('', [Validators.required]),
    FollowUpDate: new FormControl(''),
    CRHD: new FormGroup({

      RiskFactors: new FormArray([]),
      CRHD_SINCE: new FormControl(''),
      MITRAL_STENOSIS: new FormControl(''),
      MITRAL_REGURGITATION: new FormControl(''),
      AORTIC_STENOSIS: new FormControl(''),
      AORTIC_REGURGITATION: new FormControl(''),
      TRICUSPID_STENOSIS: new FormControl(''),
      TRICUSPID_REGURGITATION: new FormControl(''),
      PULMONARY_STENOSIS: new FormControl(''),
      PULMONARY_REGURGITATION: new FormControl(''),
      PAH: new FormControl(''),
      LVEF: new FormControl(''),
      LVEDD: new FormControl(''),
      LVESD: new FormControl(''),
      RV_FUNCTION: new FormControl(''),
      OLD_CVA_OR_TIA_CAROTID: new FormControl(''),
      NYHS: new FormArray([]),
      SINUS_RYTHM: new FormControl(''),
      AF: new FormControl(''),
      HO_IE: new FormControl(''),
      ECHO: new FormControl(''),
      BP: new FormControl(''),
      PR: new FormControl(''),
      BGH: new FormControl(''),
      Notes: new FormControl(''),
      Investigations: new FormControl(''),
      Diagnosis: new FormControl(''),
      FollowUp: new FormControl(''),
      FollowUpDate: new FormControl(''),
      Prescription: new FormControl(''),
      Diet: new FormControl(''),
    }),
    RiskFactors6: new FormArray([]),
    CRHD_SINCE: new FormControl(''),
    MITRAL_STENOSIS: new FormControl(''),
    MITRAL_REGURGITATION: new FormControl(''),
    AORTIC_STENOSIS: new FormControl(''),
    AORTIC_REGURGITATION: new FormControl(''),
    TRICUSPID_STENOSIS: new FormControl(''),
    TRICUSPID_REGURGITATION: new FormControl(''),
    PULMONARY_STENOSIS: new FormControl(''),
    PULMONARY_REGURGITATION: new FormControl(''),
    PAH: new FormControl(''),
    LVEF6: new FormControl(''),
    LVEDD: new FormControl(''),
    LVESD: new FormControl(''),
    RV_FUNCTION6: new FormControl(''),
    OLD_CVA_OR_TIA_CAROTID: new FormControl(''),
    NYHS: new FormArray([]),

    SINUS_RYTHM: new FormControl(''),
    AF: new FormControl(''),
    HO_IE: new FormControl(''),
    ECHO6: new FormControl(''),
    BP6: new FormControl(''),
    PR6: new FormControl(''),
    BGH6: new FormControl(''),
    Notes6: new FormControl(''),
    Investigations6: new FormControl(''),
    Diagnosis6: new FormControl(''),
    FollowUp6: new FormControl(''),
    FollowUpDate6: new FormControl(''),
    Prescription6: new FormControl(''),
    Diet6: new FormControl(''),

  });
  get RiskFactors6() {
    return this.CRHDappointmentForm.get('RiskFactors6') as FormArray;
  }
  get CRHD_SINCE() {
    return this.CRHDappointmentForm.get('CRHD_SINCE');
  }
  get MITRAL_STENOSIS() {
    return this.CRHDappointmentForm.get('MITRAL_STENOSIS');
  }
  get MITRAL_REGURGITATION() {
    return this.CRHDappointmentForm.get('MITRAL_REGURGITATION');
  }
  get AORTIC_STENOSIS() {
    return this.CRHDappointmentForm.get('AORTIC_STENOSIS');
  }
  get AORTIC_REGURGITATION() {
    return this.CRHDappointmentForm.get('AORTIC_REGURGITATION');
  }
  get TRICUSPID_STENOSIS() {
    return this.CRHDappointmentForm.get('TRICUSPID_STENOSIS');
  }
  get TRICUSPID_REGURGITATION() {
    return this.CRHDappointmentForm.get('TRICUSPID_REGURGITATION');
  }
  get PULMONARY_STENOSIS() {
    return this.CRHDappointmentForm.get('PULMONARY_STENOSIS');
  }
  get PULMONARY_REGURGITATION() {
    return this.CRHDappointmentForm.get('PULMONARY_REGURGITATION');
  }
  get PAH() {
    return this.CRHDappointmentForm.get('PAH');
  }
  get LVEF6() {
    return this.CRHDappointmentForm.get('LVEF6');
  }

  get LVEDD() {
    return this.CRHDappointmentForm.get('LVEDD');
  }
  get LVESD() {
    return this.CRHDappointmentForm.get('LVESD');
  }
  get RV_FUNCTION6() {
    return this.CRHDappointmentForm.get('RV_FUNCTION6');
  }
  get OLD_CVA_OR_TIA_CAROTID() {
    return this.CRHDappointmentForm.get('OLD_CVA_OR_TIA_CAROTID');
  }
  get NYHS() {
    return this.CRHDappointmentForm.get('NYHS') as FormArray;
  }
  get SINUS_RYTHM() {
    return this.CRHDappointmentForm.get('SINUS_RYTHM');
  }
  get NYHA6() {
    return this.CRHDappointmentForm.get('NYHA6');
  }
  get AF() {
    return this.CRHDappointmentForm.get('AF');
  }
  get HO_IE() {
    return this.CRHDappointmentForm.get('HO_IE');
  }
  get ECHO6() {
    return this.CRHDappointmentForm.get('ECHO6');
  }

  get BP6() {
    return this.CRHDappointmentForm.get('BP6');
  }
  get PR6() {
    return this.CRHDappointmentForm.get('PR6');
  }
  get BGH6() {
    return this.CRHDappointmentForm.get('BGH6');
  }
  get Notes6() {
    return this.CRHDappointmentForm.get('Notes6');
  }

  get Investigations6() {
    return this.CRHDappointmentForm.get('Investigations6');
  }
  get Diagnosis6() {
    return this.CRHDappointmentForm.get('Diagnosis6');
  }
  get FollowUp6() {
    return this.CRHDappointmentForm.get('FollowUp6');
  }
  get FollowUpDate6() {
    return this.CRHDappointmentForm.get('FollowUpDate6');
  }
  get Prescription6() {
    return this.CRHDappointmentForm.get('Prescription6');
  }
  get Diet6() {
    return this.CRHDappointmentForm.get('Diet6');
  }

  HLP_WITH_NO_CADappointmentForm = new FormGroup({

    AppointmentID: new FormControl(''),
    Type: new FormControl('', [Validators.required]),
    FollowUpDate: new FormControl(''),
    HLP_WITH_NO_CAD: new FormGroup({
      RiskFactors: new FormArray([]),
      HLP_SINCE: new FormControl(''),
      TOTAL_CH: new FormControl(''),
      LDL_C: new FormControl(''),
      TG: new FormControl(''),
      HDL_C: new FormControl(''),
      LPA: new FormControl(''),
      SUBFRACTION_OF_LDL: new FormControl(''),
      ANY_EXT_SIGNS: new FormControl(''),
      BP: new FormControl(''),
      PR: new FormControl(''),
      BGH: new FormControl(''),
      Notes: new FormControl(''),
      Investigations: new FormControl(''),
      Diagnosis: new FormControl(''),
      FollowUp: new FormControl(''),
      FollowUpDate: new FormControl(''),
      Prescription: new FormControl(''),
      Diet: new FormControl(''),
    }),
    RiskFactors7: new FormArray([]),
    HLP_SINCE: new FormControl(''),
    TOTAL_CH: new FormControl(''),
    LDL_C: new FormControl(''),
    TG: new FormControl(''),
    HDL_C: new FormControl(''),
    LPA: new FormControl(''),
    SUBFRACTION_OF_LDL: new FormControl(''),
    ANY_EXT_SIGNS: new FormControl(''),
    BP7: new FormControl(''),
    PR7: new FormControl(''),
    BGH7: new FormControl(''),
    Notes7: new FormControl(''),
    Investigations7: new FormControl(''),
    Diagnosis7: new FormControl(''),
    FollowUp7: new FormControl(''),
    FollowUpDate7: new FormControl(''),
    Prescription7: new FormControl(''),
    Diet7: new FormControl(''),
  });
  get RiskFactors7() {
    return this.HLP_WITH_NO_CADappointmentForm.get('RiskFactors7') as FormArray;
  }
  get HLP_SINCE() {
    return this.HLP_WITH_NO_CADappointmentForm.get('HLP_SINCE');
  }
  get TOTAL_CH() {
    return this.HLP_WITH_NO_CADappointmentForm.get('TOTAL_CH');
  }
  get LDL_C() {
    return this.HLP_WITH_NO_CADappointmentForm.get('LDL_C');
  }

  get TG() {
    return this.HLP_WITH_NO_CADappointmentForm.get('TG');
  }
  get HDL_C() {
    return this.HLP_WITH_NO_CADappointmentForm.get('HDL_C');
  }
  get LPA() {
    return this.HLP_WITH_NO_CADappointmentForm.get('LPA');
  }
  get SUBFRACTION_OF_LDL() {
    return this.HLP_WITH_NO_CADappointmentForm.get('SUBFRACTION_OF_LDL');
  }
  get ANY_EXT_SIGNS() {
    return this.HLP_WITH_NO_CADappointmentForm.get('ANY_EXT_SIGNS');
  }

  get BP7() {
    return this.HLP_WITH_NO_CADappointmentForm.get('BP7');
  }
  get PR7() {
    return this.HLP_WITH_NO_CADappointmentForm.get('PR7');
  }
  get BGH7() {
    return this.HLP_WITH_NO_CADappointmentForm.get('BGH7');
  }
  get Notes7() {
    return this.HLP_WITH_NO_CADappointmentForm.get('Notes7');
  }

  get Investigations7() {
    return this.HLP_WITH_NO_CADappointmentForm.get('Investigations7');
  }
  get Diagnosis7() {
    return this.HLP_WITH_NO_CADappointmentForm.get('Diagnosis7');
  }
  get FollowUp7() {
    return this.HLP_WITH_NO_CADappointmentForm.get('FollowUp7');
  }
  get FollowUpDate7() {
    return this.HLP_WITH_NO_CADappointmentForm.get('FollowUpDate7');
  }
  get Prescription7() {
    return this.HLP_WITH_NO_CADappointmentForm.get('Prescription7');
  }
  get Diet7() {
    return this.HLP_WITH_NO_CADappointmentForm.get('Diet7');
  }
  HLP_WITH_NO_CADRiskArray = [];
  HLPArray = [];
  onChangeHLP_WITH_NO_CADRisk(list, isChecked: boolean) {

    if (isChecked == true) {
      this.HLPArray.push(list);
    } else {
      const index = this.HLPArray.indexOf(list);
      if (index > -1) {
        this.HLPArray.splice(index, 1);
      }
    }

    this.HLP_WITH_NO_CADRiskArray = Array.from(new Set(this.HLPArray))

  }
  HLP_WITH_NO_CADreportObj = {};
  onSubmitHLP_WITH_NO_CAD(HLP_WITH_NO_CADappointmentForm) {
    // this.followupdatevalue = moment(this.HLP_WITH_NO_CADappointmentForm.value.FollowUpDate7).format("DD-MM-YYYY");


    // let x;
    // if (this.HLP_WITH_NO_CADappointmentForm.value.FollowUpDate7 != "Invalid date" && this.HLP_WITH_NO_CADappointmentForm.value.FollowUpDate7 != null && this.HLP_WITH_NO_CADappointmentForm.value.FollowUpDate7 != "") {
    //   x = moment(this.HLP_WITH_NO_CADappointmentForm.value.FollowUpDate7).format("DD-MM-YYYY");
    //   this.HLP_WITH_NO_CADappointmentForm.value.FollowUpDate7 = x;
    // } else {
    //   x = "";
    //   this.HLP_WITH_NO_CADappointmentForm.value.FollowUpDate7 = x;
    // }

    setTimeout(() => {
      this.spinner.hide();
    }, 5000);

    this.HLP_WITH_NO_CADappointmentForm.value.Type = this.temptype;
    this.HLP_WITH_NO_CADappointmentForm.value.RiskFactors7 = this.HLP_WITH_NO_CADRiskArray;


    this.HLP_WITH_NO_CADreportObj = {
      RiskFactors: this.HLP_WITH_NO_CADappointmentForm.value.RiskFactors7,
      HLP_SINCE: this.HLP_WITH_NO_CADappointmentForm.value.HLP_SINCE,
      TOTAL_CH: this.HLP_WITH_NO_CADappointmentForm.value.TOTAL_CH,


      LDL_C: this.HLP_WITH_NO_CADappointmentForm.value.LDL_C,
      TG: this.HLP_WITH_NO_CADappointmentForm.value.TG,
      HDL_C: this.HLP_WITH_NO_CADappointmentForm.value.HDL_C,
      LPA: this.HLP_WITH_NO_CADappointmentForm.value.LPA,
      SUBFRACTION_OF_LDL: this.HLP_WITH_NO_CADappointmentForm.value.SUBFRACTION_OF_LDL,
      ANY_EXT_SIGNS: this.HLP_WITH_NO_CADappointmentForm.value.ANY_EXT_SIGNS,

      BP: this.HLP_WITH_NO_CADappointmentForm.value.BP7,
      PR: this.HLP_WITH_NO_CADappointmentForm.value.PR7,
      BGH: this.HLP_WITH_NO_CADappointmentForm.value.BGH7,
      Notes: this.HLP_WITH_NO_CADappointmentForm.value.Notes7,
      Investigations: this.HLP_WITH_NO_CADappointmentForm.value.Investigations7,
      Diagnosis: this.HLP_WITH_NO_CADappointmentForm.value.Diagnosis7,
      FollowUp: this.HLP_WITH_NO_CADappointmentForm.value.FollowUp7,
      FollowUpDate: HLP_WITH_NO_CADappointmentForm.value.FollowUpDate7,
      Prescription: this.HLP_WITH_NO_CADappointmentForm.value.Prescription7,
      Diet: this.HLP_WITH_NO_CADappointmentForm.value.Diet7,

    };
    this.HLP_WITH_NO_CADappointmentForm.value.HLP_WITH_NO_CAD = this.HLP_WITH_NO_CADreportObj;

    this.HLP_WITH_NO_CADappointmentForm.value.FollowUpDate = HLP_WITH_NO_CADappointmentForm.value.FollowUpDate7;
    this.HLP_WITH_NO_CADappointmentForm.value.AppointmentID = this.appid;

    let result = this.apicalls.updateAppointmentsPrescription(this.HLP_WITH_NO_CADappointmentForm.value)
      .subscribe(data => {
        this.spinner.show();
        //  this.data = data;
        if (data.type == true) {


          if (data.code == 'S001') {
            this.spinner.hide();
            alert(data.data)
            this.route.navigate(["/dashboardmain/TodaysAppointments"]);
          }

          if (data.code == 'S002') {
            this.spinner.hide();
            alert(data.data);
            this.isSubmitted = false;
            return false;

          }
          else if (data.code == "PD01") {
            alert("Permissions Denied");
            return;
          }
          else if (data.code == "UA01") {
            alert("Your UnAuthorized to Access this Service");
            return;
          }
        }
        else {
          this.spinner.hide();
          this.isSubmitted = false;
          alert(data.data)
          return false;
        }
      },
        error => { alert(" Internal Server Error "); }
      );
  }

  Chest_PainappointmentForm = new FormGroup({

    AppointmentID: new FormControl(''),
    Type: new FormControl('', [Validators.required]),
    FollowUpDate: new FormControl(''),
    Chest_Pain: new FormGroup({
      RiskFactors: new FormArray([]),


      CHEST_PAIN: new FormArray([]),

      ECG: new FormArray([]),

      STRESS_TEST: new FormArray([]),

      CAG: new FormArray([]),

      ECHO: new FormControl(''),

      BP: new FormControl(''),
      PR: new FormControl(''),
      BGH: new FormControl(''),
      Notes: new FormControl(''),
      Investigations: new FormControl(''),
      Diagnosis: new FormControl(''),
      FollowUp: new FormControl(''),
      FollowUpDate: new FormControl(''),
      Prescription: new FormControl(''),
      Diet: new FormControl(''),
    }),
    RiskFactors: new FormArray([]),


    CHEST_PAIN: new FormArray([]),

    ECG: new FormArray([]),

    STRESS_TEST: new FormArray([]),

    CAG8: new FormArray([]),

    ECHO8: new FormControl(''),

    BP8: new FormControl(''),
    PR8: new FormControl(''),
    BGH8: new FormControl(''),
    Notes8: new FormControl(''),
    Investigations8: new FormControl(''),
    Diagnosis8: new FormControl(''),
    FollowUp8: new FormControl(''),
    FollowUpDate8: new FormControl(''),
    Prescription8: new FormControl(''),
    Diet8: new FormControl(''),
    RiskFactors8: new FormControl(''),


  });

  get RiskFactors8() {
    return this.Chest_PainappointmentForm.get('RiskFactors8') as FormArray;
  }
  get CHEST_PAIN() {
    return this.Chest_PainappointmentForm.get('CHEST_PAIN') as FormArray;
  }

  get ECG() {
    return this.Chest_PainappointmentForm.get('ECG') as FormArray;
  }
  get STRESS_TEST() {
    return this.Chest_PainappointmentForm.get('STRESS_TEST') as FormArray;
  }

  get CAG8() {
    return this.Chest_PainappointmentForm.get('CAG8') as FormArray;
  }
  get ECHO8() {
    return this.Chest_PainappointmentForm.get('ECHO8');
  }
  get BP8() {
    return this.Chest_PainappointmentForm.get('BP8');
  }
  get PR8() {
    return this.Chest_PainappointmentForm.get('PR8');
  }
  get BGH8() {
    return this.Chest_PainappointmentForm.get('BGH8');
  }
  get Notes8() {
    return this.Chest_PainappointmentForm.get('Notes8');
  }

  get Investigations8() {
    return this.Chest_PainappointmentForm.get('Investigations8');
  }
  get Diagnosis8() {
    return this.Chest_PainappointmentForm.get('Diagnosis8');
  }
  get FollowUp8() {
    return this.Chest_PainappointmentForm.get('FollowUp8');
  }
  get FollowUpDate8() {
    return this.Chest_PainappointmentForm.get('FollowUpDate8');
  }

  get Prescription8() {
    return this.Chest_PainappointmentForm.get('Prescription8');
  }
  get Diet8() {
    return this.Chest_PainappointmentForm.get('Diet8');
  }
  ChestpainRiskArray = [];
  onChangeChestPainArray = [];
  onChangeChestPainStressTESTArray = [];
  onChangeCAGChestPainArray = [];
  onChangeECGChestPainArray = [];
  ChestArray1 = [];
  ChestArray2 = [];
  ChestArray3 = [];
  ChestArray4 = [];
  ChestArray5 = [];
  onChangeChestpainRisk(list, isChecked: boolean) {

    if (isChecked == true) {
      this.ChestArray1.push(list);
    } else {
      const index = this.ChestArray1.indexOf(list);
      if (index > -1) {
        this.ChestArray1.splice(index, 1);
      }
    }

    this.ChestpainRiskArray = Array.from(new Set(this.ChestArray1))

  }
  onChangeChestPain(list, isChecked: boolean) {

    if (isChecked == true) {
      this.ChestArray2.push(list);
    } else {
      const index = this.ChestArray2.indexOf(list);
      if (index > -1) {
        this.ChestArray2.splice(index, 1);
      }
    }
    this.onChangeChestPainArray = Array.from(new Set(this.ChestArray2))


  }
  onChangeECGChestPain(list, isChecked: boolean) {

    if (isChecked == true) {
      this.ChestArray3.push(list);
    } else {
      const index = this.ChestArray3.indexOf(list);
      if (index > -1) {
        this.ChestArray3.splice(index, 1);
      }
    }
    this.onChangeECGChestPainArray = Array.from(new Set(this.ChestArray3))


  }
  onChangeStressTESTChestPain(list, isChecked: boolean) {

    if (isChecked == true) {
      this.ChestArray4.push(list);
    } else {
      const index = this.ChestArray4.indexOf(list);
      if (index > -1) {
        this.ChestArray4.splice(index, 1);
      }
    }
    this.onChangeChestPainStressTESTArray = Array.from(new Set(this.ChestArray4))


  }
  onChangeCAGChestPain(list, isChecked: boolean) {

    if (isChecked == true) {
      this.ChestArray5.push(list);
    } else {
      const index = this.ChestArray5.indexOf(list);
      if (index > -1) {
        this.ChestArray5.splice(index, 1);
      }
    }
    this.onChangeCAGChestPainArray = Array.from(new Set(this.ChestArray5))


  }
  Chest_PainreportObj = {};
  onSubmitChest_Pain(Chest_PainappointmentForm) {
    //  this.followupdatevalue = moment(this.Chest_PainappointmentForm.value.FollowUpDate8).format("DD-MM-YYYY");


    // let x;
    // if (this.Chest_PainappointmentForm.value.FollowUpDate8 != "Invalid date" && this.Chest_PainappointmentForm.value.FollowUpDate8 != null && this.Chest_PainappointmentForm.value.FollowUpDate8 != "") {
    //   x = moment(this.Chest_PainappointmentForm.value.FollowUpDate8).format("DD-MM-YYYY");
    //   this.Chest_PainappointmentForm.value.FollowUpDate8 = x;
    // } else {
    //   x = "";
    //   this.Chest_PainappointmentForm.value.FollowUpDate8 = x;
    // }

    setTimeout(() => {
      this.spinner.hide();
    }, 5000);

    this.Chest_PainappointmentForm.value.Type = this.temptype;
    this.Chest_PainappointmentForm.value.RiskFactors8 = this.ChestpainRiskArray;


    this.Chest_PainreportObj = {
      RiskFactors: this.Chest_PainappointmentForm.value.RiskFactors8,
      CHEST_PAIN: this.onChangeChestPainArray,
      ECG: this.onChangeECGChestPainArray,


      STRESS_TEST: this.onChangeChestPainStressTESTArray,
      CAG: this.onChangeCAGChestPainArray,
      ECHO: this.Chest_PainappointmentForm.value.ECHO8,


      BP: this.Chest_PainappointmentForm.value.BP8,
      PR: this.Chest_PainappointmentForm.value.PR8,
      BGH: this.Chest_PainappointmentForm.value.BGH8,
      Notes: this.Chest_PainappointmentForm.value.Notes8,
      Investigations: this.Chest_PainappointmentForm.value.Investigations8,
      Diagnosis: this.Chest_PainappointmentForm.value.Diagnosis8,
      FollowUp: this.Chest_PainappointmentForm.value.FollowUp8,
      FollowUpDate: Chest_PainappointmentForm.value.FollowUpDate8,
      Prescription: this.Chest_PainappointmentForm.value.Prescription8,
      Diet: this.Chest_PainappointmentForm.value.Diet8,

    };
    this.Chest_PainappointmentForm.value.Chest_Pain = this.Chest_PainreportObj;

    this.Chest_PainappointmentForm.value.FollowUpDate = Chest_PainappointmentForm.value.FollowUpDate8;
    this.Chest_PainappointmentForm.value.AppointmentID = this.appid;

    let result = this.apicalls.updateAppointmentsPrescription(this.Chest_PainappointmentForm.value)
      .subscribe(data => {
        this.spinner.show();
        //  this.data = data;
        if (data.type == true) {


          if (data.code == 'S001') {
            this.spinner.hide();
            alert(data.data)
            this.route.navigate(["/dashboardmain/TodaysAppointments"]);
          }

          if (data.code == 'S002') {
            this.spinner.hide();
            alert(data.data);
            this.isSubmitted = false;
            return false;

          }
          else if (data.code == "PD01") {
            alert("Permissions Denied");
            return;
          }
          else if (data.code == "UA01") {
            alert("Your UnAuthorized to Access this Service");
            return;
          }
        }
        else {
          this.spinner.hide();
          this.isSubmitted = false;
          alert(data.data)
          return false;
        }
      },
        error => { alert(" Internal Server Error "); }
      );
  }

  OthersappointmentForm = new FormGroup({

    AppointmentID: new FormControl(''),
    Type: new FormControl('', [Validators.required]),
    FollowUpDate: new FormControl(''),

    Other: new FormGroup({
      RiskFactors: new FormArray([]),


      PRESENTING_REASON: new FormArray([]),

      ECG: new FormArray([]),

      OTHER_DATA: new FormControl(''),


      ECHO: new FormControl(''),

      BP: new FormControl(''),
      PR: new FormControl(''),
      BGH: new FormControl(''),
      Notes: new FormControl(''),
      Investigations: new FormControl(''),
      Diagnosis: new FormControl(''),
      FollowUp: new FormControl(''),
      FollowUpDate: new FormControl(''),
      Prescription: new FormControl(''),
      Diet: new FormControl(''),
    }),
    RiskFactors: new FormArray([]),
    PRESENTING_REASON: new FormArray([]),

    ECG9: new FormArray([]),
    stress1: new FormArray([]),

    OTHER_DATA: new FormControl(''),


    ECHO: new FormControl(''),



    ECHO9: new FormControl(''),

    BP9: new FormControl(''),
    PR9: new FormControl(''),
    BGH9: new FormControl(''),
    Notes9: new FormControl(''),
    Investigations9: new FormControl(''),
    Diagnosis9: new FormControl(''),
    FollowUp9: new FormControl(''),
    FollowUpDate9: new FormControl(''),
    Prescription9: new FormControl(''),
    Diet9: new FormControl(''),
    RiskFactors9: new FormControl(''),


  });

  get RiskFactors9() {
    return this.OthersappointmentForm.get('RiskFactors9') as FormArray;
  }
  get PRESENTING_REASON() {
    return this.OthersappointmentForm.get('PRESENTING_REASON') as FormArray;
  }

  get ECG9() {
    return this.OthersappointmentForm.get('ECG9') as FormArray;
  }
  get OTHER_DATA() {
    return this.OthersappointmentForm.get('OTHER_DATA') as FormArray;
  }


  get ECHO9() {
    return this.OthersappointmentForm.get('ECHO9');
  }
  get BP9() {
    return this.OthersappointmentForm.get('BP9');
  }
  get PR9() {
    return this.OthersappointmentForm.get('PR9');
  }
  get BGH9() {
    return this.OthersappointmentForm.get('BGH9');
  }
  get Notes9() {
    return this.OthersappointmentForm.get('Notes9');
  }

  get Investigations9() {
    return this.OthersappointmentForm.get('Investigations9');
  }
  get Diagnosis9() {
    return this.OthersappointmentForm.get('Diagnosis9');
  }
  get FollowUp9() {
    return this.OthersappointmentForm.get('FollowUp9');
  }
  get FollowUpDate9() {
    return this.OthersappointmentForm.get('FollowUpDate9');
  }

  get Prescription9() {
    return this.OthersappointmentForm.get('Prescription9');
  }
  get Diet9() {
    return this.OthersappointmentForm.get('Diet9');
  }
  OtherRiskArray = [];
  OtherReasonsArray = [];
  OtherECGArray = [];
  OtherArray1 = [];
  OtherArray2 = [];
  OtherArray3 = [];
  onChangeOtherRisk(list, isChecked: boolean) {

    if (isChecked == true) {
      this.OtherArray1.push(list);
    } else {
      const index = this.OtherArray1.indexOf(list);
      if (index > -1) {
        this.OtherArray1.splice(index, 1);
      }
    }


    this.OtherRiskArray = Array.from(new Set(this.OtherArray1))

  }
  onChangeReasons(list, isChecked: boolean) {

    if (isChecked == true) {
      this.OtherArray2.push(list);
    } else {
      const index = this.OtherArray2.indexOf(list);
      if (index > -1) {
        this.OtherArray2.splice(index, 1);
      }
    }
    this.OtherReasonsArray = Array.from(new Set(this.OtherArray2))


  }
  onChangeotherECG(list, isChecked: boolean) {
    if (isChecked == true) {
      this.OtherArray3.push(list);
    } else {
      const index = this.OtherArray3.indexOf(list);
      if (index > -1) {
        this.OtherArray3.splice(index, 1);
      }
    }
    this.OtherECGArray = Array.from(new Set(this.OtherArray3))

  }
  OtherArray4 = []
  OtherStressArray = [];
  onChangestress(list, isChecked: boolean) {
    if (isChecked == true) {
      this.OtherArray4.push(list);
    } else {
      const index = this.OtherArray4.indexOf(list);
      if (index > -1) {
        this.OtherArray4.splice(index, 1);
      }
    }
    this.OtherStressArray = Array.from(new Set(this.OtherArray4))

  }
  OthersreportObj = {};
  onSubmitOthers(OthersappointmentForm) {

    //  this.followupdatevalue = moment(this.OthersappointmentForm.value.FollowUpDate9).format("DD-MM-YYYY");


    let x;
    // if (this.OthersappointmentForm.value.FollowUpDate9 != "Invalid date" && this.OthersappointmentForm.value.FollowUpDate9 != null && this.OthersappointmentForm.value.FollowUpDate9 != "") {
    //   x = moment(this.OthersappointmentForm.value.FollowUpDate9).format("DD-MM-YYYY");
    //   this.OthersappointmentForm.value.FollowUpDate9 = x;
    // } else {
    //   x = "";
    //   this.OthersappointmentForm.value.FollowUpDate9 = x;
    // }
    setTimeout(() => {
      this.spinner.hide();
    }, 5000);

    this.OthersappointmentForm.value.Type = this.temptype;
    this.OthersappointmentForm.value.RiskFactors9 = this.OtherRiskArray;

    this.OthersappointmentForm.value.STRESS_TEST = this.OtherStressArray;


    this.OthersreportObj = {
      RiskFactors: this.OthersappointmentForm.value.RiskFactors9,
      PRESENTING_REASON: this.OtherReasonsArray,
      ECG: this.OtherECGArray,
      STRESS_TEST: this.OthersappointmentForm.value.STRESS_TEST,


      OTHER_DATA: this.OthersappointmentForm.value.OTHER_DATA,

      ECHO: this.OthersappointmentForm.value.ECHO9,


      BP: this.OthersappointmentForm.value.BP9,
      PR: this.OthersappointmentForm.value.PR9,
      BGH: this.OthersappointmentForm.value.BGH9,
      Notes: this.OthersappointmentForm.value.Notes9,
      Investigations: this.OthersappointmentForm.value.Investigations9,
      Diagnosis: this.OthersappointmentForm.value.Diagnosis9,
      FollowUp: this.OthersappointmentForm.value.FollowUp9,
      FollowUpDate: this.OthersappointmentForm.value.FollowUpDate9,
      Prescription: this.OthersappointmentForm.value.Prescription9,
      Diet: this.OthersappointmentForm.value.Diet9,

    };
    this.OthersappointmentForm.value.Other = this.OthersreportObj;

    this.OthersappointmentForm.value.FollowUpDate = this.OthersappointmentForm.value.FollowUpDate9;
    this.OthersappointmentForm.value.AppointmentID = this.appid;
    let result = this.apicalls.updateAppointmentsPrescription(this.OthersappointmentForm.value)
      .subscribe(data => {
        this.spinner.show();
        //  this.data = data;
        if (data.type == true) {


          if (data.code == 'S001') {
            this.spinner.hide();
            alert(data.data)
            this.route.navigate(["/dashboardmain/TodaysAppointments"]);

          }

          if (data.code == 'S002') {
            this.spinner.hide();
            alert(data.data);
            this.isSubmitted = false;
            return false;

          }
          else if (data.code == "PD01") {
            alert("Permissions Denied");
            return;
          }
          else if (data.code == "UA01") {
            alert("Your UnAuthorized to Access this Service");
            return;
          }
        }
        else {

          this.spinner.hide();
          this.isSubmitted = false;
          alert(data.data)
          return false;
        }
      },
        error => { alert(" Internal Server Error "); }
      );
  }
  Appointmentdetails;
  patientappointmentdetails;
  detailsss;
  //----riskfactors----
  htnchecked = false;
  DMchecked = false;
  HLPchecked = false;
  SMOKERchecked = false;
  FHCADchecked = false;
  //----riskfactors CAD----
  htnchecked1 = false;
  DMchecked1 = false;
  HLPchecked1 = false;
  SMOKERchecked1 = false;
  FHCADchecked1 = false;
  //----riskfactors HTN----
  htnchecked2 = false;
  DMchecked2 = false;
  HLPchecked2 = false;
  SMOKERchecked2 = false;
  FHCADchecked2 = false;
  //----riskfactors DM----
  htnchecked3 = false;
  DMchecked3 = false;
  HLPchecked3 = false;
  SMOKERchecked3 = false;
  FHCADchecked3 = false;
  //----riskfactors HCM----
  htnchecked4 = false;
  DMchecked4 = false;
  HLPchecked4 = false;
  SMOKERchecked4 = false;
  FHCADchecked4 = false;
  //----riskfactors PAH----
  htnchecked5 = false;
  DMchecked5 = false;
  HLPchecked5 = false;
  SMOKERchecked5 = false;
  FHCADchecked5 = false;
  //-------riskfactor hlp---
  htnchecked6 = false;
  DMchecked6 = false;
  HLPchecked6 = false;
  SMOKERchecked6 = false;
  FHCADchecked6 = false;

  //-------riskfactor hlp---
  htnchecked7 = false;
  DMchecked7 = false;
  HLPchecked7 = false;
  SMOKERchecked7 = false;
  FHCADchecked7 = false;

  //-------riskfactor chectpain---
  htnchecked8 = false;
  DMchecked8 = false;
  HLPchecked8 = false;
  SMOKERchecked8 = false;
  FHCADchecked8 = false;

  // --------heartFailure--
  HFPEFchecked = false;
  HFREFchecked = false;
  COMPANSATEDCHFchecked = false;
  DECOMPANSATEDCHFchecked = false;
  //-----NYHA-----
  NYHAIchecked = false;
  NYHAIIchecked = false;
  NYHAIIIchecked = false;
  NYHAIVchecked = false;
  //------stage------
  STAGEAchecked = false;
  STAGEBchecked = false;
  STAGECchecked = false;
  STAGEDchecked = false;
  //-----DIAGNOSIS-----
  ICMPchecked = false;
  DCMchecked = false;
  CRHDchecked = false;
  RCMPchecked = false;
  PPAHchecked = false;
  SECchecked = false;
  OTHERSchecked = false;

  //-------------APCLASS-----
  APCLASSIchecked = false;
  APCLASSIIchecked = false;
  APCLASSIIIchecked = false;
  APCLASSIVchecked = false

  //--------------ADVICED post CAG-----
  MMchecked = false;
  CABGchecked = false;
  PTCAchecked = false;
  OTHERchecked = false;

  CKDchecked = false;
  CKDchecked1 = false;
  HTNROPTHALOPINIONchecked = false;

  OLDCVAchecked = false;

  HTNRETINOPATHYchecked = false;
  OLDCVADMchecked = false;

  GENESTUDYchecked = false;

  //---------------PAH Class----
  CLASSIPAHchecked = false;
  CLASSIIPAHchecked = false;
  CLASSIIIPAHchecked = false;
  CLASSIVPAHchecked = false;

  PAHCOMPANSATEDchecked = false;
  PAHDECOMPANSATEDchecked = false;

  DIAGNOSISPAHPRIMARYchecked = false;
  DIAGNOSISPAHCTEPchecked = false;
  DIAGNOSISPAHSECchecked = false;
  DIAGNOSISPAHDUEchecked = false;
  DIAGNOSISPAHotherchecked = false;

  //---------------chrd---
  OLD_CVA_OR_TIA_CAROTIDchecked = false;

  NYHAICRHDchecked = false;
  NYHAIICRHDchecked = false;
  NYHAIIICRHDchecked = false;
  NYHAIVCRHDchecked = false;

  SINUSCRHDchecked = false;
  AFCRHDchecked = false;
  HOIECRHDchecked = false;

  //  ----chest----
  ECGChest1checked = false;
  ECGChest2checked = false;
  ECGChest3checked = false;

  stresstestchest2checked = false;
  stresstestchest1checked = false;


  cagchest1checked = false;
  cagchest2checked = false;
  cagchest3checked = false;
  cagchest4checked = false;
  cagchest5checked = false;

  chestpain1checked = false;
  chestpain2checked = false;
  chestpain3checked = false;

  htnchecked9 = false;
  DMchecked9 = false;
  HLPchecked9 = false;
  SMOKERchecked9 = false;
  FHCADchecked9 = false;

  preasonchecked = false;

  preason1checked = false;

  preason2checked = false;

  ecg1others = false;
  ecg2others = false;
  ecg3others = false;

  stress1 = false;
  stress2 = false;




  getAppointmentID(AppointmentID) {

    this.apicalls.viewAppointmentById(AppointmentID).subscribe(

      data => {
        this.spinner.show();

        if (data.type == true) {
          this.Appointmentdetails = data;
          this.patientappointmentdetails = this.Appointmentdetails.data
          if (this.patientappointmentdetails.MRNumber) {
            this.GetPrescriptionData(this.patientappointmentdetails.MRNumber)
          }
          this.detailsss = this.patientappointmentdetails.PatientDetails
          this.Appointmentdetails = data;
          this.temptype = this.Appointmentdetails.data.Type
          if (this.temptype == "Chest_Pain") {
            this.cpvalue = true;
            this.htnvalue = false;
            this.chfvalue = false;
            this.cadvalue = false;
            this.dmvalue = false;
            this.hcmvalue = false;
            this.pahvalue = false;
            this.crhdvalue = false;
            this.hlpvalue = false;
            this.othervalue = false;

          }

          if (this.temptype == "CHF") {
            this.cpvalue = false;
            this.htnvalue = false;
            this.chfvalue = true;
            this.cadvalue = false;
            this.dmvalue = false;
            this.hcmvalue = false;
            this.pahvalue = false;
            this.crhdvalue = false;
            this.hlpvalue = false;
            this.othervalue = false;

          }


          if (this.temptype == "CAD") {
            this.cpvalue = false;
            this.htnvalue = false;
            this.chfvalue = false;
            this.cadvalue = true;
            this.dmvalue = false;
            this.hcmvalue = false;
            this.pahvalue = false;
            this.crhdvalue = false;
            this.hlpvalue = false;
            this.othervalue = false;

          }

          if (this.temptype == "HTN_NO_CAD_CHF") {
            this.cpvalue = false;
            this.htnvalue = true;
            this.chfvalue = false;
            this.cadvalue = false;
            this.dmvalue = false;
            this.hcmvalue = false;
            this.pahvalue = false;
            this.crhdvalue = false;
            this.hlpvalue = false;
            this.othervalue = false;

          }

          if (this.temptype == "DM_NO_CAD_CHF") {
            this.cpvalue = false;
            this.htnvalue = false;
            this.chfvalue = false;
            this.cadvalue = false;
            this.dmvalue = true;
            this.hcmvalue = false;
            this.pahvalue = false;
            this.crhdvalue = false;
            this.hlpvalue = false;
            this.othervalue = false;

          }

          if (this.temptype == "HCM") {
            this.cpvalue = false;
            this.htnvalue = false;
            this.chfvalue = false;
            this.cadvalue = false;
            this.dmvalue = false;
            this.hcmvalue = true;
            this.pahvalue = false;
            this.crhdvalue = false;
            this.hlpvalue = false;
            this.othervalue = false;

          }

          if (this.temptype == "PAH") {
            this.cpvalue = false;
            this.htnvalue = false;
            this.chfvalue = false;
            this.cadvalue = false;
            this.dmvalue = false;
            this.hcmvalue = false;
            this.pahvalue = true;
            this.crhdvalue = false;
            this.hlpvalue = false;
            this.othervalue = false;

          }

          if (this.temptype == "CRHD") {
            this.cpvalue = false;
            this.htnvalue = false;
            this.chfvalue = false;
            this.cadvalue = false;
            this.dmvalue = false;
            this.hcmvalue = false;
            this.pahvalue = false;
            this.crhdvalue = true;
            this.hlpvalue = false;
            this.othervalue = false;

          }


          if (this.temptype == "HLP_WITH_NO_CAD") {
            this.cpvalue = false;
            this.htnvalue = false;
            this.chfvalue = false;
            this.cadvalue = false;
            this.dmvalue = false;
            this.hcmvalue = false;
            this.pahvalue = false;
            this.crhdvalue = false;
            this.hlpvalue = true;
            this.othervalue = false;

          }

          if (this.temptype == "Chest_Pain") {
            this.cpvalue = true;
            this.htnvalue = false;
            this.chfvalue = false;
            this.cadvalue = false;
            this.dmvalue = false;
            this.hcmvalue = false;
            this.pahvalue = false;
            this.crhdvalue = false;
            this.hlpvalue = false;
            this.othervalue = false;

          }

          if (this.temptype == "Other") {
            this.cpvalue = false;
            this.htnvalue = false;
            this.chfvalue = false;
            this.cadvalue = false;
            this.dmvalue = false;
            this.hcmvalue = false;
            this.pahvalue = false;
            this.crhdvalue = false;
            this.hlpvalue = false;
            this.othervalue = true;

          }
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
          if (this.Appointmentdetails.data.Type.length > 0) {

          } else {
            return
          }

          if (this.Appointmentdetails.data.FollowUpDate) {
            this.Appointmentdetails.data.FollowUpDate = moment.unix(this.Appointmentdetails.data.FollowUpDate).format("DD-MM-YYYY");
            this.Appointmentdetails.data[this.Appointmentdetails.data.Type].FollowUpDate = this.Appointmentdetails.data.FollowUpDate;
          }

          if (this.Appointmentdetails.data.Type == "CHF") {
            this.appointmentForm.patchValue({
              AppointmentID: this.Appointmentdetails.data.AppointmentID,
              BP: this.Appointmentdetails.data.CHF.BP,
              PR: this.Appointmentdetails.data.CHF.PR,
              BGH: this.Appointmentdetails.data.CHF.BGH,
              Notes: this.Appointmentdetails.data.CHF.Notes,
              Investigations: this.Appointmentdetails.data.CHF.Investigations,
              Diagnosis: this.Appointmentdetails.data.CHF.Diagnosis,
              FollowUp: this.Appointmentdetails.data.CHF.FollowUp,
              FollowUpDate: this.Appointmentdetails.data.CHF.FollowUpDate,
              Prescription: this.Appointmentdetails.data.CHF.Prescription,
              Diet: this.Appointmentdetails.data.CHF.Diet,
              CAG: this.Appointmentdetails.data.CHF.CAG,
              RiskFactors: this.Appointmentdetails.data.CHF.RiskFactors,
              HeartFailure: this.Appointmentdetails.data.CHF.HeartFailure


            });









            this.RISKArray1 = this.RiskFactorFormArray = this.Appointmentdetails.data.CHF.RiskFactors;
            this.RISKArray2 = this.HeartFailureFormArray = this.Appointmentdetails.data.CHF.HeartFailure;
            this.RISKArray3 = this.NYHAFormArray = this.Appointmentdetails.data.CHF.NYHA;
            this.RISKArray4 = this.StageFormArray = this.Appointmentdetails.data.CHF.Stage;
            this.RISKArray5 = this.DIAGNOSISFormArray = this.Appointmentdetails.data.CHF.DIAGNOSIS;


            this.htnchecked = this.Appointmentdetails.data.CHF.RiskFactors.includes("HTN")
            this.DMchecked = this.Appointmentdetails.data.CHF.RiskFactors.includes("DM")
            this.HLPchecked = this.Appointmentdetails.data.CHF.RiskFactors.includes("HLP")
            this.SMOKERchecked = this.Appointmentdetails.data.CHF.RiskFactors.includes("SMOKER")
            this.FHCADchecked = this.Appointmentdetails.data.CHF.RiskFactors.includes("FHCAD")
            //------Heart Failure---//
            this.HFPEFchecked = this.Appointmentdetails.data.CHF.HeartFailure.includes("HFPEF");
            this.HFREFchecked = this.Appointmentdetails.data.CHF.HeartFailure.includes("HFREF");
            this.COMPANSATEDCHFchecked = this.Appointmentdetails.data.CHF.HeartFailure.includes("COMPANSATED CHF");
            this.DECOMPANSATEDCHFchecked = this.Appointmentdetails.data.CHF.HeartFailure.includes("DECOMPANSATED CHF");
            //--------NYHA---------//

            this.NYHAIchecked = this.Appointmentdetails.data.CHF.NYHA.includes("NYHA-I");
            this.NYHAIIchecked = this.Appointmentdetails.data.CHF.NYHA.includes("NYHA-II");
            this.NYHAIIIchecked = this.Appointmentdetails.data.CHF.NYHA.includes("NYHA-III");
            this.NYHAIVchecked = this.Appointmentdetails.data.CHF.NYHA.includes("NYHA-IV");
            //--------Stage--------// 

            this.STAGEAchecked = this.Appointmentdetails.data.CHF.Stage.includes("STAGE-A");
            this.STAGEBchecked = this.Appointmentdetails.data.CHF.Stage.includes("STAGE-B");
            this.STAGECchecked = this.Appointmentdetails.data.CHF.Stage.includes("STAGE-C");
            this.STAGEDchecked = this.Appointmentdetails.data.CHF.Stage.includes("STAGE-D");
            //--------DIAGNOSIS--------// 

            this.ICMPchecked = this.Appointmentdetails.data.CHF.DIAGNOSIS.includes("ICMP");
            this.DCMchecked = this.Appointmentdetails.data.CHF.DIAGNOSIS.includes("DCM");
            this.CRHDchecked = this.Appointmentdetails.data.CHF.DIAGNOSIS.includes("CRHD");
            this.RCMPchecked = this.Appointmentdetails.data.CHF.DIAGNOSIS.includes("RCMP");
            this.PPAHchecked = this.Appointmentdetails.data.CHF.DIAGNOSIS.includes("P-PAH");
            this.SECchecked = this.Appointmentdetails.data.CHF.DIAGNOSIS.includes("SEC");
            this.OTHERSchecked = this.Appointmentdetails.data.CHF.DIAGNOSIS.includes("OTHERS");


          }




          //-----RiskFactors--//

          if (this.Appointmentdetails.data.Type == "CAD") {

            this.CADappointmentForm.patchValue({
              AppointmentID: this.Appointmentdetails.data.AppointmentID,
              BP1: this.Appointmentdetails.data.CAD.BP,
              PR1: this.Appointmentdetails.data.CAD.PR,
              BGH1: this.Appointmentdetails.data.CAD.BGH,
              Notes1: this.Appointmentdetails.data.CAD.Notes,
              Investigations1: this.Appointmentdetails.data.CAD.Investigations,
              Diagnosis1: this.Appointmentdetails.data.CAD.Diagnosis,
              FollowUp1: this.Appointmentdetails.data.CAD.FollowUp,
              FollowUpDate1: this.Appointmentdetails.data.CAD.FollowUpDate,
              Prescription1: this.Appointmentdetails.data.CAD.Prescription,
              Diet1: this.Appointmentdetails.data.CAD.Diet,
              CAG1: this.Appointmentdetails.data.CAD.CAG,

              // RiskFactors: this.CADappointmentForm.value.RiskFactors1,
              // APCLASS: this.CADappointmentForm.value.APCLASS,
              // AdvicePostCAG: this.CADappointmentForm.value.AdvicePostCAG,
              PostCABG: this.Appointmentdetails.data.CAD.PostCABG,
              PostCABGText: this.Appointmentdetails.data.CAD.PostCABGText,
              PostPTCA: this.Appointmentdetails.data.CAD.PostPTCA,
              PostPTCAText: this.Appointmentdetails.data.CAD.PostPTCAText,

            });

            this.CAD1Array = this.RiskFactorFormArrayCad = this.Appointmentdetails.data.CAD.RiskFactors;
            this.CAD2Array = this.RiskFactorFormArrayAP = this.Appointmentdetails.data.CAD.APCLASS;
            this.CAD3Array = this.RiskFactorFormArrayAdviceCag = this.Appointmentdetails.data.CAD.AdvicePostCAG;

            //-----RiskFactors--CAD---------//
            this.htnchecked1 = this.Appointmentdetails.data.CAD.RiskFactors.includes("HTN")
            this.DMchecked1 = this.Appointmentdetails.data.CAD.RiskFactors.includes("DM")
            this.HLPchecked1 = this.Appointmentdetails.data.CAD.RiskFactors.includes("HLP")
            this.SMOKERchecked1 = this.Appointmentdetails.data.CAD.RiskFactors.includes("SMOKER")
            this.FHCADchecked1 = this.Appointmentdetails.data.CAD.RiskFactors.includes("FHCAD")

            //--------APCLASS--------// 

            this.APCLASSIchecked = this.Appointmentdetails.data.CAD.APCLASS.includes("AP-CLASS-I");
            this.APCLASSIIchecked = this.Appointmentdetails.data.CAD.APCLASS.includes("AP-CLASS-II");
            this.APCLASSIIIchecked = this.Appointmentdetails.data.CAD.APCLASS.includes("AP-CLASS-III");
            this.APCLASSIVchecked = this.Appointmentdetails.data.CAD.APCLASS.includes("AP-CLASS-IV");


            //--------------ADVICED post CAG-----
            this.MMchecked = this.Appointmentdetails.data.CAD.AdvicePostCAG.includes("MM");
            this.CABGchecked = this.Appointmentdetails.data.CAD.AdvicePostCAG.includes("CABG");
            this.PTCAchecked = this.Appointmentdetails.data.CAD.AdvicePostCAG.includes("PTCA");
            this.OTHERchecked = this.Appointmentdetails.data.CAD.AdvicePostCAG.includes("OTHERS");

          }



          if (this.Appointmentdetails.data.Type == "HTN_NO_CAD_CHF") {
            this.HTN_NO_CAD_CHFappointmentForm.patchValue({
              AppointmentID: this.Appointmentdetails.data.AppointmentID,

              // RiskFactors: this.HTN_NO_CAD_CHFappointmentForm.value.RiskFactors2,
              // Kidney: this.kidneyObj,
              // CVS: this.CVSObject,
              HTN_Since: this.Appointmentdetails.data.HTN_NO_CAD_CHF.HTN_Since,
              // HTN_RETINOPATHY_OPTHAL_OPINION: this.HTN_RETINOPATHY_OPTHAL_OPINIONValue,
              // OLD_CVA_OR_TIA: this.OLD_CVA_OR_TIAValue,
              ECHO: this.Appointmentdetails.data.HTN_NO_CAD_CHF.ECHO,

              CREATININE_GFR: this.Appointmentdetails.data.HTN_NO_CAD_CHF.Kidney.CREATININE_GFR,
              PR_CR: this.Appointmentdetails.data.HTN_NO_CAD_CHF.Kidney.PR_CR,

              LVH_ECG: this.Appointmentdetails.data.HTN_NO_CAD_CHF.CVS.LVH_ECG,
              LV_MASS: this.Appointmentdetails.data.HTN_NO_CAD_CHF.CVS.LV_MASS,
              LVEF: this.Appointmentdetails.data.HTN_NO_CAD_CHF.CVS.LVEF,


              BP2: this.Appointmentdetails.data.HTN_NO_CAD_CHF.BP,
              PR2: this.Appointmentdetails.data.HTN_NO_CAD_CHF.PR,
              BGH2: this.Appointmentdetails.data.HTN_NO_CAD_CHF.BGH,
              Notes2: this.Appointmentdetails.data.HTN_NO_CAD_CHF.Notes,
              Investigations2: this.Appointmentdetails.data.HTN_NO_CAD_CHF.Investigations,
              Diagnosis2: this.Appointmentdetails.data.HTN_NO_CAD_CHF.Diagnosis,
              FollowUp2: this.Appointmentdetails.data.HTN_NO_CAD_CHF.FollowUp,
              FollowUpDate2: this.Appointmentdetails.data.HTN_NO_CAD_CHF.FollowUpDate,
              Prescription2: this.Appointmentdetails.data.HTN_NO_CAD_CHF.Prescription,
              Diet2: this.Appointmentdetails.data.HTN_NO_CAD_CHF.Diet,


            });

            this.RiskFactorHTNriskFormArray = this.HTNArray = this.Appointmentdetails.data.HTN_NO_CAD_CHF.RiskFactors;


            //-----RiskFactors--//
            this.htnchecked2 = this.Appointmentdetails.data.HTN_NO_CAD_CHF.RiskFactors.includes("HTN")
            this.DMchecked2 = this.Appointmentdetails.data.HTN_NO_CAD_CHF.RiskFactors.includes("DM")
            this.HLPchecked2 = this.Appointmentdetails.data.HTN_NO_CAD_CHF.RiskFactors.includes("HLP")
            this.SMOKERchecked2 = this.Appointmentdetails.data.HTN_NO_CAD_CHF.RiskFactors.includes("SMOKER")
            this.FHCADchecked2 = this.Appointmentdetails.data.HTN_NO_CAD_CHF.RiskFactors.includes("FHCAD")
            // this.ckdvalue=this.Appointmentdetails.data.HTN_NO_CAD_CHF.RiskFactors;


            if (this.Appointmentdetails.data.HTN_NO_CAD_CHF.Kidney.CKD == "CKD") {
              this.CKDchecked = true;
              this.ckdvalue = "CKD";
            }

            if (this.Appointmentdetails.data.HTN_NO_CAD_CHF.HTN_RETINOPATHY_OPTHAL_OPINION == "HTN_RETINOPATHY_OPTHAL_OPINION") {
              this.HTNROPTHALOPINIONchecked = true;
              this.HTN_RETINOPATHY_OPTHAL_OPINIONValue = "HTN_RETINOPATHY_OPTHAL_OPINION"
            }
            if (this.Appointmentdetails.data.HTN_NO_CAD_CHF.OLD_CVA_OR_TIA == "OLD_CVA_OR_TIA") {
              this.OLDCVAchecked = true;
              this.OLD_CVA_OR_TIAValue = "OLD_CVA_OR_TIA"
            }

            //  this.HTNROPTHALOPINIONchecked=this.Appointmentdetails.data.HTN_NO_CAD_CHF.HTN_RETINOPATHY_OPTHAL_OPINION.include("HTN RETINOPATHY, OPTHAL OPINION")

            //  this.OLDCVAchecked=this.Appointmentdetails.data.HTN_NO_CAD_CHF.OLD_CVA_OR_TIA.include("OLD CVA OR TIA")


          }

          if (this.Appointmentdetails.data.Type == "DM_NO_CAD_CHF") {

            this.DM_NO_CAD_CHFappointmentForm.patchValue({
              AppointmentID: this.Appointmentdetails.data.AppointmentID,

              DM_Since: this.Appointmentdetails.data.DM_NO_CAD_CHF.DM_Since,

              CREATININE_GFR1: this.Appointmentdetails.data.DM_NO_CAD_CHF.Kidney.CREATININE_GFR,
              PR_CR1: this.Appointmentdetails.data.DM_NO_CAD_CHF.Kidney.PR_CR,

              LVH_ECG1: this.Appointmentdetails.data.DM_NO_CAD_CHF.CVS.LVH_ECG,
              LV_MASS1: this.Appointmentdetails.data.DM_NO_CAD_CHF.CVS.LV_MASS,
              LVEF1: this.Appointmentdetails.data.DM_NO_CAD_CHF.CVS.LVEF,


              ECHO1: this.Appointmentdetails.data.DM_NO_CAD_CHF.ECHO,
              BMI: this.Appointmentdetails.data.DM_NO_CAD_CHF.BMI,
              HBA1C: this.Appointmentdetails.data.DM_NO_CAD_CHF.HBA1C,

              BP3: this.Appointmentdetails.data.DM_NO_CAD_CHF.BP,
              PR3: this.Appointmentdetails.data.DM_NO_CAD_CHF.PR,
              BGH3: this.Appointmentdetails.data.DM_NO_CAD_CHF.BGH,
              Notes3: this.Appointmentdetails.data.DM_NO_CAD_CHF.Notes,
              Investigations3: this.Appointmentdetails.data.DM_NO_CAD_CHF.Investigations,
              Diagnosis3: this.Appointmentdetails.data.DM_NO_CAD_CHF.Diagnosis,
              FollowUp3: this.Appointmentdetails.data.DM_NO_CAD_CHF.FollowUp,
              FollowUpDate3: this.Appointmentdetails.data.DM_NO_CAD_CHF.FollowUpDate,
              Prescription3: this.Appointmentdetails.data.DM_NO_CAD_CHF.Prescription,
              Diet3: this.Appointmentdetails.data.DM_NO_CAD_CHF.Diet,


            });


            this.RiskFactorDMriskFormArray = this.DMArray1 = this.Appointmentdetails.data.DM_NO_CAD_CHF.RiskFactors;

            if (this.Appointmentdetails.data.DM_NO_CAD_CHF.Kidney.CKD == "CKD") {

              this.CKDchecked1 = true;
              this.cdkDMFormValue = "CKD";
            }

            if (this.Appointmentdetails.data.DM_NO_CAD_CHF.HTN_RETINOPATHY_OPTHAL_OPINION == "HTN_RETINOPATHY_OPTHAL_OPINION") {
              this.HTNRETINOPATHYchecked = true;
              this.dmHTN_RETINOPATHYValue = "HTN_RETINOPATHY_OPTHAL_OPINION"
            }
            if (this.Appointmentdetails.data.DM_NO_CAD_CHF.OLD_CVA_OR_TIA == "OLD_CVA_OR_TIA") {
              this.OLDCVADMchecked = true;
              this.OLD_CVA_OR_TIAvalue = "OLD_CVA_OR_TIA"
            }


            this.htnchecked3 = this.Appointmentdetails.data.DM_NO_CAD_CHF.RiskFactors.includes("HTN")
            this.DMchecked3 = this.Appointmentdetails.data.DM_NO_CAD_CHF.RiskFactors.includes("DM")
            this.HLPchecked3 = this.Appointmentdetails.data.DM_NO_CAD_CHF.RiskFactors.includes("HLP")
            this.SMOKERchecked3 = this.Appointmentdetails.data.DM_NO_CAD_CHF.RiskFactors.includes("SMOKER")
            this.FHCADchecked3 = this.Appointmentdetails.data.DM_NO_CAD_CHF.RiskFactors.includes("FHCAD")


          }



          if (this.Appointmentdetails.data.Type == "HCM") {

            this.HCMappointmentForm.patchValue({
              AppointmentID: this.Appointmentdetails.data.AppointmentID,

              //  CVS: this.CVSObject,
              CREATININE_GFR2: this.Appointmentdetails.data.HCM.CREATININE_GFR,
              DIAGNOSED_IN: this.Appointmentdetails.data.HCM.DIAGNOSED_IN,
              //  //   GENE_STUDY: this.HCMGenestudyArrayValue,
              SYMTOM_STATUS: this.Appointmentdetails.data.HCM.SYMTOM_STATUS,
              ECHO2: this.Appointmentdetails.data.HCM.ECHO,


              LVH_ECG2: this.Appointmentdetails.data.HCM.CVS.LVH_ECG,
              LV_MASS2: this.Appointmentdetails.data.HCM.CVS.LV_MASS,
              LVEF2: this.Appointmentdetails.data.HCM.CVS.LVEF,

              BP4: this.Appointmentdetails.data.HCM.BP,
              PR4: this.Appointmentdetails.data.HCM.PR,
              BGH4: this.Appointmentdetails.data.HCM.BGH,
              Notes4: this.Appointmentdetails.data.HCM.Notes,
              Investigations4: this.Appointmentdetails.data.HCM.Investigations,
              Diagnosis4: this.Appointmentdetails.data.HCM.Diagnosis,
              FollowUp4: this.Appointmentdetails.data.HCM.FollowUp,
              FollowUpDate4: this.Appointmentdetails.data.HCM.FollowUpDate,
              Prescription4: this.Appointmentdetails.data.HCM.Prescription,
              Diet4: this.Appointmentdetails.data.HCM.Diet



            });

            this.htnchecked4 = this.Appointmentdetails.data.HCM.RiskFactors.includes("HTN")
            this.DMchecked4 = this.Appointmentdetails.data.HCM.RiskFactors.includes("DM")
            this.HLPchecked4 = this.Appointmentdetails.data.HCM.RiskFactors.includes("HLP")
            this.SMOKERchecked4 = this.Appointmentdetails.data.HCM.RiskFactors.includes("SMOKER")
            this.FHCADchecked4 = this.Appointmentdetails.data.HCM.RiskFactors.includes("FHCAD")

            this.HCMRiskFactoryArray = this.HCMArray1 = this.Appointmentdetails.data.HCM.RiskFactors;

            if (this.Appointmentdetails.data.HCM.GENE_STUDY == "GENE_STUDY") {
              this.GENESTUDYchecked = true;
              this.HCMGenestudyArrayValue = "GENE_STUDY";
            }




          }


          if (this.Appointmentdetails.data.Type == "PAH") {

            this.PAHappointmentForm.patchValue({
              AppointmentID: this.Appointmentdetails.data.AppointmentID,

              EF: this.Appointmentdetails.data.PAH.EF,
              PASP: this.Appointmentdetails.data.PAH.PASP,
              PADP: this.Appointmentdetails.data.PAH.PADP,
              RV_FUNCTION: this.Appointmentdetails.data.PAH.RV_FUNCTION,

              BP5: this.Appointmentdetails.data.PAH.BP,
              PR5: this.Appointmentdetails.data.HCM.PR,
              BGH5: this.Appointmentdetails.data.HCM.BGH,
              Notes5: this.Appointmentdetails.data.HCM.Notes,
              Investigations5: this.Appointmentdetails.data.HCM.Investigations,
              Diagnosis5: this.Appointmentdetails.data.HCM.Diagnosis,
              FollowUp5: this.Appointmentdetails.data.HCM.FollowUp,
              FollowUpDate5: this.Appointmentdetails.data.HCM.FollowUpDate,
              Prescription5: this.Appointmentdetails.data.HCM.Prescription,
              Diet5: this.Appointmentdetails.data.HCM.Diet

              //   
              //     DIAGNOSIS: this.PAHDiagnosisArray,

            });


            this.PAHRiskArray = this.PAHArray1 = this.Appointmentdetails.data.PAH.RiskFactors;
            this.PAHClassArray = this.PAHArray2 = this.Appointmentdetails.data.PAH.Class;

            this.PAHDiagnosisArray = this.PAHArray3 = this.Appointmentdetails.data.PAH.DIAGNOSIS;

            if (this.Appointmentdetails.data.PAH.COMPANSATED == "COMPANSATED") {
              this.PAHCOMPANSATEDchecked = true;
              this.PAHCOMPANSATEDValue = "COMPANSATED"
            }
            if (this.Appointmentdetails.data.PAH.DECOMPANSATED == "DECOMPANSATED") {
              this.PAHDECOMPANSATEDchecked = true;
              this.PAHDECOMPANSATEDValue = "DECOMPANSATED"
            }

            this.htnchecked5 = this.Appointmentdetails.data.PAH.RiskFactors.includes("HTN")
            this.DMchecked5 = this.Appointmentdetails.data.PAH.RiskFactors.includes("DM")
            this.HLPchecked5 = this.Appointmentdetails.data.PAH.RiskFactors.includes("HLP")
            this.SMOKERchecked5 = this.Appointmentdetails.data.PAH.RiskFactors.includes("SMOKER")
            this.FHCADchecked5 = this.Appointmentdetails.data.PAH.RiskFactors.includes("FHCAD")


            // this.PAHCOMPANSATEDchecked = this.Appointmentdetails.data.PAH.COMPANSATED.includes("COMPANSATED")

            // this.PAHDECOMPANSATEDchecked = this.Appointmentdetails.data.PAH.DECOMPANSATED.includes("DECOMPANSATED")

            this.DIAGNOSISPAHPRIMARYchecked = this.Appointmentdetails.data.PAH.DIAGNOSIS.includes("PRIMARY")
            this.DIAGNOSISPAHCTEPchecked = this.Appointmentdetails.data.PAH.DIAGNOSIS.includes("CTEP")
            this.DIAGNOSISPAHSECchecked = this.Appointmentdetails.data.PAH.DIAGNOSIS.includes("SEC TO COPD")
            this.DIAGNOSISPAHDUEchecked = this.Appointmentdetails.data.PAH.DIAGNOSIS.includes("DUE TO UNTREATED CHD")
            this.DIAGNOSISPAHotherchecked = this.Appointmentdetails.data.PAH.DIAGNOSIS.includes("OTHER")

            this.CLASSIPAHchecked = this.Appointmentdetails.data.PAH.Class.includes("CLASS-I");
            this.CLASSIIPAHchecked = this.Appointmentdetails.data.PAH.Class.includes("CLASS-II");
            this.CLASSIIIPAHchecked = this.Appointmentdetails.data.PAH.Class.includes("CLASS-III");
            this.CLASSIVPAHchecked = this.Appointmentdetails.data.PAH.Class.includes("CLASS-IV");

          }

          if (this.Appointmentdetails.data.Type == "CRHD") {

            this.CRHDappointmentForm.patchValue({
              AppointmentID: this.Appointmentdetails.data.AppointmentID,

              CRHD_SINCE: this.Appointmentdetails.data.CRHD.CRHD_SINCE,
              MITRAL_STENOSIS: this.Appointmentdetails.data.CRHD.MITRAL_STENOSIS,

              MITRAL_REGURGITATION: this.Appointmentdetails.data.CRHD.MITRAL_REGURGITATION,
              AORTIC_STENOSIS: this.Appointmentdetails.data.CRHD.AORTIC_STENOSIS,
              AORTIC_REGURGITATION: this.Appointmentdetails.data.CRHD.AORTIC_REGURGITATION,
              TRICUSPID_STENOSIS: this.Appointmentdetails.data.CRHD.TRICUSPID_STENOSIS,


              TRICUSPID_REGURGITATION: this.Appointmentdetails.data.CRHD.TRICUSPID_REGURGITATION,
              PULMONARY_STENOSIS: this.Appointmentdetails.data.CRHD.PULMONARY_STENOSIS,
              PULMONARY_REGURGITATION: this.Appointmentdetails.data.CRHD.PULMONARY_REGURGITATION,
              PAH: this.Appointmentdetails.data.CRHD.PAH,
              LVEF: this.Appointmentdetails.data.CRHD.LVEF6,
              LVEDD: this.Appointmentdetails.data.CRHD.LVEDD,
              LVESD: this.Appointmentdetails.data.CRHD.LVESD,
              RV_FUNCTION6: this.Appointmentdetails.data.CRHD.RV_FUNCTION,
              ECHO6: this.Appointmentdetails.data.CRHD.ECHO,


              BP6: this.Appointmentdetails.data.CRHD.BP,
              PR6: this.Appointmentdetails.data.CRHD.PR,
              BGH6: this.Appointmentdetails.data.CRHD.BGH,
              Notes6: this.Appointmentdetails.data.CRHD.Notes,
              Investigations6: this.Appointmentdetails.data.CRHD.Investigations,
              Diagnosis6: this.Appointmentdetails.data.CRHD.Diagnosis,
              FollowUp6: this.Appointmentdetails.data.CRHD.FollowUp,
              FollowUpDate6: this.Appointmentdetails.data.CRHD.FollowUpDate,
              Prescription6: this.Appointmentdetails.data.CRHD.Prescription,
              Diet6: this.Appointmentdetails.data.CRHD.Diet


            });

            this.CRHDArray1 = this.CRHDArray = this.Appointmentdetails.data.CRHD.RiskFactors;

            this.CRHDnyhaArray = this.CRHDArray2 = this.Appointmentdetails.data.CRHD.NYHS;


            this.htnchecked6 = this.Appointmentdetails.data.CRHD.RiskFactors.includes("HTN")
            this.DMchecked6 = this.Appointmentdetails.data.CRHD.RiskFactors.includes("DM")
            this.HLPchecked6 = this.Appointmentdetails.data.CRHD.RiskFactors.includes("HLP")
            this.SMOKERchecked6 = this.Appointmentdetails.data.CRHD.RiskFactors.includes("SMOKER")
            this.FHCADchecked6 = this.Appointmentdetails.data.CRHD.RiskFactors.includes("FHCAD")

            //this.OLD_CVA_OR_TIA_CAROTIDchecked == this.Appointmentdetails.data.CRHD.OLD_CVA_OR_TIA_CAROTID.includes("OLD_CVA_OR_TIA_CAROTID")

            if (this.Appointmentdetails.data.CRHD.OLD_CVA_OR_TIA_CAROTID == "OLD_CVA_OR_TIA_CAROTID") {
              this.OLD_CVA_OR_TIA_CAROTIDchecked = true;
              this.CRHDCarotidValue = "OLD_CVA_OR_TIA_CAROTID";
            }

            this.NYHAICRHDchecked = this.Appointmentdetails.data.CRHD.NYHS.includes("NYHA-I")
            this.NYHAIICRHDchecked = this.Appointmentdetails.data.CRHD.NYHS.includes("NYHA-II")
            this.NYHAIIICRHDchecked = this.Appointmentdetails.data.CRHD.NYHS.includes("NYHA-III")
            this.NYHAIVCRHDchecked = this.Appointmentdetails.data.CRHD.NYHS.includes("NYHA-IV")

            if (this.Appointmentdetails.data.CRHD.SINUS_RYTHM == "SINUS_RYTHM") {
              this.SINUSCRHDchecked = true;

              this.CRHDSinusVAlue = "SINUS_RYTHM";
            }

            if (this.Appointmentdetails.data.CRHD.AF == "AF") {

              this.AFCRHDchecked = true;
              this.CRHDafValue = "AF";

            }

            if (this.Appointmentdetails.data.CRHD.HO_IE == "HO_IE") {

              this.HOIECRHDchecked = true;
              this.CRHDHOValue = "HO_IE";

            }






          }


          if (this.Appointmentdetails.data.Type == "HLP_WITH_NO_CAD") {

            this.HLP_WITH_NO_CADappointmentForm.patchValue({
              AppointmentID: this.Appointmentdetails.data.AppointmentID,

              HLP_SINCE: this.Appointmentdetails.data.HLP_WITH_NO_CAD.HLP_SINCE,
              TOTAL_CH: this.Appointmentdetails.data.HLP_WITH_NO_CAD.TOTAL_CH,


              LDL_C: this.Appointmentdetails.data.HLP_WITH_NO_CAD.LDL_C,
              TG: this.Appointmentdetails.data.HLP_WITH_NO_CAD.TG,
              HDL_C: this.Appointmentdetails.data.HLP_WITH_NO_CAD.HDL_C,
              LPA: this.Appointmentdetails.data.HLP_WITH_NO_CAD.LPA,
              SUBFRACTION_OF_LDL: this.Appointmentdetails.data.HLP_WITH_NO_CAD.SUBFRACTION_OF_LDL,
              ANY_EXT_SIGNS: this.Appointmentdetails.data.HLP_WITH_NO_CAD.ANY_EXT_SIGNS,

              BP7: this.Appointmentdetails.data.HLP_WITH_NO_CAD.BP,
              PR7: this.Appointmentdetails.data.HLP_WITH_NO_CAD.PR,
              BGH7: this.Appointmentdetails.data.HLP_WITH_NO_CAD.BGH,
              Notes7: this.Appointmentdetails.data.HLP_WITH_NO_CAD.Notes,
              Investigations7: this.Appointmentdetails.data.HLP_WITH_NO_CAD.Investigations,
              Diagnosis7: this.Appointmentdetails.data.HLP_WITH_NO_CAD.Diagnosis,
              FollowUp7: this.Appointmentdetails.data.HLP_WITH_NO_CAD.FollowUp,
              FollowUpDate7: this.Appointmentdetails.data.HLP_WITH_NO_CAD.FollowUpDate,
              Prescription7: this.Appointmentdetails.data.HLP_WITH_NO_CAD.Prescription,
              Diet7: this.Appointmentdetails.data.HLP_WITH_NO_CAD.Diet



            });

            this.HLPArray = this.HLP_WITH_NO_CADRiskArray = this.Appointmentdetails.data.HLP_WITH_NO_CAD.RiskFactors;

            this.htnchecked7 = this.Appointmentdetails.data.HLP_WITH_NO_CAD.RiskFactors.includes("HTN")
            this.DMchecked7 = this.Appointmentdetails.data.HLP_WITH_NO_CAD.RiskFactors.includes("DM")
            this.HLPchecked7 = this.Appointmentdetails.data.HLP_WITH_NO_CAD.RiskFactors.includes("HLP")
            this.SMOKERchecked7 = this.Appointmentdetails.data.HLP_WITH_NO_CAD.RiskFactors.includes("SMOKER")
            this.FHCADchecked7 = this.Appointmentdetails.data.HLP_WITH_NO_CAD.RiskFactors.includes("FHCAD")


          }

          if (this.Appointmentdetails.data.Type == "Chest_Pain") {



            this.Chest_PainappointmentForm.patchValue({
              AppointmentID: this.Appointmentdetails.data.AppointmentID,

              CHEST_PAIN: this.onChangeChestPainArray,
              //   ECG: this.onChangeECGChestPainArray,


              //   STRESS_TEST: this.onChangeChestPainStressTESTArray,
              //   CAG: this.onChangeCAGChestPainArray,
              ECHO8: this.Appointmentdetails.data.Chest_Pain.ECHO,
              BP8: this.Appointmentdetails.data.Chest_Pain.BP,
              PR8: this.Appointmentdetails.data.Chest_Pain.PR,
              BGH8: this.Appointmentdetails.data.Chest_Pain.BGH,
              Notes8: this.Appointmentdetails.data.Chest_Pain.Notes,
              Investigations8: this.Appointmentdetails.data.Chest_Pain.Investigations,
              Diagnosis8: this.Appointmentdetails.data.Chest_Pain.Diagnosis,
              FollowUp8: this.Appointmentdetails.data.Chest_Pain.FollowUp,
              FollowUpDate8: this.Appointmentdetails.data.Chest_Pain.FollowUpDate,
              Prescription8: this.Appointmentdetails.data.Chest_Pain.Prescription,
              Diet8: this.Appointmentdetails.data.Chest_Pain.Diet
            });


            this.ChestArray1 = this.ChestpainRiskArray = this.Appointmentdetails.data.Chest_Pain.RiskFactors

            this.ChestArray3 = this.onChangeECGChestPainArray = this.Appointmentdetails.data.Chest_Pain.ECG

            this.ChestArray5 = this.onChangeCAGChestPainArray = this.Appointmentdetails.data.Chest_Pain.CAG

            this.ChestArray2 = this.onChangeChestPainArray = this.Appointmentdetails.data.Chest_Pain.CHEST_PAIN

            this.ChestArray4 = this.onChangeChestPainStressTESTArray = this.Appointmentdetails.data.Chest_Pain.STRESS_TEST

            this.htnchecked8 = this.Appointmentdetails.data.Chest_Pain.RiskFactors.includes("HTN")
            this.DMchecked8 = this.Appointmentdetails.data.Chest_Pain.RiskFactors.includes("DM")
            this.HLPchecked8 = this.Appointmentdetails.data.Chest_Pain.RiskFactors.includes("HLP")
            this.SMOKERchecked8 = this.Appointmentdetails.data.Chest_Pain.RiskFactors.includes("SMOKER")
            this.FHCADchecked8 = this.Appointmentdetails.data.Chest_Pain.RiskFactors.includes("FHCAD")

            this.ECGChest1checked = this.Appointmentdetails.data.Chest_Pain.ECG.includes("ST/T WAVE CHANGES")
            this.ECGChest2checked = this.Appointmentdetails.data.Chest_Pain.ECG.includes("NON SPECIFIC ST/T WAVE CHANGES")
            this.ECGChest3checked = this.Appointmentdetails.data.Chest_Pain.ECG.includes("NORMAL ECG")

            this.stresstestchest2checked = this.Appointmentdetails.data.Chest_Pain.STRESS_TEST.includes("POSITIVE FOR INDUCIBLE ISCHEMIA")
            this.stresstestchest1checked = this.Appointmentdetails.data.Chest_Pain.STRESS_TEST.includes("NEGATIVE FOR INDUCIBLE ISCHEMIA")

            this.cagchest1checked = this.Appointmentdetails.data.Chest_Pain.CAG.includes("NORMAL")
            this.cagchest2checked = this.Appointmentdetails.data.Chest_Pain.CAG.includes("SVD")
            this.cagchest3checked = this.Appointmentdetails.data.Chest_Pain.CAG.includes("2 VD")
            this.cagchest4checked = this.Appointmentdetails.data.Chest_Pain.CAG.includes("3 VD")
            this.cagchest5checked = this.Appointmentdetails.data.Chest_Pain.CAG.includes("SLOW FLOW")

            this.chestpain1checked = this.Appointmentdetails.data.Chest_Pain.CHEST_PAIN.includes("TYPICAL")
            this.chestpain2checked = this.Appointmentdetails.data.Chest_Pain.CHEST_PAIN.includes("ATYPICAL")
            this.chestpain3checked = this.Appointmentdetails.data.Chest_Pain.CHEST_PAIN.includes("NOT S/O CAD")


          }




          if (this.Appointmentdetails.data.Type == "Other") {



            this.OthersappointmentForm.patchValue({
              AppointmentID: this.Appointmentdetails.data.AppointmentID,

              ECHO9: this.Appointmentdetails.data.Other.ECHO,
              BP9: this.Appointmentdetails.data.Other.BP,
              PR9: this.Appointmentdetails.data.Other.PR,
              BGH9: this.Appointmentdetails.data.Other.BGH,
              Notes9: this.Appointmentdetails.data.Other.Notes,
              Investigations9: this.Appointmentdetails.data.Other.Investigations,
              Diagnosis9: this.Appointmentdetails.data.Other.Diagnosis,
              FollowUp9: this.Appointmentdetails.data.Other.FollowUp,
              FollowUpDate9: this.Appointmentdetails.data.Other.FollowUpDate,
              Prescription9: this.Appointmentdetails.data.Other.Prescription,
              Diet9: this.Appointmentdetails.data.Other.Diet,

              OTHER_DATA: this.Appointmentdetails.data.Other.OTHER_DATA,

              PRESENTING_REASON: this.OtherReasonsArray,
              ECG: this.OtherECGArray,






            });

            this.OtherArray1 = this.OtherRiskArray = this.Appointmentdetails.data.Other.RiskFactors;
            this.OtherArray2 = this.OtherReasonsArray = this.Appointmentdetails.data.Other.PRESENTING_REASON;
            this.OtherArray3 = this.OtherECGArray = this.Appointmentdetails.data.Other.ECG;

            this.OtherArray4 = this.OtherStressArray = this.Appointmentdetails.data.Other.STRESS_TEST






            this.htnchecked9 = this.Appointmentdetails.data.Other.RiskFactors.includes("HTN")
            this.DMchecked9 = this.Appointmentdetails.data.Other.RiskFactors.includes("DM")
            this.HLPchecked9 = this.Appointmentdetails.data.Other.RiskFactors.includes("HLP")
            this.SMOKERchecked9 = this.Appointmentdetails.data.Other.RiskFactors.includes("SMOKER")
            this.FHCADchecked9 = this.Appointmentdetails.data.Other.RiskFactors.includes("FHCAD")

            this.preasonchecked = this.Appointmentdetails.data.Other.PRESENTING_REASON.includes("CHEST PAIN")

            this.preason1checked = this.Appointmentdetails.data.Other.PRESENTING_REASON.includes("SOB")

            this.preason2checked = this.Appointmentdetails.data.Other.PRESENTING_REASON.includes("PRE-OP EVALUATION")

            this.ecg1others = this.Appointmentdetails.data.Other.ECG.includes("ST/T WAVE CHANGES")
            this.ecg2others = this.Appointmentdetails.data.Other.ECG.includes("NON SPECIFIC ST/T WAVE CHANGES")
            this.ecg3others = this.Appointmentdetails.data.Other.ECG.includes("NORMAL ECG")

            // this.stress1 =false;
            // this.stress2 =false;
            this.stress2 = this.Appointmentdetails.data.Other.STRESS_TEST.includes("NEGATIVE FOR INDUCIBLE ISCHEMIA")
            this.stress1 = this.Appointmentdetails.data.Other.STRESS_TEST.includes("POSITIVE FOR INDUCIBLE ISCHEMIA")




          }



        } else {
          alert(data.message);
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ")
      });

  }
  CurrentAppointmentDate;
  obj = <any>{}
  PreviousPrescription = []
  GetPrescriptionData(MRNumber) {

    this.obj.MRNumber = MRNumber

    if (this.Appointmentdetails) {
      this.CurrentAppointmentDate = parseInt(this.Appointmentdetails.data.Date);
    }
    this.apicalls.viewPatientsPrescription(this.obj).subscribe(

      data => {
        if (data.code == "S001") {
          if (this.CurrentAppointmentDate) {
            for (let i = 0; i < data.data.length; i++) {
              console.log("qq", this.Appointmentdetails.data.Type)
              if (parseInt(data.data[i].Date) < this.CurrentAppointmentDate && data.data[i].Type != this.Appointmentdetails.data.Type) {
                if (data.data[i].Type == "CHF") {
                  this.appointmentForm.patchValue({
                    AppointmentID: data.data[i].AppointmentID,
                    BP: data.data[i].CHF.BP,
                    PR: data.data[i].CHF.PR,
                    BGH: data.data[i].CHF.BGH,
                    Notes: data.data[i].CHF.Notes,
                    Investigations: data.data[i].CHF.Investigations,
                    Diagnosis: data.data[i].CHF.Diagnosis,
                    FollowUp: data.data[i].CHF.FollowUp,
                    FollowUpDate: data.data[i].CHF.FollowUpDate,
                    Prescription: data.data[i].CHF.Prescription,
                    Diet: data.data[i].CHF.Diet,
                    CAG: data.data[i].CHF.CAG,
                    RiskFactors: data.data[i].CHF.RiskFactors,
                    HeartFailure: data.data[i].CHF.HeartFailure


                  });









                  this.RISKArray1 = this.RiskFactorFormArray = data.data[i].CHF.RiskFactors;
                  this.RISKArray2 = this.HeartFailureFormArray = data.data[i].CHF.HeartFailure;
                  this.RISKArray3 = this.NYHAFormArray = data.data[i].CHF.NYHA;
                  this.RISKArray4 = this.StageFormArray = data.data[i].CHF.Stage;
                  this.RISKArray5 = this.DIAGNOSISFormArray = data.data[i].CHF.DIAGNOSIS;


                  this.htnchecked = data.data[i].CHF.RiskFactors.includes("HTN")
                  this.DMchecked = data.data[i].CHF.RiskFactors.includes("DM")
                  this.HLPchecked = data.data[i].CHF.RiskFactors.includes("HLP")
                  this.SMOKERchecked = data.data[i].CHF.RiskFactors.includes("SMOKER")
                  this.FHCADchecked = data.data[i].CHF.RiskFactors.includes("FHCAD")
                  //------Heart Failure---//
                  this.HFPEFchecked = data.data[i].CHF.HeartFailure.includes("HFPEF");
                  this.HFREFchecked = data.data[i].CHF.HeartFailure.includes("HFREF");
                  this.COMPANSATEDCHFchecked = data.data[i].CHF.HeartFailure.includes("COMPANSATED CHF");
                  this.DECOMPANSATEDCHFchecked = data.data[i].CHF.HeartFailure.includes("DECOMPANSATED CHF");
                  //--------NYHA---------//

                  this.NYHAIchecked = data.data[i].CHF.NYHA.includes("NYHA-I");
                  this.NYHAIIchecked = data.data[i].CHF.NYHA.includes("NYHA-II");
                  this.NYHAIIIchecked = data.data[i].CHF.NYHA.includes("NYHA-III");
                  this.NYHAIVchecked = data.data[i].CHF.NYHA.includes("NYHA-IV");
                  //--------Stage--------// 

                  this.STAGEAchecked = data.data[i].CHF.Stage.includes("STAGE-A");
                  this.STAGEBchecked = data.data[i].CHF.Stage.includes("STAGE-B");
                  this.STAGECchecked = data.data[i].CHF.Stage.includes("STAGE-C");
                  this.STAGEDchecked = data.data[i].CHF.Stage.includes("STAGE-D");
                  //--------DIAGNOSIS--------// 

                  this.ICMPchecked = data.data[i].CHF.DIAGNOSIS.includes("ICMP");
                  this.DCMchecked = data.data[i].CHF.DIAGNOSIS.includes("DCM");
                  this.CRHDchecked = data.data[i].CHF.DIAGNOSIS.includes("CRHD");
                  this.RCMPchecked = data.data[i].CHF.DIAGNOSIS.includes("RCMP");
                  this.PPAHchecked = data.data[i].CHF.DIAGNOSIS.includes("P-PAH");
                  this.SECchecked = data.data[i].CHF.DIAGNOSIS.includes("SEC");
                  this.OTHERSchecked = data.data[i].CHF.DIAGNOSIS.includes("OTHERS");


                }




                //-----RiskFactors--//

                if (data.data[i].Type == "CAD") {

                  this.CADappointmentForm.patchValue({
                    AppointmentID: data.data[i].AppointmentID,
                    BP1: data.data[i].CAD.BP,
                    PR1: data.data[i].CAD.PR,
                    BGH1: data.data[i].CAD.BGH,
                    Notes1: data.data[i].CAD.Notes,
                    Investigations1: data.data[i].CAD.Investigations,
                    Diagnosis1: data.data[i].CAD.Diagnosis,
                    FollowUp1: data.data[i].CAD.FollowUp,
                    FollowUpDate1: data.data[i].CAD.FollowUpDate,
                    Prescription1: data.data[i].CAD.Prescription,
                    Diet1: data.data[i].CAD.Diet,
                    CAG1: data.data[i].CAD.CAG,

                    // RiskFactors: this.CADappointmentForm.value.RiskFactors1,
                    // APCLASS: this.CADappointmentForm.value.APCLASS,
                    // AdvicePostCAG: this.CADappointmentForm.value.AdvicePostCAG,
                    PostCABG: data.data[i].CAD.PostCABG,
                    PostCABGText: data.data[i].CAD.PostCABGText,
                    PostPTCA: data.data[i].CAD.PostPTCA,
                    PostPTCAText: data.data[i].CAD.PostPTCAText,

                  });

                  this.CAD1Array = this.RiskFactorFormArrayCad = data.data[i].CAD.RiskFactors;
                  this.CAD2Array = this.RiskFactorFormArrayAP = data.data[i].CAD.APCLASS;
                  this.CAD3Array = this.RiskFactorFormArrayAdviceCag = data.data[i].CAD.AdvicePostCAG;

                  //-----RiskFactors--CAD---------//
                  this.htnchecked1 = data.data[i].CAD.RiskFactors.includes("HTN")
                  this.DMchecked1 = data.data[i].CAD.RiskFactors.includes("DM")
                  this.HLPchecked1 = data.data[i].CAD.RiskFactors.includes("HLP")
                  this.SMOKERchecked1 = data.data[i].CAD.RiskFactors.includes("SMOKER")
                  this.FHCADchecked1 = data.data[i].CAD.RiskFactors.includes("FHCAD")

                  //--------APCLASS--------// 

                  this.APCLASSIchecked = data.data[i].CAD.APCLASS.includes("AP-CLASS-I");
                  this.APCLASSIIchecked = data.data[i].CAD.APCLASS.includes("AP-CLASS-II");
                  this.APCLASSIIIchecked = data.data[i].CAD.APCLASS.includes("AP-CLASS-III");
                  this.APCLASSIVchecked = data.data[i].CAD.APCLASS.includes("AP-CLASS-IV");


                  //--------------ADVICED post CAG-----
                  this.MMchecked = data.data[i].CAD.AdvicePostCAG.includes("MM");
                  this.CABGchecked = data.data[i].CAD.AdvicePostCAG.includes("CABG");
                  this.PTCAchecked = data.data[i].CAD.AdvicePostCAG.includes("PTCA");
                  this.OTHERchecked = data.data[i].CAD.AdvicePostCAG.includes("OTHERS");

                }



                if (data.data[i].Type == "HTN_NO_CAD_CHF") {
                  this.HTN_NO_CAD_CHFappointmentForm.patchValue({
                    AppointmentID: data.data[i].AppointmentID,

                    // RiskFactors: this.HTN_NO_CAD_CHFappointmentForm.value.RiskFactors2,
                    // Kidney: this.kidneyObj,
                    // CVS: this.CVSObject,
                    HTN_Since: data.data[i].HTN_NO_CAD_CHF.HTN_Since,
                    // HTN_RETINOPATHY_OPTHAL_OPINION: this.HTN_RETINOPATHY_OPTHAL_OPINIONValue,
                    // OLD_CVA_OR_TIA: this.OLD_CVA_OR_TIAValue,
                    ECHO: data.data[i].HTN_NO_CAD_CHF.ECHO,

                    CREATININE_GFR: data.data[i].HTN_NO_CAD_CHF.Kidney.CREATININE_GFR,
                    PR_CR: data.data[i].HTN_NO_CAD_CHF.Kidney.PR_CR,

                    LVH_ECG: data.data[i].HTN_NO_CAD_CHF.CVS.LVH_ECG,
                    LV_MASS: data.data[i].HTN_NO_CAD_CHF.CVS.LV_MASS,
                    LVEF: data.data[i].HTN_NO_CAD_CHF.CVS.LVEF,


                    BP2: data.data[i].HTN_NO_CAD_CHF.BP,
                    PR2: data.data[i].HTN_NO_CAD_CHF.PR,
                    BGH2: data.data[i].HTN_NO_CAD_CHF.BGH,
                    Notes2: data.data[i].HTN_NO_CAD_CHF.Notes,
                    Investigations2: data.data[i].HTN_NO_CAD_CHF.Investigations,
                    Diagnosis2: data.data[i].HTN_NO_CAD_CHF.Diagnosis,
                    FollowUp2: data.data[i].HTN_NO_CAD_CHF.FollowUp,
                    FollowUpDate2: data.data[i].HTN_NO_CAD_CHF.FollowUpDate,
                    Prescription2: data.data[i].HTN_NO_CAD_CHF.Prescription,
                    Diet2: data.data[i].HTN_NO_CAD_CHF.Diet,


                  });

                  this.RiskFactorHTNriskFormArray = this.HTNArray = data.data[i].HTN_NO_CAD_CHF.RiskFactors;


                  //-----RiskFactors--//
                  this.htnchecked2 = data.data[i].HTN_NO_CAD_CHF.RiskFactors.includes("HTN")
                  this.DMchecked2 = data.data[i].HTN_NO_CAD_CHF.RiskFactors.includes("DM")
                  this.HLPchecked2 = data.data[i].HTN_NO_CAD_CHF.RiskFactors.includes("HLP")
                  this.SMOKERchecked2 = data.data[i].HTN_NO_CAD_CHF.RiskFactors.includes("SMOKER")
                  this.FHCADchecked2 = data.data[i].HTN_NO_CAD_CHF.RiskFactors.includes("FHCAD")
                  // this.ckdvalue= data.data[i].HTN_NO_CAD_CHF.RiskFactors;


                  if (data.data[i].HTN_NO_CAD_CHF.Kidney.CKD == "CKD") {
                    this.CKDchecked = true;
                    this.ckdvalue = "CKD";
                  }

                  if (data.data[i].HTN_NO_CAD_CHF.HTN_RETINOPATHY_OPTHAL_OPINION == "HTN_RETINOPATHY_OPTHAL_OPINION") {
                    this.HTNROPTHALOPINIONchecked = true;
                    this.HTN_RETINOPATHY_OPTHAL_OPINIONValue = "HTN_RETINOPATHY_OPTHAL_OPINION"
                  }
                  if (data.data[i].HTN_NO_CAD_CHF.OLD_CVA_OR_TIA == "OLD_CVA_OR_TIA") {
                    this.OLDCVAchecked = true;
                    this.OLD_CVA_OR_TIAValue = "OLD_CVA_OR_TIA"
                  }

                  //  this.HTNROPTHALOPINIONchecked= data.data[i].HTN_NO_CAD_CHF.HTN_RETINOPATHY_OPTHAL_OPINION.include("HTN RETINOPATHY, OPTHAL OPINION")

                  //  this.OLDCVAchecked= data.data[i].HTN_NO_CAD_CHF.OLD_CVA_OR_TIA.include("OLD CVA OR TIA")


                }

                if (data.data[i].Type == "DM_NO_CAD_CHF") {

                  this.DM_NO_CAD_CHFappointmentForm.patchValue({
                    AppointmentID: data.data[i].AppointmentID,

                    DM_Since: data.data[i].DM_NO_CAD_CHF.DM_Since,

                    CREATININE_GFR1: data.data[i].DM_NO_CAD_CHF.Kidney.CREATININE_GFR,
                    PR_CR1: data.data[i].DM_NO_CAD_CHF.Kidney.PR_CR,

                    LVH_ECG1: data.data[i].DM_NO_CAD_CHF.CVS.LVH_ECG,
                    LV_MASS1: data.data[i].DM_NO_CAD_CHF.CVS.LV_MASS,
                    LVEF1: data.data[i].DM_NO_CAD_CHF.CVS.LVEF,


                    ECHO1: data.data[i].DM_NO_CAD_CHF.ECHO,
                    BMI: data.data[i].DM_NO_CAD_CHF.BMI,
                    HBA1C: data.data[i].DM_NO_CAD_CHF.HBA1C,

                    BP3: data.data[i].DM_NO_CAD_CHF.BP,
                    PR3: data.data[i].DM_NO_CAD_CHF.PR,
                    BGH3: data.data[i].DM_NO_CAD_CHF.BGH,
                    Notes3: data.data[i].DM_NO_CAD_CHF.Notes,
                    Investigations3: data.data[i].DM_NO_CAD_CHF.Investigations,
                    Diagnosis3: data.data[i].DM_NO_CAD_CHF.Diagnosis,
                    FollowUp3: data.data[i].DM_NO_CAD_CHF.FollowUp,
                    FollowUpDate3: data.data[i].DM_NO_CAD_CHF.FollowUpDate,
                    Prescription3: data.data[i].DM_NO_CAD_CHF.Prescription,
                    Diet3: data.data[i].DM_NO_CAD_CHF.Diet,


                  });


                  this.RiskFactorDMriskFormArray = this.DMArray1 = data.data[i].DM_NO_CAD_CHF.RiskFactors;

                  if (data.data[i].DM_NO_CAD_CHF.Kidney.CKD == "CKD") {

                    this.CKDchecked1 = true;
                    this.cdkDMFormValue = "CKD";
                  }

                  if (data.data[i].DM_NO_CAD_CHF.HTN_RETINOPATHY_OPTHAL_OPINION == "HTN_RETINOPATHY_OPTHAL_OPINION") {
                    this.HTNRETINOPATHYchecked = true;
                    this.dmHTN_RETINOPATHYValue = "HTN_RETINOPATHY_OPTHAL_OPINION"
                  }
                  if (data.data[i].DM_NO_CAD_CHF.OLD_CVA_OR_TIA == "OLD_CVA_OR_TIA") {
                    this.OLDCVADMchecked = true;
                    this.OLD_CVA_OR_TIAvalue = "OLD_CVA_OR_TIA"
                  }


                  this.htnchecked3 = data.data[i].DM_NO_CAD_CHF.RiskFactors.includes("HTN")
                  this.DMchecked3 = data.data[i].DM_NO_CAD_CHF.RiskFactors.includes("DM")
                  this.HLPchecked3 = data.data[i].DM_NO_CAD_CHF.RiskFactors.includes("HLP")
                  this.SMOKERchecked3 = data.data[i].DM_NO_CAD_CHF.RiskFactors.includes("SMOKER")
                  this.FHCADchecked3 = data.data[i].DM_NO_CAD_CHF.RiskFactors.includes("FHCAD")


                }



                if (data.data[i].Type == "HCM") {

                  this.HCMappointmentForm.patchValue({
                    AppointmentID: data.data[i].AppointmentID,

                    //  CVS: this.CVSObject,
                    CREATININE_GFR2: data.data[i].HCM.CREATININE_GFR,
                    DIAGNOSED_IN: data.data[i].HCM.DIAGNOSED_IN,
                    //  //   GENE_STUDY: this.HCMGenestudyArrayValue,
                    SYMTOM_STATUS: data.data[i].HCM.SYMTOM_STATUS,
                    ECHO2: data.data[i].HCM.ECHO,


                    LVH_ECG2: data.data[i].HCM.CVS.LVH_ECG,
                    LV_MASS2: data.data[i].HCM.CVS.LV_MASS,
                    LVEF2: data.data[i].HCM.CVS.LVEF,

                    BP4: data.data[i].HCM.BP,
                    PR4: data.data[i].HCM.PR,
                    BGH4: data.data[i].HCM.BGH,
                    Notes4: data.data[i].HCM.Notes,
                    Investigations4: data.data[i].HCM.Investigations,
                    Diagnosis4: data.data[i].HCM.Diagnosis,
                    FollowUp4: data.data[i].HCM.FollowUp,
                    FollowUpDate4: data.data[i].HCM.FollowUpDate,
                    Prescription4: data.data[i].HCM.Prescription,
                    Diet4: data.data[i].HCM.Diet



                  });

                  this.htnchecked4 = data.data[i].HCM.RiskFactors.includes("HTN")
                  this.DMchecked4 = data.data[i].HCM.RiskFactors.includes("DM")
                  this.HLPchecked4 = data.data[i].HCM.RiskFactors.includes("HLP")
                  this.SMOKERchecked4 = data.data[i].HCM.RiskFactors.includes("SMOKER")
                  this.FHCADchecked4 = data.data[i].HCM.RiskFactors.includes("FHCAD")

                  this.HCMRiskFactoryArray = this.HCMArray1 = data.data[i].HCM.RiskFactors;

                  if (data.data[i].HCM.GENE_STUDY == "GENE_STUDY") {
                    this.GENESTUDYchecked = true;
                    this.HCMGenestudyArrayValue = "GENE_STUDY";
                  }




                }


                if (data.data[i].Type == "PAH") {

                  this.PAHappointmentForm.patchValue({
                    AppointmentID: data.data[i].AppointmentID,

                    EF: data.data[i].PAH.EF,
                    PASP: data.data[i].PAH.PASP,
                    PADP: data.data[i].PAH.PADP,
                    RV_FUNCTION: data.data[i].PAH.RV_FUNCTION,

                    BP5: data.data[i].PAH.BP,
                    PR5: data.data[i].HCM.PR,
                    BGH5: data.data[i].HCM.BGH,
                    Notes5: data.data[i].HCM.Notes,
                    Investigations5: data.data[i].HCM.Investigations,
                    Diagnosis5: data.data[i].HCM.Diagnosis,
                    FollowUp5: data.data[i].HCM.FollowUp,
                    FollowUpDate5: data.data[i].HCM.FollowUpDate,
                    Prescription5: data.data[i].HCM.Prescription,
                    Diet5: data.data[i].HCM.Diet

                    //   
                    //     DIAGNOSIS: this.PAHDiagnosisArray,

                  });


                  this.PAHRiskArray = this.PAHArray1 = data.data[i].PAH.RiskFactors;
                  this.PAHClassArray = this.PAHArray2 = data.data[i].PAH.Class;

                  this.PAHDiagnosisArray = this.PAHArray3 = data.data[i].PAH.DIAGNOSIS;

                  if (data.data[i].PAH.COMPANSATED == "COMPANSATED") {
                    this.PAHCOMPANSATEDchecked = true;
                    this.PAHCOMPANSATEDValue = "COMPANSATED"
                  }
                  if (data.data[i].PAH.DECOMPANSATED == "DECOMPANSATED") {
                    this.PAHDECOMPANSATEDchecked = true;
                    this.PAHDECOMPANSATEDValue = "DECOMPANSATED"
                  }

                  this.htnchecked5 = data.data[i].PAH.RiskFactors.includes("HTN")
                  this.DMchecked5 = data.data[i].PAH.RiskFactors.includes("DM")
                  this.HLPchecked5 = data.data[i].PAH.RiskFactors.includes("HLP")
                  this.SMOKERchecked5 = data.data[i].PAH.RiskFactors.includes("SMOKER")
                  this.FHCADchecked5 = data.data[i].PAH.RiskFactors.includes("FHCAD")


                  // this.PAHCOMPANSATEDchecked =  data.data[i].PAH.COMPANSATED.includes("COMPANSATED")

                  // this.PAHDECOMPANSATEDchecked =  data.data[i].PAH.DECOMPANSATED.includes("DECOMPANSATED")

                  this.DIAGNOSISPAHPRIMARYchecked = data.data[i].PAH.DIAGNOSIS.includes("PRIMARY")
                  this.DIAGNOSISPAHCTEPchecked = data.data[i].PAH.DIAGNOSIS.includes("CTEP")
                  this.DIAGNOSISPAHSECchecked = data.data[i].PAH.DIAGNOSIS.includes("SEC TO COPD")
                  this.DIAGNOSISPAHDUEchecked = data.data[i].PAH.DIAGNOSIS.includes("DUE TO UNTREATED CHD")
                  this.DIAGNOSISPAHotherchecked = data.data[i].PAH.DIAGNOSIS.includes("OTHER")

                  this.CLASSIPAHchecked = data.data[i].PAH.Class.includes("CLASS-I");
                  this.CLASSIIPAHchecked = data.data[i].PAH.Class.includes("CLASS-II");
                  this.CLASSIIIPAHchecked = data.data[i].PAH.Class.includes("CLASS-III");
                  this.CLASSIVPAHchecked = data.data[i].PAH.Class.includes("CLASS-IV");

                }

                if (data.data[i].Type == "CRHD") {

                  this.CRHDappointmentForm.patchValue({
                    AppointmentID: data.data[i].AppointmentID,

                    CRHD_SINCE: data.data[i].CRHD.CRHD_SINCE,
                    MITRAL_STENOSIS: data.data[i].CRHD.MITRAL_STENOSIS,

                    MITRAL_REGURGITATION: data.data[i].CRHD.MITRAL_REGURGITATION,
                    AORTIC_STENOSIS: data.data[i].CRHD.AORTIC_STENOSIS,
                    AORTIC_REGURGITATION: data.data[i].CRHD.AORTIC_REGURGITATION,
                    TRICUSPID_STENOSIS: data.data[i].CRHD.TRICUSPID_STENOSIS,


                    TRICUSPID_REGURGITATION: data.data[i].CRHD.TRICUSPID_REGURGITATION,
                    PULMONARY_STENOSIS: data.data[i].CRHD.PULMONARY_STENOSIS,
                    PULMONARY_REGURGITATION: data.data[i].CRHD.PULMONARY_REGURGITATION,
                    PAH: data.data[i].CRHD.PAH,
                    LVEF: data.data[i].CRHD.LVEF6,
                    LVEDD: data.data[i].CRHD.LVEDD,
                    LVESD: data.data[i].CRHD.LVESD,
                    RV_FUNCTION6: data.data[i].CRHD.RV_FUNCTION,
                    ECHO6: data.data[i].CRHD.ECHO,


                    BP6: data.data[i].CRHD.BP,
                    PR6: data.data[i].CRHD.PR,
                    BGH6: data.data[i].CRHD.BGH,
                    Notes6: data.data[i].CRHD.Notes,
                    Investigations6: data.data[i].CRHD.Investigations,
                    Diagnosis6: data.data[i].CRHD.Diagnosis,
                    FollowUp6: data.data[i].CRHD.FollowUp,
                    FollowUpDate6: data.data[i].CRHD.FollowUpDate,
                    Prescription6: data.data[i].CRHD.Prescription,
                    Diet6: data.data[i].CRHD.Diet


                  });

                  this.CRHDArray1 = this.CRHDArray = data.data[i].CRHD.RiskFactors;

                  this.CRHDnyhaArray = this.CRHDArray2 = data.data[i].CRHD.NYHS;


                  this.htnchecked6 = data.data[i].CRHD.RiskFactors.includes("HTN")
                  this.DMchecked6 = data.data[i].CRHD.RiskFactors.includes("DM")
                  this.HLPchecked6 = data.data[i].CRHD.RiskFactors.includes("HLP")
                  this.SMOKERchecked6 = data.data[i].CRHD.RiskFactors.includes("SMOKER")
                  this.FHCADchecked6 = data.data[i].CRHD.RiskFactors.includes("FHCAD")

                  //this.OLD_CVA_OR_TIA_CAROTIDchecked ==  data.data[i].CRHD.OLD_CVA_OR_TIA_CAROTID.includes("OLD_CVA_OR_TIA_CAROTID")

                  if (data.data[i].CRHD.OLD_CVA_OR_TIA_CAROTID == "OLD_CVA_OR_TIA_CAROTID") {
                    this.OLD_CVA_OR_TIA_CAROTIDchecked = true;
                    this.CRHDCarotidValue = "OLD_CVA_OR_TIA_CAROTID";
                  }

                  this.NYHAICRHDchecked = data.data[i].CRHD.NYHS.includes("NYHA-I")
                  this.NYHAIICRHDchecked = data.data[i].CRHD.NYHS.includes("NYHA-II")
                  this.NYHAIIICRHDchecked = data.data[i].CRHD.NYHS.includes("NYHA-III")
                  this.NYHAIVCRHDchecked = data.data[i].CRHD.NYHS.includes("NYHA-IV")

                  if (data.data[i].CRHD.SINUS_RYTHM == "SINUS_RYTHM") {
                    this.SINUSCRHDchecked = true;

                    this.CRHDSinusVAlue = "SINUS_RYTHM";
                  }

                  if (data.data[i].CRHD.AF == "AF") {

                    this.AFCRHDchecked = true;
                    this.CRHDafValue = "AF";

                  }

                  if (data.data[i].CRHD.HO_IE == "HO_IE") {

                    this.HOIECRHDchecked = true;
                    this.CRHDHOValue = "HO_IE";

                  }






                }


                if (data.data[i].Type == "HLP_WITH_NO_CAD") {

                  this.HLP_WITH_NO_CADappointmentForm.patchValue({
                    AppointmentID: data.data[i].AppointmentID,

                    HLP_SINCE: data.data[i].HLP_WITH_NO_CAD.HLP_SINCE,
                    TOTAL_CH: data.data[i].HLP_WITH_NO_CAD.TOTAL_CH,


                    LDL_C: data.data[i].HLP_WITH_NO_CAD.LDL_C,
                    TG: data.data[i].HLP_WITH_NO_CAD.TG,
                    HDL_C: data.data[i].HLP_WITH_NO_CAD.HDL_C,
                    LPA: data.data[i].HLP_WITH_NO_CAD.LPA,
                    SUBFRACTION_OF_LDL: data.data[i].HLP_WITH_NO_CAD.SUBFRACTION_OF_LDL,
                    ANY_EXT_SIGNS: data.data[i].HLP_WITH_NO_CAD.ANY_EXT_SIGNS,

                    BP7: data.data[i].HLP_WITH_NO_CAD.BP,
                    PR7: data.data[i].HLP_WITH_NO_CAD.PR,
                    BGH7: data.data[i].HLP_WITH_NO_CAD.BGH,
                    Notes7: data.data[i].HLP_WITH_NO_CAD.Notes,
                    Investigations7: data.data[i].HLP_WITH_NO_CAD.Investigations,
                    Diagnosis7: data.data[i].HLP_WITH_NO_CAD.Diagnosis,
                    FollowUp7: data.data[i].HLP_WITH_NO_CAD.FollowUp,
                    FollowUpDate7: data.data[i].HLP_WITH_NO_CAD.FollowUpDate,
                    Prescription7: data.data[i].HLP_WITH_NO_CAD.Prescription,
                    Diet7: data.data[i].HLP_WITH_NO_CAD.Diet



                  });

                  this.HLPArray = this.HLP_WITH_NO_CADRiskArray = data.data[i].HLP_WITH_NO_CAD.RiskFactors;

                  this.htnchecked7 = data.data[i].HLP_WITH_NO_CAD.RiskFactors.includes("HTN")
                  this.DMchecked7 = data.data[i].HLP_WITH_NO_CAD.RiskFactors.includes("DM")
                  this.HLPchecked7 = data.data[i].HLP_WITH_NO_CAD.RiskFactors.includes("HLP")
                  this.SMOKERchecked7 = data.data[i].HLP_WITH_NO_CAD.RiskFactors.includes("SMOKER")
                  this.FHCADchecked7 = data.data[i].HLP_WITH_NO_CAD.RiskFactors.includes("FHCAD")


                }

                if (data.data[i].Type == "Chest_Pain") {



                  this.Chest_PainappointmentForm.patchValue({
                    AppointmentID: data.data[i].AppointmentID,

                    CHEST_PAIN: this.onChangeChestPainArray,
                    //   ECG: this.onChangeECGChestPainArray,


                    //   STRESS_TEST: this.onChangeChestPainStressTESTArray,
                    //   CAG: this.onChangeCAGChestPainArray,
                    ECHO8: data.data[i].Chest_Pain.ECHO,
                    BP8: data.data[i].Chest_Pain.BP,
                    PR8: data.data[i].Chest_Pain.PR,
                    BGH8: data.data[i].Chest_Pain.BGH,
                    Notes8: data.data[i].Chest_Pain.Notes,
                    Investigations8: data.data[i].Chest_Pain.Investigations,
                    Diagnosis8: data.data[i].Chest_Pain.Diagnosis,
                    FollowUp8: data.data[i].Chest_Pain.FollowUp,
                    FollowUpDate8: data.data[i].Chest_Pain.FollowUpDate,
                    Prescription8: data.data[i].Chest_Pain.Prescription,
                    Diet8: data.data[i].Chest_Pain.Diet
                  });


                  this.ChestArray1 = this.ChestpainRiskArray = data.data[i].Chest_Pain.RiskFactors

                  this.ChestArray3 = this.onChangeECGChestPainArray = data.data[i].Chest_Pain.ECG

                  this.ChestArray5 = this.onChangeCAGChestPainArray = data.data[i].Chest_Pain.CAG

                  this.ChestArray2 = this.onChangeChestPainArray = data.data[i].Chest_Pain.CHEST_PAIN

                  this.ChestArray4 = this.onChangeChestPainStressTESTArray = data.data[i].Chest_Pain.STRESS_TEST

                  this.htnchecked8 = data.data[i].Chest_Pain.RiskFactors.includes("HTN")
                  this.DMchecked8 = data.data[i].Chest_Pain.RiskFactors.includes("DM")
                  this.HLPchecked8 = data.data[i].Chest_Pain.RiskFactors.includes("HLP")
                  this.SMOKERchecked8 = data.data[i].Chest_Pain.RiskFactors.includes("SMOKER")
                  this.FHCADchecked8 = data.data[i].Chest_Pain.RiskFactors.includes("FHCAD")

                  this.ECGChest1checked = data.data[i].Chest_Pain.ECG.includes("ST/T WAVE CHANGES")
                  this.ECGChest2checked = data.data[i].Chest_Pain.ECG.includes("NON SPECIFIC ST/T WAVE CHANGES")
                  this.ECGChest3checked = data.data[i].Chest_Pain.ECG.includes("NORMAL ECG")

                  this.stresstestchest2checked = data.data[i].Chest_Pain.STRESS_TEST.includes("POSITIVE FOR INDUCIBLE ISCHEMIA")
                  this.stresstestchest1checked = data.data[i].Chest_Pain.STRESS_TEST.includes("NEGATIVE FOR INDUCIBLE ISCHEMIA")

                  this.cagchest1checked = data.data[i].Chest_Pain.CAG.includes("NORMAL")
                  this.cagchest2checked = data.data[i].Chest_Pain.CAG.includes("SVD")
                  this.cagchest3checked = data.data[i].Chest_Pain.CAG.includes("2 VD")
                  this.cagchest4checked = data.data[i].Chest_Pain.CAG.includes("3 VD")
                  this.cagchest5checked = data.data[i].Chest_Pain.CAG.includes("SLOW FLOW")

                  this.chestpain1checked = data.data[i].Chest_Pain.CHEST_PAIN.includes("TYPICAL")
                  this.chestpain2checked = data.data[i].Chest_Pain.CHEST_PAIN.includes("ATYPICAL")
                  this.chestpain3checked = data.data[i].Chest_Pain.CHEST_PAIN.includes("NOT S/O CAD")


                }




                if (data.data[i].Type == "Other") {



                  this.OthersappointmentForm.patchValue({
                    AppointmentID: data.data[i].AppointmentID,

                    ECHO9: data.data[i].Other.ECHO,
                    BP9: data.data[i].Other.BP,
                    PR9: data.data[i].Other.PR,
                    BGH9: data.data[i].Other.BGH,
                    Notes9: data.data[i].Other.Notes,
                    Investigations9: data.data[i].Other.Investigations,
                    Diagnosis9: data.data[i].Other.Diagnosis,
                    FollowUp9: data.data[i].Other.FollowUp,
                    FollowUpDate9: data.data[i].Other.FollowUpDate,
                    Prescription9: data.data[i].Other.Prescription,
                    Diet9: data.data[i].Other.Diet,

                    OTHER_DATA: data.data[i].Other.OTHER_DATA,

                    PRESENTING_REASON: this.OtherReasonsArray,
                    ECG: this.OtherECGArray,






                  });

                  this.OtherArray1 = this.OtherRiskArray = data.data[i].Other.RiskFactors;
                  this.OtherArray2 = this.OtherReasonsArray = data.data[i].Other.PRESENTING_REASON;
                  this.OtherArray3 = this.OtherECGArray = data.data[i].Other.ECG;

                  this.OtherArray4 = this.OtherStressArray = data.data[i].Other.STRESS_TEST






                  this.htnchecked9 = data.data[i].Other.RiskFactors.includes("HTN")
                  this.DMchecked9 = data.data[i].Other.RiskFactors.includes("DM")
                  this.HLPchecked9 = data.data[i].Other.RiskFactors.includes("HLP")
                  this.SMOKERchecked9 = data.data[i].Other.RiskFactors.includes("SMOKER")
                  this.FHCADchecked9 = data.data[i].Other.RiskFactors.includes("FHCAD")

                  this.preasonchecked = data.data[i].Other.PRESENTING_REASON.includes("CHEST PAIN")

                  this.preason1checked = data.data[i].Other.PRESENTING_REASON.includes("SOB")

                  this.preason2checked = data.data[i].Other.PRESENTING_REASON.includes("PRE-OP EVALUATION")

                  this.ecg1others = data.data[i].Other.ECG.includes("ST/T WAVE CHANGES")
                  this.ecg2others = data.data[i].Other.ECG.includes("NON SPECIFIC ST/T WAVE CHANGES")
                  this.ecg3others = data.data[i].Other.ECG.includes("NORMAL ECG")

                  // this.stress1 =false;
                  // this.stress2 =false;
                  this.stress2 = data.data[i].Other.STRESS_TEST.includes("NEGATIVE FOR INDUCIBLE ISCHEMIA")
                  this.stress1 = data.data[i].Other.STRESS_TEST.includes("POSITIVE FOR INDUCIBLE ISCHEMIA")




                }



              }
            }
          }


        }
        // this.spinner.show();
      })
  }

  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }
}



