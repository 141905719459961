<body data-gr-c-s-loaded="true">
    <div id="page" class="side-nav-wrapper">
        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pull-right" style="margin-right: 10px;">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                                style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                                <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                                <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                    userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                        class="">Settings</a></li>
                                <li
                                    *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                    <a [routerLink]="" (click)="users()" class="">Users</a></li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="container bg-gray">
            <div class="row">
                <div class="col-md-4">
                    <h5 class="dr-sub-head"> Create Report</h5>
                </div>
            </div>
            <div class="row orr-usercreation ptop30 pbtom30">

                <div class="col-md-12">
                    <div class="panel">
                        <!-- <div *ngIf="PatientDetiails !='null'"> -->
                            <div class="col-md-6">
                                <h5>
                                    Patient Name : {{PatientDetiails.Name}}
                                </h5>
                            </div>
                            <div class="col-md-6">
                                <h5> MRNumber : {{PatientDetiails.MRNumber}} </h5>
                            </div>
                            <div class="col-md-6">
                                <h5> Contact Number : {{PatientDetiails.ContactNumber}} </h5>
                            </div>
                            <div class="col-md-6">
                                <h5> Age : {{PatientDetiails.Age}} </h5>
                            </div>
                        <!-- </div> -->
                        <div class="tab-table-customer-entry"
                            style="margin:80px 0;background:#fff;padding:10px 0;border-top:1px solid #ddd;border-bottom:1px solid #ddd;">


                            <form id="contact-form" class="contact-form pad-40" autocomplete="off"
                                [formGroup]="twodechoreportfrom">
                                <div class="form-pad-label" style="padding-top:30px;">
                                    <div class="row">



                                    </div>
                                    <div class="row">
                                        <div class="col-md-5">
                                            <div class="input-text form-group has-feedback">
                                                <label style="width:180px;float:left;text-align:left;">MITRAL VALVE<span
                                                        class="required"> * </span>


                                                </label>
                                                <input
                                                    [class.is-invalid]="MITRAL_VALVE.invalid && (MITRAL_VALVE.dirty || MITRAL_VALVE.touched)"
                                                    class="form-control" id="MITRAL_VALVE" type="MITRAL_VALVE"
                                                    name="MITRAL_VALVE" placeholder=" MITRAL VALVE "
                                                    formControlName="MITRAL_VALVE">

                                            </div>
                                            <div style="text-align: center;">
                                                <small *ngIf="error && MITRAL_VALVE.errors?.required"
                                                    class="text-danger">Enter MITRAL VALVE
                                                </small>
                                            </div>
                                        </div>

                                        <div class="col-md-7">
                                            <div class="input-text form-group has-feedback">
                                                <label style="width:180px;float:left;text-align:left;">TRICUSPID
                                                    VALVE<span class="required"> * </span>


                                                </label>
                                                <input
                                                    [class.is-invalid]="TRICUSPID_VALVE.invalid && (TRICUSPID_VALVE.dirty || TRICUSPID_VALVE.touched)"
                                                    class="form-control" id="TRICUSPID_VALVE" type="TRICUSPID_VALVE"
                                                    name="TRICUSPID_VALVE" placeholder=" TRICUSPID VALVE "
                                                    formControlName="TRICUSPID_VALVE">
                                            </div>
                                            <div style="text-align: center;">
                                                <small *ngIf="error && TRICUSPID_VALVE.errors?.required"
                                                    class="text-danger">Enter TRICUSPID VALVE
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <div class="input-text form-group has-feedback">
                                                <label style="width:180px;float:left;text-align:left;">AORTIC VALVE<span
                                                        class="required"> * </span>


                                                </label>
                                                <input
                                                    [class.is-invalid]="AORTIC_VALVE.invalid && (AORTIC_VALVE.dirty || AORTIC_VALVE.touched)"
                                                    class="form-control" id="AORTIC_VALVE" type="AORTIC_VALVE"
                                                    name="AORTIC_VALVE" placeholder=" AORTIC VALVE "
                                                    formControlName="AORTIC_VALVE">
                                            </div>
                                            <div style="text-align: center;">
                                                <small *ngIf="error && AORTIC_VALVE.errors?.required"
                                                    class="text-danger">Enter AORTIC VALVE
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="input-text form-group has-feedback">
                                                <label style="width:200px;float:left;text-align:left;">PULMONARY
                                                    VALVE<span class="required"> * </span>


                                                </label>
                                                <input
                                                    [class.is-invalid]="PULMONARY_VALVE.invalid && (PULMONARY_VALVE.dirty || PULMONARY_VALVE.touched)"
                                                    class="form-control" id="PULMONARY_VALVE" type="PULMONARY_VALVE"
                                                    name="PULMONARY_VALVE" placeholder=" PULMONARY VALVE "
                                                    formControlName="PULMONARY_VALVE">

                                            </div>
                                            <div style="text-align: center;">
                                                <small *ngIf="error && PULMONARY_VALVE.errors?.required"
                                                    class="text-danger">Enter PULMONARY VALVE
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <div class="input-text form-group has-feedback">
                                                <label style="width:180px;float:left;text-align:left;">LEFT ATRIUM<span
                                                        class="required"> * </span>


                                                </label>
                                                <input
                                                    [class.is-invalid]="LEFT_ATRIUM.invalid && (LEFT_ATRIUM.dirty || LEFT_ATRIUM.touched)"
                                                    class="form-control" id="LEFT_ATRIUM" type="LEFT_ATRIUM"
                                                    name="LEFT_ATRIUM" placeholder=" LEFT ATRIUM "
                                                    formControlName="LEFT_ATRIUM"> <b> CM</b> 

                                            </div>
                                            <div style="text-align: center;">
                                                <small *ngIf="error && LEFT_ATRIUM.errors?.required"
                                                    class="text-danger">Enter LEFT ATRIUM
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="input-text form-group has-feedback">
                                                <label style="width:180px;float:left;text-align:left;">AORTA<span
                                                        class="required"> * </span>


                                                </label>
                                                <input
                                                    [class.is-invalid]="AORTA.invalid && (AORTA.dirty || AORTA.touched)"
                                                    class="form-control" id="AORTA" type="AORTA" name="AORTA"
                                                    placeholder=" AORTA " formControlName="AORTA"><b> CM</b> 

                                            </div>
                                            <div style="text-align: center;">
                                                <small *ngIf="error && AORTA.errors?.required" class="text-danger">Enter
                                                    AORTA
                                                </small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="input-text form-group has-feedback">
                                                <label
                                                    style="width:180px;float:left;text-align:left;padding-right:15px;">LVEDD<span
                                                        class="required"> * </span>
                                                    </label>
                                                    <input
                                                        [class.is-invalid]="LVEDD.invalid && (LVEDD.dirty || LVEDD.touched)"
                                                        class="form-control" id="LVEDD" type="LVEDD" name="LVEDD"
                                                        placeholder=" LVEDD " formControlName="LVEDD"><b>CM</b>

                                               

                                            </div>
                                            <div style="text-align: center;">
                                                <small *ngIf="error && LVEDD.errors?.required" class="text-danger">Enter
                                                    LVEDD
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="input-text form-group has-feedback">
                                                <label
                                                    style="width:200px;float:left;text-align:left;padding-right:15px;">LVESD<span
                                                        class="required"> * </span>
                                                    </label>
                                                    <input
                                                        [class.is-invalid]="LVESD.invalid && (LVESD.dirty || LVESD.touched)"
                                                        class="form-control" id="LVESD" type="LVESD" name="LVESD"
                                                        placeholder=" LVESD " formControlName="LVESD"><b> CM</b> 

                                               

                                            </div>
                                            <div style="text-align: center;">
                                                <small *ngIf="error && LVESD.errors?.required" class="text-danger">Enter
                                                    LVESD
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="input-text form-group has-feedback">
                                                <label
                                                    style="width:200px;float:left;text-align:left;padding-right:15px;">EF<span
                                                        class="required"> * </span>
                                                    </label>
                                                    <input [class.is-invalid]="EF.invalid && (EF.dirty || EF.touched)"
                                                        class="form-control" id="EF" type="EF" name="EF"
                                                        placeholder=" EF " formControlName="EF"><b>%</b>

                                            </div>
                                            <div style="text-align: center;">
                                                <small *ngIf="error && EF.errors?.required" class="text-danger">Enter EF
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="input-text form-group has-feedback">
                                                <label
                                                    style="width:200px;float:left;text-align:left;padding-right:15px;">ISDd<span
                                                        class="required"> * </span></label>
                                                    <input
                                                        [class.is-invalid]="ISDd.invalid && (ISDd.dirty || ISDd.touched)"
                                                        class="form-control" id="ISDd" type="ISDd" name="ISDd"
                                                        placeholder=" ISDd " formControlName="ISDd"><b>%</b>

                                                

                                            </div>
                                            <div style="text-align: center;">
                                                <small *ngIf="error && ISDd.errors?.required" class="text-danger">Enter
                                                    ISDd
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="input-text form-group has-feedback">
                                                <label
                                                    style="width:200px;float:left;text-align:left;padding-right:15px;">PWd<span
                                                        class="required"> * </span> </label> 
                                                    <input
                                                        [class.is-invalid]="PWd.invalid && (PWd.dirty || PWd.touched)"
                                                        class="form-control" id="PWd" type="PWd" name="PWd"
                                                        placeholder=" PWd " formControlName="PWd"><b>CM</b>

                                               

                                            </div>
                                            <div style="text-align: center;">
                                                <small *ngIf="error && PWd.errors?.required" class="text-danger">Enter
                                                    PWd
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="input-text form-group has-feedback">
                                                <label
                                                    style="width:200px;float:left;text-align:left;padding-right:15px;">FS<span
                                                        class="required"> * </span> </label>
                                                    <input [class.is-invalid]="FS.invalid && (FS.dirty || FS.touched)"
                                                        class="form-control" id="FS" type="FS" name="FS"
                                                        placeholder=" FS " formControlName="FS"><b>%</b>

                                               

                                            </div>
                                            <div style="text-align: center;">
                                                <small *ngIf="error && FS.errors?.required" class="text-danger">Enter FS
                                                </small>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="input-text form-group has-feedback">
                                                <label
                                                    style="width:180px;float:left;text-align:left;padding-right:15px;">RIGHT
                                                    ATRIUM<span class="required"> * </span>
                                                    <input
                                                        [class.is-invalid]="RIGHT_ATRIUM.invalid && (RIGHT_ATRIUM.dirty || RIGHT_ATRIUM.touched)"
                                                        class="form-control" id="RIGHT_ATRIUM" type="RIGHT_ATRIUM"
                                                        name="RIGHT_ATRIUM" placeholder=" RIGHT ATRIUM "
                                                        formControlName="RIGHT_ATRIUM">

                                                </label>

                                            </div>
                                            <div style="text-align: center;">
                                                <small *ngIf="error && RIGHT_ATRIUM.errors?.required"
                                                    class="text-danger">Enter RIGHT ATRIUM
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="input-text form-group has-feedback">
                                                <label
                                                    style="width:180px;float:left;text-align:left;padding-right:15px;">RIGHT
                                                    VENTRICLE<span class="required"> * </span>
                                                    <input
                                                        [class.is-invalid]="RIGHT_VENTRICLE.invalid && (RIGHT_VENTRICLE.dirty || RIGHT_VENTRICLE.touched)"
                                                        class="form-control" id="RIGHT_VENTRICLE" type="RIGHT_VENTRICLE"
                                                        name="RIGHT_VENTRICLE" placeholder=" RIGHT VENTRICLE "
                                                        formControlName="RIGHT_VENTRICLE">

                                                </label>

                                            </div>
                                            <div style="text-align: center;">
                                                <small *ngIf="error && RIGHT_VENTRICLE.errors?.required"
                                                    class="text-danger">Enter RIGHT VENTRICLE
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="input-text form-group has-feedback">
                                                <label
                                                    style="width:180px;float:left;text-align:left;padding-right:15px;">IAS<span
                                                        class="required"> * </span>
                                                    <input
                                                        [class.is-invalid]="IAS.invalid && (IAS.dirty || IAS.touched)"
                                                        class="form-control" id="IAS" type="IAS" name="IAS"
                                                        placeholder=" IAS " formControlName="IAS">

                                                </label>

                                            </div>
                                            <div style="text-align: center;">
                                                <small *ngIf="error && IAS.errors?.required" class="text-danger">Enter
                                                    IAS
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="input-text form-group has-feedback">
                                                <label
                                                    style="width:180px;float:left;text-align:left;padding-right:15px;">IVS<span
                                                        class="required"> * </span>
                                                    <input
                                                        [class.is-invalid]="IVS.invalid && (IVS.dirty || IVS.touched)"
                                                        class="form-control" id="IVS" type="IVS" name="IVS"
                                                        placeholder=" IVS " formControlName="IVS">

                                                </label>

                                            </div>
                                            <div style="text-align: center;">
                                                <small *ngIf="error && IVS.errors?.required" class="text-danger">Enter
                                                    IVS
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="input-text form-group has-feedback">
                                                <label
                                                    style="width:180px;float:left;text-align:left;padding-right:15px;">IVC<span
                                                        class="required"> * </span>
                                                    <input
                                                        [class.is-invalid]="IVC.invalid && (IVC.dirty || IVC.touched)"
                                                        class="form-control" id="IVC" type="IVC" name="IVC"
                                                        placeholder=" IVC " formControlName="IVC">

                                                </label>

                                            </div>
                                            <div style="text-align: center;">
                                                <small *ngIf="error && IVC.errors?.required" class="text-danger">Enter
                                                    IVC
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="input-text form-group has-feedback">
                                                <label
                                                    style="width:180px;float:left;text-align:left;padding-right:15px;">PERICARDIUM<span
                                                        class="required"> * </span>
                                                    <input
                                                        [class.is-invalid]="PERICARDIUM.invalid && (PERICARDIUM.dirty || PERICARDIUM.touched)"
                                                        class="form-control" id="PERICARDIUM" type="PERICARDIUM"
                                                        name="PERICARDIUM" placeholder=" PERICARDIUM "
                                                        formControlName="PERICARDIUM">

                                                </label>

                                            </div>
                                            <div style="text-align: center;">
                                                <small *ngIf="error && PERICARDIUM.errors?.required"
                                                    class="text-danger">Enter PERICARDIUM
                                                </small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-5">
                                            <div class="input-text form-group has-feedback">
                                                <label style="width:200px;float:left;text-align:left;">MITRAL FLOW<span
                                                        class="required"> * </span>


                                                </label>
                                                <input
                                                    [class.is-invalid]="MITRAL_FLOW.invalid && (MITRAL_FLOW.dirty || MITRAL_FLOW.touched)"
                                                    class="form-control" id="MITRAL_FLOW" type="MITRAL_FLOW"
                                                    name="MITRAL_FLOW" placeholder=" MITRAL FLOW "
                                                    formControlName="MITRAL_FLOW">
                                            </div>
                                            <div style="text-align: center;">
                                                <small *ngIf="error && MITRAL_FLOW.errors?.required"
                                                    class="text-danger">Enter MITRAL FLOW
                                                </small>
                                            </div>
                                        </div>

                                        <div class="col-md-7">
                                            <div class="input-text form-group has-feedback">
                                                <label style="width:200px;float:left;text-align:left;">TRICUSPID
                                                    FLOW<span class="required"> * </span>


                                                </label>
                                                <input
                                                    [class.is-invalid]="TRICUSPID_FLOW.invalid && (TRICUSPID_FLOW.dirty || TRICUSPID_FLOW.touched)"
                                                    class="form-control" id="TRICUSPID_FLOW" type="TRICUSPID_FLOW"
                                                    name="TRICUSPID_FLOW" placeholder=" TRICUSPID FLOW "
                                                    formControlName="TRICUSPID_FLOW">

                                            </div>
                                            <div style="text-align: center;">
                                                <small *ngIf="error && TRICUSPID_FLOW.errors?.required"
                                                    class="text-danger">Enter TRICUSPID FLOW
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <div class="input-text form-group has-feedback">
                                                <label
                                                    style="width:180px;float:left;text-align:left;padding-right:15px;">AORTIC
                                                    FLOW<span class="required"> * </span>


                                                </label>

                                                <input
                                                    [class.is-invalid]="AORTIC_FLOW.invalid && (AORTIC_FLOW.dirty || AORTIC_FLOW.touched)"
                                                    class="form-control" id="AORTIC_FLOW" type="AORTIC_FLOW"
                                                    name="AORTIC_FLOW" placeholder=" AORTIC FLOW "
                                                    formControlName="AORTIC_FLOW">

                                            </div>
                                            <div style="text-align: center;">
                                                <small *ngIf="error && AORTIC_FLOW.errors?.required"
                                                    class="text-danger">Enter AORTIC FLOW
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="input-text form-group has-feedback">
                                                <label
                                                    style="width:200px;float:left;text-align:left;padding-right:15px;">PULMONARY
                                                    FLOW<span class="required"> * </span>

                                                </label>
                                                <input
                                                    [class.is-invalid]="PULMONARYFLOW.invalid && (PULMONARYFLOW.dirty || PULMONARYFLOW.touched)"
                                                    class="form-control" id="PULMONARYFLOW" type="PULMONARYFLOW"
                                                    name="PULMONARYFLOW" placeholder=" PULMONARYFLOW "
                                                    formControlName="PULMONARYFLOW">


                                            </div>
                                            <div style="text-align: center;">
                                                <small *ngIf="error && PULMONARYFLOW.errors?.required"
                                                    class="text-danger">Enter PULMONARYFLOW
                                                </small>
                                            </div>
                                        </div>


                                    </div>

                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="input-text form-group has-feedback">
                                                <label
                                                    style="width:180px;float:left;text-align:left;padding-right:15px;">MR<span
                                                        class="required"> * </span>
                                                    <input [class.is-invalid]="MR.invalid && (MR.dirty || MR.touched)"
                                                        class="form-control" id="MR" type="MR" name="MR"
                                                        placeholder=" MR " formControlName="MR">

                                                </label>

                                            </div>
                                            <div style="text-align: center;">
                                                <small *ngIf="error && MR.errors?.required" class="text-danger">Enter MR
                                                </small>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="input-text form-group has-feedback">
                                                <label
                                                    style="width:180px;float:left;text-align:left;padding-right:15px;">TR<span
                                                        class="required"> * </span>
                                                    <input [class.is-invalid]="TR.invalid && (TR.dirty || TR.touched)"
                                                        class="form-control" id="TR" type="TR" name="TR"
                                                        placeholder=" TR " formControlName="TR">

                                                </label>

                                            </div>
                                            <div style="text-align: center;">
                                                <small *ngIf="error && TR.errors?.required" class="text-danger">Enter TR
                                                </small>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="input-text form-group has-feedback">
                                                <label
                                                    style="width:180px;float:left;text-align:left;padding-right:15px;">AR<span
                                                        class="required"> * </span>
                                                    <input [class.is-invalid]="AR.invalid && (AR.dirty || AR.touched)"
                                                        class="form-control" id="AR" type="AR" name="AR"
                                                        placeholder=" AR " formControlName="AR">

                                                </label>

                                            </div>
                                            <div style="text-align: center;">
                                                <small *ngIf="error && AR.errors?.required" class="text-danger">Enter AR
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="input-text form-group has-feedback">
                                                <label
                                                    style="width:180px;float:left;text-align:left;padding-right:15px;">PR<span
                                                        class="required"> * </span>
                                                    <input [class.is-invalid]="PR.invalid && (PR.dirty || PR.touched)"
                                                        class="form-control" id="PR" type="PR" name="PR"
                                                        placeholder=" PR " formControlName="PR">

                                                </label>

                                            </div>
                                            <div style="text-align: center;">
                                                <small *ngIf="error && PR.errors?.required" class="text-danger">Enter PR
                                                </small>
                                            </div>
                                        </div>

                                        <div class="row">

                                            <div class="col-md-6">
                                                <div class="input-text form-group has-feedback">
                                                    <label style="width:180px;float:left;text-align:left;">OTHERS<span
                                                            class="required"> * </span>


                                                    </label>


                                                    <textarea
                                                        [class.is-invalid]="OTHERS.invalid && (OTHERS.dirty || OTHERS.touched)"
                                                        class="form-control" id="OTHERS" type="OTHERS" name="OTHERS"
                                                        placeholder=" OTHERS " formControlName="OTHERS"></textarea>


                                                </div>
                                                <div style="text-align: center;">
                                                    <small *ngIf="error && OTHERS.errors?.required"
                                                        class="text-danger">Enter OTHERS
                                                    </small>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="input-text form-group has-feedback">
                                                    <label
                                                        style="width:180px;float:left;text-align:left;">CONCLUSION<span
                                                            class="required"> * </span>


                                                    </label>


                                                    <textarea
                                                        [class.is-invalid]="CONCLUSION.invalid && (CONCLUSION.dirty || CONCLUSION.touched)"
                                                        class="form-control" id="CONCLUSION" type="CONCLUSION"
                                                        name="CONCLUSION" placeholder=" CONCLUSION "
                                                        formControlName="CONCLUSION"></textarea>


                                                </div>
                                                <div style="text-align: center;">
                                                    <small *ngIf="error && CONCLUSION.errors?.required"
                                                        class="text-danger">Enter CONCLUSION
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>






                                    <div style="clear:both;"></div>

                                    <div
                                        style="margin:auto;text-align:center;margin-bottom:10px;margin-top:30px;margin-bottom:30px;">
                                        <a [routerLink]="" (click)="onSubmit(twodechoreportfrom)"> <input type="submit"
                                                class="btn-pink" value="CREATE"></a>

                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>
<ngx-spinner></ngx-spinner>