import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-updatebilling',
  templateUrl: './updatebilling.component.html',
  styleUrls: ['./updatebilling.component.css']
})
export class UpdatebillingComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private apicalls: ApicallsService, private router: ActivatedRoute, private route: Router, private httpClient: HttpClient) { }

  viewBillingDetails;
  viewalldoctors = [];
  userDetails

  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data
    this.router.paramMap.subscribe(params => {
      const BillingID = params.get('BillingID');
      if (BillingID) {
        this.getBillingID(BillingID);
      }
    });

    this.apicalls.viewAllDoctors()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {
            this.viewalldoctors = data.data;
            this.spinner.hide();
          }
          if (data.code == 'V002') {
            alert(data.data);
            this.spinner.hide();
          }
        } else {
          alert(data.data)
          this.spinner.hide();
        }
      }, error => { alert(" Internal Server Error "); });
  }


  billingForm = new FormGroup({
    Type: new FormControl('', [Validators.required]),
    Price: new FormControl('', [Validators.pattern('[0-9]*')]),
    DoctorID: new FormControl('',),
    BillingID: new FormControl('', [Validators.required]),
    BillingName: new FormControl('', [Validators.required]),
    TestName: new FormControl('', [Validators.required]),
    DoctorName: new FormControl('', [Validators.required]),
  });

  get Price() {
    return this.billingForm.get('Price');
  }
  get DoctorID() {
    return this.billingForm.get('DoctorID');
  }
  get Type() {
    return this.billingForm.get('Type');
  }
  get BillingID() {
    return this.billingForm.get('BillingID');
  }
  get BillingName() {
    return this.billingForm.get('BillingName');

  }
  get TestName() {
    return this.billingForm.get('TestName');
  }
  get DoctorName() {
    return this.billingForm.get('DoctorName');
  }

  TempDoctorName;
  getBillingID(BillingID) {

    this.apicalls.viewBilling(BillingID).subscribe(

      data => {
        this.spinner.show();

        if (data.type == true) {
          this.viewBillingDetails = data.data
          setTimeout(() => {
            this.spinner.hide();
          }, 500);


          this.billingForm.patchValue({
            Type: this.viewBillingDetails.BillingInfo.BillingName,
            BillingName: this.viewBillingDetails.BillingName,
            Price: this.viewBillingDetails.Price,
            BillingID: this.viewBillingDetails.BillingID,
          //  DoctorID: this.viewBillingDetails.DoctorID,
           // DoctorName: this.viewBillingDetails.DoctorInfo.FirstName + ' ' + this.viewBillingDetails.DoctorInfo.LastName,
          });
         // this.billingForm.value.DoctorName=this.viewBillingDetails.DoctorInfo.FirstName + ' ' + this.viewBillingDetails.DoctorInfo.LastName
        } else {
          alert(data.message);
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ")
      });
  }


  error;
  onSubmit(billingForm) {
    // if (this.billingForm.invalid) {
    //   this.error = true;
    //   return;
    // }
    console.log(" billingForm ", this.billingForm.value);
    //return;
    this.apicalls.updateBilling(this.billingForm.value)
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {
            this.spinner.hide();
            alert(data.data)
            console.log(" billing ", data);
            this.route.navigate(["/dashboardmain/ViewAllbillings"]);
          }
          if (data.code == 'S002') {
            alert(data.data);
            this.spinner.hide();
          }
        } else {
          alert(data.data)
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ");
      });
  }

  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }

}





































 // doctorsdetails;
  // onOptionsSelected(value: string) {
  //   this.apicalls.viewDoctorsById(value).subscribe(
  //     data => {
  //       this.spinner.show();

  //       if (data.type == true) {
  //         this.doctorsdetails = data
  //         setTimeout(() => {
  //           /** spinner ends after 5 seconds */
  //           this.spinner.hide();
  //         }, 500);
  //         this.billingForm.patchValue({
  //           MedicID: this.doctorsdetails.data.MedicID,
  //           DoctorName: this.doctorsdetails.data.FirstName,
  //           Fee: this.doctorsdetails.data.Fee,
  //         });
  //       } else {
  //         alert(data.message);
  //         this.spinner.hide();
  //       }
  //     }, error => {
  //       alert(" Internal Server Error ")
  //     });

  // }
