<body data-gr-c-s-loaded="true">
    <div id="page" class="side-nav-wrapper">

        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pull-right" style="margin-right: 10px;">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                                style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                                <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                                <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                    userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                        class="">Settings</a></li>
                                <li
                                    *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                    <a [routerLink]="" (click)="users()" class="">Users</a></li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="container bg-gray">
            <div class="row">
                <!-- <div class="row pt30"> -->

                <!-- <div class="col-lg-12">
                            <ol class="breadcrumb">
                                <li class="active">
                                    <a routerLink="/dashboardmain/patients">Patients</a>/
                                    Update Patients                            </li>
                            </ol>
                        </div> -->
                <!-- </div> -->
                <div class="col-md-12">
                    <h5 class="dr-sub-head">
                        Update Patient
                    </h5>
                </div>
            </div>
            <div class="row orr-usercreation ptop30 pbtom30">

                <div class="col-md-12">
                    <!-- <h5 >Create Hospital</h5> -->
                    <div class="panel">
                        <h5 class="dr-sub-head"> <a routerLink="/dashboardmain/patients">Patients</a>/ Update Patient
                        </h5>
                        <!-- <h5 >Update Patient</h5> -->

                        <div class="tab-table-customer-entry"
                            style="margin:30px 0;background:#fff;padding:10px 0;border-top:1px solid #ddd;border-bottom:1px solid #ddd;">

                            <form id="contact-form" class="contact-form pad-40" autocomplete="off"
                                [formGroup]="patientForm">
                                <div class="form-pad-label" style="padding-top:30px;">



                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Patient
                                                Name<span class="required"> * </span></label>
                                            <input [class.is-invalid]="Name.invalid && (Name.dirty || Name.touched)"
                                                class="form-control" id="Name" type="Name" name="Name"
                                                placeholder=" Patient Name " formControlName="Name">
                                            <!-- <div *ngIf="error && Name.invalid  && (Name.dirty || Name.touched)"> -->
                                            <small *ngIf="error && Name.errors?.required" class="text-danger"> Name is
                                                required</small>
                                            <small *ngIf="error && Name.errors?.minlength" class="text-danger"> Name
                                                should
                                                be minimum 3 characters
                                            </small>
                                            <small *ngIf="error && Name.errors?.pattern" class="text-danger"> Name
                                                should be
                                                alphabets
                                            </small>

                                            <!-- </div> -->
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>

                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Date
                                                of birth<span class="required"> </span></label>

                                            <div class="input-group date" style="float:left;width:100%;">
                                                <input type='text' placeholder="dd-mm-yyyy" class="form-control"
                                                    formControlName="DOB" id="DOB" name="DOB" #dp="bsDatepicker"
                                                    bsDatepicker [bsConfig]="datePickerConfig" placement="right"
                                                    [maxDate]="maxDate">
                                                <span class="input-group-addon">
                                                    <i class="glyphicon glyphicon-calendar" (click)="dp.toggle()"></i>
                                                </span>

                                            </div>
                                            <!-- <div *ngIf="DOB.invalid  && (DOB.dirty || DOB.touched)">
                                                <small *ngIf="DOB.errors?.required" class="text-danger">Date of birth is
                                                    required</small>

                                            </div> -->
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Age<span
                                                    class="required"> * </span></label>
                                            <input [class.is-invalid]="Age.invalid && (Age.dirty || Age.touched)"
                                                class="form-control" id="Age" type="Age" name="Age" placeholder=" Age "
                                                formControlName="Age">
                                            <!-- <div *ngIf="Age.invalid  && (Age.dirty || Age.touched)"> -->
                                            <small *ngIf="error && Age.errors?.required" class="text-danger">Age is
                                                required</small>
                                            <small *ngIf="error && Age.errors?.pattern" class="text-danger">Age must be
                                                two
                                                digits </small>

                                            <!-- </div> -->
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Phone
                                                Number<span class="required"> * </span></label>
                                            <input
                                                [class.is-invalid]="ContactNumber.invalid && (ContactNumber.dirty || ContactNumber.touched)"
                                                class="form-control" id="ContactNumber" type="ContactNumber"
                                                name="ContactNumber" placeholder=" Phone Number "
                                                formControlName="ContactNumber">

                                            <!-- <div
                                                *ngIf="ContactNumber.invalid  && (ContactNumber.dirty || ContactNumber.touched)"> -->
                                            <small *ngIf="error && ContactNumber.errors?.required"
                                                class="text-danger">Phone
                                                Number is
                                                required</small>
                                            <small *ngIf="error && ContactNumber.errors?.pattern"
                                                class="text-danger">Please
                                                enter valid Phone Number</small>
                                            <small *ngIf="error && ContactNumber.errors?.minlength"
                                                class="text-danger">Phone
                                                Number should be 10 digits</small>
                                            <small *ngIf="error && ContactNumber.errors?.maxlength"
                                                class="text-danger">Phone
                                                Number should be 10 digits</small>

                                            <!-- </div> -->
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">EmailID<span
                                                    class="required"> * </span></label>
                                            <input
                                                [class.is-invalid]="EmailID.invalid && (EmailID.dirty || EmailID.touched)"
                                                class="form-control" id="EmailID" type="text" name="EmailID"
                                                placeholder=" Email-ID " formControlName="EmailID">
                                            <!-- <div *ngIf="EmailID.invalid  && (EmailID.dirty || EmailID.touched)"> -->
                                            <small *ngIf="error && EmailID.errors?.required" class="text-danger">EmailID
                                                is
                                                required</small>
                                            <small *ngIf="error && EmailID.errors?.pattern" class="text-danger">Please
                                                enter
                                                valid EmailID</small>


                                            <!-- </div> -->
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">RefferedBy<span
                                                    class="required"> </span></label>
                                            <input
                                                [class.is-invalid]="RefferedBy.invalid && (RefferedBy.dirty || RefferedBy.touched)"
                                                class="form-control" id="RefferedBy" type="RefferedBy" name="RefferedBy"
                                                placeholder=" RefferedBy " formControlName="RefferedBy">

                                            <!-- <div
                                                *ngIf="RefferedBy.invalid  && (RefferedBy.dirty || RefferedBy.touched)">
                                               -->
                                            <small *ngIf="error && RefferedBy.errors?.pattern"
                                                class="text-danger">Please
                                                enter valid RefferedBy</small>

                                            <!-- </div> -->
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">District<span
                                                    class="required"> </span></label>
                                            <input
                                                [class.is-invalid]="District.invalid && (District.dirty || District.touched)"
                                                class="form-control" id="District" type="District" name="District"
                                                placeholder=" District " formControlName="District">

                                            <div *ngIf="District.invalid  && (District.dirty || District.touched)">

                                                <small *ngIf="District.errors?.pattern" class="text-danger">Please enter
                                                    valid District</small>

                                            </div>
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Hospital<span
                                                    class="required"> </span></label>
                                            <input
                                                [class.is-invalid]="Hospital.invalid && (Hospital.dirty || Hospital.touched)"
                                                class="form-control" id="Hospital" type="Hospital" name="Hospital"
                                                placeholder=" Hospital " formControlName="Hospital">

                                            <!-- <div *ngIf="Hospital.invalid  && (Hospital.dirty || Hospital.touched)"> -->

                                            <small *ngIf="error && Hospital.errors?.pattern" class="text-danger">Please
                                                enter
                                                valid Hospital</small>

                                            <!-- </div> -->
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>

                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Gender<span
                                                    class="required"> * </span></label>
                                            <select
                                                class="way-custom-dropdown price-text-field profile-mwidth form-control"
                                                style="background-color:#fff;margin-bottom:20px;"
                                                formControlName="Gender" id="Gender" name="Gender" required="">
                                                <option value="">Select Gender</option>

                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>


                                            </select>
                                            <!-- <div *ngIf="Gender.invalid  && (Gender.dirty || Gender.touched)"> -->
                                            <small *ngIf="error && Gender.errors?.required" class="text-danger">Gender
                                                is
                                                required</small>

                                            <!-- </div> -->
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>

                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">

                                            <label style="width:180px;float:left;text-align:right;padding-right:15px;">
                                                Address<span class="required"></span></label>
                                            <input
                                                [class.is-invalid]="StreetAddress.invalid && (StreetAddress.dirty || StreetAddress.touched)"
                                                class="form-control" id="StreetAddress" type="StreetAddress"
                                                name="StreetAddress" placeholder=" Address "
                                                formControlName="StreetAddress">
                                            <!-- <div
                                                *ngIf="StreetAddress.invalid  && (StreetAddress.dirty || StreetAddress.touched)">
                                                <small *ngIf="StreetAddress.errors?.required" class="text-danger">Street
                                                    Address is
                                                    required</small>

                                            </div> -->
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                    <!-- <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Colony
                                                <span class="required"> * </span></label>
                                            <input
                                                [class.is-invalid]="Colony.invalid && (Colony.dirty || Colony.touched)"
                                                class="form-control" id="Colony" type="Colony" name="Colony"
                                                placeholder=" Colony " formControlName="Colony">
                                            <div *ngIf="Colony.invalid  && (Colony.dirty || Colony.touched)">
                                                <small *ngIf="Colony.errors?.required" class="text-danger">Colony is
                                                    required</small>

                                            </div>
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Locality
                                                <span class="required"> * </span></label>
                                            <input
                                                [class.is-invalid]="Locality.invalid && (Locality.dirty || Locality.touched)"
                                                class="form-control" id="Locality" type="Locality" name="Locality"
                                                placeholder=" Locality " formControlName="Locality">
                                            <div *ngIf="Locality.invalid  && (Locality.dirty || Locality.touched)">
                                                <small *ngIf="Locality.errors?.required" class="text-danger">Locality is
                                                    required</small>

                                            </div>
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>

                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">City
                                                <span class="required"> * </span></label>
                                            <input [class.is-invalid]="City.invalid && (City.dirty || City.touched)"
                                                class="form-control" id="City" type="City" name="City"
                                                placeholder=" City " formControlName="City">
                                            <div *ngIf="City.invalid  && (City.dirty || City.touched)">
                                                <small *ngIf="City.errors?.required" class="text-danger">City is
                                                    required</small>

                                            </div>
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">PinCode
                                                <span class="required"> * </span></label>
                                            <input
                                                [class.is-invalid]="PinCode.invalid && (PinCode.dirty || PinCode.touched)"
                                                class="form-control" id="PinCode" type="PinCode" name="PinCode"
                                                placeholder=" PinCode " formControlName="PinCode">
                                            <div *ngIf="PinCode.invalid  && (PinCode.dirty || PinCode.touched)">
                                                <small *ngIf="PinCode.errors?.required" class="text-danger">PinCode is
                                                    required</small>
                                                <small *ngIf="PinCode.errors?.pattern" class="text-danger">please enter
                                                    valid PinCode</small>


                                            </div>
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">State
                                                <span class="required"> * </span></label>
                                            <input [class.is-invalid]="State.invalid && (State.dirty || State.touched)"
                                                class="form-control" id="State" type="State" name="State"
                                                placeholder=" State " formControlName="State">
                                            <div *ngIf="State.invalid  && (State.dirty || State.touched)">
                                                <small *ngIf="State.errors?.required" class="text-danger">State is
                                                    required</small>

                                            </div>
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Country
                                                <span class="required"> * </span></label>
                                            <input
                                                [class.is-invalid]="Country.invalid && (Country.dirty || Country.touched)"
                                                class="form-control" id="Country" type="Country" name="Country"
                                                placeholder=" Country " formControlName="Country">
                                            <div *ngIf="Country.invalid  && (Country.dirty || Country.touched)">
                                                <small *ngIf="Country.errors?.required" class="text-danger">Country is
                                                    required</small>

                                            </div>
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div> -->



                                    <div
                                        style="margin:auto;text-align:center;margin-bottom:10px;margin-top:30px;margin-bottom:30px;">
                                        <a [routerLink]="" (click)="onSubmit(patientForm)"> <input type="submit"
                                                class="btn-pink" value="SAVE"></a>

                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>

            </div>
        </div>

        <script>

            var base_url = 'http://drgokulreddy.in/';
            function emp_entry() {

                var flag = 0;
                var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
                var alpha = /^[a-zA-Z\s]+$/;
                var email = $("#email").val();
                var regex = /^[0-9]*(?:\.\d{1,2})?$/;

                if ($("#name").val() == '') {
                    $("#name_error").html('Enter Name');
                    flag = 1;
                }
                else {
                    if (!alpha.test($("#name").val())) {
                        $("#name_error").html('Enter only Alphabets');
                        flag = 1;
                    }
                    else {
                        $("#name_error").html('');
                    }

                }
                if ($("#email").val() == '') {
                    $("#email_error").html('Enter email');
                    flag = 1;
                }
                else if (!pattern.test($("#email").val())) {
                    $("#email_error").html('Enter valid  Email');
                    flag = 1;
                }
                else {
                    $("#email_error").html('');
                }





                if ($("#pass").val() == '') {
                    $("#pass_error").html('Enter Password');
                    flag = 1;
                }
                else {
                    $("#pass_error").html('');

                }


                if ($("#utype").val() == '') {
                    $("#utype_error").html('Select User Type');
                    flag = 1;
                }
                else {
                    $("#utype_error").html('');

                }
                if (flag) {
                    $(".error").each(function () {
                        if ($(this).html().length > 0) {

                            var str = $(this).attr('id');
                            var res = str.split("_");
                            $("#" + res[0]).focus();
                            return false;
                        }

                    })
                    return false;
                }

            }


        </script>
        <script src="./GOKUL CLINIC - Add User page_files/jquery.dataTables.min.js.download"></script>
        <script src="./GOKUL CLINIC - Add User page_files/dataTables.bootstrap.min.js.download"></script>
        <style type="text/css">
            @media print {


                #get-quote,
                .upper {
                    display: none;
                }
            }
        </style>
        <script type="text/javascript">
            $('.print').click(function () {
                window.print();
                //printDiv();

            });
            function printDiv() {

                var divToPrint = document.getElementById('divtoprint');

                var newWin = window.open('', 'Print-Window');

                newWin.document.open();

                newWin.document.write('<html><body onload="window.print()">' + divToPrint.innerHTML + '</body></html>');

                newWin.document.close();

                setTimeout(function () { newWin.close(); }, 10);

            }
            $(document).ready(function () {
                $('#example').DataTable();
            });
            $(document).ready(function () {
                $(".alert").fadeTo(2000, 500).slideUp(500, function () {
                    $(".alert").slideUp(500);
                });
            });
        </script>
    </div>
    <script src="./GOKUL CLINIC - Add User page_files/tabs.js.download"></script>





</body>
<ngx-spinner></ngx-spinner>