import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';

@Component({
  selector: 'app-view-all-users',
  templateUrl: './view-all-users.component.html',
  styleUrls: ['./view-all-users.component.css']
})
export class ViewAllUsersComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private apicalls: ApicallsService, private router: ActivatedRoute, private route: Router, private httpClient: HttpClient) { }
  userslist;
  userDetails;
  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data
    this.apicalls.viewAllUsers()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          setTimeout(() => {

            this.spinner.hide();
          }, 500);
          this.userslist = data;
          //console.log("users...", this.userslist);
        } else {
          this.spinner.hide();
          if (data.code == 'PD01') {
            alert(data.data)
            this.route.navigate(["/dashboardmain/dashboard"]);
          }
          else {
            alert(data.data)
          }
        }
      }, error => { alert(" Internal Server Error "); }
        // this.alertService.warning(" Internal Server Error ");
      );

  }


  onClickCreate() {
    this.route.navigate(["/dashboardmain/CreateUser"]);
  }

  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }

  editdata(MedicID) {
    console.log(" ", MedicID)
    this.route.navigate(["/dashboardmain/UpdateUser", MedicID]);
  }


  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
  Settings(){
    this.route.navigate(["/dashboardmain/configurations"]);
  }
  

}
