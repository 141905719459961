<!DOCTYPE html>
<html lang="en">

<body>
    <div id="page" class="side-nav-wrapper">
        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pull-right" style="margin-right: 10px;">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                                style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                                <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                                <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                    userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                        class="">Settings</a></li>
                                <li
                                    *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                    <a [routerLink]="" (click)="users()" class="">Users</a></li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>


        <div class="container bg-gray">
            <form id="contact-form" class="contact-form pad-40" autocomplete="off" [formGroup]="RevenueForm">

                <div class="col-md-3" style="padding-top: 5px;" [hidden]="userDetails.Roles[0].Role == 'RECEPTIONIST' ">
                    <div class="input-text form-group has-feedback">
                        <div class="input-group date" style="width:215px;float:left;">

                            <input type='text' class="form-control" placeholder="select start date"
                                formControlName="StartDate" id="StartDate" name="StartDate" placement="right"
                                bsDatepicker [(bsValue)]="SelectStartDate">
                            <span class="input-group-addon">
                                <i class="glyphicon glyphicon-calendar"></i>
                            </span>

                        </div>
                        <small *ngIf="error && StartDate.errors?.required" class="text-danger">Start Date
                            is required</small>
                    </div>
                </div>
                <div class="col-md-3" style="padding-top: 5px;" [hidden]="userDetails.Roles[0].Role == 'RECEPTIONIST' ">
                    <div class="input-text form-group has-feedback">
                        <div class="input-group date" style="width:215px;float:left;">

                            <input type='text' class="form-control" placeholder="select end date"
                                formControlName="EndDate" id="EndDate" name="EndDate" placement="right" bsDatepicker
                                [(bsValue)]="SelectEndDate">
                            <span class="input-group-addon">
                                <i class="glyphicon glyphicon-calendar"></i>
                            </span>

                        </div>
                        <small *ngIf="error && EndDate.errors?.required" class="text-danger">End Date
                            is required</small>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-text form-group has-feedback">

                        <select class="way-custom-dropdown price-text-field profile-mwidth form-control"
                            style="background-color:#fff;margin-bottom:20px;" formControlName="MRNumber" id="MRNumber"
                            name="MRNumber" required="">
                            <option value="">All Customers</option>
                            <option *ngFor="let patient of patients;" value="{{patient.MRNumber}}">
                                {{patient.PatientName}}</option>

                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-text form-group has-feedback">

                        <select class="way-custom-dropdown price-text-field profile-mwidth form-control"
                            style="background-color:#fff;margin-bottom:20px;" [ngModel]="type" formControlName="Type" id="Type"
                            name="Type" required="" #mytypes (change)="onTypeSelected(mytypes.value)">
                            <option value="" disabled>Select Type</option>
                            <option value="All">All Types</option>
                            <option *ngFor="let list of BillingsTypes; let i=index"
                            value="{{list.BillingID}}">{{list.BillingName}}</option>
                        </select>
                    </div>
                </div>
            
                <div class="col-md-3" >
                    <div class="input-text form-group has-feedback">

                        <select class="way-custom-dropdown price-text-field profile-mwidth form-control"
                            style="background-color:#fff;margin-bottom:20px;" formControlName="BillID" id="BillID"
                            name="BillID">
                            <option value="">All Tests</option>
                            <option *ngFor="let list of Billings |filter:type; let i=index" value="{{list.BillingID}}" >
                                {{list.BillingName}}</option>
                        </select>
                    </div>
                </div>
               
                <div style="margin:auto;text-align:center;margin-bottom:0px;margin-top:0px;">
                    <a [routerLink]="" (click)="onSubmit(RevenueForm)"> <input type="submit" class="btn-pink"
                            value="Submit"></a>
                </div>
                <div style="clear:both;"></div>
                <div style="margin:auto;text-align:right;margin-bottom:0px;margin-top:0px;">
                    <a [routerLink]="" (click)="ExcelReport()" class="btn btn-info btn-lg"> 
                        <span type="submit" class="glyphicon glyphicon-download-alt"
                            value=""> </span>Download</a>
                </div>
            </form>
            <div class="row orr-usercreation ptop5 pbtom5" style="margin-top: 0px;">
                <div class="col-md-12">
                    <div class="panel">
                        <table cellspacing="0" cellpadding="0" width="100%" class="mirchi-birthday-grid" id="example">
                            <thead>
                                <tr>
                                    <th style="text-align:center;"> S.No</th>
                                    <th style="text-align:center;"> Payment ID</th>
                                    <th style="text-align:center;"> MRNumber</th>
                                    <th style="text-align:center;"> Patient Name</th>
                                    <th style="text-align:center;"> Date </th>

                                    <th style="text-align:center;"> Type</th>
                                    <th style="text-align:center;"> Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let list of  revenuedatadetails; let i=index ">
                                    <td style="text-align:center;">{{i + 1}}</td>
                                    <td style="text-align:center;">{{list.PaymentID}}</td>
                                    <td style="text-align:center;">{{list.MRNumber}}</td>
                                    <td style="text-align:center;">{{list.PatientName}}</td>
                                    <td style="text-align:center;">{{list.CreatedDate*1000|date:"dd-MM-yyyy"}}</td>

                                    <td style="text-align:center;"> {{list.Type}}</td>
                                    <td style="text-align:center;">{{list.TotalAmountPaid}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="row" style="padding-top: 20px;">
                            <div style="text-align: right; padding-right: 70px;">
                                <h5><b>TotalRevenue : </b> <b style="color:#367fa9;">{{TotalRevenue}}</b></h5>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>

</html>
<ngx-spinner></ngx-spinner>