import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aboutcholesterol',
  templateUrl: './aboutcholesterol.component.html',
  styleUrls: ['./aboutcholesterol.component.css']
})
export class AboutcholesterolComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  openlist;
  openlist1;
  over(){
    this.openlist="open"
  }

  out(){
    this.openlist="";
  }
  over1(){
    this.openlist1="open"
  }

  out1(){
    this.openlist1="";
  }

}
