<!DOCTYPE html>
<!-- saved from url=(0028)http://drgokulreddy.in/admin -->
<html lang="en">

<head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">

    <title> LOGIN </title>
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="keywords" content="HTML5 Template">
    <meta name="description" content="Mist — Multi-Purpose HTML Template">
    <meta name="author" content="">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <!-- Favicon -->

    <link rel="shortcut icon" href="http://drgokulreddy.in/assets/img/favicon.ico" />
    <!-- Font -->
    <link rel='stylesheet' href='http://fonts.googleapis.com/css?family=Arimo:300,400,700,400italic,700italic' />
    <link href='http://fonts.googleapis.com/css?family=Oswald:400,300,700' rel='stylesheet' type='text/css' />
    <!-- Font Awesome Icons -->
    <link href='http://drgokulreddy.in/assets/css/font-awesome.min.css' rel='stylesheet' type='text/css' />
    <!-- Bootstrap core CSS -->
    <link href="http://drgokulreddy.in/assets/css/bootstrap.min.css" rel="stylesheet" />
    <!-- Custom Style -->
    <link href="http://drgokulreddy.in/assets/css/style.css" rel="stylesheet" />
    <link href="http://drgokulreddy.in/assets/css/responsive.css" rel="stylesheet" />
    <!-- Color Scheme -->
    <link href="http://drgokulreddy.in/assets/css/color.css" rel="stylesheet" />
    <link href="http://drgokulreddy.in/assets/css/custom.css" rel="stylesheet" />
    <link href="http://drgokulreddy.in/assets/css/checkboxcss.css" rel="stylesheet" type="text/css">

    <script type="text/javascript" src="http://drgokulreddy.in/assets/js/jquery.min.js"></script>
    <script type="text/javascript" src="http://drgokulreddy.in/assets/js/bootstrap.min.js"></script>
    <style>
        .error {
            color: red;
        }
    </style>
</head>


<body class="bg-blue" data-gr-c-s-loaded="true" style="padding-bottom: 111px;">
    <div id="page">

        <section>
            <div class="container">
                <div class="row height80"></div>
            </div>
            <div>
                <div class="container">

                    <div class="row orr-usercreation mtop40">
                        <div class="col-md-12">
                            <div class="col-md-offset-4 col-md-4 login-mdiv">

                                <div class="col-md-12">



                                    <form id="contact-form" class="contact-form pad-40" method="post" autocomplete="off"
                                        [formGroup]="loginForm">
                                        <h3 class="title text-center mbtom0">LOGIN</h3>
                                        <p class="text-center"><img src="../assets/images/dash.png"></p>

                                        <div class="text-center fs20" *ngIf="loginError" [hidden]='IsLoggined'
                                            style="color:red"> {{loginError}}</div>

                                        <div class="form-group">
                                            <p class="login-usr"> <img src="../assets/images/user.jpg"></p>

                                            <input
                                                [class.is-invalid]="UserID.invalid && (UserID.dirty || UserID.touched)"
                                                class="form-control" id="UserID" type="UserID" name="UserID"
                                                placeholder="User Name" formControlName="UserID" required>
                                            <small *ngIf="error &&  UserID.errors?.required" class="text-danger">Email
                                                Id is
                                                required</small>
                                            <small *ngIf="UserID.errors?.pattern" class="text-danger">Please enter
                                                valid Email Id
                                            </small>

                                        </div>
                                        <p class="login-usr2"> <img src="../assets/images/lock.png"></p>
                                        <div class="form-group">
                                            <input
                                                [class.is-invalid]="Password.invalid && (Password.dirty || Password.touched)"
                                                class="form-control" id="Password" type="Password" name="Password"
                                                placeholder=" ********** " formControlName="Password">
                                            <small *ngIf="error && Password.errors?.required"
                                                class="text-danger">Password is
                                                required</small>

                                        </div>
                                        <div class="clearfix"></div>

                                        <div class="btn-signin">
                                            <a [routerLink]="" type="submit" (click)="onSubmit(loginForm)">
                                                SIGN IN</a>
                                        </div>

                                        <a [routerLink]="" (click)="onSubmitForgotPassword()"> Forgot Password? </a>
                                    </form>

                                </div>
                                <div class="col-md-12 text-center signin-name">
                                    <h5>DR GOKUL REDDY M</h5>
                                </div>
                                <div class="cleafix"></div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    </div>






</body>

</html>
<ngx-spinner></ngx-spinner>