import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApicallsService } from 'src/app/services/apicalls.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';

import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'app-upload-helath-reports',
  templateUrl: './upload-helath-reports.component.html',
  styleUrls: ['./upload-helath-reports.component.css']
})
export class UploadHelathReportsComponent implements OnInit {
  constructor(private spinner: NgxSpinnerService, private apicalls: ApicallsService, private router: ActivatedRoute,
    private route: Router, private httpClient: HttpClient) {
  }
  userDetails;
  ngOnInit(): void {

    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data

    this.router.paramMap.subscribe(params => {
      const AppointmentID = params.get('AppointmentID');
      if (AppointmentID) {
        this.getAppointmentID(AppointmentID);
      }
    });

    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumber: false,
        dateInputFormat: 'DD-MM-YYYY'

      }
    );
  }
  myFiles: string[] = [];
  datePickerConfig: Partial<BsDatepickerConfig>;
  onFileChange(event) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.myFiles.push(event.target.files[i]);
    }
  }
  Appointmentdetails;
  getAppointmentID(AppointmentID) {
    this.apicalls.viewAppointmentById(AppointmentID).subscribe(
      data => {
        this.spinner.show();

        if (data.type == true) {
          this.Appointmentdetails = data
          console.log(" Appointmentdetails ", this.Appointmentdetails)
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
          }, 500);
          this.healthReportForm.patchValue({
            AppointmentID: this.Appointmentdetails.data.AppointmentID,
            MedicID: this.Appointmentdetails.data.MedicID,
            DoctorName: this.Appointmentdetails.data.FirstName,
            MRNumber: this.Appointmentdetails.data.MRNumber,
            Fee: this.Appointmentdetails.data.Fee,
            //  MedicID:this.detailshospital.data.MedicID,
            DoctorID: this.Appointmentdetails.data.MedicID,
            //  DoctorName:this.detailshospital.data.DoctorName,
            PatientName: this.Appointmentdetails.data.PatientName,
            HospitalID: this.Appointmentdetails.data.HospitalID,
            HospitalName: this.Appointmentdetails.data.HospitalName,
            Discount: this.Appointmentdetails.data.Discount,
            Date: this.Appointmentdetails.data.Date,
            Time: this.Appointmentdetails.data.Time,
            //  Fee: this.detailshospital.data.Fee,
            PaymentStatus: this.Appointmentdetails.data.PaymentStatus,
            Age: this.Appointmentdetails.data.PatientDetails.Age,
            Gender: this.Appointmentdetails.data.PatientDetails.Gender,
            ContactNumber: this.Appointmentdetails.data.PatientDetails.ContactNumber,
            StreetAddress: this.Appointmentdetails.data.PatientDetails.Address.StreetAddress,
            Colony: this.Appointmentdetails.data.PatientDetails.Address.Colony,
            Locality: this.Appointmentdetails.data.PatientDetails.Address.Locality,
            PinCode: this.Appointmentdetails.data.PatientDetails.Address.PinCode,
            City: this.Appointmentdetails.data.PatientDetails.Address.City,
            State: this.Appointmentdetails.data.PatientDetails.Address.State,
            Country: this.Appointmentdetails.data.PatientDetails.Address.Country
          });
        } else {
          alert(data.message);
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ")
      });
  }
  healthReportForm = new FormGroup({
    PatientName: new FormControl('', Validators.required),
    MRNumber: new FormControl('', Validators.required),
    ReportGeneratedDate: new FormControl('', Validators.required),
    file: new FormControl('', Validators.required),
    ReportName: new FormControl('', Validators.pattern('[a-zA-Z ]*')),
    Description: new FormControl('', Validators.pattern('[a-zA-Z ]*')),
  })

  get PatientName() {
    return this.healthReportForm.get('PatientName');
  }
  get MRNumber() {
    return this.healthReportForm.get('MRNumber');
  }
  get ReportGeneratedDate() {
    return this.healthReportForm.get('ReportGeneratedDate');
  }
  get file() {
    return this.healthReportForm.get('file');
  }
  get ReportName() {
    return this.healthReportForm.get('ReportName');
  }
  get Description() {
    return this.healthReportForm.get('Description');
  }

  error;
  onSubmit(healthReportForm) {
    if (this.healthReportForm.invalid) {
      this.error = true;
      return;
    }

    const formData = new FormData();
    formData.append("MRNumber", this.Appointmentdetails.data.MRNumber);
    let ReportGeneratedDate = moment(this.healthReportForm.value.ReportGeneratedDate).format("DD-MM-YYYY");
    formData.append("ReportGeneratedDate", ReportGeneratedDate);
    formData.append("ReportName", this.healthReportForm.value.ReportName);
    formData.append("Description", this.healthReportForm.value.Description);

    for (var i = 0; i < this.myFiles.length; i++) {
      formData.append("Reports", this.myFiles[i]);
    }
    this.apicalls.uploadReports(formData)
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {
            this.spinner.hide();
            alert(data.data)
            this.route.navigate(["/dashboardmain/appointments"]);
          }
          if (data.code == 'S002') {
            alert(data.data);
            this.spinner.hide();
          }
        } else {
          alert(data.data)
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ");
      });

  }





  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }
  Settings(){
    this.route.navigate(["/dashboardmain/configurations"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }

}
