import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../services/apicalls.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboardmain',
  templateUrl: './dashboardmain.component.html',
  styleUrls: ['./dashboardmain.component.css']
})
export class DashboardmainComponent implements OnInit {


  constructor(private spinner: NgxSpinnerService, private httpclient: HttpClient, private apicalls: ApicallsService, private route: Router, private router: ActivatedRoute) { }
  userDetails
  ngOnInit(): void {
    let data = this.apicalls.getToken();
    if(data ==null ){
      this.route.navigate(["/logout"]);
    }
    this.userDetails = data.data.UserData.Data
  }
  /*Sidebar navigations Start*/

  dashboard() {
    this.route.navigate(["/dashboardmain/dashboard"]);
  }

  Resources() {
    this.route.navigate(["/dashboardmain/viewallresources"]);
  }

  Roles() {
    this.route.navigate(["/dashboardmain/viewallroles"]);
  }
  Privileges() {
    this.route.navigate(["/dashboardmain/privileges"]);
  }
  Permissions() {
    this.route.navigate(["/dashboardmain/permissions"]);
  }
  ChannelPartners() {
    this.route.navigate(["/dashboardmain/channelpartners"]);
  }

  Hospitals() {
    this.route.navigate(["/dashboardmain/hospitals"]);
  }
  // appointments() {
  //   this.route.navigate(["/dashboardmain/appointments"]);
  // }
  todaysAppointments() {
    this.route.navigate(["/dashboardmain/TodaysAppointments"]);
  }

  createPatientBillings() {
    this.route.navigate(["/dashboardmain/CreatePatientBillings"]);
  }

  // Billings() {
  //   this.route.navigate(["/dashboardmain/ViewAllbillings"]);
  // }
  Payments() {
    this.route.navigate(["/dashboardmain/ViewAllPayments"]);
  }

  Allechoreports() {
    this.route.navigate(["/dashboardmain/allechoreports"]);
  }

  Revenue() {
    this.route.navigate(["/dashboardmain/revenue"]);
  }

  Configurations() {
    this.route.navigate(["/dashboardmain/configuration"]);
  }

  Followupappointments() {
    this.route.navigate(["/dashboardmain/followupappointments"]);
  }
  patients() {
    this.route.navigate(["/dashboardmain/patients"]);
  }

  patientsbycondition() {
    this.route.navigate(["/dashboardmain/PatientsbyCondition"]);
  }
  newRegistartion() {
    this.route.navigate(["/dashboardmain/CreatePatientBillings"])
  }

  /*Sidebar navigations End*/

  /*Navibar navigations Start*/
  doctors() {
    this.route.navigate(["/dashboardmain/doctors"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  } Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  /*Navibar navigations End*/

}
