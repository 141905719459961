<!DOCTYPE html>
<!-- saved from url=(0046)http://drgokulreddy.in/prescription/10111/4169 -->
<html lang="en">

<head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <meta name="generator" content="">

    <title> GOKUL CLINIC - Patient Records page </title>
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="keywords" content="">
    <meta name="description" content="">
    <meta name="author" content="">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="shortcut icon" href="http://drgokulreddy.in/prescription/10111/img/favicon.ico">
    <!-- Font -->
    <link rel="stylesheet" href="http://drgokulreddy.in/assets/css">
    <link href="http://drgokulreddy.in/assets/css(1)" rel="stylesheet" type="text/css">
    <!-- Font Awesome Icons -->
    <link href="http://drgokulreddy.in/assets/css/font-awesome.min.css" rel="stylesheet" type="text/css">
    <!-- Bootstrap core CSS -->
    <link href="http://drgokulreddy.in/assets/css/bootstrap.min.css" rel="stylesheet">
    <link href="http://drgokulreddy.in/assets/css/hover-dropdown-menu.css" rel="stylesheet">
    <!-- Icomoon Icons -->
    <link href="http://drgokulreddy.in/assets/css/icons.css" rel="stylesheet">
    <!-- Revolution Slider -->
    <link href="http://drgokulreddy.in/assets/css/revolution-slider.css" rel="stylesheet">
    <link href="http://drgokulreddy.in/assets/css/settings.css" rel="stylesheet">
    <!-- Animations -->
    <link href="http://drgokulreddy.in/assets/css/animate.min.css" rel="stylesheet">
    <!-- Owl Carousel Slider -->
    <link href="http://drgokulreddy.in/assets/css/owl/owl.carousel.css" rel="stylesheet">
    <link href="http://drgokulreddy.in/assets/css/owl/owl.theme.css" rel="stylesheet">
    <link href="http://drgokulreddy.in/assets/css/owl/owl.transitions.css" rel="stylesheet">
    <!-- PrettyPhoto Popup -->
    <link href="http://drgokulreddy.in/assets/css/prettyPhoto.css" rel="stylesheet">
    <!-- Custom Style -->
    <link href="http://drgokulreddy.in/assets/css/owlcustom.css" rel="stylesheet">
    <link href="http://drgokulreddy.in/assets/css/style.css" rel="stylesheet">
    <link href="http://drgokulreddy.in/assets/css/responsive.css" rel="stylesheet">
    <!-- Color Scheme -->
    <link href="http://drgokulreddy.in/assets/css/color.css" rel="stylesheet">
    <link href="http://drgokulreddy.in/assets/css/checkboxcss.css" rel="stylesheet">
    <link rel="stylesheet" href="http://drgokulreddy.in/assets/css/tabs.css">



    <style>
        .error {
            color: red;
        }
    </style>
</head>

<body data-gr-c-s-loaded="true">
    <div id="page" class="side-nav-wrapper">
        <!-- Simple Navbar -->

        <!-- Simple Navbar -->
        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pull-right" style="margin-right: 10px;">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                                style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                                <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                                <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                    userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                        class="">Settings</a></li>
                                <li
                                    *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                    <a [routerLink]="" (click)="users()" class="">Users</a></li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>


        
        <div class="container bg-gray">
            <div class="row">
                <div class="col-md-12">
                    <h5 class="dr-sub-head"><b> PATIENT RECORDS PAGE </b></h5>
                </div>
            </div>
            <div class="row orr-usercreation ptop30 pbtom30">

                <div class="col-md-12">


                    <div class="panel" style="padding:15px;">
                        <div class="row">
                            <div class="col-md-6">
                                <h5 class="text-left"> NAME : {{detailsss?.Name}}</h5>

                            </div>
                            <div class="col-md-6">
                                <h5> DATE : {{patientappointmentdetails?.Date *1000|date:"dd-MMM-yyyy"}}</h5>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <h6 class="text-left">GENDER : {{detailsss?.Gender}} </h6>
                            </div>
                            <div class="col-md-3">
                                <h6 class="text-left"> AGE :  {{detailsss?.Age}}</h6>
                            </div>
                            <div class="col-md-6">
                                <h6>MR NUMBER : {{patientappointmentdetails?.MRNumber}}</h6>
                            </div>
                        </div>

                        <h5 class="blue-color ptop00"> DIAGNOSIS </h5>


                        <div class="row">
                            <div class="col-md-12">
                                <ul class="tabs">

                                        <li rel="tab1" class="gc-btn" (click)="chf()" [ngStyle]="chfvalue && {'background-color': '#01c887'}"> CHF </li>
                                        <li rel="tab2" class="" (click)="cad()" [ngStyle]="cadvalue && {'background-color': '#01c887'}"> CAD </li>
                                        <li rel="tab3" class="" (click)="htn()" [ngStyle]="htnvalue && {'background-color': '#01c887'}">HTN WITH NO CAD</li>
                                        <li rel="tab4" class="" (click)="dm()" [ngStyle]="dmvalue && {'background-color': '#01c887'}"> DM WITH No CAD</li>
                                        <li rel="tab5" class="" (click)="hcm()" [ngStyle]="hcmvalue && {'background-color': '#01c887'}"> HCM</li>
                                        <li rel="tab6" class="" (click)="pah()" [ngStyle]="pahvalue && {'background-color': '#01c887'}"> PAH</li>
                                        <li rel="tab7" class="" (click)="crhd()" [ngStyle]="crhdvalue && {'background-color': '#01c887'}"> CRHD</li>
                                        <li rel="tab8" class="" (click)="hlp()" [ngStyle]="hlpvalue && {'background-color': '#01c887'}"> HLP WITH NO CAD</li>
                                        <li rel="tab9" class="" (click)="cp()" [ngStyle]="cpvalue && {'background-color': '#01c887'}"> CHEST PAIN</li>
                                        <li rel="tab10" class="" (click)="others()" [ngStyle]="othervalue && {'background-color': '#01c887'}"> OTHER</li>
                                       
                                   
                                </ul>
                               


                                <div class="tab_container">



                                    <h3 class="tab_drawer_heading" rel="tab1"> CHF </h3>

                                    <div id="tab1" class="tab_content" style="display: block;" *ngIf="chfvalue">

                                        <div class="text-center fs20" *ngIf="submitError" [hidden]='isSubmitted'
                                        style="color:red"> {{submitError}}</div>
                                        <form autocomplete="off" [formGroup]="appointmentForm">

                                            <input name="pid"> <input type="hidden" name="app_id"
                                                value="10111">
                                            <h2 class="ttitle"><span><i></i></span> CHF </h2>



                                            <div class="row">
                                                <div class="col-md-2">
                                                    <h5 class="blue-color"> RISK FACTORS </h5>
                                                </div>
                                                <div class="col-md-10">
                                                    <div class="pull-left pr10">
                                                        <input id="2-1" class="checkbox-custom yellow"
                                                            name="risk_factor[]" value="HTN" type="checkbox" [checked]=htnchecked   (change)="onChange('HTN', $event.target.checked)"
                                                            formArrayName="RiskFactors">
                                                        <label for="2-1" class="checkbox-custom-label yellow"> HTN
                                                        </label>
                                                    </div>
                                                    <div class="pull-left pr10">
                                                        <input id="2-2"  class="checkbox-custom yellow"
                                                            name="risk_factor[]" value="DM" type="checkbox" [checked]=DMchecked  (change)="onChange('DM', $event.target.checked)"
                                                            formArrayName="RiskFactors">
                                                        <label for="2-2" class="checkbox-custom-label yellow"> DM 
                                                        </label>
                                                    </div>
                                                    <div class="pull-left pr10">
                                                        <input id="2-3" class="checkbox-custom yellow"
                                                            name="risk_factor[]" value="HLP" type="checkbox" [checked]=HLPchecked (change)="onChange('HLP', $event.target.checked)"
                                                            formArrayName="RiskFactors">
                                                        <label for="2-3" class="checkbox-custom-label yellow"> HLP
                                                        </label>
                                                    </div>
                                                    <div class="pull-left pr10">
                                                        <input id="2-4" class="checkbox-custom yellow"
                                                            name="risk_factor[]" value="SMOKER" type="checkbox" [checked]=SMOKERchecked (change)="onChange('SMOKER', $event.target.checked)"
                                                            formArrayName="RiskFactors">
                                                        <label for="2-4" class="checkbox-custom-label yellow"> SMOKER
                                                        </label>
                                                    </div>
                                                    <div class="pull-left pr10">
                                                        <input id="2-5" class="checkbox-custom yellow"
                                                            name="risk_factor[]" value="FHCAD" type="checkbox" [checked]=FHCADchecked (change)="onChange('FHCAD', $event.target.checked)"
                                                            formArrayName="RiskFactors">
                                                        <label for="2-5" class="checkbox-custom-label yellow"> FHCAD
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                                <div>
                                        <div class="pull-left pr10">
                                            <input id="1-1" class="checkbox-custom" name="chf_step1[]"  [checked]=HFPEFchecked (change)="onChange('HFPEF', $event.target.checked)"
                                            formArrayName="HeartFailure" value="HFPEF" type="checkbox">
                                            <label for="1-1" class="checkbox-custom-label"> HFPEF </label>
                                        </div>
                                        <div class="pull-left pr10">
                                            <input id="1-2" class="checkbox-custom" name="chf_step1[]" [checked]=HFREFchecked (change)="onChange('HFREF', $event.target.checked)"
                                            formArrayName="HeartFailure" value="HFREF" type="checkbox">
                                            <label for="1-2" class="checkbox-custom-label"> HFREF </label>
                                        </div>
                                        <div class="pull-left pr10">
                                            <input id="1-3" class="checkbox-custom" name="chf_step1[]" [checked]=COMPANSATEDCHFchecked  (change)="onChange('COMPANSATED CHF', $event.target.checked)"
                                            formArrayName="HeartFailure" value="COMPANSATED CHF" type="checkbox">
                                            <label for="1-3" class="checkbox-custom-label"> COMPENSATED  CHF </label>
                                        </div>
                                        <div class="pull-left pr10">
                                            <input id="1-4" class="checkbox-custom" name="chf_step1[]" [checked]=DECOMPANSATEDCHFchecked (change)="onChange('DECOMPANSATED CHF', $event.target.checked)"
                                            formArrayName="HeartFailure" value="DECOMPANSATED CHF" type="checkbox">
                                            <label for="1-4" class="checkbox-custom-label"> DECOMPENSATED CHF </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                                <div>
                                        <div class="pull-left pr10">
                                            <input id="1-5" class="checkbox-custom" name="chf_step2[]" [checked]=NYHAIchecked (change)="onChange('NYHA-I', $event.target.checked)"
                                            formArrayName="NYHA" value="NYHA-I" type="checkbox">
                                            <label for="1-5" class="checkbox-custom-label"> NYHA-I </label>
                                        </div>
                                        <div class="pull-left pr10">
                                            <input id="1-6" class="checkbox-custom" name="chf_step2[]" [checked]=NYHAIIchecked (change)="onChange('NYHA-II', $event.target.checked)"
                                            formArrayName="NYHA" value="NYHA-II" type="checkbox">
                                            <label for="1-6" class="checkbox-custom-label">  NYHA-II  </label>
                                        </div>
                                        <div class="pull-left pr10">
                                            <input id="1-7" class="checkbox-custom" name="chf_step2[]" [checked]=NYHAIIIchecked (change)="onChange('NYHA-III', $event.target.checked)"
                                            formArrayName="NYHA" value="NYHA-III" type="checkbox">
                                            <label for="1-7" class="checkbox-custom-label">  NYHA-III  </label>
                                        </div>
                                        <div class="pull-left pr10">
                                            <input id="1-8" class="checkbox-custom" name="chf_step2[]" [checked]=NYHAIVchecked (change)="onChange('NYHA-IV', $event.target.checked)"
                                            formArrayName="NYHA" value="NYHA-IV" type="checkbox">
                                            <label for="1-8" class="checkbox-custom-label">  NYHA-IV  </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                                <div>
                                        <div class="pull-left pr10">
                                            <input id="1-9" class="checkbox-custom" name="chf_step3[]" [checked]=STAGEAchecked (change)="onChange('STAGE-A', $event.target.checked)"
                                            formArrayName="Stage" value="STAGE-A" type="checkbox">
                                            <label for="1-9" class="checkbox-custom-label"> STAGE-A </label>
                                        </div>
                                        <div class="pull-left pr10">
                                            <input id="1-10" class="checkbox-custom" name="chf_step3[]" [checked]=STAGEBchecked (change)="onChange('STAGE-B', $event.target.checked)"
                                            formArrayName="Stage" value="STAGE-B" type="checkbox">
                                            <label for="1-10" class="checkbox-custom-label"> STAGE-B </label>
                                        </div>
                                        <div class="pull-left pr10">
                                            <input id="1-11" class="checkbox-custom" name="chf_step3[]" [checked]=STAGECchecked (change)="onChange('STAGE-C', $event.target.checked)"
                                            formArrayName="Stage"  value="STAGE-C" type="checkbox">
                                            <label for="1-11" class="checkbox-custom-label"> STAGE-C </label>
                                        </div>
                                        <div class="pull-left pr10">
                                            <input id="1-12" class="checkbox-custom" name="chf_step3[]" [checked]=STAGEDchecked (change)="onChange('STAGE-D', $event.target.checked)"
                                            formArrayName="Stage" value="STAGE-D" type="checkbox">
                                            <label for="1-12" class="checkbox-custom-label"> STAGE-D </label>
                                        </div>
                                        <div class="clearfix"></div>
                                            </div>

                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">CAG</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <input
                                                                [class.is-invalid]="CAG.invalid && (CAG.dirty || CAG.touched)"
                                                                class="form-control" id="CAG" type="text" name="CAG"
                                                                placeholder=" CAG " formControlName="CAG">
                                                            <!-- <div *ngIf="CAG.invalid  && (CAG.dirty || CAG.touched)"> -->
                                                                <small *ngIf="error && CAG.errors?.required"
                                                                    class="text-danger">CAG is
                                                                    required</small>
                                                                <!-- <small *ngIf="CAG.errors?.pattern" class="text-danger">CAG must be number  </small> -->

                                                            <!-- </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <b> DIAGNOSIS : </b>
                                            <div>

                                             

                                                <div class="pull-left pr10">
                                            <input id="1-13" class="checkbox-custom" name="diagnosys[]" [checked]=ICMPchecked (change)="onChange('ICMP', $event.target.checked)"
                                            formArrayName="DIAGNOSIS" value="ICMP" type="checkbox">
                                            <label for="1-13" class="checkbox-custom-label"> ICMP </label>
                                        </div>
                                        <div class="pull-left pr10">
                                            <input id="1-14" class="checkbox-custom" name="diagnosys[]" [checked]=DCMchecked (change)="onChange('DCM', $event.target.checked)"
                                            formArrayName="DIAGNOSIS" value="DCM" type="checkbox">
                                            <label for="1-14" class="checkbox-custom-label"> DCM </label>
                                        </div>
                                        <div class="pull-left pr10">
                                            <input id="1-15" class="checkbox-custom" name="diagnosys[]" [checked]=CRHDchecked  (change)="onChange('CRHD', $event.target.checked)"
                                            formArrayName="DIAGNOSIS" value="CRHD" type="checkbox">
                                            <label for="1-15" class="checkbox-custom-label"> CRHD </label>
                                        </div>

                                        <div class="pull-left pr10">
                                            <input id="1-16" class="checkbox-custom" name="diagnosys[]" [checked]=RCMPchecked  (change)="onChange('RCMP', $event.target.checked)"
                                            formArrayName="DIAGNOSIS" value="RCMP" type="checkbox">
                                            <label for="1-16" class="checkbox-custom-label"> RCMP </label>
                                        </div><div class="pull-left pr10">
                                            <input id="1-17" class="checkbox-custom" name="diagnosys[]" [checked]=PPAHchecked  (change)="onChange('P-PAH', $event.target.checked)"
                                            formArrayName="DIAGNOSIS"value="P-PAH" type="checkbox">
                                            <label for="1-17" class="checkbox-custom-label"> P-PAH </label>
                                        </div>
                                        <div class="pull-left pr10">
                                            <input id="1-18" class="checkbox-custom" name="diagnosys[]"[checked]=SECchecked  (change)="onChange('SEC', $event.target.checked)"
                                            formArrayName="DIAGNOSIS" value="SEC" type="checkbox">
                                            <label for="1-18" class="checkbox-custom-label"> SEC </label>
                                        </div>
                                        <div class="pull-left pr10">
                                            <input id="1-19" class="checkbox-custom" name="diagnosys[]" [checked]=OTHERSchecked (change)="onChange('OTHERS', $event.target.checked)"
                                            formArrayName="DIAGNOSIS" value="OTHERS" type="checkbox">
                                            <label for="1-19" class="checkbox-custom-label"> OTHERS </label>
                                        </div>
                                        <div class="clearfix"></div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">BP</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <input
                                                                [class.is-invalid]="BP.invalid && (BP.dirty || BP.touched)"
                                                                class="form-control" id="BP" type="BP" name="BP"
                                                                placeholder=" BP " formControlName="BP">
                                                            <!-- <div *ngIf="BP.invalid  && (BP.dirty || BP.touched)"> -->
                                                                <small *ngIf="error && BP.errors?.required"
                                                                    class="text-danger">BP is
                                                                    required</small>


                                                            <!-- </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">PR</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <input
                                                                [class.is-invalid]="PR.invalid && (PR.dirty || PR.touched)"
                                                                class="form-control" id="PR" type="text" name="PR"
                                                                placeholder=" PR " formControlName="PR">
                                                            <!-- <div *ngIf="PR.invalid  && (PR.dirty || PR.touched)"> -->
                                                                <small *ngIf="error && PR.errors?.required"
                                                                    class="text-danger">PR is
                                                                    required</small>

                                                            <!-- </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">BGH</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <!-- <textarea id="inputStandard" name="bgh" class="form-control" placeholder="" cols="10"></textarea> -->
                                                            <textarea
                                                                [class.is-invalid]="BGH.invalid && (BGH.dirty || BGH.touched)"
                                                                class="form-control" id="BGH" type="BGH" name="BGH"
                                                                placeholder=" BGH " formControlName="BGH"
                                                                cols="10"></textarea>
                                                            <!-- <div *ngIf="BGH.invalid  && (BGH.dirty || BGH.touched)"> -->
                                                                <small *ngIf="error && BGH.errors?.required"
                                                                    class="text-danger">BGH is
                                                                    required</small>

                                                            <!-- </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">NOTES</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <!-- <textarea id="inputStandard" name="notes" class="form-control" placeholder="" cols="10"></textarea> -->
                                                            <textarea
                                                                [class.is-invalid]="Notes.invalid && (Notes.dirty || Notes.touched)"
                                                                class="form-control" id="Notes" type="text" name="Notes"
                                                                placeholder=" Notes " formControlName="Notes"
                                                                cols="10"></textarea>
                                                            <!-- <div
                                                                *ngIf="Notes.invalid  && (Notes.dirty || Notes.touched)"> -->
                                                                <small *ngIf="error && Notes.errors?.required"
                                                                    class="text-danger">Notes is
                                                                    required</small>


                                                            <!-- </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">INVESTIGATIONS</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <!-- <textarea id="inputStandard" name="investigation" class="form-control" placeholder="" cols="10"></textarea> -->
                                                            <textarea
                                                                [class.is-invalid]="Investigations.invalid && (Investigations.dirty || Investigations.touched)"
                                                                class="form-control" id="Investigations" type="text"
                                                                name="Investigations" placeholder=" Investigations "
                                                                formControlName="Investigations" cols="10"></textarea>
                                                            <!-- <div
                                                                *ngIf="Investigations.invalid  && (Investigations.dirty || Investigations.touched)"> -->
                                                                <small *ngIf="error && Investigations.errors?.required"
                                                                    class="text-danger">Investigations is
                                                                    required</small>


                                                            <!-- </div> -->

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">Diagnosis</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <!-- <textarea id="inputStandard" name="prescription" class="form-control" placeholder="" cols="10"></textarea> -->

                                                            <textarea
                                                                [class.is-invalid]="Diagnosis.invalid && (Diagnosis.dirty || Diagnosis.touched)"
                                                                class="form-control" id="Diagnosis" type="text"
                                                                name="Diagnosis" placeholder=" Diagnosis "
                                                                formControlName="Diagnosis" cols="10"></textarea>
                                                            <!-- <div
                                                                *ngIf="Diagnosis.invalid  && (Diagnosis.dirty || Diagnosis.touched)"> -->
                                                                <small *ngIf="error && Diet.errors?.required"
                                                                    class="text-danger">Diagnosis is
                                                                    required</small>


                                                            <!-- </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">ADVICED Rx</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <!-- <textarea id="inputStandard" name="prescription" class="form-control" placeholder="" cols="10"></textarea> -->

                                                            <textarea
                                                                [class.is-invalid]="Prescription.invalid && (Prescription.dirty || Prescription.touched)"
                                                                class="form-control" id="Prescription" type="text"
                                                                name="Prescription" placeholder=" Prescription "
                                                                formControlName="Prescription" cols="10"></textarea>
                                                            <!-- <div
                                                                *ngIf="Prescription.invalid  && (Prescription.dirty || Prescription.touched)"> -->
                                                                <small *ngIf="error && Prescription.errors?.required"
                                                                    class="text-danger">Prescription is
                                                                    required</small>


                                                            <!-- </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">DIET and Any Advices</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <!-- <textarea id="inputStandard" name="prescription" class="form-control" placeholder="" cols="10"></textarea> -->

                                                            <textarea
                                                                [class.is-invalid]="Diet.invalid && (Diet.dirty || Diet.touched)"
                                                                class="form-control" id="Diet" type="text" name="Diet"
                                                                placeholder=" Diet " formControlName="Diet"
                                                                cols="10"></textarea>
                                                            <!-- <div *ngIf="Diet.invalid  && (Diet.dirty || Diet.touched)"> -->
                                                                <small *ngIf="error && Diet.errors?.required"
                                                                    class="text-danger">Diet is
                                                                    required</small>


                                                            <!-- </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">FOLLOW UP</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <!-- <input type="text" id="inputStandard" name="followup" class="form-control" placeholder=""> -->
                                                            <input
                                                                [class.is-invalid]="FollowUp.invalid && (FollowUp.dirty || FollowUp.touched)"
                                                                class="form-control" id="FollowUp" type="text"
                                                                name="FollowUp" placeholder=" FollowUp "
                                                                formControlName="FollowUp">
                                                            <!-- <div
                                                                *ngIf="FollowUp.invalid  && (FollowUp.dirty || FollowUp.touched)"> -->
                                                                <small *ngIf="error && FollowUp.errors?.required"
                                                                    class="text-danger">FollowUp is
                                                                    required</small>

                                                            <!-- </div> -->

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">FOLLOW UP Date</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <!-- <input type="text" id="inputStandard" name="followup" class="form-control" placeholder=""> -->
                                                            <div class="input-group date">
                                                                <input type='text' class="form-control"
                                                                    placeholder="dd-mm-yyyy"
                                                                    formControlName="FollowUpDate" id="FollowUpDate"
                                                                    name="FollowUpDate" #dp="bsDatepicker" bsDatepicker
                                                                    [bsConfig]="datePickerConfig" placement="right">
                                                                <span class="input-group-addon">
                                                                    <i class="glyphicon glyphicon-calendar"
                                                                        (click)="dp.toggle()"></i>
                                                                </span>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-center col-md-10">
                                             
                                                <button type="submit" class="btn btn-default upper submitBtn"><a
                                                        [routerLink]=""
                                                        (click)="onSubmit(appointmentForm)">Submit</a></button>

                                            </div>
                                        </form>
                                    </div>


                                    <h3 class="tab_drawer_heading" rel="tab2"> CAD </h3>
                                    <div id="tab2" class="tab_content" style="display: block;" *ngIf="cadvalue">
                                        
                                            <form autocomplete="off" [formGroup]="CADappointmentForm">


                                            <input type="hidden" name="pid" value="4169"> <input type="hidden"
                                                name="app_id" value="10111">
                                            <h2 class="ttitle"><span><i></i></span> CAD </h2>

                                            <div class="row">
                                                <div class="col-md-2">
                                                    <h5 class="blue-color"> RISK FACTORS </h5>
                                                </div>
                                                <div class="col-md-10">
                                                    <div class="pull-left pr10">
                                                        <input id="3-1" class="checkbox-custom yellow"
                                                            name="risk_factor[]" value="HTN" type="checkbox" [checked]=htnchecked1 (change)="onChangecad('HTN', $event.target.checked)"
                                                            formArrayName="RiskFactors1">
                                                        <label for="3-1" class="checkbox-custom-label yellow"> HTN
                                                        </label>
                                                    </div>
                                                    <div class="pull-left pr10">
                                                        <input id="3-2" class="checkbox-custom yellow"
                                                            name="risk_factor[]" value="DM" type="checkbox" [checked]=DMchecked1 (change)="onChangecad('DM', $event.target.checked)"
                                                            formArrayName="RiskFactors1">
                                                        <label for="3-2" class="checkbox-custom-label yellow"> DM
                                                        </label>
                                                    </div>
                                                    <div class="pull-left pr10">
                                                        <input id="3-3" class="checkbox-custom yellow"
                                                            name="risk_factor[]" value="HLP" type="checkbox" [checked]=HLPchecked1 (change)="onChangecad('HLP', $event.target.checked)"
                                                            formArrayName="RiskFactors1">
                                                        <label for="3-3" class="checkbox-custom-label yellow"> HLP
                                                        </label>
                                                    </div>
                                                    <div class="pull-left pr10">
                                                        <input id="3-4" class="checkbox-custom yellow"
                                                            name="risk_factor[]" value="SMOKER" type="checkbox" [checked]=SMOKERchecked1 (change)="onChangecad('SMOKER', $event.target.checked)"
                                                            formArrayName="RiskFactors1">
                                                        <label for="3-4" class="checkbox-custom-label yellow"> SMOKER
                                                        </label>
                                                    </div>
                                                    <div class="pull-left pr10">
                                                        <input id="3-5" class="checkbox-custom yellow"
                                                            name="risk_factor[]" value="FHCAD" type="checkbox" [checked]=FHCADchecked1 (change)="onChangecad('FHCAD', $event.target.checked)"
                                                            formArrayName="RiskFactors1">
                                                        <label for="3-5" class="checkbox-custom-label yellow"> FHCAD
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div>
                                                    <div class="pull-left pr10">
                                                        <input id="3-6" class="checkbox-custom " name="cad_step1[]" [checked]=APCLASSIchecked (change)="onChangeapclass('AP-CLASS-I', $event.target.checked)"
                                                        formArrayName="APCLASS"
                                                            value="AP-CLASS-I" type="checkbox">
                                                        <label for="3-6" class="checkbox-custom-label "> AP-CLASS-I
                                                        </label>
                                                    </div>
                                                    <div class="pull-left pr10">
                                                        <input id="3-7" class="checkbox-custom " name="cad_step1[]" [checked]=APCLASSIIchecked (change)="onChangeapclass('AP-CLASS-II', $event.target.checked)"
                                                        formArrayName="APCLASS"
                                                            value="AP-CLASS-II" type="checkbox">
                                                        <label for="3-7" class="checkbox-custom-label "> AP-CLASS-II
                                                        </label>
                                                    </div>
                                                    <div class="pull-left pr10">
                                                        <input id="3-8" class="checkbox-custom " name="cad_step1[]" [checked]=APCLASSIIIchecked (change)="onChangeapclass('AP-CLASS-III', $event.target.checked)"
                                                        formArrayName="APCLASS"
                                                            value="AP-CLASS-III" type="checkbox">
                                                        <label for="3-8" class="checkbox-custom-label "> AP-CLASS-III
                                                        </label>
                                                    </div>
                                                    <div class="pull-left pr10">
                                                        <input id="3-9" class="checkbox-custom " name="cad_step1[]" [checked]=APCLASSIVchecked (change)="onChangeapclass('AP-CLASS-IV', $event.target.checked)"
                                                        formArrayName="APCLASS"
                                                            value="AP-CLASS-IV" type="checkbox">
                                                        <label for="3-9" class="checkbox-custom-label "> AP-CLASS-IV
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>


                                                <!-- <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">CAG :</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <input type="text" name="cad_cag" id="inputStandard"
                                                                    class="form-control" placeholder="">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">CAG</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <input
                                                                    [class.is-invalid]="CAG1.invalid && (CAG1.dirty || CAG1.touched)"
                                                                    class="form-control" id="CAG1" type="text" name="CAG1"
                                                                    placeholder=" CAG " formControlName="CAG1">
                                                                <!-- <div *ngIf="BP1.invalid  && (BP1.dirty || BP1.touched)"> -->
                                                                    <small *ngIf="error && CAG1.errors?.required"
                                                                        class="text-danger">CAG is
                                                                        required</small>
    
    
                                                                <!-- </div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <b> ADVICE POST CAG : </b>
                                                <div>

                                                    <div class="pull-left pr10">
                                                        <input id="3-10" class="checkbox-custom yellow"
                                                            name="cad_diagnosys[]" [checked]=MMchecked (change)="onChangeadvicecag('MM', $event.target.checked)"
                                                            formArrayName="AdvicePostCAG" value="MM" type="checkbox">
                                                        <label for="3-10" class="checkbox-custom-label yellow"> MM
                                                        </label>
                                                    </div>
                                                    <div class="pull-left pr10">
                                                        <input id="3-11" class="checkbox-custom yellow"
                                                            name="cad_diagnosys[]" [checked]=CABGchecked (change)="onChangeadvicecag('CABG', $event.target.checked)"
                                                            formArrayName="AdvicePostCAG" value="CABG" type="checkbox">
                                                        <label for="3-11" class="checkbox-custom-label yellow"> CABG
                                                        </label>
                                                    </div>
                                                    <div class="pull-left pr10">
                                                        <input id="3-12" class="checkbox-custom yellow"
                                                            name="cad_diagnosys[]" [checked]=PTCAchecked (change)="onChangeadvicecag('PTCA', $event.target.checked)"
                                                            formArrayName="AdvicePostCAG" value="PTCA" type="checkbox">
                                                        <label for="3-12" class="checkbox-custom-label yellow"> PTCA
                                                        </label>
                                                    </div>
                                                    <div class="pull-left pr10">
                                                        <input id="3-13" class="checkbox-custom yellow"
                                                            name="cad_diagnosys[]" [checked]=OTHERchecked  (change)="onChangeadvicecag('OTHER', $event.target.checked)"
                                                            formArrayName="AdvicePostCAG" value="OTHER" type="checkbox">
                                                        <label for="3-13" class="checkbox-custom-label yellow"> OTHER
                                                        </label>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                </div>
                                                <div class="form-group">
                                                    <div>
                                                        <label for="2-14" class="checkbox-custom-label"> POST CABG
                                                        </label>
                                                    </div>
                                                    <div style="padding-top:5px;padding-left:15px;">

                                                        <div class="col-lg-10 col-md-6">
                                                            <div class="bs-component" style="display:flex;">
                                                                <select class="form-control mr10"  formControlName="PostCABG" name="PostCABG">
                                                                        <option value="">--Select--</option>
                                                                    <option value="1990">1990</option>
                                                                    <option value="1991">1991</option>
                                                                    <option value="1992">1992</option>
                                                                    <option value="1993">1993</option>
                                                                    <option value="1994">1994</option>
                                                                    <option value="1995">1995</option>
                                                                    <option value="1996">1996</option>
                                                                    <option value="1997">1997</option>
                                                                    <option value="1998">1998</option>
                                                                    <option value="1999">1999</option>
                                                                    <option value="2000">2000</option>
                                                                    <option value="2001">2001</option>
                                                                    <option value="2002">2002</option>
                                                                    <option value="2003">2003</option>
                                                                    <option value="2004">2004</option>
                                                                    <option value="2005">2005</option>
                                                                    <option value="2006">2006</option>
                                                                    <option value="2007">2007</option>
                                                                    <option value="2008">2008</option>
                                                                    <option value="2009">2009</option>
                                                                    <option value="2010">2010</option>
                                                                    <option value="2011">2011</option>
                                                                    <option value="2012">2012</option>
                                                                    <option value="2013">2013</option>
                                                                    <option value="2014">2014</option>
                                                                    <option value="2015">2015</option>
                                                                    <option value="2016">2016</option>
                                                                    <option value="2017">2017</option>
                                                                    <option value="2018">2018</option>
                                                                    <option value="2019">2019</option>
                                                                    <option value="2020">2020</option>

                                                                </select>
                                                                <input type="text" id="inputStandard"
                                                                    name="cad_step4_year" class="form-control" formControlName="PostCABGText"
                                                                    placeholder="">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div>

                                                        <label for="2-15" class="checkbox-custom-label"> POST PTCA
                                                        </label>
                                                    </div>
                                                    <div style="padding-top:5px;padding-left:15px;">

                                                        <div class="col-lg-10 col-md-6">
                                                            <div class="bs-component" style="display:flex;">
                                                                <select class="form-control mr10"  formControlName="PostPTCA" name="PostPTCA">
                                                                        <option value="">--Select--</option>
                                                                    <option value="1990">1990</option>
                                                                    <option value="1991">1991</option>
                                                                    <option value="1992">1992</option>
                                                                    <option value="1993">1993</option>
                                                                    <option value="1994">1994</option>
                                                                    <option value="1995">1995</option>
                                                                    <option value="1996">1996</option>
                                                                    <option value="1997">1997</option>
                                                                    <option value="1998">1998</option>
                                                                    <option value="1999">1999</option>
                                                                    <option value="2000">2000</option>
                                                                    <option value="2001">2001</option>
                                                                    <option value="2002">2002</option>
                                                                    <option value="2003">2003</option>
                                                                    <option value="2004">2004</option>
                                                                    <option value="2005">2005</option>
                                                                    <option value="2006">2006</option>
                                                                    <option value="2007">2007</option>
                                                                    <option value="2008">2008</option>
                                                                    <option value="2009">2009</option>
                                                                    <option value="2010">2010</option>
                                                                    <option value="2011">2011</option>
                                                                    <option value="2012">2012</option>
                                                                    <option value="2013">2013</option>
                                                                    <option value="2014">2014</option>
                                                                    <option value="2015">2015</option>
                                                                    <option value="2016">2016</option>
                                                                    <option value="2017">2017</option>
                                                                    <option value="2018">2018</option>
                                                                    <option value="2019">2019</option>
                                                                    <option value="2020">2020</option>

                                                                </select>
                                                                <input type="text" name="cad_step5_year"
                                                                    id="inputStandard" class="form-control" formControlName="PostPTCAText"
                                                                    placeholder="">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                             
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">BP</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <input
                                                                    [class.is-invalid]="BP1.invalid && (BP1.dirty || BP1.touched)"
                                                                    class="form-control" id="BP1" type="BP1" name="BP1"
                                                                    placeholder=" BP " formControlName="BP1">
                                                                <div *ngIf="BP1.invalid  && (BP1.dirty || BP1.touched)">
                                                                    <small *ngIf="BP1.errors?.required"
                                                                        class="text-danger">BP1 is
                                                                        required</small>
    
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">PR</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <input
                                                                    [class.is-invalid]="PR1.invalid && (PR1.dirty || PR1.touched)"
                                                                    class="form-control" id="PR1" type="text" name="PR1"
                                                                    placeholder=" PR " formControlName="PR1">
                                                                <div *ngIf="PR1.invalid  && (PR1.dirty || PR1.touched)">
                                                                    <small *ngIf="PR1.errors?.required"
                                                                        class="text-danger">PR is
                                                                        required</small>
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">BGH</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <!-- <textarea id="inputStandard" name="bgh" class="form-control" placeholder="" cols="10"></textarea> -->
                                                                <textarea
                                                                    [class.is-invalid]="BGH1.invalid && (BGH1.dirty || BGH1.touched)"
                                                                    class="form-control" id="BGH1" type="BGH1" name="BGH1"
                                                                    placeholder=" BGH " formControlName="BGH1"
                                                                    cols="10"></textarea>
                                                                <div *ngIf="BGH1.invalid  && (BGH1.dirty || BGH1.touched)">
                                                                    <small *ngIf="BGH1.errors?.required"
                                                                        class="text-danger">BGH is
                                                                        required</small>
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">NOTES</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <!-- <textarea id="inputStandard" name="notes" class="form-control" placeholder="" cols="10"></textarea> -->
                                                                <textarea
                                                                    [class.is-invalid]="Notes1.invalid && (Notes1.dirty || Notes1.touched)"
                                                                    class="form-control" id="Notes1" type="text" name="Notes1"
                                                                    placeholder=" Notes " formControlName="Notes1"
                                                                    cols="10"></textarea>
                                                                <div
                                                                    *ngIf="Notes1.invalid  && (Notes1.dirty || Notes1.touched)">
                                                                    <small *ngIf="Notes1.errors?.required"
                                                                        class="text-danger">Notes is
                                                                        required</small>
    
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">INVESTIGATIONS</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <!-- <textarea id="inputStandard" name="investigation" class="form-control" placeholder="" cols="10"></textarea> -->
                                                                <textarea
                                                                    [class.is-invalid]="Investigations1.invalid && (Investigations1.dirty || Investigations1.touched)"
                                                                    class="form-control" id="Investigations1" type="text"
                                                                    name="Investigations1" placeholder=" Investigations "
                                                                    formControlName="Investigations1" cols="10"></textarea>
                                                                <div
                                                                    *ngIf="Investigations1.invalid  && (Investigations1.dirty || Investigations1.touched)">
                                                                    <small *ngIf="Investigations1.errors?.required"
                                                                        class="text-danger">Investigations is
                                                                        required</small>
    
    
                                                                </div>
    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">Diagnosis</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <!-- <textarea id="inputStandard" name="prescription" class="form-control" placeholder="" cols="10"></textarea> -->
    
                                                                <textarea
                                                                    [class.is-invalid]="Diagnosis1.invalid && (Diagnosis1.dirty || Diagnosis1.touched)"
                                                                    class="form-control" id="Diagnosis1" type="text"
                                                                    name="Diagnosis1" placeholder=" Diagnosis "
                                                                    formControlName="Diagnosis1" cols="10"></textarea>
                                                                <div
                                                                    *ngIf="Diagnosis1.invalid  && (Diagnosis1.dirty || Diagnosis1.touched)">
                                                                    <small *ngIf="Diagnosis1.errors?.required"
                                                                        class="text-danger">Diagnosis is
                                                                        required</small>
    
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">ADVICED Rx</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <!-- <textarea id="inputStandard" name="prescription" class="form-control" placeholder="" cols="10"></textarea> -->
    
                                                                <textarea
                                                                    [class.is-invalid]="Prescription1.invalid && (Prescription1.dirty || Prescription1.touched)"
                                                                    class="form-control" id="Prescription1" type="text"
                                                                    name="Prescription1" placeholder=" Prescription "
                                                                    formControlName="Prescription1" cols="10"></textarea>
                                                                <div
                                                                    *ngIf="Prescription1.invalid  && (Prescription1.dirty || Prescription1.touched)">
                                                                    <small *ngIf="Prescription1.errors?.required"
                                                                        class="text-danger">Prescription is
                                                                        required</small>
    
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">DIET and Any Advices</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <!-- <textarea id="inputStandard" name="prescription" class="form-control" placeholder="" cols="10"></textarea> -->
    
                                                                <textarea
                                                                    [class.is-invalid]="Diet1.invalid && (Diet1.dirty || Diet1.touched)"
                                                                    class="form-control" id="Diet1" type="text" name="Diet1"
                                                                    placeholder=" Diet " formControlName="Diet1"
                                                                    cols="10"></textarea>
                                                                <div *ngIf="Diet1.invalid  && (Diet1.dirty || Diet1.touched)">
                                                                    <small *ngIf="Diet1.errors?.required"
                                                                        class="text-danger">Diet is
                                                                        required</small>
    
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">FOLLOW UP</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <!-- <input type="text" id="inputStandard" name="followup" class="form-control" placeholder=""> -->
                                                                <input
                                                                    [class.is-invalid]="FollowUp1.invalid && (FollowUp1.dirty || FollowUp1.touched)"
                                                                    class="form-control" id="FollowUp1" type="text"
                                                                    name="FollowUp1" placeholder=" FollowUp "
                                                                    formControlName="FollowUp1">
                                                                <!-- <div
                                                                    *ngIf="FollowUp.invalid  && (FollowUp.dirty || FollowUp.touched)"> -->
                                                                    <small *ngIf="errors && FollowUp1.errors?.required"
                                                                        class="text-danger">FollowUp is
                                                                        required</small>
    
                                                                <!-- </div> -->
    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">FOLLOW UP Date</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <!-- <input type="text" id="inputStandard" name="followup" class="form-control" placeholder=""> -->
                                                                <div class="input-group date">
                                                                    <input type='text' class="form-control"
                                                                        placeholder="dd-mm-yyyy"
                                                                        formControlName="FollowUpDate1" id="FollowUpDate1"
                                                                        name="FollowUpDate1" #dp="bsDatepicker" bsDatepicker
                                                                        [bsConfig]="datePickerConfig" placement="right">
                                                                    <span class="input-group-addon">
                                                                        <i class="glyphicon glyphicon-calendar"
                                                                            (click)="dp.toggle()"></i>
                                                                    </span>
    
                                                                </div>
    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="text-center col-md-10">
                                                        <button type="submit" class="btn btn-default upper submitBtn"><a
                                                            [routerLink]=""
                                                            (click)="onSubmitCAD(CADappointmentForm)">Submit</a></button>
                                                    <!-- <button type="submit"
                                                        class="btn btn-default upper submitBtn">Submit</button> -->
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                    <h3 class="tab_drawer_heading d_active" rel="tab3"> HTN NO CAD/CHF </h3>
                                    <div id="tab3" class="tab_content" style="display: block;" *ngIf="htnvalue">
                                            <form autocomplete="off" [formGroup]="HTN_NO_CAD_CHFappointmentForm">

                                            <input type="hidden" name="pid" value="4169"> <input type="hidden"
                                                name="app_id" value="10111">

                                            <h2 class="ttitle" style="margin-top: 35px;"><span><i></i></span> HTN NO
                                                CAD/CHF </h2>

                                            <div class="row">
                                                <div class="col-md-2">
                                                    <h5 class="blue-color"> RISK FACTORS </h5>
                                                </div>
                                                <div class="col-md-10">
                                                    <div class="pull-left pr10">
                                                        <input id="4-1" class="checkbox-custom yellow" [checked]=htnchecked2 (change)="onChangeHTNrisk('HTN', $event.target.checked)"
                                                        formArrayName="RiskFactors2"
                                                            name="risk_factor[]" value="HTN" type="checkbox">
                                                        <label for="4-1" class="checkbox-custom-label yellow"> HTN
                                                        </label>
                                                    </div>
                                                    <div class="pull-left pr10">
                                                        <input id="4-2" class="checkbox-custom yellow" [checked]=DMchecked2 (change)="onChangeHTNrisk('DM', $event.target.checked)"
                                                        formArrayName="RiskFactors2"
                                                            name="risk_factor[]" value="DM" type="checkbox">
                                                        <label for="4-2" class="checkbox-custom-label yellow"> DM
                                                        </label>
                                                    </div>
                                                    <div class="pull-left pr10">
                                                        <input id="4-3" class="checkbox-custom yellow" [checked]=HLPchecked2 (change)="onChangeHTNrisk('HLP', $event.target.checked)"
                                                        formArrayName="RiskFactors2"
                                                            name="risk_factor[]" value="HLP" type="checkbox">
                                                        <label for="4-3" class="checkbox-custom-label yellow"> HLP
                                                        </label>
                                                    </div>
                                                    <div class="pull-left pr10">
                                                        <input id="4-4" class="checkbox-custom yellow" [checked]=SMOKERchecked2 (change)="onChangeHTNrisk('SMOKER', $event.target.checked)"
                                                        formArrayName="RiskFactors2"
                                                            name="risk_factor[]" value="SMOKER" type="checkbox">
                                                        <label for="4-4" class="checkbox-custom-label yellow"> SMOKER
                                                        </label>
                                                    </div>
                                                    <div class="pull-left pr10">
                                                        <input id="4-5" class="checkbox-custom yellow" [checked]=FHCADchecked2 (change)="onChangeHTNrisk('FHCAD', $event.target.checked)"
                                                        formArrayName="RiskFactors2"
                                                            name="risk_factor[]" value="FHCAD" type="checkbox">
                                                        <label for="4-5" class="checkbox-custom-label yellow"> FHCAD
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">HTN SINCE</h4>
                                                        </label>
                                                        <div class="col-lg-3 col-md-6">
                                                            <div class="bs-component">
                                                                    <input
                                                                    [class.is-invalid]="HTN_Since.invalid && (HTN_Since.dirty || HTN_Since.touched)"
                                                                    class="form-control" id="HTN_Since" type="text" name="HTN_Since"
                                                                    placeholder=" HTN_Since " formControlName="HTN_Since">
                                                                <div *ngIf="HTN_Since.invalid  && (HTN_Since.dirty || HTN_Since.touched)">
                                                                    <small *ngIf="HTN_Since.errors?.required"
                                                                        class="text-danger">PR is
                                                                        required</small>
    
                                                                </div>
                                                                    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <b> TARGET ORGAN INVOLVEMENT : </b>
                                                <div>

                                                    <div class="pull-left pr10">

                                                        <b> KIDNEY : </b>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                    <div class="pull-left pr10">
                                                        <label class=""> CREATININE, GFR </label>
                                                      
                                                        <div class="bs-component" style="display:inline-block;">
                                                                <input
                                                                    [class.is-invalid]="CREATININE_GFR.invalid && (CREATININE_GFR.dirty || CREATININE_GFR.touched)"
                                                                    class="form-control" id="CREATININE_GFR" type="text" name="CREATININE_GFR"
                                                                    placeholder=" CREATININE_GFR " formControlName="CREATININE_GFR">
                                                                <div *ngIf="CREATININE_GFR.invalid  && (CREATININE_GFR.dirty || CREATININE_GFR.touched)">
                                                                    <small *ngIf="CREATININE_GFR.errors?.required"
                                                                        class="text-danger">CREATININE_GFR is
                                                                        required</small>
    
                                                                </div>
                                                        </div>

                                                        <label class="ml10"> PROTEINURIA, 24HOURS/SPOT PR:CR </label>
                                                        <div class="bs-component" style="display:inline-block;">
                                                                <input
                                                                [class.is-invalid]="PR_CR.invalid && (PR_CR.dirty || PR_CR.touched)"
                                                                class="form-control" id="PR_CR" type="text" name="PR_CR"
                                                                placeholder=" PROTEINURIA, 24HOURS/SPOT PR:CR " formControlName="PR_CR">
                                                            <div *ngIf="PR_CR.invalid  && (PR_CR.dirty || PR_CR.touched)">
                                                                <small *ngIf="PR_CR.errors?.required"
                                                                    class="text-danger">PR_CR is
                                                                    required</small>

                                                            </div>
                                                        </div>
                                                        <input id="4-6" class="checkbox-custom " name="htn_ckd" [checked]=CKDchecked (change)="onChangeckd('CKD', $event.target.checked)"
                                                        formArrayName="CKD"
                                                            value="CKD" type="checkbox">
                                                        <label for="4-6" class="checkbox-custom-label ml10 pn"> CKD
                                                        </label>
                                                    </div>

                                                    <div class="clearfix"></div>
                                                </div>
                                                <div>

                                                    <div class="pull-left pr35">
                                                        <b> CVS : </b>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                    <div class="pull-left pr10">
                                                        <label class="pn"> LVH-ECG </label>
                                                        <div class="bs-component" style="display:inline-block;">
                                                                <input
                                                                [class.is-invalid]="LVH_ECG.invalid && (LVH_ECG.dirty || LVH_ECG.touched)"
                                                                class="form-control" id="LVH_ECG" type="text" name="LVH_ECG"
                                                                placeholder=" LVH_ECG " formControlName="LVH_ECG">
                                                            <div *ngIf="LVH_ECG.invalid  && (LVH_ECG.dirty || LVH_ECG.touched)">
                                                                <small *ngIf="LVH_ECG.errors?.required"
                                                                    class="text-danger">LVH_ECG is
                                                                    required</small>

                                                            </div>
                                                        </div>

                                                      
                                                    </div>
                                                    <label class="ml10 pn"> LV MASS </label>
                                                    <div class="bs-component" style="display:inline-block;">
                                                            <input
                                                            [class.is-invalid]="LV_MASS.invalid && (LV_MASS.dirty || LV_MASS.touched)"
                                                            class="form-control" id="LV_MASS" type="text" name="LV_MASS"
                                                            placeholder=" LV_MASS " formControlName="LV_MASS">
                                                        <div *ngIf="LV_MASS.invalid  && (LV_MASS.dirty || LV_MASS.touched)">
                                                            <small *ngIf="LV_MASS.errors?.required"
                                                                class="text-danger">LV_MASS is
                                                                required</small>

                                                        </div>
                                                    </div>
                                                    <label class="ml10 pn"> LVEF </label>
                                                    <div class="bs-component" style="display:inline-block;">
                                                            <input
                                                            [class.is-invalid]="LVEF.invalid && (LVEF.dirty || LVEF.touched)"
                                                            class="form-control" id="LVEF" type="text" name="LVEF"
                                                            placeholder=" LVEF " formControlName="LVEF">
                                                        <div *ngIf="LVEF.invalid  && (LVEF.dirty || LVEF.touched)">
                                                            <small *ngIf="LVEF.errors?.required"
                                                                class="text-danger">LVEF is
                                                                required</small>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>
                                            </div>
                                            <div>

                                                <div class="pull-left pr10">
                                                    <input id="4-7" class="checkbox-custom " name="HTN_RETINOPATHY_OPTHAL_OPINION" [checked]=HTNROPTHALOPINIONchecked (change)="onChangeHTN_RETINOPATHY_OPTHAL_OPINION('HTN_RETINOPATHY_OPTHAL_OPINION', $event.target.checked)"
                                                    formArrayName="HTN_RETINOPATHY_OPTHAL_OPINION"
                                                        value="HTN RETINOPATHY, OPTHAL OPINION" type="checkbox">
                                                    <label for="4-7" class="checkbox-custom-label " > HTN RETINOPATHY,
                                                        OPTHAL OPINION </label>
                                                    <input id="4-8" class="checkbox-custom " [checked]=OLDCVAchecked (change)="onChangeOLD_CVA_OR_TIA('OLD_CVA_OR_TIA', $event.target.checked)"
                                                    formArrayName="OLD_CVA_OR_TIA" name="OLD_CVA_OR_TIA"
                                                        value="OLD CVA OR TIA" type="checkbox">
                                                    <label for="4-8" class="checkbox-custom-label ml10"> OLD CVA OR TIA
                                                    </label>
                                                </div>
                                                <div class="clearfix"></div>
                                            </div>





                                            <div class="ptop30">

                                                <div class="form-group">
                                                    <label class="ml10 pn"> ECHO </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                                <textarea
                                                            [class.is-invalid]="ECHO.invalid && (ECHO.dirty || ECHO.touched)"
                                                            class="form-control" id="ECHO" type="text" name="ECHO"
                                                            placeholder=" ECHO " formControlName="ECHO" cols="10"></textarea>
                                                        <div *ngIf="ECHO.invalid  && (ECHO.dirty || ECHO.touched)">
                                                            <small *ngIf="ECHO.errors?.required"
                                                                class="text-danger">ECHO is
                                                                required</small>

                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                             <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">BP</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <input
                                                                    [class.is-invalid]="BP2.invalid && (BP2.dirty || BP2.touched)"
                                                                    class="form-control" id="BP2" type="BP2" name="BP2"
                                                                    placeholder=" BP " formControlName="BP2">
                                                                <div *ngIf="BP2.invalid  && (BP2.dirty || BP2.touched)">
                                                                    <small *ngIf="BP2.errors?.required"
                                                                        class="text-danger">BP is
                                                                        required</small>
    
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">PR</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <input
                                                                    [class.is-invalid]="PR2.invalid && (PR2.dirty || PR2.touched)"
                                                                    class="form-control" id="PR2" type="text" name="PR2"
                                                                    placeholder=" PR " formControlName="PR2">
                                                                <div *ngIf="PR2.invalid  && (PR2.dirty || PR2.touched)">
                                                                    <small *ngIf="PR2.errors?.required"
                                                                        class="text-danger">PR is
                                                                        required</small>
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">BGH</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <textarea
                                                                    [class.is-invalid]="BGH2.invalid && (BGH2.dirty || BGH2.touched)"
                                                                    class="form-control" id="BGH2" type="BGH2" name="BGH2"
                                                                    placeholder=" BGH " formControlName="BGH2"
                                                                    cols="10"></textarea>
                                                                <div *ngIf="BGH2.invalid  && (BGH2.dirty || BGH2.touched)">
                                                                    <small *ngIf="BGH2.errors?.required"
                                                                        class="text-danger">BGH is
                                                                        required</small>
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">NOTES</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <textarea
                                                                    [class.is-invalid]="Notes2.invalid && (Notes2.dirty || Notes2.touched)"
                                                                    class="form-control" id="Notes2" type="text" name="Notes2"
                                                                    placeholder=" Notes " formControlName="Notes2"
                                                                    cols="10"></textarea>
                                                                <div
                                                                    *ngIf="Notes1.invalid  && (Notes2.dirty || Notes2.touched)">
                                                                    <small *ngIf="Notes2.errors?.required"
                                                                        class="text-danger">Notes is
                                                                        required</small>
    
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">INVESTIGATIONS</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <textarea
                                                                    [class.is-invalid]="Investigations2.invalid && (Investigations2.dirty || Investigations2.touched)"
                                                                    class="form-control" id="Investigations2" type="text"
                                                                    name="Investigations2" placeholder=" Investigations "
                                                                    formControlName="Investigations2" cols="10"></textarea>
                                                                <div
                                                                    *ngIf="Investigations2.invalid  && (Investigations2.dirty || Investigations2.touched)">
                                                                    <small *ngIf="Investigations2.errors?.required"
                                                                        class="text-danger">Investigations is
                                                                        required</small>
    
    
                                                                </div>
    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">Diagnosis</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
    
                                                                <textarea
                                                                    [class.is-invalid]="Diagnosis2.invalid && (Diagnosis2.dirty || Diagnosis2.touched)"
                                                                    class="form-control" id="Diagnosis2" type="text"
                                                                    name="Diagnosis2" placeholder=" Diagnosis "
                                                                    formControlName="Diagnosis2" cols="10"></textarea>
                                                                <div
                                                                    *ngIf="Diagnosis2.invalid  && (Diagnosis2.dirty || Diagnosis2.touched)">
                                                                    <small *ngIf="Diagnosis2.errors?.required"
                                                                        class="text-danger">Diagnosis is
                                                                        required</small>
    
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">ADVICED Rx</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                   <textarea
                                                                    [class.is-invalid]="Prescription2.invalid && (Prescription2.dirty || Prescription2.touched)"
                                                                    class="form-control" id="Prescription2" type="text"
                                                                    name="Prescription2" placeholder=" Prescription "
                                                                    formControlName="Prescription2" cols="10"></textarea>
                                                                <div
                                                                    *ngIf="Prescription2.invalid  && (Prescription2.dirty || Prescription2.touched)">
                                                                    <small *ngIf="Prescription2.errors?.required"
                                                                        class="text-danger">Prescription is
                                                                        required</small>
    
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">DIET and Any Advices</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                    <textarea
                                                                    [class.is-invalid]="Diet2.invalid && (Diet2.dirty || Diet2.touched)"
                                                                    class="form-control" id="Diet2" type="text" name="Diet2"
                                                                    placeholder=" Diet " formControlName="Diet2"
                                                                    cols="10"></textarea>
                                                                <div *ngIf="Diet2.invalid  && (Diet2.dirty || Diet2.touched)">
                                                                    <small *ngIf="Diet2.errors?.required"
                                                                        class="text-danger">Diet is
                                                                        required</small>
                                                                        </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">FOLLOW UP</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <input
                                                                    [class.is-invalid]="FollowUp2.invalid && (FollowUp2.dirty || FollowUp2.touched)"
                                                                    class="form-control" id="FollowUp2" type="text"
                                                                    name="FollowUp2" placeholder=" FollowUp "
                                                                    formControlName="FollowUp2">
                                                             
                                                                    <small *ngIf="errors && FollowUp2.errors?.required"
                                                                        class="text-danger">FollowUp is
                                                                        required</small>
    
    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">FOLLOW UP Date</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <div class="input-group date">
                                                                    <input type='text' class="form-control"
                                                                        placeholder="dd-mm-yyyy"
                                                                        formControlName="FollowUpDate2" id="FollowUpDate2"
                                                                        name="FollowUpDate2" #dp="bsDatepicker" bsDatepicker
                                                                        [bsConfig]="datePickerConfig" placement="right">
                                                                    <span class="input-group-addon">
                                                                        <i class="glyphicon glyphicon-calendar"
                                                                            (click)="dp.toggle()"></i>
                                                                    </span>
    
                                                                </div>
    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            <div class="text-center col-md-10">
                                               
                                                    <button type="submit" class="btn btn-default upper submitBtn"><a
                                                        [routerLink]=""
                                                        (click)="onSubmitHTN_NO_CAD_CHF(HTN_NO_CAD_CHFappointmentForm)">Submit</a></button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <!-- #tab3 htn no cad end -->


                                <!-- #tab4 dm no cad start -->
                                <h3 class="tab_drawer_heading" rel="tab4">DM NO CAD/CHF</h3>
                                <div id="tab4" class="tab_content" style="display: block;" *ngIf="dmvalue">
                                 
                                        <form autocomplete="off" [formGroup]="DM_NO_CAD_CHFappointmentForm">
                                        
                                        <input type="hidden" name="pid" value="4169"> <input type="hidden" name="app_id"
                                            value="10111">

                                        <h2 class="ttitle" style="margin-top:35px;"><span><i></i></span> DM NO CAD/CHF
                                        </h2>
                                        <div class="row">
                                            <div class="col-md-2">
                                                <h5 class="blue-color"> RISK FACTORS </h5>
                                            </div>
                                            <div class="col-md-10">
                                                <div class="pull-left pr10">
                                                    <input id="5-1" class="checkbox-custom yellow" [checked]=htnchecked3 name="risk_factor[]" (change)="onChangeDMrisk('HTN', $event.target.checked)"
                                                    formArrayName="RiskFactors3"
                                                        value="HTN" type="checkbox">
                                                    <label for="5-1" class="checkbox-custom-label yellow"> HTN </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="5-2" class="checkbox-custom yellow" [checked]=DMchecked3 name="risk_factor[]" (change)="onChangeDMrisk('DM', $event.target.checked)"
                                                    formArrayName="RiskFactors3"
                                                        value="DM" type="checkbox">
                                                    <label for="5-2" class="checkbox-custom-label yellow"> DM </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="5-3" class="checkbox-custom yellow" [checked]=HLPchecked3 name="risk_factor[]" (change)="onChangeDMrisk('HLP', $event.target.checked)"
                                                    formArrayName="RiskFactors3"
                                                        value="HLP" type="checkbox">
                                                    <label for="5-3" class="checkbox-custom-label yellow"> HLP </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="5-4" class="checkbox-custom yellow" [checked]=SMOKERchecked3 name="risk_factor[]" (change)="onChangeDMrisk('SMOKER', $event.target.checked)"
                                                    formArrayName="RiskFactors3"
                                                        value="SMOKER" type="checkbox">
                                                    <label for="5-4" class="checkbox-custom-label yellow"> SMOKER
                                                    </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="5-5" class="checkbox-custom yellow" [checked]=FHCADchecked3 name="risk_factor[]" (change)="onChangeDMrisk('FHCAD', $event.target.checked)"
                                                    formArrayName="RiskFactors3"
                                                        value="FHCAD" type="checkbox">
                                                    <label for="5-5" class="checkbox-custom-label yellow"> FHCAD
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">DM SINCE</h4>
                                                    </label>
                                                    <div class="col-lg-3 col-md-6">
                                                        <div class="bs-component">
                                                           
                                                                <input
                                                                [class.is-invalid]="DM_Since.invalid && (DM_Since.dirty || DM_Since.touched)"
                                                                class="form-control" id="DM_Since" type="text" name="DM_Since"
                                                                placeholder=" DM_Since " formControlName="DM_Since">
                                                            <div *ngIf="DM_Since.invalid  && (DM_Since.dirty || DM_Since.touched)">
                                                                <small *ngIf="DM_Since.errors?.required"
                                                                    class="text-danger">DM_Since is
                                                                    required</small>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <b> TARGET ORGAN INVOLVEMENT : </b>
                                            <div>

                                                <div class="pull-left pt10">

                                                    <label class="pn"> BMI </label>
                                                    <div class="bs-component" style="display:inline-block;">
                                                       
                                                            <input
                                                            [class.is-invalid]="BMI.invalid && (BMI.dirty || BMI.touched)"
                                                            class="form-control" id="BMI" type="text" name="BMI"
                                                            placeholder=" BMI " formControlName="BMI">
                                                        <div *ngIf="BMI.invalid  && (BMI.dirty || BMI.touched)">
                                                            <small *ngIf="BMI.errors?.required"
                                                                class="text-danger">Bmi is
                                                                required</small>

                                                        </div>
                                                    </div>
                                                    <label class="pn ml10"> HBA1C </label>
                                                    <div class="bs-component" style="display:inline-block;">
                                                       
                                                            <input
                                                            [class.is-invalid]="HBA1C.invalid && (HBA1C.dirty || HBA1C.touched)"
                                                            class="form-control" id="HBA1C" type="text" name="HBA1C"
                                                            placeholder=" HBA1C " formControlName="HBA1C">
                                                        <div *ngIf="HBA1C.invalid  && (HBA1C.dirty || HBA1C.touched)">
                                                            <small *ngIf="HBA1C.errors?.required"
                                                                class="text-danger">PR is
                                                                required</small>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>
                                                <div class="pull-left pr14">

                                                    <b> KIDNEY : </b>
                                                </div>
                                                <div class="clearfix"></div>
                                              
                                                <div class="pull-left pr10">
                                                    <label class=""> CREATININE, GFR </label>
                                                  
                                                    <div class="bs-component" style="display:inline-block;">
                                                       
                                                            <input
                                                                [class.is-invalid]="CREATININE_GFR1.invalid && (CREATININE_GFR1.dirty || CREATININE_GFR1.touched)"
                                                                class="form-control" id="CREATININE_GFR1" type="text" name="CREATININE_GFR1"
                                                                placeholder=" CREATININE " formControlName="CREATININE_GFR1">
                                                            <div *ngIf="CREATININE_GFR1.invalid  && (CREATININE_GFR1.dirty || CREATININE_GFR1.touched)">
                                                                <small *ngIf="CREATININE_GFR1.errors?.required"
                                                                    class="text-danger">CREATININE_GFR is
                                                                    required</small>

                                                            </div>
                                                    </div>

                                                    <label class="ml10"> PROTEINURIA, 24HOURS/SPOT PR:CR </label>
                                                    <div class="bs-component" style="display:inline-block;">
                                                        <!-- <input type="text" name="htn_PROTEINURIA" id="inputStandard"
                                                            class="form-control" placeholder=""> -->
                                                            <input
                                                            [class.is-invalid]="PR_CR1.invalid && (PR_CR1.dirty || PR_CR1.touched)"
                                                            class="form-control" id="PR_CR1" type="text" name="PR_CR1"
                                                            placeholder=" PR_CR1 " formControlName="PR_CR1">
                                                        <div *ngIf="PR_CR1.invalid  && (PR_CR1.dirty || PR_CR1.touched)">
                                                            <small *ngIf="PR_CR1.errors?.required"
                                                                class="text-danger">PR_CR is
                                                                required</small>

                                                        </div>
                                                    </div>
                                                    <input id="4-6" class="checkbox-custom " [checked]=CKDchecked1 name="htn_ckd" (change)="onChangeckdDM('CKD', $event.target.checked)"
                                                    formArrayName="CKD1"
                                                        value="CKD" type="checkbox">
                                                    <label for="4-6" class="checkbox-custom-label ml10 pn"> CKD
                                                    </label>
                                                </div>
                                                <div class="clearfix"></div>
                                            </div>
                                            <div>

                                                <div class="pull-left pr35">

                                                    <b> CVS : </b>
                                                </div>
                                                <div class="clearfix"></div>
                                                <div class="pull-left pr10">
                                                    
                                                    <label class="pn"> LVH-ECG </label>
                                                    <div class="bs-component" style="display:inline-block;">
                                                       
                                                            <input
                                                            [class.is-invalid]="LVH_ECG1.invalid && (LVH_ECG1.dirty || LVH_ECG1.touched)"
                                                            class="form-control" id="LVH_ECG1" type="text" name="LVH_ECG1"
                                                            placeholder=" LVH_ECG " formControlName="LVH_ECG1">
                                                        <div *ngIf="LVH_ECG1.invalid  && (LVH_ECG1.dirty || LVH_ECG1.touched)">
                                                            <small *ngIf="LVH_ECG1.errors?.required"
                                                                class="text-danger">LVH_ECG is
                                                                required</small>

                                                        </div>
                                                    </div>

                                                    <label class="ml10 pn"> LV MASS </label>
                                                    <div class="bs-component" style="display:inline-block;">
                                                     
                                                            <input
                                                            [class.is-invalid]="LV_MASS1.invalid && (LV_MASS1.dirty || LV_MASS1.touched)"
                                                            class="form-control" id="LV_MASS1" type="text" name="LV_MASS1"
                                                            placeholder=" LV_MASS " formControlName="LV_MASS1">
                                                        <div *ngIf="LV_MASS1.invalid  && (LV_MASS1.dirty || LV_MASS1.touched)">
                                                            <small *ngIf="LV_MASS1.errors?.required"
                                                                class="text-danger">LV_MASS is
                                                                required</small>

                                                        </div>
                                                    </div>
                                                    <label class="ml10 pn"> LVEF </label>
                                                    <div class="bs-component" style="display:inline-block;">
                                                      
                                                    
                                                    <input
                                                    [class.is-invalid]="LVEF1.invalid && (LVEF1.dirty || LVEF1.touched)"
                                                    class="form-control" id="LVEF1" type="text" name="LVEF1"
                                                    placeholder=" LVEF " formControlName="LVEF1">
                                                <div *ngIf="LVEF1.invalid  && (LVEF1.dirty || LVEF1.touched)">
                                                    <small *ngIf="LVEF1.errors?.required"
                                                        class="text-danger">LVEF is
                                                        required</small>

                                                </div>
                                                </div>
                                            </div>
                                                <div class="clearfix"></div>

                                            </div>
                                            <div>

                                                <div class="pull-left pr10">
                                                    <input id="5-7" class="checkbox-custom " name="dm_retina" [checked]=HTNRETINOPATHYchecked (change)="onChangeHTN_RETINOPATHY_OPTHAL_OPINION1('HTN_RETINOPATHY_OPTHAL_OPINION', $event.target.checked)"
                                                    formArrayName="HTN_RETINOPATHY_OPTHAL_OPINION1"
                                                        value="HTN RETINOPATHY, OPTHAL OPINION" type="checkbox">
                                                    <label for="5-7" class="checkbox-custom-label "> HTN RETINOPATHY,
                                                        OPTHAL OPINION </label>
                                                    <input id="5-8" class="checkbox-custom " name="dm_oldvca" [checked]=OLDCVADMchecked (change)="onChangeOLD_CVA_OR_TIA1('OLD_CVA_OR_TIA', $event.target.checked)"
                                                    formArrayName="OLD_CVA_OR_TIA1"
                                                        value="OLD CVA OR TIA" type="checkbox">
                                                    <label for="5-8" class="checkbox-custom-label ml10"> OLD CVA OR
                                                        TIA</label>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                            <div class="ptop30">

                                                    <div class="form-group">
                                                        <label class="ml10 pn"> ECHO </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <!-- <textarea id="inputStandard" name="htn_echo"
                                                                    class="form-control" placeholder=""
                                                                    cols="10"></textarea> -->
                                                                    <textarea
                                                                [class.is-invalid]="ECHO1.invalid && (ECHO1.dirty || ECHO1.touched)"
                                                                class="form-control" id="ECHO1" type="text" name="ECHO1"
                                                                placeholder=" ECHO " formControlName="ECHO1" cols="10"></textarea>
                                                            <div *ngIf="ECHO1.invalid  && (ECHO1.dirty || ECHO1.touched)">
                                                                <small *ngIf="ECHO1.errors?.required"
                                                                    class="text-danger">ECHO is
                                                                    required</small>
    
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                          
                                             <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">BP</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <input
                                                                    [class.is-invalid]="BP3.invalid && (BP3.dirty || BP3.touched)"
                                                                    class="form-control" id="BP3" type="BP3" name="BP3"
                                                                    placeholder=" BP " formControlName="BP3">
                                                                <div *ngIf="BP3.invalid  && (BP3.dirty || BP3.touched)">
                                                                    <small *ngIf="BP3.errors?.required"
                                                                        class="text-danger">BP is
                                                                        required</small>
    
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">PR</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <input
                                                                    [class.is-invalid]="PR3.invalid && (PR3.dirty || PR3.touched)"
                                                                    class="form-control" id="PR3" type="text" name="PR3"
                                                                    placeholder=" PR " formControlName="PR3">
                                                                <div *ngIf="PR3.invalid  && (PR3.dirty || PR3.touched)">
                                                                    <small *ngIf="PR3.errors?.required"
                                                                        class="text-danger">PR is
                                                                        required</small>
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">BGH</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <textarea
                                                                    [class.is-invalid]="BGH3.invalid && (BGH3.dirty || BGH3.touched)"
                                                                    class="form-control" id="BGH3" type="BGH3" name="BGH3"
                                                                    placeholder=" BGH " formControlName="BGH3"
                                                                    cols="10"></textarea>
                                                                <div *ngIf="BGH3.invalid  && (BGH3.dirty || BGH3.touched)">
                                                                    <small *ngIf="BGH3.errors?.required"
                                                                        class="text-danger">BGH is
                                                                        required</small>
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">NOTES</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <textarea
                                                                    [class.is-invalid]="Notes3.invalid && (Notes3.dirty || Notes3.touched)"
                                                                    class="form-control" id="Notes3" type="text" name="Notes3"
                                                                    placeholder=" Notes3 " formControlName="Notes3"
                                                                    cols="10"></textarea>
                                                                <div
                                                                    *ngIf="Notes3.invalid  && (Notes3.dirty || Notes3.touched)">
                                                                    <small *ngIf="Notes3.errors?.required"
                                                                        class="text-danger">Notes is
                                                                        required</small>
    
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">INVESTIGATIONS</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <textarea
                                                                    [class.is-invalid]="Investigations3.invalid && (Investigations3.dirty || Investigations3.touched)"
                                                                    class="form-control" id="Investigations3" type="text"
                                                                    name="Investigations3" placeholder=" Investigations "
                                                                    formControlName="Investigations3" cols="10"></textarea>
                                                                <div
                                                                    *ngIf="Investigations3.invalid  && (Investigations3.dirty || Investigations3.touched)">
                                                                    <small *ngIf="Investigations3.errors?.required"
                                                                        class="text-danger">Investigations is
                                                                        required</small>
    
    
                                                                </div>
    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">Diagnosis</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
    
                                                                <textarea
                                                                    [class.is-invalid]="Diagnosis3.invalid && (Diagnosis3.dirty || Diagnosis3.touched)"
                                                                    class="form-control" id="Diagnosis3" type="text"
                                                                    name="Diagnosis3" placeholder=" Diagnosis "
                                                                    formControlName="Diagnosis3" cols="10"></textarea>
                                                                <div
                                                                    *ngIf="Diagnosis3.invalid  && (Diagnosis3.dirty || Diagnosis3.touched)">
                                                                    <small *ngIf="Diagnosis3.errors?.required"
                                                                        class="text-danger">Diagnosis is
                                                                        required</small>
    
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">ADVICED Rx</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
    
                                                                <textarea
                                                                    [class.is-invalid]="Prescription3.invalid && (Prescription3.dirty || Prescription3.touched)"
                                                                    class="form-control" id="Prescription3" type="text"
                                                                    name="Prescription3" placeholder=" Prescription "
                                                                    formControlName="Prescription3" cols="10"></textarea>
                                                                <div
                                                                    *ngIf="Prescription3.invalid  && (Prescription3.dirty || Prescription3.touched)">
                                                                    <small *ngIf="Prescription3.errors?.required"
                                                                        class="text-danger">Prescription is
                                                                        required</small>
    
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">DIET and Any Advices</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
    
                                                                <textarea
                                                                    [class.is-invalid]="Diet3.invalid && (Diet3.dirty || Diet3.touched)"
                                                                    class="form-control" id="Diet3" type="text" name="Diet3"
                                                                    placeholder=" Diet " formControlName="Diet3"
                                                                    cols="10"></textarea>
                                                                <div *ngIf="Diet3.invalid  && (Diet3.dirty || Diet3.touched)">
                                                                    <small *ngIf="Diet3.errors?.required"
                                                                        class="text-danger">Diet is
                                                                        required</small>
    
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">FOLLOW UP</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <input
                                                                    [class.is-invalid]="FollowUp3.invalid && (FollowUp3.dirty || FollowUp3.touched)"
                                                                    class="form-control" id="FollowUp3" type="text"
                                                                    name="FollowUp3" placeholder=" FollowUp "
                                                                    formControlName="FollowUp3">
                                                                <!-- <div
                                                                    *ngIf="FollowUp.invalid  && (FollowUp.dirty || FollowUp.touched)"> -->
                                                                    <small *ngIf="errors && FollowUp3.errors?.required"
                                                                        class="text-danger">FollowUp is
                                                                        required</small>
    
                                                                <!-- </div> -->
    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">FOLLOW UP Date</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <!-- <input type="text" id="inputStandard" name="followup" class="form-control" placeholder=""> -->
                                                                <div class="input-group date">
                                                                    <input type='text' class="form-control"
                                                                        placeholder="dd-mm-yyyy"
                                                                        formControlName="FollowUpDate3" id="FollowUpDate3"
                                                                        name="FollowUpDate3" #dp="bsDatepicker" bsDatepicker
                                                                        [bsConfig]="datePickerConfig" placement="right">
                                                                    <span class="input-group-addon">
                                                                        <i class="glyphicon glyphicon-calendar"
                                                                            (click)="dp.toggle()"></i>
                                                                    </span>
    
                                                                </div>
    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            <div class="text-center col-md-10">
                                               
                                                    <button type="submit" class="btn btn-default upper submitBtn"><a
                                                        [routerLink]=""
                                                        (click)="onSubmitDM_NO_CAD_CHF(DM_NO_CAD_CHFappointmentForm)">Submit</a></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <!-- #tab4 dm no cad end -->

                                <!-- #tab5 hcm start -->
                                <h3 class="tab_drawer_heading" rel="tab5">HCM</h3>
                                <div id="tab5" class="tab_content" style="display: block;" *ngIf="hcmvalue">
                                  

                                        <form autocomplete="off" [formGroup]="HCMappointmentForm">
                                        <input type="hidden" name="pid" value="4169"> <input type="hidden" name="app_id"
                                            value="10111">
                                        <h2 class="ttitle" style="margin-top:35px;"><span><i></i></span> HCM </h2>
                                        <div class="row">
                                            <div class="col-md-2">
                                                <h5 class="blue-color"> RISK FACTORS </h5>
                                            </div>
                                            <div class="col-md-10">
                                                <div class="pull-left pr10">
                                                    <input id="6-1" class="checkbox-custom yellow" name="risk_factor[]" [checked]=htnchecked4 (change)="onChangeHCMRisk('HTN', $event.target.checked)"
                                                    formArrayName="RiskFactors4"
                                                        value="HTN" type="checkbox">
                                                    <label for="6-1" class="checkbox-custom-label yellow"> HTN </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="6-2" class="checkbox-custom yellow" name="risk_factor[]" [checked]=DMchecked4 (change)="onChangeHCMRisk('DM', $event.target.checked)"
                                                    formArrayName="RiskFactors4"
                                                        value="DM" type="checkbox">
                                                    <label for="6-2" class="checkbox-custom-label yellow"> DM </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="6-3" class="checkbox-custom yellow" name="risk_factor[]" [checked]=HLPchecked4 (change)="onChangeHCMRisk('HLP', $event.target.checked)"
                                                    formArrayName="RiskFactors4"
                                                        value="HLP" type="checkbox">
                                                    <label for="6-3" class="checkbox-custom-label yellow"> HLP </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="6-4" class="checkbox-custom yellow" name="risk_factor[]" [checked]=SMOKERchecked4 (change)="onChangeHCMRisk('SMOKER', $event.target.checked)"
                                                    formArrayName="RiskFactors4"
                                                        value="SMOKER" type="checkbox">
                                                    <label for="6-4" class="checkbox-custom-label yellow"> SMOKER
                                                    </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="6-5" class="checkbox-custom yellow" name="risk_factor[]" [checked]=FHCADchecked4 (change)="onChangeHCMRisk('FHCAD', $event.target.checked)"
                                                    formArrayName="RiskFactors4"
                                                        value="FHCAD" type="checkbox">
                                                    <label for="6-5" class="checkbox-custom-label yellow"> FHCAD
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">DIAGNOSED IN</h4>
                                                    </label>
                                                    <div class="col-lg-3 col-md-6">
                                                        <div class="bs-component">
                                                            <!-- <input type="text" name="diagnosed_in" id="inputStandard"
                                                                class="form-control" placeholder=""> -->
                                                                <input
                                                                [class.is-invalid]="DIAGNOSED_IN.invalid && (DIAGNOSED_IN.dirty || DIAGNOSED_IN.touched)"
                                                                class="form-control" id="DIAGNOSED_IN" type="text" name="DIAGNOSED_IN"
                                                                placeholder=" DIAGNOSED_IN " formControlName="DIAGNOSED_IN">
                                                            <div *ngIf="DIAGNOSED_IN.invalid  && (DIAGNOSED_IN.dirty || DIAGNOSED_IN.touched)">
                                                                <small *ngIf="DIAGNOSED_IN.errors?.required"
                                                                    class="text-danger">DIAGNOSED IN is
                                                                    required</small>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <b> TARGET ORGAN INVOLVEMENT</b>
                                            <div>

                                                <div class="pull-left pr10">

                                                    <b> CVS : </b>
                                                </div>
                                                <div class="clearfix"></div>
                                                <!-- <div class="pull-left pr10">
                                                    <label class=""> LVH-ECG </label>
                                                    <div class="bs-component" style="display:inline-block;">
                                                        <input type="text" name="hcm_lvh" id="inputStandard"
                                                            class="form-control" placeholder="">
                                                    </div>

                                                    <label for="5-10" class="ml10"> LV MASS </label>
                                                    <div class="bs-component" style="display:inline-block;">
                                                        <input type="text" name="hsm_lvmass" id="inputStandard"
                                                            class="form-control" placeholder="">
                                                    </div>
                                                    <label for="5-11" class="ml10"> LVEF </label>
                                                    <div class="bs-component" style="display:inline-block;">
                                                        <input type="text" name="hcm_lvef" id="inputStandard"
                                                            class="form-control" placeholder="">
                                                    </div>
                                                </div> -->
                                                <div class="pull-left pr10">
                                                    
                                                        <label class="pn"> LVH-ECG </label>
                                                        <div class="bs-component" style="display:inline-block;">
                                                            <!-- <input type="text" name="dm_lvh" id="inputStandard"
                                                                class="form-control" placeholder=""> -->
                                                                <input
                                                                [class.is-invalid]="LVH_ECG2.invalid && (LVH_ECG2.dirty || LVH_ECG2.touched)"
                                                                class="form-control" id="LVH_ECG2" type="text" name="LVH_ECG2"
                                                                placeholder=" LVH_ECG " formControlName="LVH_ECG2">
                                                            <div *ngIf="LVH_ECG2.invalid  && (LVH_ECG2.dirty || LVH_ECG2.touched)">
                                                                <small *ngIf="LVH_ECG2.errors?.required"
                                                                    class="text-danger">LVH_ECG is
                                                                    required</small>
    
                                                            </div>
                                                        </div>
    
                                                        <label class="ml10 pn"> LV MASS </label>
                                                        <div class="bs-component" style="display:inline-block;">
                                                            <!-- <input type="text" name="dm_lvmass" id="inputStandard"
                                                                class="form-control" placeholder=""> -->
                                                                <input
                                                                [class.is-invalid]="LV_MASS2.invalid && (LV_MASS2.dirty || LV_MASS2.touched)"
                                                                class="form-control" id="LV_MASS2" type="text" name="LV_MASS2"
                                                                placeholder=" LV_MASS " formControlName="LV_MASS2">
                                                            <div *ngIf="LV_MASS2.invalid  && (LV_MASS2.dirty || LV_MASS2.touched)">
                                                                <small *ngIf="LV_MASS2.errors?.required"
                                                                    class="text-danger">LV_MASS is
                                                                    required</small>
    
                                                            </div>
                                                        </div>
                                                        <label class="ml10 pn"> LVEF </label>
                                                        <div class="bs-component" style="display:inline-block;">
                                                            <!-- <input type="text" name="dm_lvef" id="inputStandard"
                                                                class="form-control" placeholder=""> -->
                                                        
                                                        <input
                                                        [class.is-invalid]="LVEF2.invalid && (LVEF2.dirty || LVEF2.touched)"
                                                        class="form-control" id="LVEF2" type="text" name="LVEF2"
                                                        placeholder=" LVEF " formControlName="LVEF2">
                                                    <!-- <div *ngIf="LVEF1.invalid  && (LVEF1.dirty || LVEF1.touched)"> -->
                                                        <small *ngIf="error && LVEF2.errors?.required"
                                                            class="text-danger">LVEF is
                                                            required</small>
    
                                                    <!-- </div> -->
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>
                                                <div class="pull-left">

                                                    <b> FAMILY HISTROY : </b>

                                                </div>
                                                <div class="clearfix"></div>
                                                <div class="pull-left pr10">

                                                    <label for="5-14" class="checkbox-custom-label yellow"> CREATININE,
                                                        GFR </label>
                                                    <div class="bs-component" style="display:inline-block;">
                                                        <!-- <input type="text" name="hcm_creatinine" id="inputStandard"
                                                            class="form-control" placeholder=""> -->
                                                            <input
                                                            [class.is-invalid]="CREATININE_GFR2.invalid && (CREATININE_GFR2.dirty || CREATININE_GFR2.touched)"
                                                            class="form-control" id="CREATININE_GFR2" type="text" name="CREATININE_GFR2"
                                                            placeholder=" CREATININE_GFR " formControlName="CREATININE_GFR2">
                                                        <div *ngIf="CREATININE_GFR2.invalid  && (CREATININE_GFR2.dirty || CREATININE_GFR2.touched)">
                                                            <small *ngIf="CREATININE_GFR2.errors?.required"
                                                                class="text-danger">CREATININE_GFR2 is
                                                                required</small>
        
                                                        </div>
                                                    </div>
                                                    <input id="5-15" class="checkbox-custom " name="hcm_gene" [checked]=GENESTUDYchecked (change)="onChangeGENESTUDY('GENE_STUDY', $event.target.checked)"
                                                    formArrayName="GENE_STUDY"
                                                        value="GENE STUDY" type="checkbox">
                                                    <label for="5-15" class="checkbox-custom-label pn ml10">GENE
                                                        STUDY</label>
                                                </div>
                                                <div class="clearfix"></div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">

                                                    <label for="inputStandard" class="control-label top-pad-0 pr17">
                                                        <h4 class="fs14 ptop8">SYMTOM STATUS</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <!-- <input type="text" name="symtom" id="inputStandard"
                                                                class="form-control" placeholder=""> -->
                                                                <input
                                                            [class.is-invalid]="SYMTOM_STATUS.invalid && (SYMTOM_STATUS.dirty || SYMTOM_STATUS.touched)"
                                                            class="form-control" id="SYMTOM_STATUS" type="text" name="SYMTOM_STATUS"
                                                            placeholder=" SYMTOM_STATUS " formControlName="SYMTOM_STATUS">
                                                        <div *ngIf="SYMTOM_STATUS.invalid  && (SYMTOM_STATUS.dirty || SYMTOM_STATUS.touched)">
                                                            <small *ngIf="SYMTOM_STATUS.errors?.required"
                                                                class="text-danger">SYMTOM_STATUS is
                                                                required</small>
        
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">BP</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <input type="text" id="inputStandard" name="bp"
                                                                class="form-control" placeholder="">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">PR</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <input type="text" id="inputStandard" name="pr"
                                                                class="form-control" placeholder="">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->


                                            <div class="ptop30">

                                                <div class="form-group">
                                                    <label class="ml10"> ECHO </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                                <textarea
                                                                [class.is-invalid]="ECHO2.invalid && (ECHO2.dirty || ECHO2.touched)"
                                                                class="form-control" id="ECHO2" type="text" name="ECHO2"
                                                                placeholder=" ECHO " formControlName="ECHO2" cols="10"></textarea>
                                                            <div *ngIf="ECHO2.invalid  && (ECHO2.dirty || ECHO2.touched)">
                                                                <small *ngIf="ECHO2.errors?.required"
                                                                    class="text-danger">ECHO is
                                                                    required</small>
    
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">BGH</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <textarea id="inputStandard" name="bgh" class="form-control"
                                                                placeholder="" cols="10"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">NOTES</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <textarea id="inputStandard" name="notes"
                                                                class="form-control" placeholder=""
                                                                cols="10"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">INVESTIGATIONS</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <textarea id="inputStandard" name="investigation"
                                                                class="form-control" placeholder=""
                                                                cols="10"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">FOLLOW UP</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <input type="text" id="inputStandard" name="followup"
                                                                class="form-control" placeholder="">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop20">
                                                <div class="form-group">

                                                    <label for="inputStandard" class="control-label top-pad-0 pr35">
                                                        <h4 class="fs14 ptop8">PRESCRIPTION</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <textarea id="inputStandard" name="hcm_prescription"
                                                                class="form-control" placeholder=""
                                                                cols="10"></textarea>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">BP</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <input
                                                                    [class.is-invalid]="BP4.invalid && (BP4.dirty || BP4.touched)"
                                                                    class="form-control" id="BP4" type="BP4" name="BP4"
                                                                    placeholder=" BP " formControlName="BP4">
                                                                <!-- <div *ngIf="BP3.invalid  && (BP3.dirty || BP3.touched)"> -->
                                                                    <small *ngIf="error && BP4.errors?.required"
                                                                        class="text-danger">BP is
                                                                        required</small>
    
    
                                                                <!-- </div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">PR</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <input
                                                                    [class.is-invalid]="PR4.invalid && (PR4.dirty || PR4.touched)"
                                                                    class="form-control" id="PR4" type="text" name="PR4"
                                                                    placeholder=" PR " formControlName="PR4">
                                                                <div *ngIf="PR4.invalid  && (PR4.dirty || PR4.touched)">
                                                                    <small *ngIf="PR4.errors?.required"
                                                                        class="text-danger">PR is
                                                                        required</small>
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">BGH</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <!-- <textarea id="inputStandard" name="bgh" class="form-control" placeholder="" cols="10"></textarea> -->
                                                                <textarea
                                                                    [class.is-invalid]="BGH4.invalid && (BGH4.dirty || BGH4.touched)"
                                                                    class="form-control" id="BGH4" type="text" name="BGH4"
                                                                    placeholder=" BGH " formControlName="BGH4"
                                                                    cols="10"></textarea>
                                                                <!-- <div *ngIf="BGH3.invalid  && (BGH3.dirty || BGH3.touched)"> -->
                                                                    <small *ngIf="error && BGH3.errors?.required"
                                                                        class="text-danger">BGH is
                                                                        required</small>
    
                                                                <!-- </div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">NOTES</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <!-- <textarea id="inputStandard" name="notes" class="form-control" placeholder="" cols="10"></textarea> -->
                                                                <textarea
                                                                    [class.is-invalid]="Notes4.invalid && (Notes4.dirty || Notes4.touched)"
                                                                    class="form-control" id="Notes4" type="text" name="Notes4"
                                                                    placeholder=" Notes " formControlName="Notes4"
                                                                    cols="10"></textarea>
                                                                <!-- <div
                                                                    *ngIf="Notes3.invalid  && (Notes3.dirty || Notes3.touched)"> -->
                                                                    <small *ngIf="error && Notes4.errors?.required"
                                                                        class="text-danger">Notes is
                                                                        required</small>
    
    
                                                                <!-- </div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">INVESTIGATIONS</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <!-- <textarea id="inputStandard" name="investigation" class="form-control" placeholder="" cols="10"></textarea> -->
                                                                <textarea
                                                                    [class.is-invalid]="Investigations4.invalid && (Investigations4.dirty || Investigations4.touched)"
                                                                    class="form-control" id="Investigations4" type="text"
                                                                    name="Investigations4" placeholder=" Investigations "
                                                                    formControlName="Investigations4" cols="10"></textarea>
                                                                <!-- <div
                                                                    *ngIf="Investigations3.invalid  && (Investigations3.dirty || Investigations3.touched)"> -->
                                                                    <small *ngIf="error && Investigations4.errors?.required"
                                                                        class="text-danger">Investigations is
                                                                        required</small>
    
    
                                                                <!-- </div> -->
    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">Diagnosis</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <!-- <textarea id="inputStandard" name="prescription" class="form-control" placeholder="" cols="10"></textarea> -->
    
                                                                <textarea
                                                                    [class.is-invalid]="Diagnosis4.invalid && (Diagnosis4.dirty || Diagnosis4.touched)"
                                                                    class="form-control" id="Diagnosis4" type="text"
                                                                    name="Diagnosis4" placeholder=" Diagnosis "
                                                                    formControlName="Diagnosis4" cols="10"></textarea>
                                                                <!-- <div
                                                                    *ngIf="Diagnosis3.invalid  && (Diagnosis3.dirty || Diagnosis3.touched)"> -->
                                                                    <small *ngIf="Diagnosis4.errors?.required"
                                                                        class="text-danger">Diagnosis is
                                                                        required</small>
    
    
                                                                <!-- </div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">ADVICED Rx</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <!-- <textarea id="inputStandard" name="prescription" class="form-control" placeholder="" cols="10"></textarea> -->
    
                                                                <textarea
                                                                    [class.is-invalid]="Prescription4.invalid && (Prescription4.dirty || Prescription4.touched)"
                                                                    class="form-control" id="Prescription4" type="text"
                                                                    name="Prescription4" placeholder=" Prescription "
                                                                    formControlName="Prescription4" cols="10"></textarea>
                                                                <!-- <div
                                                                    *ngIf="Prescription3.invalid  && (Prescription3.dirty || Prescription3.touched)"> -->
                                                                    <small *ngIf=" error && Prescription4.errors?.required"
                                                                        class="text-danger">Prescription is
                                                                        required</small>
    
    
                                                                <!-- </div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">DIET and Any Advices</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <!-- <textarea id="inputStandard" name="prescription" class="form-control" placeholder="" cols="10"></textarea> -->
    
                                                                <textarea
                                                                    [class.is-invalid]="Diet4.invalid && (Diet4.dirty || Diet4.touched)"
                                                                    class="form-control" id="Diet4" type="text" name="Diet4"
                                                                    placeholder=" Diet " formControlName="Diet4"
                                                                    cols="10"></textarea>
                                                                <!-- <div *ngIf="Diet4.invalid  && (Diet4.dirty || Diet4.touched)"> -->
                                                                    <small *ngIf="Diet4.errors?.required"
                                                                        class="text-danger">Diet is
                                                                        required</small>
    
    
                                                                <!-- </div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">FOLLOW UP</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <!-- <input type="text" id="inputStandard" name="followup" class="form-control" placeholder=""> -->
                                                                <input
                                                                    [class.is-invalid]="FollowUp4.invalid && (FollowUp4.dirty || FollowUp4.touched)"
                                                                    class="form-control" id="FollowUp4" type="text"
                                                                    name="FollowUp4" placeholder=" FollowUp "
                                                                    formControlName="FollowUp4">
                                                                <!-- <div
                                                                    *ngIf="FollowUp.invalid  && (FollowUp.dirty || FollowUp.touched)"> -->
                                                                    <small *ngIf="errors && FollowUp4.errors?.required"
                                                                        class="text-danger">FollowUp is
                                                                        required</small>
    
                                                                <!-- </div> -->
    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">FOLLOW UP Date</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <!-- <input type="text" id="inputStandard" name="followup" class="form-control" placeholder=""> -->
                                                                <div class="input-group date">
                                                                    <input type='text' class="form-control"
                                                                        placeholder="dd-mm-yyyy"
                                                                        formControlName="FollowUpDate4" id="FollowUpDate4"
                                                                        name="FollowUpDate4" #dp="bsDatepicker" bsDatepicker
                                                                        [bsConfig]="datePickerConfig" placement="right">
                                                                    <span class="input-group-addon">
                                                                        <i class="glyphicon glyphicon-calendar"
                                                                            (click)="dp.toggle()"></i>
                                                                    </span>
    
                                                                </div>
    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            <div class="text-center col-md-10">
                                                <!-- <button type="submit"
                                                    class="btn btn-default upper submitBtn">Submit</button> -->
                                                    <button type="submit" class="btn btn-default upper submitBtn"><a
                                                        [routerLink]=""
                                                        (click)="onSubmitHCM(HCMappointmentForm)">Submit</a></button>
                                                </div>
                                            </div>
                                    </form>
                                </div>

                                <!-- #tab5 hcm end -->

                                <!-- #tab6 pah start -->
                                <h3 class="tab_drawer_heading" rel="tab6">PAH</h3>
                                <div id="tab6" class="tab_content" style="display: block;" *ngIf="pahvalue">
                                    <!-- <form action="http://drgokulreddy.in/Home/insert_precord/doc_pah" method="post"
                                        onsubmit="return chf()"> -->
                                        <form autocomplete="off" [formGroup]="PAHappointmentForm">

                                        <input type="hidden" name="pid" value="4169"> <input type="hidden" name="app_id"
                                            value="10111">
                                        <h2 class="ttitle" style="margin-top:35px;"><span><i></i></span> PAH </h2>
                                        <div class="row">
                                            <div class="col-md-2">
                                                <h5 class="blue-color"> RISK FACTORS </h5>
                                            </div>
                                            <div class="col-md-10">
                                                <div class="pull-left pr10">
                                                    <input id="7-1" class="checkbox-custom yellow" name="risk_factor[]" [checked]=htnchecked5 (change)="onChangePAHRisk('HTN', $event.target.checked)"
                                                    formArrayName="RiskFactors5"
                                                        value="HTN" type="checkbox">
                                                    <label for="7-1" class="checkbox-custom-label yellow"> HTN </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="7-2" class="checkbox-custom yellow" name="risk_factor[]" [checked]=DMchecked5 (change)="onChangePAHRisk('DM', $event.target.checked)"
                                                    formArrayName="RiskFactors5"
                                                        value="DM" type="checkbox">
                                                    <label for="7-2" class="checkbox-custom-label yellow"> DM </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="7-3" class="checkbox-custom yellow" name="risk_factor[]" [checked]=HLPchecked5 (change)="onChangePAHRisk('HLP', $event.target.checked)"
                                                    formArrayName="RiskFactors5"
                                                        value="HLP" type="checkbox">
                                                    <label for="7-3" class="checkbox-custom-label yellow"> HLP </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="7-4" class="checkbox-custom yellow" name="risk_factor[]" [checked]=SMOKERchecked5 (change)="onChangePAHRisk('SMOKER', $event.target.checked)"
                                                    formArrayName="RiskFactors5"
                                                        value="SMOKER" type="checkbox">
                                                    <label for="7-4" class="checkbox-custom-label yellow"> SMOKER
                                                    </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="7-5" class="checkbox-custom yellow" name="risk_factor[]" [checked]=FHCADchecked5  (change)="onChangePAHRisk('FHCAD', $event.target.checked)"
                                                    formArrayName="RiskFactors5"
                                                        value="FHCAD" type="checkbox">
                                                    <label for="7-5" class="checkbox-custom-label yellow"> FHCAD
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="col-md-12">
                                            <b> PRESENTING REASON : </b>
                                            <div>

                                                <div class="pull-left pr10">
                                                    <input id="7-6" class="checkbox-custom yellow" name="pah_s1[]" [checked]=CLASSIPAHchecked (change)="onChangePAHClass('CLASS-I', $event.target.checked)"
                                                    formArrayName="Class"
                                                        value="CLASS-I" type="checkbox">
                                                    <label for="7-6" class="checkbox-custom-label yellow"> CLASS-I
                                                    </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="7-7" class="checkbox-custom yellow" name="pah_s1[]" [checked]=CLASSIIPAHchecked (change)="onChangePAHClass('CLASS-II', $event.target.checked)"
                                                    formArrayName="Class"
                                                        value="CLASS-II" type="checkbox">
                                                    <label for="7-7" class="checkbox-custom-label yellow"> CLASS-II 
                                                    </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="7-8" class="checkbox-custom yellow" name="pah_s1[]" [checked]=CLASSIIIPAHchecked (change)="onChangePAHClass('CLASS-III', $event.target.checked)"
                                                    formArrayName="Class"
                                                        value="CLASS-III" type="checkbox">
                                                    <label for="7-8" class="checkbox-custom-label yellow"> CLASS-III
                                                    </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="7-9" class="checkbox-custom yellow" name="pah_s1[]" [checked]=CLASSIVPAHchecked (change)="onChangePAHClass('CLASS-IV', $event.target.checked)"
                                                    formArrayName="Class"
                                                        value="CLASS-IV" type="checkbox">
                                                    <label for="7-9" class="checkbox-custom-label yellow"> CLASS-IV
                                                    </label>
                                                </div>

                                            </div>
                                            <div class="clearfix mb20"></div>


                                            <div>
                                                <div class="pull-left pr10">
                                                    <input id="7-10" class="checkbox-custom yellow" name="pah_chf[]" [checked]=PAHCOMPANSATEDchecked (change)="onChangePAHCOMPANSATED('COMPANSATED', $event.target.checked)"
                                                    formArrayName="COMPANSATED"
                                                        value="COMPANSATED" type="checkbox">
                                                    <label for="7-10" class="checkbox-custom-label yellow"> COMPANSATED
                                                    </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="7-11" class="checkbox-custom yellow" name="pah_chf[]" [checked]=PAHDECOMPANSATEDchecked (change)="onChangePAHDECOMPANSATED('DECOMPANSATED', $event.target.checked)"
                                                    formArrayName="DECOMPANSATED"
                                                        value="DECOMPANSATED" type="checkbox">
                                                    <label for="7-11" class="checkbox-custom-label yellow">
                                                        DECOMPANSATED </label>
                                                </div>
                                            </div>
                                            <div class="clearfix mb20"></div>
                                            <b> ECHO : </b>
                                            <div>
                                                <div class="pull-left pr10">

                                                    <label for="6-12" class="checkbox-custom-label yellow"> EF </label>
                                                    <div class="bs-component" style="display:inline-block;">
                                                        <!-- <input type="text" name="pah_ef" id="inputStandard"
                                                            class="form-control" placeholder=""> -->
                                                              <input
                                                                    [class.is-invalid]="EF.invalid && (EF.dirty || EF.touched)"
                                                                    class="form-control" id="EF" type="text"
                                                                    name="EF" placeholder=" EF "
                                                                    formControlName="EF">
                                                               
                                                                    <small *ngIf="errors && EF.errors?.required"
                                                                        class="text-danger">EF is
                                                                        required</small>
    
                                                    </div>
                                                    <label for="6-13" class="ml10"> PASP </label>
                                                    <div class="bs-component" style="display:inline-block;">
                                                        <!-- <input type="text" name="pah_pasp" id="inputStandard"
                                                            class="form-control" placeholder=""> -->
                                                            <input
                                                            [class.is-invalid]="PASP.invalid && (PASP.dirty || PASP.touched)"
                                                            class="form-control" id="PASP" type="text"
                                                            name="PASP" placeholder=" PASP "
                                                            formControlName="PASP">
                                                       
                                                            <small *ngIf="errors && PASP.errors?.required"
                                                                class="text-danger">PASP is
                                                                required</small>
                                                    </div>
                                                    <label for="6-14" class="ml10"> PADP </label>
                                                    <div class="bs-component" style="display:inline-block;">
                                                        <!-- <input type="text" name="pah_padp" id="inputStandard"
                                                            class="form-control" placeholder=""> -->
                                                            <input
                                                            [class.is-invalid]="PADP.invalid && (PADP.dirty || PADP.touched)"
                                                            class="form-control" id="PADP" type="text"
                                                            name="PADP" placeholder=" PADP "
                                                            formControlName="PADP">
                                                       
                                                            <small *ngIf="errors && PADP.errors?.required"
                                                                class="text-danger">PADP is
                                                                required</small>
                                                    </div>
                                                    <label for="6-15" class="ml10"> RV FUNCTION </label>
                                                    <div class="bs-component" style="display:inline-block;">
                                                        <!-- <input type="text" name="pah_rv" id="inputStandard"
                                                            class="form-control" placeholder=""> -->
                                                            <input
                                                            [class.is-invalid]="RV_FUNCTION.invalid && (RV_FUNCTION.dirty || RV_FUNCTION.touched)"
                                                            class="form-control" id="RV_FUNCTION" type="text"
                                                            name="RV_FUNCTION" placeholder=" RV_FUNCTION "
                                                            formControlName="RV_FUNCTION">
                                                       
                                                            <small *ngIf="errors && RV_FUNCTION.errors?.required"
                                                                class="text-danger">RV_FUNCTION is
                                                                required</small>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="clearfix mb20"></div>
                                            <b> DIAGNOSIS : </b>
                                            <div>
                                                <div class="pull-left pr10">
                                                    <input id="7-12" class="checkbox-custom yellow" [checked]=DIAGNOSISPAHPRIMARYchecked (change)="onChangePAHDIAGNOSIS('PRIMARY', $event.target.checked)"
                                                    formArrayName="DIAGNOSIS1"
                                                        name="pah_diagnosys[]" value="PRIMARY" type="checkbox">
                                                    <label for="7-12" class="checkbox-custom-label yellow"> PRIMARY
                                                    </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="7-13" class="checkbox-custom yellow" [checked]=DIAGNOSISPAHCTEPchecked (change)="onChangePAHDIAGNOSIS('CTEP', $event.target.checked)"
                                                    formArrayName="DIAGNOSIS1"
                                                        name="pah_diagnosys[]" value="CTEP" type="checkbox">
                                                    <label for="7-13" class="checkbox-custom-label yellow"> CTEP
                                                    </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="7-14" class="checkbox-custom yellow" [checked]=DIAGNOSISPAHSECchecked (change)="onChangePAHDIAGNOSIS('SEC TO COPD', $event.target.checked)"
                                                    formArrayName="DIAGNOSIS1"
                                                        name="pah_diagnosys[]" value="SEC TO COPD " type="checkbox">
                                                    <label for="7-14" class="checkbox-custom-label yellow"> SEC TO COPD
                                                    </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="7-15" class="checkbox-custom yellow" [checked]=DIAGNOSISPAHDUEchecked  (change)="onChangePAHDIAGNOSIS('DUE TO UNTREATED CHD', $event.target.checked)"
                                                    formArrayName="DIAGNOSIS1"
                                                        name="pah_diagnosys[]" value=" DUE TO UNTREATED CHD "
                                                        type="checkbox">
                                                    <label for="7-15" class="checkbox-custom-label yellow"> DUE TO
                                                        UNTREATED CHD </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="7-16" class="checkbox-custom yellow" [checked]=DIAGNOSISPAHotherchecked  (change)="onChangePAHDIAGNOSIS('OTHER', $event.target.checked)"
                                                    formArrayName="DIAGNOSIS1"
                                                        name="pah_diagnosys[]" value="OTHER" type="checkbox">
                                                    <label for="7-16" class="checkbox-custom-label yellow"> OTHER
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                            <!-- <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">BP</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <input type="text" id="inputStandard" name="bp"
                                                                class="form-control" placeholder="">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">PR</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <input type="text" id="inputStandard" name="pr"
                                                                class="form-control" placeholder="">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">BGH</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <textarea id="inputStandard" name="bgh" class="form-control"
                                                                placeholder="" cols="10"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">NOTES</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <textarea id="inputStandard" name="notes"
                                                                class="form-control" placeholder=""
                                                                cols="10"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">INVESTIGATIONS</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <textarea id="inputStandard" name="investigation"
                                                                class="form-control" placeholder=""
                                                                cols="10"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">FOLLOW UP</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <input type="text" id="inputStandard" name="followup"
                                                                class="form-control" placeholder="">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">

                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">PRESCRIPTION</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <textarea id="inputStandard" name="pah_pres"
                                                                class="form-control" placeholder=""
                                                                cols="10"></textarea>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->

                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">BP</h4>
                                               </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <input
                                                                [class.is-invalid]="BP5.invalid && (BP5.dirty || BP5.touched)"
                                                                class="form-control" id="BP5" type="text" name="BP5"
                                                                placeholder=" BP " formControlName="BP5">
                                                                <small *ngIf="error && BP5.errors?.required"
                                                                    class="text-danger">BP is
                                                                    required</small>


                                                          
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">PR</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <input
                                                                [class.is-invalid]="PR5.invalid && (PR5.dirty || PR5.touched)"
                                                                class="form-control" id="PR5" type="text" name="PR5"
                                                                placeholder=" PR " formControlName="PR5">
                                                                <small *ngIf="error && PR5.errors?.required"
                                                                    class="text-danger">PR is
                                                                    required</small>

                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">BGH</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <textarea
                                                                [class.is-invalid]="BGH5.invalid && (BGH5.dirty || BGH5.touched)"
                                                                class="form-control" id="BGH5" type="text" name="BGH5"
                                                                placeholder=" BGH " formControlName="BGH5"
                                                                cols="10"></textarea>
                                                                <small *ngIf="error && BGH5.errors?.required"
                                                                    class="text-danger">BGH is
                                                                    required</small>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">NOTES</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <textarea
                                                                [class.is-invalid]="Notes5.invalid && (Notes5.dirty || Notes5.touched)"
                                                                class="form-control" id="Notes5" type="text" name="Notes5"
                                                                placeholder=" Notes5 " formControlName="Notes5"
                                                                cols="10"></textarea>
                                                                <small *ngIf="error && Notes5.errors?.required"
                                                                    class="text-danger">Notes is
                                                                    required</small>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">INVESTIGATIONS</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <textarea
                                                                [class.is-invalid]="Investigations5.invalid && (Investigations5.dirty || Investigations5.touched)"
                                                                class="form-control" id="Investigations5" type="text"
                                                                name="Investigations5" placeholder=" Investigations "
                                                                formControlName="Investigations5" cols="10"></textarea>
                                                            
                                                                <small *ngIf="error && Investigations5.errors?.required"
                                                                    class="text-danger">Investigations is
                                                                    required</small>



                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">Diagnosis</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">

                                                            <textarea
                                                                [class.is-invalid]="Diagnosis5.invalid && (Diagnosis5.dirty || Diagnosis5.touched)"
                                                                class="form-control" id="Diagnosis5" type="text"
                                                                name="Diagnosis5" placeholder=" Diagnosis "
                                                                formControlName="Diagnosis5" cols="10"></textarea>
                                                           
                                                                <small *ngIf="error && Diagnosis5.errors?.required"
                                                                    class="text-danger">Diagnosis is
                                                                    required</small>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">ADVICED Rx</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <!-- <textarea id="inputStandard" name="prescription" class="form-control" placeholder="" cols="10"></textarea> -->

                                                            <textarea
                                                                [class.is-invalid]="Prescription5.invalid && (Prescription5.dirty || Prescription5.touched)"
                                                                class="form-control" id="Prescription5" type="text"
                                                                name="Prescription5" placeholder=" Prescription "
                                                                formControlName="Prescription5" cols="10"></textarea>
                                                                <small *ngIf="error && Prescription5.errors?.required"
                                                                    class="text-danger">Prescription is
                                                                    required</small>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">DIET and Any Advices</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">

                                                            <textarea
                                                                [class.is-invalid]="Diet5.invalid && (Diet5.dirty || Diet5.touched)"
                                                                class="form-control" id="Diet5" type="text" name="Diet5"
                                                                placeholder=" Diet " formControlName="Diet5"
                                                                cols="10"></textarea>
                                                                <small *ngIf="Diet5.errors?.required"
                                                                    class="text-danger">Diet is
                                                                    required</small>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">FOLLOW UP</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <!-- <input type="text" id="inputStandard" name="followup" class="form-control" placeholder=""> -->
                                                            <input
                                                                [class.is-invalid]="FollowUp5.invalid && (FollowUp5.dirty || FollowUp5.touched)"
                                                                class="form-control" id="FollowUp5" type="text"
                                                                name="FollowUp5" placeholder=" FollowUp "
                                                                formControlName="FollowUp5">
                                                                <small *ngIf="errors && FollowUp5.errors?.required"
                                                                    class="text-danger">FollowUp is
                                                                    required</small>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">FOLLOW UP Date</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <div class="input-group date">
                                                                <input type='text' class="form-control"
                                                                    placeholder="dd-mm-yyyy"
                                                                    formControlName="FollowUpDate5" id="FollowUpDate5"
                                                                    name="FollowUpDate5" #dp="bsDatepicker" bsDatepicker
                                                                    [bsConfig]="datePickerConfig" placement="right">
                                                                <span class="input-group-addon">
                                                                    <i class="glyphicon glyphicon-calendar"
                                                                        (click)="dp.toggle()"></i>
                                                                </span>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-center col-md-10">
                                                <!-- <button type="submit"
                                                    class="btn btn-default upper submitBtn">Submit</button> -->
                                                    <button type="submit" class="btn btn-default upper submitBtn"><a
                                                        [routerLink]=""
                                                        (click)="onSubmitPAH(PAHappointmentForm)">Submit</a></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <!-- #tab6 pah end -->

                                <!-- #tab7 chrd start-->
                                
                                <h3 class="tab_drawer_heading" rel="tab7">CRHD</h3>
                                <div id="tab7" class="tab_content" style="display: block;" *ngIf="crhdvalue">
                                    <!-- <form action="http://drgokulreddy.in/Home/insert_precord/doc_crhd" method="post"
                                        onsubmit="return chf()"> -->
                                        <form autocomplete="off" [formGroup]="CRHDappointmentForm">

                                        <input type="hidden" name="pid" value="4169"> <input type="hidden" name="app_id"
                                            value="10111">
                                        <h2 class="ttitle" style="margin-top:35px;"><span><i></i></span> CRHD </h2>
                                        <div class="row">
                                            <div class="col-md-2">
                                                <h5 class="blue-color"> RISK FACTORS </h5>
                                            </div>
                                            <div class="col-md-10">
                                                <div class="pull-left pr10">
                                                    <input id="8-1" class="checkbox-custom yellow" name="risk_factor[]" [checked]=htnchecked6 (change)="onChangeCRHDRisk('HTN', $event.target.checked)"
                                                    formArrayName="RiskFactors6"
                                                        value="HTN" type="checkbox">
                                                    <label for="8-1" class="checkbox-custom-label yellow"> HTN </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="8-2" class="checkbox-custom yellow" name="risk_factor[]" [checked]=DMchecked6 (change)="onChangeCRHDRisk('DM', $event.target.checked)"
                                                    formArrayName="RiskFactors6"
                                                        value="DM" type="checkbox">
                                                    <label for="8-2" class="checkbox-custom-label yellow"> DM </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="8-3" class="checkbox-custom yellow" name="risk_factor[]" [checked]=HLPchecked6 (change)="onChangeCRHDRisk('HLP', $event.target.checked)"
                                                    formArrayName="RiskFactors6"
                                                        value="HLP" type="checkbox">
                                                    <label for="8-3" class="checkbox-custom-label yellow"> HLP </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="8-4" class="checkbox-custom yellow" name="risk_factor[]" [checked]=SMOKERchecked6 (change)="onChangeCRHDRisk('SMOKER', $event.target.checked)"
                                                    formArrayName="RiskFactors6"
                                                        value="SMOKER" type="checkbox">
                                                    <label for="8-4" class="checkbox-custom-label yellow"> SMOKER
                                                    </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="8-5" class="checkbox-custom yellow" name="risk_factor[]" [checked]=FHCADchecked6 (change)="onChangeCRHDRisk('FHCAD', $event.target.checked)"
                                                    formArrayName="RiskFactors6"
                                                        value="FHCAD" type="checkbox">
                                                    <label for="8-5" class="checkbox-custom-label yellow"> FHCAD
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">CRHD SINCE</h4>
                                                    </label>
                                                    <div class="col-lg-3 col-md-6">
                                                        <div class="bs-component">
                                                            <!-- <input type="text" name="since" id="inputStandard"
                                                                class="form-control" placeholder=""> -->
                                                                <input
                                                                [class.is-invalid]="CRHD_SINCE.invalid && (CRHD_SINCE.dirty || CRHD_SINCE.touched)"
                                                                class="form-control" id="CRHD_SINCE" type="text"
                                                                name="CRHD_SINCE" placeholder=" CRHD_SINCE "
                                                                formControlName="CRHD_SINCE">
                                                           
                                                                <small *ngIf="errors && CRHD_SINCE.errors?.required"
                                                                    class="text-danger">CRHD_SINCE is
                                                                    required</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <b> VALVE INVOLVEMENT : </b>
                                            <div class="">
                                                <div class="col-md-6 pln">
                                                    <div class="pull-left pr10">

                                                        <b> MITRAL </b>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                    <div class="pull-left pr10">
                                                        <label for="8-6" class="checkbox-custom-label yellow"> MITRAL
                                                            STENOSIS </label>
                                                        <select class="form-control mr10" formControlName="MITRAL_STENOSIS" name="MITRAL_STENOSIS">
                                                                <option value="">---SELECT---</option>
                                                            <option value="NORMAL">NORMAL</option>
                                                            <option value="MILD">MILD</option>
                                                            <option value="MOD">MOD</option>
                                                            <option value="SEVERE">SEVERE</option>
                                                        </select>
                                                    </div>
                                                    <div class="pull-left pr10">


                                                        <label for="8-7" class="checkbox-custom-label yellow"> MITRAL
                                                            REGURGITATION</label>
                                                        <select class="form-control mr10"  formControlName="MITRAL_REGURGITATION" name="MITRAL_REGURGITATION">
                                                                <option value="">---SELECT---</option>
                                                            <option value="NORMAL">NORMAL</option>
                                                            <option value="MILD">MILD</option>
                                                            <option value="MOD">MOD</option>
                                                            <option value="SEVERE">SEVERE</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-md-6">
                                                    <div class="pull-left pr14">

                                                        <b> AORTIC </b>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                    <div class="pull-left pr10">
                                                        <label for="8-8" class="checkbox-custom-label yellow"> AORTIC
                                                            STENOSIS </label>
                                                        <select class="form-control mr10"  formControlName="AORTIC_STENOSIS" name="AORTIC_STENOSIS">
                                                                <option value="">---SELECT---</option>
                                                            <option value="NORMAL">NORMAL</option>
                                                            <option value="MILD">MILD</option>
                                                            <option value="MOD">MOD</option>
                                                            <option value="SEVERE">SEVERE</option>
                                                        </select>
                                                    </div>
                                                    <div class="pull-left pr10">
                                                        <label for="8-9" class="checkbox-custom-label yellow"> AORTIC
                                                            REGURGITATION </label>
                                                        <select class="form-control mr10"  formControlName="AORTIC_REGURGITATION" name="AORTIC_REGURGITATION">
                                                           
                                                                <option value="">---SELECT---</option>
                                                                 <option value="NORMAL">NORMAL</option>
                                                            <option value="MILD">MILD</option>
                                                            <option value="MOD">MOD</option>
                                                            <option value="SEVERE">SEVERE</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>
                                                <div class="col-md-6 pln">
                                                    <div class="pull-left pr14">

                                                        <b> TRICUSPID </b>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                    <div class="pull-left pr10">

                                                        <label for="8-10" class="checkbox-custom-label yellow">
                                                            TRICUSPID STENOSIS</label>
                                                        <select class="form-control mr10"  formControlName="TRICUSPID_STENOSIS" name="TRICUSPID_STENOSIS">
                                                           
                                                                <option value="">---SELECT---</option>
                                                                 <option value="NORMAL">NORMAL</option>
                                                            <option value="MILD">MILD</option>
                                                            <option value="MOD">MOD</option>
                                                            <option value="SEVERE">SEVERE</option>
                                                        </select>
                                                    </div>
                                                    <div class="pull-left pr10">
                                                        <label for="8-11" class="checkbox-custom-label yellow">
                                                            TRICUSPID REGURGITATION </label>
                                                        <select class="form-control mr10"  formControlName="TRICUSPID_REGURGITATION" name="TRICUSPID_REGURGITATION">
                                                          
                                                                <option value="">---SELECT---</option>
                                                                  <option value="NORMAL">NORMAL</option>
                                                            <option value="MILD">MILD</option>
                                                            <option value="MOD">MOD</option>
                                                            <option value="SEVERE">SEVERE</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="pull-left pr14">
                                                        <b> POLMONARY </b>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                    <div class="pull-left pr10">
                                                        <label for="8-12" class="checkbox-custom-label yellow">
                                                            PULMONARY STENOSIS </label>
                                                        <select class="form-control mr10"  formControlName="PULMONARY_STENOSIS" name="PULMONARY_STENOSIS">
                                                                <option value="">---SELECT---</option>
                                                            <option value="NORMAL">NORMAL</option>
                                                            <option value="MILD">MILD</option>
                                                            <option value="MOD">MOD</option>
                                                            <option value="SEVERE"> SEVERE</option>
                                                        </select>
                                                    </div>
                                                    <div class="pull-left pr10">
                                                        <label for="8-13" class="checkbox-custom-label yellow">
                                                            PULMONARY REGURGITATION</label>
                                                        <select class="form-control mr10"  formControlName="PULMONARY_REGURGITATION" name="PULMONARY_REGURGITATION">
                                                                <option value="">---SELECT---</option>
                                                            <option value="NORMAL">NORMAL</option>
                                                            <option value="MILD">MILD</option>
                                                            <option value="MOD">MOD</option>
                                                            <option value="SEVERE"> SEVERE</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>

                                                <div class="pull-left pr10">


                                                    <label for="8-15" class="checkbox-custom-label yellow"> PAH </label>
                                                    <div class="bs-component" style="display:inline-block;width:100px;">
                                                        <!-- <input type="text" name="cr_pah" id="inputStandard"
                                                            class="form-control" placeholder=""> -->
                                                            <input
                                                            [class.is-invalid]="PAH.invalid && (PAH.dirty || PAH.touched)"
                                                            class="form-control" id="PAH" type="text"
                                                            name="PAH" placeholder=" PAH "
                                                            formControlName="PAH">
                                                       
                                                            <small *ngIf="errors && PAH.errors?.required"
                                                                class="text-danger">PAH is
                                                                required</small>
                                                    </div>
                                                    <label for="8-16" class="checkbox-custom-label yellow"> LVEF
                                                    </label>
                                                    <div class="bs-component" style="display:inline-block;width:100px;">
                                                        <!-- <input type="text" name="cr_lvef" id="inputStandard"
                                                            class="form-control" placeholder=""> -->
                                                            <input
                                                            [class.is-invalid]="LVEF6.invalid && (LVEF6.dirty || LVEF6.touched)"
                                                            class="form-control" id="LVEF6" type="text"
                                                            name="LVEF6" placeholder=" LVEF "
                                                            formControlName="LVEF6">
                                                       
                                                            <small *ngIf="errors && LVEF6.errors?.required"
                                                                class="text-danger">LVEF is
                                                                required</small>
                                                    </div>
                                                    <label for="8-17" class="checkbox-custom-label yellow"> LVEDD
                                                    </label>
                                                    <div class="bs-component" style="display:inline-block;width:100px;">
                                                        <!-- <input type="text" name="cr_lvedd" id="inputStandard"
                                                            class="form-control" placeholder=""> -->
                                                            <input
                                                            [class.is-invalid]="LVEDD.invalid && (LVEDD.dirty || LVEDD.touched)"
                                                            class="form-control" id="LVEDD" type="text"
                                                            name="LVEDD" placeholder=" LVEDD "
                                                            formControlName="LVEDD">
                                                       
                                                            <small *ngIf="errors && LVEDD.errors?.required"
                                                                class="text-danger">LVEDD is
                                                                required</small>
                                                    </div>
                                                    <label for="8-18" class="checkbox-custom-label yellow"> LVESD
                                                    </label>
                                                    <div class="bs-component" style="display:inline-block;width:100px;">
                                                        <!-- <input type="text" name="cr_lvsd" id="inputStandard"
                                                            class="form-control" placeholder=""> -->
                                                            <input
                                                            [class.is-invalid]="LVESD.invalid && (LVESD.dirty || LVESD.touched)"
                                                            class="form-control" id="LVESD" type="text"
                                                            name="LVESD" placeholder=" LVESD "
                                                            formControlName="LVESD">
                                                       
                                                            <small *ngIf="errors && LVESD.errors?.required"
                                                                class="text-danger">LVESD is
                                                                required</small>
                                                    </div>
                                                    <label for="8-19" class="checkbox-custom-label yellow"> RV FUNCTION
                                                    </label>
                                                    <div class="bs-component" style="display:inline-block;width:100px;">
                                                        <!-- <input type="text" name="cr_rv" id="inputStandard"
                                                            class="form-control" placeholder=""> -->
                                                            <input
                                                            [class.is-invalid]="RV_FUNCTION6.invalid && (RV_FUNCTION6.dirty || RV_FUNCTION6.touched)"
                                                            class="form-control" id="RV_FUNCTION6" type="text"
                                                            name="RV_FUNCTION6" placeholder=" RV_FUNCTION "
                                                            formControlName="RV_FUNCTION6">
                                                       
                                                            <small *ngIf="errors && RV_FUNCTION6.errors?.required"
                                                                class="text-danger">RV_FUNCTION is
                                                                required</small>
                                                    </div>
                                                </div>
                                                <div class="clearfix mb20"></div>

                                                <div>
                                                    <div class="pull-left pr10">
                                                        <input id="8-20" class="checkbox-custom " name="cr_s3"  [checked]=OLD_CVA_OR_TIA_CAROTIDchecked (change)="onChangeCRHDCarotid('OLD_CVA_OR_TIA_CAROTID', $event.target.checked)"
                                                        formArrayName="OLD_CVA_OR_TIA_CAROTID"
                                                            value="OLD CVA OR TIA, CAROTID" type="checkbox">
                                                        <label for="8-20" class="checkbox-custom-label ">OLD CVA OR TIA,
                                                            CAROTID</label>
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>

                                                <div>

                                                    <div class="pull-left pr10">
                                                        <input id="8-21" class="checkbox-custom " name="cr_s4[]" [checked]=NYHAICRHDchecked (change)="onChangeCRHDNYHA('NYHA-I', $event.target.checked)"
                                                        formArrayName="NYHS"
                                                            value="NYHA-I" type="checkbox">
                                                        <label for="8-21" class="checkbox-custom-label "> NYHA-I
                                                        </label>
                                                    </div>
                                                    <div class="pull-left pr10">
                                                        <input id="8-22" class="checkbox-custom " name="cr_s4[]" [checked]=NYHAIICRHDchecked (change)="onChangeCRHDNYHA('NYHA-II', $event.target.checked)"
                                                        formArrayName="NYHS"
                                                            value="NYHA-II" type="checkbox">
                                                        <label for="8-22" class="checkbox-custom-label "> NYHA-II
                                                        </label>
                                                    </div>
                                                    <div class="pull-left pr10">
                                                        <input id="8-23" class="checkbox-custom " name="cr_s4[]" [checked]=NYHAIIICRHDchecked (change)="onChangeCRHDNYHA('NYHA-III', $event.target.checked)"
                                                        formArrayName="NYHS"
                                                            value="NYHA-III" type="checkbox">
                                                        <label for="8-23" class="checkbox-custom-label "> NYHA-III
                                                        </label>
                                                    </div>
                                                    <div class="pull-left pr10">
                                                        <input id="8-24" class="checkbox-custom " name="cr_s4[]" [checked]=NYHAIVCRHDchecked (change)="onChangeCRHDNYHA('NYHA-IV', $event.target.checked)"
                                                        formArrayName="NYHS"
                                                            value="NYHA-IV" type="checkbox">
                                                        <label for="8-24" class="checkbox-custom-label "> NYHA-IV
                                                        </label>
                                                    </div>
                                                    <div class="clearfix"></div>

                                                </div>
                                                <div>
                                                    <div class="pull-left pr10">
                                                        <input id="8-25" class="checkbox-custom " name="cr_s4[]" [checked]=SINUSCRHDchecked (change)="onChangeCRHDSinus('SINUS_RYTHM', $event.target.checked)"
                                                        formArrayName="SINUS_RYTHM"
                                                            value="SINUS RYTHM" type="checkbox">
                                                        <label for="8-25" class="checkbox-custom-label "> SINUS RYTHM
                                                        </label>
                                                    </div>
                                                    <div class="pull-left pr10">
                                                        <input id="8-26" class="checkbox-custom " name="cr_s4[]" [checked]=AFCRHDchecked (change)="onChangeCRHDaf('AF', $event.target.checked)"
                                                        formArrayName="AF"
                                                            value="AF" type="checkbox">
                                                        <label for="8-26" class="checkbox-custom-label "> AF </label>
                                                    </div>
                                                    <div class="pull-left pr10">
                                                        <input id="8-27" class="checkbox-custom " name="cr_s4[]" [checked]=HOIECRHDchecked (change)="onChangeCRHDho_ie('HO_IE', $event.target.checked)"
                                                        formArrayName="HO_IE"
                                                            value="H/O IE" type="checkbox">
                                                        <label for="8-27" class="checkbox-custom-label "> H/O IE
                                                        </label>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                </div>
                                                <div class="ptop30">

                                                    <div class="form-group">
                                                        <label class="ml10 pn"> ECHO </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <!-- <textarea id="inputStandard" name="htn_echo"
                                                                    class="form-control" placeholder=""
                                                                    cols="10"></textarea> -->
                                                                    <textarea
                                                                [class.is-invalid]="ECHO6.invalid && (ECHO6.dirty || ECHO6.touched)"
                                                                class="form-control" id="ECHO6" type="text" name="ECHO6"
                                                                placeholder=" ECHO " formControlName="ECHO6" cols="10"></textarea>
                                                                <small *ngIf="ECHO6.errors?.required"
                                                                    class="text-danger">ECHO is
                                                                    required</small>
    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">BP</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <input type="text" id="inputStandard" name="bp"
                                                                    class="form-control" placeholder="">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">PR</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <input type="text" id="inputStandard" name="pr"
                                                                    class="form-control" placeholder="">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>




                                                <div class="ptop30">

                                                    <div class="form-group">
                                                        <label for="8-14" class="pn"> ECHO </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <textarea id="inputStandard" name="cr_echo"
                                                                    class="form-control" placeholder=""
                                                                    cols="10"></textarea>


                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>





                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">BGH</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <textarea id="inputStandard" name="bgh"
                                                                    class="form-control" placeholder=""
                                                                    cols="10"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">NOTES</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <textarea id="inputStandard" name="notes"
                                                                    class="form-control" placeholder=""
                                                                    cols="10"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">INVESTIGATIONS</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <textarea id="inputStandard" name="investigation"
                                                                    class="form-control" placeholder=""
                                                                    cols="10"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">FOLLOW UP</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <input type="text" id="inputStandard" name="followup"
                                                                    class="form-control" placeholder="">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">

                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">PRESCRIPTION</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <textarea id="inputStandard" name="cpresc"
                                                                    class="form-control" placeholder=""
                                                                    cols="10"></textarea>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">BP</h4>
                                                   </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <input
                                                                    [class.is-invalid]="BP6.invalid && (BP6.dirty || BP6.touched)"
                                                                    class="form-control" id="BP6" type="text" name="BP6"
                                                                    placeholder=" BP " formControlName="BP6">
                                                                    <small *ngIf="error && BP6.errors?.required"
                                                                        class="text-danger">BP is
                                                                        required</small>
    
    
                                                              
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">PR</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <input
                                                                    [class.is-invalid]="PR6.invalid && (PR6.dirty || PR6.touched)"
                                                                    class="form-control" id="PR6" type="text" name="PR6"
                                                                    placeholder=" PR " formControlName="PR6">
                                                                    <small *ngIf="error && PR6.errors?.required"
                                                                        class="text-danger">PR is
                                                                        required</small>
    
                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">BGH</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <textarea
                                                                    [class.is-invalid]="BGH6.invalid && (BGH6.dirty || BGH6.touched)"
                                                                    class="form-control" id="BGH6" type="text" name="BGH6"
                                                                    placeholder=" BGH " formControlName="BGH6"
                                                                    cols="10"></textarea>
                                                                    <small *ngIf="error && BGH6.errors?.required"
                                                                        class="text-danger">BGH is
                                                                        required</small>
    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">NOTES</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <textarea
                                                                    [class.is-invalid]="Notes6.invalid && (Notes6.dirty || Notes6.touched)"
                                                                    class="form-control" id="Notes6" type="text" name="Notes6"
                                                                    placeholder=" Notes6 " formControlName="Notes6"
                                                                    cols="10"></textarea>
                                                                    <small *ngIf="error && Notes6.errors?.required"
                                                                        class="text-danger">Notes is
                                                                        required</small>
    
    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">INVESTIGATIONS</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <textarea
                                                                    [class.is-invalid]="Investigations6.invalid && (Investigations6.dirty || Investigations6.touched)"
                                                                    class="form-control" id="Investigations6" type="text"
                                                                    name="Investigations6" placeholder=" Investigations "
                                                                    formControlName="Investigations6" cols="10"></textarea>
                                                                
                                                                    <small *ngIf="error && Investigations6.errors?.required"
                                                                        class="text-danger">Investigations is
                                                                        required</small>
    
    
    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">Diagnosis</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
    
                                                                <textarea
                                                                    [class.is-invalid]="Diagnosis6.invalid && (Diagnosis6.dirty || Diagnosis6.touched)"
                                                                    class="form-control" id="Diagnosis6" type="text"
                                                                    name="Diagnosis6" placeholder=" Diagnosis "
                                                                    formControlName="Diagnosis6" cols="10"></textarea>
                                                               
                                                                    <small *ngIf="error && Diagnosis6.errors?.required"
                                                                        class="text-danger">Diagnosis is
                                                                        required</small>
    
    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">ADVICED Rx</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <!-- <textarea id="inputStandard" name="prescription" class="form-control" placeholder="" cols="10"></textarea> -->
    
                                                                <textarea
                                                                    [class.is-invalid]="Prescription6.invalid && (Prescription6.dirty || Prescription6.touched)"
                                                                    class="form-control" id="Prescription6" type="text"
                                                                    name="Prescription6" placeholder=" Prescription "
                                                                    formControlName="Prescription6" cols="10"></textarea>
                                                                    <small *ngIf="error && Prescription6.errors?.required"
                                                                        class="text-danger">Prescription is
                                                                        required</small>
    
    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">DIET and Any Advices</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
    
                                                                <textarea
                                                                    [class.is-invalid]="Diet6.invalid && (Diet6.dirty || Diet6.touched)"
                                                                    class="form-control" id="Diet6" type="text" name="Diet6"
                                                                    placeholder=" Diet6 " formControlName="Diet6"
                                                                    cols="10"></textarea>
                                                                    <small *ngIf="Diet6.errors?.required"
                                                                        class="text-danger">Diet is
                                                                        required</small>
    
    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">FOLLOW UP</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <!-- <input type="text" id="inputStandard" name="followup" class="form-control" placeholder=""> -->
                                                                <input
                                                                    [class.is-invalid]="FollowUp6.invalid && (FollowUp6.dirty || FollowUp6.touched)"
                                                                    class="form-control" id="FollowUp6" type="text"
                                                                    name="FollowUp6" placeholder=" FollowUp "
                                                                    formControlName="FollowUp6">
                                                                    <small *ngIf="errors && FollowUp6.errors?.required"
                                                                        class="text-danger">FollowUp is
                                                                        required</small>
    
    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ptop30">
                                                    <div class="form-group">
                                                        <label for="inputStandard" class="control-label top-pad-0">
                                                            <h4 class="fs14 ptop8">FOLLOW UP Date</h4>
                                                        </label>
                                                        <div class="col-lg-8 col-md-6">
                                                            <div class="bs-component">
                                                                <div class="input-group date">
                                                                    <input type='text' class="form-control"
                                                                        placeholder="dd-mm-yyyy"
                                                                        formControlName="FollowUpDate6" id="FollowUpDate6"
                                                                        name="FollowUpDate6" #dp="bsDatepicker" bsDatepicker
                                                                        [bsConfig]="datePickerConfig" placement="right">
                                                                    <span class="input-group-addon">
                                                                        <i class="glyphicon glyphicon-calendar"
                                                                            (click)="dp.toggle()"></i>
                                                                    </span>
    
                                                                </div>
    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="text-center col-md-10">
                                                    <!-- <button type="submit"
                                                        class="btn btn-default upper submitBtn">Submit</button> -->
                                                        <button type="submit" class="btn btn-default upper submitBtn"><a
                                                            [routerLink]=""
                                                            (click)="onSubmitCRHD(CRHDappointmentForm)">Submit</a></button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>


                                <!-- #tab7 chrd end -->

                                <!-- #tab8 HLP WITH NO CAD start -->
                                <h3 class="tab_drawer_heading" rel="tab8">HLP WITH NO CAD</h3>
                                <div id="tab8" class="tab_content" style="display: block;" *ngIf="hlpvalue">
                                    <!-- <form action="http://drgokulreddy.in/Home/insert_precord/doc_hlp" method="post"
                                        onsubmit="return chf()"> -->
                                        
                                        <form autocomplete="off" [formGroup]="HLP_WITH_NO_CADappointmentForm">


                                        <input type="hidden" name="pid" value="4169"> <input type="hidden" name="app_id"
                                            value="10111">
                                        <h2 class="ttitle" style="margin-top:35px;"><span><i></i></span> HLP WITH NO CAD
                                        </h2>
                                        <div class="row">
                                            <div class="col-md-2">
                                                <h5 class="blue-color"> RISK FACTORS </h5>
                                            </div>
                                            <div class="col-md-10">
                                                <div class="pull-left pr10">
                                                    <input id="9-1" class="checkbox-custom yellow" name="risk_factor[]" [checked]=htnchecked7 (change)="onChangeHLP_WITH_NO_CADRisk('HTN', $event.target.checked)"
                                                    formArrayName="RiskFactors7"
                                                        value="HTN" type="checkbox">
                                                    <label for="9-1" class="checkbox-custom-label yellow"> HTN </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="9-2" class="checkbox-custom yellow" name="risk_factor[]" [checked]=DMchecked7 (change)="onChangeHLP_WITH_NO_CADRisk('DM', $event.target.checked)"
                                                    formArrayName="RiskFactors7"
                                                        value="DM" type="checkbox">
                                                    <label for="9-2" class="checkbox-custom-label yellow"> DM </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="9-3" class="checkbox-custom yellow" name="risk_factor[]" [checked]=HLPchecked7 (change)="onChangeHLP_WITH_NO_CADRisk('HLP', $event.target.checked)"
                                                    formArrayName="RiskFactors7"
                                                        value="HLP" type="checkbox">
                                                    <label for="9-3" class="checkbox-custom-label yellow"> HLP </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="9-4" class="checkbox-custom yellow" name="risk_factor[]" [checked]=SMOKERchecked7 (change)="onChangeHLP_WITH_NO_CADRisk('SMOKER', $event.target.checked)"
                                                    formArrayName="RiskFactors7"
                                                        value="SMOKER" type="checkbox">
                                                    <label for="9-4" class="checkbox-custom-label yellow"> SMOKER
                                                    </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="9-5" class="checkbox-custom yellow" name="risk_factor[]" [checked]=FHCADchecked7 (change)="onChangeHLP_WITH_NO_CADRisk('FHCAD', $event.target.checked)"
                                                    formArrayName="RiskFactors7"
                                                        value="FHCAD" type="checkbox">
                                                    <label for="9-5" class="checkbox-custom-label yellow"> FHCAD
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">

                                            <div class="clearfix"></div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">HLP SINCE</h4>
                                                    </label>
                                                    <div class="col-lg-3 col-md-6">
                                                        <div class="bs-component">
                                                            <!-- <input type="text" name="hsince" id="inputStandard"
                                                                class="form-control" placeholder=""> -->
                                                                <input
                                                                [class.is-invalid]="HLP_SINCE.invalid && (HLP_SINCE.dirty || HLP_SINCE.touched)"
                                                                class="form-control" id="HLP_SINCE" type="text"
                                                                name="HLP_SINCE" placeholder=" HLP_SINCE "
                                                                formControlName="HLP_SINCE">
                                                                <small *ngIf="errors && HLP_SINCE.errors?.required"
                                                                    class="text-danger">HLP_SINCE is
                                                                    required</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <div class="pull-left pr10">

                                                    <label for="8-6" class="checkbox-custom-label yellow"> TOTAL CH
                                                    </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <div class="col-lg-12 col-md-6">
                                                        <div class="bs-component">
                                                            <!-- <input type="text" name="h1" id="inputStandard"
                                                                class="form-control" placeholder=""> -->
                                                                <input
                                                                [class.is-invalid]="TOTAL_CH.invalid && (TOTAL_CH.dirty || TOTAL_CH.touched)"
                                                                class="form-control" id="TOTAL_CH" type="text"
                                                                name="TOTAL_CH" placeholder=" TOTAL_CH "
                                                                formControlName="TOTAL_CH">
                                                                <small *ngIf="errors && TOTAL_CH.errors?.required"
                                                                    class="text-danger">TOTAL_CH is
                                                                    required</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>
                                            </div>
                                            <div>
                                                <div class="pull-left pr10">

                                                    <label for="9-6" class="checkbox-custom-label yellow"> LDL-C
                                                    </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <div class="col-lg-12 col-md-6">
                                                        <div class="bs-component">
                                                            <!-- <input type="text" name="h2" id="inputStandard"
                                                                class="form-control" placeholder=""> -->
                                                                <input
                                                                [class.is-invalid]="LDL_C.invalid && (LDL_C.dirty || LDL_C.touched)"
                                                                class="form-control" id="LDL_C" type="text"
                                                                name="LDL_C" placeholder=" LDL_C "
                                                                formControlName="LDL_C">
                                                                <small *ngIf="errors && LDL_C.errors?.required"
                                                                    class="text-danger">LDL_C is
                                                                    required</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>
                                            </div>
                                            <div>
                                                <div class="pull-left pr10">

                                                    <label for="8-8" class="checkbox-custom-label yellow"> TG </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <div class="col-lg-12 col-md-6">
                                                        <div class="bs-component">
                                                            <!-- <input type="text" name="h3" id="inputStandard"
                                                                class="form-control" placeholder=""> -->
                                                                <input
                                                                [class.is-invalid]="TG.invalid && (TG.dirty || TG.touched)"
                                                                class="form-control" id="TG" type="text"
                                                                name="TG" placeholder=" TG "
                                                                formControlName="TG">
                                                                <small *ngIf="errors && TG.errors?.required"
                                                                    class="text-danger">TG is
                                                                    required</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>
                                            </div>
                                            <div>
                                                <div class="pull-left pr10">

                                                    <label for="9-7" class="checkbox-custom-label yellow"> HDL-C
                                                    </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <div class="col-lg-12 col-md-6">
                                                        <div class="bs-component">
                                                            <!-- <input type="text" name="h4" id="inputStandard"
                                                                class="form-control" placeholder=""> -->
                                                                <input
                                                                [class.is-invalid]="HDL_C.invalid && (HDL_C.dirty || HDL_C.touched)"
                                                                class="form-control" id="HDL_C" type="text"
                                                                name="HDL_C" placeholder=" HDL_C "
                                                                formControlName="HDL_C">
                                                                <small *ngIf="errors && HDL_C.errors?.required"
                                                                    class="text-danger">HDL_C is
                                                                    required</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>
                                            </div>
                                            <div>
                                                <div class="pull-left pr10">

                                                    <label for="9-8" class="checkbox-custom-label yellow"> LP(A)
                                                    </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <div class="col-lg-12 col-md-6">
                                                        <div class="bs-component">
                                                            <!-- <input type="text" name="h5" id="inputStandard"
                                                                class="form-control" placeholder=""> -->
                                                                <input
                                                                [class.is-invalid]="LPA.invalid && (LPA.dirty || LPA.touched)"
                                                                class="form-control" id="LPA" type="text"
                                                                name="LPA" placeholder=" LPA "
                                                                formControlName="LPA">
                                                                <small *ngIf="errors && LPA.errors?.required"
                                                                    class="text-danger">LPA is
                                                                    required</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>
                                            </div>
                                            <div>
                                                <div class="pull-left pr10">

                                                    <label for="9-9" class="checkbox-custom-label yellow"> SUBFRACTION
                                                        OF LDL </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <div class="col-lg-12 col-md-6">
                                                        <div class="bs-component">
                                                            <!-- <input type="text" name="h6" id="inputStandard"
                                                                class="form-control" placeholder=""> -->
                                                                <input
                                                                [class.is-invalid]="SUBFRACTION_OF_LDL.invalid && (SUBFRACTION_OF_LDL.dirty || SUBFRACTION_OF_LDL.touched)"
                                                                class="form-control" id="SUBFRACTION_OF_LDL" type="text"
                                                                name="SUBFRACTION_OF_LDL" placeholder=" SUBFRACTION_OF_LDL "
                                                                formControlName="SUBFRACTION_OF_LDL">
                                                                <small *ngIf="errors && SUBFRACTION_OF_LDL.errors?.required"
                                                                    class="text-danger">SUBFRACTION_OF_LDL is
                                                                    required</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>
                                            </div>
                                            <div>
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">ANY EXT SIGNS</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <!-- <input type="text" name="h7" id="inputStandard"
                                                                class="form-control" placeholder=""> -->
                                                                <input
                                                                [class.is-invalid]="ANY_EXT_SIGNS.invalid && (ANY_EXT_SIGNS.dirty || ANY_EXT_SIGNS.touched)"
                                                                class="form-control" id="ANY_EXT_SIGNS" type="text"
                                                                name="ANY_EXT_SIGNS" placeholder=" ANY_EXT_SIGNS "
                                                                formControlName="ANY_EXT_SIGNS">
                                                                <small *ngIf="errors && ANY_EXT_SIGNS.errors?.required"
                                                                    class="text-danger">ANY_EXT_SIGNS is
                                                                    required</small>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="clearfix"></div>
                                            </div>

                                           
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">BP</h4>
                                               </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <input
                                                                [class.is-invalid]="BP7.invalid && (BP7.dirty || BP7.touched)"
                                                                class="form-control" id="BP7" type="text" name="BP7"
                                                                placeholder=" BP " formControlName="BP7">
                                                                <small *ngIf="error && BP7.errors?.required"
                                                                    class="text-danger">BP is
                                                                    required</small>


                                                          
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">PR</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <input
                                                                [class.is-invalid]="PR7.invalid && (PR7.dirty || PR7.touched)"
                                                                class="form-control" id="PR7" type="text" name="PR7"
                                                                placeholder=" PR " formControlName="PR7">
                                                                <small *ngIf="error && PR7.errors?.required"
                                                                    class="text-danger">PR is
                                                                    required</small>

                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">BGH</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <textarea
                                                                [class.is-invalid]="BGH7.invalid && (BGH7.dirty || BGH7.touched)"
                                                                class="form-control" id="BGH7" type="text" name="BGH7"
                                                                placeholder=" BGH " formControlName="BGH7"
                                                                cols="10"></textarea>
                                                                <small *ngIf="error && BGH7.errors?.required"
                                                                    class="text-danger">BGH is
                                                                    required</small>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">NOTES</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <textarea
                                                                [class.is-invalid]="Notes7.invalid && (Notes7.dirty || Notes7.touched)"
                                                                class="form-control" id="Notes7" type="text" name="Notes7"
                                                                placeholder=" Notes7 " formControlName="Notes7"
                                                                cols="10"></textarea>
                                                                <small *ngIf="error && Notes7.errors?.required"
                                                                    class="text-danger">Notes is
                                                                    required</small>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">INVESTIGATIONS</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <textarea
                                                                [class.is-invalid]="Investigations7.invalid && (Investigations7.dirty || Investigations7.touched)"
                                                                class="form-control" id="Investigations7" type="text"
                                                                name="Investigations7" placeholder=" Investigations "
                                                                formControlName="Investigations7" cols="10"></textarea>
                                                            
                                                                <small *ngIf="error && Investigations7.errors?.required"
                                                                    class="text-danger">Investigations is
                                                                    required</small>



                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">Diagnosis</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">

                                                            <textarea
                                                                [class.is-invalid]="Diagnosis7.invalid && (Diagnosis7.dirty || Diagnosis7.touched)"
                                                                class="form-control" id="Diagnosis7" type="text"
                                                                name="Diagnosis7" placeholder=" Diagnosis "
                                                                formControlName="Diagnosis7" cols="10"></textarea>
                                                           
                                                                <small *ngIf="error && Diagnosis7.errors?.required"
                                                                    class="text-danger">Diagnosis is
                                                                    required</small>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">ADVICED Rx</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <!-- <textarea id="inputStandard" name="prescription" class="form-control" placeholder="" cols="10"></textarea> -->

                                                            <textarea
                                                                [class.is-invalid]="Prescription7.invalid && (Prescription7.dirty || Prescription7.touched)"
                                                                class="form-control" id="Prescription7" type="text"
                                                                name="Prescription7" placeholder=" Prescription "
                                                                formControlName="Prescription7" cols="10"></textarea>
                                                                <small *ngIf="error && Prescription7.errors?.required"
                                                                    class="text-danger">Prescription is
                                                                    required</small>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">DIET and Any Advices</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">

                                                            <textarea
                                                                [class.is-invalid]="Diet7.invalid && (Diet7.dirty || Diet7.touched)"
                                                                class="form-control" id="Diet7" type="text" name="Diet7"
                                                                placeholder=" Diet7 " formControlName="Diet7"
                                                                cols="10"></textarea>
                                                                <small *ngIf="Diet7.errors?.required"
                                                                    class="text-danger">Diet is
                                                                    required</small>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">FOLLOW UP</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <!-- <input type="text" id="inputStandard" name="followup" class="form-control" placeholder=""> -->
                                                            <input
                                                                [class.is-invalid]="FollowUp7.invalid && (FollowUp7.dirty || FollowUp7.touched)"
                                                                class="form-control" id="FollowUp7" type="text"
                                                                name="FollowUp7" placeholder=" FollowUp "
                                                                formControlName="FollowUp7">
                                                                <small *ngIf="errors && FollowUp7.errors?.required"
                                                                    class="text-danger">FollowUp is
                                                                    required</small>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">FOLLOW UP Date</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <div class="input-group date">
                                                                <input type='text' class="form-control"
                                                                    placeholder="dd-mm-yyyy"
                                                                    formControlName="FollowUpDate7" id="FollowUpDate7"
                                                                    name="FollowUpDate7" #dp="bsDatepicker" bsDatepicker
                                                                    [bsConfig]="datePickerConfig" placement="right">
                                                                <span class="input-group-addon">
                                                                    <i class="glyphicon glyphicon-calendar"
                                                                        (click)="dp.toggle()"></i>
                                                                </span>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-center col-md-10">
                                                <!-- <button type="submit"
                                                    class="btn btn-default upper submitBtn">Submit</button> -->

                                                    <button type="submit" class="btn btn-default upper submitBtn"><a
                                                        [routerLink]=""
                                                        (click)="onSubmitHLP_WITH_NO_CAD(HLP_WITH_NO_CADappointmentForm)">Submit</a></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>


                                <!-- #tab8 HLP WITH NO CAD end -->

                                <!-- #tab9 CHEST PAIN start-->
                                <h3 class="tab_drawer_heading" rel="tab9">CHEST PAIN</h3>
                                <div id="tab9" class="tab_content" style="display: block;" *ngIf="cpvalue">
                                  
                                        <form autocomplete="off" [formGroup]="Chest_PainappointmentForm">


                                        <input type="hidden" name="pid" value="4169"> <input type="hidden" name="app_id"
                                            value="10111">
                                        <h2 class="ttitle" style="margin-top:35px;"><span><i></i></span> CHEST PAIN
                                        </h2>
                                        <div class="row">
                                            <div class="col-md-2">
                                                <h5 class="blue-color"> RISK FACTORS </h5>
                                            </div>
                                            <div class="col-md-10">
                                                <div class="pull-left pr10">
                                                    <input id="10-1" class="checkbox-custom yellow" name="risk_factor[]" [checked]=htnchecked8 (change)="onChangeChestpainRisk('HTN', $event.target.checked)"
                                                    formArrayName="RiskFactors8"
                                                        value="HTN" type="checkbox">
                                                    <label for="10-1" class="checkbox-custom-label yellow"> HTN </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="10-2" class="checkbox-custom yellow" name="risk_factor[]" [checked]=DMchecked8 (change)="onChangeChestpainRisk('DM', $event.target.checked)"
                                                    formArrayName="RiskFactors8"
                                                        value="DM" type="checkbox">
                                                    <label for="10-2" class="checkbox-custom-label yellow"> DM </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="10-3" class="checkbox-custom yellow" name="risk_factor[]" [checked]=HLPchecked8 (change)="onChangeChestpainRisk('HLP', $event.target.checked)"
                                                    formArrayName="RiskFactors8"
                                                        value="HLP" type="checkbox">
                                                    <label for="10-3" class="checkbox-custom-label yellow"> HLP </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="10-4" class="checkbox-custom yellow" name="risk_factor[]" [checked]=SMOKERchecked8 (change)="onChangeChestpainRisk('SMOKER', $event.target.checked)"
                                                    formArrayName="RiskFactors8"
                                                        value="SMOKER" type="checkbox">
                                                    <label for="10-4" class="checkbox-custom-label yellow"> SMOKER
                                                    </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="10-5" class="checkbox-custom yellow" name="risk_factor[]" [checked]=FHCADchecked8 (change)="onChangeChestpainRisk('FHCAD', $event.target.checked)"
                                                    formArrayName="RiskFactors8"
                                                        value="FHCAD" type="checkbox">
                                                    <label for="10-5" class="checkbox-custom-label yellow"> FHCAD
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="col-md-12">

                                            <div>

                                                <div class="pull-left pt10">
                                                    <b> CHEST PAIN : </b>
                                                    <input id="10-6" class="checkbox-custom " name="cpain[]" [checked]=chestpain1checked (change)="onChangeChestPain('TYPICAL', $event.target.checked)"
                                                    formArrayName="CHEST_PAIN"
                                                        value="TYPICAL" type="checkbox">
                                                    <label for="10-6" class="checkbox-custom-label pn ml10"> TYPICAL
                                                    </label>
                                                    <input id="10-7" class="checkbox-custom " name="cpain[]" [checked]=chestpain2checked (change)="onChangeChestPain('ATYPICAL', $event.target.checked)"
                                                    formArrayName="CHEST_PAIN"
                                                        value="ATYPICAL" type="checkbox">
                                                    <label for="10-7" class="checkbox-custom-label pn ml10"> ATYPICAL
                                                    </label>
                                                    <input id="10-8" class="checkbox-custom " name="cpain[]" [checked]=chestpain3checked (change)="onChangeChestPain('NOT S/O CAD', $event.target.checked)"
                                                    formArrayName="CHEST_PAIN"
                                                        value="NOT S/O CAD" type="checkbox">
                                                    <label for="10-8" class="checkbox-custom-label pn ml10"> NOT S/O CAD
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="clearfix mb20"></div>

                                            <div>
                                                <div class="pull-left pr14">
                                                    <b>ECG : </b>
                                                    <input id="10-9" class="checkbox-custom " name="cecg[]" [checked]=ECGChest1checked (change)="onChangeECGChestPain('ST/T WAVE CHANGES', $event.target.checked)"
                                                    formArrayName="ECG"
                                                        value="ST/T WAVE CHANGES" type="checkbox">
                                                    <label for="10-9" class="checkbox-custom-label ml10 pn">ST/T WAVE
                                                        CHANGES</label>
                                                    <input id="10-10" class="checkbox-custom " name="cecg[]" [checked]=ECGChest2checked (change)="onChangeECGChestPain('NON SPECIFIC ST/T WAVE CHANGES', $event.target.checked)"
                                                    formArrayName="ECG"
                                                        value="NON SPECIFIC ST/T WAVE CHANGES" type="checkbox">
                                                    <label for="10-10" class="checkbox-custom-label ml10 pn"> NON
                                                        SPECIFIC ST/T WAVE CHANGES</label>
                                                    <input id="10-11" class="checkbox-custom " name="cecg[]" [checked]=ECGChest3checked (change)="onChangeECGChestPain('NORMAL ECG', $event.target.checked)"
                                                    formArrayName="ECG"
                                                        value="NORMAL ECG" type="checkbox">
                                                    <label for="10-11" class="checkbox-custom-label ml10 pn"> NORMAL
                                                        ECG</label>
                                                </div>

                                            </div>
                                            <div class="clearfix mb20"></div>

                                            <div>


                                            </div>
                                            <div class="clearfix mb20"></div>

                                            <div>
                                                <div class="pull-left pr10">
                                                    <b>STRESS TEST</b>
                                                    <input id="10-13" class="checkbox-custom " name="cstress[]" [checked]=stresstestchest1checked (change)="onChangeStressTESTChestPain('POSITIVE FOR INDUCIBLE ISCHEMIA', $event.target.checked)"
                                                    formArrayName="STRESS_TEST"
                                                        value="POSITIVE FOR INDUCIBLE ISCHEMIA" type="checkbox">
                                                    <label for="10-13" class="checkbox-custom-label ml10 pn">POSITIVE
                                                        FOR INDUCIBLE ISCHEMIA</label>
                                                    <input id="10-14" class="checkbox-custom " name="cstress[]" [checked]=stresstestchest2checked (change)="onChangeStressTESTChestPain('NEGATIVE FOR INDUCIBLE ISCHEMIA', $event.target.checked)"
                                                    formArrayName="STRESS_TEST"
                                                        value="NEGATIVE FOR INDUCIBLE ISCHEMIA" type="checkbox">
                                                    <label for="10-14" class="checkbox-custom-label ml10 pn"> NEGATIVE
                                                        FOR INDUCIBLE ISCHEMIA </label>
                                                </div>

                                            </div>
                                            <div class="clearfix mb20"></div>

                                            <div>
                                                <div class="pull-left pr14">
                                                    <b>CAG</b>
                                                    <input id="10-15" class="checkbox-custom " name="ccag[]" [checked]=cagchest1checked (change)="onChangeCAGChestPain('NORMAL', $event.target.checked)"
                                                    formArrayName="CAG8"
                                                        value="NORMAL" type="checkbox">
                                                    <label for="10-15"
                                                        class="checkbox-custom-label ml10 pn">NORMAL</label>
                                                    <input id="10-16" class="checkbox-custom " name="ccag[]" [checked]=cagchest2checked (change)="onChangeCAGChestPain('SVD', $event.target.checked)"
                                                    formArrayName="CAG8"value="SVD"
                                                        type="checkbox">
                                                    <label for="10-16" class="checkbox-custom-label ml10 pn">SVD</label>
                                                    <input id="10-17" class="checkbox-custom " name="ccag[]" [checked]=cagchest3checked (change)="onChangeCAGChestPain('2 VD', $event.target.checked)"
                                                    formArrayName="CAG8"
                                                        value="2 VD" type="checkbox">
                                                    <label for="10-17" class="checkbox-custom-label ml10 pn">2
                                                        VD</label>
                                                    <input id="10-18" class="checkbox-custom " name="ccag[]" [checked]=cagchest4checked (change)="onChangeCAGChestPain('3 VD', $event.target.checked)"
                                                    formArrayName="CAG8"
                                                        value="3 VD" type="checkbox">
                                                    <label for="10-18" class="checkbox-custom-label ml10 pn">3
                                                        VD</label>
                                                    <input id="10-19" class="checkbox-custom " name="ccag[]" [checked]=cagchest5checked (change)="onChangeCAGChestPain('SLOW FLOW', $event.target.checked)"
                                                    formArrayName="CAG8"
                                                        value="SLOW FLOW" type="checkbox">
                                                    <label for="10-19" class="checkbox-custom-label ml10 pn">SLOW
                                                        FLOW</label>
                                                    <div class="bs-component" style="display:inline-block;">
                                                        <input type="text" name="ccag_year" id="inputStandard"
                                                            class="form-control" placeholder="">
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="clearfix"></div>
                                           
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">BP</h4>
                                               </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <input
                                                                [class.is-invalid]="BP8.invalid && (BP8.dirty || BP8.touched)"
                                                                class="form-control" id="BP8" type="text" name="BP8"
                                                                placeholder=" BP " formControlName="BP8">
                                                                <small *ngIf="error && BP8.errors?.required"
                                                                    class="text-danger">BP is
                                                                    required</small>


                                                          
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">PR</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <input
                                                                [class.is-invalid]="PR8.invalid && (PR8.dirty || PR8.touched)"
                                                                class="form-control" id="PR8" type="text" name="PR8"
                                                                placeholder=" PR " formControlName="PR8">
                                                                <small *ngIf="error && PR8.errors?.required"
                                                                    class="text-danger">PR is
                                                                    required</small>

                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">

                                                <div class="form-group">
                                                    <b>ECHO :</b>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <!-- <textarea id="inputStandard" name="cecho"
                                                                class="form-control" placeholder=""
                                                                cols="10"></textarea> -->


                                                                <textarea
                                                                [class.is-invalid]="ECHO8.invalid && (ECHO8.dirty || ECHO8.touched)"
                                                                class="form-control" id="ECHO8" type="text" name="ECHO8"
                                                                placeholder=" ECHO " formControlName="ECHO8" cols="10"></textarea>
                                                                <small *ngIf="error && ECHO8.errors?.required"
                                                                    class="text-danger">PR is
                                                                    required</small>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">BGH</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <textarea
                                                                [class.is-invalid]="BGH8.invalid && (BGH8.dirty || BGH8.touched)"
                                                                class="form-control" id="BGH8" type="text" name="BGH8"
                                                                placeholder=" BGH " formControlName="BGH8"
                                                                cols="10"></textarea>
                                                                <small *ngIf="error && BGH8.errors?.required"
                                                                    class="text-danger">BGH is
                                                                    required</small>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">NOTES</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <textarea
                                                                [class.is-invalid]="Notes8.invalid && (Notes8.dirty || Notes8.touched)"
                                                                class="form-control" id="Notes8" type="text" name="Notes8"
                                                                placeholder=" Notes " formControlName="Notes8"
                                                                cols="10"></textarea>
                                                                <small *ngIf="error && Notes8.errors?.required"
                                                                    class="text-danger">Notes is
                                                                    required</small>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">INVESTIGATIONS</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <textarea
                                                                [class.is-invalid]="Investigations8.invalid && (Investigations8.dirty || Investigations8.touched)"
                                                                class="form-control" id="Investigations8" type="text"
                                                                name="Investigations8" placeholder=" Investigations "
                                                                formControlName="Investigations8" cols="10"></textarea>
                                                            
                                                                <small *ngIf="error && Investigations8.errors?.required"
                                                                    class="text-danger">Investigations is
                                                                    required</small>



                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">Diagnosis</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">

                                                            <textarea
                                                                [class.is-invalid]="Diagnosis8.invalid && (Diagnosis8.dirty || Diagnosis8.touched)"
                                                                class="form-control" id="Diagnosis8" type="text"
                                                                name="Diagnosis8" placeholder=" Diagnosis "
                                                                formControlName="Diagnosis8" cols="10"></textarea>
                                                           
                                                                <small *ngIf="error && Diagnosis8.errors?.required"
                                                                    class="text-danger">Diagnosis is
                                                                    required</small>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">ADVICED Rx</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">

                                                            <textarea
                                                                [class.is-invalid]="Prescription8.invalid && (Prescription8.dirty || Prescription8.touched)"
                                                                class="form-control" id="Prescription8" type="text"
                                                                name="Prescription8" placeholder=" Prescription "
                                                                formControlName="Prescription8" cols="10"></textarea>
                                                                <small *ngIf="error && Prescription8.errors?.required"
                                                                    class="text-danger">Prescription is
                                                                    required</small>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">DIET and Any Advices</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">

                                                            <textarea
                                                                [class.is-invalid]="Diet8.invalid && (Diet8.dirty || Diet8.touched)"
                                                                class="form-control" id="Diet8" type="text" name="Diet8"
                                                                placeholder=" Diet " formControlName="Diet8"
                                                                cols="10"></textarea>
                                                                <small *ngIf="Diet8.errors?.required"
                                                                    class="text-danger">Diet is
                                                                    required</small>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">FOLLOW UP</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <input
                                                                [class.is-invalid]="FollowUp8.invalid && (FollowUp8.dirty || FollowUp8.touched)"
                                                                class="form-control" id="FollowUp8" type="text"
                                                                name="FollowUp8" placeholder=" FollowUp "
                                                                formControlName="FollowUp8">
                                                                <small *ngIf="errors && FollowUp8.errors?.required"
                                                                    class="text-danger">FollowUp is
                                                                    required</small>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">FOLLOW UP Date</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <div class="input-group date">
                                                                <input type='text' class="form-control"
                                                                    placeholder="dd-mm-yyyy"
                                                                    formControlName="FollowUpDate8" id="FollowUpDate8"
                                                                    name="FollowUpDate8" #dp="bsDatepicker" bsDatepicker
                                                                    [bsConfig]="datePickerConfig" placement="right">
                                                                <span class="input-group-addon">
                                                                    <i class="glyphicon glyphicon-calendar"
                                                                        (click)="dp.toggle()"></i>
                                                                </span>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-center col-md-10">
                                                <button type="submit" class="btn btn-default upper submitBtn"><a
                                                    [routerLink]=""
                                                    (click)="onSubmitChest_Pain(Chest_PainappointmentForm)">Submit</a></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <!-- #tab9 CHEST PAIN end-->


                                <!-- #tab10 OTHER start -->

                                <h3 class="tab_drawer_heading" rel="tab10">OTHER</h3>
                                <div id="tab10" class="tab_content" style="display: block;" *ngIf="othervalue">
                                  
                                        
                                        <form autocomplete="off" [formGroup]="OthersappointmentForm">

                                        <input type="hidden" name="pid" value="4169"> <input type="hidden" name="app_id"
                                            value="10111">
                                        <h2 class="ttitle" style="margin-top:35px;"><span><i></i></span> OTHER </h2>
                                        <div class="row">
                                            <div class="col-md-2">
                                                <h5 class="blue-color"> RISK FACTORS </h5>
                                            </div>
                                            <div class="col-md-10">
                                                <div class="pull-left pr10">
                                                    <input id="11-1" class="checkbox-custom yellow" name="risk_factor[]" [checked]=htnchecked9 (change)="onChangeOtherRisk('HTN', $event.target.checked)"
                                                    formArrayName="RiskFactors9"
                                                        value="HTN" type="checkbox">
                                                    <label for="11-1" class="checkbox-custom-label yellow"> HTN </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="11-2" class="checkbox-custom yellow" name="risk_factor[]" [checked]=DMchecked9 (change)="onChangeOtherRisk('DM', $event.target.checked)"
                                                    formArrayName="RiskFactors9"
                                                        value="DM" type="checkbox">
                                                    <label for="11-2" class="checkbox-custom-label yellow"> DM </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="11-3" class="checkbox-custom yellow" name="risk_factor[]" [checked]=HLPchecked9 (change)="onChangeOtherRisk('HLP', $event.target.checked)"
                                                    formArrayName="RiskFactors9"
                                                        value="HLP" type="checkbox">
                                                    <label for="11-3" class="checkbox-custom-label yellow"> HLP </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="11-4" class="checkbox-custom yellow" name="risk_factor[]" [checked]=SMOKERchecked9 (change)="onChangeOtherRisk('SMOKER', $event.target.checked)"
                                                    formArrayName="RiskFactors9"
                                                        value="SMOKER" type="checkbox">
                                                    <label for="11-4" class="checkbox-custom-label yellow"> SMOKER
                                                    </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="11-5" class="checkbox-custom yellow" name="risk_factor[]" [checked]=FHCADchecked9 (change)="onChangeOtherRisk('FHCAD', $event.target.checked)"
                                                    formArrayName="RiskFactors9"
                                                        value="FHCAD" type="checkbox">
                                                    <label for="11-5" class="checkbox-custom-label yellow"> FHCAD
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">

                                            <b> PRESENTING REASON : </b>
                                            <div>

                                                <div class="pull-left pr10">
                                                    <input id="11-6" class="checkbox-custom yellow" name="preason[]" [checked]=preasonchecked (change)="onChangeReasons('CHEST PAIN', $event.target.checked)"
                                                    formArrayName="PRESENTING_REASON"
                                                        value="CHEST PAIN" type="checkbox">
                                                    <label for="11-6" class="checkbox-custom-label yellow"> CHEST PAIN
                                                    </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="11-7" class="checkbox-custom yellow" name="preason[]" [checked]=preason1checked (change)="onChangeReasons('SOB', $event.target.checked)"
                                                    formArrayName="PRESENTING_REASON"
                                                        value="SOB" type="checkbox">
                                                    <label for="11-7" class="checkbox-custom-label yellow"> SOB </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="11-8" class="checkbox-custom yellow" name="preason[]" [checked]=preason2checked (change)="onChangeReasons('PRE-OP EVALUATION', $event.target.checked)"
                                                    formArrayName="PRESENTING_REASON"
                                                        value="PRE-OP EVALUATION" type="checkbox">
                                                    <label for="11-8" class="checkbox-custom-label yellow"> PRE-OP
                                                        EVALUATION </label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <div class="col-lg-12 col-md-6">
                                                        <div class="bs-component">
                                                            <input type="text" id="inputStandard" name="preason_text"
                                                                class="form-control" placeholder="">
                                                               
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="clearfix mb5"></div>
                                            <b>ECG</b>
                                            <div>
                                                <div class="pull-left pr14">
                                                    <input id="11-9" class="checkbox-custom yellow" name="pecg[]" [checked]=ecg1others (change)="onChangeotherECG('ST/T WAVE CHANGES', $event.target.checked)"
                                                    formArrayName="ECG9"
                                                        value="ST/T WAVE CHANGES" type="checkbox">
                                                    <label for="11-9" class="checkbox-custom-label yellow">ST/T WAVE
                                                        CHANGES</label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="11-10" class="checkbox-custom yellow" name="pecg[]" [checked]=ecg2others (change)="onChangeotherECG('NON SPECIFIC ST/T WAVE CHANGES', $event.target.checked)"
                                                    formArrayName="ECG9"
                                                        value="NON SPECIFIC ST/T WAVE CHANGES" type="checkbox">
                                                    <label for="11-10" class="checkbox-custom-label yellow"> NON
                                                        SPECIFIC ST/T WAVE CHANGES</label>
                                                </div>
                                                <div class="pull-left pr10">
                                                    <input id="11-11" class="checkbox-custom yellow" name="pecg[]" [checked]=ecg3others (change)="onChangeotherECG('NORMAL ECG', $event.target.checked)"
                                                    formArrayName="ECG9"
                                                        value="NORMAL ECG" type="checkbox">
                                                    <label for="11-11" class="checkbox-custom-label yellow"> NORMAL
                                                        ECG</label>
                                                </div>
                                            </div>
                                            <div class="clearfix mb5"></div>


                                        </div>
                                        <div class="clearfix mb5"></div>

                                        <div>

                                            <div class="pull-left pr10">
                                                <input id="11-12" class="checkbox-custom yellow" name="pstress[]" [checked]=stress1 (change)="onChangestress('POSITIVE FOR INDUCIBLE ISCHEMIA', $event.target.checked)"
                                                formArrayName="stress1"
                                                    value="POSITIVE FOR INDUCIBLE ISCHEMIA" type="checkbox">
                                                <label for="11-12" class="checkbox-custom-label yellow">POSITIVE FOR
                                                    INDUCIBLE ISCHEMIA</label>
                                            </div>
                                            <div class="pull-left pr10">
                                                <input id="11-13" class="checkbox-custom yellow" name="pstress[]" [checked]=stress2 (change)="onChangestress('NEGATIVE FOR INDUCIBLE ISCHEMIA', $event.target.checked)"
                                                formArrayName="stress1"
                                                    value="NEGATIVE FOR INDUCIBLE ISCHEMIA" type="checkbox">
                                                <label for="11-13" class="checkbox-custom-label yellow"> NEGATIVE FOR
                                                    INDUCIBLE ISCHEMIA </label>
                                            </div>
                                        </div>
                                        <div class="clearfix mb5"></div>
                                        <div class="ptop30">
                                            <div class="form-group">

                                                <label for="inputStandard" class="control-label top-pad-0 pr10">
                                                    <h4 class="fs14 ptop8">OTHER DATA</h4>
                                                </label>
                                                <div class="col-lg-8 col-md-6">
                                                    <div class="bs-component">
                                                        
                                                            <input
                                                            [class.is-invalid]="OTHER_DATA.invalid && (OTHER_DATA.dirty || OTHER_DATA.touched)"
                                                            class="form-control" id="OTHER_DATA" type="text"
                                                            name="OTHER_DATA" placeholder=" OTHER_DATA "
                                                            formControlName="OTHER_DATA">
                                                            <small *ngIf="errors && OTHER_DATA.errors?.required"
                                                                class="text-danger">OTHER_DATA is
                                                                required</small>
                                                    </div>
                                                </div>
                                            </div>
                                         
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">BP</h4>
                                               </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <input
                                                                [class.is-invalid]="BP9.invalid && (BP9.dirty || BP9.touched)"
                                                                class="form-control" id="BP9" type="text" name="BP9"
                                                                placeholder=" BP " formControlName="BP9">
                                                                <small *ngIf="error && BP9.errors?.required"
                                                                    class="text-danger">BP is
                                                                    required</small>


                                                          
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">PR</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <input
                                                                [class.is-invalid]="PR9.invalid && (PR9.dirty || PR9.touched)"
                                                                class="form-control" id="PR9" type="text" name="PR9"
                                                                placeholder=" PR " formControlName="PR9">
                                                                <small *ngIf="error && PR9.errors?.required"
                                                                    class="text-danger">PR is
                                                                    required</small>

                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">

                                                <div class="form-group">
                                                    <b>ECHO :</b>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <!-- <textarea id="inputStandard" name="cecho"
                                                                class="form-control" placeholder=""
                                                                cols="10"></textarea> -->


                                                                <textarea
                                                                [class.is-invalid]="ECHO9.invalid && (ECHO9.dirty || ECHO9.touched)"
                                                                class="form-control" id="ECHO9" type="text" name="ECHO9"
                                                                placeholder=" ECHO " formControlName="ECHO9" cols="10"></textarea>
                                                                <small *ngIf="error && ECHO9.errors?.required"
                                                                    class="text-danger">PR is
                                                                    required</small>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">BGH</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <textarea
                                                                [class.is-invalid]="BGH9.invalid && (BGH9.dirty || BGH9.touched)"
                                                                class="form-control" id="BGH9" type="text" name="BGH9"
                                                                placeholder=" BGH " formControlName="BGH9"
                                                                cols="10"></textarea>
                                                                <small *ngIf="error && BGH9.errors?.required"
                                                                    class="text-danger">BGH is
                                                                    required</small>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">NOTES</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <textarea
                                                                [class.is-invalid]="Notes9.invalid && (Notes9.dirty || Notes9.touched)"
                                                                class="form-control" id="Notes9" type="text" name="Notes9"
                                                                placeholder=" Notes " formControlName="Notes9"
                                                                cols="10"></textarea>
                                                                <small *ngIf="error && Notes9.errors?.required"
                                                                    class="text-danger">Notes is
                                                                    required</small>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">INVESTIGATIONS</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <textarea
                                                                [class.is-invalid]="Investigations9.invalid && (Investigations9.dirty || Investigations9.touched)"
                                                                class="form-control" id="Investigations9" type="text"
                                                                name="Investigations9" placeholder=" Investigations "
                                                                formControlName="Investigations9" cols="10"></textarea>
                                                            
                                                                <small *ngIf="error && Investigations9.errors?.required"
                                                                    class="text-danger">Investigations is
                                                                    required</small>



                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">Diagnosis</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">

                                                            <textarea
                                                                [class.is-invalid]="Diagnosis9.invalid && (Diagnosis9.dirty || Diagnosis9.touched)"
                                                                class="form-control" id="Diagnosis9" type="text"
                                                                name="Diagnosis9" placeholder=" Diagnosis "
                                                                formControlName="Diagnosis9" cols="10"></textarea>
                                                           
                                                                <small *ngIf="error && Diagnosis9.errors?.required"
                                                                    class="text-danger">Diagnosis is
                                                                    required</small>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">ADVICED Rx</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">

                                                            <textarea
                                                                [class.is-invalid]="Prescription9.invalid && (Prescription9.dirty || Prescription9.touched)"
                                                                class="form-control" id="Prescription9" type="text"
                                                                name="Prescription9" placeholder=" Prescription "
                                                                formControlName="Prescription9" cols="10"></textarea>
                                                                <small *ngIf="error && Prescription9.errors?.required"
                                                                    class="text-danger">Prescription is
                                                                    required</small>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">DIET and Any Advices</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">

                                                            <textarea
                                                                [class.is-invalid]="Diet9.invalid && (Diet9.dirty || Diet9.touched)"
                                                                class="form-control" id="Diet9" type="text" name="Diet9"
                                                                placeholder=" Diet " formControlName="Diet9"
                                                                cols="10"></textarea>
                                                                <small *ngIf="Diet9.errors?.required"
                                                                    class="text-danger">Diet is
                                                                    required</small>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">FOLLOW UP</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <input
                                                                [class.is-invalid]="FollowUp9.invalid && (FollowUp9.dirty || FollowUp9.touched)"
                                                                class="form-control" id="FollowUp9" type="text"
                                                                name="FollowUp9" placeholder=" FollowUp "
                                                                formControlName="FollowUp9">
                                                                <small *ngIf="errors && FollowUp9.errors?.required"
                                                                    class="text-danger">FollowUp is
                                                                    required</small>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ptop30">
                                                <div class="form-group">
                                                    <label for="inputStandard" class="control-label top-pad-0">
                                                        <h4 class="fs14 ptop8">FOLLOW UP Date</h4>
                                                    </label>
                                                    <div class="col-lg-8 col-md-6">
                                                        <div class="bs-component">
                                                            <div class="input-group date">
                                                                <input type='text' class="form-control"
                                                                    placeholder="dd-mm-yyyy"
                                                                    formControlName="FollowUpDate9" id="FollowUpDate9"
                                                                    name="FollowUpDate9" #dp="bsDatepicker" bsDatepicker
                                                                    [bsConfig]="datePickerConfig" placement="right">
                                                                <span class="input-group-addon">
                                                                    <i class="glyphicon glyphicon-calendar"
                                                                        (click)="dp.toggle()"></i>
                                                                </span>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="text-center col-md-10">
                                            
                                                <button type="submit" class="btn btn-default upper submitBtn"><a
                                                    [routerLink]=""
                                                    (click)="onSubmitOthers(OthersappointmentForm)">Submit</a></button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <!-- #tab10 OTHER end -->

                    </div>
                </div>
            </div>

            <div class="clearfix"></div>
        </div>

       
    </div>

</body>

</html>