import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-createbillings',
  templateUrl: './createbillings.component.html',
  styleUrls: ['./createbillings.component.css']
})
export class CreatebillingsComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private apicalls: ApicallsService, private router: ActivatedRoute, private route: Router, private httpClient: HttpClient) { }
  viewalldoctors;
  //Type1 = "Test";
  userDetails;
  BillingsTypes;
  BillingsTest = [];
  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data
    this.apicalls.viewAllDoctors()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {
            this.viewalldoctors = data;
            this.spinner.hide();
          }
          if (data.code == 'V002') {
            alert(data.data);
            this.spinner.hide();
          }
        } else {
          alert(data.data)
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ");
      });


    //getTypeMastersData
    this.apicalls.getTypeMastersData()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {
            this.spinner.hide();
          }
          this.BillingsTypes = data.data;
          if (data.code == 'S002') {
            alert(data.data);
            this.spinner.hide();
          }
        } else {
          alert(data.data)
          this.spinner.hide();
        }
      }, error => { alert(" Internal Server Error "); });



  }
  BillingType = '';
  IsDoctorRequired;
  onBillingType(value: string) {
    for (var j = 0; j < this.BillingsTypes.length; j++) {
      if (this.BillingsTypes[j].BillingID == value) {
        this.IsDoctorRequired = this.BillingsTypes[j].IsDoctorRequired
      }
    }
    this.apicalls.getTestMastersData()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {
            this.spinner.hide();
          }

          this.BillingsTest = [];
          for (var i = 0; i < data.data.length; i++) {
            if (data.data[i].BillingType == value) {
              this.BillingType = data.data[i];
              this.BillingsTest.push(data.data[i]);
            }
          }

          if (data.code == 'S002') {
            alert(data.data);
            this.spinner.hide();
          }
        } else {
          alert(data.data)
          this.spinner.hide();
        }
      }, error => { alert(" Internal Server Error "); });
  }



  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }
  doctorsdetails;
  onOptionsSelected(value: string) {
    this.apicalls.viewDoctorsById(value).subscribe(
      data => {
        this.spinner.show();
        if (data.type == true) {
          this.doctorsdetails = data
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
          }, 500);
          this.billingForm.patchValue({
            MedicID: this.doctorsdetails.data.MedicID,
            DoctorName: this.doctorsdetails.data.FirstName,
            Fee: this.doctorsdetails.data.Fee,
          });
        } else {
          alert(data.message);
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ")
      });

  }
  billingForm = new FormGroup({
    //TestName: new FormControl('', [Validators.required]),//Validators.required,
    Type: new FormControl('', [Validators.required]),
    Price: new FormControl('', [Validators.pattern('[0-9]*')]),
    DoctorID: new FormControl('',),
    BillingID: new FormControl('', [Validators.required]),
    BillingName: new FormControl('', [Validators.required]),
  });

  // get TestName() {
  //   return this.billingForm.get('TestName');
  // }
  get Price() {
    return this.billingForm.get('Price');
  }
  get DoctorID() {
    return this.billingForm.get('DoctorID');
  }
  get Type() {
    return this.billingForm.get('Type');
  }
  get BillingID() {
    return this.billingForm.get('BillingID');
  }

  error;

  onSubmit(billingForm) {


    // if (this.billingForm.invalid) {
    //   this.error = true;
    //   return;
    // }
    this.spinner.show();

    for (var i = 0; i < this.BillingsTest.length; i++) {
      if (this.BillingsTest[i].BillingID == this.billingForm.value.BillingID) {
        this.billingForm.value.BillingName = this.BillingsTest[i].BillingName;
      }
    }

    this.apicalls.createBilling(this.billingForm.value)
      .subscribe(data => {
        if (data.type == true) {
          if (data.code == 'S001') {
            this.spinner.hide();
            alert(data.data)
            this.route.navigate(["/dashboardmain/ViewAllbillings"]);
          }
          if (data.code == 'S002') {
            alert(data.data);
            this.spinner.hide();
          }
        } else {
          alert(data.data)
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ");
      });
  }

  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }
}
