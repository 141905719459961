import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl, FormArray, FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-updatehospitals',
  templateUrl: './updatehospitals.component.html',
  styleUrls: ['./updatehospitals.component.css']
})
export class UpdatehospitalsComponent implements OnInit {

  details;
  userDetails;
  constructor(private spinner: NgxSpinnerService, private httpclient: HttpClient, private formBuilder: FormBuilder, private apicalls: ApicallsService, private route: Router, private router: ActivatedRoute) { }

  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data

    this.router.paramMap.subscribe(params => {
      const HospitalID = params.get('id');
      if (HospitalID) {

        this.getHospitalId(HospitalID);
      }
    });

  }

  data: '';
  pathurl: string = 'GokulClinic';
  isSubmitted: boolean = true;
  //loggedIn:boolean=true;
  channelError: string;
  AddressObj = {};

  onSubmit(hospitalForm) {
    console.log("@@@@@@@hospital", this.hospitalForm.value);

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 5000);

    // console.log("DATATATATATATATATATA",this.hospitalForm.value.Address[0].City)
    // this.hospitalForm.value.Address[0].StreetAddress=this.hospitalForm.value.StreetAddress
    // this.hospitalForm.value.Address[0].Colony=this.hospitalForm.value.Colony;
    // this.hospitalForm.value.Address[0].Locality=this.hospitalForm.value.Locality;
    // this.hospitalForm.value.Address[0].PinCode=this.hospitalForm.value.PinCode;
    // this.hospitalForm.value.Address[0].City=this.hospitalForm.value.City;
    // this.hospitalForm.value.Address[0].State=this.hospitalForm.value.State;
    // this.hospitalForm.value.Address[0].Country=this.hospitalForm.value.Country;

    // this.hospitalForm.value.Address[0].Latitude=this.hospitalForm.value.Latitude;
    // this.hospitalForm.value.Address[0].Longitude=this.hospitalForm.value.Longitude;
    // this.hospitalForm.value.Address[0].LocationLink=this.hospitalForm.value.LocationLink;

    // console.log("DATATATATATATATATATA",this.hospitalForm.value.Address[0].City)

    this.AddressObj = {
      StreetAddress: this.hospitalForm.value.StreetAddress,
      Colony: this.hospitalForm.value.Colony,
      Locality: this.hospitalForm.value.Locality,
      PinCode: this.hospitalForm.value.PinCode,
      City: this.hospitalForm.value.City,
      State: this.hospitalForm.value.State,
      Country: this.hospitalForm.value.Country,
      Latitude: this.hospitalForm.value.Latitude,
      Longitude: this.hospitalForm.value.Longitude,
      LocationLink: this.hospitalForm.value.LocationLink
    };
    this.hospitalForm.value.Address = this.AddressObj;
    this.hospitalForm.value.ContactNumber = '';
    //  this.hospitalForm.value.SecondryContactNumber='';
    // if (this.hospitalForm.invalid) {
    //   alert("Please enter all fields")
    //   return;
    // }
    let result = this.apicalls.updateHospitals(this.hospitalForm.value)
      .subscribe(data => {
        this.spinner.show();
        //  this.data = data;
        if (data.type == true) {

          if (data.code == 'U001') {
            this.spinner.hide();
            alert(data.data)
            console.log("!!!!!@@@@@@@@@@@!!!1", data);
            this.route.navigate(["/dashboardmain/hospitals"]);
          }
          //    if (data.code == 'L005') {
          //     this.spinner.hide();
          //     alert(data.data)
          // // console.log("!!!!!@@@@@@@@@@@!!!1",data);        
          //    this.route.navigate(["/dashboardmain/hospitals"]);
          //   }
          if (data.code == 'S002') {
            this.spinner.hide();
            alert(data.data);
            this.isSubmitted = false;
            return false;
            //   console.log("!!!!!@@@@@@@@@@@!!!1",data);        
            // this.route.navigate(["/dashboardmain/channelpartners"]);
          }

        }
        else {
          // "code":"L005"
          console.log("!!!!!!!!!!!!!1", data.data);
          this.spinner.hide();
          this.isSubmitted = false;
          alert(data.data)
          this.channelError = data.data;

          return false;
          //  alert( data.data)

        }

        // }
      },
        error => { alert(" Internal Server Error "); }
      );

  }
  // "HospitalName": "XYZ",
  //                       "HospitalBranch": "jubliee hills",
  //                       "ContactNumber": "9885626638",
  //                       "SecondryContactNumber": "9885626678",
  //                       "Address" : {

  //   "StreetAddress":"abc",
  //   "Colony":"abc",
  //   "Locality":"abc",
  //   "PinCode":"500090",
  //   "City":"hyd",
  //   "State":"telangana",
  //   "Country":"india"
  get HospitalID() {
    return this.hospitalForm.get('HospitalID');
  }

  get StreetAddress() {
    return this.hospitalForm.get('StreetAddress');
  }
  get Colony() {
    return this.hospitalForm.get('Colony');
  }
  get Locality() {
    return this.hospitalForm.get('Locality');
  }
  get PinCode() {
    return this.hospitalForm.get('PinCode');
  }
  get City() {
    return this.hospitalForm.get('City');
  }
  get State() {
    return this.hospitalForm.get('State');
  }
  get Country() {
    return this.hospitalForm.get('Country');
  }
  get HospitalName() {
    return this.hospitalForm.get('HospitalName');
  }
  get HospitalBranch() {
    return this.hospitalForm.get('HospitalBranch');
  }
  get SecondryContactNumber() {
    return this.hospitalForm.get('SecondryContactNumber');
  }
  get ContactNumber() {
    return this.hospitalForm.get('ContactNumber');
  }
  get Address() {
    return this.hospitalForm.get('Address') as FormArray;
  }
  get Status() {
    return this.hospitalForm.get('Status');
  }


  hospitalForm = new FormGroup({
    HospitalID: new FormControl('', [Validators.required]),
    HospitalName: new FormControl('', [Validators.required, Validators.minLength(3), Validators.pattern('[a-zA-Z ]*')]),
    HospitalBranch: new FormControl('', [Validators.required, Validators.minLength(3), Validators.pattern('[a-zA-Z ]*')]),

    //   EmailID : new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z2-9.-]+\.[a-z]{2,4}$')]),
    // AliasName : new FormControl('', [Validators.required,Validators.minLength(10)]),
    ContactNumber: new FormControl('', [Validators.required, Validators.pattern('[6789][0-9]{9}'), Validators.minLength(10), Validators.maxLength(10)]),
    SecondryContactNumber: new FormControl('', [Validators.required, Validators.pattern('[6789][0-9]{9}'), Validators.minLength(10), Validators.maxLength(10)]),
    // Address:new FormArray([
    //   this.addAddressFormGroup()
    // ]),
    Address: new FormGroup({
      StreetAddress: new FormControl('', [Validators.required]),
      Colony: new FormControl('', [Validators.required]),
      Locality: new FormControl('', [Validators.required]),
      PinCode: new FormControl('', [Validators.required, Validators.pattern('[1-9][0-9]{5}')]),
      City: new FormControl('', [Validators.required]),
      State: new FormControl('', [Validators.required]),
      Country: new FormControl('', [Validators.required]),

      Latitude: new FormControl(''),
      Longitude: new FormControl(''),
      LocationLink: new FormControl('')
    }),
    StreetAddress: new FormControl('', [Validators.required]),
    Colony: new FormControl('', [Validators.required]),
    Locality: new FormControl('', [Validators.required]),
    PinCode: new FormControl('', [Validators.required, Validators.pattern('[0-9]{6}')]),
    City: new FormControl('', [Validators.required]),
    State: new FormControl('', [Validators.required]),
    Country: new FormControl('', [Validators.required]),

    Latitude: new FormControl(''),
    Longitude: new FormControl(''),
    LocationLink: new FormControl(''),
    Status: new FormControl('')
  });


  addAddressFormGroup(): FormGroup {
    return this.formBuilder.group({
      StreetAddress: new FormControl('', [Validators.required]),
      Colony: new FormControl('', [Validators.required]),
      Locality: new FormControl('', [Validators.required]),
      PinCode: new FormControl('', [Validators.required, Validators.pattern('[0-9]{6}')]),
      City: new FormControl('', [Validators.required]),
      State: new FormControl('', [Validators.required]),
      Country: new FormControl('', [Validators.required]),

      Latitude: new FormControl(''),
      Longitude: new FormControl(''),
      LocationLink: new FormControl('')

    });
  }

  getHospitalId(HospitalID: string) {
    console.log("HospitalID..........", HospitalID)
    this.apicalls.viewHospitalsById(HospitalID).subscribe(

      data => {
        this.spinner.show();
        // if (data.success) {
        //   setTimeout(() => {
        //     /** spinner ends after 5 seconds */
        //     this.spinner.hide();
        //   }, 2000);

        if (data.type == true) {
          this.details = data
          console.log("DDDDData@@@@@@@@@@@@@@@@@", this.details)
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
          }, 500);
          this.hospitalForm.patchValue({

            HospitalID: this.details.data.HospitalID,
            HospitalName: this.details.data.HospitalName,
            HospitalBranch: this.details.data.HospitalBranch,
            ContactNumber: this.details.data.ContactNumber,
            SecondryContactNumber: this.details.data.SecondryContactNumber,

            StreetAddress: this.details.data.Address.StreetAddress,
            Colony: this.details.data.Address.Colony,
            Locality: this.details.data.Address.Locality,
            PinCode: this.details.data.Address.PinCode,
            City: this.details.data.Address.City,
            State: this.details.data.Address.State,
            Country: this.details.data.Address.Country,

            Latitude: this.details.data.Address.Latitude,
            Longitude: this.details.data.Address.Longitude,
            LocationLink: this.details.data.Address.LocationLink,


            Status: this.details.data.Status


          });
        } else {
          alert(data.message);
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ")
      });


  }

  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }
}



