import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../../services/apicalls.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-createreport',
  templateUrl: './createreport.component.html',
  styleUrls: ['./createreport.component.css']
})
export class CreatereportComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private httpclient: HttpClient, private apicalls: ApicallsService, private route: Router, private router: ActivatedRoute) { }

  PatientDetiails;
  userDetails;

  BillID;
  PaymentID;
  BilltypeID;
  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data

    this.router.paramMap.subscribe(params => {
      const MRNumber = params.get('MRNumber');
      this.BillID = params.get('BillID');
      this.BilltypeID = params.get('Type');
      this.PaymentID = params.get('PaymentID');

      this.spinner.show();
      this.apicalls.viewPatientsById(MRNumber).subscribe(
        data => {
          if (data.type == true) {
            if (data.code == 'S001') {
              this.PatientDetiails = data.data;
              this.twodechoreportfrom.patchValue({
    
                // ReportID: this.twoDechoReportDetails.data.ReportID,
                 MITRAL_VALVE: "Normal",
                TRICUSPID_VALVE: "Normal",
                AORTIC_VALVE: "Normal",
                PULMONARY_VALVE: "Normal",
                // LEFT_ATRIUM: "Normal",
                // AORTA: "Normal",
                RIGHT_ATRIUM: "Normal",
                RIGHT_VENTRICLE: "Normal",
                IAS: "Normal",
                IVS: "Normal",
                IVC: "Normal",
                PERICARDIUM: "No Pericardial effusion",
               // OTHERS: "Normal",
                CONCLUSION: "Normal Valves,\n Normal Chambers \n No RWMA of LV \n No MR \n Good LV Systolic Function \n   Normal LV filling\n Intact IAS/IVS ,\nNo Pericardial effusion ",
                //LEFT_VENTRCILE
                // LVEDD: this.twoDechoReportDetails.data.LEFT_VENTRCILE.LVEDD,
                // LVESD: this.twoDechoReportDetails.data.LEFT_VENTRCILE.LVESD,
                // EF: this.twoDechoReportDetails.data.LEFT_VENTRCILE.EF,
                // ISDd: this.twoDechoReportDetails.data.LEFT_VENTRCILE.ISDd,
                // PWd: this.twoDechoReportDetails.data.LEFT_VENTRCILE.PWd,
                // FS: this.twoDechoReportDetails.data.LEFT_VENTRCILE.FS,
           
                MITRAL_FLOW: "E>A ,Normal",
                TRICUSPID_FLOW: "Laminar ,Normal",
                AORTIC_FLOW: "1.4m/s, Normal",
                PULMONARYFLOW: "1.1m/s, Normal",
                // //COLOUR_DOPPLER
                MR: "Nil",
                TR: "Nil",
                AR: "Nil",
                PR: "Nil",
              });
              this.spinner.hide();
            }
            else if (data.code == "S002") {
              alert("No data found")
              this.Patientinfo = {}
            }
            setTimeout(() => {
              this.spinner.hide();
            }, 500);
          }
          else if (data.code == "UA01") {
            alert("No data found")
          } else {
            alert(data.message);
            this.spinner.hide();
          }
        }, error => {
          alert(" Internal Server Error ")
        });

    });
  }


  error = false;
  isSubmitted: boolean = true;
  channelError: string;

  onSubmit(twodechoreportfrom) {

    if (this.twodechoreportfrom.invalid) {
      this.error = true;
      return;
    }


    if (this.twodechoreportfrom.value.CMRNumber != "NewCustomer") {
      this.twodechoreportfrom.value.MRNumber = this.twodechoreportfrom.value.CMRNumber;
    }

    this.twodechoreportfrom.value.Name = this.PatientDetiails.Name;
    this.twodechoreportfrom.value.MRNumber = this.PatientDetiails.MRNumber;
    this.twodechoreportfrom.value.BillID = this.BillID;
    this.twodechoreportfrom.value.PaymentID = this.PaymentID;
    this.twodechoreportfrom.value.BillTypeID=this.BilltypeID;



    this.twodechoreportfrom.value.LEFT_VENTRCILE = {}

    this.twodechoreportfrom.value.LEFT_VENTRCILE.LVEDD = this.twodechoreportfrom.value.LVEDD
    this.twodechoreportfrom.value.LEFT_VENTRCILE.LVESD = this.twodechoreportfrom.value.LVESD
    this.twodechoreportfrom.value.LEFT_VENTRCILE.ISDd = this.twodechoreportfrom.value.ISDd

    this.twodechoreportfrom.value.LEFT_VENTRCILE.PWd = this.twodechoreportfrom.value.PWd

    this.twodechoreportfrom.value.LEFT_VENTRCILE.FS = this.twodechoreportfrom.value.FS

    this.twodechoreportfrom.value.LEFT_VENTRCILE.EF = this.twodechoreportfrom.value.EF



    this.twodechoreportfrom.value.DOPPLER = {}

    this.twodechoreportfrom.value.DOPPLER.MITRAL_FLOW = this.twodechoreportfrom.value.MITRAL_FLOW
    this.twodechoreportfrom.value.DOPPLER.TRICUSPID_FLOW = this.twodechoreportfrom.value.TRICUSPID_FLOW

    this.twodechoreportfrom.value.DOPPLER.AORTIC_FLOW = this.twodechoreportfrom.value.AORTIC_FLOW

    this.twodechoreportfrom.value.DOPPLER.PULMONARYFLOW = this.twodechoreportfrom.value.PULMONARYFLOW

    this.twodechoreportfrom.value.COLOUR_DOPPLER = {}

    this.twodechoreportfrom.value.COLOUR_DOPPLER.MR = this.twodechoreportfrom.value.MR

    this.twodechoreportfrom.value.COLOUR_DOPPLER.TR = this.twodechoreportfrom.value.TR

    this.twodechoreportfrom.value.COLOUR_DOPPLER.AR = this.twodechoreportfrom.value.AR

    this.twodechoreportfrom.value.COLOUR_DOPPLER.PR = this.twodechoreportfrom.value.PR

    setTimeout(() => {
      this.spinner.hide();
    }, 5000);


    let result = this.apicalls.createtwodeechoreport(this.twodechoreportfrom.value)
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {
            this.spinner.hide();
            alert(data.data)
            if (data.ReportData) {
              this.route.navigate(["/dashboardmain/Print2DEchoReport", data.ReportData.ReportID, data.ReportData.MRNumber]);
            } else {
              this.route.navigate(["/dashboardmain/appointments"]);
            }

          } else if (data.code == 'S002') {
            this.spinner.hide();
            alert(data.data);
            this.isSubmitted = false;
            return false;
          }
        } else if (data.code == 'UA01') {
          alert("Your UnAuthorized to Access this Service")
        } else if (data.code = 'PD01') {
          alert("Permissions Denied")
        }
        else {
          this.spinner.hide();
          this.isSubmitted = false;
          alert(data.data)
          this.channelError = data.data;
          return false;
        }
      },
        error => { alert(" Internal Server Error "); }
      );

  }


  get MedicID() {
    return this.twodechoreportfrom.get('MedicID');
  }
  get DoctorName() {
    return this.twodechoreportfrom.get('DoctorName');
  }

  get CMRNumber() {
    return this.twodechoreportfrom.get('CMRNumber');
  }

  get MITRAL_VALVE() {
    return this.twodechoreportfrom.get('MITRAL_VALVE');
  }

  get TRICUSPID_VALVE() {
    return this.twodechoreportfrom.get('TRICUSPID_VALVE');
  }


  get AORTIC_VALVE() {
    return this.twodechoreportfrom.get('AORTIC_VALVE');
  }

  get PULMONARY_VALVE() {
    return this.twodechoreportfrom.get('PULMONARY_VALVE');
  }

  get LEFT_ATRIUM() {
    return this.twodechoreportfrom.get('LEFT_ATRIUM');
  }

  get AORTA() {
    return this.twodechoreportfrom.get('AORTA');
  }

  // ---------------------------------

  get LVEDD() {
    return this.twodechoreportfrom.get('LVEDD');
  }

  get LVESD() {
    return this.twodechoreportfrom.get('LVESD');
  }

  get EF() {
    return this.twodechoreportfrom.get('EF');
  }

  get ISDd() {
    return this.twodechoreportfrom.get('ISDd');
  }

  get PWd() {
    return this.twodechoreportfrom.get('PWd');
  }

  get FS() {
    return this.twodechoreportfrom.get('FS');
  }

  get RIGHT_ATRIUM() {
    return this.twodechoreportfrom.get('RIGHT_ATRIUM');
  }

  get RIGHT_VENTRICLE() {
    return this.twodechoreportfrom.get('RIGHT_VENTRICLE');
  }

  get IAS() {
    return this.twodechoreportfrom.get('IAS');
  }

  get IVS() {
    return this.twodechoreportfrom.get('IVS');
  }

  get IVC() {
    return this.twodechoreportfrom.get('IVC');
  }

  get PERICARDIUM() {
    return this.twodechoreportfrom.get('PERICARDIUM');
  }

  get MITRAL_FLOW() {
    return this.twodechoreportfrom.get('MITRAL_FLOW');
  }

  get TRICUSPID_FLOW() {
    return this.twodechoreportfrom.get('TRICUSPID_FLOW');
  }

  get AORTIC_FLOW() {
    return this.twodechoreportfrom.get('AORTIC_FLOW');
  }

  get PULMONARYFLOW() {
    return this.twodechoreportfrom.get('PULMONARYFLOW');
  }

  get ContactNumber() {
    return this.twodechoreportfrom.get('ContactNumber');
  }


  get MR() {
    return this.twodechoreportfrom.get('MR');
  }

  get TR() {
    return this.twodechoreportfrom.get('TR');
  }

  get AR() {
    return this.twodechoreportfrom.get('AR');
  }

  get PR() {
    return this.twodechoreportfrom.get('PR');
  }


  get OTHERS() {
    return this.twodechoreportfrom.get('OTHERS');
  }

  get CONCLUSION() {
    return this.twodechoreportfrom.get('CONCLUSION');
  }

  get Name() {
    return this.twodechoreportfrom.get('Name');
  }
  get BillTypeID() {
    return this.twodechoreportfrom.get('BillTypeID');
  }

  





  twodechoreportfrom = new FormGroup({
    Name: new FormControl(''),
    ContactNumber: new FormControl(''),
    MedicID: new FormControl(''),
    DoctorID: new FormControl('',),
    CMRNumber: new FormControl(''),
    DoctorName: new FormControl(''),
    MITRAL_VALVE: new FormControl('',),
    TRICUSPID_VALVE: new FormControl('',),
    AORTIC_VALVE: new FormControl('',),
    PULMONARY_VALVE: new FormControl('',),
    LEFT_ATRIUM: new FormControl('',),
    AORTA: new FormControl('',),

    LVEDD: new FormControl('',),
    LVESD: new FormControl('',),
    EF: new FormControl('',),
    ISDd: new FormControl('',),
    PWd: new FormControl('',),
    FS: new FormControl('',),

    RIGHT_ATRIUM: new FormControl('',),
    RIGHT_VENTRICLE: new FormControl('',),
    IAS: new FormControl('',),
    IVS: new FormControl('',),
    IVC: new FormControl('',),
    PERICARDIUM: new FormControl('',),

    MITRAL_FLOW: new FormControl('',),
    TRICUSPID_FLOW: new FormControl('',),
    AORTIC_FLOW: new FormControl('',),
    PULMONARYFLOW: new FormControl('',),


    MR: new FormControl('',),
    TR: new FormControl('',),
    AR: new FormControl('',),
    PR: new FormControl('',),

    OTHERS: new FormControl('',),

    CONCLUSION: new FormControl('',),
    BillTypeID:new FormControl('',),

  });

  // PatientDetiails;
  // onPhoneEntered(value: string) {

  //   this.apicalls.viewPatientsByContactNumber(value).subscribe(

  //     data => {
  //       //this.spinner.show();

  //       if (data.type == true) {
  //         if (data.code == 'S001') {
  //           this.PatientDetiails = data.data;

  //         }
  //         if (data.code == 'S002') {
  //           this.PatientDetiails = []
  //         }

  //         setTimeout(() => {
  //           /** spinner ends after 5 seconds */
  //           this.spinner.hide();
  //         }, 500);


  //       } else {
  //         alert(data.message);
  //         this.spinner.hide();
  //       }
  //     }, error => {
  //       alert(" Internal Server Error ")
  //     });

  // }

  isReadonly = false;
  isDisabled = false;
  Patientinfo;

  onPatientSelectSelected(value: string) {
    if (value == "NewCustomer") {
      this.isReadonly = false;
      this.isDisabled = false;
      this.Patientinfo = {}
      this.Patientinfo.Address = {}
      this.twodechoreportfrom.patchValue({
        PatientName: this.Patientinfo.Name,
        Gender: "",
        Age: this.Patientinfo.Age,
        StreetAddress: this.Patientinfo.Address.StreetAddress,
        District: this.Patientinfo.District
      });

    } else {
      this.isReadonly = !this.isReadonly;
      this.isDisabled = !this.isDisabled;
      this.apicalls.viewPatientsById(value).subscribe(
        data => {
          if (data.type == true) {
            if (data.code == 'S001') {
              this.Patientinfo = data.data;
              this.twodechoreportfrom.patchValue({
                Name: this.Patientinfo.Name,
                Gender: this.Patientinfo.Gender,
                ContactNumber: this.Patientinfo.ContactNumber,
                Age: this.Patientinfo.Age,
                StreetAddress: this.Patientinfo.Address.StreetAddress,
                District: this.Patientinfo.District
              });

            }
            else if (data.code == 'S002') {
              alert(data.data);
              this.spinner.hide();
              this.Patientinfo = {}
            } else if (data.code == 'PD01') {
              alert(data.data);
              this.spinner.hide();
            }
          } else {
            alert(data.message);
            this.spinner.hide();
          }
        }, error => {
          alert(" Internal Server Error ")
        });

    }
  }


  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }

  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }


}
