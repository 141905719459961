import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
import { from } from 'rxjs';
import { Query, DataManager, WebApiAdaptor } from '@syncfusion/ej2-data';
import { AutoCompleteComponent } from '@syncfusion/ej2-angular-dropdowns';
import { CheckBoxComponent } from '@syncfusion/ej2-angular-buttons';

@Component({
  selector: 'app-create-patient-billings',
  templateUrl: './create-patient-billings.component.html',
  styleUrls: ['./create-patient-billings.component.css']
})
export class CreatePatientBillingsComponent implements OnInit {


  constructor(private spinner: NgxSpinnerService, private httpclient: HttpClient, private formBuilder: FormBuilder,
    private apicalls: ApicallsService, private route: Router, private router: ActivatedRoute) {
  }
  patients;
  doctorsDetails;
  Billings = [];
  BillingsTypes = [];

  Arr = []; //Array type captured in a variable
  num: number = 5;
  billingObj = <any>{};

  userDetails;
  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data;
    


    for (var j = 0; j < this.num; j++) {
      this.billingObj.BillingType = '';
      this.billingObj.BillingID = '';
      this.billingObj.Price = 0;
      this.billingObj.Discount = 0;
      this.billingObj.TotalPrice = 0;
      
      this.Arr.push(this.billingObj);
      this.billingObj = {};
    }

    this.apicalls.viewAllPatients()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
          this.patients = data.data;
        } else {
          this.spinner.hide();
          if (data.code == 'PD01') {
            alert(data.data)
            this.route.navigate(["/dashboardmain/dashboard"]);
          }
          else {
            alert(data.data)
          }
        }
      }, error => { alert(" Internal Server Error "); });

    this.apicalls.viewAllDoctors()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {
            this.doctorsDetails = data;
            if(this.doctorsDetails.data.length ==1){
              this.doctorinfo=this.doctorsDetails.data[0].MedicID
            }
            this.spinner.hide();
          }
          if (data.code == 'V002') {
            alert(data.data);
            this.spinner.hide();
          }
        } else {
          alert(data.data)
          this.spinner.hide();
        }
      }, error => { alert(" Internal Server Error "); });

    this.apicalls.getTypeMastersData()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {
            this.spinner.hide();
          }
          this.BillingsTypes = data.data;
          if (data.code == 'S002') {
            alert(data.data);
            this.spinner.hide();
          }
        } else {
          alert(data.data)
          this.spinner.hide();
        }
      }, error => { alert(" Internal Server Error "); });


      this.apicalls.viewAlluniqueBillings("Active")
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
          this.found = false;
       //   this.Arr[k].BillingType=value;
            this.Billings=data.data;
        } else {
          this.spinner.hide();
          if (data.code == 'PD01') {
            alert(data.data)
            this.route.navigate(["/dashboardmain/dashboard"]);
          }
          else {
            alert(data.data)
          }
        }
      }, error => { alert(" Internal Server Error "); });


  }



  PatientDetiails;
  onPhoneEntered(value: string) {
    this.apicalls.viewPatientsByContactNumber(value).subscribe(
      data => {
        if (data.type == true) {
          if (data.code == 'S001') {
            this.PatientDetiails = data.data;
          }
          if (data.code == 'S002') {
            this.PatientDetiails = []
          }
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
          }, 500);
        } else {
          alert(data.message);
          this.spinner.hide();
        }
      }, error => { alert(" Internal Server Error ") })
  }

  TempBillingsArray = []
  found = false;
  IsDoctorRequired=false;
  onBillingType(value: string, k) {
    this.IsDoctorRequired=false;
    this.Arr[k].BillingType=value;
    this.Arr[k].BillingID="";
    this.Arr[k].Price = 0;
    this.Arr[k].Discount =0;
    this.Arr[k].TotalPrice = 0;


    for(var z=0;z<this.BillingsTypes.length;z++){
      if(this.BillingsTypes[z].BillingID ==value){
        if(this.BillingsTypes[z].IsDoctorRequired =="Yes"){
          this.IsDoctorRequired=true;
        }
      }
    }
    this.Arr[k].BillingID="";
    this.NewArray = []
    for (let i = 0; i < this.Arr.length; i++) {
      this.NewObj = this.Arr[i];
      this.NewArray.push(this.NewObj);
      this.NewObj = {};
    }
    delete this.Arr

    this.Arr = JSON.parse(JSON.stringify(this.NewArray))
    return;

    
  }


  doctorinfo;
  onDoctorSelected(value: string) {
    this.doctorinfo=value;
    if(this.doctorinfo !=undefined){
      for(var a =0;a<this.Arr.length;a++){
        console.log(this.Arr[a])
        for(var m=0;m<this.BillingsTypes.length;m++){
          if(this.BillingsTypes[m].BillingID ==this.Arr[a].BillingType){
            if(this.BillingsTypes[m].IsDoctorRequired =="Yes"){
              this.IsDoctorRequired=true;
              this.Arr[a].BillingID="";
              this.Arr[a].Price=0;
              this.Arr[a].Discount=0;
              this.Arr[a].TotalPrice=0;
              this.NewArray = []
              for (let i = 0; i < this.Arr.length; i++) {
                this.NewObj = this.Arr[i];
                this.NewArray.push(this.NewObj);
                this.NewObj = {};
              }
              delete this.Arr
          
              this.Arr = JSON.parse(JSON.stringify(this.NewArray))
              return;

            }
          }
        }
      }
    }
   
  }
  obj=<any>{}
  onSelectBillingName(value: string, k: string) {

    if(this.Arr[k].BillingType == undefined || this.Arr[k].BillingType.length<1){
      alert("select Billing Type")
      this.Arr[k].BillingID="";
      this.NewArray = []
      for (let i = 0; i < this.Arr.length; i++) {
        this.NewObj = this.Arr[i];
        this.NewArray.push(this.NewObj);
        this.NewObj = {};
      }
      delete this.Arr
  
      this.Arr = JSON.parse(JSON.stringify(this.NewArray))
      return;
    }
    if(this.IsDoctorRequired && this.doctorinfo){
    
      this.obj.BillingType =this.Arr[k].BillingType
      this.obj.BillingID=value;
      this.obj.MedicID=this.doctorinfo;
      this.obj.index=k
     this.fetchprice(this.obj)

    }else if(this.IsDoctorRequired && this.doctorinfo== undefined){
      alert("Select a Doctor")
      this.Arr[k].BillingID="";
      this.NewArray = []
      for (let i = 0; i < this.Arr.length; i++) {
        this.NewObj = this.Arr[i];
        this.NewArray.push(this.NewObj);
        this.NewObj = {};
      }
      delete this.Arr
  
      this.Arr = JSON.parse(JSON.stringify(this.NewArray))
      return;

    }
    else if(!this.IsDoctorRequired){
      for (var i = 0; i < this.Billings.length; i++) {
        if (this.Billings[i].BillingID == value) {
          this.Arr[k].BillingType = this.Billings[i].BillingType
          this.Arr[k].BillingID = this.Billings[i].BillingID
          this.Arr[k].Price = parseInt(this.Billings[i].Price);
          this.Arr[k].Discount = parseInt(this.Arr[k].Discount);
          this.Arr[k].TotalPrice = parseInt(this.Billings[i].Price);
        }
      }
    }
 

  }

  priceobj;
  fetchprice(obj) {

    this.apicalls.viewAllBillingsPrices(obj).subscribe(
      data => {
        if (data.type == true) {
          if (data.code == 'S001') {
          this.priceobj=data.data[0];
          this.Arr[obj.index].BillingID=this.priceobj.BillingID;
          this.Arr[obj.index].Price=parseInt(this.priceobj.Price);
          this.Arr[obj.index].Discount=0;

          this.Arr[obj.index].TotalPrice=parseInt(this.priceobj.Price);


          }
          if (data.code == 'S002') {
            this.priceobj = {}
            alert("No Price Found")
            // this.Arr[obj.index].Price=0;
            // this.Arr[obj.index].Discount=0;
            // this.Arr[obj.index].TotalPrice=0;
          }
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
        } else {
          alert(data.message);
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ")
      });
   
  }

  

  NewArray
  NewObj = <any>{};
  onDiscount(value, k: string) {
    if (value >= 0 && !isNaN(value.charAt(0))) {
      value = parseInt(value)
      if (value > this.Arr[k].Price) {
        this.Arr[k].TotalPrice = this.Arr[k].Price;

        value = 0;
        this.Arr[k].Discount = 0;
        alert("Enter valid Discount Price.");
        value = 0;
        this.Arr[k].Discount = 0;
        this.Arr[k].TotalPrice = this.Arr[k].Price;
      } else {
        this.Arr[k].Discount = value;
        this.Arr[k].TotalPrice = this.Arr[k].Price - parseInt(value);
      }

    } else {
      this.Arr[k].TotalPrice = this.Arr[k].Price;
      value = 0;
      this.Arr[k].Discount = 0;
      alert("Enter valid Discount Price.");
      value = 0;
      this.Arr[k].Discount = 0;
      this.Arr[k].TotalPrice = this.Arr[k].Price;
    }
    this.NewArray = []
    for (let i = 0; i < this.Arr.length; i++) {
      this.NewObj = this.Arr[i];
      this.NewArray.push(this.NewObj);
      this.NewObj = {};
    }
    delete this.Arr

    this.Arr = JSON.parse(JSON.stringify(this.NewArray))
  }




  isReadonly = false;
  isDisabled = false;
  Patientinfo;
  onPatientSelectSelected(value: string) {
    if (value == "NewPatient") {
      this.isReadonly = false;
      this.isDisabled = false;
      this.Patientinfo = {}
      this.Patientinfo.Address = {}
      this.patientBillingsForm.patchValue({
        PatientName: "",
        Gender: "",
        Age: "",
        MRNumber: "NewPatient",
        StreetAddress: "",
      });
    } else {
      this.isReadonly = !this.isReadonly;
      this.isDisabled = !this.isDisabled;
      this.apicalls.viewPatientsById(value).subscribe(
        data => {
          if (data.type == true) {
            if (data.code == 'S001') {
              this.Patientinfo = data.data;
              this.patientBillingsForm.patchValue({
                PatientName: this.Patientinfo.Name,
                Gender: this.Patientinfo.Gender,
                ContactNumber: this.Patientinfo.ContactNumber,
                MRNumber: this.Patientinfo.MRNumber,
                Age: this.Patientinfo.Age,
                StreetAddress: this.Patientinfo.Address.StreetAddress,
              });

            }
            if (data.code == 'S002') {
              this.Patientinfo = {}
            }
            setTimeout(() => {
              this.spinner.hide();
            }, 500);
          } else {
            alert(data.message);
            this.spinner.hide();
          }
        }, error => {
          alert(" Internal Server Error ")
        });

    }
  }





  patientBillingsForm = new FormGroup({
    ContactNumber: new FormControl('', [Validators.required, Validators.pattern('[6789][0-9]{9}'), Validators.minLength(10), Validators.maxLength(10)]),
    PatientName: new FormControl('', [Validators.minLength(3), Validators.pattern('[a-zA-Z ]*')]),
    MRNumber: new FormControl(''),
    Age: new FormControl('', [Validators.required, Validators.pattern('[0-9][0-9]{1}')]),
    Gender: new FormControl('', [Validators.required]),
    StreetAddress: new FormControl(''),
    BillingType: new FormControl('',),
    BillingID: new FormControl(''),
    Price: new FormControl(''),
    Discount: new FormControl('', [Validators.pattern('[1-9]+[0-9]*'),]),
    TotalPrice: new FormControl(''),
    TestArray: new FormArray([]),
    MedicID: new FormControl(''),
    Address: new FormGroup({
      StreetAddress: new FormControl(''),
    })
  });
  get ContactNumber() {
    return this.patientBillingsForm.get('ContactNumber');
  }
  get PatientName() {
    return this.patientBillingsForm.get('PatientName');
  }
  get MRNumber() {
    return this.patientBillingsForm.get('MRNumber');
  }
  get Age() {
    return this.patientBillingsForm.get('Age');
  }
  get Gender() {
    return this.patientBillingsForm.get('Gender');
  }
  get StreetAddress() {
    return this.patientBillingsForm.get('StreetAddress');
  }
  get BillingType() {
    return this.patientBillingsForm.get('BillingType');
  }
  get BillingID() {
    return this.patientBillingsForm.get('BillingID');
  }
  get Price() {
    return this.patientBillingsForm.get('Price');
  }

  get Discount() {
    return this.patientBillingsForm.get('Discount');
  }

  get TotalPrice() {
    return this.patientBillingsForm.get('TotalPrice');
  }

  get TestArray() {
    return this.patientBillingsForm.get('TestArray') as FormArray;
  }

  get MedicID() {
    return this.patientBillingsForm.get('MedicID');

  }
  get Address() {
    return this.patientBillingsForm.get('Address') as FormArray;
  }


  error;
  TempArr = []
  AddressObj = {};
  onSubmit(patientBillingsForm) {
    this.TempArr=[]
    this.spinner.show();
    for (var i = 0; i < this.Arr.length; i++) {
      if (this.Arr[i].BillingType != null && this.Arr[i].BillingID != null) {
        if(this.Arr[i].BillingType){
          for(var z=0;z<this.BillingsTypes.length;z++){
            if(this.BillingsTypes[z].BillingID ==this.Arr[i].BillingType){
              if(this.BillingsTypes[z].IsDoctorRequired =="Yes"){
                this.Arr[i].MedicID=this.doctorinfo;
              }
            }
          }
        }
        this.TempArr.push(this.Arr[i]);
      }
    }
    if (this.patientBillingsForm.value.MRNumber == "NewPatient") {
      delete this.patientBillingsForm.value.MRNumber;
      this.patientBillingsForm.value.PaymentStatus = "Paid"
      this.patientBillingsForm.value.TestArray = this.TempArr;
    } else {
      this.patientBillingsForm.value.PaymentStatus = "Paid"
      this.patientBillingsForm.value.TestArray = this.TempArr;

    }
    if (this.patientBillingsForm.value.StreetAddress != '' && this.patientBillingsForm.value.StreetAddress != null
      && this.patientBillingsForm.value.StreetAddress != 'undefined') {
      this.AddressObj = {

        StreetAddress: this.patientBillingsForm.value.StreetAddress,
      }
    } else {
      this.AddressObj = {
        StreetAddress: '',
      }
    }
    this.patientBillingsForm.value.Address = this.AddressObj

    delete this.patientBillingsForm.value.BillingID
    delete this.patientBillingsForm.value.BillingType
    delete this.patientBillingsForm.value.Discount
    delete this.patientBillingsForm.value.Price
    delete this.patientBillingsForm.value.StreetAddress
    delete this.patientBillingsForm.value.TotalPrice

    this.apicalls.createPayment(this.patientBillingsForm.value)
      .subscribe(data => {
       
        if (data.type == true) {
          if (data.code == 'S001') {
            this.spinner.hide();
            alert(data.data)
            this.route.navigate(["/dashboardmain/UpdatePayment/", data.PaymentDetails.PaymentID]);
          }
          if (data.code == 'S002') {
            alert(data.data);
            this.spinner.hide();
          }
        } else {
          alert(data.data)
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ");
      });
  }




  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }
  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }






  @ViewChild('local')
  public localObj: AutoCompleteComponent;
  @ViewChild('remote')
  public remoteObj: AutoCompleteComponent;
  @ViewChild('checkbox')
  public checkboxObj: CheckBoxComponent;


  //public countries: { [key: string]: Object; }[]= this.patients;
  // maps the local data column to fields property
  public localFields: Object = { value: 'ContactNumber' };
  //set the placeholder to AutoComplete input
  public localWaterMark: string = 'e.g. 9490219062';

}


