import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../services/apicalls.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'app-revenue',
  templateUrl: './revenue.component.html',
  styleUrls: ['./revenue.component.css']
})
export class RevenueComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private apicalls: ApicallsService, private router: ActivatedRoute, private route: Router, private httpClient: HttpClient) { }

  patients;
  Tests;
  TotalRevenue;
  type="All";
  
  StartDatePicker: Partial<BsDatepickerConfig>;
  EndDatePicker: Partial<BsDatepickerConfig>;
  userDetails;
  Billings = [];
  BillingsTypes = [];
  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data
    this.StartDatePicker = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumber: false,
        dateInputFormat: 'DD-MM-YYYY',

      });
    this.EndDatePicker = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumber: false,
        dateInputFormat: 'DD-MM-YYYY'
      });

    this.RevenueForm.value.Type = "All";


    this.apicalls.getPatients()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {
            this.patients = data.data;
            //console.log(" ", data.data);
          } else {
            this.patients = []
          }
          this.spinner.hide();

        } else {
          this.spinner.hide();

        }
      }, error => { });


      this.apicalls.getTypeMastersData()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {
            this.spinner.hide();
          }
          this.BillingsTypes = data.data;
          if (data.code == 'S002') {
            alert(data.data);
            this.spinner.hide();
          }
        } else {
          alert(data.data)
          this.spinner.hide();
        }
      }, error => { alert(" Internal Server Error "); });


      this.apicalls.viewAlluniqueBillings("Active")
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
            this.Billings=data.data;
        } else {
          this.spinner.hide();
          if (data.code == 'PD01') {
            alert(data.data)
            this.route.navigate(["/dashboardmain/dashboard"]);
          }
          else {
            alert(data.data)
          }
        }
      }, error => { alert(" Internal Server Error "); });


    this.apicalls.viewRevenue(this.RevenueForm.value)
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == "S001") {
            this.revenuedatadetails = data.data.AllPaymentDetails;
            this.TotalRevenue = data.data.TotalRevenue
          } else if (data.code == "S002") {
            this.revenuedatadetails = []
            alert("No Data Found")
          }else if(data.code =="S002"){
            alert("No data found")
          }else if(data.code =="UA01"){
            alert("Your UnAuthorized to Access this Service")
          }else if(data.code =="PD01"){
            alert("Permissions Denied")
          }else{
            alert(data.data)
          }
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
        } else {
          this.spinner.hide();

        }
      }, error => { alert(" Internal Server Error "); });

  }

  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }

  SelectStartDate() {

  }
  SelectEndDate() {

  }

  revenuedatadetails = []
  error;
  onSubmit(RevenueForm) {
    this.revenuedatadetails=[]
    this.spinner.show();
    var startDate = new Date();
    var endDate = new Date();
    if (this.userDetails.Roles[0].Role == 'RECEPTIONIST') {
      this.RevenueForm.value.StartDate = moment(startDate).format("DD-MM-YYYY");
      this.RevenueForm.value.EndDate = moment(endDate).format("DD-MM-YYYY");
    } else if (this.RevenueForm.value.StartDate == "Invalid date" && this.RevenueForm.value.StartDate == "Invalid date") {
      this.RevenueForm.value.StartDate = "";//moment(this.RevenueForm.value.StartDate).format("DD-MM-YYYY");
      this.RevenueForm.value.EndDate = "";//moment(this.RevenueForm.value.EndDate).format("DD-MM-YYYY");
    } else {
      if(this.RevenueForm.value.StartDate)
      {
        this.RevenueForm.value.StartDate = moment(this.RevenueForm.value.StartDate).format("DD-MM-YYYY");

      }
      if(this.RevenueForm.value.EndDate)
      {
        this.RevenueForm.value.EndDate = moment(this.RevenueForm.value.EndDate).format("DD-MM-YYYY");

      }

    }
    this.apicalls.viewRevenue(this.RevenueForm.value)
      .subscribe(data => {
     
        if (data.type == true) {
          if (data.code == "S001") {
            this.revenuedatadetails = data.data.AllPaymentDetails;
            this.TotalRevenue = data.data.TotalRevenue;
          } else if (data.code == "S002") {
            this.revenuedatadetails = []
            alert("No Data Found")
          }
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
        } else {
          this.spinner.hide();

        }
      }, error => { alert(" Internal Server Error "); });

  }

  ExcelReport() {
    var startDate = new Date();
    var endDate = new Date();

    if(!this.RevenueForm.value.StartDate){
      this.RevenueForm.value.StartDate = moment(startDate).format("DD-MM-YYYY");
      this.RevenueForm.value.EndDate = moment(endDate).format("DD-MM-YYYY");
    }else{
      this.RevenueForm.value.StartDate = moment(this.RevenueForm.value.StartDate).format("DD-MM-YYYY");
      this.RevenueForm.value.EndDate = moment(this.RevenueForm.value.EndDate).format("DD-MM-YYYY");

    }
   
    var URL = this.apicalls.apiURL + "/api/revenue/getRevenueExcel"+"?"+"Type="+this.RevenueForm.value.Type+"&StartDate="+
    this.RevenueForm.value.StartDate+"&EndDate="+this.RevenueForm.value.EndDate+"&BillID="+this.RevenueForm.value.BillID+"&MRNumber="+this.RevenueForm.value.MRNumber
    window.open(URL);
  

  }

  RevenueForm = new FormGroup({
    StartDate: new FormControl('',),
    EndDate: new FormControl('',),
    Type: new FormControl(''),
    MRNumber: new FormControl(''),
    BillID: new FormControl('')
  });
  get StartDate() {
    return this.RevenueForm.get('StartDate');
  }

  get EndDate() {
    return this.RevenueForm.get('EndDate');
  }

  get Type() {
    return this.RevenueForm.get('Type');
  }

  get MRNumber() {
    return this.RevenueForm.get('MRNumber');
  }

  get BillID() {
    return this.RevenueForm.get('BillID');
  }


  istest;
  onTypeSelected(value: string) {
      this.type = value

  }
  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }

}
