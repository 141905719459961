<!DOCTYPE html>
<!-- saved from url=(0031)http://drgokulreddy.in/patients -->
<html lang="en">

<body>
    <div id="page" class="side-nav-wrapper">
        <!-- Simple Navbar -->
        <!-- Simple Navbar -->
        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pull-right" style="margin-right: 10px;">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                                style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                                <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                                <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                    userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                        class="">Settings</a></li>
                                <li
                                    *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                    <a [routerLink]="" (click)="users()" class="">Users</a></li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>


        <div class="container bg-gray">
            <div class="row" style="padding-top: 10px; padding-left: 15px;">
                <div class="col-md-4">

                </div>
                <div class="col-md-4" style="padding-left: 480px;">



                </div>


            </div>
            <div class="row orr-usercreation ptop30 pbtom30">

                <div class="col-md-12">


                    <div class="panel">
                        <form id="contact-form" class="contact-form " [formGroup]="viewReportsForm">
                            <div class="row">
                                <div class="col-md-5" style="padding-left: 50px;">
                                    <div class="input-text form-group has-feedback">
                                        <label style="width:200px;float:left;text-align:left;padding-right:0px;">Phone
                                            Number<span class="required"> * </span></label>

                                        <ejs-autocomplete class="form-control" id='country' #local
                                            [dataSource]='patients' [autofill]='true' [fields]='localFields'
                                            filterType='StartsWith' [placeholder]='localWaterMark' #mynumber
                                            (change)="onPhoneEntered(mynumber.value)" required
                                            style="border: 5px; padding-left: 10px; width: 100%; border: 10px;">
                                        </ejs-autocomplete>
                                    </div>
                                  
                                </div>
                              
                                <div class="col-md-5" style="padding-right: 50px;">
                                    <div class="input-text form-group has-feedback">
                                        <label style="width:180px;float:left;text-align:right;padding-right:15px;">
                                            Patient Name<span class="required"> * </span></label>
                                        <select class="way-custom-dropdown price-text-field profile-mwidth form-control"
                                            style="background-color:#fff;margin-bottom:20px;" id="MRNumber"
                                            name="MRNumber" required #patientSelect
                                            (change)='onPatientSelect(patientSelect.value)' formControlName="MRNumber">
                                            <option value="" disabled>Select Patient</option>
                                            <option *ngFor="let pt of PatientDetiails"
                                                value="{{pt.MRNumber}}">
                                                {{pt.Name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="input-text form-group has-feedback">
                                        <label style="width:180px;float:left;text-align:right;padding-right:15px;">
                                          Bill  Type<span class="required"> </span></label>
                                        <select class="way-custom-dropdown price-text-field profile-mwidth form-control"
                                            style="background-color:#fff;margin-bottom:20px;" [ngModel]="type" formControlName="Type" id="Type"
                                            name="Type" required="" #mytypes (change)="onTypeSelected(mytypes.value)">
                                            <option value="" disabled>Select Type</option>
                                            <option value="All">All Types</option>
                                            <option *ngFor="let list of BillingsTypes; let i=index"
                                            value="{{list.BillingID}}">{{list.BillingName}}</option>
                                        </select>
                                    </div>
                                </div>
                            
                                <div class="col-md-5" >
                                    <div class="input-text form-group has-feedback">
                                        <label style="width:180px;float:left;text-align:right;padding-right:15px;">
                                            Bill Name<span class="required"> </span></label>
                                        <select class="way-custom-dropdown price-text-field profile-mwidth form-control"
                                            style="background-color:#fff;margin-bottom:20px;"  formControlName="BillID" [ngModel]="Bill" id="BillID"
                                            name="BillID" #myBills (change)="onBillSelected(myBills.value)">
                                            <option value="" disabled>Select Name</option>
                                            <option value="All">All Bill Names</option>
                                            <option *ngFor="let list of Billings |filter:type; let i=index" value="{{list.BillingID}}" >
                                                {{list.BillingName}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </form>




                        <table cellspacing="0" cellpadding="0" width="100%" class="mirchi-birthday-grid" id="example">
                            <thead>
                                <tr>
                                    <th style="text-align:center;"> S.No</th>
                                    <th style="text-align:center;"> Date </th>
                                    <th style="text-align:center;"> Type</th>

                                    <th style="text-align:center;"> Action</th>
                                </tr>
                            </thead>

                            <tbody>

                                <tr *ngFor="let list of Reports; let i=index ">
                                    <td style="text-align:center;">{{i + 1}}</td>

                                    <td style="text-align:center;">{{list.Date * 1000 | date:'dd-MM-yyyy'}}</td>
                                    <td style="text-align:center;">{{list.TypeName}}</td>


                                    <td style="text-align:center;">
                                      
                                        <a [routerLink]="" (click)="navigate(list)"><img
                                                src="../assets/images/print.png" width="25px" height="25px"
                                                alt="No prescription"></a>
                                                <a [routerLink]="" (click)="editechoreport(list)" *ngIf="list.Type =='2D_ECHO'"><img
                                                    src="../assets/images/edit.png" width="25px" height="25px"
                                                    alt="No prescription" style="padding-left: 1px;"></a>
                                    </td>





                                </tr>



                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    </div>
</body>

</html>
<ngx-spinner></ngx-spinner>