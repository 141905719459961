import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private httpclient: HttpClient, private apicalls: ApicallsService, private route: Router, private router: ActivatedRoute) { }
  userDetails;
  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data
    //console.log(" userDetails ", this.userDetails);
  }

  // dashboard(){
  //   this.route.navigate(["/dashboard"]);
  // }

  // Resources(){
  //   this.route.navigate(["/viewallresources"]);
  // }

  // Roles(){
  //   this.route.navigate(["/viewallroles"]);
  // }

  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }
  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
  dashboard() {
    this.route.navigate(["/dashboardmain/dashboard"]);
  }

  Resources() {
    this.route.navigate(["/dashboardmain/viewallresources"]);
  }

  Roles() {
    this.route.navigate(["/dashboardmain/viewallroles"]);
  }
  Privileges() {
    this.route.navigate(["/dashboardmain/privileges"]);
  }
  Permissions() {
    this.route.navigate(["/dashboardmain/permissions"]);
  }
  ChannelPartners() {
    this.route.navigate(["/dashboardmain/channelpartners"]);
  }

  Hospitals() {
    this.route.navigate(["/dashboardmain/hospitals"]);
  }
  appointments() {
    this.route.navigate(["/dashboardmain/appointments"]);
  }
  todaysAppointments() {
    this.route.navigate(["/dashboardmain/TodaysAppointments"]);
  }


  // Billings() {
  //   this.route.navigate(["/dashboardmain/ViewAllbillings"]);
  // }
  Payments() {
    this.route.navigate(["/dashboardmain/ViewAllPayments"]);
  }

  Allechoreports() {
    this.route.navigate(["/dashboardmain/allechoreports"]);
  }

  Revenue() {
    this.route.navigate(["/dashboardmain/revenue"]);
  }

  Configurations() {
    this.route.navigate(["/dashboardmain/configuration"]);
  }

  Followupappointments() {
    this.route.navigate(["/dashboardmain/followupappointments"]);
  }



  doctors() {
    this.route.navigate(["/dashboardmain/doctors"]);
  }
  patients() {
    this.route.navigate(["/dashboardmain/patients"]);
  }

  patientsbycondition() {
    this.route.navigate(["/dashboardmain/patients"]);
  }

  newRegistartion() {
    this.route.navigate(["/dashboardmain/CreatePatientBillings"])
  }
  
}
