import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../services/apicalls.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.css']
})
export class MasterComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private apicalls: ApicallsService, private router: ActivatedRoute, private route: Router, private httpClient: HttpClient) { }


  typeValue = false;
  typetestValue = false;

  viewAllbillingtypes;
  viewAllbillingtests;
  error = false;
  userDetails
  ngOnInit() {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data

    this.apicalls.viewAllBillingTypeMasterData()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          setTimeout(() => {

            this.spinner.hide();
          }, 500);
          this.viewAllbillingtypes = data.data;

        } else {
          this.spinner.hide();

          if (data.code == 'PD01') {
            alert(data.data)
            this.route.navigate(["/dashboardmain/dashboard"]);

          }
          else {
            alert(data.data)
          }

        }
      }, error => {
        error => { alert(" Internal Server Error "); }

      });

  }

  get BillingName() {
    return this.mastertypeForm.get('BillingName');
  }
  get IsDoctorRequired() {
    return this.mastertypeForm.get('IsDoctorRequired');
  }

  mastertypeForm = new FormGroup({
    BillingName: new FormControl('', [Validators.required]),
    IsDoctorRequired: new FormControl('',),
  });


  onSubmit(mastertypeForm) {
    if (this.mastertypeForm.invalid) {
      this.error = true;
      return;
    }
    if (this.mastertypeForm.value.IsDoctorRequired == null || this.mastertypeForm.value.IsDoctorRequired == undefined || this.mastertypeForm.value.IsDoctorRequired.length < 1) {
      alert('Select Doctor Requires Option')
      return
    }

    console.log(" ######  ", this.mastertypeForm.value);
    //return;
    let result = this.apicalls.createBillingTypeMaster(this.mastertypeForm.value)
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {
            alert(data.data)
            this.Billings();
            this.BillingName.setValue('');
            this.spinner.hide();
            this.route.navigate(["/dashboardmain/master"]);
          }
          if (data.code == 'L005') {
            this.spinner.hide();
            alert(data.data)
            this.route.navigate(["/dashboardmain/master"]);
          }
          if (data.code == 'S002') {
            this.spinner.hide();
            alert(data.data);
          }
        }
        else {
          this.spinner.hide();
          if (data.code == 'PD01') {
            alert(data.data)
            this.route.navigate(["/dashboardmain/dashboard"]);
          }
          else {
            alert(data.data)
          }
        }
      },
        error => { alert(" Internal Server Error "); }
      );

  }





  get BillingType() {
    return this.mastertestForm.get('BillingType');
  }
  get BillingName1() {
    return this.mastertestForm.get('BillingName1');
  }

  mastertestForm = new FormGroup({
    BillingName1: new FormControl('', [Validators.required]),
    BillingType: new FormControl('', [Validators.required]),
  });





  Billings() {

    this.typeValue = true;
    this.typetestValue = false;
    this.apicalls.viewAllBillingTypeMasterData()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {


          setTimeout(() => {

            this.spinner.hide();
          }, 500);
          this.viewAllbillingtypes = data;

        } else {


          this.spinner.hide();

          if (data.code == 'PD01') {
            alert(data.data)
            this.route.navigate(["/dashboardmain/dashboard"]);

          }
          else {
            alert(data.data)
          }

        }
      }, error => {
        error => { alert(" Internal Server Error "); }

      });
  }
  tempStatus;
  editdata(list) {
    if (list.Status == 'Active') {
      this.tempStatus = 'InActive';
    } else {
      this.tempStatus = 'Active';
    }

    this.spinner.show();

    let result = this.apicalls.updateBillingTypeMaster(list.BillingID, this.tempStatus)
      .subscribe(data => {
        this.spinner.show();

        if (data.type == true) {

          if (data.code == 'S001') {

            alert(data.data)
            this.Billings();
            this.spinner.hide();

            this.route.navigate(["/dashboardmain/master"]);
          }
          if (data.code == 'L005') {
            this.spinner.hide();
            alert(data.data)
            this.route.navigate(["/dashboardmain/master"]);
          }
          if (data.code == 'S002') {
            alert(data.data)
            this.Billings();
            this.spinner.hide();
          }

        }
        else {

          this.spinner.hide();


          if (data.code == 'PD01') {
            alert(data.data)
            this.spinner.hide();
            this.route.navigate(["/dashboardmain/dashboard"]);

          }
          else {
            alert(data.data);
            this.spinner.hide();
          }

        }

      },
        error => { alert(" Internal Server Error "); }
      );

  }

  tempBillingName;
  onSubmittest(mastertestForm) {
    if (this.mastertestForm.invalid) {
      this.error = true;
      return;
    }
    this.spinner.show();

    console.log("TEST Form data", this.mastertestForm.value)
    // this.tempBillingName={BillingName:this.mastertestForm.value.BillingName1};
    this.mastertestForm.value.BillingName = this.mastertestForm.value.BillingName1
    console.log("TEST Form data........", this.mastertestForm.value)
    let result = this.apicalls.createBillingTestMaster(this.mastertestForm.value)
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {

          if (data.code == 'S001') {

            alert(data.data)

            this.BillingsTest()
            this.BillingName1.setValue('');
            this.BillingType.setValue('');
            this.spinner.hide();


            this.route.navigate(["/dashboardmain/master"]);
          }
          if (data.code == 'L005') {
            this.spinner.hide();
            alert(data.data)
            this.route.navigate(["/dashboardmain/master"]);
          }

          if (data.code == 'S002') {
            this.spinner.hide();
            alert(data.data);
          }

        }
        else {
          this.spinner.hide();
          if (data.code == 'PD01') {
            alert(data.data)
            this.route.navigate(["/dashboardmain/dashboard"]);
          }
          else {
            alert(data.data)
          }

        }

      },
        error => { alert(" Internal Server Error "); }
      );
  }
  editdatatest(list) {
    if (list.Status == 'Active') {
      this.tempStatus = 'InActive';
    } else {
      this.tempStatus = 'Active';
    }
    this.spinner.show();

    let result = this.apicalls.updateBillingTestMaster(list.BillingID, this.tempStatus)
      .subscribe(data => {
        this.spinner.show();

        if (data.type == true) {

          if (data.code == 'S001') {

            alert(data.data);
            this.BillingsTest()
            this.spinner.hide();


            this.route.navigate(["/dashboardmain/master"]);
          }
          if (data.code == 'L005') {
            this.spinner.hide();
            alert(data.data)

            this.route.navigate(["/dashboardmain/master"]);
          }
          if (data.code == 'S002') {
            alert(data.data);
            this.BillingsTest()
            this.spinner.hide();
          }

        }
        else {
          this.spinner.hide();
          if (data.code == 'PD01') {
            alert(data.data)
            this.spinner.hide();
            this.route.navigate(["/dashboardmain/dashboard"]);

          }
          else {
            alert(data.data);
            this.spinner.hide();
          }
        }

      },
        error => { alert(" Internal Server Error "); }
      );

  }
  BillingsTest() {
    this.typetestValue = true;
    this.typeValue = false;

    this.apicalls.viewAllBillingTestMasterData()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          setTimeout(() => {

            this.spinner.hide();
          }, 500);
          this.viewAllbillingtests = data;

        } else {

          this.spinner.hide();

          if (data.code == 'PD01') {
            alert(data.data)
            this.spinner.hide();
            this.route.navigate(["/dashboardmain/dashboard"]);

          }
          else {
            alert(data.data);
            this.spinner.hide();
          }

        }
      }, error => {
        error => { alert(" Internal Server Error "); }

      });

  }

  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }

}

