<body data-gr-c-s-loaded="true" style="background-color: #f5f5f5; padding-bottom: 100px;">
    <div id="page" class="side-nav-wrapper">

        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pull-right" style="margin-right: 10px;">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                                style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                                <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                                <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                    userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                        class="">Settings</a></li>
                                <li
                                    *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                    <a [routerLink]="" (click)="users()" class="">Users</a></li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="container bg-gray">
            <div class="row">
                <div class="col-md-12">
                    <h5 class="dr-sub-head">Configurations</h5>
                </div>
            </div>


            <div class="row">
                <a [routerLink]="" (click)="Resources()" class="">
                    <div class="col-md-4" *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ">
                        <div class="card">
                            <img src="../assets/images/paper.png" alt="Avatar" style="align-items: center;width:40%">
                            <div class="container">
                                <h4><b>Resources</b></h4>
                            </div>
                        </div>
                    </div>
                </a>
                <a [routerLink]="" (click)="Roles()" class="">
                    <div class="col-md-4"
                        *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role == 'ADMIN'">
                        <div class="card">
                            <img src="../assets/images/queue.png" alt="Avatar" style="align-items: center;width:40%">
                            <div class="container">
                                <h4><b>Roles</b></h4>
                            </div>
                        </div>
                    </div>
                </a>
                <a [routerLink]="" (click)="Permissions()" class="">
                    <div class="col-md-4"
                        *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role == 'ADMIN'">
                        <div class="card">
                            <img src="../assets/images/authentication.png" alt="Avatar"
                                style="align-items: center;width:40%">
                            <div class="container">
                                <h4><b>Permissions</b></h4>
                            </div>
                        </div>
                    </div>
                </a>

            </div>
            <br>
            <div class="row">
                <a [routerLink]="" (click)="Hospitals()" class="">
                    <div class="col-md-4"
                        *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role == 'ADMIN'">
                        <div class="card">
                            <img src="../assets/images/hospital.png" alt="Avatar" style="align-items: center;width:40%">
                            <div class="container">
                                <h4><b>Hospitals</b></h4>
                            </div>
                        </div>
                    </div>
                </a>
                <a [routerLink]="" (click)="doctors()" class="">
                    <div class="col-md-4"
                        *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role == 'ADMIN'">
                        <div class="card">
                            <img src="../assets/images/doctor.png" alt="Avatar" style="align-items: center;width:40%">
                            <div class="container">
                                <h4><b>Doctor</b></h4>
                            </div>
                        </div>
                    </div>
                </a>
                <a [routerLink]="" (click)="Billings()" class="">
                    <div class="col-md-4"
                        *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role == 'ADMIN'">
                        <div class="card">
                            <img src="../assets/images/billing2.png" alt="Avatar" style="align-items: center;width:40%">
                            <div class="container">
                                <h4><b>Billings</b></h4>
                            </div>
                        </div>
                    </div>
                </a>
               
                <!-- <div class="col-md-4">
                    <div class="card">
                        <img src="../assets/images/paper.png" alt="Avatar" style="align-items: center;width:40%">
                        <div class="container">
                            <h4><b>Resources</b></h4>
                        </div>
                    </div>
                </div> -->

            </div>
            <br>
            <div class="row">
                <a [routerLink]="" (click)="master()" class="">
                    <div class="col-md-4"
                        *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role == 'ADMIN'">
                        <div class="card">
                            <img src="../assets/images/paper.png" alt="Avatar" style="align-items: center;width:40%">
                            <div class="container">
                                <h4><b>Masters</b></h4>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</body>
<ngx-spinner></ngx-spinner>