<body data-gr-c-s-loaded="true">
    <div id="page" class="side-nav-wrapper">

        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pull-right" style="margin-right: 10px;">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                                style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                                <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                                <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                    userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                        class="">Settings</a></li>
                                <li
                                    *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                    <a [routerLink]="" (click)="users()" class="">Users</a></li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="row orr-usercreation" style="margin: 0px; padding: 0px;">

            <div class="panel" style=" margin: 0px;">
                <div class="panel-body">



                    <form id="contact-form" class="contact-form " autocomplete="off" [formGroup]="patientBillingsForm">
                        <div class="form-pad-label" style="padding-top:0px; margin-top: 0px;">

                            <div class="panel" style="margin-left: 5px; padding-left: 50px;">


                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:200px;float:left;text-align:left;padding-right:0px;">Phone
                                                Number<span class="required"> * </span></label>
                                            <!-- <input
                                                    [class.is-invalid]="ContactNumber.invalid && (ContactNumber.dirty || ContactNumber.touched)"
                                                    class="form-control" id="ContactNumber" type="ContactNumber"
                                                    name="ContactNumber" placeholder=" Phone Number "
                                                    formControlName="ContactNumber" required #mynumber
                                                    (change)="onPhoneEntered(mynumber.value)"> -->
                                            <ejs-autocomplete class="form-control" id='country' #local
                                                [dataSource]='patients' [autofill]='true' [fields]='localFields'
                                                filterType='StartsWith' [placeholder]='localWaterMark' #mynumber
                                                (change)="onPhoneEntered(mynumber.value)"
                                                formControlName="ContactNumber" required
                                                style="border: 5px; padding-left: 10px; width: 100%; border: 10px;">
                                            </ejs-autocomplete>
                                        </div>
                                        <div style="text-align: center;">
                                            <small *ngIf="error && ContactNumber.errors?.required"
                                                class="text-danger">Phone
                                                Number is required</small>
                                            <small *ngIf="error && ContactNumber.errors?.pattern"
                                                class="text-danger">Please
                                                enter valid Phone Number</small>
                                            <small *ngIf="error && ContactNumber.errors?.minlength"
                                                class="text-danger">Phone Number should be 10 digits</small>
                                            <small *ngIf="error && ContactNumber.errors?.maxlength"
                                                class="text-danger">Phone Number should be 10 digits</small>

                                        </div>
                                    </div>



                                    <!-- [hidden]="MRNumber.value=='NewPatient'" -->
                                    <div class="col-md-6">
                                        <div class="input-text form-group has-feedback">
                                            <label style="width:180px;float:left;text-align:right;padding-right:15px;">
                                                Patient Name<span class="required"> * </span></label>
                                            <select
                                                class="way-custom-dropdown price-text-field profile-mwidth form-control"
                                                style="background-color:#fff;margin-bottom:20px;" 
                                                formControlName="MRNumber" id="MRNumber" name="MRNumber" required
                                                #patientSelect (change)='onPatientSelectSelected(patientSelect.value)'>
                                                <option value="" disabled>Select Patient</option>

                                                <option *ngFor="let pt of PatientDetiails; let i=index"
                                                    value="{{pt.MRNumber}}">{{pt.Name}}</option>
                                                <option value="NewPatient">--New Patient--</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Age<span
                                                    class="required"> * </span></label>
                                            <input [class.is-invalid]="Age.invalid && (Age.dirty || Age.touched)"
                                                class="form-control" id="Age" type="text" name="Age" placeholder=" Age "
                                                formControlName="Age" required [readonly]="isReadonly">

                                        </div>
                                        <div style="text-align: center;">
                                            <small *ngIf="error && Age.errors?.required" class="text-danger">Age
                                                is required</small>
                                            <small *ngIf="error && Age.errors?.pattern" class="text-danger">Age
                                                must be two digits </small>

                                        </div>
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Gender<span
                                                    class="required"> * </span></label>
                                            <select
                                                class="way-custom-dropdown price-text-field profile-mwidth form-control"
                                                style="background-color:#fff;margin-bottom:20px;"
                                                formControlName="Gender" id="Gender" name="Gender" required>
                                                <option value="" disabled>Select Gender</option>
                                                <option value="Male" [disabled]="isDisabled">Male</option>
                                                <option value="Female" [disabled]="isDisabled">Female</option>


                                            </select>
                                            <div style="text-align: center;">
                                                <small *ngIf="error && Gender.errors?.required"
                                                    class="text-danger">Gender is required</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">

                                        <div class="input-text form-group has-feedback"
                                            *ngIf="MRNumber.value=='NewPatient'">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Patient
                                                Name <span class="required"> * </span></label>
                                            <input
                                                [class.is-invalid]="PatientName.invalid && (PatientName.dirty || PatientName.touched)"
                                                class="form-control" id="PatientName" type="text" name="PatientName"
                                                placeholder=" Patient Name " formControlName="PatientName" required>

                                        </div>
                                        <div style="text-align: center;">
                                            <small *ngIf="error && PatientName.errors?.required"
                                                class="text-danger">PatientName is required</small>
                                            <small *ngIf="error && PatientName.errors?.minlength"
                                                class="text-danger">Name should be minimum 3 characters
                                            </small>
                                            <small *ngIf="error && PatientName.errors?.pattern"
                                                class="text-danger">PatientName must be alphabets </small>
                                        </div>
                                        <div class="input-text form-group has-feedback">

                                            <label style="width:180px;float:left;text-align:right;padding-right:15px;">
                                                Address</label>
                                            <textarea
                                                [class.is-invalid]="StreetAddress.invalid && (StreetAddress.dirty || StreetAddress.touched)"
                                                class="form-control" id="StreetAddress" type="text" name="StreetAddress"
                                                placeholder=" Address " formControlName="StreetAddress"
                                                style="height: 0px;"></textarea>
                                            <div
                                                *ngIf="StreetAddress.invalid  && (StreetAddress.dirty || StreetAddress.touched)">
                                                <small *ngIf="StreetAddress.errors?.required" class="text-danger">Street
                                                    Address is required</small>
                                            </div>
                                        </div>
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Doctor
                                                Name</label>
                                            <select
                                                class="way-custom-dropdown price-text-field profile-mwidth form-control"
                                                style="background-color:#fff;margin-bottom:20px;"
                                                formControlName="MedicID" id="MedicID" name="MedicID" #docSelect (change)='onDoctorSelected(docSelect.value)'>
                                                <option value="" disabled>Select Doctor's Name</option>

                                                <option *ngFor="let list of doctorsDetails?.data; let i=index"
                                                    value="{{list.MedicID}}">{{list.FirstName}}</option>

                                            </select>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>





                    <div class="panel" style="margin-left: 5px; padding-left: 50px;">
                        <div class="row" style="text-align: center;">
                            <div class="col-md-1">
                                <div class="input-text form-group has-feedback">
                                    Sr. No
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="input-text form-group has-feedback">
                                    Billing Type
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="input-text form-group has-feedback">
                                    Billing Name
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="input-text form-group has-feedback">
                                    Amount
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="input-text form-group has-feedback">
                                    Discount
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="input-text form-group has-feedback">
                                    Final Amount
                                </div>
                            </div>
                        </div>


                        <div class="row" *ngFor="let i of Arr; let k=index">
                            <div class="col-md-1">
                                <div class="input-text form-group has-feedback">
                                    {{k + 1}}
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="input-text form-group has-feedback">
                                    <select class="way-custom-dropdown price-text-field profile-mwidth form-control"
                                        style="background-color:#fff;margin-bottom:20px;" id="BillingType"
                                        name="BillingType" required [ngModel]="Arr[k].BillingType" #BillingType
                                        (change)="onBillingType(BillingType.value,k)">
                                        <option value="" selected disabled>Billing Type </option>
                                        <option *ngFor="let list of BillingsTypes; let i=index"
                                            value="{{list.BillingID}}">{{list.BillingName}}</option>

                                    </select>
                              
                                </div>
                            </div>

                            <div class="col-md-2">
                                <div class="input-text form-group has-feedback">
                                    <select class="way-custom-dropdown price-text-field profile-mwidth form-control"
                                        style="background-color:#fff;margin-bottom:20px;" id="BillingID"
                                        [ngModel]="Arr[k].BillingID" #BillingID name="BillingID" required
                                        (change)="onSelectBillingName(BillingID.value,k)">
                                        <option value="" selected disabled>Billing Name </option>
                                        <option *ngFor="let list of Billings | filter:Arr[k].BillingType; let i=index" value="{{list.BillingID}}" >
                                            {{list.BillingName }}</option>
                                    </select>
                                    <div style="text-align: center;">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="input-text form-group has-feedback">

                                    <input [class.is-invalid]="Price.invalid && (Price.dirty || Price.touched)"
                                        class="form-control" id="Price" type="number" name="Price"
                                        placeholder=" Amount " required [ngModel]="Arr[k].Price" readonly>
                                    <div style="text-align: center;">
                                        <small *ngIf="error && Price.errors?.required" class="text-danger">Amount
                                            is required</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="input-text form-group has-feedback">
                                    <input [class.is-invalid]="Discount.invalid && (Discount.dirty || Discount.touched)"
                                        class="form-control" id="Discount" type="number" name="Discount"
                                        placeholder=" Discount " [ngModel]="Arr[k].Discount" #Discount
                                        (change)="onDiscount(Discount.value,k)" pattern="^[1-9]+[0-9]*$">
                                    <div style="text-align: center;">
                                        <!-- <small *ngIf="error &&  Price.errors?.required" class="text-danger">Price
                                            is required</small> -->
                                        <small *ngIf="Discount.errors?.pattern" class="text-danger">Please
                                            enter
                                            valid Price

                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="input-text form-group has-feedback">
                                    <input
                                        [class.is-invalid]="TotalPrice.invalid && (TotalPrice.dirty || TotalPrice.touched)"
                                        class="form-control" id="TotalPrice" type="number" name="TotalPrice"
                                        placeholder=" FinalAmount " [ngModel]="Arr[k].TotalPrice" readonly>
                                    <div style="text-align: center;">
                                        <small *ngIf="error && TotalPrice.errors?.required" class="text-danger">Final
                                            Amount
                                            is required</small>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div style="margin:auto;text-align:center;margin-bottom:10px;margin-top:30px;margin-bottom:30px;">
                        <a [routerLink]="" (click)="onSubmit(patientBillingsForm)"> <input type="submit"
                                class="btn-pink" value="SAVE"></a>
                        <!-- <a [routerLink]="" (click)="onSubmit(patientBillingsForm)" style="padding: 20px;">
                                <input type="submit" class="btn-pink" value="PRINT"></a> -->

                    </div>


                </div>

            </div>



        </div>
    </div>
    <script type="text/javascript">
        function removeOption() {

            /* select the option with the  
            value of basic and remove the option*/
            $("#BillingType option[value='Appointment']").remove();
        } 
    </script>
</body>


<!-- <table cellspacing="0" cellpadding="0" width="100%" class="mirchi-birthday-grid" id="example">
    <thead>
        <tr>
            <th style="text-align:center;"> S.No</th>

        </tr>
    </thead>

    <tbody>
        <tr *ngFor="let list of Arr; let i=index ">
            <td style="text-align:center;">{{i + 1}}</td>
            <td>
                <select class="way-custom-dropdown price-text-field profile-mwidth form-control"
                    style="background-color:#fff;margin-bottom:20px;" id="BillingType" name="BillingType" required
                    [ngModel]="Arr[k].BillingType" #BillingType (change)="onBillingType(BillingType.value,k)">
                    <option value="" selected disabled>Billing Type </option>

                    <option value="Appointment">
                        Appointment
                    </option>
                    <option value="Test">Test</option>


                </select>
            </td>
            <td>
                <select class="way-custom-dropdown price-text-field profile-mwidth form-control"
                    style="background-color:#fff;margin-bottom:20px;" id="BillingID" [ngModel]="Arr[k].BillingID"
                    #BillingID name="BillingID" required (change)="onSelectBillingName(BillingID.value,k)">
                    <option value="" selected disabled>Billing Name </option>

                    <option *ngFor="let list of Billings; let i=index" value="{{list.BillingID}}">
                        {{list.BillingName}}</option>
                </select>
            </td>
            <td>
                <input [class.is-invalid]="Price.invalid && (Price.dirty || Price.touched)" class="form-control"
                    id="Price" type="text" name="Price" placeholder=" Amount " required [ngModel]="Arr[k].Price"
                    readonly>
            </td>
            <td>
                <input [class.is-invalid]="Discount.invalid && (Discount.dirty || Discount.touched)"
                    class="form-control" id="Discount" type="text" name="Discount" placeholder=" Discount "
                    [ngModel]="Arr[k].Discount" #Discount (change)="onDiscount(Discount.value,k)"
                    pattern="^[1-9]+[0-9]*$" formControlName="Discount">
            </td>
            <td>
                <input [class.is-invalid]="TotalPrice.invalid && (TotalPrice.dirty || TotalPrice.touched)"
                    class="form-control" id="TotalPrice" type="text" name="TotalPrice" placeholder=" FinalAmount "
                    [ngModel]="Arr[k].TotalPrice" readonly>
            </td>

        </tr>
    </tbody>
</table> -->