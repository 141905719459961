<body data-gr-c-s-loaded="true">
    <div id="page" class="side-nav-wrapper">

        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pull-right" style="margin-right: 10px;">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                                style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                                <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                                <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                    userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                        class="">Settings</a></li>
                                <li
                                    *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                    <a [routerLink]="" (click)="users()" class="">Users</a></li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>

    <div class="row bg-gray">
        <div id="printdiv">

            <div class="row orr-usercreation ptop30 pbtom30">
                <div class="col-md-3">

                </div>
                <div class="col-md-8">


                    <div class="panel" style="border: none;">
                        <form action="" method="post">
                            <input type="hidden" name="app_id" value="10229">

                            <div
                                style="text-align: -webkit-center; color: #0c93e6; font-size: 20px; font-weight: bold;">
                                Dr.Gokul Reddy Clinic</div>
                            <div calss="row">
                                
                                <div class="col-md-12" style="text-align: -webkit-center;border-bottom: 1px solid #aaa;">

                                    <p>Street number-8, Habsiguda, 500076,
                                        Hyderabad, Telangana, India</p>
                                </div>

                               
                            </div>
                            <div class="clearfix"></div>
                            <div calss="row">
                                <div style="text-align: center; margin-bottom: 15px;margin-left: 10px;">
                                    Dr Gokul Reddy M, MD, DM Consult Cardiologist, (Regd Number-38759)
                                </div>
                            </div>
                            <div class="row" style="padding:0 15px;">
                                <div class="col-md-6" style="width: 300px;    float: left;">
                                    <h5><b> Receipt No : {{paymentdetails.PaymentID}} </b></h5>
                                </div>
                                <div class="col-md-6 text-right">
                                    <h5><b> Date : {{paymentdetails.CreatedDate *1000 | date }} </b></h5>
                                </div>
                                <div class="clearfix"></div>
                                <div class="pull-left">
                                    <span class="col-lg-12 ptop5"><b> PATIENT NAME :
                                            {{paymentdetails.PatientDetails.Name}}
                                        </b></span>
                                </div>
                                <div class="pull-left">
                                    <span class="col-lg-12 ptop5"><b> AGE : {{paymentdetails.PatientDetails.Age}}
                                        </b></span>
                                </div>
                                <div class="pull-left">
                                    <span class="col-lg-12 ptop5"><b> MR NUMBER : {{paymentdetails.MRNumber}}
                                        </b></span>
                                </div>

                                <div class="col-md-12"
                                    *ngIf="paymentdetails.PaymentItems &&  paymentdetails.PaymentItems.length>0">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Sl.No</th>
                                                <th>Description</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let list of paymentdetails.PaymentItems; let i=index ">
                                                <td>{{i+1}}</td>
                                                <td>{{list.BillName}}</td>
                                                <td>{{list.FinalPrice}}</td>
                                            </tr>
                                            <tr><td> &nbsp;</td><td> <b>TotalAmount</b></td><td> <b>{{paymentdetails.TotalAmount}}/-</b></td></tr>
                                           
                                        </tbody>

                                    </table>
                                </div>
                                <div class="col-md-12" style="padding-top:10px;"> <span> Received with
                                        thanks Rs {{paymentdetails.TotalAmount}} /-

                                    </span>
                                    <div class="pull-right pright15">
                                        <img src="../../../assets/images/signature.png">
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                            </div>


                        </form>
                    </div>

                </div>


            </div>

        </div>



        <div class="row bg-gray" style="text-align: center;">
            <button (click)="printPage('printdiv')" class="btn-signin" style="background: #367fa9;">Print this
                Receipt</button>

        </div>

    </div>

</body>
<script type="text/javascript">
    $(window).load(function () {

        window.print();

    });
    $('.print').click(function () {
        window.print();
        //printDiv();

    });
    function printDiv() {

        var divToPrint = document.getElementById('divtoprint');

        var newWin = window.open('', 'Print-Window');

        newWin.document.open();

        newWin.document.write('<html><body onload="window.print()">' + divToPrint.innerHTML + '</body></html>');

        newWin.document.close();

        setTimeout(function () { newWin.close(); }, 10);

    }
    $(document).ready(function () {
        $('#example').DataTable();
    });
    $(document).ready(function () {
        $(".alert").fadeTo(2000, 500).slideUp(500, function () {
            $(".alert").slideUp(500);
        });
    });
</script>
<ngx-spinner></ngx-spinner>