<app-header></app-header>
<nav class="navbar navbar-inverse">
    <div>
        <div style="position:relative;">
            <div>
                <div class="collapse navbar-collapse" id="myNavbar" style="float:left;padding:0px;clear:both;width:100%;background:#2d3e50;">
                    <div>
                        <ul class="nav navbar-nav ag-menu sm pl40" style="float:left;" data-smartmenus-id="14811307330159312">
                            <li class=""><a href="">HOME</a>
                            </li>
                            <li class="{{openlist}}"  (mouseover)='over()' (mouseout)='out()'><a href="" class="has-submenu">MYPRACTICE  </a>
                                <ul class="dropdown-menu sm-nowrap" >
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Dr Gokul reddy</a>
                                                    </div>
                                                    <div>
                                                        <a class="" routerLink="/practice-locations"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>Practice Locations</a>
                                                    </div>
                                                    <!-- <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Book  appointment</a>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                            </li>
                            <li class="{{openlist1}}" (mouseover)='over1()' (mouseout)='out1()'><a class="has-submenu" href="#" >PATIENT INFO </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/about-cholesterol"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>ABOUT CHOLESTEROL</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/angioplasty"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> ANGIOPLASTY</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/chestpain"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CHESTPAIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/common-misconceptions"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>COMMON MISCONCEPTIONS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/coronary-angiogram"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CORONARY ANGIOGRAM</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/exercsie-and-heart-health"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> EXERCSIE & HEART HEALTH</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/guide-to-taking-warfarin"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> GUIDE TO TAKING WARFARIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-attack"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTATTACK</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-fauilre"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTFAUILRE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/high-blood-pressure"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HIGH BLOOD PRESSURE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/htn-myths"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>HTN-MYTHS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/tips-to-lower"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>TIPS  TO LOWER CHOLESTEROL   LEVELS</a> </div>



                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li><a href="#">SERVICES</a></li>
                            <li><a href="#">APPOINTMENTS</a></li>
                            <li class=""><a  href="faq">FAQS </a></li>
                            <li class=""><a  href="blog">MY BLOG</a></li>

                            <li class=""> <a  href="reach-us">REACH US</a></li>

                            <div class="clearfix"></div>
                        </ul>
                        <div class="pull-right pn">
                            <a href="#"><img src="/../../assets/front/img/askdemo.png" style="height:52px;"/></a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</nav>

<section style="background:#fff;">
    <div class="container">

        <div  style="padding:30px 15px;">

            <div class="row">

                <h4 class="mb5" style="color: #0991cd;font-weight: bold;">Guide to Taking Warfarin</h4>

                <!--<p class="pull-right"><img src="img/gokulreddy.png" style="height:350px;"/></p>-->

                <p>

                    Warfarin / Acitrom is used to prevent harmful blood clots from forming or growing larger. Beneficial blood clots prevent or stop bleeding, but harmful blood clots can cause a <u>heart attack, stroke, deep vein thrombosis or pulmonary embolism.</u> These are also called as “blood thinners”; however, warfarin does not thin the blood but instead causes the blood to take longer to form a clot.

                </p>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">How Does Warfarin Work?</h5>



                <p>

                    The formation of a clot in the body is a complex process that involves multiple substances called clotting factors. Warfarin decreases the body’s ability to form blood clots by blocking the formation of vitamin K–dependent clotting factors. Vitamin K is needed to make clotting factors and prevent bleeding. Therefore, by giving a medication that blocks the clotting factors, your body can stop harmful clots from forming and prevent clots from getting larger.

                </p>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">   Monitoring and Dosing Tips</h5>





                <p>The goal of warfarin /Acitrom therapy is to decrease the clotting tendency of blood, not to prevent clotting completely. Therefore, the effect of warfarin /Acitrom must be monitored carefully with blood testing. On the basis of the results of the blood test, your daily dose will be adjusted to keep your clotting time within a target range. The blood test used to measure the time it takes for blood to clot is referred to as

                    a prothrombin time test, or PT. The PT is reported as the International Normalized Ratio (INR).</p>



                <p>The INR is a standardized way of expressing the PT value. The INR ensures that PT results obtained by different laboratories can be compared. It is important to monitor the INR (at least once a month and sometimes as often as twice weekly) to make sure that the level of anti-coagulation remains in the effective range. If the INR is too low, blood clots will not be prevented, but if the INR is too high, there is an increased risk of bleeding. This is why those who take warfarin must have their blood tested so frequently.</p>



                <p>Never increase or decrease your dose unless instructed to do so by your healthcare provider.</p>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;"> Side Effects</h5>



                <p>The major complications associated with warfarin are clotting due to underdosing or bleeding due to excessive anticoagulation. The most serious bleeding is gastrointestinal or intracerebral. Excessive bleeding can occur in any area of the body, and patients taking warfarin should report any falls or accidents, as well as signs or symptoms of bleeding or unusual bruising, to their healthcare provider.</p>



                <p><b>Signs of unusual bleeding include:</b></p>





                <ul class="list_1">

                    <li>bleeding from the gums,</li>

                    <li>blood in the urine,</li>

                    <li>bloody or dark stool,</li>

                    <li>a nosebleed, or</li>

                    <li>vomiting blood.</li>



                </ul>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;"> When to Call Your Doctor</h5>



                <p>If you experience the following signs , you should call your doctor /ER,</p>



                <ul class="list_1">

                    <li>Severe headache, confusion, weakness or numbness</li>

                    <li>Coughing up large amounts of bright red blood</li>

                    <li>Vomiting blood</li>

                    <li>Bleeding that will not stop</li>

                    <li>Bright red blood in stool</li>

                    <li>Fall or injury to the head</li>

                    <li>Headache that is severe or unusual</li>



                </ul>



                <p>Some simple changes to decrease the risk of bleeding while taking warfarin include the following:</p>





                <ul class="list_1">

                    <li>Use a soft-bristle toothbrush</li>

                    <li>Shave with an electric razor rather than a blade</li>

                    <li>Take care when using sharp objects, such as knives and scissors</li>

                    <li>Avoid activities that have a risk of falling or injury</li>

                </ul>

                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Warfarin Interacts With Other Medications</h5>





                <p>Patients who take warfarin should consult with their healthcare provider before taking any new medication, including over-the-counter (nonprescription) drugs, herbal medicines, vitamins or any other products.</p>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Warfarin Interacts With Alcohol and With Certain Foods</h5>



                <p><b>Alcohol -</b> Alcohol intake can affect how the body metabolizes warfarin. Alcohol should be limited to no more than 1 to 2 servings of alcohol occasionally.. The antiplatelet effect of alcohol increases the risk of major bleeding, even if the INR remains within the target range.</p>



                <p><b>Foods -</b> Some foods can interfere with the effectiveness of warfarin. The most important point to remember is to eat what you normally eat and not to make any major changes in your diet without contacting your healthcare provider.</p>



                <p><b>Vitamin K -</b> Eating an increased amount of foods rich in vitamin K can lower the PT and INR, making warfarin less effective and potentially increasing the risk of blood clots. Patients who take warfarin should aim to eat a relatively similar amount of vitamin K each week. The highest amount of vitamin K is found in green and leafy vegetables such as Cabbage , lettuce, and spinach. It is not necessary to avoid these foods; however, it is important to try to keep the amount of vitamin K you eat consistent.</p>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">FOOD GUIDE</h5>



                <table class="table table-bordered table-responsive">

                    <tbody>

                    <tr><td>Low in Vit –K and safe when taken in moderation</td> <td>High in Vitamin-K , Avoid taking inconsistently or in large portions</td> </tr>

                    <tr><td>Onions</td> <td>Kiwifruit</td> </tr>

                    <tr><td>Bananas</td> <td>Lettuce</td> </tr>

                    <tr><td>Sweet potato</td> <td>Mustard greens</td> </tr>

                    <tr><td>Carrots</td> <td>Soybeans</td> </tr>

                    <tr><td>Cauliflower</td> <td>Spinach</td> </tr>

                    <tr><td>Potatoes</td> <td>Turnip greens</td> </tr>

                    <tr><td>Cilantro (garnish)</td> <td>Vegetable drinks</td> </tr>

                    <tr><td>Corn</td> <td>Amaranth leaves</td> </tr>

                    <tr><td>Cucumber</td> <td>Asparagus</td> </tr>

                    <tr><td>Okra</td> <td>Broccoli</td> </tr>

                    <tr><td>Green beans ands peas</td> <td>Brussels sprouts</td> </tr>

                    <tr><td>Tomato</td> <td>Cabbage</td> </tr>

                    <tr><td>Mushrooms (white)</td> <td></td> </tr>

                    </tbody>

                </table>





            </div>



        </div>

    </div>
</section>
<app-footer></app-footer>