import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl, FormArray, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-view-echo-reports',
  templateUrl: './view-echo-reports.component.html',
  styleUrls: ['./view-echo-reports.component.css']
})
export class ViewEchoReportsComponent implements OnInit {

  
  constructor(private spinner: NgxSpinnerService, private httpclient: HttpClient, private apicalls: ApicallsService, private route: Router, private router: ActivatedRoute) { }
  userDetails
  ngOnInit(): void {

    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data

    this.router.paramMap.subscribe(params => {
      const ReportID = params.get('ReportID');

      if (ReportID) {
        this.get2DEchoReportByID(ReportID);
      }
    });
  }
  error = false;
  isSubmitted: boolean = true;
  channelError: string;

  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }


  twoDechoReportDetails
  get2DEchoReportByID(ReportID) {
    this.apicalls.viewPatientTwoDEchoReports(ReportID).subscribe(

      data => {
        this.spinner.show();

        if (data.type == true) {
          this.twoDechoReportDetails = data
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
          this.Updatetwodechoreportfrom.patchValue({
            MRNumber: this.twoDechoReportDetails.data.MRNumber,
            ReportID: this.twoDechoReportDetails.data.ReportID,
            MITRAL_VALVE: this.twoDechoReportDetails.data.MITRAL_VALVE,
            TRICUSPID_VALVE: this.twoDechoReportDetails.data.TRICUSPID_VALVE,
            AORTIC_VALVE: this.twoDechoReportDetails.data.AORTIC_VALVE,
            PULMONARY_VALVE: this.twoDechoReportDetails.data.PULMONARY_VALVE,
            LEFT_ATRIUM: this.twoDechoReportDetails.data.LEFT_ATRIUM,
            AORTA: this.twoDechoReportDetails.data.AORTA,
            RIGHT_ATRIUM: this.twoDechoReportDetails.data.RIGHT_ATRIUM,
            RIGHT_VENTRICLE: this.twoDechoReportDetails.data.RIGHT_VENTRICLE,
            IAS: this.twoDechoReportDetails.data.IAS,
            IVS: this.twoDechoReportDetails.data.IVS,
            IVC: this.twoDechoReportDetails.data.IVC,
            PERICARDIUM: this.twoDechoReportDetails.data.PERICARDIUM,
            OTHERS: this.twoDechoReportDetails.data.OTHERS,
            CONCLUSION: this.twoDechoReportDetails.data.CONCLUSION,
            //LEFT_VENTRCILE
            LVEDD: this.twoDechoReportDetails.data.LEFT_VENTRCILE.LVEDD,
            LVESD: this.twoDechoReportDetails.data.LEFT_VENTRCILE.LVESD,
            EF: this.twoDechoReportDetails.data.LEFT_VENTRCILE.EF,
            ISDd: this.twoDechoReportDetails.data.LEFT_VENTRCILE.ISDd,
            PWd: this.twoDechoReportDetails.data.LEFT_VENTRCILE.PWd,
            FS: this.twoDechoReportDetails.data.LEFT_VENTRCILE.FS,
            //DOPPLER
            MITRAL_FLOW: this.twoDechoReportDetails.data.DOPPLER.MITRAL_FLOW,
            TRICUSPID_FLOW: this.twoDechoReportDetails.data.DOPPLER.TRICUSPID_FLOW,
            AORTIC_FLOW: this.twoDechoReportDetails.data.DOPPLER.AORTIC_FLOW,
            PULMONARYFLOW: this.twoDechoReportDetails.data.DOPPLER.PULMONARYFLOW,
            //COLOUR_DOPPLER
            MR: this.twoDechoReportDetails.data.COLOUR_DOPPLER.MR,
            TR: this.twoDechoReportDetails.data.COLOUR_DOPPLER.TR,
            AR: this.twoDechoReportDetails.data.COLOUR_DOPPLER.AR,
            PR: this.twoDechoReportDetails.data.COLOUR_DOPPLER.PR,
          });
        } else {
          alert(data.message);
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ")
      });
  }


  onSubmit(Updatetwodechoreportfrom) {

    if (this.Updatetwodechoreportfrom.invalid) {
      this.error = true;
      return;
    }


    setTimeout(() => {
      this.spinner.hide();
    }, 5000);

    
    this.Updatetwodechoreportfrom.value.ReportID=this.twoDechoReportDetails.data.ReportID;

    this.Updatetwodechoreportfrom.value.LEFT_VENTRCILE = {}

    this.Updatetwodechoreportfrom.value.LEFT_VENTRCILE.LVEDD = this.Updatetwodechoreportfrom.value.LVEDD
    this.Updatetwodechoreportfrom.value.LEFT_VENTRCILE.LVESD = this.Updatetwodechoreportfrom.value.LVESD
    this.Updatetwodechoreportfrom.value.LEFT_VENTRCILE.ISDd = this.Updatetwodechoreportfrom.value.ISDd

    this.Updatetwodechoreportfrom.value.LEFT_VENTRCILE.PWd = this.Updatetwodechoreportfrom.value.PWd

    this.Updatetwodechoreportfrom.value.LEFT_VENTRCILE.FS = this.Updatetwodechoreportfrom.value.FS

    this.Updatetwodechoreportfrom.value.LEFT_VENTRCILE.EF = this.Updatetwodechoreportfrom.value.EF


    this.Updatetwodechoreportfrom.value.DOPPLER = {}

    this.Updatetwodechoreportfrom.value.DOPPLER.MITRAL_FLOW = this.Updatetwodechoreportfrom.value.MITRAL_FLOW
    this.Updatetwodechoreportfrom.value.DOPPLER.TRICUSPID_FLOW = this.Updatetwodechoreportfrom.value.TRICUSPID_FLOW

    this.Updatetwodechoreportfrom.value.DOPPLER.AORTIC_FLOW = this.Updatetwodechoreportfrom.value.AORTIC_FLOW

    this.Updatetwodechoreportfrom.value.DOPPLER.PULMONARYFLOW = this.Updatetwodechoreportfrom.value.PULMONARYFLOW

    this.Updatetwodechoreportfrom.value.COLOUR_DOPPLER = {}

    this.Updatetwodechoreportfrom.value.COLOUR_DOPPLER.MR = this.Updatetwodechoreportfrom.value.MR

    this.Updatetwodechoreportfrom.value.COLOUR_DOPPLER.TR = this.Updatetwodechoreportfrom.value.TR

    this.Updatetwodechoreportfrom.value.COLOUR_DOPPLER.AR = this.Updatetwodechoreportfrom.value.AR

    this.Updatetwodechoreportfrom.value.COLOUR_DOPPLER.PR = this.Updatetwodechoreportfrom.value.PR
    
    let result = this.apicalls.Updatetwodeechoreport(this.Updatetwodechoreportfrom.value)
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {
            this.spinner.hide();
            alert(data.data)
            if (data.ReportData) {
              this.route.navigate(["/dashboardmain/Print2DEchoReport", data.ReportData.ReportID, data.ReportData.MRNumber]);
            } else {
              this.route.navigate(["/dashboardmain/appointments"]);
            }

          } else if (data.code == 'S002') {
            this.spinner.hide();
            alert(data.data);
            this.isSubmitted = false;
            return false;
          }
        } else if (data.code == 'UA01') {
          alert("Your UnAuthorized to Access this Service")
        } else if (data.code = 'PD01') {
          alert("Permissions Denied")
        }
        else {
          this.spinner.hide();
          this.isSubmitted = false;
          alert(data.data)
          this.channelError = data.data;
          return false;
        }
      },
        error => { 
          console.log("sssssss",error)
           alert(" Internal Server Error "); }
      );

  }

  get MedicID() {
    return this.Updatetwodechoreportfrom.get('MedicID');
  }
  get DoctorName() {
    return this.Updatetwodechoreportfrom.get('DoctorName');
  }

  get CMRNumber() {
    return this.Updatetwodechoreportfrom.get('CMRNumber');
  }

  get MITRAL_VALVE() {
    return this.Updatetwodechoreportfrom.get('MITRAL_VALVE');
  }

  get TRICUSPID_VALVE() {
    return this.Updatetwodechoreportfrom.get('TRICUSPID_VALVE');
  }


  get AORTIC_VALVE() {
    return this.Updatetwodechoreportfrom.get('AORTIC_VALVE');
  }

  get PULMONARY_VALVE() {
    return this.Updatetwodechoreportfrom.get('PULMONARY_VALVE');
  }

  get LEFT_ATRIUM() {
    return this.Updatetwodechoreportfrom.get('LEFT_ATRIUM');
  }

  get AORTA() {
    return this.Updatetwodechoreportfrom.get('AORTA');
  }

  // ---------------------------------

  get LVEDD() {
    return this.Updatetwodechoreportfrom.get('LVEDD');
  }

  get LVESD() {
    return this.Updatetwodechoreportfrom.get('LVESD');
  }

  get EF() {
    return this.Updatetwodechoreportfrom.get('EF');
  }

  get ISDd() {
    return this.Updatetwodechoreportfrom.get('ISDd');
  }

  get PWd() {
    return this.Updatetwodechoreportfrom.get('PWd');
  }

  get FS() {
    return this.Updatetwodechoreportfrom.get('FS');
  }

  get RIGHT_ATRIUM() {
    return this.Updatetwodechoreportfrom.get('RIGHT_ATRIUM');
  }

  get RIGHT_VENTRICLE() {
    return this.Updatetwodechoreportfrom.get('RIGHT_VENTRICLE');
  }

  get IAS() {
    return this.Updatetwodechoreportfrom.get('IAS');
  }

  get IVS() {
    return this.Updatetwodechoreportfrom.get('IVS');
  }

  get IVC() {
    return this.Updatetwodechoreportfrom.get('IVC');
  }

  get PERICARDIUM() {
    return this.Updatetwodechoreportfrom.get('PERICARDIUM');
  }

  get MITRAL_FLOW() {
    return this.Updatetwodechoreportfrom.get('MITRAL_FLOW');
  }

  get TRICUSPID_FLOW() {
    return this.Updatetwodechoreportfrom.get('TRICUSPID_FLOW');
  }

  get AORTIC_FLOW() {
    return this.Updatetwodechoreportfrom.get('AORTIC_FLOW');
  }

  get PULMONARYFLOW() {
    return this.Updatetwodechoreportfrom.get('PULMONARYFLOW');
  }

  get ContactNumber() {
    return this.Updatetwodechoreportfrom.get('ContactNumber');
  }


  get MR() {
    return this.Updatetwodechoreportfrom.get('MR');
  }

  get TR() {
    return this.Updatetwodechoreportfrom.get('TR');
  }

  get AR() {
    return this.Updatetwodechoreportfrom.get('AR');
  }

  get PR() {
    return this.Updatetwodechoreportfrom.get('PR');
  }


  get OTHERS() {
    return this.Updatetwodechoreportfrom.get('OTHERS');
  }

  get CONCLUSION() {
    return this.Updatetwodechoreportfrom.get('CONCLUSION');
  }

  get Name() {
    return this.Updatetwodechoreportfrom.get('Name');
  }





  Updatetwodechoreportfrom = new FormGroup({
    Name: new FormControl(''),
    ContactNumber: new FormControl(''),
    MedicID: new FormControl(''),
    DoctorID: new FormControl('',),
    CMRNumber: new FormControl(''),
    DoctorName: new FormControl(''),
    MITRAL_VALVE: new FormControl('',),
    TRICUSPID_VALVE: new FormControl('',),
    AORTIC_VALVE: new FormControl('',),
    PULMONARY_VALVE: new FormControl('',),
    LEFT_ATRIUM: new FormControl('',),
    AORTA: new FormControl('',),

    LVEDD: new FormControl('',),
    LVESD: new FormControl('',),
    EF: new FormControl('',),
    ISDd: new FormControl('',),
    PWd: new FormControl('',),
    FS: new FormControl('',),

    RIGHT_ATRIUM: new FormControl('',),
    RIGHT_VENTRICLE: new FormControl('',),
    IAS: new FormControl('',),
    IVS: new FormControl('',),
    IVC: new FormControl('',),
    PERICARDIUM: new FormControl('',),

    MITRAL_FLOW: new FormControl('',),
    TRICUSPID_FLOW: new FormControl('',),
    AORTIC_FLOW: new FormControl('',),
    PULMONARYFLOW: new FormControl('',),


    MR: new FormControl('',),
    TR: new FormControl('',),
    AR: new FormControl('',),
    PR: new FormControl('',),

    OTHERS: new FormControl('',),

    CONCLUSION: new FormControl('',),

  });

 

  
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }

}
