<app-header></app-header>

<nav class="navbar navbar-inverse">
    <div>
        <div style="position:relative;">
            <div>
                <div class="collapse navbar-collapse" id="myNavbar" style="float:left;padding:0px;clear:both;width:100%;background:#2d3e50;">
                    <div>
                        <ul class="nav navbar-nav ag-menu sm pl40" style="float:left;" data-smartmenus-id="14811307330159312">
                            <li class=""><a href="">HOME</a>
                            </li>
                            <li class="{{openlist}}"  (mouseover)='over()' (mouseout)='out()'><a href="" class="has-submenu">MYPRACTICE  </a>
                                <ul class="dropdown-menu sm-nowrap" >
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Dr Gokul reddy</a>
                                                    </div>
                                                    <div>
                                                        <a class="" routerLink="/practice-locations"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>Practice Locations</a>
                                                    </div>
                                                    <!-- <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Book  appointment</a>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                            </li>
                            <li class="{{openlist1}}" (mouseover)='over1()' (mouseout)='out1()'><a class="has-submenu" href="#" >PATIENT INFO </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/about-cholesterol"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>ABOUT CHOLESTEROL</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/angioplasty"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> ANGIOPLASTY</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/chestpain"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CHESTPAIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/common-misconceptions"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>COMMON MISCONCEPTIONS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/coronary-angiogram"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CORONARY ANGIOGRAM</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/exercsie-and-heart-health"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> EXERCSIE & HEART HEALTH</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/guide-to-taking-warfarin"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> GUIDE TO TAKING WARFARIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-attack"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTATTACK</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-fauilre"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTFAUILRE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/high-blood-pressure"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HIGH BLOOD PRESSURE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/htn-myths"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>HTN-MYTHS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/tips-to-lower"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>TIPS  TO LOWER CHOLESTEROL   LEVELS</a> </div>



                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li><a href="#">SERVICES</a></li>
                            <li><a href="#">APPOINTMENTS</a></li>
                            <li class=""><a  href="faq">FAQS </a></li>
                            <li class=""><a  href="blog">MY BLOG</a></li>

                            <li class=""> <a  href="reach-us">REACH US</a></li>

                            <div class="clearfix"></div>
                        </ul>
                        <div class="pull-right pn">
                            <a href="#"><img src="/../../assets/front/img/askdemo.png" style="height:52px;"/></a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</nav>

<section style="background:#fff;">

    <div class="container">

        <div  style="padding:30px 15px;">

            <div class="row">

                <h4 class="mb5" style="color: #0991cd;font-weight: bold;"> Heart Fauilre </h4>

                <!--<p class="pull-right"><img src="img/gokulreddy.png" style="height:350px;"/></p>-->

                <p>

                    Heart failure, sometimes known as congestive heart failure, occurs when your heart muscle doesn't pump blood as well as it should. Certain conditions, such as narrowed arteries in your heart (coronary artery disease) or high blood pressure, gradually leave your heart too weak or stiff to fill and pump efficiently.

                </p>



                <p>Not all conditions that lead to heart failure can be reversed, but treatments can improve the signs and symptoms of heart failure and help you live longer. Lifestyle changes — such as exercising, reducing salt in your diet, managing stress and losing weight — can improve your quality of life.</p>



                <p>One way to prevent heart failure is to control conditions that cause heart failure, such as coronary artery disease, high blood pressure, diabetes or obesity.</p>



                <p>Heart failure can be ongoing (chronic), or your condition may start suddenly (acute).</p>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Heart failure signs and symptoms may include:</h5>





                <ul class="list_1">

                    <li>Shortness of breath (dyspnea) when you exert yourself or when you lie down</li>

                    <li>Fatigue and weakness</li>

                    <li>Swelling (edema) in your legs, ankles and feet</li>

                    <li>Rapid or irregular heartbeat</li>

                    <li>Reduced ability to exercise</li>

                    <li>Persistent cough or wheezing with white or pink blood-tinged phlegm</li>

                    <li>Increased need to urinate at night</li>

                    <li>Swelling of your abdomen (ascites)</li>

                    <li>Sudden weight gain from fluid retention</li>

                    <li>Lack of appetite and nausea</li>

                    <li>Difficulty concentrating or decreased alertness</li>

                    <li> Sudden, severe shortness of breath and coughing up pink, foamy mucus</li>

                    <li>Chest pain if your heart failure is caused by a heart attack</li>

                </ul>







                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">When to see a doctor</h5>



                <p>See your doctor if you think you might be experiencing signs or symptoms of heart failure. Seek emergency treatment if you experience any of the following:</p>



                <ul class="list_1">

                    <li>Chest pain</li>

                    <li>Fainting or severe weakness</li>

                    <li>Rapid or irregular heartbeat associated with shortness of breath, chest pain or fainting</li>

                    <li>Sudden, severe shortness of breath and coughing up pink, foamy mucus</li>





                </ul>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Causes</h5>





                <p>Heart failure often develops after other conditions have damaged or weakened your heart. However, the heart doesn't need to be weakened to cause heart failure. It can also occur if the heart becomes too stiff.</p>



                <p>In heart failure, the main pumping chambers of your heart (the ventricles) may become stiff and not fill properly between beats. In some cases of heart failure, your heart muscle may become damaged and weakened, and the ventricles stretch (dilate) to the point that the heart can't pump blood efficiently throughout your body. Over time, the heart can no longer keep up with the normal demands placed on it to pump blood to the rest of your body.</p>



                <p>An ejection fraction is an important measurement of how well your heart is pumping and is used to help classify heart failure and guide treatment. In a healthy heart, the ejection fraction is 50 percent or higher — meaning that more than half of the blood that fills the ventricle is pumped out with each beat. But heart failure can occur even with a normal ejection fraction. This happens if the heart muscle becomes stiff from conditions such as high blood pressure.</p>



                <p>The term "congestive heart failure" comes from blood backing up into — or congesting — the liver, abdomen, lower extremities and lungs. However, not all heart failure is congestive. You might have shortness of breath or weakness due to heart failure and not have any fluid building up.</p>



                <p>Heart failure can involve the left side (left ventricle), right side (right ventricle) or both sides of your heart. Generally, heart failure begins with the left side, specifically the left ventricle — your heart's main pumping chamber.</p>



                <p>Any of the following conditions can damage or weaken your heart and can cause heart failure. Some of these can be present without your knowing it:</p>





                <ul class="list_1">

                    <li><b>Coronary artery disease and heart attack.</b> Coronary artery disease is the most common form of heart disease and the most common cause of heart failure. A heart attack occurs if plaques formed by the fatty deposits in your arteries rupture. This causes a blood clot to form, which may block blood flow to an area of the heart muscle, weakening the heart's pumping ability and often leaving permanent damage. If the damage is significant, it can lead to a weakened heart muscle.</li>



                    <li><b>High blood pressure (hypertension).</b> Blood pressure is the force of blood pumped by your heart through your arteries. If your blood pressure is high, your heart has to work harder than it should to circulate blood throughout your body.<br>

                        <br>



                        Over time, the heart muscle may become thicker to compensate for the extra work it must perform. Eventually, your heart muscle may become either too stiff or too weak to effectively pump blood.</li>



                    <li><b>Faulty heart valves.</b> The valves of your heart keep blood flowing in the proper direction through the heart. A damaged valve — due to a heart defect, coronary artery disease or heart infection — forces your heart to work harder to keep blood flowing as it should.<br>

                        <br>



                        Over time, this extra work can weaken your heart. Faulty heart valves, however, can be fixed or replaced if found in time.</li>



                    <li><b>Damage to the heart muscle (cardiomyopathy).</b> Heart muscle damage (cardiomyopathy) can have many causes, including several diseases, infections, alcohol abuse and the toxic effect of drugs, such as cocaine or some drugs used for chemotherapy.<br>

                        <br>



                        Genetic factors play an important role in several types of cardiomyopathy, such as dilated cardiomyopathy, hypertrophic cardiomyopathy, arrhythmogenic right ventricular cardiomyopathy, left ventricular noncompaction and restrictive cardiomyopathy.</li>





                    <li><b>Myocarditis. Myocarditis is an inflammation of the heart muscle.</b> It's most commonly caused by a virus and can lead to left-sided heart failure.</li>



                    <li><b>Heart defects you're born with (congenital heart defects).</b> If your heart and its chambers or valves haven't formed correctly, the

                        healthy parts of your heart have to work harder to pump blood through your heart, which, in turn, may lead to heart failure.

                    </li>



                    <li><b>Abnormal heart rhythms (heart arrhythmias).</b> Abnormal heart rhythms may cause your heart to beat too fast, which creates extra work for your heart. Over time, your heart may weaken, leading to heart failure. A slow heartbeat may prevent your heart from getting enough blood out to the body and may also lead to heart failure.</li>



                    <li><b>Other diseases.</b> Chronic diseases — such as diabetes, HIV, hyperthyroidism, hypothyroidism, or a buildup of iron (hemochromatosis) or protein (amyloidosis) —also may contribute to heart failure.

                        Causes of acute heart failure include viruses that attack the heart muscle, severe infections, allergic reactions, blood clots in the lungs, the use of certain medications or any illness that affects the whole body.</li>

                </ul>





                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Complications</h5>



                <p>If you have heart failure, your outlook depends on the cause and the severity, your overall health, and other factors such as your age. Complications can include:</p>



                <ul class="list_1">

                    <li>Kidney damage or failure. Heart failure can reduce the blood flow to your kidneys, which can eventually cause kidney failure if left untreated. Kidney damage from heart failure can require dialysis for treatment.</li>



                    <li>Heart valve problems. The valves of your heart, which keep blood flowing in the proper direction through your heart, may not function properly if your heart is enlarged or if the pressure in your heart is very high due to heart failure.</li>



                    <li>Heart rhythm problems. Heart rhythm problems (arrhythmias) can be a potential complication of heart failure.</li>



                    <li>Liver damage. Heart failure can lead to a buildup of fluid that puts too much pressure on the liver. This fluid backup can lead to scarring, which makes it more difficult for your liver to function properly.</li>



                </ul>



                <p>Some people's symptoms and heart function will improve with proper treatment. However, heart failure can be life-threatening. People with heart failure may have severe symptoms, and some may require heart transplantation or support with a ventricular assist device.</p>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;"> Treatments and drugs</h5>



                <p><b>Medications</b></p>



                <p>Doctors usually treat heart failure with a combination of medications. Depending on your symptoms, you might take one or more medications, including:</p>





                <ul class="list_1">

                    <li>Angiotensin-converting enzyme (ACE) inhibitors. These drugs help people with systolic heart failure live longer and feel better. ACE inhibitors are a type of vasodilator, a drug that widens blood vessels to lower blood pressure, improve blood flow and decrease the workload on the heart. Examples include enalapril (Vasotec), lisinopril (Zestril) and captopril (Capoten).</li>



                    <li>Angiotensin II receptor blockers. These drugs, which include losartan (Cozaar) and valsartan (Diovan), have many of the same benefits as ACE inhibitors. They may be an alternative for people who can't tolerate ACE inhibitors.</li>



                    <li>Beta blockers. This class of drugs not only slows your heart rate and reduces blood pressure but also limits or reverses some of the damage to your heart if you have systolic heart failure. Examples include carvedilol (Coreg), metoprolol (Lopressor) and bisoprolol (Zebeta).<br>

                        <br>



                        These medicines reduce the risk of some abnormal heart rhythms and lessen your chance of dying unexpectedly. Beta blockers

                        may reduce signs and symptoms of heart failure, improve heart function, and help you live longer.</li>



                    <li>Diuretics. Often called water pills, diuretics make you urinate more frequently and keep fluid from collecting in your body. Diuretics, such as furosemide (Lasix), also decrease fluid in your lungs so you can breathe more easily.<br>

                        <br>



                        Because diuretics make your body lose potassium and magnesium, your doctor also may prescribe supplements of these minerals. If you're taking a diuretic, your doctor will likely monitor levels of potassium and magnesium in your blood through regular blood tests.</li>



                    <li>Aldosterone antagonists. These drugs include spironolactone (Aldactone) and eplerenone (Inspra). These are potassium-sparing diuretics, which also have additional properties that may help people with severe systolic heart failure live longer.<br>

                        <br>



                        Unlike some other diuretics, spironolactone and eplerenone can raise the level of potassium in your blood to dangerous levels, so talk to your doctor if increased potassium is a concern, and learn if you need to modify your intake of food that's high in potassium.</li>



                    <LI>Inotropes. These are intravenous medications used in people with severe heart failure in the hospital to improve heart pumping function and maintain blood pressure.</LI>



                    <li>Digoxin (Lanoxin). This drug, also referred to as digitalis, increases the strength of your heart muscle contractions. It also tends to slow the heartbeat. Digoxin reduces heart failure symptoms in systolic heart failure. It may be more likely to be given to someone with a heart rhythm problem, such as atrial fibrillation.</li>



                </ul>

                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Surgery and medical devices</h5>







                <p>In some cases, doctors recommend surgery to treat the underlying problem that led to heart failure. Some treatments being studied and used in certain people include:</p>



                <ul class="list_1">

                    <li>Coronary bypass surgery. If severely blocked arteries are contributing to your heart failure, your doctor may recommend coronary artery bypass surgery. In this procedure, blood vessels from your leg, arm or chest bypass a blocked artery in your heart to allow blood to flow through your heart more freely.</li>



                    <li> Heart valve repair or replacement. If a faulty heart valve causes your heart failure, your doctor may recommend repairing or replacing the valve. The surgeon can modify the original valve (valvuloplasty) to eliminate backward blood flow. Surgeons can also repair the valve by reconnecting valve leaflets or by removing excess valve tissue so that the leaflets can close tightly. Sometimes repairing the valve includes tightening or replacing the ring around the valve (annuloplasty). <br>

                        <br>

                        Valve replacement is done when valve repair isn't possible. In valve replacement surgery, the damaged valve is replaced by an artificial (prosthetic) valve.

                        Certain types of heart valve repair or replacement can now be done without open heart surgery, using either minimally invasive surgery or cardiac catheterization techniques.</li>



                    <li>Implantable cardioverter-defibrillators (ICDs). An ICD is a device similar to a pacemaker. It's implanted under the skin in your chest with wires leading through your veins and into your heart.

                        The ICD monitors the heart rhythm. If the heart starts beating at a dangerous rhythm, or if your heart stops, the ICD tries to pace your heart or shock it back into normal rhythm. An ICD can also function as a pacemaker and speed your heart up if it is going too slow.</li>



                    <li>Cardiac resynchronization therapy (CRT), or biventricular pacing. A biventricular pacemaker sends timed electrical impulses to both of the heart's lower chambers (the left and right ventricles) so that they pump in a more efficient, coordinated manner.

                        Many people with heart failure have problems with their heart's electrical system that cause their already-weak heart muscle to beat in an uncoordinated fashion. This inefficient muscle contraction may cause heart failure to worsen. Often a

                        biventricular pacemaker is combined with an ICD for people with heart failure.</li>





                    <li>Heart pumps. These mechanical devices, such as ventricular assist devices (VADs), are implanted into the abdomen or chest and attached to a weakened heart to help it pump blood to the rest of your body. VADs are most often used in the heart's left ventricle, but they can also be used in the right ventricle or in both ventricles.<br>

                        <br>



                        Doctors first used heart pumps to help keep heart transplant candidates alive while they waited for a donor heart. VADs are now sometimes used as an alternative to transplantation. Implanted heart pumps can significantly extend and improve the lives of some people with severe heart failure who aren't eligible for or able to undergo heart transplantation or are waiting for a new heart.</li>



                    <li>Heart transplant. Some people have such severe heart failure that surgery or medications don't help. They may need to have their diseased heart replaced with a healthy donor heart.

                        Heart transplants can dramatically improve the survival and quality of life of some people with severe heart failure. However, candidates for transplantation often have to wait a long time before a suitable donor heart is found. Some transplant candidates improve during this waiting period through drug

                        treatment or device therapy and can be removed from the transplant waiting list.</li>





                </ul>



                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">End-of-life care and heart failure</h5>





                <p>Even with the number of treatments available for heart failure, it's possible that your heart failure may worsen to the point where medications are no longer working and a heart transplant or device isn't an option. If this occurs, you may need to enter hospice care. Hospice care provides a special course of treatment to terminally ill people.</p>



                <p>Hospice care allows family and friends — with the aid of nurses, social workers and trained volunteers — to care for and comfort a loved one at home or in hospice residences. Hospice care provides emotional, psychological, social and spiritual support for people who are ill and those closest to them.</p>



                <p>Although most people under hospice care remain in their own homes, the program is available anywhere — including nursing homes and assisted living centers. For people who stay in a hospital, specialists in end-of-life care can provide comfort, compassionate care and dignity.</p>



                <p>Although it can be difficult, discuss end-of-life issues with your family and medical team. Part of this discussion will likely involve advance directives — a general term for oral and written instructions you give

                    concerning your medical care should you become unable to speak for yourself.</p>



                <p>If you have an implantable cardioverter-defibrillator (ICD), one important consideration to discuss with your family and doctors is turning off the defibrillator so that it can't deliver shocks to make your heart continue beating.</p>







                <h5 class="mb5" style="color: #2d3e50;font-weight: bold;margin-top:30px;">Prevention</h5>



                <p>The key to preventing heart failure is to reduce your risk factors. You can control or eliminate many of the risk factors for heart disease — high blood pressure and coronary artery disease, for example — by making lifestyle changes along with the help of any needed medications.</p>



                <p>Lifestyle changes you can make to help prevent heart failure include:</p>





                <ul class="list_1">

                    <li>Not smoking</li>



                    <li>Controlling certain conditions, such as high blood pressure and diabetes</li>



                    <li>Staying physically active</li>



                    <li>Eating healthy foods</li>

                    <li> Maintaining a healthy weight</li>



                    <li> Reducing and managing stress</li>



                </ul>







            </div>



        </div>

    </div>

   

</section>
<app-footer></app-footer>