<body data-gr-c-s-loaded="true">
    <div id="page" class="side-nav-wrapper">
        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pull-right" style="margin-right: 10px;">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                                style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                                <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                                <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                    userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                        class="">Settings</a></li>
                                <li
                                    *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                    <a [routerLink]="" (click)="users()" class="">Users</a></li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>



    <div class="row bg-gray">
        <div id="printdiv">

            <div class="row orr-usercreation ptop30 pbtom30">
                <div class="col-md-3">

                </div>
                <div class="col-md-8">
                    <div class="panel" style="border: none;" style="padding:15px; margin-top: 40px; border: 0px;">

                        
                        
                        <div calss="row">
                            <div style="text-align: center; margin-bottom: 15px;margin-left: 10px;">
                                <h4 style="font-weight: bold; text-decoration: underline;"> 2D ECHOCARDIOGRAM</h4>
                            </div>
                        </div>
                        
                        <div class="row" style="padding:0 15px;">

                            <div class="col-md-6 text-left">
                                <h6><b> Report Date : </b>
                                    {{twoDechoReportDetails.data.UpdatedDate *1000| date:'dd-MMM-yyyy'}}
                                </h6>

                            </div>
                        </div>


                        <div class="row" style="padding:0 15px;">
                            <div class="col-md-6 text-left">
                                <h6><b> PATIENT NAME : </b> {{PatientDetiails.data.Name}}</h6>
                            </div>


                        </div>
                        <div class="row" style="padding:0 15px; ">
                            <table cellspacing="0px" cellpadding="0px" width="100%"
                                style="padding-top: 0px;  margin-left: 15px;">
                                <thead>
                                    <tr>
                                        <td>
                                            <h6><b> AGE : </b>{{PatientDetiails.data.Age}}</h6>
                                        </td>
                                        <td>
                                            <h6><b> SEX : </b>{{PatientDetiails.data.Gender}}</h6>

                                        </td>
                                        <td>
                                            <h6><b> MR NUMBER : </b>{{twoDechoReportDetails.data.MRNumber}}</h6>
                                        </td>
                                    </tr>
                                </thead>

                            </table>
                        </div>

                        <div class="row" style="padding:0 15px; ">
                            <div class="col-md-12 text-left">
                                <h6><b> MITRAL VALVE : </b> {{twoDechoReportDetails.data.MITRAL_VALVE}}</h6>
                            </div>
                            <div class="col-md-12 text-left">
                                <h6><b> TRICUSPID VALVE : </b>
                                    {{twoDechoReportDetails.data.TRICUSPID_VALVE}} </h6>
                            </div>
                            <div class="col-md-12 text-left">
                                <h6><b> AORTIC VALVE : </b>
                                    {{twoDechoReportDetails.data.AORTIC_VALVE}}</h6>
                            </div>
                            <div class="col-md-12 text-left">
                                <h6><b> PULMONARY VALVE : </b>
                                    {{twoDechoReportDetails.data.PULMONARY_VALVE}} </h6>
                            </div>
                            <div class="col-md-12 text-left">
                                <h6><b> LEFT ATRIUM : </b>
                                    {{twoDechoReportDetails.data.LEFT_ATRIUM}} </h6>
                            </div>
                            <div class="col-md-12 text-left">
                                <h6><b> AORTA : </b>
                                    {{twoDechoReportDetails.data.AORTA}}</h6>
                            </div>

                            <div class="col-md-12 text-left">
                                <h6><b> LEFT VENTRCILE : </b></h6>
                                <table cellspacing="0px" cellpadding="0px" width="100%"
                                    style="padding-top: 0px;  margin-left: 50px;">
                                    <thead>
                                        <tr>
                                            <td><b>LVEDD : </b> {{twoDechoReportDetails.data.LEFT_VENTRCILE.LVEDD}}
                                                <b>CM</b>
                                            </td>
                                            <td><b>LVESD : </b>{{twoDechoReportDetails.data.LEFT_VENTRCILE.LVESD}}
                                                <b>CM</b>
                                            </td>
                                            <td><b>EF : </b>{{twoDechoReportDetails.data.LEFT_VENTRCILE.EF}}
                                                <b>%</b>
                                            </td>
                                        </tr>
                                    </thead>
                                    <thead>
                                        <tr>
                                            <td><b>ISDd : </b>{{twoDechoReportDetails.data.LEFT_VENTRCILE.ISDd}}
                                                <b>CM</b>
                                            </td>
                                            <td><b>PWd : </b>{{twoDechoReportDetails.data.LEFT_VENTRCILE.PWd}}
                                                <b>CM</b>
                                            </td>
                                            <td><b>FS : </b>{{twoDechoReportDetails.data.LEFT_VENTRCILE.FS}}
                                                <b>%</b>
                                            </td>
                                        </tr>
                                    </thead>
                                </table>
                            </div>


                            <div class="col-md-12 text-left">
                                <h6><b> RIGHT ATRIUM : </b>
                                    {{twoDechoReportDetails.data.RIGHT_ATRIUM}}</h6>
                            </div>
                            <div class="col-md-12 text-left">
                                <h6><b> RIGHT VENTRICLE : </b>
                                    {{twoDechoReportDetails.data.RIGHT_VENTRICLE}}</h6>
                            </div>

                            <div class="col-md-12 text-left">
                                <h6><b>IAS : </b>
                                    {{twoDechoReportDetails.data.IAS}}</h6>
                            </div>
                            <div class="col-md-12 text-left">
                                <h6><b> IVS : </b>
                                    {{twoDechoReportDetails.data.IVS}}</h6>
                            </div>
                            <div class="col-md-12 text-left">
                                <h6> <b> IVC : </b>
                                    {{twoDechoReportDetails.data.IVC}}</h6>
                            </div>

                            <div class="row" style="padding:15px; margin-top: 40px;"> 

                            </div>


                            <div class="col-md-12 text-left" >
                                <h6><b> PERICARDIUM : </b>
                                    {{twoDechoReportDetails.data.PERICARDIUM}}</h6>
                            </div>

                            <div class="col-md-12 text-left">
                                <h6><b> DOPPLER : </b></h6>
                                <table cellspacing="0" cellpadding="0" width="100%"
                                    style=" margin-top: 0px;  margin-left: 100px; margin-top: -15px;">
                                    <thead>
                                        <tr>
                                            <td><b>MITRAL FLOW : </b>
                                                {{twoDechoReportDetails.data.DOPPLER.MITRAL_FLOW}}
                                            </td>
                                        </tr>
                                    </thead>
                                    <thead>
                                        <tr>
                                            <td><b>TRICUSPID FLOW :
                                                </b>{{twoDechoReportDetails.data.DOPPLER.TRICUSPID_FLOW}}
                                            </td>
                                        </tr>
                                    </thead>
                                    <thead>
                                        <tr>
                                            <td><b>AORTIC FLOW : </b>
                                                {{twoDechoReportDetails.data.DOPPLER.AORTIC_FLOW}}
                                            </td>
                                        </tr>
                                    </thead>

                                    <thead>
                                        <tr>
                                            <td><b>PULMONARY FLOW : </b>
                                                {{twoDechoReportDetails.data.DOPPLER.PULMONARYFLOW}}
                                            </td>
                                        </tr>
                                    </thead>
                                </table>
                            </div>

                            <div class="col-md-12 text-left">
                                <h6><b> COLOUR DOPPLER : </b></h6>
                            </div>
                            <table cellspacing="0" cellpadding="0" width="100%"
                                style="  margin-left: 150px;margin-top: -15px;">
                                <thead>
                                    <tr>
                                        <td><b>MR : </b>{{twoDechoReportDetails.data.COLOUR_DOPPLER.MR}}</td>
                                        <td><b>TR : </b>{{twoDechoReportDetails.data.COLOUR_DOPPLER.TR}}</td>
                                    </tr>
                                </thead>
                                <thead>
                                    <tr>
                                        <td><b>AR : </b>{{twoDechoReportDetails.data.COLOUR_DOPPLER.AR}}</td>
                                        <td><b>PR : </b>{{twoDechoReportDetails.data.COLOUR_DOPPLER.PR}}</td>
                                    </tr>
                                </thead>
                            </table>


                            <div class="col-md-12 form-group" style="margin-top: 5px;">

                                <label for="inputStandard" class="control-label top-pad-0">
                                    <h4 class="fs14 "><b>OTHERS : </b></h4>
                                </label>
                                <div class="col-lg-8 col-md-6">
                                    <div class="bs-component multi_lines_text">
                                        {{twoDechoReportDetails.data.OTHERS}}</div>
                                </div>
                            </div>
                            <div class="col-md-12 form-group" style="margin-top: 5px;">

                                <label for="inputStandard" class="control-label top-pad-0">
                                    <h4 class="fs14 "><b>CONCLUSION : </b></h4>
                                </label>
                                <div class="col-lg-8 col-md-6">
                                    <div class="bs-component multi_lines_text">
                                        {{twoDechoReportDetails.data.CONCLUSION}}</div>
                                </div>
                            </div>

                            <div class="form-group pull-right" style="margin-top: 5px;">


                                <div class="col-lg-12 col-md-12">
                                    <div class="bs-component">
                                        <b>Dr GOKUL REDDY MANDALA</b> </div>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>


            </div>
        </div>
        <div class="row bg-gray" style="text-align: center;">

            <button (click)="printPage('printdiv')" class="btn btn-default print upper submitBtn"
                style="background: #367fa9;">Print </button>

        </div>
    </div>


    <style type="text/css" media="print">
        @media print {


            #get-quote,
            .upper {
                display: none;
            }
        }
        @page { size: auto;  margin: 0mm; }
        body  { margin: 1.0cm; }


    </style>

</body>