import { Injectable,Injector } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { ApicallsService } from '../services/apicalls.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor{

  constructor(private apicalls:ApicallsService) { }

  intercept(req,next){
    let tokenizedReq =req.clone({
      setHeaders:{
        Autorization:`${this.apicalls.getToken()}`
      }
    });
   // console.log(tokenizedReq);
    return next.handle(tokenizedReq);
  }
}
