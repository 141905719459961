import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Component({
  selector: 'app-updateappointments',
  templateUrl: './updateappointments.component.html',
  styleUrls: ['./updateappointments.component.css']
})

export class UpdateappointmentsComponent implements OnInit {
  datePickerConfig: Partial<BsDatepickerConfig>;

  constructor(private spinner: NgxSpinnerService, private httpclient: HttpClient, private formBuilder: FormBuilder, private apicalls: ApicallsService, private route: Router, private router: ActivatedRoute) {


    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumber: false,
        dateInputFormat: 'DD-MM-YYYY'

      }
    );
  }
  userDetails

  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data


    this.router.paramMap.subscribe(params => {
      const AppointmentID = params.get('id');
      if (AppointmentID) {

        this.getAppointmentID(AppointmentID);
      }
    });





  }
  Appointmentdetails;
  prescirptiontype;
  prescirption;
  getAppointmentID(AppointmentID) {

    this.apicalls.viewAppointmentById(AppointmentID).subscribe(

      data => {
        this.spinner.show();

        if (data.type == true) {
          if (data.code == "S001") {
            this.Appointmentdetails = data.data;
            this.prescirptiontype = this.Appointmentdetails["Type"];
            this.prescirption = this.Appointmentdetails[this.prescirptiontype]
            
            setTimeout(() => {
              this.spinner.hide();
            }, 500);
          }
          else if (data.code == "PD01") {
            alert("Permissions Denied");
            return;
          }
          else if (data.code == "UA01") {
            alert("Your UnAuthorized to Access this Service");
            return;
          }


        } else {
          alert(data.message);
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ")
      });

  }
  Next;
  printPage(comp) {
    console.log(document.body.scrollHeight)
    this.Next="false"
    var rect = document.getElementById(comp).getBoundingClientRect();
    console.log(rect)
var height = rect.height;
console.log(height)
if(height >1200){
  this.Next="true";

}
    // let printContents = document.getElementById(comp).innerHTML;
    //  let originalContents = document.body.innerHTML;


    //  document.body.innerHTML = printContents;
    
    var originalTitle = document.title;
    var url = document.URL;
  //  console.log(url)
    var curURL = window.location.href;
history.replaceState(history.state, '', '/');
document.title = "Print";
window.print();
history.replaceState(history.state, '', curURL);

   // window.print();

    //  document.body.innerHTML = originalContents;

  }






  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }


  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }

}



