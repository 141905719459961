<div id="page" class="side-nav-wrapper">
    <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
        <div class="container">
            <div class="row">
                <div class="col-md-2 pull-right" style="margin-right: 10px;">
                    <div class="dropdown">
                        <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                            style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                            <span class="caret"></span></button>
                        <ul class="dropdown-menu">
                            <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                            <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                            <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                    userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                    class="">Settings</a></li>
                            <li
                                *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                <a [routerLink]="" (click)="users()" class="">Users</a></li>

                        </ul>
                    </div>
                </div>

            </div>

        </div>
    </div>


    <div class="container bg-gray">

        <div class="row orr-usercreation ptop30 pbtom30">

            <div class="col-md-12">


                <div class="panel">
                    <div style="text-align: center; color:#367fa9;">
                        <h4 style=" font-weight: bold;">
                            Patients By Condition
                        </h4>
                        <div class="col-md-6">
                            <div class="input-text form-group has-feedback">
                                <label style="width:180px;float:left;text-align:right;padding-right:15px;">Select
                                    Condition<span class="required"> * </span></label>
                                <select class="way-custom-dropdown price-text-field profile-mwidth form-control"
                                    style="background-color:#fff;margin-bottom:20px; width: 250px;" id="Type"
                                    name="Type" [(ngModel)]="Type" #TypeSelect
                                    (change)="onTypeChange(TypeSelect.value)">
                                    <option value="" disabled>Select Condition</option>
                                    <option value="CHF">CHF</option>
                                    <option value="CAD">CAD</option>
                                    <option value="HTN_NO_CAD_CHF">HTN NO CAD CHF</option>
                                    <option value="DM_NO_CAD_CHF">DM NO CAD CHF</option>
                                    <option value="HCM">HCM</option>
                                    <option value="PAH">PAH</option>
                                    <option value="CRHD">CRHD</option>
                                    <option value="HLP_WITH_NO_CAD">HLP WITH NO CAD</option>
                                    <option value="Chest_Pain">Chest Pain</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div style="text-align:right;margin-bottom:0px;margin-top:0px;margin-right: 10px;">
                        <a [routerLink]="" (click)="ExcelReport()" class="btn btn-info btn-lg">
                            <span type="submit" class="glyphicon glyphicon-download-alt" value=""> </span>Download</a>
                    </div>

                    <div class="col-md-12" style="margin-left: 20px;">
                        <label>Search with Patient's Name or MR Number</label>
                        <label style="padding-left: 425px;">No of records per page</label>
                    </div>

                    <div class="row" style="margin-left: 20px;">
                        <div class="col-md-3">
                            <div class="input-text form-group has-feedback">
                                <i class="fa fa-search"
                                    style="font-size:24px;padding-top: 9px;padding-right: 9px;"></i><input type="text"
                                    class="form-control" placeholder=" Enter text here to search " [(ngModel)]="term">
                            </div>
                        </div>

                        <div class="col-md-3" style="margin-left: 500px;margin-right: 0px;">
                            <div class="form-group">
                                <select class="way-custom-dropdown price-text-field profile-mwidth form-control"
                                    id="limit" name="limit" style="width: 50px;" #Selectedlimit
                                    (change)='onLimit(Selectedlimit.value)'>
                                    <option selected value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <table cellspacing="0" cellpadding="0" width="100%" class="mirchi-birthday-grid" id="example">
                        <thead>
                            <tr>
                                <th style="text-align:center;"> S.No</th>
                                <th style="text-align:center;"> Appointment ID </th>
                                <th style="text-align: center;">MR Number</th>
                                <th style="text-align: center;">Contact Number</th>
                                <th style="text-align:center;"> Patient Name</th>

                                <th style="text-align: center;">Appointment Date</th>


                                <!-- <th style="text-align:center;"> Actions</th> -->


                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let list of collection | paginate: config|filter:term; let i=index ">
                                <td style="text-align:center;">{{i + 1}}</td>
                                <td style="text-align:center;">{{list.AppointmentID}}</td>
                                <td style="text-align:center;">{{list.MRNumber}}</td>
                                <td style="text-align:center;">{{list.ContactNumber}}</td>
                                <td style="text-align:center;">{{list.PatientName}}</td>
                                <td style="text-align:center;">{{list.Date*1000|date:"dd-MM-yyyy"}}</td>
                                <!-- <td style="text-align:center;">{{list.PaymentStatus}}</td>
                                <td style="text-align:center;">{{list.Status}}</td> -->

                                <!-- <td>
                                    <a [routerLink]="" (click)="editprescription(list.AppointmentID)">
                                        <img src="http://drgokulreddy.in/assets/img/prescription-icon.png">
                                    </a>
                                    <a [routerLink]="" *ngIf="list.Type"
                                        (click)="PrintPrescription(list.AppointmentID)"><img
                                            src="../assets/images/print2.png" width="25px" height="25px"
                                            alt="prescription Print"></a>
                                    <a *ngIf="!list.Type" [disabled]="!list.Type"><img src="../assets/images/print.png"
                                            width="25px" height="25px" alt="No prescription"></a>

                                </td> -->



                            </tr>

                        </tbody>
                    </table>


                    <pagination-controls (pageChange)="pageChange($event)" class="my-pagination">
                    </pagination-controls>



                </div>
            </div>
        </div>

    </div>
</div>

<ngx-spinner></ngx-spinner>