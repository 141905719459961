import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';

@Component({
  selector: 'app-viewallmyappointments',
  templateUrl: './viewallmyappointments.component.html',
  styleUrls: ['./viewallmyappointments.component.css']
})
export class ViewallmyappointmentsComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService,private apicalls: ApicallsService, private router: ActivatedRoute, private route: Router, private httpClient: HttpClient) { }
 
  viewallpatientsappointments;
  
  // dtOptions: DataTables.Settings = {};
  // dtTrigger: Subject<any> = new Subject();
  userDetails
  ngOnInit() {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data
    // let ResutedLocalStorage = this.apicalls.getToken();
  
    // let LocalStoragetoken = ResutedLocalStorage['data'];
  
    // let LocalStorageAuthObject = LocalStoragetoken.role_type;

   
    // if(LocalStorageAuthObject =="Student"){
    //   alert("Resticred aacess navigating to home page");
    //   this.route.navigate(["/dashboard/student_welcomepage"]);
    //   return;
    // }


   // console.log("Users details are");
    // this.dtOptions = {
    //   pageLength: 6,
    //   stateSave: true,
    //   lengthMenu: [[5, 10, -1], [5, 10, "All"]],
    //   processing: true
    // };
    this.apicalls.viewAllPatientsAppointments('MRND00000001')
          .subscribe(data => {
        this.spinner.show();
        if(data.type==true){
        

          setTimeout(() => {
          
            this.spinner.hide();
          }, 500);
          this.viewallpatientsappointments = data;
       console.log("viewallpatientsappointments............",this.viewallpatientsappointments);
      //  this.dtTrigger.next();
      
      }else{
        
      //  this.alertService.info(data.message)
       // this.spinner.hide();

       this.spinner.hide();
        
        if(data.code=='PD01'){
          alert(data.data)
          this.route.navigate(["/dashboardmain/dashboard"]);

        }
        else{
        alert(data.data)
        }

      }
      },error => {
        error => { alert(" Internal Server Error "); }
       // this.alertService.warning(" Internal Server Error ");
      });

    }
    // onSubmitCreateBatch(){
    //   this.route.navigate(["/dashboard/batch_create"]);
    // }

    
    // onSubmitUpdate(list){
    // //  alert(" Edit batch option will be included soon..........")
    //  //  console.log("((((((((((",list);
    //    this.route.navigate(["/dashboard/view_batchbyid",list.batch_id,list.programme_id]);
       
    //  }
   
  
    onClickCreate(){
      this.route.navigate(["/dashboardmain/createpatients"]);
    }

    // dashboard(){
    //   this.route.navigate(["/dashboard"]);
    // }
  
    // Resources(){
    //   this.route.navigate(["/viewallresources"]);
    // }
  
    // Roles(){
    //   this.route.navigate(["/viewallroles"]);
    // }
    
    changePassword(){
      this.route.navigate(["/dashboardmain/changepassword"]);
    }
    logout(){
      this.route.navigate(["/logout"]);
    }
    Settings(){
      this.route.navigate(["/dashboardmain/configurations"]);
    }
    users() {
      this.route.navigate(["/dashboardmain/ViewAllUsers"]);
    }
    // editdata(MRNumber){

    //   this.route.navigate(["/dashboardmain/updatepatients",MRNumber]);
    // }
}


