import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';

@Component({
  selector: 'app-viewallappointments',
  templateUrl: './viewallappointments.component.html',
  styleUrls: ['./viewallappointments.component.css']
})
export class ViewallappointmentsComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private apicalls: ApicallsService, private router: ActivatedRoute, private route: Router, private httpClient: HttpClient) { }

  viewallappointments;
  appointments = []
  userDetails
  term: string = '';


  config: any;
  collection = [];
  pages;

  pageLimit = 10;
  onLimit(value: number) {
    //console.log("limit ", value);
    this.pageLimit = value;
    this.onPage();
  }

  ngOnInit() {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data


    this.config = {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0
    };
    this.onPage();

    // this.router.queryParamMap.subscribe(params => {
    //   this.config.currentPage = params.get('page')//params['page'] ? params['page'] : 1
    //   this.config.itemsPerPage = params['limit'] ? params['limit'] : this.pageLimit
    //   //this.config.totalItems= this.pages
    //   console.log(" !!!! ", this.config);
    //   //const limit = '5';
    //   this.apicalls.viewAllAppointmentsPagination(this.config.currentPage, this.config.itemsPerPage).subscribe(data => {
    //     this.spinner.show();
    //     if (data.type == true) {
    //       setTimeout(() => {
    //         this.spinner.hide();
    //       }, 500);
    //       if (data.code == "S001") {
    //         this.collection = [];
    //         //this.pages = data.data.pages;
    //         this.config.totalItems = data.data.TotalRecords//data.data.pages * this.config.itemsPerPage;
    //         for (var i = 0; i < data.data.appointments.length; i++) {

    //           this.collection.push(data.data.appointments[i]);
    //           this.appointments.push(data.data.appointments[i]);

    //         }
    //         //console.log(" coll ", this.collection);

    //       } else if (data.code == "UA01") {
    //         alert("Your UnAuthorized to Access this Service");
    //         return;
    //       }
    //     } else {
    //       alert(data.data)
    //     }

    //   }, error => { alert(" Internal Server Error "); })
    // })
    // let result = this.appointments.sort((a, b) => {
    //   return <any>new Date(b.Date) - <any>new Date(a.Date);
    // });
  }

  onPage() {

    this.router.queryParamMap.subscribe(params => {
      this.config.currentPage = params.get('page')//params['page'] ? params['page'] : 1
      this.config.itemsPerPage = params['limit'] ? params['limit'] : this.pageLimit
      //console.log(" !!!! ", this.config);
      this.apicalls.viewAllAppointmentsPagination(this.config.currentPage, this.config.itemsPerPage).subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
          if (data.code == "S001") {
            this.collection = [];
            this.config.totalItems = data.data.TotalRecords//data.data.pages * this.config.itemsPerPage;
            for (var i = 0; i < data.data.appointments.length; i++) {
              this.collection.push(data.data.appointments[i]);
              this.appointments.push(data.data.appointments[i]);
            }
          } else if (data.code == "UA01") {
            alert("Your UnAuthorized to Access this Service");
            return;
          }
        } else {
          alert(data.data)
        }

      }, error => { alert(" Internal Server Error "); })
    })
    let result = this.appointments.sort((a, b) => {
      return <any>new Date(b.Date) - <any>new Date(a.Date);
    });
  }



  pageChange(newPage: number) {
    this.route.navigate(['/dashboardmain/appointments'], { queryParams: { page: newPage } });
  }

  get sortData() {
    let result = this.appointments.sort((a, b) => {
      return <any>new Date(b.Date) - <any>new Date(a.Date);
    });
    console.log(result);
    return result;
  }

  onClickCreate() {
    this.route.navigate(["/dashboardmain/createappointments"]);
  }


  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }

  PrintPrescription(AppointmentID) {

    this.route.navigate(["/dashboardmain/updateappointments", AppointmentID]);
  }
  editprescription(AppointmentID) {
    this.route.navigate(["/dashboardmain/updateappointmentsprescriptions", AppointmentID]);
  }
  UploadHelathReports(AppointmentID) {
    this.route.navigate(["/dashboardmain/UploadHelathReports", AppointmentID]);
  }
  viewAllHealthReports(AppointmentID, MRNumber) {
    this.route.navigate(["/dashboardmain/ViewAllHelathReports", AppointmentID, MRNumber]);
  }
  todaysAppointments() {
    this.route.navigate(["/dashboardmain/TodaysAppointments"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }
}



