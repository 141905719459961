import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ApicallsService } from '../../services/apicalls.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {

  authObject: number;
  details: any;
  authObject1: number;
  details1: any;
  authObject2: any;
  authObjectemailid: any;
  authObjectstudemailid: any;
  registersuccess;
  Isregistered: boolean = false;
  dataStatus;

  isSubmitted: boolean = true;
  changepasswordError: string;

  constructor(private spinner: NgxSpinnerService, private formBuilder: FormBuilder, private httpclient: HttpClient, private route: Router, private apicalls: ApicallsService, private router: ActivatedRoute) { }

  userDetails
  ngOnInit() {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data

    this.spinner.show();
    setTimeout(() => {

      this.spinner.hide();
    }, 500);

    // let result1 = this.apicalls.getToken();
    //  console.log(result1);
    // let token = result1['data'];
    //  console.log("==========token data", token);
    //let authObjectrole = token.role_type;


    // this.changePasswordForm.patchValue({

    //   UserID: token.UserID
    // });


  }
  onSubmit(changePasswordForm) {
    // if (this.changePasswordForm.valid) {
    //  console.log("Form Submitted!");
    console.log("**********8", this.changePasswordForm.value);

    let result = this.apicalls.changePassword(this.changePasswordForm.value)

      .subscribe(
        data => {
          this.spinner.show();
          if (data.type == true) {
            if (data.code == 'P001') {
              // setTimeout(() => {
              //   /** spinner ends after 5 seconds */
              //   this.spinner.hide();
              // }, 500);
              //   alert(data.message)
              //  this.dataStatus=data.status;
              //     this.registersuccess=data.message;
              //     this.Isregistered=false;
              this.spinner.hide();
              alert(data.data);
              console.log("Password Successfully Changed", data.data);
              //
              this.route.navigate(["/login"]);
            }
            else {
              // this.dataStatus=data.status;
              this.isSubmitted = false;
              this.changepasswordError = data.data;
              this.spinner.hide();
              alert(data.data);
              this.route.navigate(["/dashboard/changepassword"]);
              return false;
              // this.registersuccess=data.message;
              // this.Isregistered=false;
              this.spinner.hide();
              //     this.CurrentPassword.reset()
              // alert(data.message);
              // // setTimeout(() => {
              // /** spinner ends after 5 seconds */
              // this.spinner.hide();
              // // }, 500);


              this.route.navigate(["/dashboard/changepassword"]);
            }
          } else {
            this.spinner.hide();
            alert(data.data);
            console.log("^^^^^^^^^^^^^^", data.data)
            this.route.navigate(["/login"]);
          }
        }, error => {
          alert(" Internal Server Error ");
        }
      );

  }

  // get UserID() {
  //   return this.changePasswordForm.get('UserID');
  // }

  get CurrentPassword() {
    return this.changePasswordForm.get('CurrentPassword');
  }

  get NewPassword() {
    return this.changePasswordForm.get('NewPassword');
  }
  changePasswordForm = new FormGroup({

    // UserID: new FormControl(''),
    CurrentPassword: new FormControl('', [Validators.required]),
    NewPassword: new FormControl('', [Validators.required, Validators.minLength(6)])
  });

  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }
  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }

}
