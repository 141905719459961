
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl, FormArray, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-updatepermissions',
  templateUrl: './updatepermissions.component.html',
  styleUrls: ['./updatepermissions.component.css']
})
export class UpdatepermissionsComponent implements OnInit {

  permissiondetails;

  viewallresources;
  viewallroles;
  viewallchannelpartners;
  viewallprivileges;
  dropdownSettings = {};
  dropdownList = [];
  constructor(private spinner: NgxSpinnerService, private httpclient: HttpClient, private formBuilder: FormBuilder, private apicalls: ApicallsService, private route: Router, private router: ActivatedRoute) { }
  detailsResource;
  ResourceName;
  userDetails;
  ngOnInit(): void {

    const data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data
    this.router.paramMap.subscribe(params => {
      const PermissionID = params.get('id');
      if (PermissionID) {

        this.getPermissionId(PermissionID);
      }
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'PrivilegeID',
      textField: 'PrivilegeName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    }

    this.apicalls.viewAllResources()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {


          setTimeout(() => {

            this.spinner.hide();
          }, 500);
          this.viewallresources = data;

        } else {

          this.spinner.hide();

        }
      }, error => {
        error => { alert(" Internal Server Error "); }
      });


    this.apicalls.viewAllRoles()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {


          setTimeout(() => {

            this.spinner.hide();
          }, 500);
          this.viewallroles = data;

        } else {

          this.spinner.hide();

        }
      }, error => {
        error => { alert(" Internal Server Error "); }
      });

    this.apicalls.viewAllChannelPartners()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {


          setTimeout(() => {

            this.spinner.hide();
          }, 500);
          this.viewallchannelpartners = data;

        } else {

          this.spinner.hide();

        }
      }, error => {
        error => { alert(" Internal Server Error "); }
      });


    this.apicalls.viewAllPrivileges()
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {


            this.viewallprivileges = data;
            let tmp = [];
            for (let j = 0; j < this.viewallprivileges.data.length; j++) {
              tmp.push({ PrivilegeID: data.data[j].PrivilegeID, PrivilegeName: data.data[j].Privilege, PrivilegeAliasName: data.data[j].PrivilegeAliasName });

            }
            this.dropdownList = tmp;
            this.spinner.hide();
          }

          if (data.code == 'V002') {

            alert(data.data);
            this.spinner.hide();
          }

        } else {
          alert(data.data)
          //  this.alertService.info(data.message)
          this.spinner.hide();

        }
      },
        error => {
          alert(" Internal Server Error ");
          // this.alertService.warning(" Internal Server Error ");
        });


  }

  data: '';
  pathurl: string = 'GokulClinic';
  isSubmitted: boolean = true;
  //loggedIn:boolean=true;
  channelError: string;
  details;
  RoleName;
  channeldetails;
  channelname;
  channelaliasname;
  channelid;
  PermissionArray = [];
  PermissionArray1 = [];
  channelobj = {
    ChannelPartnerID: "",
    ChannelPartnerName: "",
    Privileges: []
  }



  get ResourceID() {
    return this.permissionsForm.get('ResourceID');
  }
  get RoleID() {
    return this.permissionsForm.get('RoleID');
  }

  get ChannelPartnerID() {
    return this.permissionsForm.get('ChannelPartnerID');
  }
  get PrivilegeID() {
    return this.permissionsForm.get('PrivilegeID');
  }

  get ChannelID() {
    return this.permissionsForm.get('ChannelID');
  }

  permissionsForm = new FormGroup({
    Resource: new FormControl(''),
    ResourceID: new FormControl('', [Validators.required]),
    Role: new FormControl(''),
    //ChannelID: new FormControl(''),
    PrivilegeID: new FormControl(''),
    RoleID: new FormControl('', [Validators.required]),
    Privileges: new FormArray([
      this.addPrivilegesFormGroup()
    ]),
    // ChannelPartners: new FormArray([
    //   this.addChannelPartnerFormGroup()
    // ])

  });

  addChannelPartnerFormGroup(): FormGroup {
    return this.formBuilder.group({

      ChannelPartnerID: new FormControl('', [Validators.required]),
      ChannelPartnerName: new FormControl(''),
      Privileges: new FormArray([
        this.addPrivilegesFormGroup()
      ])


    });
  }
  addPrivilegesFormGroup(): FormGroup {
    return this.formBuilder.group({

      PrivilegeID: new FormControl('', [Validators.required]),
      PrivilegeAliasName: new FormControl('', [Validators.required]),
      PrivilegeName: new FormControl('', [Validators.required]),


    });
  }

  obj = [];
  obj1 = [];
  selectedItems = [];
  selectPrivilege(value) {
    this.obj = this.selectedItems
  }

  onItemSelect(value: any) {
  }
  onSelectAll(dropdownList) {
    this.obj = this.dropdownList
  }
  selectPrivilegeType(value) {

  }
  onItemDeSelect(value) {
    this.obj = this.selectedItems
  }

  getPermissionId(PermissionID: string) {
    this.apicalls.viewPermissionsById(PermissionID).subscribe(
      data => {
        this.spinner.show();
        if (data.type == true) {
          this.permissiondetails = data
          setTimeout(() => {
            this.spinner.hide();
          }, 500);



          this.obj = this.permissiondetails.data.ChannelPartners[0].Privileges;

          for (let pre in this.obj) {
            delete this.obj[pre]._id;
            // this.obj[pre].Privilege = this.obj[pre].PrivilegeName.toUpperCase();
            //delete this.obj[pre].PrivilegeName;
          }

          this.selectedItems = this.obj;
          this.permissionsForm.patchValue({
            RoleID: this.permissiondetails.data.RoleID,
            Role: this.permissiondetails.data.Role,
            ResourceID: this.permissiondetails.data.ResourceID,
            //ChannelID: this.permissiondetails.data.ChannelPartners[0].ChannelPartnerID,
            Privileges: this.permissiondetails.data.ChannelPartners[0].Privileges,
            Status: this.permissiondetails.data.Status
          });
        } else {
          alert(data.data);
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ")
      });
  }



  onSubmit(permissionsForm) {

    if(this.selectedItems.length ==0){
      alert("Select Atleaset one Privilege")
      return;
    }
    this.obj=this.selectedItems; 

    for (let i = 0; i < this.obj.length; i++) {
      this.obj[i].PrivilegeID = this.obj[i].PrivilegeID;
      this.PermissionArray.push(this.obj[i]);
      this.PermissionArray1.push({ PrivilegeID: this.PermissionArray[i].PrivilegeID, PrivilegeName: this.PermissionArray[i].PrivilegeName,  });//PrivilegeName: this.PermissionArray[i].PrivilegeAliasName
    }


    for (let j = 0; j < this.viewallresources.data.length; j++) {
      if (this.permissionsForm.value.ResourceID == this.viewallresources.data[j].ResourceID) {
        this.permissionsForm.value.Resource = this.viewallresources.data[j].ResourceAliasName;
      }
    }


    for (let k = 0; k < this.viewallroles.data.length; k++) {
      if (this.permissionsForm.value.RoleID == this.viewallroles.data[k].RoleID) {
        this.permissionsForm.value.Role = this.viewallroles.data[k].RoleAliasName;
      }
    }



    // for (let l = 0; l < this.viewallchannelpartners.data.length; l++) {
    //   if (this.permissionsForm.value.ChannelID == this.viewallchannelpartners.data[l].ChannelID) {
    //     this.permissionsForm.value.channelname = this.viewallchannelpartners.data[l].Name;
    //   }
    // }

    //this.channelobj.ChannelPartnerID = this.permissionsForm.value.ChannelID;
    //this.channelobj.ChannelPartnerName = this.permissionsForm.value.channelname;
    this.channelobj.Privileges = this.PermissionArray1
    this.permissionsForm.value.ChannelPartners = []
    this.permissionsForm.value.ChannelPartners.push(this.channelobj);

    this.permissionsForm.value.Status = "Active";
    this.permissionsForm.value.PermissionID = this.permissiondetails.data.PermissionID


    this.spinner.show();

    let result = this.apicalls.updatePermissions(this.permissionsForm.value)
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {

          if (data.code == 'S001') {
            this.spinner.hide();
            alert(data.data)
            this.route.navigate(["/dashboardmain/permissions"]);
          }
          if (data.code == 'L005') {
            this.spinner.hide();
            alert(data.data)
            this.route.navigate(["/dashboardmain/permissions"]);
          }
          if (data.code == 'S002') {
            this.spinner.hide();
            alert(data.data);
            this.isSubmitted = false;
            return false;
          }

        }
        else {
          this.spinner.hide();
          this.isSubmitted = false;
          alert(data.data)
          this.channelError = data.data;

          return false;

        }

      },
        error => { alert(" Internal Server Error "); }
      );



  }

  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }
  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
}
