<!DOCTYPE html>
<!-- saved from url=(0031)http://drgokulreddy.in/patients -->
<html lang="en">

<body>
    <div id="page" class="side-nav-wrapper">
        <!-- Simple Navbar -->
        <!-- Simple Navbar -->
        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pull-right" style="margin-right: 10px;">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                                style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                                <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                                <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                    userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                        class="">Settings</a></li>
                                <li
                                    *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                    <a [routerLink]="" (click)="users()" class="">Users</a></li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="container bg-gray">
            <div class="row">
                <div class="col-md-4">
                    <h5 class="dr-sub-head"> Helath Reports</h5>
                </div>
            </div>

            <div class="btn-signin">
                <a (click)="UploadHelathReports(AppointmentID)" style="width: 165px;">
                    Upload Reports </a>
            </div>



            <div class="row orr-usercreation ptop30 pbtom30">
                <div class="col-md-12">
                    <div class="panel">
                        <div class="tab-table-customer-entry"
                            style="margin:30px 0;background:#fff;padding:10px 0;border-top:1px  #ddd;border-bottom:1px  #ddd;">


                            <div class="row">
                                <div class="col-md-4" *ngIf="PatientDetails !=null">
                                    <h5 class="text-left" style="padding-left: 50px;">
                                        Patient Name : {{PatientDetails.Name}}
                                    </h5>
                                </div>
                                <div class="col-md-4">
                                    <h5> MRNumber : {{PatientDetails.MRNumber}} </h5>
                                </div>
                                <div class="col-md-4">
                                    <h5> Contact Number : {{PatientDetails.ContactNumber}} </h5>
                                </div>

                            </div>


                            <div class="row" *ngIf="showform">
                                <form id="contact-form" class="contact-form pad-40" autocomplete="off"
                                    [formGroup]="healthReportForm">

                                    <div style="clear:both;"></div>
                                    <div class="col-md-6">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:200px;float:left;text-align:right;padding-right:5px;">Select
                                                Report Date <span class="required"> * </span></label>

                                            <div class="input-group date">
                                                <input type='text' class="form-control" placeholder="dd-mm-yyyy"
                                                    formControlName="ReportGeneratedDate" id="ReportGeneratedDate"
                                                    name="ReportGeneratedDate" #dp="bsDatepicker" bsDatepicker
                                                    [bsConfig]="datePickerConfig" placement="right"
                                                    style=" width: 280px;">
                                                <span class="input-group-addon">
                                                    <i class="glyphicon glyphicon-calendar" (click)="dp.toggle()"></i>
                                                </span>

                                            </div>
                                            <div style="text-align: center;">
                                                <small *ngIf="error && ReportGeneratedDate.errors?.required"
                                                    class="text-danger">
                                                    Report Date is required</small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="input-text form-group has-feedback">
                                            <label style="width:200px;float:left;text-align:right;padding-right:5px;">
                                                Select Report Files<span class="required"> * </span></label>
                                            <input formControlName="file" id="file" type="file" multiple
                                                class="form-control" (change)="onFileChange($event)" required>

                                            <small *ngIf="error && file.errors?.required" class="text-danger">Report
                                                file
                                                is required</small>
                                        </div>
                                    </div>

                                    <div style="clear:both;"></div>
                                    <div class="col-md-6">
                                        <div class="input-text form-group has-feedback">
                                            <label style="width:180px;float:left;text-align:right;padding-right:15px;">
                                                Report Name<span class="required"> </span></label>
                                            <input
                                                [class.is-invalid]="ReportName.invalid && (ReportName.dirty || ReportName.touched)"
                                                class="form-control" id="ReportName" type="text" name="ReportName"
                                                placeholder="report name" formControlName="ReportName">
                                            <small *ngIf="ReportName.errors?.pattern" class="text-danger">Report Name
                                                must be alphabets </small>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="input-text form-group has-feedback">
                                            <label style="width:180px;float:left;text-align:right;padding-right:15px;">
                                                Description<span class="required"> </span></label>
                                            <textarea
                                                [class.is-invalid]="Description.invalid && (Description.dirty || Description.touched)"
                                                class="form-control" id="Description" type="text" name="Description"
                                                placeholder=" Description " formControlName="Description"></textarea>
                                            <small *ngIf="Description.errors?.pattern" class="text-danger">Description
                                                must be alphabets </small>
                                        </div>
                                    </div>


                                    <div style="clear:both;"></div>
                                    <div
                                        style="margin:auto;text-align:center;margin-bottom:10px;margin-top:30px;margin-bottom:30px;">
                                        <a [routerLink]="" (click)="onSubmit(healthReportForm)"> <input type="submit"
                                                class="btn-pink" value="SAVE"></a>

                                    </div>

                                </form>
                            </div>



                            <div class="row">
                                <div class="col-md-4">
                                    <!-- <h5 class="text-left" style="padding-left: 50px;">
                                        Report ID : {{report.data.ReportID}}
                                    </h5> -->
                                </div>
                                <div class="col-md-4">
                                    <!-- <h5> Report Name : {{report.data.ReportName}} </h5> -->
                                </div>
                                <div class="col-md-4">
                                    <!-- <h5> Report Date :{{ReportDate}} </h5> -->
                                </div>
                            </div>
                            <div style="clear:both;"></div>

                            <div class="row" style="padding-left: 50px; padding-right: 50px;">

                                <div class="col-md-4" *ngFor="let report of reports">

                                    <!-- <img style="padding: 10px;" width="100%" src={{report}} /> -->


                                    <a href="#" (click)="show()">
                                        <img id="imageresource" src="{{report}}"
                                            style="padding:10px; width: 500px; height: 200px;">
                                    </a>
                                    <div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal"
                                        tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h4 class="modal-title" id="myModalLabel">Image preview</h4>
                                                </div>
                                                <div class="modal-body">
                                                    <img src="{{report}}" id="imagepreview"
                                                        style="width: auto; height: auto;">
                                                    <!-- https://therichpost.com/wp-content/uploads/2019/09/Angular-8-custom-accordion-working-example-364x225.png -->
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-default" data-dismiss="modal"
                                                        (click)="hide()">Close</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>











                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </div>
    </div>

</body>

</html>
<ngx-spinner></ngx-spinner>