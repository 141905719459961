import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../services/apicalls.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
@Component({
  selector: 'app-patientsbycategory',
  templateUrl: './patientsbycategory.component.html',
  styleUrls: ['./patientsbycategory.component.css']
})
export class PatientsbycategoryComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private httpclient: HttpClient, private formBuilder: FormBuilder,
    private apicalls: ApicallsService, private route: Router, private router: ActivatedRoute,
    private _router: Router) { }
  userDetails
  ngOnInit(): void {
    let data = this.apicalls.getToken();
    if (data == null) {
      this.route.navigate(["/logout"]);
    }
    this.userDetails = data.data.UserData.Data;

    this.config = {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0
    };
    this.route.navigate([], {
      relativeTo: this.router,
      queryParams: {
        page: 1
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: false
      // do not trigger navigation
    })


  }

  Type = "";
  patientdetails;
  term;

  config: any;
  collection = [];
  pageLimit = 10;
  onLimit(value: number) {
    this.pageLimit = value;
    this.onPage();
  }
  TempType;
  onTypeChange(Type) {
    this.patientdetails = []
    this.TempType = Type;
    //this.spinner.show();
    this.route.navigate([], {
      relativeTo: this.router,
      queryParams: {
        page: 1
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false
    })
    this.onPage();
  }


  onPage() {
    this.router.queryParamMap.subscribe(params => {
      this.config.currentPage = params.get('page')//params['page'] ? params['page'] : 1 //params.get('page')//
      this.config.itemsPerPage = params['limit'] ? params['limit'] : this.pageLimit
      //console.log(" @@ ", this.TempType, this.config);
      this.spinner.show();
      this.apicalls.viewPatientsByConditionPagination(this.TempType, this.config.currentPage, this.config.itemsPerPage).subscribe(data => {
        if (data.type === true) {
          if (data.code == 'S001') {
            this.collection = []
            this.config.totalItems = data.data.TotalRecords
            for (var i = 0; i < data.data.appointments.length; i++) {
              this.collection.push(data.data.appointments[i]);
            }
            this.spinner.hide();
          }
          else if (data.code == 'S002') {
            this.spinner.hide();
            alert(data.data);
          } else {
            this.spinner.hide();
            alert(data.data);
          }
        } else {
          this.spinner.hide();
          alert(data.data)
          return false;
        }
      }, error => {
        console.log(error)
        alert(" Internal Server Error ");
      });

    })
  }

  pageChange(newPage: number) {
    this.route.navigate(['/dashboardmain/PatientsbyCondition'], { queryParams: { page: newPage } });
  }

  ExcelReport() {

    if (!this.Type) {
      alert("Select Condition")
      return;
    }
    var URL = this.apicalls.apiURL + "/api/patient/viewPatientsByConditionReport" + "?" + "Type=" + this.Type
    window.open(URL);
  }
  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }
  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
}
