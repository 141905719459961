import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, Validators, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
@Component({
  selector: 'app-view-helath-reports',
  templateUrl: './view-all-helath-reports.component.html',
  styleUrls: ['./view-all-helath-reports.component.css']
})
export class ViewAllHelathReportsComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private httpclient: HttpClient, private formBuilder: FormBuilder,
    private apicalls: ApicallsService, private route: Router, private router: ActivatedRoute) { }

  PatientDetails;
  reports = [];
  AppointmentID;
  myFiles: string[] = [];
  datePickerConfig: Partial<BsDatepickerConfig>;
  userDetails;

  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data

    this.router.paramMap.subscribe(params => {
      const AppointmentID = params.get('AppointmentID');
      const MRNumber = params.get('MRNumber');
      if (AppointmentID) {
        this.getAppointmentID(AppointmentID);
      }
      this.apicalls.viewAllHealthReports(AppointmentID, MRNumber).subscribe(
        data => {
          this.spinner.show();
          if (data.type == true) {
            if (data.code == "S001") {
              setTimeout(() => {
                this.spinner.hide();
              }, 500);
              for (var i = 0; i < data.data.recordsdata.length; i++) {
                for (var j = 0; j < data.data.recordsdata[i].ReportsImageURLS.length; j++) {
                  this.reports.push(data.data.recordsdata[i].ReportsImageURLS[j])
                }
              }
            }
         
          }
          else {
            alert(data.message);
            this.spinner.hide();
          }
        }, error => {
          alert(" Internal Server Error ")
        });

    });

    this.datePickerConfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumber: false,
        dateInputFormat: 'DD-MM-YYYY'

      }
    );
  }

  Appointmentdetails;
  getAppointmentID(AppointmentID) {
    this.apicalls.viewAppointmentById(AppointmentID).subscribe(
      data => {
        this.spinner.show();
        if (data.type == true) {
          this.Appointmentdetails = data
          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinner.hide();
          }, 500);
          this.PatientDetails = this.Appointmentdetails.data.PatientDetails;
          this.AppointmentID = this.Appointmentdetails.data.AppointmentID

        } else {
          alert(data.message);
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ")
      });
  }

  onFileChange(event) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.myFiles.push(event.target.files[i]);
    }
  }


  healthReportForm = new FormGroup({

    ReportGeneratedDate: new FormControl('', Validators.required),
    file: new FormControl('', Validators.required),
    ReportName: new FormControl('', Validators.pattern('[a-zA-Z ]*')),
    Description: new FormControl('', Validators.pattern('[a-zA-Z ]*')),
  })


  get ReportGeneratedDate() {
    return this.healthReportForm.get('ReportGeneratedDate');
  }
  get file() {
    return this.healthReportForm.get('file');
  }
  get ReportName() {
    return this.healthReportForm.get('ReportName');
  }
  get Description() {
    return this.healthReportForm.get('Description');
  }
  error;
  onSubmit(healthReportForm) {
    if (this.healthReportForm.invalid) {
      this.error = true;
      return;
    }

    const formData = new FormData();
    formData.append("MRNumber", this.PatientDetails.MRNumber);
    formData.append("AppointmentID", this.AppointmentID);
    let ReportGeneratedDate = moment(this.healthReportForm.value.ReportGeneratedDate).format("DD-MM-YYYY");
    formData.append("ReportGeneratedDate", ReportGeneratedDate);
    formData.append("ReportName", this.healthReportForm.value.ReportName);
    formData.append("Description", this.healthReportForm.value.Description);

    //console.log(" @@@#### ", formData);
    for (var i = 0; i < this.myFiles.length; i++) {
      formData.append("Reports", this.myFiles[i]);
    }
    //return;
    this.apicalls.uploadReports(formData)
      .subscribe(data => {
        this.spinner.show();
        if (data.type == true) {
          if (data.code == 'S001') {
            this.spinner.hide();
            alert(data.data)
            this.route.navigate(["/dashboardmain/ViewAllHelathReports", this.AppointmentID, this.PatientDetails.MRNumber]);
            window.location.reload();
          }
          if (data.code == 'S002') {
            alert(data.data);
            this.spinner.hide();
          }
        } else {
          alert(data.data)
          this.spinner.hide();
        }
      }, error => {
        alert(" Internal Server Error ");
      });

  }





  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }
  showform = false;
  UploadHelathReports(AppointmentID) {
    //console.log(" @@@@@@@@@ ", AppointmentID)
    this.showform = true;
    // this.route.navigate(["/dashboardmain/ViewAllHelathReports", AppointmentID]);
  }

  showModal: boolean;
  show() {
    this.showModal = true; // Show-Hide Modal Check

  }
  //Bootstrap Modal Close event
  hide() {
    this.showModal = false;
  }
  Settings(){
    this.route.navigate(["/dashboardmain/configurations"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
}
