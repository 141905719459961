<app-header></app-header>

<nav class="navbar navbar-inverse">
    <div>
        <div style="position:relative;">
            <div>
                <div class="collapse navbar-collapse" id="myNavbar" style="float:left;padding:0px;clear:both;width:100%;background:#2d3e50;">
                    <div>
                        <ul class="nav navbar-nav ag-menu sm pl40" style="float:left;" data-smartmenus-id="14811307330159312">
                            <li class=""><a href="">HOME</a>
                            </li>
                            <li class="{{openlist}}"  (mouseover)='over()' (mouseout)='out()'><a href="" class="has-submenu">MYPRACTICE  </a>
                                <ul class="dropdown-menu sm-nowrap" >
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Dr Gokul reddy</a>
                                                    </div>
                                                    <div>
                                                        <a class="" routerLink="/practice-locations"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>Practice Locations</a>
                                                    </div>
                                                    <!-- <div>
                                                        <a class="" routerLink="/gokul-reddy-mandala"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> Book  appointment</a>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                            </li>
                            <li class="{{openlist1}}" (mouseover)='over1()' (mouseout)='out1()'><a class="has-submenu" href="#" >PATIENT INFO </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="page-links">
                                                    <div>
                                                        <a class="" routerLink="/about-cholesterol"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>ABOUT CHOLESTEROL</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/angioplasty"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> ANGIOPLASTY</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/chestpain"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CHESTPAIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/common-misconceptions"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>COMMON MISCONCEPTIONS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/coronary-angiogram"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> CORONARY ANGIOGRAM</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/exercsie-and-heart-health"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> EXERCSIE & HEART HEALTH</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/guide-to-taking-warfarin"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> GUIDE TO TAKING WARFARIN</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-attack"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTATTACK</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/heart-fauilre"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HEARTFAUILRE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/high-blood-pressure"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/> HIGH BLOOD PRESSURE</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/htn-myths"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>HTN-MYTHS</a>
                                                    </div>
                                                    <div>
                                                        <a  routerLink="/tips-to-lower"><img src="/../../assets/front/img/right-arrow.png" class="pr5"/>TIPS  TO LOWER CHOLESTEROL   LEVELS</a> </div>



                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li><a href="#">SERVICES</a></li>
                            <li><a href="#">APPOINTMENTS</a></li>
                            <li class=""><a  href="faq">FAQS </a></li>
                            <li class=""><a  href="blog">MY BLOG</a></li>

                            <li class=""> <a  href="reach-us">REACH US</a></li>

                            <div class="clearfix"></div>
                        </ul>
                        <div class="pull-right pn">
                            <a href="#"><img src="/../../assets/front/img/askdemo.png" style="height:52px;"/></a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</nav>

<section style="background:#fff;">

    <div class="container">

        <div  style="padding:30px 15px;">

            <div class="row">

                <h4 class="mb5" style="color: #0991cd;font-weight: bold;">EXERCSIE and HEART HEALTH</h4>


                <p>

                    Regular exercise not only burns calories and shapes muscles but also protects your heart.

                </p>



                <p>

                    Like other muscles, your heart becomes stronger with regular physical activity. As your cardiovascular fitness improves, your heart muscle doesn't have to work as forcefully to pump oxygen-rich blood through your body.

                </p>



                <p>Promoting efficient blood flow is just one way exercise helps your heart. You probably know that regular exercise at a moderate or vigorous intensity lowers the risk of heart disease and heart attack. And having a strong heart helps you cope with other stresses in life, whether physical or emotional.</p>



                <p>Any form of aerobic exercise, such as walking, running, biking or swimming, can improve your cardiovascular fitness. Interval training — alternating short bursts of high-intensity activity with less intense activity — is especially effective.</p>



                <p>Interval training challenges your heart by putting it into the maximal heart rate zone for short bursts of time. The maximum heart rate is the upper limit of what your cardiovascular system can handle during physical activity — an intensity that feels like you're working very hard.</p>



                <ul class="list_1">

                    <li>Between the high-intensity intervals, your heart rate comes back down into a lower heart rate zone, allowing for heart rate recovery.</li>

                    <li>Getting your heart rate back up after a short rest challenges your heart muscle in a way that makes it operate more efficiently.</li>



                </ul>



                <p>If you have a chronic health condition or haven't been exercising regularly, consult your doctor before trying interval training. The American College of Sports Medicine recommends that people have established a base level of fitness — exercising three to five times a week for 20 to 60 minutes — before beginning interval training.</p>



                <p>Resistance training, also called strength training, has benefits for your heart, too. Long-term resistance training can help lower blood pressure. Resistance training also increases muscle mass. This makes it easier for your body to burn calories and maintain a healthy weight, which helps keep your heart healthy.</p>





                <p><b><I> 1 .Try interval training. Find the method you like best, whether it's getting your heart rate up and down on the treadmill or doing bursts of plyometric exercises.</I></b></p>

                <p><b><I>2.Take a moment to remind yourself of your wellness goals and the benefits you'll reap from regular exercise. If heart health wasn't on your list, add it!</I></b></p>



                <p><b><I>3.Once a week, make exercise a social affair by working out with a friend, joining a walking club or trying a new activity.</I></b></p>











            </div>



        </div>

    </div>
</section>
<app-footer></app-footer>
