import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApicallsService } from '../../services/apicalls.service';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService, private apicalls: ApicallsService, private router: ActivatedRoute, private route: Router, private httpClient: HttpClient) { }
  viewallroles=[];
  AddressObj = {};

  userDetails
  ngOnInit(): void {
    let data = this.apicalls.getToken();
    this.userDetails = data.data.UserData.Data
    this.apicalls.viewAllRoles()
      .subscribe(data => {
        this.spinner.show();


        if (data.type == true) {
          this.spinner.hide();
          if(data.code =="S001"){
            for(var i=0;i<data.data.length;i++ ){
              if(data.data[i].Role !="DOCTOR" && data.data[i].Status=="Active"){
                this.viewallroles.push(data.data[i])
              }
            }
         
          }else if(data.code =="S002"){
            alert("No data found")
          }else if(data.code =="UA01"){
            alert("Your UnAuthorized to Access this Service")
          }else if(data.code =="PD01"){
            alert("Permissions Denied")
          }else{
            alert(data.data)
          }
          
        } else {
          this.spinner.hide();
        }
      }, error => {
        error => { alert(" Internal Server Error "); }
      });

  }

  changePassword() {
    this.route.navigate(["/dashboardmain/changepassword"]);
  }
  logout() {
    this.route.navigate(["/logout"]);
  }

  userForm = new FormGroup({
    FirstName: new FormControl('', [Validators.required, Validators.minLength(3), Validators.pattern('[a-zA-Z ]*')]),
    LastName: new FormControl('', [Validators.required, Validators.minLength(3), Validators.pattern('[a-zA-Z ]*')]),
    Age: new FormControl('', [Validators.required, Validators.pattern('[0-9][0-9]{1}')]),
    ContactNumber: new FormControl('', [Validators.required, Validators.pattern('[6789][0-9]{9}'), Validators.minLength(10), Validators.maxLength(10)]),
    RoleID: new FormControl('', [Validators.required]),
    EmailID: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z2-9.-]+\.[a-z]{2,4}$')]),
    StreetAddress: new FormControl('', [Validators.required]),
    Address: new FormGroup({
      StreetAddress: new FormControl(''),
    })
  });

  get FirstName() {
    return this.userForm.get('FirstName');
  }
  get LastName() {
    return this.userForm.get('LastName');
  }
  get Age() {
    return this.userForm.get('Age');
  }
  get ContactNumber() {
    return this.userForm.get('ContactNumber');
  }
  get EmailID() {
    return this.userForm.get('EmailID');
  }
  get RoleID() {
    return this.userForm.get('RoleID');
  }
  get StreetAddress() {
    return this.userForm.get('StreetAddress');
  }
  
  get Roles() {
    return this.userForm.get('Roles') as FormArray;
  }
  error;
  TempRoles = [];
  onSubmit(userForm) {
    if (this.userForm.invalid) {
      this.error = true;
      return;
    }
    this.spinner.show();
    this.userForm.value.Roles
    this.AddressObj = {
      StreetAddress: this.userForm.value.StreetAddress,
     
    };
    for (let k = 0; k < this.viewallroles.length; k++) {
      if (this.userForm.value.RoleID == this.viewallroles[k].RoleID) {
        if (this.viewallroles[k].Status == "Active") {
          this.TempRoles.push(this.viewallroles[k]);
        }
      }
    }
    this.userForm.value.Address = this.AddressObj;
    this.userForm.value.Roles = this.TempRoles;
    this.apicalls.createUser(this.userForm.value)
      .subscribe(data => {
        this.spinner.hide();
        if (data.type == true) {
          if (data.code == 'L005') {
            this.spinner.hide();
            alert(data.data)
            this.route.navigate(["/dashboardmain/ViewAllUsers"]);
          }
          if (data.code == 'S002') {
            alert(data.data);
            this.spinner.hide();
          }
        } else if(data.code =="UA01"){
          alert("Your UnAuthorized to Access this Service")
        }else if(data.code =="PD01"){
          alert("Permissions Denied")
        }else{
          alert(data.data)
        }
      }, error => {
        alert(" Internal Server Error ");
      });
  }
  Settings() {
    this.route.navigate(["/dashboardmain/configurations"]);
  }
  users() {
    this.route.navigate(["/dashboardmain/ViewAllUsers"]);
  }
}
