<body data-gr-c-s-loaded="true">
    <div id="page" class="side-nav-wrapper">

        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pull-right" style="margin-right: 10px;">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                                style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                                <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                                <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                    userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                        class="">Settings</a></li>
                                <li
                                    *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                    <a [routerLink]="" (click)="users()" class="">Users</a></li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="container bg-gray">
            <div class="row">
                <div class="col-md-12">
                    <h5 class="dr-sub-head">Create Hospital</h5>
                </div>
            </div>
            <div class="row orr-usercreation ptop30 pbtom30">

                <div class="col-md-12">
                    <!-- <h5 >Create Hospital</h5> -->
                    <div class="panel">
                        <h5>Create Hospital</h5>
                        <!-- <h5 style="margin-left:60px;text-align:left;padding-left:15px;margin:0px 0;padding:0px 0; ">Create Hospital</h5> -->

                        <div class="tab-table-customer-entry"
                            style="margin:30px 0;background:#fff;padding:10px 0;border-top:1px solid #ddd;border-bottom:1px solid #ddd;">
                            <!-- <div class="text-center fs20" *ngIf="channelError" [hidden]='isSubmitted'  style="background-color:white;color:red">{{channelError}}</div> -->

                            <!-- <form action="http://drgokulreddy.in/Home/add_user" onsubmit="return emp_entry()" method="post" enctype="multipart/form-data"> -->
                            <form id="contact-form" class="contact-form pad-40" autocomplete="off"
                                [formGroup]="hospitalForm">
                                <div class="form-pad-label" style="padding-top:30px;">

                                    <!-- <div class="col-md-6">
                            <div class="input-text form-group has-feedback">
                                <label style="width:100px;float:left;text-align:right;padding-right:15px;">USER NAME<span class="required"> * </span></label>
                                <input type="text" name="email" id="email" class="input-name form-control" placeholder=" User Name " style="float:left;width:70%;">
                            </div>
                            <span class="error" id="email_error"></span>
                        </div> -->


                                    <!-- <div style="clear:both;"></div> -->

                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Hospital
                                                Name<span class="required"> * </span></label>
                                            <input
                                                [class.is-invalid]="HospitalName.invalid && (HospitalName.dirty || HospitalName.touched)"
                                                class="form-control" id="HospitalName" type="HospitalName"
                                                name="HospitalName" placeholder=" Hospital Name "
                                                formControlName="HospitalName">
                                            <div
                                                *ngIf="HospitalName.invalid  && (HospitalName.dirty || HospitalName.touched)">
                                                <small *ngIf="HospitalName.errors?.required"
                                                    class="text-danger">Hospital Name is
                                                    required</small>
                                                <small *ngIf="HospitalName.errors?.minlength"
                                                    class="text-danger">Hospital Name should be minimum 3 characters
                                                </small>
                                                <small *ngIf="HospitalName.errors?.pattern" class="text-danger">Hospital
                                                    Name should be alphabets
                                                </small>

                                            </div>
                                            <!-- <input type="text" name="Resource" id="Resource" class="input-name form-control" placeholder=" Resource Name " style="float:left;width:70%;"> -->
                                        </div>
                                        <!-- <span class="error" id="email_error"></span> -->
                                    </div>
                                    <div style="clear:both;"></div>
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Hospital
                                                Branch<span class="required"> * </span></label>
                                            <input
                                                [class.is-invalid]="HospitalBranch.invalid && (HospitalBranch.dirty || HospitalBranch.touched)"
                                                class="form-control" id="HospitalBranch" type="HospitalBranch"
                                                name="HospitalBranch" placeholder=" Hospital Branch "
                                                formControlName="HospitalBranch">
                                            <div
                                                *ngIf="HospitalBranch.invalid  && (HospitalBranch.dirty || HospitalBranch.touched)">
                                                <small *ngIf="HospitalBranch.errors?.required"
                                                    class="text-danger">Hospital Branch is
                                                    required</small>
                                                <small *ngIf="HospitalBranch.errors?.minlength"
                                                    class="text-danger">Hospital Branch should be minimum 3 characters
                                                </small>
                                                <small *ngIf="HospitalName.errors?.pattern" class="text-danger">Hospital
                                                    Name should be alphabets
                                                </small>
                                            </div>
                                            <!-- <input type="text" name="Resource" id="Resource" class="input-name form-control" placeholder=" Resource Name " style="float:left;width:70%;"> -->
                                        </div>
                                        <!-- <span class="error" id="email_error"></span> -->
                                    </div>
                                    <div style="clear:both;"></div>
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Phone
                                                Number<span class="required"> * </span></label>
                                            <input
                                                [class.is-invalid]="ContactNumber.invalid && (ContactNumber.dirty || ContactNumber.touched)"
                                                class="form-control" id="ContactNumber" type="ContactNumber"
                                                name="ContactNumber" placeholder=" Phone Number "
                                                formControlName="ContactNumber">
                                            <!-- <div *ngIf="submitted && f.mobile_number.errors" class="text-danger">
                                                                <small *ngIf="f.mobile_number.errors.required">Mobile Number is required</small>
                                                                <small *ngIf="f.mobile_number.errors?.minlength" >Enter valid number(min 10)</small>
                                                                <small *ngIf="f.mobile_number.errors?.maxlength" >Enter valid number(min 10)</small>


                                                                <small *ngIf="f.mobile_number.errors?.pattern" >Enter valid mobile number</small>
                                                            </div> -->
                                            <div
                                                *ngIf="ContactNumber.invalid  && (ContactNumber.dirty || ContactNumber.touched)">
                                                <small *ngIf="ContactNumber.errors?.required" class="text-danger">Phone
                                                    Number is
                                                    required</small>
                                                <small *ngIf="ContactNumber.errors?.pattern" class="text-danger">Please
                                                    enter valid Phone Number</small>
                                                <small *ngIf="ContactNumber.errors?.minlength" class="text-danger">Phone
                                                    Number should be 10 digits</small>
                                                <small *ngIf="ContactNumber.errors?.maxlength" class="text-danger">Phone
                                                    Number should be 10 digits</small>

                                            </div>
                                            <!-- <input type="text" name="Resource" id="Resource" class="input-name form-control" placeholder=" Resource Name " style="float:left;width:70%;"> -->
                                        </div>
                                        <!-- <span class="error" id="email_error"></span> -->
                                    </div>
                                    <div style="clear:both;"></div>

                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Alternate
                                                Phone Number<span class="required"> * </span></label>
                                            <input
                                                [class.is-invalid]="SecondryContactNumber.invalid && (SecondryContactNumber.dirty || SecondryContactNumber.touched)"
                                                class="form-control" id="SecondryContactNumber"
                                                type="SecondryContactNumber" name="SecondryContactNumber"
                                                placeholder=" Alternate Phone Number "
                                                formControlName="SecondryContactNumber">

                                            <div
                                                *ngIf="SecondryContactNumber.invalid  && (SecondryContactNumber.dirty || SecondryContactNumber.touched)">
                                                <small *ngIf="SecondryContactNumber.errors?.required"
                                                    class="text-danger">Phone Number is
                                                    required</small>
                                                <small *ngIf="SecondryContactNumber.errors?.pattern"
                                                    class="text-danger">Please enter valid Phone Number</small>
                                                <small *ngIf="SecondryContactNumber.errors?.minlength"
                                                    class="text-danger">Phone Number should be 10 digits</small>
                                                <small *ngIf="SecondryContactNumber.errors?.maxlength"
                                                    class="text-danger">Phone Number should be 10 digits</small>

                                            </div>
                                            <!-- <input type="text" name="Resource" id="Resource" class="input-name form-control" placeholder=" Resource Name " style="float:left;width:70%;"> -->
                                        </div>
                                        <!-- <span class="error" id="email_error"></span> -->
                                    </div>
                                    <div style="clear:both;"></div>

                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <!-- <label style="width:100px;float:left;text-align:right;padding-right:15px;">Street Address<span class="required"> * </span></label> -->

                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Street
                                                Address<span class="required"> * </span></label>
                                            <input
                                                [class.is-invalid]="StreetAddress.invalid && (StreetAddress.dirty || StreetAddress.touched)"
                                                class="form-control" id="StreetAddress" type="StreetAddress"
                                                name="StreetAddress" placeholder=" Street Address "
                                                formControlName="StreetAddress">
                                            <div
                                                *ngIf="StreetAddress.invalid  && (StreetAddress.dirty || StreetAddress.touched)">
                                                <small *ngIf="StreetAddress.errors?.required" class="text-danger">Street
                                                    Address is
                                                    required</small>

                                            </div>
                                            <!-- <input type="text" name="Resource" id="Resource" class="input-name form-control" placeholder=" Resource Name " style="float:left;width:70%;"> -->
                                        </div>
                                        <!-- <span class="error" id="email_error"></span> -->
                                    </div>
                                    <div style="clear:both;"></div>
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Colony
                                                <span class="required"> * </span></label>
                                            <input
                                                [class.is-invalid]="Colony.invalid && (Colony.dirty || Colony.touched)"
                                                class="form-control" id="Colony" type="Colony" name="Colony"
                                                placeholder=" Colony " formControlName="Colony">
                                            <div *ngIf="Colony.invalid  && (Colony.dirty || Colony.touched)">
                                                <small *ngIf="Colony.errors?.required" class="text-danger">Colony is
                                                    required</small>

                                            </div>
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Locality
                                                <span class="required"> * </span></label>
                                            <input
                                                [class.is-invalid]="Locality.invalid && (Locality.dirty || Locality.touched)"
                                                class="form-control" id="Locality" type="Locality" name="Locality"
                                                placeholder=" Locality " formControlName="Locality">
                                            <div *ngIf="Locality.invalid  && (Locality.dirty || Locality.touched)">
                                                <small *ngIf="Locality.errors?.required" class="text-danger">Locality is
                                                    required</small>

                                            </div>
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>

                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">City
                                                <span class="required"> * </span></label>
                                            <input [class.is-invalid]="City.invalid && (City.dirty || City.touched)"
                                                class="form-control" id="City" type="City" name="City"
                                                placeholder=" City " formControlName="City">
                                            <div *ngIf="City.invalid  && (City.dirty || City.touched)">
                                                <small *ngIf="City.errors?.required" class="text-danger">City is
                                                    required</small>

                                            </div>
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">PinCode
                                                <span class="required"> * </span></label>
                                            <input
                                                [class.is-invalid]="PinCode.invalid && (PinCode.dirty || PinCode.touched)"
                                                class="form-control" id="PinCode" type="PinCode" name="PinCode"
                                                placeholder=" PinCode " formControlName="PinCode">
                                            <div *ngIf="PinCode.invalid  && (PinCode.dirty || PinCode.touched)">
                                                <small *ngIf="PinCode.errors?.required" class="text-danger">PinCode is
                                                    required</small>
                                                <small *ngIf="PinCode.errors?.pattern" class="text-danger">please enter
                                                    valid PinCode</small>


                                            </div>
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">State
                                                <span class="required"> * </span></label>
                                            <input [class.is-invalid]="State.invalid && (State.dirty || State.touched)"
                                                class="form-control" id="State" type="State" name="State"
                                                placeholder=" State " formControlName="State">
                                            <div *ngIf="State.invalid  && (State.dirty || State.touched)">
                                                <small *ngIf="State.errors?.required" class="text-danger">State is
                                                    required</small>

                                            </div>
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                    <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label
                                                style="width:180px;float:left;text-align:right;padding-right:15px;">Country
                                                <span class="required"> * </span></label>
                                            <input
                                                [class.is-invalid]="Country.invalid && (Country.dirty || Country.touched)"
                                                class="form-control" id="Country" type="Country" name="Country"
                                                placeholder=" Country " formControlName="Country">
                                            <div *ngIf="Country.invalid  && (Country.dirty || Country.touched)">
                                                <small *ngIf="Country.errors?.required" class="text-danger">Country is
                                                    required</small>

                                            </div>
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>

                                    <!-- <div class="col-md-8">
                                        <div class="input-text form-group has-feedback">
                                            <label style="width:100px;float:left;text-align:right;padding-right:15px;">AliasName<span class="required"> * </span></label>
                                            <textarea
                                            [class.is-invalid]="AliasName.invalid && (AliasName.dirty || AliasName.touched)"
                                            class="form-control" id="AliasName" type="AliasName" name="AliasName"
                                            placeholder=" AliasName " formControlName="AliasName"></textarea>
                                            <div *ngIf="AliasName.invalid  && (AliasName.dirty || AliasName.touched)">
                                                    <small *ngIf="AliasName.errors?.required" class="text-danger">AliasName is
                                                        required</small>
                                                        <small *ngIf="AliasName.errors?.pattern" class="text-danger">AliasName should me minimum 10 characters
                                                            </small>
                            
                                                </div>
                                        </div>
                                    </div> -->
                                    <!-- <div style="clear:both;"></div> -->

                                    <div
                                        style="margin:auto;text-align:center;margin-bottom:10px;margin-top:30px;margin-bottom:30px;">
                                        <a [routerLink]="" (click)="onSubmit(hospitalForm)"> <input type="submit"
                                                class="btn-pink" value="CREATE"></a>

                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>

            </div>
        </div>

        <script>

            var base_url = 'http://drgokulreddy.in/';
            function emp_entry() {

                var flag = 0;
                var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
                var alpha = /^[a-zA-Z\s]+$/;
                var email = $("#email").val();
                var regex = /^[0-9]*(?:\.\d{1,2})?$/;

                if ($("#name").val() == '') {
                    $("#name_error").html('Enter Name');
                    flag = 1;
                }
                else {
                    if (!alpha.test($("#name").val())) {
                        $("#name_error").html('Enter only Alphabets');
                        flag = 1;
                    }
                    else {
                        $("#name_error").html('');
                    }

                }
                if ($("#email").val() == '') {
                    $("#email_error").html('Enter email');
                    flag = 1;
                }
                else if (!pattern.test($("#email").val())) {
                    $("#email_error").html('Enter valid  Email');
                    flag = 1;
                }
                else {
                    $("#email_error").html('');
                }





                if ($("#pass").val() == '') {
                    $("#pass_error").html('Enter Password');
                    flag = 1;
                }
                else {
                    $("#pass_error").html('');

                }


                if ($("#utype").val() == '') {
                    $("#utype_error").html('Select User Type');
                    flag = 1;
                }
                else {
                    $("#utype_error").html('');

                }
                if (flag) {
                    $(".error").each(function () {
                        if ($(this).html().length > 0) {

                            var str = $(this).attr('id');
                            var res = str.split("_");
                            $("#" + res[0]).focus();
                            return false;
                        }

                    })
                    return false;
                }

            }


        </script>
        <script src="./GOKUL CLINIC - Add User page_files/jquery.dataTables.min.js.download"></script>
        <script src="./GOKUL CLINIC - Add User page_files/dataTables.bootstrap.min.js.download"></script>
        <style type="text/css">
            @media print {


                #get-quote,
                .upper {
                    display: none;
                }
            }
        </style>
        <script type="text/javascript">
            $('.print').click(function () {
                window.print();
                //printDiv();

            });
            function printDiv() {

                var divToPrint = document.getElementById('divtoprint');

                var newWin = window.open('', 'Print-Window');

                newWin.document.open();

                newWin.document.write('<html><body onload="window.print()">' + divToPrint.innerHTML + '</body></html>');

                newWin.document.close();

                setTimeout(function () { newWin.close(); }, 10);

            }
            $(document).ready(function () {
                $('#example').DataTable();
            });
            $(document).ready(function () {
                $(".alert").fadeTo(2000, 500).slideUp(500, function () {
                    $(".alert").slideUp(500);
                });
            });
        </script>
    </div>
    <script src="./GOKUL CLINIC - Add User page_files/tabs.js.download"></script>





</body>
<ngx-spinner></ngx-spinner>