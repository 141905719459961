<!DOCTYPE html>
<!-- saved from url=(0031)http://drgokulreddy.in/patients -->
<html lang="en">

<body>
    <div id="page" class="side-nav-wrapper">
        <!-- Simple Navbar -->
        <!-- Simple Navbar -->
        <div id="get-quote" class="vtop-logo get-a-quote black text-center lt-bg-blue">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pull-right" style="margin-right: 10px;">
                        <div class="dropdown">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                                style="background:transparent; margin-bottom: 10px;">{{userDetails.FirstName}}&nbsp;{{userDetails.LastName}}
                                <span class="caret"></span></button>
                            <ul class="dropdown-menu">
                                <li><a [routerLink]="" (click)="changePassword()" class="">Change Password</a></li>
                                <li><a [routerLink]="" (click)="logout()" class="">Logout</a></li>
                                <li *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' ||
                                    userDetails.Roles[0].Role == 'ADMIN'"><a [routerLink]="" (click)="Settings()"
                                        class="">Settings</a></li>
                                <li
                                    *ngIf="userDetails.Roles[0].Role == 'SUPERADMIN' || userDetails.Roles[0].Role =='ADMIN'">
                                    <a [routerLink]="" (click)="users()" class="">Users</a></li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="container bg-gray">
            <div class="row">
                <div class="col-md-4">
                    <h5 class="dr-sub-head"> Helath Reports</h5>
                </div>
            </div>
            <div class="row orr-usercreation ptop30 pbtom30">
                <div class="col-md-12">
                    <div class="panel">
                        <div class="tab-table-customer-entry"
                            style="margin:30px 0;background:#fff;padding:10px 0;border-top:1px  #ddd;border-bottom:1px  #ddd;">


                            <div class="row">
                                <div class="col-md-4">
                                    <h5 class="text-left" style="padding-left: 50px;">
                                        Patient Name : {{report.data.PatientDetails.Name}}
                                    </h5>
                                </div>
                                <div class="col-md-4">
                                    <h5> MRNumber : {{report.data.MRNumber}} </h5>
                                </div>
                                <div class="col-md-4">
                                    <h5> Contact Number : {{report.data.PatientDetails.ContactNumber}} </h5>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <h5 class="text-left" style="padding-left: 50px;">
                                        Report ID : {{report.data.ReportID}}
                                    </h5>
                                </div>
                                <div class="col-md-4">
                                    <h5> Report Name : {{report.data.ReportName}} </h5>
                                </div>
                                <div class="col-md-4">
                                    <h5> Report Date :{{ReportDate}} </h5>
                                </div>
                            </div>
                            <div style="clear:both;"></div>

                            <div class="row" style="padding-left: 50px; padding-right: 50px;">

                                <div *ngFor="let reports of report.data.ReportsImageURLS">
                                    <div style="clear:both;"></div>
                                    <img style="padding: 10px; border-width:5px; border-color: black;" width="auto"
                                        src={{reports}} />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>

</html>
<ngx-spinner></ngx-spinner>